import React from 'react'
import {useState, useEffect} from '@services/hooks'
import {Title} from '@components/General/Elements'
import styled, {createGlobalStyle} from 'styled-components'
import ReactTooltip from 'react-tooltip'
import Analytics from '@services/analytics'
import ScreenerSelector from './ScreenerSelector'
import AdminParamsSelector from './AdminParamsSelector'
import GenerateReport from './GenerateReport'
import ReportDatesSelector from './ReportDatesSelector'

const AdminSignalReport = ({user, mainColWidth, wideColWidth}) => {
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [scoreParam, setScoreParam] = useState({})
    const [adminPlParams, setAdminPlParams] = useState({})
    const [tickerSelectionParams, setTickerSelectionParams] = useState({
        sector: [],
    })
    const [
        analystTeamSelectionParams,
        setAanalystTeamSelectionParamsetAnalysisParams,
    ] = useState({})
    const [scoreVer, setScoreVer] = useState({})

    useEffect(() => {
        Analytics.exec('track', 'select report Signal Report')
    }, [])

    return (
        <Container id="mainDiv" style={{width: wideColWidth}}>
            <OverrideStyles />
            <ReactTooltip />
            <RepTitle
                text={'Signal Report'}
                //toolTip={title}
            />
            <ReportDatesSelector
                mainColWidth={mainColWidth}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
            />

            <ScreenerSelector
                mainColWidth={mainColWidth}
                wideColWidth={wideColWidth}
                tickerSelectionParams={tickerSelectionParams}
                setTickerSelectionParams={setTickerSelectionParams}
                useUniverse={true}
            />

            <AdminParamsSelector
                user={user}
                repType={'admin_signal'}
                wideColWidth={wideColWidth}
                scoreParam={scoreParam}
                setScoreParam={setScoreParam}
                setScoreVer={setScoreVer}
                analystTeamSelectionParams={analystTeamSelectionParams}
                setAanalystTeamSelectionParamsetAnalysisParams={
                    setAanalystTeamSelectionParamsetAnalysisParams
                }
            />

            <GenerateReport
                user={user}
                mainColWidth={mainColWidth}
                fromDate={fromDate}
                toDate={toDate}
                scoreParam={scoreParam}
                tickerSelectionParams={tickerSelectionParams}
                analystTeamSelectionParams={analystTeamSelectionParams}
                scoreVer={scoreVer}
                adminPlParams={adminPlParams}
                setAdminPlParams={setAdminPlParams}
                actionText={'Generate Signal Report'}
                reportType={'admin_signal'}
            />
        </Container>
    )
}

export default AdminSignalReport

const Container = styled.div`
    font-family: 'Inter';
`

const OverrideStyles = createGlobalStyle`
    .ReactCollapse--collapse {
        transition: height 600ms;
    }
`

const RepTitle = styled(Title)`
    display: flex;
    flex-direction: row;
`
