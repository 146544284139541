import React from 'react'
import styled from 'styled-components'
import {useState, useEffect} from '@services/hooks'
import LineChart from '@components/General/LineChart'
import Dialog from '@mui/material/Dialog'

import {
    Title,
    DialogContainer,
    DialogTopRow,
    DialogRow,
} from '@components/General/Elements'
import moment from 'moment'
import {Close} from '@mui/icons-material'
import swal from 'sweetalert2'
import {AppColors} from '@services/styles'
import {api} from '@services/api'

const Symbol2Bench = ({pos, isOpenDialog, setIsOpenDialog}) => {
    const [chartData, setChartData] = useState([])

    useEffect(() => {
        if (isOpenDialog && pos && pos.etf) {
            var d = new Date()
            d.setMonth(d.getMonth() - 1)

            const entryDate = pos.entry_date ? pos.entry_date.date : d
            console.log(
                'my date',
                entryDate,
                moment(entryDate).format('YYYY-MM-DD'),
            )

            api.getSymbol2EtfChart(
                pos.symbol,
                pos.etf,
                moment(entryDate).format('YYYY-MM-DD'),
            ).subscribe(
                (response) => {
                    if (response && response.data && response.data.chart_data) {
                        console.log(
                            'compare chart data',
                            response.data.chart_data,
                        )

                        setChartData(response.data.chart_data)
                        var temp = response.data.chart_data
                        if (temp.datasets) {
                            if (
                                temp.datasets.length == 0 ||
                                (temp.datasets[0] &&
                                    temp.datasets[0].data &&
                                    temp.datasets[0].data.length < 2)
                            ) {
                                swal.fire({
                                    text: 'There is no data available for this symbol. It takes at least one day to calculate the benchmark comparison.',
                                    icon: 'error',
                                    confirmButtonColor: AppColors.TIPIGO_GREEN,
                                }).then(
                                    (res) => {
                                        setIsOpenDialog(false)
                                    },
                                    (err) => {
                                        setIsOpenDialog(false)
                                    },
                                )
                            }
                        }
                    }
                },
                (error) => {
                    console.log('compare chart data error', error)
                },
            )
        }
    }, [pos, isOpenDialog])

    useEffect(() => {
        // this is so we dont see last one when open a new one..
        if (!isOpenDialog) {
            setChartData([])
        }
        console.log('item', pos)
    }, [isOpenDialog])

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            modal={true}
            open={isOpenDialog}
            onClose={() => setIsOpenDialog(false)}>
            <DialogContainer>
                <DialogTopRow style={{marginBottom: 10}}>
                    <Title text={'Compare to Benchmark'} />
                    <Close onClick={() => setIsOpenDialog(false)} />
                </DialogTopRow>
                <LineChart graphData={chartData} />
            </DialogContainer>
        </Dialog>
    )
}
export default Symbol2Bench
