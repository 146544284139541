import React from 'react'
import styled from 'styled-components'
import {useState, useEffect, useUser} from '@services/hooks'
import {DialogRow} from '@components/General/Elements'
import DateRange from '@components/General/DateRange'
import FrameInput from '@components/General/FrameInput'
// import FrameText from '@components/General/FrameText'

import FrameSelect from '@components/General/FrameSelect'
import {AppColors} from '@services/styles'
import {ReactComponent as RemoveLine} from '@images/square_minus.svg'

// find which funcs are the same for all modes and move them here
// see if can separate
const AddPosLine = ({
    item,
    updateField,
    _setTicker,
    setEntry,
    setQuantity,
    _setInputValue,
    single,
    removeRow,
    setByValue,
    ticker,
    closeMode,
    minDate,
}) => {
    useEffect(() => {
        console.log('item', item)
    }, [])

    return (
        <DialogRow
            key={item.id}
            style={{
                justifyContent: 'space-between',
                alignItems: 'flex-end',
            }}>
            {closeMode ? (
                <FrameInput
                    title="Type"
                    value={item.pos_type}
                    disabled
                    style={{width: 90}}
                    frameStyle={{height: 23}}
                />
            ) : (
                <FrameSelect
                    title="Type"
                    style={{width: 130, marginRight: 20}}
                    values={['Buy', 'Sell']}
                    value={item.type}
                    setValue={(val) => updateField(val, item.id, 'type')}
                    disabled={item.posId ? true : false}
                    frameStyle={{height: 28}}
                />
            )}
            <FrameInput
                title={'Symbol'}
                value={item.symbol}
                setValue={(val) => _setTicker(val, item.id, true)}
                inputStyle={item.color ? {color: item.color} : null}
                disabled={item.posId || ticker || closeMode}
                style={{width: 90, marginRight: 20}}
                frameStyle={{height: 23}}
            />
            <DateRange
                fromDate={item?.date || null}
                minDate={minDate}
                toDate={new Date()}
                setFromDate={(val) => updateField(val, item.id, 'date')}
                dateFormat={'MM/dd/yy'}
                style={{width: 150, marginRight: 20}}
                oneDate
                oneDateTitle={closeMode ? 'Close Date' : 'Entry Date'}
                disableWeekend
                frameStyle={{height: 14}}
            />
            <FrameInput
                title={closeMode ? 'Close Price' : 'Entry Price'}
                value={item.price}
                setValue={(val) => setEntry(val, item.id)}
                style={{width: 100, marginRight: 20}}
                frameStyle={{height: 23}}
            />
            <FrameInput
                title={'Quantity'}
                value={item.quantity}
                setValue={(val) => setQuantity(val, item.id)}
                maxLength={8}
                style={{width: 100, marginRight: 20}}
                frameStyle={{height: 23}}
            />

            <FrameInput
                sMode
                sModeDone={() => setByValue(item.id)}
                title={'Value'}
                value={item.value}
                setValue={(val) => _setInputValue(val, item.id)}
                style={{width: 120, marginRight: 20}}
                frameStyle={{height: 23}}
                disabled={item.price == '' || item.price == 0 || closeMode}
            />
            <FrameInput
                title={'Notes'}
                value={item.notes}
                setValue={(val) => updateField(val, item.id, 'notes')}
                style={{width: 150, marginRight: single ? 0 : 20}}
                frameStyle={{height: 23}}
            />
            {!single && (
                <RemoveLine
                    onClick={() => removeRow(item.id)}
                    style={{
                        width: 49,
                        height: 44,
                        stroke: AppColors.APP_GRAY_LIGHT,
                    }}
                />
            )}
        </DialogRow>
    )
}
export default AddPosLine
