import React from 'react'
import {api} from '@services/api'
import {useState, useEffect} from '@services/hooks'
import styled from 'styled-components'
import {Title, SendButton} from '../General/Elements'
import FrameText from '../General/FrameText'
import Swal from 'sweetalert2'
import {AppColors} from '@services/styles'

const d = new Date()

const NotifyUsers = () => {
    const [flavors, setFlavors] = useState([])
    const [msg, setMsg] = useState({})

    useEffect(() => {
        getFlavors()
    }, [])

    useEffect(() => {
        console.log('mmmm', msg)
    }, [msg])

    useEffect(() => {
        if (flavors) {
            let tmp = {}
            flavors.forEach((f) => {
                tmp[f] = ''
            })
            setMsg(tmp)
        }
    }, [flavors])

    const getFlavors = () => {
        api.getAppFlavors().subscribe(
            (response) => {
                setFlavors(response.data)
            },
            (error) => {
                console.log('getAppFlavors error', error)
            },
        )
    }
    // componentDidUpdate(prevProps) {
    //     if (prevProps.app !== this.props.app) {
    //         this.setState({app: this.props.app})
    //         this.setState({
    //             msg: this.props.app === 'SponserTips' ? msgHe : msgEn,
    //             textDir: this.props.app === 'SponserTips' ? 'rtl' : 'ltr',
    //         })
    //     }
    // }
    // changeMsg = (event) => {
    //     this.setState({msg: event.target.value})
    // }
    const updateMsg = (msg, app) => {
        setMsg((prev) => ({...prev, [app]: msg}))
    }

    const sendMsg = (app) => {
        console.log('msg_to', app, msg[app])
        if (!msg[app] || msg[app] == '') {
            Swal.fire({
                text: 'please enter messege text',
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
        } else {
            Swal.fire({
                text:
                    'this wiil send a notification to all ' +
                    app +
                    ' users! continue?',
                showCancelButton: true,
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            }).then((response) => {
                if (response && response.isConfirmed) {
                    console.log('Yes send')
                    api.notifyAllUsers(app, msg[app]).subscribe(
                        (response) => {
                            console.log('send response', response)
                            alert('notifications added to send queue')
                        },
                        (error) => {
                            alert('send error')
                            console.log('notifyAllUsers error', error)
                        },
                    )
                } else {
                    console.log('No')
                }
            })
        }
    }

    return (
        <Container>
            <Title text="Notify all users" />
            {flavors.map((flavor) => {
                return (
                    <Row>
                        <FrameText
                            title={'notify all ' + flavor + ' users'}
                            style={{width: 300, marginRight: 26}}
                            frameStyle={{height: 80}}
                            setValue={updateMsg}
                            params={flavor}
                        />
                        <SendButton onClick={() => sendMsg(flavor)}>
                            Send
                        </SendButton>
                    </Row>
                )
            })}
        </Container>
    )
}

export default NotifyUsers

const Container = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
`
const Row = styled.div`
    display: flex;
    margin-top: 30px;
    align-items: flex-end;
    justify-content: space-between;
`
