import React, {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'

import {Range, createSliderWithTooltip} from 'rc-slider'

import _ from 'lodash'

import {Label, Container} from './HomeDrawer.styles'
import {AppColors} from '../../services/styles'

const StyledRange = styled(Range)`
    margin-top: 20px;
    .rc-slider-track {
        background-color: ${AppColors.TIPIGO_BLUE};
    }
    .rc-slider-handle {
        border-color: ${AppColors.TIPIGO_BLUE};
        background-color: ${AppColors.TIPIGO_BLUE};
        margin-left: 0px;
    }
    /* 
        styles of tooltip can find in main.css 
        under class .rc-slider-tooltip-inner 
    */
`

const Heading = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const AlertRange = ({
    label,
    keyBy,
    alerts,
    tipFormatter,
    onChange,
    step,
    value,
    //maxMc,
    //minMc,
}) => {
    if (!alerts) return null

    let minAlertVal =
        label == 'Analyst Grade' ? 1 : keyBy(_.minBy(alerts, keyBy))

    let maxAlertVal =
        label == 'Analyst Grade' ? 7 : keyBy(_.maxBy(alerts, keyBy))

    // if (label == 'Market Cap') {
    //     // this is for js issues with big numbers. TODO: find better
    //     //const maxEq = Math.abs(maxAlertVal - maxMc) < 100
    //     const minIsMax = Math.abs(minAlertVal - maxMc) < 100
    //     //console.log('mc_top', minAlertVal, minMc)
    //     if (minIsMax) {
    //         minAlertVal = _.minBy(alerts, (a) =>
    //             parseInt(a.market_cap),
    //         ).market_cap
    //         maxAlertVal = _.maxBy(alerts, (a) =>
    //             parseInt(a.market_cap),
    //         ).market_cap
    //     }
    // }

    // if (label === 'Upside') {
    //      may work around big upside like that - big numbers are not a bug
    //      but if doing this need to fix flter satge in HomeDrawer
    //     maxAlertVal = 200
    // }
    const mcFormater = (val) => {
        const arr = alerts.filter((a) => a.mc_index == val)
        if (arr.length) {
            return arr[0].market_cap.toLocaleString('en-US', {
                // style: 'currency', // can use that, will $1.2T. i think too much
                // currency: 'USD',
                notation: 'compact',
                maximumFractionDigits: 1,
            })
        } else {
            return ''
        }
    }
    value = _.isEqual(value, [0, 0]) ? [minAlertVal, maxAlertVal] : value
    // TODO: not done, some tipFormatter here and some in HomeDrawer, HomeDrawer doesn't "know" alerts,
    // more ranges here may need similar solution... and more
    // make order
    const valLabel = value
        ? label === 'Market Cap'
            ? value.map(mcFormater).join(' - ')
            : value.map(tipFormatter).join(' - ')
        : ''

    return (
        <Container>
            <Heading>
                <Label>{label}</Label>
                <Label>{valLabel}</Label>
            </Heading>
            <StyledRange
                min={minAlertVal}
                max={maxAlertVal}
                step={step}
                // 25.06.23 remove next with all changes in mc, not sure it was in use before. maybe just for tooltip(?) but couldn't see any effect
                //tipFormatter={tipFormatter}
                onChange={onChange}
                value={value}
                scale={(v) => 0}
            />
        </Container>
    )
}

const mapStateToProps = (state) => ({alerts: state.finance.data.alerts})
export default connect(mapStateToProps)(AlertRange)
