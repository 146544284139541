import React, {Component, PropTypes} from 'react'
import {api} from '@services/api'
import styled from 'styled-components'
import {useState, useEffect} from '@services/hooks'

const styles = {
    main: {paddingTop: 25},
    divList: {display: 'flex', paddingTop: 4},
}
const SymbolTable = ({pid, flag}) => {
    const [list, setList] = useState(null)

    useEffect(() => {
        getSymbols(pid)
    }, [])

    useEffect(() => {
        setList(null)
        getSymbols(pid)
    }, [pid, flag])

    const getSymbols = (pid) => {
        api.getPortfolio(pid).subscribe(
            (response) => {
                console.log('getSymbols response', response)
                setList(response.data.data.list)
            },
            (error) => {
                console.log('getSymbols error', error)
            },
        )
    }

    const removeSymbol = (symbol) => {
        api.removePortfolioSymbol(pid, symbol).subscribe(
            (response) => {
                if (response.data.status === true) {
                    getSymbols(pid)
                    //this.setState({ refreshList: !this.state.refreshList });
                } else {
                    alert(response.data.status)
                }
            },
            (error) => {
                console.log('getSymbols error', error)
            },
        )
    }

    return (
        <div style={styles.main}>
            {list && (
                <div style={{justifyContent: 'center'}}>
                    {!list.some((item) => item.symbol == 'SPY') && (
                        <SpyWarn>missing SPY symbol</SpyWarn>
                    )}
                    <div style={{...styles.divList, fontWeight: 600}}>
                        <TableCell>Symbol</TableCell>
                        <TableCell>Entry</TableCell>
                        <TableCell>Last</TableCell>
                    </div>
                    {list.map((s, i) => {
                        return (
                            <div key={i} style={styles.divList}>
                                <TableCell>{s.symbol}</TableCell>
                                <TableCell>{s.entry}</TableCell>
                                <TableCell>{s.last_price}</TableCell>

                                <button
                                    onClick={() => removeSymbol(s.symbol)}
                                    style={{width: 60}}>
                                    remove
                                </button>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default SymbolTable

const TableCell = styled.span`
    display: flex;
    align-items: flex-start;
    width: 80px;
`
const SpyWarn = styled.div`
    display: flex;
    align-items: flex-start;
    color: #ee5555;
    font-size: 14px;
`
