import React from 'react'
import styled from 'styled-components'
//import {useState, useEffect, useUser} from '@services/hooks'
import Dialog from '@mui/material/Dialog'
import {
    Title,
    DialogContainer,
    DialogTopRow,
    DialogRow,
} from '@components/General/Elements'
import {Close} from '@mui/icons-material'
import {Doughnut} from 'react-chartjs-2'
import ReactTooltip from 'react-tooltip'

const ScoreCircle = ({totalSize, value, small = true, reverse}) => {
    const num_val = value == '??' ? '25' : value //TODO: loose this bs
    console.log('temp_val', value)
    // more BS! doc to understabd...

    // the color should be min to max (green) but the circle stays 0 to 100

    const getColor = (score) => {
        if (reverse) {
            if (score > 66) return '#E92D2D'
            else if (score > 33) return '#FDC32F'
            else return '#00BA6C'
        }
        if (score > 66) return '#00BA6C'
        else if (score > 33) return '#FDC32F'
        else return '#E92D2D'
    }
    const chartData = {
        datasets: [
            //TODO: 100, 5 etc
            {
                data: [num_val, 100 - num_val],
                backgroundColor: [getColor(value), '#E9EAEC'],
            },
        ],
    }
    const options = {
        plugins: {
            legend: {display: false},
            tooltip: {
                enabled: false,
            },
        },
        responsive: true,
        cutout: small ? 22 : 59, // this option has to correspond with total width given to the chart container. otherwsie it might disappear
    }
    const plugins = [
        {
            beforeDraw: function (chart) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx
                ctx.restore()
                //var fontSize = (height / 160).toFixed(2)
                ctx.font = (small ? '16' : '46') + 'px Inter'
                //ctx.textBaseline = 'top'
                var temp_val =
                    !value && value !== 0 && value !== '0'
                        ? ''
                        : value == '??'
                        ? value
                        : Number.isInteger(value)
                        ? value
                        : parseFloat(value.toFixed(3))

                var text = temp_val,
                    textX = Math.round(
                        (width - ctx.measureText(text).width) / 2,
                    ),
                    textY = height / 2 + (small ? 5 : 15)
                ctx.fillText(text, textX, textY)
                ctx.save()
            },
        },
    ]

    // consider moving to a file if it gets to big or more uses
    return (
        <div style={{width: totalSize}}>
            <Doughnut data={chartData} options={options} plugins={plugins} />
        </div>
    )
}

const ScoreParts = ({isOpenDialog, setIsOpenDialog, data}) => {
    //Chart.overrides.doughnut.plugins.legend.display = false

    return (
        data && (
            <Dialog
                maxWidth="sm"
                fullWidth
                modal={true}
                open={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}>
                <DialogContainer style={{padding: 25}}>
                    <ReactTooltip multiline={true} id="path1" />
                    <DialogTopRow>
                        <Title text={'Opportunity Rank for ' + data.ticker} />
                        <Close onClick={() => setIsOpenDialog(false)} />
                    </DialogTopRow>

                    <DialogRow>
                        <MainPart>
                            <Side>
                                <ScoreLabelBig>Total Rank</ScoreLabelBig>
                                <ScoreCircle
                                    totalSize={150}
                                    value={data['total score']}
                                    small={false}
                                />
                            </Side>
                            <Side2>
                                <SmallScore>
                                    <ScoreCircle
                                        totalSize={64}
                                        value={data.algorithm_score_disp}
                                    />
                                    <ScoreLabel
                                        data-for="path1"
                                        data-tip="Tipigo's Analysts score is based on the rating given to this symbol by Tipigo's top analyst team over the past 3 months.<br /> It reflects their assessment of the symbol's potential performance.">
                                        Analyst Data
                                    </ScoreLabel>
                                </SmallScore>
                                <SmallScore>
                                    <ScoreCircle
                                        totalSize={64}
                                        value={data.risk_disp} //num_rec_score_disp
                                        //max={60}
                                        reverse
                                    />
                                    <ScoreLabel
                                        data-for="path1"
                                        data-tip="Tipigo's low risk score is determined by analyzing the price variance of the symbol over the last three months and taking into account the consensus level among analysts.<br />It indicates the perceived level of risk associated with the symbol.<br/>Should/Must take under consideration: Stock SHARP ratio, Standard Deviation in compare to similar stocks and/or benchmark, BETA calculated for last 3 months.">
                                        Risk
                                    </ScoreLabel>
                                </SmallScore>
                                <SmallScore>
                                    <ScoreCircle
                                        totalSize={64}
                                        value={data.market_score_disp}
                                    />
                                    <ScoreLabel
                                        data-for="path1"
                                        data-tip=" The Momentum Score is based on Tipigo's engine's assessment of the symbol's market performance over the past 3 months.<br/> Factors such as positive P&L (Profit and Loss) and performance compared to a preconfigured benchmark ETF are considered in calculating this score.">
                                        Momentum Score
                                    </ScoreLabel>
                                </SmallScore>
                                <SmallScore>
                                    <ScoreCircle
                                        totalSize={64}
                                        value={data.fundamental_score}
                                    />
                                    <ScoreLabel
                                        data-for="path1"
                                        data-tip="The Fundamental score is based on Tipigo's engine's evaluation of the symbol's fundamental characteristics.<br/>Factors such as market capitalization, beta, price-to-earnings ratio (P/E), price-to-book ratio (P/B), Dept to Equity Ratio and earning date are taken into account when determining this score.">
                                        Fundamental Score
                                    </ScoreLabel>
                                </SmallScore>
                            </Side2>
                        </MainPart>
                    </DialogRow>
                    {/* <OverrideTooltipStyle /> */}
                </DialogContainer>
            </Dialog>
        )
    )
}
export default ScoreParts

const MainPart = styled.div`
    display: flex;
    flex: 1;
`
const Side = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    border: 0px solid green;
    justify-content: center;
`
const Side2 = styled(Side)`
    align-items: flex-start;
    padding-left: 20px;
`
const SmallScore = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
`
const ScoreLabelBig = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 14px;
`
const ScoreLabel = styled(ScoreLabelBig)`
    font-size: 15px;
    margin-left 8px;
`
// this is effecting but did not find the right class to override
// const AlignTooltip = styled(ReactTooltip).attrs({
//     className: 'custom-tooltip',
// })`
//     &.custom-tooltip {
//         text-align: left !important;
//         background-color: red;
//     }
// `
// const OverrideTooltipStyle = createGlobalStyle`
// .tooltip{background-color: green;}
// .tooltip.inner{background-color: green;}
// `
