import React, {useEffect} from 'react'

// import {bindActionCreators} from 'redux'
// import {connect} from 'react-redux'
// import store from '@store'
//import _ from 'lodash'
import {
    userIsAuthenticatedRedir,
    userIsNotAuthenticatedRedir,
    // userIsAdminRedir,
    // userIsAuthenticated,
    // userIsNotAuthenticated,
} from './Auth'

import Home from '@components/Home'
import NoMatch from '@components/NoMatch'
import InvestmentProfile from '@components/InvestmentProfile'
import EditProfile from '@components/EditProfile'

import Stock from '@components/Stock'
import Profile from '@components/Profile'
import Login from '@components/Login'
//import ChangePassword from '@components/ChangePassword'
import Terms from '@components/Terms'
import Register from '@components/Profile/Register'
import Manage from '@components/Manage'
//import Reports from '@components/Reports'   28.07.22 comment
import AppHome from '@components/AppHome'
import AppPortfolios from '@components/AppPortfolios'
import Contact from '@components/Contact'
import NewAccountUser from '@components/Profile/NewAccountUser'
import ResetPassword from '@components/ResetPassword'
// For Customization Options, edit  or use
// './src/material_ui_raw_theme_file.jsx' as a template.
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import theme from './material_ui_raw_theme_file'

import {ToastContainer} from 'react-toastify'

//import DataService from '@services/data'
import {getCurrentUser} from '@store/actions'
import {useDispatch} from '@services/hooks'

import {
    HashRouter as Router,
    Route,
    Switch,
    // Link,
    //Redirect,
    //useNavigate,
} from 'react-router-dom'
// import {useHistory} from '@services/hooks'

const App = () => {
    const dispatch = useDispatch()
    // const user = useUser()
    // const history = useHistory()

    useEffect(() => {
        // const navigate = useNavigate()
        // navigate('http://google.com')
        // window.location = 'https://google.com'
        window.process = {
            // 30.11.23 add this to try to solve 'Uncaught ReferenceError: process is not defined'
            // did not help
            ...window.process,
        }
        dispatch(getCurrentUser())
    }, [dispatch])

    return (
        <div>
            <MuiThemeProvider muiTheme={theme}>
                <Router>
                    <div>
                        <Switch>
                            <Route
                                exact
                                path="/"
                                component={userIsAuthenticatedRedir(Home)}
                            />
                            <Route
                                exact
                                path="/newProfile"
                                component={userIsAuthenticatedRedir(Register)}
                            />
                            <Route
                                path="/investment-profile"
                                component={userIsAuthenticatedRedir(
                                    //InvestmentProfile,
                                    EditProfile,
                                )}
                            />
                            <Route
                                path="/stock/:symbol"
                                component={userIsAuthenticatedRedir(Stock)}
                            />
                            <Route
                                path="/profile"
                                component={userIsAuthenticatedRedir(Profile)}
                            />
                            <Route
                                path="/manage/:mode/:itemId"
                                component={userIsAuthenticatedRedir(Manage)}
                            />
                            <Route
                                path="/manage/:mode"
                                component={userIsAuthenticatedRedir(Manage)}
                            />
                            {/* 28.07.22 comment 
                            <Route
                                path="/reports/:report/:params"
                                component={userIsAuthenticatedRedir(Reports)}
                            />
                            <Route
                                path="/reports/:report"
                                component={userIsAuthenticatedRedir(Reports)}
                            />
                            <Route
                                path="/reports"
                                component={userIsAuthenticatedRedir(Reports)}
                            /> */}
                            <Route
                                path="/appportfolios"
                                component={userIsAuthenticatedRedir(
                                    AppPortfolios,
                                )}
                            />
                            <Route
                                path="/login"
                                component={userIsNotAuthenticatedRedir(Login)}
                            />
                            {/* <Route
                                path="/change-password/:token"
                                component={userIsNotAuthenticatedRedir(
                                    ChangePassword,
                                )}
                            /> */}
                            <Route
                                path="/terms"
                                component={userIsNotAuthenticatedRedir(Terms)}
                            />
                            <Route path="/apphome" component={AppHome} />
                            <Route path="/contact" component={Contact} />
                            {/* 03.01.24 <Route
                                path="/register"
                                component={NewAccountUser}
                            /> */}
                            <Route
                                path="/reset-pass/:code"
                                component={ResetPassword}
                            />
                            <Route
                                component={userIsAuthenticatedRedir(NoMatch)}
                            />
                        </Switch>
                    </div>
                </Router>
            </MuiThemeProvider>
            <ToastContainer autoClose={4000} />
        </div>
    )
}

// function mapStateToProps(state) {
//     return {}
// }

// function mapDispatchToProps(dispatch) {
//     const ds = new DataService(dispatch)

//     return {
//         getCurrentUser1: () => ds.getCurrentUser(),
//     }
// }

export default /*connect(mapStateToProps, mapDispatchToProps)*/ App
