import React, {Component} from 'react'
import CircularProgress from 'material-ui/CircularProgress'
// import ReactTable from "react-table";
// import Pagination from "../General/Pagination";
import Api from '@services/api'

class MoreTools extends Component {
    api = new Api()

    state = {
        userId: '',
        results: null,
        running: false,
    }

    updateUserId = (event) => {
        this.setState({userId: event.target.value})
    }

    //   getCSVUrl = () => {
    //     let envs = Config.AVAILABLE_ENVIRONMENTS;
    //     let selectedEnv =
    //       window.location.host === "app.tipigo.com" ||
    //       window.location.host === "insight.tipigo.com"
    //         ? "production"
    //         : "development";
    //     let protocol = Config.USE_HTTPS ? "https" : "http";
    //     let BASE_URL = protocol + "://" + envs[selectedEnv];

    //     let lastDate = new Date(this.state.endMonth);
    //     // get last day of selected month
    //     lastDate = new Date(lastDate.getFullYear(), lastDate.getMonth() + 1, 0);
    //     return (
    //       BASE_URL +
    //       "/reports/get_gold_history_envents/" +
    //       this.state.goldThreshold +
    //       "/" +
    //       this.state.timeWindow +
    //       "/" +
    //       this.state.startMonth +
    //       "/" +
    //       lastDate.getFullYear() +
    //       "-" +
    //       (lastDate.getMonth() + 1) + //  0-11
    //       "-" +
    //       lastDate.getDate()
    //     );
    //   };

    //----------------------------------------------------------
    getUserOrder = () => {
        this.setState({results: null, running: true})
        console.log('running', this.state)

        this.api.getUserOrderByID(this.state.userId).subscribe((response) => {
            console.log('response', response)

            this.setState({
                running: false,
                results: response && response.data ? response.data : null,
            })
        })
    }

    render() {
        return (
            <div>
                <div>
                    <label>
                        User ID (Google only):
                        <input
                            value={this.state.userId}
                            onChange={this.updateUserId}
                            style={{width: 180}}
                        />
                    </label>
                    &nbsp;&nbsp;
                    <button onClick={this.getUserOrder}>Get user order</button>
                </div>
                <br />
                {this.state.results ? (
                    <div>
                        {this.state.results.msg && (
                            <div>{this.state.results.msg}</div>
                        )}
                        {this.state.results.url && (
                            <div>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={this.state.results.url}>
                                    customer order
                                </a>
                            </div>
                        )}
                    </div>
                ) : this.state.running ? (
                    <CircularProgress />
                ) : (
                    <div />
                )}
            </div>
        )
    }
}

export default MoreTools
