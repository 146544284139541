import React from 'react'
import {api} from '@services/api'
import {useState, useEffect} from '@services/hooks'
import styled, {createGlobalStyle} from 'styled-components'
//import {Close, SwapCallsTwoTone} from '@material-ui/icons'
import {Close} from '@mui/icons-material'
import 'sweetalert2/dist/sweetalert2.min.css'
import Dialog from '@mui/material/Dialog'
import {
    Title,
    SendButton,
    DialogContainer,
    DialogTopRow,
    DialogRow,
} from '@components/General/Elements'
import Swal from 'sweetalert2'
//import FrameDiv from './Controls/FrameDiv'
import FrameInput from '../General/FrameInput'
import {AppColors} from '@services/styles'
import {validPassword, invalidPassMsg} from '@services/utils'

const ChangePassword = ({show, hide}) => {
    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [confirm, setConfirm] = useState('')
    const [nomatch, setNomatch] = useState(false)

    useEffect(() => {
        setNomatch(
            newPass.length > 0 && confirm.length > 1 && newPass != confirm,
        )
    }, [newPass, confirm])

    const updatePassword = () => {
        if (newPass !== confirm) {
            Swal.fire({
                text: 'New password does not match confirm',
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
            return
        }
        if (!validPassword(newPass)) {
            Swal.fire({
                text: invalidPassMsg,
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
            return
        }
        api.changePasswordDirect(oldPass, newPass).subscribe(
            (response) => {
                if (response.data && response.data.status) {
                    Swal.fire({
                        text: 'New password saved',
                        icon: 'success',
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                    })
                    hide()
                }
            },
            (error) => {
                console.log('error', error)
                Swal.fire({
                    text: error.data.msg,
                    icon: 'error',
                    confirmButtonColor: AppColors.TIPIGO_GREEN,
                })
                return
            },
        )
    }

    return (
        <Dialog modal={true} open={show} fullWidth>
            <OverrideStyles />
            <DialogContainer>
                <DialogTopRow>
                    <Title text={'Change Password'} />
                    <Close onClick={() => hide()} />
                </DialogTopRow>
                <DialogRow>
                    <FrameInput
                        title={'Current Password'}
                        value={oldPass}
                        setValue={setOldPass}
                        style={{flex: 1}}
                        password
                        maxLength={20}
                    />
                </DialogRow>
                <DialogRow>
                    <FrameInput
                        title={'New Password'}
                        value={newPass}
                        setValue={setNewPass}
                        style={{flex: 1}}
                        password
                        maxLength={20}
                    />
                </DialogRow>
                <DialogRow style={{flexDirection: 'column'}}>
                    <FrameInput
                        title={'Confirm New Password'}
                        value={confirm}
                        setValue={setConfirm}
                        //style={{flex: 1}}
                        password
                        maxLength={20}
                    />
                    <NoMatch>
                        {nomatch ? 'Passwords do not match' : ' '}
                    </NoMatch>
                </DialogRow>

                <DialogRow style={{justifyContent: 'flex-end'}}>
                    <SendButton onClick={updatePassword}>Save</SendButton>
                </DialogRow>
            </DialogContainer>
        </Dialog>
    )
}

export default ChangePassword

const NoMatch = styled.div`
    font-size: 11px;
    color: #f44336;
    margin-top: 5px;
    height: 18px;
`
const OverrideStyles = createGlobalStyle`
    .swal2-container {
        z-index: 2000;
    }`
