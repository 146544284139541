import React from 'react'
import styled from 'styled-components'
import {useState, useEffect, useUser} from '@services/hooks'
import Dialog from '@mui/material/Dialog'
import {
    Title,
    SendButton,
    DialogContainer,
    DialogTopRow,
    DialogRow,
} from '@components/General/Elements'
import {Close} from '@mui/icons-material'
import {Checkbox} from '@components/General/Elements'
import {AppColors} from '@services/styles'
import {api} from '@services/api'
import FrameInput from '@components/General/FrameInput'
import swal from 'sweetalert2'
import {intOrEmpty} from '@services/utils'
import ReactTooltip from 'react-tooltip'
import {ReactComponent as InfoIcon} from '@images/info.svg'
import FrameSelect from '@components/General/FrameSelect'
import {unvirsesForHistoryScore} from '@constants/Finance'

const weekly =
    'You will receive a single weekly notification to minimize alert frequency and manage notification noise effectively'
const MonitorSettings = ({
    pId,
    isOpenDialog,
    setIsOpenDialog,
    deletePotfolio,
}) => {
    const user = useUser()
    const [mySets, setMySets] = useState([])
    const allSets = [
        {type: 'buy', text: 'Buy rating for portfolio holdings'},
        {type: 'sell', text: 'Sell rating for portfolio holdings'},
        {type: 'earning', text: 'Symbol earning date'},
        {
            type: 'refresh',
            text: 'Refresh positions after 3 months',
            info: 'This alert is generated when a position in your portfolio is approaching its predefined refresh time.<br/> Refreshing your portfolio is essential to ensure your investment strategy remains up-to-date',
        },
        {type: 'ma50', text: 'Under the Moving Average 50', info: weekly},
        {
            type: 'etf',
            text: 'Benchmark Deviation Limit',
            hasVal: true,
            def: 10,
            text2: '%',
            info: weekly,
        },
        {
            type: 'sl',
            text: 'Stoploss ',
            text2: '% since entry',
            hasVal: true,
            info: weekly,
            def: 10,
        },
        {type: 'split', text: 'Split/Dividend event'},
        {
            type: 'profit',
            text: 'Take profit after',
            text2: '%',
            hasVal: true,
            info: weekly,
            def: 10,
        },
        {
            type: 'universeRank',
            text: 'Rank level decrease by',
            text2: ' points. on universe:',
            hasVal: true,
            info: "Alerts on changes in the Symbol's universe rank",
            def: 10,
            options: unvirsesForHistoryScore,
            selectedOption: unvirsesForHistoryScore[0],
            oneRow: true,
        },
    ]
    const numberOfRows = Math.ceil(
        allSets.filter((set) => !set.oneRow).length / 2,
    )
    const rows = [...Array(numberOfRows).keys()]
    useEffect(() => {
        if (pId)
            api.getPortfolioNotificationsSettings(pId).subscribe(
                (response) => {
                    console.log('getting settings', response)
                    if (response?.data?.data) {
                        // for easier handle we want mySets to have all
                        var selected = response.data.data.map((item) => {
                            return {...item, checked: true}
                        })
                        // add not existing ones
                        allSets.forEach((a) => {
                            if (
                                selected.filter((s) => s.type === a.type)
                                    .length == 0
                            ) {
                                selected.push({
                                    type: a.type,
                                    value: a.hasVal ? a.def : null,
                                    checked: false,
                                    selectedOption: a.options
                                        ? a.options[0]
                                        : null,
                                })
                            }
                        })
                        setMySets(selected)
                    } else {
                        console.log('error getting settings2')
                    }
                },
                (error) => {
                    console.log('error getting settings', error)
                },
            )
    }, [pId])

    const setOneSetting = (check, type, value) => {
        const thisSet = mySets.find((set) => set.type === type)
        value = value ? intOrEmpty(value.toString()) : null
        setMySets([
            ...mySets.filter((set) => set.type != type),
            {...thisSet, checked: check, value: value},
        ])
    }
    const setOneSettingOption = (type, value) => {
        const thisSet = mySets.find((set) => set.type === type)

        setMySets([
            ...mySets.filter((set) => set.type != type),
            {...thisSet, selectedOption: value},
        ])
    }

    useEffect(() => {
        console.log(mySets)
    }, [mySets])

    const updateSettings = () => {
        api.setPortfolioNotificationsSettings(
            pId,
            mySets.filter((s) => s.checked),
        ).subscribe(
            (response) => {
                if (response?.data?.status) {
                    swal.fire({
                        text: 'Monitor Settings updated',
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                    }).then((response) => setIsOpenDialog(false))
                } else {
                    console.log('error updateing settings2')
                }
            },
            (error) => {
                console.log('error updateing settings', error)
            },
        )
    }

    const getOneSetting = ({item, curr}) => {
        return (
            <SetContainer oneRow={item.oneRow} options={item.options}>
                <Label>
                    <Checkbox
                        checked={curr.checked}
                        onChange={(e) =>
                            setOneSetting(
                                e.target.checked,
                                item.type,
                                curr.value,
                            )
                        }
                    />
                    <SpanText>{item.text}</SpanText>

                    {item.hasVal ? (
                        <>
                            <FrameInput
                                value={curr.value}
                                setValue={(val) =>
                                    setOneSetting(curr.checked, item.type, val)
                                }
                                maxLength={2}
                                style={{
                                    width: 40,
                                    height: 18,
                                    marginLeft: 5,
                                    marginRight: 4,
                                }}
                                frameStyle={{
                                    padding: 5,
                                    marginTop: -6,
                                }}
                            />
                            <SpanText>{item.text2}</SpanText>
                        </>
                    ) : null}
                </Label>
                {item.options && (
                    <FrameSelect
                        values={item.options}
                        value={curr.selectedOption}
                        setValue={(val) => setOneSettingOption(item.type, val)}
                        style={{
                            width: 180,
                            marginLeft: 5,
                            marginRight: 4,
                        }}
                        frameStyle={{height: 28}}
                    />
                )}
                {item.info && (
                    <InfoIcon
                        width={20}
                        style={{marginLeft: 6}}
                        data-tip={item.info}
                        data-for="monitor-tt"
                    />
                )}
            </SetContainer>
        )
    }

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            modal={true}
            open={isOpenDialog}
            onClose={() => setIsOpenDialog(false)}>
            <DialogContainer>
                <ReactTooltip id="monitor-tt" html={true} />
                <DialogTopRow>
                    <Title text={'Settings'} />
                    <Close onClick={() => setIsOpenDialog(false)} />
                </DialogTopRow>
                <SubTitle>Portfolio Monitor</SubTitle>
                <SubSubTitle>
                    Set up personalized monitoring and notifications to track
                    your portfolio's performance.
                </SubSubTitle>
                {mySets.length === allSets.length && (
                    <>
                        {rows.map((row) => {
                            const rowSets = allSets
                                .filter((set) => !set.oneRow)
                                .slice(row * 2, row * 2 + 2)
                            return (
                                <DialogRow
                                    key={`row${row}`}
                                    style={{paddingTop: 13}}>
                                    {rowSets.map((item) => {
                                        const curr = mySets.find(
                                            (set) => set.type === item.type,
                                        )
                                        return getOneSetting({item, curr})
                                    })}
                                </DialogRow>
                            )
                        })}
                        {allSets
                            .filter((set) => set.oneRow)
                            .map((item) => {
                                const curr = mySets.find(
                                    (set) => set.type === item.type,
                                )
                                return (
                                    <DialogRow
                                        key={`row${item.type}`}
                                        style={{paddingTop: 13}}>
                                        {getOneSetting({item, curr})}
                                    </DialogRow>
                                )
                            })}
                    </>
                )}

                <SepareteRow>
                    <SendButton onClick={updateSettings}>Save</SendButton>
                </SepareteRow>

                <SubTitle>Delete Portfolio</SubTitle>
                <DialogRow style={{justifyContent: 'flex-end'}}>
                    <SendButton onClick={deletePotfolio}>Delete</SendButton>
                </DialogRow>
            </DialogContainer>
        </Dialog>
    )
}

export default MonitorSettings

//display: inline-block;
const Label = styled.label`
    display: flex;
    flex-direction: row;
`
const SetContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: ${(p) => (p.options ? 5 : 15)}px;px;
    width: ${(p) => (p.oneRow ? 740 : 370)}px;
`
const SpanText = styled.span`
    vertical-align: middle;
    font-size: 14px;
`
const SubSubTitle = styled(DialogRow)`
    font-size: 16px;
    padding-top: 5px;
    color: ${AppColors.SUB_HEAD_GREY};
`
const SubTitle = styled.div`
    font-size: 17px;
    font-weight: 600;
    margin-top: 20px;
    color: ${AppColors.BLACK};
`
const SepareteRow = styled(DialogRow)`
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 20px;
    padding-bottom: 25px;
    padding-right: 10px;
    border-bottom: 1px solid ${AppColors.APP_GRAY_LIGHT};
`
