import React from 'react'
import {useEffect, useUser} from '@services/hooks'

import Dialog from '@mui/material/Dialog'
import 'react-datepicker/dist/react-datepicker.css'
import {
    Title,
    DialogContainer,
    DialogTopRow,
    DialogRow,
} from '@components/General/Elements'
import {Close} from '@mui/icons-material'
import SelectSectors from '../../Reports/SelectSectors.jsx'
import {AppColors} from '@services/styles'

const SelectBenchmarks = ({
    isOpenDialog,
    setIsOpenDialog,
    benchmarks,
    setBenchmarks,
    showLongShort,
    type,
}) => {
    const user = useUser()

    useEffect(() => {
        console.log('benchmark change in pop ', benchmarks)
    }, [benchmarks])

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            modal={true}
            open={isOpenDialog}
            onClose={() => setIsOpenDialog(false)}>
            <DialogContainer>
                <DialogTopRow>
                    <Title text={'Add Benchmarks to chart'} />
                    <Close onClick={() => setIsOpenDialog(false)} />
                </DialogTopRow>
                <DialogRow>
                    <SelectSectors
                        sectors={benchmarks}
                        setSectors={setBenchmarks}
                        type={type ? type : 'benchmark'}
                        width={800}
                        //user={user}

                        givenTitle={null}
                    />
                </DialogRow>
                {showLongShort && (
                    <DialogRow
                        style={{
                            borderTop: '1px solid ' + AppColors.APP_GRAY_LIGHT,
                            marginTop: 20,
                        }}>
                        <SelectSectors
                            sectors={benchmarks}
                            givenList={[
                                {
                                    title: 'Open Long Positions',
                                    name: 'long',
                                    value: true,
                                },
                                {
                                    title: 'Open Short Positions',
                                    name: 'short',
                                    value: true,
                                },
                            ]}
                            setSectors={setBenchmarks}
                            // 08.10.23 type={'sectors'} ATT: sectors and not sector
                            width={800}
                            givenTitle={null}
                        />
                    </DialogRow>
                )}
            </DialogContainer>
        </Dialog>
    )
}

export default SelectBenchmarks
