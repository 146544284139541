import React, {Component} from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import {Range} from 'rc-slider'

import {AppColors, Dimensions} from '../../services/styles'

export const ContainerPadding = '10px'

export const Container = styled.div`
    padding: ${ContainerPadding};
`

const SelectWrap = styled(Container)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    outline: 0px !important;

    > * {
        border-color: hsl(0, 0%, 80%) !important;
    }
`
const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: 'none',
        outline: 'none',
        '&:hover': {borderColor: 'gray'}, // border style on hover Alon Masofon error here see coment
        border: '1px solid lightgray', // default border color
        boxShadow: 'none', // no box-shadow
    }),
}
const SSelect = styled(Select).attrs({styles: customStyles})`
    width: 80%;
    z-index: 99;
`
export const Label = styled.span`
    font-size: 14px;
    color: ${AppColors.APP_GRAY};
`
const AllSelect = (p) => (
    <SelectWrap>
        <Label>Profile</Label>
        <SSelect {...p} />
    </SelectWrap>
)
export {AllSelect as Select}

const StyledRange = styled(Range)`
    .rc-slider-track {
        background-color: ${AppColors.TIPIGO_BLUE};
    }
    .rc-slider-handle {
        border-color: ${AppColors.TIPIGO_BLUE};
    }
`
export {StyledRange as Range}

const ResetContainer = styled(Container)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 5px 0px;
    span {
        color: ${AppColors.TIPIGO_BLUE};
        font-size: 16px;
        cursor: pointer;
    }
`
export const Reset = ({onClick}) => (
    <ResetContainer>
        <span onClick={onClick}>Reset</span>
    </ResetContainer>
)
