import React from 'react'
import {api} from '@services/api'
import {useState, useEffect} from '@services/hooks'
//import Api from '../services/api'
import Header from '../Header'
import styled, {createGlobalStyle} from 'styled-components'
import FrameInput from '@components/General/FrameInput'
import {Title, SendButton} from '@components/General/Elements'
import Swal from 'sweetalert2'
import AppBar from 'material-ui/AppBar'
import {AppColors, Dimensions} from '@services/styles'
import {AppBarBack} from '../NavAppBar/NavAppBar.styles'
import isEmail from 'validator/lib/isEmail'
import {Link} from 'react-router-dom'
import {validPassword, invalidPassMsg} from '@services/utils'

const NewAccountUser = () => {
    const [company, setCompany] = useState('')
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [confirm, setConfirm] = useState('')
    const [noMatch, setNoMatch] = useState(false)
    const [agree, setAgree] = useState(false)

    const sendRequest = () => {
        if (!isEmail(email.trim())) {
            Swal.fire({text: 'Please enter a valid email', icon: 'error'})
            return
        }
        if (pass !== confirm) {
            Swal.fire({
                text: 'Password does not match confirm',
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
            return
        }
        if (!validPassword(pass)) {
            Swal.fire({
                text: invalidPassMsg,
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
            return
        }

        if (firstName.length < 2 || lastName.length < 2 || company.length < 2) {
            Swal.fire({
                text: 'Name/Company must be at least 2 charecters',
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
            return
        }
        if (
            firstName.length > 40 ||
            lastName.length > 40 ||
            company.length > 40
        ) {
            Swal.fire({
                text: 'Name/Company can be up to 40 charecters',
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
            return
        }
        if (!agree) {
            Swal.fire({
                text: 'Please accept the terms of service before resignation',
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
            return
        }

        api.registerAccountAndUser(
            company,
            email,
            firstName,
            lastName,
            pass,
        ).subscribe(
            (response) => {
                console.log('register response', response)
                Swal.fire({
                    text: 'Registration request received. Please check your email to complete the process.',
                    confirmButtonColor: AppColors.TIPIGO_GREEN,
                })
            },
            (error) => {
                console.log('register error', error)
                const txt =
                    error.data && error.data.msg
                        ? error.data.msg
                        : error.data && error.data.res_code
                        ? error.data.res_code
                        : ''
                Swal.fire({
                    text: 'Resgistration error. ' + txt,
                    icon: 'error',
                    confirmButtonColor: AppColors.TIPIGO_GREEN,
                })
            },
        )
    }

    useEffect(() => {
        setNoMatch(pass.length > 0 && confirm.length > 1 && pass != confirm)
    }, [pass, confirm])

    return (
        <Container>
            {/* <OverrideBg /> */}
            <Header />
            <AppBar
                style={{
                    height: Dimensions.APP_BAR_HEIGHT,
                    backgroundColor: AppColors.SUB_HEAD_GREY,
                    position: 'fixed',
                    zIndex: 10,
                    top: Dimensions.NAVBAR_TOP_OFFSET,
                    alignItems: 'center', // #stupidhead
                }}
                iconStyleLeft={{marginTop: 0}}
                iconElementLeft={
                    <AppBarBack onClick={() => (window.location = '/#/')} />
                }
            />
            <SideDiv />
            <MainDiv>
                <Title text={'Create User'} style={{alignSelf: 'flex-start'}} />
                {/* TODO: side by side or rows..? see later when we complete fields */}
                <Row style={{marginTop: 20}}>
                    <FrameInput
                        title="Company/Account name"
                        value={company}
                        setValue={setCompany}
                        style={{width: 275}}
                    />
                    <FrameInput
                        title="Email"
                        value={email}
                        setValue={setEmail}
                        style={{width: 275}}
                    />
                </Row>
                <Row>
                    <FrameInput
                        title="First Name"
                        value={firstName}
                        setValue={setFirstName}
                        style={{width: 275}}
                    />
                    <FrameInput
                        title="Last Name"
                        value={lastName}
                        setValue={setLastName}
                        style={{width: 275}}
                    />
                </Row>
                <Row>
                    <FrameInput
                        autoComplete="new-password"
                        title="Password"
                        value={pass}
                        password
                        setValue={setPass}
                        maxLength={20}
                        style={{width: 275}}
                    />
                    <FrameInput
                        autoComplete="new-password"
                        title="Confirm Password"
                        value={confirm}
                        password
                        setValue={setConfirm}
                        maxLength={20}
                        style={{width: 275}}
                    />
                </Row>
                <Row>
                    <NoMatch>
                        {noMatch ? 'Passwords do not match' : ' '}
                    </NoMatch>
                </Row>
                <Row>
                    <AgreeCont>
                        <Checkbox
                            checked={agree}
                            onChange={(e) => setAgree(e.target.checked)}
                        />
                        I Agree to{' '}
                        <AgreeLink to={'/terms'} target={'blank'}>
                            Terms of Service
                        </AgreeLink>
                    </AgreeCont>
                    <SendButton onClick={sendRequest} style={{width: 275}}>
                        Submit
                    </SendButton>
                </Row>
            </MainDiv>
            <SideDiv />
        </Container>
    )
}

export default NewAccountUser

const Container = styled.div`
    margin-top: ${50 +
    Dimensions.APP_BAR_HEIGHT +
    Dimensions.NAVBAR_TOP_OFFSET}px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
`
const SideDiv = styled.div`
    display: flex;
    flex: 1;
`
const MainDiv = styled.div`
    display: flex;
    flex: 1;
    background-color: #fdfdff;
    flex-direction: column;
    padding: 40px;
    justify-content: center;
    align-items: center;
    border: 0.5px solid ${AppColors.APP_GRAY_LIGHT};
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.16);
    border-radius: 7px;
`
const Row = styled.div`
    display: flex;
    flex: 1;
    border: 0px solid green;
    width: 600px;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 12px;
`

const NoMatch = styled.div`
    font-size: 11px;
    color: #f44336;
    height: 18px;
`
const AgreeCont = styled.div`
    font-size: 14px;
`
const AgreeLink = styled(Link)`
    color: ${AppColors.TIPIGO_GREEN};
`
const Checkbox = styled.input.attrs({type: 'checkbox'})`
    vertical-align: middle;
    margin-right: 9px;
    margin-top: 1px;
    width: 16px;
    height: 16px;
`
