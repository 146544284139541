import React, {Component} from 'react'
import Paper from 'material-ui/Paper'
import ReactTooltip from 'react-tooltip'
import _ from 'lodash'
import swal from 'sweetalert2'
import RangeSelector1 from '@components/General/RangeSelector1'
import CheckboxList from '@components/General/CheckboxList'
import RadioButtonList from '@components/General/RadioButtonList'
import YesNoToggle from '@components/General/YesNoToggle'
import SimpleFloatInput from '@components/General/SimpleFloatInput'
import {red400} from 'material-ui/styles/colors'
import {DeleteOutline, CopyAll, HelpOutline} from '@mui/icons-material'
import {Indexes, ScreenersInfo} from '../../constants/Finance'
import styled from 'styled-components'
import {useState, useEffect, useUser} from '@services/hooks'
import {AppColors} from '@services/styles'

const Screener1 = ({
    screenerType,
    onScreenerChange,
    matchingStocks,
    applyAcrossAllSectors,
    data,
    removeScreener,
    currentSector,
}) => {
    const [secondaryTitle, setSecondaryTitle] = useState('')
    const [title, setTitle] = useState('Unknown Filter')

    useEffect(() => {
        if (screenerType === 'market_val') {
            setSecondaryTitle('(in millions)')
        }

        if (ScreenersInfo[screenerType] && ScreenersInfo[screenerType].title) {
            setTitle(ScreenersInfo[screenerType].title)
        }
    }, [])

    const handleChange = (value, a, b) => {
        console.log('screener change', value, a, b)
        onScreenerChange(screenerType, value, a, b)
    }

    const getByType = () => {
        if (currentSector == 'Materials')
            console.log('getByType', screenerType, data.value.minVal) // data here "seems" to be correct but display not

        if (
            ['market_val', 'beta', 'last_price', 'avg_vol_20d'].indexOf(
                screenerType,
            ) > -1
        ) {
            return (
                <RangeSelector1
                    currentScreener={screenerType}
                    currentSector={currentSector}
                    value1={data.value.minVal}
                    value2={data.value.maxVal}
                    onChange={handleChange}
                />
            )
        } else if (screenerType === 'peg_ratio') {
            const options = [
                {
                    type: 'lt_1',
                    title: '< 1',
                },
                {
                    type: '1_to_2',
                    title: '1 to 2',
                },
                {
                    type: '2_to_3',
                    title: '2 to 3',
                },
                {
                    type: 'gt_3',
                    title: '> 3',
                },
            ]

            return (
                <CheckboxList
                    values={options}
                    selected={_.isArray(data.value) ? data.value : [data.value]}
                    onChange={handleChange}
                />
            )
        } else if (screenerType === 'pe_ratio_12m') {
            const options = [
                {
                    type: '0_to_20',
                    title: '0 to 20',
                },
                {
                    type: '20_to_50',
                    title: '20 to 50',
                },
                {
                    type: '50_to_80',
                    title: '50 to 80',
                },
                {
                    type: 'gt_80',
                    title: '> 80',
                },
            ]

            return (
                <CheckboxList
                    values={options}
                    selected={_.isArray(data.value) ? data.value : [data.value]}
                    onChange={handleChange}
                />
            )
        } else if (screenerType === 'dividend_yield') {
            const options = [
                {
                    type: 'lt_2',
                    title: '< 2%',
                },
                {
                    type: '2_to_5',
                    title: '2% to 5%',
                },
                {
                    type: '5_to_8',
                    title: '5% to 8%',
                },
                {
                    type: '8_to_10',
                    title: '8% to 10%',
                },
                {
                    type: 'gt_10',
                    title: '> 10%',
                },
            ]

            return (
                <CheckboxList
                    values={options}
                    selected={_.isArray(data.value) ? data.value : [data.value]}
                    onChange={handleChange}
                />
            )
        } else if (screenerType === 'indexes') {
            const v =
                data.value && _.isArray(data.value) && data.value.length > 0
                    ? data.value
                    : []

            const indexes = Indexes.map((index) =>
                Object.assign({}, index, {
                    checked: v.indexOf(index.type) > -1,
                }),
            )

            return (
                <RadioButtonList
                    values={indexes}
                    selected={data.value}
                    onChange={handleChange}
                />
            )
        } else if (screenerType === 'moving_ave_50') {
            return <YesNoToggle value={data.value} onToggle={handleChange} />
        } else if (screenerType === 'upside') {
            return (
                <SimpleFloatInput
                    value={data.value}
                    onChange={handleChange}
                    showPrecentage={true}
                />
            )
        } else {
            return null
        }
    }

    return (
        <div style={{display: 'flex'}}>
            <ReactTooltip />
            <Paper
                style={{
                    padding: 10,
                    display: 'flex',
                    flex: 1,
                    // height: 65,
                    alignItems: 'center',
                    marginBottom: 15,
                }}>
                <h4
                    style={{
                        margin: 10,
                        width: 130,
                        position: 'relative',
                        lineHeight: 1.2,
                    }}>
                    {title}
                    {secondaryTitle ? (
                        <span>
                            <br />
                            <small style={{color: '#666', fontWeight: 300}}>
                                {secondaryTitle}
                            </small>
                        </span>
                    ) : null}

                    {ScreenersInfo[screenerType] &&
                    ScreenersInfo[screenerType].description ? (
                        <span>
                            <HelpOutline
                                style={{
                                    color: '#aaa',
                                    cursor: 'pointer',
                                    marginLeft: 12,
                                    position: 'absolute',
                                    top: 0,
                                    fontSize: 18,
                                }}
                                data-tip={
                                    ScreenersInfo[screenerType].description
                                }
                            />
                        </span>
                    ) : null}
                </h4>

                <div style={{flex: 1, padding: 10}}>{getByType()}</div>

                <div
                    style={{
                        display: 'flex',
                        marginRight: 20,
                        minWidth: 135,
                    }}>
                    <Matching>
                        Matching
                        <br />
                        stocks
                    </Matching>
                    <MatchingNum>{matchingStocks || 0}</MatchingNum>
                </div>

                <CopyAll
                    data-tip="Apply across all sectors"
                    onClick={applyAcrossAllSectors}
                />

                <DeleteFilter
                    onClick={() => {
                        swal.fire({
                            title: 'Remove this screener?',
                            text: 'You will lose all current settings for this screener',
                            reverseButtons: 'true',
                            showCancelButton: true,
                            confirmButtonColor: AppColors.TIPIGO_GREEN,
                            confirmButtonText: 'Yes, remove it!',
                        }).then(
                            (response) => {
                                console.log('remove1')
                                if (response && response.isConfirmed) {
                                    console.log('remove2')
                                    removeScreener(screenerType)
                                }
                            },
                            (error) => console.log('cancelled'),
                        )
                    }}
                    sx={{color: red400}}
                />
            </Paper>
        </div>
    )
}

export default Screener1

const DeleteFilter = styled(DeleteOutline)`
    margin-left: 10px;
    cursor: pointer;
`

const Matching = styled.div`
    text-align: right;
    line-height: 1px
    margin: 12px;
    border-right: 1px solid  #949494;
    padding-right: 6px;
    margin-right: 5px
    color: #949494;
    font-size: 12px;
`
const MatchingNum = styled.h3`
    font-size: 24px;
    margin: 0px
    margin-top: 14px
    font-weight: 200
    color: #3b3b3b;
`
