import React from 'react'
import {AppColors} from '@services/styles'
import {Link} from 'react-router-dom'

import BirdBg from '@images/bird-ai.png'

const Terms = () => {
    return (
        <div
            style={{
                backgroundImage: `url(${BirdBg})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center',
                backgroundSize: '50%',
            }}>
            <div
                style={{
                    backgroundColor: 'rgba(255,255,255,.7)',
                    padding: 50,
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: 80,
                    flexDirection: 'column',
                }}>
                <div>
                    <div
                        style={{
                            backgroundColor: AppColors.TIPIGO_GREEN,
                            height: 5,
                            width: '100%',
                        }}
                    />
                    <h1>
                        <strong>
                            Tipigo Terms and Conditions of use and service
                        </strong>
                    </h1>
                    <p>
                        By signing in to Tipigo's you confirm that you read and
                        agree to the terms and condition of use and service and
                        that your age is above 18 years old.
                    </p>
                    <p>
                        tipigo.com provides information, which you may choose to
                        use in making your own investment decisions
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Be aware</strong>:&nbsp;{' '}
                        <strong>
                            Past performance is no guarantee of future results.
                            Inherent in any investment is the potential for
                            loss.
                        </strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Tipigo providing the information under "Terms and
                        Conditions of Service" .
                    </p>
                    <p>
                        All the materials provided by Tipigo are for
                        informational purposes only and nothing herein
                        constitutes investment, legal, accounting or tax advice,
                        or a recommendation to buy, sell or hold a security.
                    </p>
                    <p>
                        All information published in www.tipigo.com is current
                        as of the date of herein and is subject to change
                        without notice. Tipigo is not a licensed securities
                        dealer, broker or US investment adviser or investment
                        bank.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Terms and Conditions of Service:</strong>
                        &nbsp;You represent that you have read and agree to be
                        bound by the terms and conditions of service ("terms")
                        for tipigo.com. You further agree to comply with your
                        local law regarding the transmission of any data
                        obtained from the Service (as defined herein) in
                        accordance with the terms, not to use the Service for
                        illegal purposes, and not to interfere or disrupt
                        networks connected to the Service.
                    </p>
                    <p>
                        For services, paid and free, Tipigo requires
                        registration. The information is required for account
                        set-up and to enable us to provide the maximum quality
                        of service.
                    </p>
                    <p>
                        Member contact information is used to send information
                        about our company and promotional materials sent from
                        Tipigo. Please note that you can choose to "opt-out" of
                        the list at any time.
                    </p>
                    <p>
                        <strong>Intellectual Property:</strong>&nbsp;This
                        service, including but not limited to text, content,
                        photographs, video, audio, and graphics (the "service"),
                        is protected by copyrights, trademarks, service marks,
                        international treaties, and/or other proprietary rights
                        and laws of the U.S. and other countries. The Service is
                        also protected as a collective work or compilation under
                        U.S. copyright and other laws and treaties. You agree to
                        abide by all additional copyright notices or
                        restrictions contained in the Service.
                    </p>
                    <p>
                        <strong>Restrictions on Use: </strong>You may not use
                        the Tipigo's services for any illegal purpose or in any
                        manner inconsistent with the terms. You agree to use the
                        Service solely for your own noncommercial use and
                        benefit, and not for resale or other transfer or
                        disposition to, or use by or for the benefit of, any
                        other person or entity.
                    </p>
                    <p>
                        You agree not to use, transfer, distribute, or dispose
                        of any information contained in Tipigo in any manner
                        that could compete with the business of Tipigo. You
                        acknowledge that the Service has been developed,
                        compiled, prepared, revised, selected and arranged by
                        Tipigo and others (including certain other information
                        sources) through the application of methods and
                        standards of judgment developed and applied through the
                        expenditure of substantial time, effort and money and
                        constitutes valuable intellectual property and trade
                        secrets of Tipigo and such others. You agree to protect
                        the proprietary rights of Tipigo and all others having
                        rights in the Service during and after the term of this
                        agreement and to comply with all reasonable written
                        requests made by Tipigo or its suppliers of content,
                        equipment or otherwise ("Suppliers") to protect their
                        and others' contractual, statutory and common law rights
                        in the Service. You agree to notify Tipigo in writing
                        promptly upon becoming aware of any unauthorized access
                        or use of the Service by any party or of any claim that
                        the Service infringes upon any copyright, trademark, or
                        other contractual, statutory or common law rights.
                    </p>
                    <p>
                        You may not copy, reproduce, recompile, decompile,
                        reverse engineer, distribute, publish, display, modify,
                        transmit, or in any way exploit any part of the service,
                        except that you may download or make one print copy of
                        this service for your own personal, non-commercial use,
                        provided that all copyright and other proprietary
                        notices are retained. The analysis and data included in
                        tipigo.com may not be recirculated, redistributed or
                        published without prior written consent from Tipigo.
                        Modification of the service's content is a violation of
                        Tipigo's copyright. No part of this service may be
                        offered for sale or distributed.
                    </p>
                    <p>
                        <strong>License:</strong>&nbsp;You acquire absolutely no
                        rights or licenses in or to the Service and materials
                        contained therein other than the limited right to
                        utilize the Service in accordance with the terms. Should
                        you choose to download content from the Service, you
                        must do so in accordance with the terms. Such download
                        is licensed to you by Tipigo only for your own personal,
                        noncommercial use in accordance with the terms and does
                        not transfer any other rights to you.
                    </p>
                    <p>
                        <strong>Rights Reserved:</strong>&nbsp;All present and
                        future rights in and to trade secrets, patents,
                        copyrights, trademarks, service marks, know-how and
                        other proprietary rights of any type under the laws of
                        any governmental authority, domestic or foreign,
                        including rights in and to all applications and
                        registrations relating to the Service (the "Intellectual
                        Property Rights") shall, as between you and Tipigo, at
                        all times be and remain the sole and exclusive property
                        of Tipigo. All present and future rights in and title to
                        the Service (including the right to exploit the Service
                        and any portions of the Service over any present or
                        future technology) are reserved to Tipigo for its
                        exclusive use. Except as specifically permitted by the
                        terms, you may not copy or make any use of the Service
                        or any portion thereof. Except as specifically permitted
                        herein, you shall not use the trademarks, trade names,
                        service marks, trade dress, logos or titles of Tipigo or
                        the Service, or the names of any individual participant
                        in, or contributor to, the Service, or any variations or
                        derivatives thereof, for any purpose, without Tipigo
                        prior written approval.
                    </p>
                    <p>
                        <strong>Disclaimer and Limitation of Liability:</strong>
                        &nbsp;You agree that your use of the Service is at your
                        sole risk and acknowledge that the Service and anything
                        contained therein, including, but not limited to,
                        content, services, goods or advertisements (the "Items")
                        are provided "AS IS" and that Tipigo makes no warranty
                        of any kind, express or implied, as to the Items,
                        including, but not limited to, merchantability,
                        noninfringement, title or fitness for a particular
                        purpose or use. Tipigo does not warrant that the Service
                        is compatible with your equipment or that the Service is
                        free of errors or viruses and is not liable for any
                        damage you may suffer as a result of such destructive
                        features. You agree that Tipigo, its Suppliers and its
                        third-party agents shall have no responsibility or
                        liability for any injury or damages, whether caused by
                        the negligence of Tipigo, its employees, subcontractors,
                        agents, Suppliers or otherwise arising in connection
                        with the Service and shall not be liable for any lost
                        profits, losses, punitive, incidental or consequential
                        damages or any claim against Tipigo by any other party
                        or any fault, inaccuracy, omission, delay or any other
                        failure in the Service caused by your computer equipment
                        or arising from your use of the Service on such
                        equipment.
                    </p>
                    <p>
                        The content of other Web sites, services, resources,
                        goods or advertisements (including those for which
                        Tipigo may receive compensation) that may be linked to
                        the Service is not maintained or controlled by Tipigo.
                        Tipigo is therefore not responsible for the
                        availability, content or accuracy of other Web sites,
                        services, resources or goods that may be linked to, or
                        advertised on, the Service. Tipigo does not make any
                        warranty, express or implied, with respect to the use of
                        the links provided on the Service, guarantee the
                        accuracy, completeness, usefulness or adequacy of any
                        other Web sites, services, resources, goods or
                        advertisements that may be linked to the Service or make
                        any endorsement, express or implied, of any other Web
                        sites, services, resources, goods or advertisements that
                        may be linked to the Service. Tipigo is also not
                        responsible for the reliability or continued
                        availability of the telephone lines and equipment you
                        use to access the Service. You understand that Tipigo
                        and/or third-party contributors to the Service may
                        choose at any time to inhibit or prohibit their content
                        from being accessed under the terms. You acknowledge
                        that the service is provided for information purposes
                        only and is not intended for trading purposes, the
                        service includes information taken from the New York
                        Stock Exchange, American Stock Exchange, NASDAQ and
                        other sources, Tipigos does not guarantee the sequence,
                        accuracy, completeness or timeliness of the service and
                        the provision of certain parts of the service is subject
                        to the terms and conditions of other agreements to which
                        Tipigo is a party. Accordingly, anything to the contrary
                        herein set forth notwithstanding, Tipigo, its officers
                        and employees, general partner, affiliates,
                        subsidiaries, successors and assigns, Suppliers and its
                        third-party agents shall not, directly or indirectly, be
                        liable, in any way, to you or any other person for any
                        inaccuracies or errors in or omissions from the Service
                        including, but not limited to, quotes and financial
                        data, abstracts, delays, errors or interruptions in the
                        transmission or delivery of the Service or any loss or
                        damage arising therefrom or occasioned thereby, or by
                        any reason of nonperformance.
                    </p>
                    <p>
                        <strong>Limitation of Liability:</strong>&nbsp;UNDER NO
                        CIRCUMSTANCES, INCLUDING BUT NOT LIMITED TO NEGLIGENCE,
                        SHALL TIPIGO, ITS SUPPLIERS AND ITS THIRD PARTY AGENTS
                        BE LIABLE TO YOU FOR DIRECT, INDIRECT, INCIDENTAL,
                        CONSEQUENTIAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES
                        EVEN IF A TIPIGO AUTHORIZED REPRESENTATIVE HAS BEEN
                        ADVISED SPECIFICALLY OF THE POSSIBILITY OF SUCH DAMAGES,
                        ARISING FROM USE OF OR INABILITY TO USE THE SERVICE OR
                        ANY PROVISION OF THE TERMS, SUCH AS, BUT NOT LIMITED TO,
                        LOSS OF REVENUE OR ANTICIPATED PROFITS OR LOST BUSINESS.
                        Applicable law may not allow the limitation or exclusion
                        of liability or incidental or consequential damages. In
                        no event shall Tipigo total liability to you for all
                        damages, losses, and causes and action (whether in
                        contract or tort, including but not limited to
                        negligence) exceed the amount paid by you, if any, for
                        accessing this site.
                    </p>
                    <p>
                        <strong>Indemnification:</strong>&nbsp;You agree, at
                        your own expense, to indemnify, defend, and hold
                        harmless Tipigo, and its employees, representatives,
                        Suppliers, and agents, against any claim, suit, action,
                        or other proceeding brought against Tipigo, its
                        employees, representatives, Suppliers, and agents, by a
                        third party, to the extent that such claim, suit, action
                        or other proceeding brought against Tipigo, its
                        employees, representatives, Suppliers, and agents is
                        based on or arises in connection with the Service,
                        including but not limited to your use or someone using
                        your computer's use of the Service; a violation of the
                        terms by you or anyone using your computer; a claim that
                        any use of the Service by you or someone using your
                        computer infringes any Intellectual Property Right (as
                        herein defined) of any third party, or any right of
                        personality or publicity, is libelous or defamatory, or
                        otherwise results in injury or damage to any third
                        party; any deletions, additions, insertions, or
                        alterations to, or any unauthorized use of, the Service
                        by you or someone using your computer; any
                        misrepresentation or breach of representation or
                        warranty made by you contained herein; or any breach of
                        any covenant or agreement to be performed by you
                        hereunder. You agree to pay any and all costs, damages,
                        and expenses, including, but not limited to, reasonable
                        attorneys' fees and costs awarded against or otherwise
                        incurred by or in connection with or arising from any
                        such claim, suit, action or proceeding attributable to
                        any such claim.
                    </p>
                    <p>
                        <strong>Termination of Terms:</strong>&nbsp;Either you
                        or Tipigo may terminate the terms at will, at any time
                        and effective immediately. You may terminate the terms
                        by discontinuing use of the Service and destroying all
                        materials obtained from the Service. These terms will
                        terminate immediately without notice from Tipigo if you,
                        in Tipigo sole discretion, fail to comply with any
                        provision of these terms. Upon termination by you or
                        upon notice of termination by Tipigo, you must destroy
                        promptly all materials obtained from the Service and any
                        copies thereof.
                    </p>
                    <p>
                        <strong>
                            Governing Law: This Agreement shall be governed and
                            construed in accordance with the laws of Israel,
                            without giving effect to conflicts of law principles
                            thereof. You agree to submit to the personal
                            jurisdiction of the State of ISRAEL courts, located
                            in Tell-Aviv&nbsp; with respect to any legal
                            proceedings that may arise in connection with the
                            Service or from a dispute as to the interpretation
                            or breach of the terms of service.
                        </strong>
                    </p>
                    <p>
                        <strong>Accessing tipig.com:</strong>&nbsp;Tipigo does
                        not represent that materials in the service are
                        appropriate or available for use in any countries. If
                        you choose to access the service, you are responsible
                        for compliance with foreign and local laws. Never the
                        less, Software is further subject to United States
                        export controls and may not be downloaded, exported, or
                        reexported into (or to a national or resident of) Cuba,
                        Iraq, Libya, North Korea, Iran, or Syria, Sudan, or any
                        other country to which the U.S. has embargoed goods or
                        to anyone on the U.S. Treasury Department's Table of
                        Deny Orders. By using the Service, you represent and
                        warrant that you are not located in, controlled by, or a
                        national or resident of any such country or on any such
                        list.
                    </p>
                    <p>
                        <strong>Miscellaneous:</strong>&nbsp;You accept that
                        Tipigo has the right to change the content or
                        functionality of any aspect of the Service at any time
                        at Tipigo's sole discretion. You further accept that
                        such changes may result in your being unable to access
                        some or all services.
                    </p>
                    <p>
                        <strong>Severability of terms:</strong>&nbsp;If any
                        provision of the terms is found invalid or
                        unenforceable, that provision will be enforced to the
                        maximum extent, and the other provisions of this
                        Agreement will remain in force.
                    </p>
                    <p>
                        <strong>Agreement in entirety:</strong>&nbsp;The terms
                        are the entire agreement between you and Tipigo.
                    </p>
                </div>
                <div>
                    <h1>
                        <strong>End-User License Agreement (EULA)</strong>
                    </h1>
                    <p>
                        This End-User License Agreement ("EULA") is a legal
                        agreement between you ("User" or "You") and Tipigo
                        Ventures Ltd. ("Tipigo" or "Licensor") for the Tipigo
                        Insight System service product ("service").
                    </p>
                    <p>
                        By accessing, downloading, copying, or otherwise using
                        the service, You agree to be bound by the terms of this
                        EULA. If You do not agree to the terms of this EULA, You
                        are not authorized to use the service.
                    </p>

                    <p>
                        <strong>1. Grant of License</strong>
                        <br />
                        Subject to the terms and conditions of this EULA, Tipigo
                        grants You a limited, non-exclusive, non-transferable,
                        and revocable license to use the service for Your
                        personal or internal business purposes.
                    </p>
                    <p>
                        <strong>2. Restrictions</strong>
                        <br />
                        You may not, and You may not permit others to:
                        <ul>
                            <li>
                                Modify, adapt, translate, reverse engineer,
                                decompile, disassemble, or create derivative
                                works based on the service;
                            </li>
                            <li>
                                Rent, lease, lend, sell, redistribute,
                                sublicense, or otherwise transfer rights to the
                                service;
                            </li>
                            <li>
                                Use the service in any manner that violates any
                                applicable laws or regulations;
                            </li>
                            <li>
                                Remove or alter any proprietary notices or
                                labels on the service.
                            </li>
                        </ul>
                    </p>
                    <p>
                        <strong>3. Ownership</strong>
                        <br />
                        The service is licensed, not sold, to You. Tipigo and
                        its licensors retain all right, title, and interest in
                        and to the service, including all intellectual property
                        rights therein. This EULA does not convey to You any
                        rights of ownership in or related to the service.
                    </p>
                    <p>
                        <strong>4. Warranty Disclaimer</strong>
                        <br />
                        THE service IS PROVIDED "AS IS" AND WITHOUT WARRANTY OF
                        ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
                        LAW, TIPIGO DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS,
                        IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT
                        LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                    </p>
                    <p>
                        <strong>5. Limitation of Liability</strong>
                        <br />
                        IN NO EVENT SHALL TIPIGO BE LIABLE FOR ANY DIRECT,
                        INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR
                        CONSEQUENTIAL DAMAGES (INCLUDING BUT NOT LIMITED TO
                        PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF
                        USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) ARISING
                        OUT OF OR IN ANY WAY RELATED TO THE USE OR INABILITY TO
                        USE THE service, EVEN IF ADVISED OF THE POSSIBILITY OF
                        SUCH DAMAGES.
                    </p>

                    <p>
                        <strong>6. Termination</strong>
                        <br />
                        This EULA is effective until terminated by either party.
                        Tipigo may terminate this EULA at any time if You fail
                        to comply with any term or condition of this EULA. Upon
                        termination, You shall cease all use of the service and
                        destroy all copies of the service in Your possession or
                        control.
                    </p>
                    <p>
                        <strong>7. Governing Law</strong>
                        <br />
                        This EULA shall be governed by and construed in
                        accordance with the laws of [Jurisdiction], without
                        regard to its conflict of law provisions.
                    </p>
                    <p>
                        <strong>8. Entire Agreement</strong>
                        <br />
                        This EULA constitutes the entire agreement between the
                        parties with respect to the subject matter hereof and
                        supersedes all prior and contemporaneous agreements and
                        understandings, whether written or oral, relating to
                        such subject matter.
                    </p>
                    <p>
                        <strong>9. Contact Information</strong>
                        <br />
                        If You have any questions about this EULA, You may
                        contact Tipigo at{' '}
                        <Link
                            to="#"
                            onClick={(e) => {
                                window.location.href =
                                    'mailto:support@tipigo.com'
                            }}>
                            support@tipigo.com
                        </Link>
                        .<br />
                        By accepting this EULA or by installing, downloading,
                        copying, accessing, or otherwise using the service, You
                        acknowledge that You have read, understood, and agree to
                        be bound by the terms and conditions of this EULA.
                    </p>
                </div>
                <div>
                    <Link to={'/login'}>Back to login</Link>
                </div>
            </div>
        </div>
    )
}

export default Terms
