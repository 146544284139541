/**
 * Configuration file to the codelovers react app.
 *
 * IMPORTANT: this file is referenced by several of the core codelovers
 * modules and should not be removed. All of the flags below are required
 * for proper operation of the libraries.
 */

const selectedEnv =
    window.location.host === 'app.tipigo.com' ||
    window.location.host === 'insight.tipigo.com' ||
    window.location.host === 'localhost:3000' // remove --not if you want local as prod
        ? 'production'
        : 'development' //'production' //'development'

const Config = {
    PLATFORM: 'reactjs',

    STORAGE: null, // Use default localStorage

    /**
     * Defined the available app environments
     * @type {Object}
     */
    AVAILABLE_ENVIRONMENTS: {
        localhost: 'tipigo.local',
        development: 'dev.tipigo.com', //'app.tipigo.com' 'dev.tipigo.com'
        production: 'app.tipigo.com',
        charles: 'localhost.charlesproxy.com',
    },

    /**
     * Define the selected environment
     * e.g.'localhost' | 'development' | 'production'
     * @type {String}
     */
    SELECTED_ENV: selectedEnv,

    /**
     * Set to 'true' if you want to cache the cl-api responses
     * @type {Boolean}
     */
    CACHE_API_RESPONSES: false,

    /**
     * Set to 'true' if you want to use SSL/HTTPS
     * @type {Boolean}
     */
    USE_HTTPS: true, // 09.08.23 not sure about it... selectedEnv === 'development' ? false : true,

    /**
     * Set production log level
     *  'none' - don't show logs in production
     *  'low' - show only errors and warnings
     *  'high' - show all log levels
     * @type {String}
     */

    PRODUCTION_LOG_LEVEL: 'high', // TODO: ron 'none',

    SEGMENTIO: {
        key: 'vqnef7y8sm',
    },
}

// logs:

if (Config.SELECTED_ENV === 'production') {
    switch (Config.PRODUCTION_LOG_LEVEL) {
        case 'none':
            console.debug =
                console.info =
                console.log =
                console.warn =
                console.error =
                    () => {}
            break

        case 'low':
            console.debug = console.info = console.log = () => {}
            break

        case 'high':
            break

        default:
            break
    }
}

module.exports = Config
