import React, {useMemo} from 'react'
import {api} from '@services/api'
import {useState, useEffect, useUser} from '@services/hooks'
import SelectSectors from '@components/Reports/SelectSectors.jsx'
import FrameSelect from '@components/General/FrameSelect'
import FrameInput from '@components/General/FrameInput'
import RangeFilter from '@components/Reports/Controls/RangeFilter'
import styled from 'styled-components'
import FormControlLabel from '@mui/material/FormControlLabel'
import {Checkbox} from '@components/General/Elements'
import ReactTooltip from 'react-tooltip'

// import {formatUniverseResponse} from '@services/utils'

const ScreenerSelector = ({
    mainColWidth,
    wideColWidth,
    tickerSelectionParams, // TODO: change name as it includes also reportParams
    setTickerSelectionParams,
    onlyUniverse,
    useUniverse,
    useSectors,
    useScreeners,
}) => {
    // TODO: valid and format all user inputs to valid integers or floats
    const user = useUser()
    const [customIndexes, setCustomIndexes] = useState(null)
    const [checkboxes, setCheckboxes] = useState({
        daysToEarning: false,
        minEps: false,
        minRoe: false,
        minUpside: false,
        filterAdr: false,
        removeIfAnyShort: false,
        excludeNoRatings: false,
    })

    useEffect(() => {
        useUniverse && setCustomIndexes(user.universeList || [])
    }, [user])

    useEffect(() => {
        if (customIndexes && customIndexes.length > 0) {
            const sp = customIndexes.find((item) =>
                item.label.includes('S&P500'),
            )

            setTickerSelectionParams({
                universe: sp ? sp.value : '',
                universeName: sp ? sp.label : null,
                marketCapCond: '0',
                marketCapValue1: 0,
                marketCapValue2: 0,
                betaCond: '0',
                betaValue1: 0,
                betaValue2: 0,
                sector: [],
                daysToEarning: null,
                minEps: null,
                minRoe: null,
                minUpside: null,
                filterAdr: false,
                removeIfAnyShort: false,
                excludeNoRatings: false,
            })
        }
    }, [customIndexes])

    const universes = useMemo(() => {
        return customIndexes && customIndexes.length > 0
            ? [
                  {
                      value: 'all',
                      label: 'All stocks. No filter',
                  },
                  ...customIndexes,
              ]
            : [
                  {
                      value: 'all',
                      label: 'All stocks. No filter',
                  },
              ]
    }, [customIndexes])

    const updateTickerSelectionParams = (field) => (value) => {
        console.log('updateTickerSelectionParams', field, value)
        setTickerSelectionParams({...tickerSelectionParams, [field]: value})
    }

    const updateUniverse = (universe) => {
        const fullUniverse = universes.find((u) => u.value === universe)

        console.log('update universe', universes, universe, fullUniverse)
        setTickerSelectionParams({
            ...tickerSelectionParams,
            universe,
            universeName: fullUniverse?.label,
        })
    }

    const {
        universe,
        sector,
        marketCapCond,
        marketCapValue1,
        marketCapValue2,
        betaCond,
        betaValue1,
        betaValue2,
        daysToEarning,
        minEps,
        minRoe,
        minUpside,
        filterAdr,
        removeIfAnyShort,
        excludeNoRatings,
    } = tickerSelectionParams

    useEffect(() => {
        const {
            daysToEarning,
            minEps,
            minRoe,
            minUpside,
            filterAdr,
            removeIfAnyShort,
            excludeNoRatings,
        } = tickerSelectionParams
        setCheckboxes({
            daysToEarning: daysToEarning ? true : false,
            minEps: minEps || minEps === 0,
            minRoe: minRoe || minRoe === 0,
            minUpside: minUpside || minUpside === 0,
            filterAdr: filterAdr ? true : false,
            removeIfAnyShort: removeIfAnyShort ? true : false,
            excludeNoRatings: excludeNoRatings ? true : false,
        })
    }, [tickerSelectionParams])

    const getFilterRow = (
        title,
        field,
        defaultValue,
        value,
        placeholder,
        checkOnly,
        tooltip,
        marginTop,
    ) => {
        if (!marginTop) marginTop = checkOnly ? 32 : 10
        return (
            <FlexRow width={mainColWidth} marginTop={marginTop}>
                <FormControlLabel
                    label={
                        <div
                            data-tip={tooltip}
                            data-for="screeners-tt"
                            style={{fontSize: 15}}>
                            {title}{' '}
                        </div>
                    }
                    sx={{fontSize: '17px'}}
                    control={
                        <Checkbox
                            checked={checkboxes[field]}
                            onChange={(e) => {
                                setCheckboxes({
                                    ...checkboxes,
                                    [field]: e.target.checked,
                                })
                                if (e.target.checked)
                                    updateTickerSelectionParams(field)(
                                        checkOnly && defaultValue !== 0
                                            ? true
                                            : defaultValue,
                                    )
                                else
                                    updateTickerSelectionParams(field)(
                                        checkOnly && defaultValue !== 0
                                            ? false
                                            : '',
                                    )
                            }}
                        />
                    }
                />
                {!checkOnly && (
                    <FrameInput
                        value={value}
                        setValue={updateTickerSelectionParams(field)}
                        placeholder={placeholder}
                        disabled={!checkboxes[field]}
                        frameStyle={{height: 23}}
                    />
                )}
            </FlexRow>
        )
    }

    return (
        <Container>
            <ReactTooltip id="screeners-tt" />
            {useUniverse && (
                <Row width={mainColWidth}>
                    {customIndexes?.length && (
                        <FrameSelect
                            value={universe}
                            values={universes}
                            setValue={updateUniverse}
                            // setLabel={updateTickerSelectionParams('universeName')}
                            title={'Select Universe'}
                        />
                    )}
                </Row>
            )}

            {useSectors && (
                <Row>
                    <SelectSectors
                        width={wideColWidth}
                        //user={user}
                        sectors={sector || []}
                        setSectors={updateTickerSelectionParams('sector')}
                        type={'sector'}
                    />
                </Row>
            )}

            {useScreeners && (
                <>
                    <FlexRow width={mainColWidth}>
                        <RangeFilter
                            title={'Market Cap filter'}
                            condition={marketCapCond}
                            setCondition={updateTickerSelectionParams(
                                'marketCapCond',
                            )}
                            val1={marketCapValue1}
                            val2={marketCapValue2}
                            setVal1={updateTickerSelectionParams(
                                'marketCapValue1',
                            )}
                            setVal2={updateTickerSelectionParams(
                                'marketCapValue2',
                            )}
                            fromTitle={'from (B)'}
                        />
                    </FlexRow>
                    <FlexRow width={mainColWidth}>
                        <RangeFilter
                            title={'Beta filter'}
                            condition={betaCond}
                            setCondition={updateTickerSelectionParams(
                                'betaCond',
                            )}
                            val1={betaValue1}
                            val2={betaValue2}
                            setVal1={updateTickerSelectionParams('betaValue1')}
                            setVal2={updateTickerSelectionParams('betaValue2')}
                        />
                    </FlexRow>
                    {getFilterRow(
                        'Earnings date nears',
                        'daysToEarning',
                        30,
                        daysToEarning,
                        'Minimum days to earning date',
                        false,
                        null,
                        25,
                    )}
                    {getFilterRow(
                        'Minimum Consensus upside',
                        'minUpside',
                        0,
                        minUpside,
                        'Minimum upside',
                        false,
                        null,
                        7,
                    )}
                    {getFilterRow(
                        'Positive EPS only',
                        'minEps',
                        0,
                        minEps,
                        null,
                        true,
                        null,
                        17,
                    )}
                    {getFilterRow(
                        'Positive ROE only',
                        'minRoe',
                        0,
                        minRoe,
                        null,
                        true,
                    )}
                    {getFilterRow(
                        'Filter out ADRs',
                        'filterAdr',
                        null,
                        filterAdr,
                        null,
                        true,
                    )}
                    {getFilterRow(
                        'Avoid Bearish Sentiment',
                        'removeIfAnyShort',
                        null,
                        removeIfAnyShort,
                        null,
                        true,
                        'Exclude opportunities with recent short ratings indicating bearish sentiment.',
                    )}

                    {getFilterRow(
                        'Exclude No Ratings',
                        'excludeNoRatings',
                        null,
                        excludeNoRatings,
                        null,
                        true,
                        'Exclude Stocks with no ratings. in the report time period.',
                    )}
                </>
            )}
        </Container>
    )
}

export default ScreenerSelector

const Container = styled.div`
    border: 0px solid green;
`
const Row = styled.div`
    margin-top: 24px;
    width: ${(p) => p.width}px;
`
const FlexRow = styled(Row)`
    display: flex;
    justify-content: space-between;
    width: ${(p) => p.width}px;
    margin-top: ${(p) => p.marginTop}px;
`
