import * as types from "../ActionTypes";

export function apiRequest(method, data = {}) {
  return {
    type: types.API_REQUEST,
    method,
    data,
  };
}

export function apiResponse(method, data = {}) {
  return {
    type: types.API_RESPONSE,
    method,
    data,
    receivedAt: Date.now(),
  };
}

export function apiError(method, error) {
  return {
    type: types.API_ERROR,
    method,
    error,
  };
}

export function apiUpdateWatchlist({ status, followingSymbols }) {
  return {
    type: types.WATCHLIST_UPDATE,
    status,
    followingSymbols,
  };
}

export function updateUserAnalystList(analysts) {
  return {
    type: types.ANALYST_LIST_UPDATE,
    data: analysts,
  };
}
