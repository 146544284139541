import React from 'react'
import {useState, useEffect} from '@services/hooks'
import Trends from '@components/Reports/Trends'
import {Title} from '@components/General/Elements'
import styled, {createGlobalStyle} from 'styled-components'
import {Collapse} from 'react-collapse'
import ReactTooltip from 'react-tooltip'
import {AppColors} from '@services/styles'
import Analytics from '@services/analytics'
import ScreenerSelector from './ScreenerSelector'
import AdminParamsSelector from './AdminParamsSelector'
import GenerateReport from './GenerateReport'
import ReportDatesSelector from './ReportDatesSelector'

const ScoredReport = ({user, mainColWidth, wideColWidth}) => {
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(new Date())
    const [scoreParam, setScoreParam] = useState({})
    const [adminPlParams, setAdminPlParams] = useState({})
    const [tickerSelectionParams, setTickerSelectionParams] = useState({
        sector: [],
    })
    const [
        analystTeamSelectionParams,
        setAanalystTeamSelectionParamsetAnalysisParams,
    ] = useState({})
    const [scoreVer, setScoreVer] = useState({})
    const [openMainChevron, setOpenMainChevron] = useState(true)

    useEffect(() => {
        setOpenMainChevron(true)
        Analytics.exec('track', 'select report Sector Sentiment - Analysts')
    }, [])

    const toggleMainChevron = () => setOpenMainChevron(!openMainChevron)

    return (
        <Container id="mainDiv" style={{width: wideColWidth}}>
            <Trends
                mainColWidth={mainColWidth}
                graphHeight={85}
                graphWidth={80}
                fold
                foldState={false}
            />
            <Trends
                title={'Sector Sentiment - Analysts'}
                mainColWidth={mainColWidth}
                graphHeight={85}
                graphWidth={80}
                fold
                foldState={false}
                type={'analystSector'}
            />

            <OverrideStyles />
            <ReactTooltip />
            <RepTitle
                text={'Offline Opportunity report'}
                //toolTip={title}
                fold={true}
                onClick={toggleMainChevron}
            />
            <Collapse isOpened={openMainChevron}>
                <Subtitle>
                    Uncover trading opportunities from a historical period of
                    time using this powerful tool. Gain insights into past
                    market trends and validate your investment strategy. Whether
                    you're analyzing performance or seeking to refine your
                    approach, the Historical Opportunity Report provides
                    essential information to support your decision-making
                    process.
                </Subtitle>

                <ReportDatesSelector
                    mainColWidth={mainColWidth}
                    oneDate={!user.is_admin}
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                />

                <ScreenerSelector
                    mainColWidth={mainColWidth}
                    wideColWidth={wideColWidth}
                    tickerSelectionParams={tickerSelectionParams}
                    setTickerSelectionParams={setTickerSelectionParams}
                    useScreeners={true}
                    useSectors={true}
                    useUniverse={true}
                />

                <AdminParamsSelector
                    user={user}
                    repType={'scored'}
                    wideColWidth={wideColWidth}
                    scoreParam={scoreParam}
                    setScoreParam={setScoreParam}
                    setScoreVer={setScoreVer}
                    analystTeamSelectionParams={analystTeamSelectionParams}
                    setAanalystTeamSelectionParamsetAnalysisParams={
                        setAanalystTeamSelectionParamsetAnalysisParams
                    }
                />

                <GenerateReport
                    user={user}
                    mainColWidth={mainColWidth}
                    fromDate={fromDate}
                    toDate={toDate}
                    scoreParam={scoreParam}
                    tickerSelectionParams={tickerSelectionParams}
                    analystTeamSelectionParams={analystTeamSelectionParams}
                    scoreVer={scoreVer}
                    actionText={'Generate opportunities list'}
                    adminPlParams={adminPlParams}
                    setAdminPlParams={setAdminPlParams}
                    oneDate={!user.is_admin}
                    reportType={'scored'}
                />
            </Collapse>
        </Container>
    )
}

export default ScoredReport

const Container = styled.div`
    font-family: 'Inter';
`
const Subtitle = styled.div`
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    margin-top: 10px;
    color: ${AppColors.APP_GRAY};
`
const OverrideStyles = createGlobalStyle`
    .ReactCollapse--collapse {
        transition: height 600ms;
    }
`
const RepTitle = styled(Title)`
    display: flex;
    flex-direction: row;
`
