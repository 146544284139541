import React from 'react'
import {PageContent} from './Home.styled'
import styled from 'styled-components'
import {useState, useEffect} from '@services/hooks'
import {api} from '@services/api'
import FrameSelect from '../General/FrameSelect'
import CircularProgress from 'material-ui/CircularProgress'
import {AppColors} from '@services/styles'
import {Title} from '../General/Elements'
import {Dimensions} from '@services/styles'
import Analytics from '@services/analytics'

// these are just hard coded list of topics, there are many more in QM
// see _quotemedia_topics_by_channel for the list. But that list is also partial
// see QM docs for a list of all topics

const topics = [
    'Top News',
    'Information Technology',
    'Healthcare',
    'Financials',
    'Real Estate',
    'Biotechnology',
    'Commodities',
    'Cryptocurrency',
    'Insider Trades',
    'Cannabis',
    'Energy',
    'Alternate Energy',
    'Aerospace',
]
const News = () => {
    const [list, setList] = useState([])
    const [topic, setTopic] = useState('Top News')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        Analytics.exec('track', 'news page')
    }, [])

    const getRandomImg = () => {
        const i = ((Math.random() * 8) | 0) + 1
        return require('@images/pic' + i + '.jpg').default
    }

    const getTopNews = () => {
        api.getTopNewsQm(20, 0).subscribe(
            (response) => {
                console.log('getTopNewsQm', response.data)
                PrepList(response)
            },
            (error) => {
                console.log('getTopNewsQm error', error)
            },
        )
    }

    useEffect(() => {
        setLoading(true)
        if (topic == 'Top News') {
            getTopNews()
        } else {
            api.getChannelNews([topic], 20, 0).subscribe(
                (response) => {
                    PrepList(response)
                },
                (error) => {
                    console.log('getChannelNews error', error)
                },
            )
        }
    }, [topic])

    const PrepList = (response) => {
        var wImage = response.data.map((item) => {
            return {...item, img: getRandomImg()}
        })
        // add some empty boxes at the end. so if count % number_in_row != 0 it will align left
        wImage = [...wImage, null, null, null]
        setLoading(false)
        setList(wImage)
    }

    const openNewsItem = (url) => {
        window.open(url, '_blank')
    }

    return (
        <PageContent>
            <Container>
                <TopPart>
                    <Title text={'News'} />
                    <FrameSelect
                        values={topics}
                        value={topic}
                        setValue={setTopic}
                        searchable
                        //title={'Select Topic'}
                        style={{width: 300}}
                    />
                </TopPart>
                {loading ? (
                    <Circular />
                ) : (
                    list && (
                        <MainPart>
                            {list.map((item) => {
                                return item ? (
                                    <Box>
                                        <BSImg src={item.img} width={'100%'} />
                                        <ItemTitle
                                            onClick={() =>
                                                openNewsItem(item.url)
                                            }>
                                            {item.title}
                                        </ItemTitle>
                                    </Box>
                                ) : (
                                    <Box cover />
                                )
                            })}
                        </MainPart>
                    )
                )}
            </Container>
        </PageContent>
    )
}

export default News

const Container = styled.div`
    dispaly: flex;
    flex: 1;
    font-size: 17px;
`
const TopPart = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: end;
`
const MainPart = styled.div`
    display: flex;
    flex: 1;
    flex-flow: row wrap;
    justify-content: space-between;
`
const Box = styled.div`
    border: ${(p) => (p.cover ? 0 : 1)}px solid ${AppColors.APP_GRAY_LIGHT};
    border-radius: 7px;
    width: 250px;
    display: block;
    margin-bottom: 25px;
    overflow: hidden;
    ${(p) => (p.cover ? null : 'box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.29);')}
`
const BSImg = styled.img``
const ItemTitle = styled.div`
    padding: 10px;
    color: ${AppColors.BLACK};
    font-size: 18px;
    cursor: pointer;
`
const Circular = styled(CircularProgress)`
    position: absolute;
    top: ${Dimensions.NAVBAR_TOP_OFFSET + 35}px;
    transform: translateX(-50%);
    left: 50%;
`
