import React, { Component } from 'react'
import classnames from 'classnames'
//
// import _ from './utils'
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';

const defaultButton = props =>
  <RaisedButton {...props} primary={true} label={props.label} style={{
      opacity: props.disabled ? 0 : 1,
      flex: 1
    }} />

export default class ReactTablePagination extends Component {
  constructor (props) {
    super()

    this.getSafePage = this.getSafePage.bind(this)
    this.changePage = this.changePage.bind(this)
    this.applyPage = this.applyPage.bind(this)

    this.state = {
      page: props.page
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState({ page: nextProps.page })
  }

  getSafePage (page) {
    if (isNaN(page)) {
      page = this.props.page
    }
    return Math.min(Math.max(page, 0), this.props.pages - 1)
  }

  changePage (page) {
    page = this.getSafePage(page)
    this.setState({ page })
    if (this.props.page !== page) {
      this.props.onPageChange(page)
    }
  }

  applyPage (e) {
    e && e.preventDefault()
    const page = this.state.page
    this.changePage(page === '' ? this.props.page : page)
  }

  render () {
    const {
      // Computed
      pages,
      // Props
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      showPageJump,
      canPrevious,
      canNext,
      onPageSizeChange,
      className,
      PreviousComponent = defaultButton,
      NextComponent = defaultButton
    } = this.props

    return (
      <div
        className={classnames(className, '-pagination')}
        style={this.props.paginationStyle}
      >
        <div style={{padding: 10}}>
          <PreviousComponent
            label={this.props.previousText}
            onClick={e => {
              if (!canPrevious) return
              this.changePage(page - 1)
            }}
            disabled={!canPrevious}
          />
        </div>
        <div className='-center' style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <span className='-pageInfo' style={{flex: 1}}>
            {this.props.pageText}{' '}
            {showPageJump
              ? <div className='-pageJump'>
                <TextField
                  id="ad8a7s9duh1b39uhaskjcnbq923urbaksjbda"
                  style={{width: 50}}
                  type={this.state.page === '' ? 'text' : 'number'}
                  onChange={e => {
                    const val = e.target.value
                    const page = val - 1
                    if (val === '') {
                      return this.setState({ page: val })
                    }
                    this.setState({ page: this.getSafePage(page) })
                  }}
                  value={this.state.page === '' ? '' : this.state.page + 1}
                  onBlur={this.applyPage}
                  onKeyPress={e => {
                    if (e.which === 13 || e.keyCode === 13) {
                      this.applyPage()
                    }
                  }}
                />
              </div>
              : <span className='-currentPage'>
                {page + 1}
              </span>}{' '}
            {this.props.ofText}{' '}
            <span className='-totalPages'>{pages || 1}</span>
          </span>
          {showPageSizeOptions &&
            <span className='select-wrap -pageSizeOptions' style={{height: 48, flex: 1}}>
              <SelectField
                style={{maxWidth: 150}}
                onChange={(event, key, payload) => onPageSizeChange(Number(pageSizeOptions[key]))}
                value={pageSize}
              >
                {pageSizeOptions.map((option, i) => {
                  return (
                    <MenuItem key={i} value={option}
                      primaryText={option + ' ' + this.props.rowsText} />
                  )
                })}

              </SelectField>

            </span>}
        </div>
        <div style={{padding: 10}}>
          <NextComponent
            label={this.props.nextText}
            onClick={e => {
              if (!canNext) return
              this.changePage(page + 1)
            }}
            disabled={!canNext}
          />
        </div>
      </div>
    )
  }
}
