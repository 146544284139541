import './AppHome/AppHome.css'
import React, {Component} from 'react'
import News from './AppHome/News'
import Ratings from './AppHome/Ratings'
import Api from '@services/api'
import {getUrlParams} from '@services/utils'
import styles from './AppHome/Styles'
import WebfontLoader from '@dr-kobros/react-webfont-loader'

const config = {
    google: {
        families: ['Montserrat:400,600'],
    },
}

class AppHome extends Component {
    constructor(props) {
        super(props)

        this.api = new Api()
        this.state = {
            user: null,
            id: null,
        }
    }

    sendMessage = (param) => {
        console.log(param)
        window.ReactNativeWebView.postMessage(param)
    }

    myTest = (param) => {
        console.log('myTest()', param)
        alert('myTest() ' + param)
    }

    login() {
        if (!this.state.user && this.state.id) {
            this.api.appLogin(this.state.id).subscribe(
                (response) => {
                    this.setState({
                        user: response && response.data ? response.data : null,
                    })
                    //--------------------------------
                    // where to add this???
                    // const dispatch = useDispatch()
                    // dispatch(getCurrentUser())
                    //--------------------------------
                    console.log('after login (old)user', response.data)
                },
                (error) => {
                    this.setState({user: null})
                    console.log(error)
                },
            )
        } else {
            console.log('missing data', this.state)
        }
    }

    componentDidMount() {
        let urlParams = getUrlParams()

        // let parent = this._reactInternalInstance._currentElement._owner._instance;
        // console.log("parent:", parent);

        if (urlParams && urlParams.id) {
            // setState is NOT immeditae
            this.setState(
                {
                    id: urlParams.id,
                },
                this.login,
            )

            console.log('componentDidMount', this.state, urlParams)
        } else {
            console.log(urlParams)
        }
    }

    webfontCallback(status) {
        console.log('webfont callback status', status)
    }

    render() {
        return (
            <WebfontLoader config={config} onStatus={this.webfontCallback}>
                <div>
                    <div style={styles.top_header_logo}>
                        <img
                            style={styles.logo}
                            src={require('@images/logo.svg').default}
                            alt=""
                        />
                    </div>

                    <div style={styles.stock_market_forex_trading_gra}>
                        <div style={styles.real_time_feed}>
                            REAL-TIME FEED OF MACHINE-LEARNING SELECTION ENGINE
                        </div>
                        <div style={styles.buttons_wrapper}>
                            <div style={styles.buttons_holder}>
                                {!(
                                    this.state.user &&
                                    this.state.user.subscription_status
                                ) && (
                                    <div
                                        style={styles.subscribe_button}
                                        onClick={() =>
                                            this.sendMessage(
                                                '{"subscribe":"some param"}',
                                            )
                                        }>
                                        <div style={styles.subscribe_text}>
                                            Get Premium
                                        </div>
                                    </div>
                                )}
                                <div
                                    style={styles.allrating_button}
                                    onClick={() =>
                                        this.sendMessage(
                                            '{"navigate":"Ratings"}',
                                        )
                                    }>
                                    <div style={styles.allrating_text}>
                                        See Ratings
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={styles.boxes_wrapper}>
                        <div style={styles.boxes_holder}>
                            <div style={styles.tipigo_div}>
                                <div style={styles.tipigo}>
                                    Alpha Target
                                    <div style={styles.tipigo_precent}>
                                        +13.06%
                                    </div>
                                </div>
                            </div>
                            <div style={styles.sandp_div}>
                                <div style={styles.sandp}>
                                    S&P
                                    <div style={styles.sandp_precent}>
                                        +1.02%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={styles.graph_wrapper}>
                        <img
                            style={styles.graph}
                            src={require('@images/graph.png').default}
                            alt=""
                        />
                    </div>

                    <div
                        style={{
                            ...styles.w3_container,
                            ...styles.how_does_wrapper,
                        }}>
                        {/* <div style={{...styles.how_does_bold, ...styles.unselectable}}>How does it work?</div> */}
                        <div style={styles.how_does_bold}>
                            How does it work? Analyzing the Analysts Synergy of
                            big data and human expertise
                        </div>
                        <table style={styles.infoTable}>
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            ...styles.marged_rows,
                                            ...styles.symbols,
                                        }}>
                                        <img
                                            src={
                                                require('@images/1symbol.png')
                                                    .default
                                            }
                                        />
                                    </td>
                                    <td
                                        style={{
                                            ...styles.table_text,
                                            ...styles.marged_rows,
                                        }}>
                                        Analyze sell-side research
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            ...styles.marged_rows,
                                            ...styles.symbols,
                                        }}>
                                        <img
                                            src={
                                                require('@images/2symbol.png')
                                                    .default
                                            }
                                        />
                                    </td>
                                    <td
                                        style={{
                                            ...styles.table_text,
                                            ...styles.marged_rows,
                                        }}>
                                        Identify best performing analysts
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            ...styles.marged_rows,
                                            ...styles.symbols,
                                        }}>
                                        <img
                                            src={
                                                require('@images/3symbol.png')
                                                    .default
                                            }
                                        />
                                    </td>
                                    <td
                                        style={{
                                            ...styles.table_text,
                                            ...styles.marged_rows,
                                        }}>
                                        Find analysts who have rising momentum
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {!(
                        this.state.user && this.state.user.subscription_status
                    ) && (
                        <div style={styles.buttons}>
                            <div
                                style={styles.buttons_font}
                                onClick={() =>
                                    this.sendMessage(
                                        '{"subscribe":"some param"}',
                                    )
                                }>
                                Go Premium
                            </div>
                        </div>
                    )}
                    <div style={{...styles.headers, ...styles.w3_container}}>
                        <div style={styles.headers_font}>News</div>
                    </div>

                    {this.state.user && <News sendMessage={this.sendMessage} />}

                    {this.state.user && (
                        <Ratings sendMessage={this.sendMessage} />
                    )}
                </div>
            </WebfontLoader>
        )
    }
}
export default AppHome
