import React from 'react'
import styled from 'styled-components'
import {sectorsChartColors} from '@services/styles'

const HorizontalStackLine = ({
    oneSet,
    sectorOrder,
    small,
    isLast,
    deepColor = true,
}) => {
    const sectorColor = (sector, num) => {
        const index =
            sectorOrder.indexOf(sector) !== -1
                ? sectorOrder.indexOf(sector)
                : Math.floor(Math.random() * (sectorOrder.length - 1))
        return sectorsChartColors[index][num]
    }

    return (
        oneSet && (
            <OneLineContainer
                key={oneSet.title}
                style={{paddingBottom: isLast ? 30 : 2}}>
                <OneLineTitle>{oneSet.title}</OneLineTitle>
                <OneLine>
                    {oneSet.data.map((item, i) => {
                        return (
                            <Unit key={oneSet.title + i} val={item.value}>
                                <LineColor
                                    color={sectorColor(
                                        item.sector,
                                        deepColor ? 0 : 1,
                                    )}
                                    data-tip={item.label}
                                    data-for="path-bar"
                                />

                                <ItemText>
                                    {(!small &&
                                        ((item.sector.length < 12 &&
                                            item.per > 0.07) ||
                                            item.per > 0.09)) ||
                                    item.per > 0.3
                                        ? item.label
                                        : ''}
                                </ItemText>
                            </Unit>
                        )
                    })}
                </OneLine>
            </OneLineContainer>
        )
    )
}

export default HorizontalStackLine

const OneLineContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 31px;
    padding-bottom: 3px;
`
const ItemText = styled.div`
    font-size: 12px;
`
const OneLine = styled.div`
    display: flex;
    flex: 1;
`
const OneLineTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
`
const Unit = styled.div`
    display: flex;
    flex: ${(p) => p.val};
    min-width: 4px;
    flex-direction: column;
    overflow: hidden;
`
const LineColor = styled.div`
    height: 12px;
    background-color: ${(p) => p.color};
`
