import React from 'react'
import styled from 'styled-components'
import {api} from '@services/api'
import {useState, useEffect} from '@services/hooks'
import Dialog from '@mui/material/Dialog'
import Popover from '@mui/material/Popover'
import moment from 'moment'

import {ReactComponent as IconGreen} from '@images/noti_icon_green.svg'
import {ReactComponent as IconRed} from '@images/noti_icon_red.svg'
import {ReactComponent as IconSplit} from '@images/noti_icon_split.svg'
import {ReactComponent as IconUnsplit} from '@images/noti_icon_unsplit.svg'
import {ReactComponent as IconDown} from '@images/noti_icon_down.svg'
import {ReactComponent as IconBell} from '@images/noti_icon_bell.svg'
import {AppColors} from '@services/styles'
import {Link} from 'react-router-dom'
import CircularProgress from 'material-ui/CircularProgress'

/*
use popover:
    better position with anchor 
use dialog:
    can do absolute position but might not be good enough
    better handle a lot of content
    better handle inner scroll or scroll the window
*/

const Notifications = ({open, setAnchor, anchor, pId, setHasNewNoti}) => {
    const [list, setList] = useState([])
    const [count, setCount] = useState(5)
    const [showLoad, setShowLoad] = useState(true)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        // will not update if user stays on the page a long time...
        // need to solve it? or good enough?
        if (count) getNotifications()
    }, [count])

    useEffect(() => {
        if (list.length) {
            // use showLoad insted of count because count updated faster then list
            // so it will "jump" off and on again for each load
            setShowLoad(list.length >= count)
            setLoading(false) // it starts in true and will get here after first load
        }
    }, [list])

    useEffect(() => {
        // update pop after instead of when pulling because we don't know if they see the first batch
        // for consecutive batches it does not matter because they are by user request
        if (open && count) {
            api.setUserPortfolioNotiPop(pId, count).subscribe(
                (response) => {
                    if (response?.data) {
                        setHasNewNoti(response.data.status !== 0)
                    }
                },
                (error) => {
                    console.log('set notifications pop error', error)
                },
            )
        }
    }, [open, count])

    const showMore = () => {
        setLoading(true)
        setCount(count + 5)
    }

    const getNotifications = () => {
        api.getUserPortfolioNotifications(pId, count, 0).subscribe(
            (response) => {
                console.log('got_notifications ', response)
                if (response.data && response.data.data) {
                    setList(response.data.data)
                } else {
                    console.log('get notifications non')
                }
            },
            (error) => {
                console.log('get notifications error', error)
            },
        )
    }

    return (
        <Popover
            // sx={{
            //     '& .MuiDialog-container': {
            //         justifyContent: 'flex-start',
            //         alignItems: 'flex-start',
            //     },
            // }}
            // PaperProps={{
            //     sx: {
            //         m: 0,
            //         top: 10,
            //         left: 10,
            //     },
            // }}
            //id={popid}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
            onClose={() => setAnchor(null)}
            anchorEl={anchor}
            //fullWidth
            //maxWidth={'sm'}
        >
            <Container>
                {list &&
                    list.map((item, i) => {
                        return (
                            <NotiRow
                                pop={item.pop ? 1 : 0}
                                key={item.symbol + i}
                                to={'/stock/' + item.symbol}>
                                {item.rating == 'buy' ? (
                                    <IconGreen />
                                ) : item.rating == 'sell' ? (
                                    <IconRed />
                                ) : item.rating == 'earning' ? (
                                    <IconBell />
                                ) : item.rating == 'split' &&
                                  item.factor > 1 ? (
                                    <IconUnsplit />
                                ) : item.rating == 'split' &&
                                  item.factor < 1 ? (
                                    <IconSplit />
                                ) : (
                                    <IconBell />
                                )}
                                <ItemText>
                                    <ItemTitle>
                                        {
                                            <>
                                                {item.title}&nbsp;
                                                <Symbol rating={item.rating}>
                                                    {item.symbol}
                                                </Symbol>{' '}
                                            </>
                                        }
                                    </ItemTitle>
                                    {item.text}
                                    {/* {item.rating == 'split' &&
                                        item.split_date &&
                                        item.factor && [
                                            'on ',
                                            moment(item.split_date.date).format(
                                                'YYYY-MM-DD',
                                            ),
                                            ' by factor of ',
                                            item.factor.toFixed(2),
                                            <br />,
                                            'consider updateing entry price or quantity',
                                        ]} 
                                   
                                     {item.rec_date && item.analyst && (
                                        <>
                                            on&nbsp;
                                            {moment(item.rec_date.date).format(
                                                'YYYY-MM-DD',
                                            )}
                                            &nbsp;by&nbsp;{item.analyst}
                                        </>
                                    )} */}
                                </ItemText>
                            </NotiRow>
                        )
                    })}
                {showLoad && (
                    <ShowMore onClick={showMore}>
                        <ShowMoreBtn>Show More</ShowMoreBtn>{' '}
                        {loading && <CircularProgress size={24} />}
                    </ShowMore>
                )}
            </Container>
        </Popover>
    )
}

export default Notifications

const Container = styled.div`
    display: flex;
    flex-direction: column;
    //padding-right: 30px;
    //padding-left: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
`
const NotiRow = styled(Link)`
    display: flex;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    text-decoration: none;
    background-color: ${(p) => (p.pop ? 'none' : AppColors.GRAY_BG)};
    padding-right: 30px;
    padding-left: 20px;
`
const ItemTitle = styled.div`
    display: flex;
    font-size: 17px;
    font-weight: 700;
    padding-bottom: 3px;
`
const ItemText = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding-left: 18px;
    color: ${AppColors.BLACK};
`
const Symbol = styled.div`
    color: ${(p) =>
        p.rating == 'buy'
            ? AppColors.TIPIGO_GREEN
            : p.rating == 'sell'
            ? AppColors.RED_TEXT
            : AppColors.APP_GRAY};
`
const ShowMore = styled.div`
    display: flex;
    flex: 1;
`
const ShowMoreBtn = styled.div`
    font-size: 16px;
    color: ${AppColors.TIPIGO_GREEN};
    cursor: pointer;
    width: 50%;
    padding-left: 20px;
    padding-top: 9px;
`
