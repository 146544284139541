import React from 'react'
import styled from 'styled-components'
import {api} from '@services/api'
import {PageContent} from './Home/Home.styled'
import {TopBlock, Title, SendButton} from '@components/General/Elements'
import {useState, useEffect, useUser, useHistory} from '@services/hooks'
import {AppColors, Dimensions} from '@services/styles'
import {ReactComponent as OppIcon} from '@images/opp_scan_icon.svg'
import {ReactComponent as PortIcon} from '@images/portfolio_icon.svg'
import {ReactComponent as LfIcon} from '@images/lf_icon.svg'
import {ReactComponent as BigLogo} from '@images/gray_logo.svg'
import {ReactComponent as EmptySymbol} from '@images/bs_charts.svg'

import {ReactComponent as GoArr} from '@images/right_arrow.svg'
import Main from './HomePage/Main'
import CircularProgress from 'material-ui/CircularProgress'

const HomePage = () => {
    const user = useUser()
    const history = useHistory()
    const [myFile, setMyFile] = useState(null)
    const [totalLf, setTotalLf] = useState('')
    const [totalAssets, setTotalAssets] = useState(0)
    const [filePl, setFilePl] = useState(0)
    const [newClient, setNewClient] = useState(true)
    const [preLoad, setPreLoad] = useState(true)

    const displayName =
        'Welcome ' +
        (user
            ? user.nickname
                ? user.nickname
                : user.first_name
                ? user.first_name
                : user.email
                ? user.email
                : ''
            : '')

    const loadPortfolio = () => {
        api.getUserPortfolios(user.user_id).subscribe(
            (response) => {
                if (!response.data.list || response.data.list.length === 0) {
                    console.log('user portfolio non')
                } else {
                    console.log('user portfolio exists')
                    setMyFile(response.data.list[0])
                    setNewClient(false)
                }
                setPreLoad(false)
            },
            (error) => {
                console.log('getUserPortfolios', error)
                setPreLoad(false)
            },
        )
    }

    useEffect(() => {
        if (myFile) {
            setTotalAssets(
                myFile.open_total + myFile.current_cash + myFile.short_pl_money,
            )
            if (myFile.total_invest) {
                setFilePl(
                    ((myFile.open_total +
                        myFile.current_cash +
                        myFile.short_pl_money -
                        myFile.total_invest) /
                        myFile.total_invest) *
                        100,
                )
            }
        }
    }, [myFile])

    useEffect(() => {
        loadPortfolio()
    }, [user])

    const getBox = (title, btnText, btnFunc, text, mr, icon) => {
        return (
            <Box style={{marginRight: mr}}>
                <BoxLeft>
                    <Circle>{icon}</Circle>
                </BoxLeft>
                <BoxMain>
                    <BoxTitle>{title}</BoxTitle>
                    <SendButton style={{width: 185}} onClick={btnFunc}>
                        {btnText}
                    </SendButton>
                    <BoxText>{text}</BoxText>
                </BoxMain>
            </Box>
        )
    }
    const getBox2 = (
        title,
        bigNumber,
        smallNumber,
        text,
        mr,
        icon,
        btnFunc,
    ) => {
        return (
            <Box style={{marginRight: mr}}>
                <BoxLeft>
                    <Circle>{icon}</Circle>
                </BoxLeft>
                <BoxMain>
                    <BoxTitle>{title}</BoxTitle>
                    <BoxMiddle>
                        <BoxBigNumber>{bigNumber}</BoxBigNumber>
                        <BoxText style={{marginLeft: 5}}>{text}</BoxText>
                    </BoxMiddle>
                    <BoxSmallNumber val={smallNumber}>
                        {smallNumber}
                    </BoxSmallNumber>
                    <SeeMoreCont>
                        <SeeMoreLink onClick={btnFunc}>
                            <SeeMoreText>See more</SeeMoreText>
                            <GoArr style={{stroke: AppColors.TIPIGO_GREEN}} />
                        </SeeMoreLink>
                    </SeeMoreCont>
                </BoxMain>
            </Box>
        )
    }

    return (
        <PageContent>
            <Container>
                <Title text={displayName} />
                {preLoad ? (
                    <Progress>
                        <CircularProgress />
                    </Progress>
                ) : (
                    <Container>
                        <TopRow>
                            {getBox(
                                'Opportunities Scanner',
                                'Scan now',
                                () => history.push('/?tab=4'), //&opp=1
                                'Get a custom trade-list based on your preferences and the latest market data.',
                                40,
                                <OppIcon />,
                            )}
                            {newClient
                                ? getBox(
                                      'Portfolio',
                                      'Start here',
                                      () => history.push('/?tab=3'),
                                      'Start building your personalized investment portfolio.',
                                      40,
                                      <PortIcon />,
                                  )
                                : getBox2(
                                      'Portfolio',
                                      totalAssets.toLocaleString('en-US', {
                                          style: 'currency',
                                          currency: 'USD',
                                      }),
                                      (filePl > 0 ? '+' : '') +
                                          filePl.toFixed(2) +
                                          '%',
                                      'Total',
                                      40,
                                      <PortIcon />,
                                      () => history.push('/?tab=3'),
                                  )}

                            {newClient
                                ? getBox(
                                      "Analysts' ratings",
                                      'Live feed',
                                      () => history.push('/?tab=0'),
                                      'See curated feed with the latest trading opportunities by the top analyst team.',
                                      0,
                                      <LfIcon />,
                                  )
                                : getBox2(
                                      "Analysts' ratings",
                                      totalLf,
                                      '',
                                      'New ratings',
                                      0,
                                      <LfIcon />,
                                      () => history.push('/?tab=0'),
                                  )}
                        </TopRow>
                        {newClient ? (
                            <EmptyMainBlock>
                                <EmptySymbol />
                                <EmptyText>
                                    Welcome to your portfolio. Please enter your
                                    total portfolio{' '}
                                    <FundsLink href="/#/?tab=3">
                                        Funds
                                    </FundsLink>{' '}
                                    and add
                                    <br /> your positions to be able to follow
                                    up your performance over time.
                                </EmptyText>
                                <SendButton
                                    style={{marginTop: 30}}
                                    onClick={() => history.push('/?tab=3')}>
                                    Let's start
                                </SendButton>
                            </EmptyMainBlock>
                        ) : (
                            <Main
                                user={user}
                                //setTotalAssets={setTotalAssets}
                                //setFilePl={setFilePl}
                                setTotalLf={setTotalLf}
                                history={history}
                                myFile={myFile}
                            />
                        )}
                    </Container>
                )}
            </Container>
        </PageContent>
    )
}

export default HomePage

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 17px;
    border: 0px solid green;
    max-width: 1400px;
`
// this is *more or less* correct height but.. not sure what will be and should it "fit" in the height or can we have scroll
// height: ${window.innerHeight -
//     Dimensions.NAVBAR_TOP_OFFSET -
//     Dimensions.APP_BAR_HEIGHT * 2}px;

const Box = styled(TopBlock)`
    flex-direction: row;
`
const FundsLink = styled.a`
    color: ${AppColors.TIPIGO_GREEN};
    text-decoration: none;
`
const EmptyText = styled.div`
    margin-top: 30px;
    font-size: 15px;
    font-weight: 400;
    color: ${AppColors.BLACK};
    text-align: center;
`
const EmptyMainBlock = styled(TopBlock)`
    //height: 80%;
    height: calc(100% - 180px); // not sure about that...
    bottom: 10px;
    align-items: center;
    justify-content: center;
`
const BoxLeft = styled.div``
const BoxMain = styled.div`
    padding-left: 17px;
    border: 0px solid green;
    flex: 1;
`
const Circle = styled.div`
    background-color: ${AppColors.LIGHT_GRAY_BG};
    border-radius: 50%;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    display: flex;
`
const TopRow = styled.div`
    display: flex;
    flex: 1;
    border: 0px solid red;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 25px;
`
const BoxTitle = styled.div`
    font-size: 18px;
    margin-bottom: 15px;
    margin-top: 12px;
    color: ${AppColors.BLACK};
`
const BoxText = styled.div`
    font-size: 14px;
    color: ${AppColors.APP_GRAY};
    margin-top: 10px;
`
const BoxBigNumber = styled.div`
    font-size: 45px;
    font-weight: 300;
    color: ${AppColors.BLACK};
    border: 0px solid green;
    height: auto;
    display: flex;
`
const BoxSmallNumber = styled.div`
    font-size: 25px;
    height: 21px;
    font-weight: 300;
    margin-top: 20px;
    color: ${(p) =>
        p.val
            ? parseFloat(p.val) > 0
                ? AppColors.TIPIGO_GREEN
                : AppColors.RED_TEXT
            : AppColors.BLACK};
`
//it should be align at the same height as SendButton bit couldnt align the text with the div(yet) so added margin-top
// TODO: this is stupid! fix it
const BoxMiddle = styled.div`
    display: flex;
    flex-direction: row;
    border: 0px solid red;
    margin-top: 24px;
`
const SeeMoreCont = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
`
const SeeMoreLink = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
`
const SeeMoreText = styled.div`
    color: ${AppColors.TIPIGO_GREEN};
    font-size: 15px;
`
const Progress = styled(Container)`
    justify-content: center;
    align-items: center;
    margin-top: 50px;
`
