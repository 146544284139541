import React from 'react'

// import FontIcon from 'material-ui/FontIcon'
// import IconButton from 'material-ui/IconButton'
// import RaisedButton from 'material-ui/RaisedButton'
// import FlatButton from 'material-ui/FlatButton'
// import CircularProgress from 'material-ui/CircularProgress'
import {
    Card,
    CardActions,
    CardTitle,
    CardText,
    CardHeader,
} from 'material-ui/Card'
import moment from 'moment'
import {AlertTypes} from '@constants/Finance'
import * as ApiActions from '@store/actions/api-actions'
import Api from '@services/api'
import {AppColors} from '@services/styles'
import {connect} from 'react-redux'
//import {formatDailyChange} from '@services/utils'
import Analytics from '@services/analytics'
//import ActionStars from 'material-ui/svg-icons/action/grade'
import _ from 'lodash'
import {
    WatchlistIcon,
    LIVE_FEED_ICON_SIZE,
    Expand,
    ExpandIcon,
    ExpandTitle,
} from './Alert.style'
// import AnalystImage from './AnalystImage'
// import 'rc-tooltip/assets/bootstrap_white.css';
// import './Alert.css'
import HeaderInfo, {HorizontalBox} from './AlertHeaderInfo'
import AddSymbol from '@images/add_gray.svg'
import AddSymbol_y from '@images/add_yellow.svg'

import {red400, orange400} from 'material-ui/styles/colors'
import styled from 'styled-components'
import {ReactComponent as BirdGreen} from '@images/bird_green.svg'
import {ReactComponent as BirdRed} from '@images/bird_red.svg'
import {ReactComponent as NumBox} from '@images/num_sqrs.svg'

export const upsideCalculate = (target, lastPrice) =>
    (100 * (target - lastPrice)) / lastPrice

const _getAlertType = (alert) => {
    if (alert.type === 1014 && alert.rating === 'buy') {
        return AlertTypes.BUY
    } else if (alert.type === 1014 && alert.rating === 'sell') {
        return AlertTypes.SELL
    } else if (alert.type === 1015) {
        return AlertTypes.EARNING_ANNOUNCEMENT
    } else if (alert.type === 1016) {
        return AlertTypes.UNDER_PERFORMING_RELATIVE_TO_SECTOR
    } else if (alert.type === 1004) {
        return AlertTypes.TARGET_PRICE
    } else if (alert.type === 1017) {
        return AlertTypes.EARNING_ANNOUNCEMENT_TODAY
    }

    return null
}

class Alert extends React.Component {
    constructor(props) {
        super(props)
        // bind method
        this.toggleWatchList = this.toggleWatchList.bind(this)

        // declear Variables
        this.isMount = false

        // initialize state
        this.state = {
            open: false,
            followingSymbols: this.props.watchlist,
        }
    }
    componentWillUnmount() {
        this.isMount = false
    }
    componentDidMount() {
        this.isMount = true
    }
    componentWillReceiveProps(nextProps) {
        if (!this.isMount) {
            this.setState({followingSymbols: nextProps.watchlist})
        }
    }

    toggleWatchList(isExist) {
        const {
            alert: {symbol},
        } = this.props
        const {followingSymbols: watchlist} = this.state

        if (isExist) {
            _.remove(
                watchlist,
                (o) => o.symbol.toUpperCase() === symbol.toUpperCase(),
            )
        } else {
            watchlist.push({symbol})
        }
        this.setState({followingSymbols: watchlist})

        this.props.toggleWatchlist(!isExist, symbol).then()
    }
    render() {
        const {alert, symbols, style, windowRef} = this.props
        const {followingSymbols: watchlist, open} = this.state
        const alertType = _getAlertType(alert)

        const isBullish = alertType === AlertTypes.BUY
        let color = orange400
        let message = null
        let symbol = (alert.symbol || '').toUpperCase()

        let symbolData = null
        if (symbols[symbol]) {
            symbolData = symbols[symbol]
        }

        let ea = null
        if (alert.earning_date) {
            ea = moment(alert.earning_date, 'YYYY-MMM-DD').format('LL')
        }

        let shouldDisplayDescription = false

        const headerTitle = (buy) => {
            return (
                <HeaderInfo
                    time={alert.time}
                    buy={buy}
                    analyst={alert.analyst_name}
                    successRate={alert.success_rate}
                    // 29.11.21 analystPerfomence={alert.analyst_performance}
                    symbol={symbol}
                    lastPrice={symbolData.lastPrice}
                    dailyChange={symbolData.dailyChange}
                    target={alert.price_target}
                    sector={alert.sector_name}
                    alertId={alert.id}
                    //performance_data={alert.performance_data}
                    rate={alert.rate}
                    company={alert.company_name_2}
                    //universes={alert.universes} // test for filters, don't think we need it
                />
            )
        }

        if (alertType === AlertTypes.BUY) {
            color = AppColors.TIPIGO_GREEN
            message = headerTitle(true)
            shouldDisplayDescription = true
        } else if (alertType === AlertTypes.SELL) {
            color = AppColors.TIPIGO_RED
            message = headerTitle(false)
            shouldDisplayDescription = true
        } else if (alertType === AlertTypes.EARNING_ANNOUNCEMENT) {
            message = (
                <span>
                    <span style={{color, fontWeight: 'bold'}}>
                        Tipigo Alert:{' '}
                    </span>{' '}
                    <a href={'#/stock/' + symbol}>{symbol}</a>
                    {` (${symbolData.lastPrice}`}
                    {symbolData.dailyChange}
                    {')'}
                    {` earning announcement is coming up`}
                    {ea ? ' on ' + ea : ''}
                </span>
            )
        } else if (alertType === AlertTypes.EARNING_ANNOUNCEMENT_TODAY) {
            message = (
                <span>
                    <span style={{color, fontWeight: 'bold'}}>
                        Tipigo Alert:{' '}
                    </span>{' '}
                    <a href={'#/stock/' + symbol}>{symbol}</a>
                    {` (${symbolData.lastPrice}`}
                    {symbolData.dailyChange}
                    {')'}
                    {` earning announcement is today`}
                </span>
            )
        } else if (
            alertType === AlertTypes.UNDER_PERFORMING_RELATIVE_TO_SECTOR
        ) {
            message = `${symbol} is underperforming relative to sector`
        } else if (alertType === AlertTypes.TARGET_PRICE) {
            message = `Last price is 2% from Recommended Target Price`
        }

        if (!message) {
            return null
        }

        let exsitingPos = _.find(watchlist, (pos) => {
            return symbol.toUpperCase() === pos.symbol.toUpperCase()
        })

        let buttonText = 'Add to Watchlist'
        let buttonBgColor = AppColors.TIPIGO_GREEN
        let buttonAction = 'add'
        if (exsitingPos) {
            buttonText = 'Remove from Watchlist'
            buttonBgColor = red400
            buttonAction = 'remove'
        }

        let description =
            alert.description ||
            `Investment opportunity by ${alert.analyst_name}.<br/>Original message not available.`
        if (description) {
            const re = /([A-Z][A-Z][A-Z][A-Z]?)\)/gim
            let descDailyChange = '' // formatDailyChange(symbolData, true, true, true)
            description = description.replace(re, (a, stock) => {
                return "<a href='#/stock/" + stock + "'>" + stock + '</a>)'
            })
        }

        //const alertStyle = style || {marginBottom: 20}

        return (
            <Card1
                style={this.props.style}
                //style={alertStyle}
                //containerStyle={{height: '100%'}}
                onClick={() => this.setState({open: !open})}>
                <div style={{height: '100%', display: 'flex'}}>
                    {/* <div this was side green line
                        style={{
                            backgroundColor: color,
                            width: 5,
                            // height: '100%',
                            borderTopLeftRadius: 2,
                            borderBottomLeftRadius: 2,
                        }}
                    /> */}

                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%',
                        }}>
                        {/* <div style={{flex: 1}}> */}
                        <CardTitle
                            title={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            //
                                        }}>
                                        {alertType === AlertTypes.BUY ? (
                                            alert.count > 1 ? (
                                                <NumAndBox>
                                                    <NumBox
                                                        color={
                                                            AppColors.TIPIGO_GREEN
                                                        }
                                                    />
                                                    <Num number={alert.count}>
                                                        {alert.count}
                                                    </Num>
                                                </NumAndBox>
                                            ) : (
                                                <BirdGreen />
                                            )
                                        ) : alert.count > 1 ? (
                                            <NumAndBox>
                                                <NumBox
                                                    color={AppColors.TIPIGO_RED}
                                                />
                                                <Num number={alert.count}>
                                                    {alert.count}
                                                </Num>
                                            </NumAndBox>
                                        ) : (
                                            <BirdRed />
                                        )}
                                        <div style={{width: 20}}></div>
                                        <div
                                            style={{
                                                lineHeight: 2,
                                                fontSize: 18,
                                            }}>
                                            {message}
                                        </div>
                                    </div>
                                    {this.props.actions && (
                                        <HorizontalBox>
                                            <AddToPortIcon
                                                src={
                                                    alert.in_file
                                                        ? AddSymbol_y
                                                        : AddSymbol
                                                }
                                                onClick={() => {
                                                    this.props.setSymbolForDialog(
                                                        symbol,
                                                    )
                                                    this.props.setOpenPortDialog(
                                                        true,
                                                    )
                                                }}
                                                //the tooltip and the dialog control is on livefeed because here we are "in a loop"
                                                data-tip={
                                                    alert.in_file
                                                        ? 'Symbol is in portfolio. Add another position'
                                                        : 'Add a position to Portfolio'
                                                }
                                            />

                                            <WatchlistIcon
                                                style={{
                                                    marginRight: 5,
                                                    marginLeft: 9,
                                                }}
                                                isExist={exsitingPos}
                                                isBullish={isBullish}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    Analytics.exec(
                                                        'track',
                                                        'User clicked ' +
                                                            buttonText +
                                                            ' button',
                                                    )
                                                    this.toggleWatchList(
                                                        exsitingPos,
                                                    )
                                                }}
                                            />
                                            <ExpandIcon open={open} size={35} />
                                        </HorizontalBox>
                                    )}
                                </div>
                            }
                        />

                        <Expand open={open}>
                            <ExpandTitle>
                                Original rating:
                                {/* 04.11.22 remove this, if needed look for commented #rec_id_and_source in user_service 
                                     ({alert.source}) */}
                            </ExpandTitle>
                            {shouldDisplayDescription ? (
                                <span>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}></div>
                                </span>
                            ) : null}
                        </Expand>
                        {/* </div> */}
                    </div>
                </div>
            </Card1>
        )
    }
}

// Alert.propTypes = {
//   alert: PropTypes.object.isRequired,
// };

const mapStateToProps = (state) => {
    return {
        symbols: state.finance.data.symbols,
        watchlist: state.finance.data.followingSymbols,
    }
}

const mapDispatchToProps = (dispatch) => {
    const api = new Api()

    return {
        toggleWatchlist: (shouldAdd, symbol) => {
            return new Promise((resolve, reject) => {
                const action = shouldAdd ? 'followSymbol' : 'unfollowSymbol'
                api[action](symbol).subscribe(
                    (response) => {
                        dispatch(ApiActions.apiUpdateWatchlist(response.data))
                        resolve(response.data)
                    },
                    (error) => {
                        console.warn(error)
                        reject(error)
                    },
                )
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert)

//styled(AddSymbol)
const AddToPortIcon = styled.img`
    //stroke: ${AppColors.APP_GRAY};
    cursor: pointer;
    border: 0px;
    outline: none;
`

const Card1 = styled.div`
    border: 1px solid ${AppColors.APP_GRAY_LIGHT};
    border-radius: 7px;
    margin-top: 11px;
`
const NumAndBox = styled.div`
    position: relative;
`
const Num = styled.div`
    position: absolute;
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
    top: 5px;
    left: ${(p) => (p.number > 9 ? 9 : 14)}px;
`
