import {fade} from 'material-ui/utils/colorManipulator'
import * as Colors from 'material-ui/styles/colors'
import {spacing, getMuiTheme} from 'material-ui/styles'

import {AppColors, Dimensions} from '@services/styles'

const rawBaseTheme = {
    ...spacing,
    fontFamily: 'Roboto, sans-serif',
    appBar: {
        height: Dimensions.NAVBAR_TOP_OFFSET,
        paddingLeft: Dimensions.PAGE_PADDING_LEFT,
        paddingRight: Dimensions.PAGE_PADDING_RIGHT,
    },
    palette: {
        primary1Color: AppColors.TIPIGO_BLUE,
        primary2Color: Colors.cyan700,
        primary3Color: Colors.lightBlack,
        accent1Color: AppColors.TIPIGO_GREEN,
        accent2Color: Colors.grey100,
        accent3Color: Colors.grey500,
        textColor: Colors.darkBlack,
        alternateTextColor: Colors.white,
        canvasColor: Colors.white,
        borderColor: Colors.grey300,
        disabledColor: fade(Colors.darkBlack, 0.3),
    },
}

//Theme must be wrapped in funciton getMuiTheme
export default getMuiTheme(rawBaseTheme)
