import React, {Component, PropTypes} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

//import * as ApiActions from '@store/actions/api-actions'
import * as AppActions from '@store/actions/app-actions'

// import AppBar from 'material-ui/AppBar'
// import FlatButton from 'material-ui/FlatButton'
// import IconButton from 'material-ui/IconButton'
// import RaisedButton from 'material-ui/RaisedButton'
// import Popover from 'material-ui/Popover'
// import Menu from 'material-ui/Menu'
// import MenuItem from 'material-ui/MenuItem'
// import FontIcon from 'material-ui/FontIcon'
import CircularProgress from 'material-ui/CircularProgress'
//import {Tabs, Tab} from 'material-ui/Tabs'

import Header from './Header'
//import {PageContent, PollLoading, PollFetching} from './Home/Home.styled'
import NavBar, {getActiveTab} from './NavAppBar'
import {getUrlParams} from '@services/utils'
import DataService from '@services/data'
import LiveFeed from './Home/LiveFeed'
import Watchlist from './Home/Watchlist'
import MyFile from './Home/MyFile'
import Tools from './Home/Tools'
import Test from './Home/Test'
import News from './Home/News'
import HomePage from './HomePage'
//import Stock from '@components/Stock'

//import * as Colors from 'material-ui/styles/colors'
import {Dimensions, AppColors} from '@services/styles'

const FINANCE_QUERY_INTERVAL = 1000 * 120 // In milliseconds. 07.11.22 10 to 30

const POLL_STATUS_WAITING = 'POLL_STATUS_WAITING'
const POLL_STATUS_UPDATING = 'POLL_STATUS_UPDATING'
const POLL_STATUS_NONE = 'POLL_STATUS_NONE'

class Home extends Component {
    constructor(props, context) {
        super(props, context)

        let tab = 6

        let urlParams = getUrlParams()
        if (urlParams && urlParams.tab) {
            tab = parseInt(urlParams.tab)
        }
        console.log('tab_changed', tab)

        this.pollTimeOut = null

        this.state = {
            //portOpenPos: true,
            profileSelectorOpen: false,
            drawerOpen: true,
            pollStatus: POLL_STATUS_NONE,
            activeTab:
                tab == 0
                    ? '_livefeed'
                    : tab == 1
                    ? '_watchlist'
                    : tab == 3
                    ? '_myfile'
                    : tab == 4
                    ? '_tools'
                    : tab == 6
                    ? '_homepage'
                    : '_news',
        }
    }

    componentWillMount() {
        const {user, initialized, dataLoading, sectorsLoading} = this.props

        if (!user || !user.session_token) {
            console.warn(
                'Missing user or session token, redirecting back to login',
            )
            // TODO: back to login or something
            return
        }

        // Check if we already initialized the finance data
        if (!initialized && !dataLoading && !sectorsLoading) {
            // No data, let's get some and show a spinner while we're at it
            if (user.account_type === 'user' && user.current_profile) {
                this.props.getFinancialData(user.current_profile)
            } else {
                this.props.getFinancialData(user.session_token)
            }
        }
    }

    componentDidMount() {
        this._isMounted = true
        this.poll()
    }

    componentWillUnmount() {
        this._isMounted = false
        if (this.pollTimeOut) {
            window.clearInterval(this.pollTimeOut)
            this.pollTimeOut = null
        }
    }

    componentWillReceiveProps(nextProps) {
        const {
            user,
            initialized,
            dataLoading,
            sectorsLoading,
            sectors,
            redirectedOnce,
        } = nextProps

        if (
            user &&
            user.session_token &&
            initialized === true &&
            !dataLoading &&
            !sectorsLoading &&
            redirectedOnce !== true
        ) {
            if (
                !sectors ||
                (sectors.length < 1 && user.account_type === 'profile')
            ) {
                // TODO: strange after (because?) my file. jumps here after login
                console.log('jump to investment')
                this.props.setRedirectedOnceFlag(true)
                this.props.history.replace('/investment-profile')
            }
        }
    }

    poll(restart = false) {
        let self = this

        if (this.pollTimeOut) {
            window.clearTimeout(this.pollTimeOut)
            this.pollTimeOut = null
        }

        if (restart === true && self.props) {
            this.financeLoopRequest()
            return
        }

        if (this.pollTimeOut === null) {
            this._isMounted && this.setState({pollStatus: POLL_STATUS_WAITING})
            this.pollTimeOut = setTimeout(() => {
                this.financeLoopRequest()
            }, FINANCE_QUERY_INTERVAL)
        }
    }

    financeLoopRequest = () => {
        const {user, initialized, dataLoading} = this.props
        const handleResponse = (res) => {
            this._isMounted &&
                this.setState({pollStatus: POLL_STATUS_NONE}, () =>
                    this.poll(false),
                )
        }
        console.log({
            user,
            initialized,
            dataLoading,
            isMounted: this._isMounted,
        })
        if (user && user.session_token && initialized && !dataLoading) {
            // We have a user (i.e. we're logged in)
            // Fetch silently and only get finance info (exclude portfolio)
            // self.props.getFinancialData(user.session_token, true, true)
            this._isMounted && this.setState({pollStatus: POLL_STATUS_UPDATING})
            if (user.account_type === 'user' && user.current_profile) {
                this.props.getFinancialData(
                    user.current_profile,
                    true,
                    true,
                    handleResponse,
                )
            } else {
                this.props.getFinancialData(
                    user.session_token,
                    true,
                    true,
                    handleResponse,
                )
            }
        }
    }

    getActivePage = () => {
        return getActiveTab(this.props.history) == 1
            ? '_watchlist'
            : getActiveTab(this.props.history) == 0
            ? '_livefeed'
            : getActiveTab(this.props.history) == 6
            ? '_homepage'
            : getActiveTab(this.props.history) == 3
            ? '_myfile'
            : getActiveTab(this.props.history) == 15
            ? '_test'
            : getActiveTab(this.props.history) == 4
            ? '_tools'
            : '_news'
    }

    renderContent = () => {
        if (this.props.dataLoading) {
            return (
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        paddingTop:
                            Dimensions.CONTENT_BASE_PADDING +
                            Dimensions.NAVBAR_TOP_OFFSET +
                            Dimensions.APP_BAR_HEIGHT +
                            30,
                        //paddingLeft: Dimensions.DRAWER_WIDTH,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>
                    <CircularProgress />
                </div>
            )
        }

        const activeTab = this.getActivePage()
        if (activeTab === '_homepage') {
            return <HomePage />
        }
        if (activeTab === '_livefeed') {
            return (
                <LiveFeed
                    poll={this.poll.bind(this)}
                    gotoWatchlist={() => {
                        this._isMounted &&
                            this.setState({activeTab: '_watchlist'})
                    }}
                />
            )
        }
        if (activeTab === '_watchlist') {
            return <Watchlist />
        }
        if (activeTab === '_myfile') {
            console.log('loadp_needRefresh0')
            return <MyFile /> //showOpen={this.state.portOpenPos}
        }
        if (activeTab === '_tools') {
            return <Tools />
        }
        if (activeTab === '_test') {
            return <Test />
        }
        if (activeTab === '_news') {
            return <News />
        }
        return null
    }

    renderUpdateLoader = () => {
        // 21.10.21 comment. not needed but can uncomment again
        // const {pollStatus} = this.state
        // if (pollStatus == POLL_STATUS_WAITING) {
        //     return <PollLoading interval={FINANCE_QUERY_INTERVAL / 1000} />
        // } else if (pollStatus == POLL_STATUS_UPDATING) {
        //     return <PollFetching />
        // }

        return null
    }

    // changePortfolioMode = (isOpen) => {
    //     this.setState({portOpenPos: isOpen})
    // }

    render() {
        const {user} = this.props

        return (
            <div>
                <Header />
                <NavBar
                    rightElement={this.renderUpdateLoader()}
                    changePortfolioModeFunc={this.changePortfolioMode}
                />
                <div
                    style={{
                        paddingRight: Dimensions.PAGE_PADDING_RIGHT,
                        paddingLeft: Dimensions.PAGE_PADDING_LEFT,
                    }}>
                    {this.renderContent()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        redirectedOnce: state.user.redirectedOnce,
        initialized: state.finance.initialized,
        isFetching: state.finance.isFetching,
        dataLoading: state.finance.isFetching['getUserFinanceInfo'],
        sectorsLoading: state.finance.isFetching['getUserProfileData'],
        sectors: state.finance.data.sectors || [],
    }
}

const mapDispatchToProps = (dispatch) => {
    const ds = new DataService(dispatch)

    return {
        getFinancialData: (
            sessionToken,
            fetchSilently = false,
            financeOnly = false,
            callback,
        ) => {
            return ds.getFinancialData(
                sessionToken,
                fetchSilently,
                financeOnly,
                callback,
            )
        },

        setRedirectedOnceFlag: (value) => {
            dispatch(AppActions.setRedirectedOnceFlag(true))
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))
