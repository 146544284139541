import React from 'react'
import {api} from '@services/api'
import {useState, useEffect, useUser} from '@services/hooks'
import styled from 'styled-components'
import {SendButton, Title} from '@components/General/Elements'
import DateRange from '@components/General/DateRange'
import FrameInput from '@components/General/FrameInput'
import FrameSelect from '@components/General/FrameSelect'
import swal from 'sweetalert2'
import moment from 'moment'

const NotificationsReport = (mainColWidth) => {
    let d = new Date()
    d.setMonth(d.getMonth() - 3)
    let td = new Date()
    const user = useUser()

    const [users, setUsers] = useState([])
    const [selectedId, setSelectedId] = useState('all')
    const [fromDate, setFromDate] = useState(d)
    const [toDate, setToDate] = useState(td)
    useEffect(() => {
        api.getAllUsersWithPortflioBySameAccount(user.user_id).subscribe(
            // api.getUserPortolios().subscribe(
            (response) => {
                console.log('users with files', response.data)
                const data = response?.data?.list || response?.data
                if (data) {
                    var list = data.map((item) => {
                        return {label: item.email, value: item.id}
                    })
                    setUsers([{label: 'All', value: 'all'}, ...list])
                }
            },
            (error) => console.log('error getting files', error),
        )
    }, [])
    const getReport = () => {
        console.log('before get excel', moment(fromDate).format('YYYY-MM-DD'))

        api.getUserPortfoliosNotificationsReport(
            selectedId,
            moment(fromDate).format('YYYY-MM-DD'),
            moment(toDate).format('YYYY-MM-DD'),
            user.user_id,
        )
    }
    useEffect(() => {
        console.log('users', users)
    }, [users])

    return (
        <Container>
            <Title text={'Portfolio Notifications Report'} />
            <Row>
                <FrameSelect
                    title={'Select User'}
                    values={users}
                    value={selectedId}
                    setValue={setSelectedId}
                    style={{width: 400}}
                    searchable
                />
            </Row>
            <Row>
                <DateRange
                    fromDate={fromDate}
                    toDate={toDate}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    mainColWidth={400}
                />
            </Row>
            <Row style={{alignItems: 'flex-end', flexDirection: 'column'}}>
                <SendButton onClick={getReport}>Get report</SendButton>
            </Row>
        </Container>
    )
}

export default NotificationsReport

const Container = styled.div`
    font-family: 'Inter';
    width: 450px;
`
const Row = styled.div`
    margin-top: 30px;
    display: flex;
    border: 0px solid green;
    width: 400px;
`
