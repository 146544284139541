import React from 'react'
import {api} from '@services/api'
import {useState, useEffect} from '@services/hooks'
import styled from 'styled-components'
import BaseManageTable from './BaseManageTable'
import {Link} from 'react-router-dom'
import IconButton from 'material-ui/IconButton'
import {grey400} from 'material-ui/styles/colors'
import EditAnalyst from './EditAnalyst'

const columns = [
    {
        accessor: 'id',
        Header: 'id',
        Cell: (row) => <div style={{fontSize: 7}}>{row.value}</div>,
        sortable: false,
    },
    //{accessor: 'email', Header: 'Email'},
    {
        accessor: (row) =>
            row.nick_name
                ? row.nick_name
                : (row.first_name ? row.first_name : '') +
                  ' ' +
                  (row.last_name ? row.last_name : ''),
        Header: 'Name',
        sortable: true,
    },

    {
        accessor: 'global_name',
        Header: 'Global Name',
        sortable: true,
    },
    {
        accessor: 'id_',
        Cell: (row) => (
            <Link to={'/manage/analyst/' + row.row.values.id}>
                <IconButton
                    style={{height: 25}}
                    iconStyle={{
                        color: grey400,
                        fontSize: 15,
                    }}
                    //onClick={() => EditGlobalName(row.row.values)}
                    iconClassName="material-icons">
                    edit
                </IconButton>
            </Link>
        ),
    },
]

const AnalystsTable = () => {
    const [tableData, setTableData] = useState(null)
    const [editNameDialog, setEditNameDialog] = useState(false)
    const [name, setName] = useState('')
    const [globalName, setGlobalName] = useState('')

    // const EditGlobalName = (row) => {
    //     console.log(row)
    //     setName(row.Name)
    //     setGlobalName(row.global_name)
    //     setEditNameDialog(true)
    // }

    useEffect(() => {
        api.getAnalysts().subscribe(
            (response) => {
                console.log('getAnalysts ', response.data)
                setTableData(response.data.data)
            },
            (error) => {
                console.log('getAnalysts error', error)
            },
        )
    }, [])

    const applySearchFilter = (searchText) => {
        return tableData.filter(
            (d) =>
                d.id.indexOf(searchText) !== -1 ||
                (d.email &&
                    d.email.toLowerCase().indexOf(searchText.toLowerCase()) !==
                        -1) ||
                (d.nick_name &&
                    d.nick_name
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) !== -1) ||
                (d.first_name &&
                    d.first_name
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) !== -1) ||
                (d.last_name &&
                    d.last_name
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) !== -1) ||
                (d.global_name &&
                    d.global_name
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) !== -1),
        )
    }

    return (
        <Container>
            {tableData && (
                // <InsightTable
                //     columns={columns}
                //     data={tableData}
                // />
                <BaseManageTable
                    mode="profile"
                    columns={columns}
                    searchFilter={applySearchFilter}
                    data={tableData}
                />
            )}
        </Container>
    )
}

export default AnalystsTable

const Container = styled.div`
    dispaly: flex;
    flex: 1;
    margin-top: 20px;
`
