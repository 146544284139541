import * as types from '../ActionTypes'
import _ from 'lodash'

const defaultState = {
    isFetching: {},
    initialized: false,
    marketOpen: null,
    data: {},
}

const financeReducer = (state = defaultState, action) => {
    if (action.type === types.MARKET_STATUS_UPDATE) {
        return Object.assign({}, state, {
            marketOpen: action.open,
        })
    } else if (action.type === types.PORTFOLIO_STATUS_UPDATE) {
        let newState = Object.assign({}, state)
        if (action.data) {
            newState.data.sectors = action.data
        }
        return newState
    } else if (action.type === types.COMMIT_REMOVED_POSITION) {
        let newState = Object.assign({}, state)
        if (
            action.positionId &&
            newState.data.finance_info.positions &&
            newState.data.finance_info.positions[action.positionId]
        ) {
            delete newState.data.finance_info.positions[action.positionId]
        }
        return newState
    } else if (action.type === types.WATCHLIST_UPDATE) {
        let newState = Object.assign({}, state)
        if (action.followingSymbols) {
            // 06.11.22 add map, save thiner value. not done - see comments in calling to _getFollowingSymbols
            newState.data.followingSymbols = action.followingSymbols.map(
                (item) => {
                    return {symbol: item.symbol}
                },
            )
        }
        return newState
    } else if (
        ['getUserFinanceInfo', 'getUserProfileData'].indexOf(action.method) > -1
    ) {
        switch (action.type) {
            case types.API_REQUEST:
                return Object.assign({}, state, {
                    isFetching: Object.assign({}, state.isFetching, {
                        [action.method]: true,
                    }),
                })
            case types.API_RESPONSE:
                let responseData =
                    action.data && action.data.data
                        ? action.data.data
                        : action.data

                let outputData = Object.assign({}, state.data, responseData)

                // Handle alerts
                // const exsitingAlerts = state.data.alerts || []
                // const exsitingAlertIds = exsitingAlerts.map(a => a.id)
                //
                // outputData.alerts = _.unionBy(exsitingAlerts, outputData.alerts, 'id').map(a => {
                //   if (exsitingAlertIds.indexOf(a.id) === -1) {
                //     // Alert is not in existing ID which means it's new
                //     a.entryTime = (new Date()).getTime()
                //   }
                //
                //   return a
                // })

                // Remove stuff we don't need
                if (outputData.user_data) delete outputData.user_data

                let newState = Object.assign({}, state, {
                    isFetching: Object.assign({}, state.isFetching, {
                        [action.method]: false,
                    }),
                    initialized: outputData.finance_info ? true : false,
                    data: outputData,
                })

                return newState
            case types.API_ERROR:
                return Object.assign({}, state, {
                    isFetching: Object.assign({}, state.isFetching, {
                        [action.method]: false,
                    }),
                    initialized: true,
                })

            default:
                return state
        }
    } else if (['login', 'logout'].indexOf(action.method) > -1) {
        switch (action.type) {
            case types.API_RESPONSE:
                // User logged in or logged out
                // Reset financial data
                return Object.assign({}, defaultState)
            default:
                return state
        }
    } else if (action.method === 'dismissAlert') {
        let newState = Object.assign({}, state)
        let alertId =
            action.data || (action.error ? action.error.alertId : null)
        const alertIndex = _.findIndex(newState.data.alerts, {id: alertId})

        if (alertIndex > -1) {
            switch (action.type) {
                case types.API_REQUEST:
                    newState.data.alerts[alertIndex].local_status = 'dismissing'
                    return newState
                case types.API_RESPONSE:
                    _.remove(newState.data.alerts, {id: alertId})
                    return newState
                case types.API_ERROR:
                    newState.data.alerts[alertIndex].local_status =
                        'dismiss_failed'
                    if (newState.data.alerts[alertIndex].mock === true) {
                        _.remove(newState.data.alerts, {id: alertId})
                    }
                    return newState
                default:
                    return state
            }
        }
    } else {
        switch (action.type) {
            case 'SET_IS_FINANCE_FETCHING':
                return {...state, isFeching: action.isFeching}

            case 'SET_FINANCE_INITIALIZED':
                return {...state, initialized: action.initialized}

            case 'SET_MARKET_OPEN':
                return {...state, marketOpen: action.marketOpen}

            case 'SET_FINANCE_DATA':
                return {
                    ...state,
                    data: {...state.data, ...(action.data.data || action.data)},
                    initialized: true,
                }
            case 'SET_UNIVERSES':
                return {...state, universes: action.universeList}
            default:
                return state
        }
    }

    return state
}

export default financeReducer
