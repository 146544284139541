import React from 'react'
import {useState, useEffect} from '@services/hooks'
import FrameInput from '@components/General/FrameInput'
import FrameText from '@components/General/FrameText'
import {Title} from '@components/General/Elements'
import styled, {createGlobalStyle} from 'styled-components'
import ReactTooltip from 'react-tooltip'
import {AppColors} from '@services/styles'
import Analytics from '@services/analytics'
import FileLineChart from '@components/General/FileLineChart'
import ReportDatesSelector from './ReportDatesSelector'
import GenerateReport from './GenerateReport'

const BackTestReport = ({user, mainColWidth, wideColWidth}) => {
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [tickers, setTickers] = useState('')
    const [benchmarks, setBenchmarks] = useState(['SPY'])

    useEffect(() => {
        Analytics.exec('track', 'select report Backtest')
    }, [])

    useEffect(() => {
        console.log('tickers_change', tickers, tickers.trim())
    }, [tickers])

    return (
        <Container id="mainDiv" style={{width: wideColWidth}}>
            <ReactTooltip />
            <RepTitle
                text={'Backtest'}
                //toolTip={title}
            />
            <ReportDatesSelector
                mainColWidth={mainColWidth}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                dateFormat={'MM/dd/yyyy'}
                wholeMonth={false}
            />
            <Row width={mainColWidth}>
                <FrameText
                    frameStyle={{height: 180}}
                    value={tickers}
                    setValue={setTickers}
                    title={'Symbols (separate by comma, space or new line)'}
                />
            </Row>
            <Row>
                {tickers.trim() && (
                    <FileLineChart
                        // refreshChart={refreshBtChart}
                        // setRefreshChart={setRefreshBtChart}
                        autoFirst={true}
                        tickers={tickers}
                        fromDate={fromDate}
                        toDate={toDate}
                        allowDates={false}
                        exposeBenchmarks={setBenchmarks}
                    />
                )}
            </Row>

            <GenerateReport
                user={user}
                mainColWidth={mainColWidth}
                fromDate={fromDate}
                toDate={toDate}
                actionText={'Generate Backtest Report'}
                reportType={'backtest'}
                tickers={tickers}
                benchmarks={benchmarks}
            />
            {/* {repType !== 'admin_pl' && (
                <>
                    <Row
                        width={mainColWidth}
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                        <SendButton onClick={() => getReportMail(false)}>
                            {repType === 'scored'
                                ? 'Generate and send opportunities list'
                                : 'Generate Report and Send to Email'}
                        </SendButton>
                        {repType === 'scored' && user.is_admin && (
                            <Link href={generateOpportunitiesReport()}>
                                <SendButton>
                                    Generate new opportunities list
                                </SendButton>
                            </Link>
                        )}
                    </Row>
                </>
            )} */}
        </Container>
    )
}

export default BackTestReport

const Container = styled.div`
    font-family: 'Inter';
`
const AdminTitle = styled.div`
    font-size: 16px;
    font-weight: 500;
`
const Row = styled.div`
    padding-top: 38px;
    width: ${(p) => p.width}px;
`
const FlexRow = styled(Row)`
    display: flex;
    justify-content: space-between;
    width: ${(p) => p.width}px;
    border: 0px solid purple;
    align-items: baseline;
`
const Subtitle = styled.div`
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    margin-top: 10px;
    color: ${AppColors.APP_GRAY};
`
// const McContainer = styled.div`
//     display: flex;
//     flex-direction: row;
//     border: 1px solid purple;
// `

const OverrideStyles = createGlobalStyle`
    .ReactCollapse--collapse {
        transition: height 600ms;
    }
`
// .kaka {
//         background-color: red;
//         border: 1px solid green;
//     }
const RepTitle = styled(Title)`
    display: flex;
    flex-direction: row;
`
// const Dates = styled.div`
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     width: ${(p) => p.mainColWidth}px;
//     border: 0px solid green;
// `

const Link = styled.a`
    text-decoration: none;
`
