import React, {Component} from 'react'
import {Dimensions} from '@services/styles'

//import Paper from 'material-ui/Paper'
import ReactTooltip from 'react-tooltip'
import _ from 'lodash'
import swal from 'sweetalert2'
import RangeSelector1 from '@components/General/RangeSelector1'
//import CheckboxList from '@components/General/CheckboxList'
//import RadioButtonList from '@components/General/RadioButtonList'
//import YesNoToggle from '@components/General/YesNoToggle'
//import {red400} from 'material-ui/styles/colors'
import {DeleteOutline} from '@mui/icons-material'
import {Indexes, ScreenersInfo} from '../constants/Finance'
import styled from 'styled-components'
import {
    useState,
    useEffect,
    useUser,
    useSectors,
    useSystemSectors,
    useSectorsLoading,
    useDispatch,
    useFinanceInitialized,
} from '@services/hooks'
import Header from './Header'
import ProfileSideBar from '@components/InvestmentProfile/ProfileSideBar'
import FilterUnit from '@components/InvestmentProfile/FilterUnit'
import CircularProgress from 'material-ui/CircularProgress'
import {api} from '@services/api'
import FrameSelect from '@components/General/FrameSelect'
import {SendButton, SendButtonDis} from '@components/General/Elements'
import {AppColors} from '@services/styles'

const EditProfile = ({}) => {
    const [EditSectors, setEditSectors] = useState([])
    const [selectedSector, setSelectedSector] = useState(null)
    const [loading, setLoading] = useState(true)
    const [reloadAll, setReloadAll] = useState(false)
    const [availScrreners, setAvailScrreners] = useState([])
    const [newScreenerName, setNewScreenerName] = useState('')

    useEffect(() => {
        //TODO: add conditions. user is dss user had profile, more...

        loadAll()
    }, [])

    useEffect(() => {
        if (reloadAll) {
            loadAll()
        }
    }, [reloadAll])

    const rebuildTeam = () => {
        // we don't need that, keep it only for easy rollback (server and client)
        const params = {data: {item: 1}}

        api.editSuperPortfolio(params).subscribe(
            (response) => {
                console.log('save profile response', response)

                swal.fire({
                    title: 'Rebuilding Team',
                    text: 'Profile is being processed in the background and will be ready in a few minutes',
                    confirmButtonColor: AppColors.TIPIGO_GREEN,
                })
            },
            (error) => {
                console.log('save profile error', error)
                swal.fire({
                    text: 'save failed. ' + error?.data?.result,
                    icon: 'error',
                    confirmButtonColor: AppColors.TIPIGO_GREEN,
                })
            },
        )
    }

    const loadAll = () => {
        api.getUserProfileData().subscribe(
            (response) => {
                console.log('got profile', response)
                if (response && response.data) {
                    if (response.data.sectors) {
                        setEditSectors(response.data.sectors)
                    } else {
                        //empty profile
                        setEditSectors([])
                        setLoading(false)
                    }

                    setReloadAll(false)
                }
            },
            (error) => {
                console.log('got profile error', error)
            },
        )
    }

    useEffect(() => {
        if (EditSectors) {
            console.log('got_edit_sectors', EditSectors)
            if (EditSectors.length) {
                setSelectedSector(EditSectors[0])
            }
            setLoading(false)
        }
    }, [EditSectors])

    useEffect(() => {
        if (selectedSector) {
            console.log('new_selected', selectedSector)
            let hasFilters = selectedSector.screeners.map((s) => s.field)
            //-------------------------------
            // BIG TODO: for now i don't let him add these. don't even know if they are working
            // they might adress real fields but the fields may not be updated (symbol, company_profile, more)
            // so before leting them back check each one! how it is used by screener AMD how is it updated
            hasFilters.push('pe_ratio_12m')
            hasFilters.push('avg_vol_20d')
            hasFilters.push('indexes')
            hasFilters.push('peg_ratio')
            //-------------------------------
            let filterNames = []
            Object.entries(ScreenersInfo).forEach(([key, value]) => {
                if (!hasFilters.includes(key)) {
                    filterNames.push({label: value.title, value: key})
                }
            })
            setAvailScrreners(filterNames)
            console.log('all', filterNames, hasFilters)
        }
    }, [selectedSector])

    const addScreener = () => {
        if (newScreenerName) {
            let newScreener = {
                field: newScreenerName,
                minVal: null,
                maxVal: null,
                pos_type: 2,
            }
            // not saved in DB at this stage. only after user saves the filter
            setSelectedSector({
                ...selectedSector,
                screeners: [...selectedSector['screeners'], newScreener],
            })
        }
    }
    const deleteSector = () => {
        // api is deleteScreener but used here to delete a sector with no screeners.
        // show this button only if there are no screeners.
        // can change this behavior but than need a "real" delete sector function. see commnts below
        swal.fire({
            //title: 'Delete sector ',
            text: 'Delete sector ' + selectedSector.name + '?',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: AppColors.TIPIGO_GREEN,
            confirmButtonText: 'Delete',
        }).then(
            (response) => {
                if (response && response.isConfirmed) {
                    setLoading(true)
                    api.deleteScreener(
                        selectedSector.sector_ref_id,
                        '',
                    ).subscribe(
                        (response) => {
                            console.log('deleteScreener response', response)
                            setSelectedSector(null)
                            setReloadAll(true)
                        },
                        (error) => {
                            console.log('deleteScreener error', error)
                        },
                    )
                }
            },
            (error) => {
                console.log('cancelled')
            },
        )
    }

    return (
        <div>
            <Header />
            {loading ? (
                <Circular style={{flex: 1, alignSelf: 'center'}} />
            ) : (
                <Container>
                    <ProfileSideBar
                        sectors={EditSectors}
                        currentSector={selectedSector}
                        setCurrentSector={setSelectedSector}
                        setReloadAll={setReloadAll}
                        setLoading={setLoading}
                    />
                    <MainPart>
                        {selectedSector &&
                            selectedSector.screeners &&
                            selectedSector.screeners.map((screener) => {
                                return (
                                    <FilterUnit
                                        key={screener.id}
                                        screener={screener}
                                        setLoading={setLoading}
                                        setReloadAll={setReloadAll}
                                        currentSector={selectedSector}
                                    />
                                )
                            })}
                        {selectedSector ? (
                            selectedSector.screeners.length == 0 && (
                                <NoScreeners>
                                    No filters yet. Add a filter below
                                </NoScreeners>
                            )
                        ) : (
                            <NoScreeners>
                                No Profile definitions. Select a sector on the
                                right to get started
                            </NoScreeners>
                        )}
                        {selectedSector && availScrreners?.length && (
                            <BottomLine>
                                <AddFilterCont>
                                    <FrameSelect
                                        title={'Add Filter'}
                                        style={{marginRight: 15, width: 200}}
                                        values={availScrreners}
                                        value={newScreenerName}
                                        setValue={setNewScreenerName}
                                    />

                                    <SendButton
                                        onClick={addScreener}
                                        style={{alignSelf: 'end'}}>
                                        Add
                                    </SendButton>
                                </AddFilterCont>
                                {selectedSector.screeners.length == 0 && (
                                    // allow this only if there are no filters yet, otherwise make user delete all filters
                                    // when last deleted so will sector. this will show after adding sector and before adding filters
                                    <DeleteIcon
                                        onClick={deleteSector}
                                        fontSize={'large'}
                                    />
                                )}
                            </BottomLine>
                        )}
                    </MainPart>
                    {/* <ItemsDialog
                        options={_.map(_.values(ScreenersInfo), (o) => o.title)}
                        title="Select Filters"
                        // preSelected={screenerNames}
                        // submit={handleSelectedScreenerUpdate}
                        // handleClose={handleFiltersDialogClose}
                        // open={filtersModalOpen}
                    /> */}
                    <RebuildCont>
                        <SendButton onClick={rebuildTeam}>
                            Rebuild Team
                        </SendButton>
                    </RebuildCont>
                </Container>
            )}
        </div>
    )
}

export default EditProfile

const Container = styled.div`
    margin-top: ${Dimensions.NAVBAR_TOP_OFFSET}px;
    paddingtop: ${Dimensions.APP_BAR_HEIGHT + 20}px;
    display: flex;
`
const MainPart = styled.div`
    padding: 20px;
    background-color: #eee;
    flex: 2;
`
const RebuildCont = styled.div`
    display: flex;
    align-items: flex-end;
    padding-top: 23px;
    padding-right: 10px;
    border: 0px solid pink;
    flex: 0.7;
    flex-direction: column;
`
const AddFilterCont = styled.div`
    display: flex;
`
const BottomLine = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
`
const Circular = styled(CircularProgress)`
    position: absolute;
    top: ${Dimensions.NAVBAR_TOP_OFFSET + 120}px;
    transform: translateX(-50%);
    left: 50%;
`
const NoScreeners = styled.div`
    border: 0px solid green;
    display: flex;
    justify-content: center;
    font-size: 19px;
    padding: 19px;
    flex: 1;
`
const DeleteIcon = styled(DeleteOutline)`
    color: ${AppColors.RED_TEXT};
    align-self: end;
`
