import React from 'react'
import FrameInput from '@components/General/FrameInput'
import FrameSelect from '@components/General/FrameSelect'
import styled from 'styled-components'
import {useEffect} from 'react'

const RangeFilter = ({
    title,
    condition,
    setCondition,
    val1,
    setVal1,
    val2,
    setVal2,
    fromTitle,
}) => {
    useEffect(() => {
        if (condition === 0 || condition === '0') {
            setVal1('')
            setVal2('')
        } else if (condition === 1) {
            setVal1('')
        } else if (condition === 2) {
            setVal2('')
        }
    }, [condition])
    return (
        <McContainer>
            <FrameSelect
                value={condition}
                values={[
                    {value: '0', label: 'none'},
                    {value: 1, label: 'lower than'},
                    {value: 2, label: 'greater than'},
                    {value: 3, label: 'range'},
                ]}
                setValue={setCondition}
                title={title}
                //style={{marginRight: 10, width: 160}}
                style={{marginRight: 20, flex: 2}}
            />

            <FrameInput
                title={fromTitle ? fromTitle : 'from'}
                disabled={['0', 0, 1].includes(condition)}
                value={val1}
                setValue={setVal1}
                //style={{width: 70, marginRight: 10}}
                style={{marginRight: 20, flex: 1}}
                maxLength={10}
            />

            <FrameInput
                title={'to'}
                disabled={['0', 0, 2].includes(condition)}
                value={val2}
                setValue={setVal2}
                //style={{width: 65}}
                style={{flex: 1}}
                maxLength={10}
            />
        </McContainer>
    )
}

export default RangeFilter

const McContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    border: 0px solid purple;
`
