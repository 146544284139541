import React from 'react'
import {PageContent} from './Home.styled'
import styled from 'styled-components'
import {useTable, useSortBy, useBlockLayout} from 'react-table' //usePagination
import {useSticky} from 'react-table-sticky'

const columns = [
    {accessor: 'a', Header: 'a'}, //sticky: 'left'
    {accessor: 'b', Header: 'b'},
    {accessor: 'c', Header: 'c'}, //, sticky: 'right'
]
const data = [
    {a: '111', b: '111', c: '111'},
    {a: '222', b: '222', c: '222'},
    {a: '333', b: '333', c: '333'},
    {a: '444', b: '444', c: '444'},
    {a: '555', b: '555', c: '555'},
    {a: '666', b: '666', c: '666'},
    {a: '777', b: '777', c: '777'},
    {a: '888', b: '888 ndjcn', c: '88hh'},
    {a: '999', b: '999', c: 'rerter'},
    {a: '000aksjas njnaS', b: '00ajs s', c: '777'},
]
const Test = () => {
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 15,
            width: 100,
            maxWidth: 400,
        }),
        [],
    )

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
        useTable(
            {
                columns,
                data,
                defaultColumn,
            },
            useBlockLayout,
            //useSticky, this was the whole point(?) but it seems ok without it
        )

    return (
        <PageContent>
            <Container>here I am</Container>
            <Styles>
                <div
                    {...getTableProps()}
                    className="table sticky"
                    style={{height: 200}}>
                    <div className="header">
                        {headerGroups.map((headerGroup) => (
                            <div
                                {...headerGroup.getHeaderGroupProps()}
                                className="tr">
                                {headerGroup.headers.map((column) => (
                                    <div
                                        {...column.getHeaderProps()}
                                        className="th">
                                        {column.render('Header')}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                    <div {...getTableBodyProps()} className="body">
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <div {...row.getRowProps()} className="tr">
                                    {row.cells.map((cell) => {
                                        return (
                                            <div
                                                {...cell.getCellProps()}
                                                className="td">
                                                {cell.render('Cell')}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Styles>
        </PageContent>
    )
}

export default Test

const Container = styled.div`
    dispaly: flex;
    flex: 1;
    font-size: 20px;
`

const Styles = styled.div`
    padding: 1rem;

    .table {
        .th,
        .td {
            padding: 5px;
            border-bottom: 1px solid #ddd;
            border-right: 1px solid #ddd;
            background-color: #fff;
            overflow: hidden;
            width: 50px;
            :last-child {
                border-right: 0;
            }
        }

        &.sticky {
            overflow: scroll;
            .header,
            .footer {
                position: sticky;
                z-index: 1;
                width: fit-content;
            }

            .header {
                top: 0;
                box-shadow: 0px 3px 3px #ccc;
            }

            .footer {
                bottom: 0;
                box-shadow: 0px -3px 3px #ccc;
            }

            .body {
                position: relative;
                z-index: 0;
            }
            [data-sticky-td] {
                position: sticky;
            }

            [data-sticky-last-left-td] {
                box-shadow: 2px 0px 3px #ccc;
            }

            [data-sticky-first-right-td] {
                box-shadow: -2px 0px 3px #ccc;
            }
        }
    }
`

const Styles_original_example = styled.div`
    padding: 1rem;

    .table {
        .tr {
            :last-child {
                .td {
                    border-bottom: 0;
                }
            }
        }

        .th,
        .td {
            padding: 5px;
            border-bottom: 1px solid #ddd;
            border-right: 1px solid #ddd;
            background-color: #fff;
            overflow: hidden;
            :last-child {
                border-right: 0;
            }

            .resizer {
                display: inline-block;
                width: 5px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(50%);
                z-index: 1;

                &.isResizing {
                    background: red;
                }
            }
        }

        &.sticky {
            overflow: scroll;
            .header,
            .footer {
                position: sticky;
                z-index: 1;
                width: fit-content;
            }

            .header {
                top: 0;
                box-shadow: 0px 3px 3px #ccc;
            }

            .footer {
                bottom: 0;
                box-shadow: 0px -3px 3px #ccc;
            }

            .body {
                position: relative;
                z-index: 0;
            }

            [data-sticky-td] {
                position: sticky;
            }

            [data-sticky-last-left-td] {
                box-shadow: 2px 0px 3px #ccc;
            }

            [data-sticky-first-right-td] {
                box-shadow: -2px 0px 3px #ccc;
            }
        }
    }
`

// <table {...getTableProps()}>
//                     <thead>
//                         {headerGroups.map((headerGroup) => (
//                             <tr {...headerGroup.getHeaderGroupProps()}>
//                                 {headerGroup.headers.map((column) => (
//                                     <th {...column.getHeaderProps()}>
//                                         {column.render('Header')}
//                                     </th>
//                                 ))}
//                             </tr>
//                         ))}
//                     </thead>

//                     <tbody {...getTableBodyProps()}>
//                         {rows.map((row, i) => {
//                             prepareRow(row)
//                             return (
//                                 <tr {...row.getRowProps()}>
//                                     {row.cells.map((cell) => {
//                                         return (
//                                             <td {...cell.getCellProps()}>
//                                                 {cell.render('Cell')}
//                                             </td>
//                                         )
//                                     })}
//                                 </tr>
//                             )
//                         })}
//                     </tbody>
//                 </table>
