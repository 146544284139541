import React from 'react'
import {
    api,
    baseNewApiUrl,
    newApiReports,
    newApiScannerSettings,
} from '@services/api'
import {useState, useEffect, useRef, useUser} from '@services/hooks'
import {
    SendButton,
    SendButtonDis,
    SendButton2,
    Title,
    FormTopRow,
    FormRow,
    IconButton,
} from '@components/General/Elements'
import swal from 'sweetalert2'
import styled from 'styled-components'
import OppScannerList from './OppScanner/OppScannerList'
import OppScannerRunner from './OppScanner/OppScannerRunner'
import OppScannerEdit from './OppScanner/OppScannerEdit'
import {AppColors, Dimensions} from '@services/styles'
import FileLineChart from '@components/General/FileLineChart'
import BulkAddPositions from '../Home/MyFile/BulkAddPositions'
import HorizontalStack from '@components/General/HorizontalStack'

// import FrameInput from '@components/General/FrameInput'
// import Dialog from '@mui/material/Dialog'
import {FileDownloadOutlined} from '@mui/icons-material'
import ReactTooltip from 'react-tooltip'
import qs from 'qs'
import {sectorShortName} from '@services/utils'

// import {formatUniverseResponse} from '@services/utils'

const editWidth = 750
const resultWidth = 1200
const defaultChartStart = new Date()
defaultChartStart.setMonth(defaultChartStart.getMonth() - 1)

const OppScanner = () => {
    const user = useUser()
    const [customIndexes, setCustomIndexes] = useState([])
    const [stage, setStage] = useState('pre')
    const [listSelected, setListSelected] = useState({})
    const [addPosDialog, setAddPosDialog] = useState(false)
    const [selectedSet, setSelectedSet] = useState(0)
    const [allSets, setAllSets] = useState([])
    const [sectors, setSectors] = useState([])
    const [universe, setUniverse] = useState('all')
    const [lastSelected, setLastSelected] = useState({})
    const [currentSettings, setCurrentSettings] = useState({})
    const [realtime, setRealtime] = useState(true)
    const [portfolioTickers, setPortfolioTickers] = useState([])

    const stageRef = useRef()
    stageRef.current = stage

    const addToFile = () => {
        setAddPosDialog(true)
    }

    useEffect(() => {
        console.log('stage_change', stage)

        if (stage == 'afterEdit') {
            getAllSettings()
            setStage('pre')
        }
        if (stage !== 'run' && stage !== 'results' && stage !== 'preRun') {
            // keep the name and count but clean results - in case of 'back' or 'cancel'
            setSectors(
                Object.keys(sectors).reduce((cur, key) => {
                    return Object.assign(cur, {
                        [key]: {
                            ...sectors[key],
                            done: false,
                            list: [],
                            alloc: null,
                        },
                    })
                }, {}),
            )
        }
        // if (stage == 'preRun') {
        //     getResults2()
        // }
    }, [stage])

    useEffect(() => {
        console.log('customIndexes_user', user.universeList)
    }, [user])

    useEffect(() => {
        // api.getTipigoIndexes(0).subscribe((response) => {
        //     setCustomIndexes(formatUniverseResponse(response, true))
        // })
        setCustomIndexes(user?.universeList || [])
        getPortfolioTickers()
        getAllSettings()
    }, [])

    const getPortfolioTickers = () => {
        api.getUserPortfolioTikers().subscribe((response) => {
            if (response && response.data) {
                setPortfolioTickers(response.data)
            }
        })
    }

    const formatNewScannerSettings = (response) => {
        if (response.data?.list) return response.data.list
        if (response.data?.data)
            return response.data.data.map((set) => {
                return {...set, id: set._id}
            })
        return []
    }
    const getAllSettings = () => {
        api.getAccountOppScannerSettings().subscribe(
            (response) => {
                console.log('getAllSettings response', response)
                const list = formatNewScannerSettings(response)
                var arr = list.map((item) => {
                    return {value: item.id, label: item.name}
                })
                setAllSets([...arr, {value: 0, label: 'Default'}])
            },
            (error) => {
                console.log('getAllSettings error', error)
            },
        )
    }

    useEffect(() => {
        const done =
            Object.entries(sectors).filter(([key, sector]) => !sector.done)
                .length === 0

        if (stage == 'run' && done) {
            // mark done when ALL are done
            setStage('results')
        }
        // const all = sectors.all
        // if (all?.list?.length && !all.alloc) {
        //     setSectors({
        //         ...sectors,
        //         all: {
        //             ...sectors['all'],
        //             alloc: getSectorAllocationSet(all.list),
        //         },
        //     })
        //     console.log('got_all', all)
        // }
        console.log('sectors changed', sectors, done)
    }, [sectors])

    useEffect(() => {
        const all = sectors.all
        if (all?.list?.length) {
            const newAllocations = getSectorAllocationSet(
                all.list.filter(
                    (opp) =>
                        listSelected.All?.includes(opp.ticker) ||
                        listSelected.All?.length === 0 ||
                        !listSelected.All,
                ),
            )
            setSectors({
                ...sectors,
                all: {
                    ...sectors['all'],
                    alloc: newAllocations,
                },
            })
        }
    }, [listSelected.All])

    useEffect(() => {}, [listSelected.All])

    const defaultScreeners = {
        daysToEarning: 14,
        minUpside: 5,
        minEps: 0,
        // minRoe: 0, 0 !== undefined !!!
        // filterAdr: false,
        removeIfAnyShort: true,
        excludeNoRatings: true,
    }

    const getBasicQueryParamsNew = () => {
        const {screeners, _id} = currentSettings
        if (!_id) return defaultScreeners
        var basicSelectionParams = {}
        if (screeners) {
            basicSelectionParams = {...screeners}

            const rangeFilters = ['beta', 'market_val']
            rangeFilters.forEach((filter) => {
                const newName = filter === 'market_val' ? 'marketCap' : filter
                const min = screeners[newName]?.min
                const max = screeners[newName]?.max
                if (screeners[newName]) {
                    //TODO: get rid of condition cross system - we don't need that
                    if (min && max) basicSelectionParams[newName + 'Cond'] = 3
                    else if (min && !max)
                        basicSelectionParams[newName + 'Cond'] = 2
                    else if (!min && max)
                        basicSelectionParams[newName + 'Cond'] = 1
                    basicSelectionParams[newName + 'Val1'] = min
                    basicSelectionParams[newName + 'Val2'] = max
                }
            })
            console.log(
                'currentSettings',
                currentSettings,
                basicSelectionParams,
            )
        }
        return basicSelectionParams
    }

    // const getBasicQueryParamsOld = () => {
    //     const {screeners, id} = currentSettings
    //     if (!id) return defaultScreeners
    //     const {
    //         val1: marketCapVal1,
    //         val2: marketCapVal2,
    //         condition: marketCapCond,
    //     } = screeners?.find((item) => item.type === 'market_val') || {}
    //     const {
    //         val1: betaVal1,
    //         val2: betaVal2,
    //         condition: betaCond,
    //     } = screeners?.find((item) => item.type === 'beta') || {}
    //     return {
    //         daysToEarning: currentSettings.filter_earning,
    //         minUpside: currentSettings.filter_target,
    //         minEps: currentSettings.min_eps,
    //         minRoe: currentSettings.min_roe,
    //         marketCapVal1,
    //         marketCapVal2,
    //         marketCapCond,
    //         betaVal1,
    //         betaVal2,
    //         betaCond,
    //     }
    // }

    const getFixedQueryParams = () => {
        const basicSelectionParams = newApiScannerSettings
            ? getBasicQueryParamsNew()
            : {} //getBasicQueryParamsOld()

        const thinerParams = {
            minEps: basicSelectionParams.minEps,
            minRoe: basicSelectionParams.minRoe,
            marketCapVal1: basicSelectionParams.marketCapVal1,
            marketCapVal2: basicSelectionParams.marketCapVal2,
            marketCapCond: basicSelectionParams.marketCapCond,
            betaVal1: basicSelectionParams.betaVal1,
            betaVal2: basicSelectionParams.betaVal2,
            betaCond: basicSelectionParams.betaCond,
            filterAdr: basicSelectionParams.filterAdr,
            excludeNoRatings: basicSelectionParams.excludeNoRatings,
        }

        const queryParams = {}
        queryParams.tickerSelectionParams = {
            ...thinerParams,
            universeId: universe,
            daysToEarning: basicSelectionParams.daysToEarning || false,
        }

        queryParams.reportParams = {
            currentDay: realtime,
            minUpside: basicSelectionParams.minUpside || false,
            removeIfAnyShort: basicSelectionParams.removeIfAnyShort || false,
        }

        queryParams.debugOptions = {
            debugPrint: false,
            minimal: true,
            timings: true,
        }
        queryParams.isAdmin = user.is_admin
        return queryParams
    }

    const getOppScannerExcelUrl = () => {
        const queryParams = getFixedQueryParams()
        queryParams.tickerSelectionParams.sectorsWithNumberOfOpportunities =
            Object.keys(sectors).map((sectorId) => ({
                sectorId,
                numberOfOpportunities: sectors[sectorId].count,
            }))

        queryParams.tickerSelectionParams.selected =
            Object.values(listSelected).flat()

        // console.log('tickerSelectionParams.selected', qs.stringify(queryParams))

        return `${baseNewApiUrl}/reports/getOpportunityScannerExcel?${qs.stringify(
            queryParams,
        )}`
    }

    const getResultsNew = () => {
        console.log('get results new')

        const tempList =
            Object.keys(lastSelected).length == 0
                ? {...sectors}
                : {...sectors, [lastSelected.key]: lastSelected.value}

        setSectors(tempList)

        setStage('run')

        const queryParams = getFixedQueryParams()

        Object.keys(tempList).forEach((sectorId) => {
            const selectedSectors = [sectorId]
            console.log('tempList', tempList)
            const sectorQueryParams = {...queryParams}
            sectorQueryParams.tickerSelectionParams.sectorIds = selectedSectors
            queryParams.reportParams.maxNumberOfOpportunities =
                tempList[sectorId].count
            // queryParams.reportParams.minUpside = 21
            console.log('query', queryParams)

            api.getOpportunityScanner(queryParams).subscribe((res) => {
                console.log('res', res)

                setSectors((prev) => ({
                    ...prev,
                    [sectorId]: {
                        ...tempList[sectorId],
                        list: res.data.opportunities.map((opp) => {
                            return {
                                ...opp,
                                in_file: portfolioTickers.includes(opp.ticker),
                            }
                        }),
                        done: true,
                    },
                }))
            })
        })
    }

    // const getResults = (file) => {
    //     // not active in new. for old only
    //     setStage('preRun')
    //     const tempList =
    //         Object.keys(lastSelected).length == 0
    //             ? {...sectors}
    //             : {...sectors, [lastSelected.key]: lastSelected.value}
    //     setSectors(tempList)
    // }

    const getExcel = () => {
        // not active in new. for old only
        const items = Object.values(sectors).map((item) => {
            // create a new thiner object
            return {name: item.name, count: item.count}
        })

        api.performanceReportDirectExcel(
            selectedSet,
            universe,
            encodeURIComponent(JSON.stringify(items)),
        )
    }

    // const getResults2 = () => {
    //     // not active in new. for old only
    //     // Tamir changed stage from 'preRun' to 'pre' so this is skipped
    //     setStage('run')
    //     var startTime = performance.now()
    //     Object.entries(sectors).forEach(([key, sectorValue]) => {
    //         console.log('in_loop')
    //         api.performanceReportDirect(
    //             selectedSet,
    //             universe,
    //             sectorValue.count,
    //             sectorValue.name == 'All' ? 'all' : sectorValue.name,
    //         ).subscribe(
    //             (response) => {
    //                 console.log(
    //                     'direct_response after ' +
    //                         ((performance.now() - startTime) / 1000).toFixed(0),
    //                     response,
    //                 )
    //                 if (stageRef.current === 'run') {
    //                     //did not work: subscribe takes prev state ?
    //                     setSectors((prev) => ({
    //                         ...prev,
    //                         [key]: {
    //                             ...sectorValue,
    //                             list: response.data.list,
    //                             done: true,
    //                         },
    //                     }))
    //                 } // else: user may have clicked cancel while running
    //             },
    //             (error) => {
    //                 setStage('pre')
    //                 swal.fire({
    //                     text: 'Timeout, please contact support',
    //                     icon: 'error',
    //                     confirmButtonColor: AppColors.TIPIGO_GREEN,
    //                 })
    //                 console.log(
    //                     'direct rep_error after ' +
    //                         ((performance.now() - startTime) / 1000).toFixed(0),
    //                     error,
    //                 )
    //             },
    //         )
    //     })
    // }

    const getSectorAllocationSet = (list) => {
        console.log('getSectorAllocationSet', list)
        const bySector = list.reduce((acc, opp) => {
            acc[opp.rawSector] = (acc[opp.rawSector] || 0) + 1
            return acc
        }, {})
        const set = Object.entries(bySector).map(([sector, count]) => {
            var p = count / list.length
            const name = sectorShortName(sector)

            return {
                label: name + ' ' + (p * 100).toFixed(p > 0.01 ? 0 : 2) + '%',
                sector: name,
                value: count,
                per: p,
            }
        })
        return [{title: 'Sectors allocation', data: set}]
    }

    return (
        <Container stage={stage}>
            <ReactTooltip id="path" />
            <FormTopRow>
                <FormTopRow style={{flexDirection: 'column'}}>
                    <Title
                        text={
                            stage === 'edit'
                                ? selectedSet === 0
                                    ? 'New Settings'
                                    : 'Edit Settings'
                                : 'New Opportunities List'
                        }
                    />

                    <Subtitle>
                        {stage == 'pre'
                            ? 'Discover New Trading Opportunities. This tool is useful when you are opening a new portfolio or replacing existing positions'
                            : stage == 'results'
                            ? "Use the checkboxes to select or unselect opportunities. To quickly select all opportunities, simply use the checkbox in the header. Once you've made your selections, scroll down to review the performance of the chosen symbols. Click the next button, to easily add the selected positions to your portfolio."
                            : ''}
                    </Subtitle>
                </FormTopRow>
                {stage == 'results' && (
                    <>
                        {!newApiReports && (
                            <IconButton
                                data-tip={'Downlod results to excel'}
                                data-for="path">
                                <FileDownloadOutlined onClick={getExcel} />
                            </IconButton>
                        )}
                        {(user.is_admin ||
                            window.location.hostname === 'localhost' ||
                            newApiReports) && (
                            <Link
                                href={getOppScannerExcelUrl()}
                                onClick={() =>
                                    swal.fire({
                                        html: 'The report request was successfully sent. <br />The download should start shortly.',
                                        confirmButtonColor:
                                            AppColors.TIPIGO_GREEN,
                                        icon: 'success',
                                    })
                                }>
                                <IconButton
                                    data-tip={'Downlod results to excel'}
                                    data-for="path">
                                    <FileDownloadOutlined
                                        sx={{color: '#444444'}}
                                    />
                                </IconButton>
                            </Link>
                        )}
                    </>
                )}
            </FormTopRow>
            {stage == 'pre' && (
                <OppScannerRunner
                    //setDirectResults={setDirectResults}
                    customIndexes={customIndexes}
                    setStage={setStage}
                    stage={stage}
                    selectedSet={selectedSet}
                    setSelectedSet={setSelectedSet}
                    allSets={allSets}
                    sectors={sectors}
                    setSectors={setSectors}
                    // getResults={getResults}
                    getResultsNew={getResultsNew}
                    universe={universe}
                    setUniverse={setUniverse}
                    lastSelected={lastSelected}
                    setLastSelected={setLastSelected}
                    onGotNewSettings={setCurrentSettings}
                    user={user}
                    realtime={realtime}
                    setRealtime={setRealtime}
                />
            )}
            {stage === 'edit' && (
                <OppScannerEdit
                    selectedSet={selectedSet}
                    setStage={setStage}
                    setSelectedSet={setSelectedSet}
                />
            )}

            <MainRow>
                {(stage === 'results' || stage === 'run') &&
                    Object.entries(sectors).map(([key, sector], i) => {
                        return (
                            <Row key={key} style={{flexDirection: 'column'}}>
                                <SectorTitle>{sector.name}</SectorTitle>
                                {sector.alloc && (
                                    <>
                                        <HorizontalStack
                                            title={' '}
                                            // showMainTitle={false}
                                            setsData={sector.alloc}
                                        />
                                        <div style={{height: 20}} />
                                    </>
                                )}
                                <OppScannerList
                                    tableWidth={resultWidth}
                                    done={sector.done}
                                    data={sector.list}
                                    setListSelected={setListSelected}
                                    listSelected={listSelected}
                                    sector={sector.name}
                                />
                            </Row>
                        )
                    })}
                {stage === 'results' && (
                    <>
                        <Row>
                            <SendButton2
                                style={{marginRight: 20}}
                                onClick={() => setStage('pre')}>
                                Back
                            </SendButton2>
                            {Object.keys(listSelected).length > 0 ? (
                                <SendButton onClick={addToFile}>
                                    Next
                                </SendButton>
                            ) : (
                                <SendButtonDis>Next</SendButtonDis>
                            )}
                        </Row>
                        {Object.keys(listSelected).length > 0 && (
                            <>
                                <Row>
                                    <FileLineChart
                                        fromDate={defaultChartStart}
                                        autoFirst={true}
                                        tickers={Object.values(
                                            listSelected,
                                        ).flat()}
                                        contStyle={{flex: 1}}
                                    />
                                </Row>
                                <Row>
                                    <SendButton2
                                        style={{marginRight: 20}}
                                        onClick={() => setStage('pre')}>
                                        Back
                                    </SendButton2>
                                    <SendButton onClick={addToFile}>
                                        Next
                                    </SendButton>
                                </Row>
                            </>
                        )}
                    </>
                )}
            </MainRow>

            <BulkAddPositions
                isOpenDialog={addPosDialog}
                setIsOpenDialog={setAddPosDialog}
                tickers={Object.values(listSelected).flat()}
                msgOnSuccess
            />
        </Container>
    )
}

export default OppScanner

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 0px solid blue;
    font-family: 'Inter';
    width: ${(p) => (p.stage == 'results' ? resultWidth : editWidth)}px;
`
const MainRow = styled(FormRow)`
    border: 0px solid pink;
    flex-direction: column;
`
const Row = styled(FormRow)`
    border: 0px solid green;
    margin-bottom: 30px;
    justify-content: flex-end;
`
const SectorTitle = styled.div`
    font-size: 19px;
    margin-bottom: 10px;
    margin-left: 7px;
`
const Subtitle = styled.div`
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    margin-top: 10px;
    color: ${AppColors.APP_GRAY};
`

const Link = styled.a``
