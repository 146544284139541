import React, { Component, PropTypes } from 'react';
import { Link } from 'react-router-dom';

import AppBar from 'material-ui/AppBar';
import FlatButton from 'material-ui/FlatButton';
import FontIcon from 'material-ui/FontIcon';

import Header from './Header';

class NoMatch extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {

    return (
      <div>
        <Header />

        <div style={{padding: 140, textAlign: 'center'}}>
          <h1>Oh! No! 404</h1>
          <FontIcon className="fa fa-frown-o" style={{
              fontSize: 64
            }}></FontIcon>
          <h3>Sorry, there's nothing here</h3>
          <Link to="/">
            <FlatButton secondary={true}
              label="Let's go back home..." />
          </Link>
        </div>
      </div>

    )
  }
}

export default NoMatch;
