import React from 'react'
import CircularProgress from 'material-ui/CircularProgress'
import {AppColors} from '@services/styles'

import birdUrl from '@images/bird-ai-50-opacity.png'

const Loading = () => {
    return (
        <div>
            <div
                style={{
                    paddingTop: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundImage: 'url(' + birdUrl + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    minHeight: 600,
                }}>
                <div
                    style={{
                        flex: 1,
                        textAlign: 'center',
                        padding: 30,
                        flexDirection: 'column',
                    }}>
                    <div style={{flex: 1}}>
                        <img
                            style={{width: 120}}
                            src={require('@images/tipigo_logo.png').default}
                        />
                    </div>

                    <div style={{flex: 1}}>
                        <CircularProgress color={AppColors.TIPIGO_GREEN} />
                    </div>

                    <div>Authenticating...</div>
                </div>

                <div
                    style={{
                        position: 'fixed',
                        height: 60,
                        bottom: 0,
                        width: '100%',
                        textAlign: 'center',
                        color: '#aaa',
                        fontSize: 12,
                    }}>
                    Tipigo &copy; 2017
                </div>
            </div>
        </div>
    )
}

export default Loading
