import React, {Component} from 'react'
// import styled from 'styled-components'
// import {api} from '@services/api'
// import Dialog from '@mui/material/Dialog'
// import CloseIcon from '@mui/icons-material/Close'
// import {useState, useEffect} from '@services/hooks'
// import CircularProgress from 'material-ui/CircularProgress'
// import {AppColors} from '@services/styles'
// import {SymbolInTable, ScrollTableStyles} from '@components/General/Elements'
// import {
//     // Header, BasedOn,
//     BasedBox,
//     BasedElement,
// } from './ExploreBox.ducks'
// import ScrollTable from '@components/General/ScrollTable'
// import moment from 'moment'
import _ from 'lodash'
import ExploreBox from './ExploreBox'
import {Triggre, AllWrapper} from './Explore.styled'

export default class ExploreButton extends Component {
    state = {
        open: false,
    }
    forceClose = (e) => {
        if (this.state.open) {
            this.toggleOpen(e)
        }
    }
    toggleOpen = (e) => {
        const {open} = this.state
        e && e.preventDefault()
        e && e.stopPropagation()
        this.setState({open: !open})
    }
    render = () => {
        const {
            symbol,
            analystName,
            successRate,
            sector,
            alertId,
            bsRate,
            ...props
        } = this.props

        const data = {
            symbol,
            analystName,
            successRate,
            sector,
            alertId,
            bsRate,
        }

        const {open} = this.state
        return (
            <AllWrapper>
                <Triggre onClick={this.toggleOpen} {...props}>
                    see details
                </Triggre>
                {open && (
                    <ExploreBox {...data} open onClose={this.forceClose} />
                )}
            </AllWrapper>
        )
    }
}

// export const ExploreBox = ({
//     symbol,
//     sector,
//     analystName,
//     successRate,
//     open,
//     onClose,
//     alertId,
//     bsRate,
// }) => {
//     const [tableData, setTableDate] = useState(null)
//     const [profile, setProfile] = useState('')
//     const [buildDate, setBuildDate] = useState('')

//     useEffect(() => {
//         api.getAlertExploreList(alertId).subscribe(
//             (response) => {
//                 if (response.data && response.data.analyst_performance) {
//                     const sorted = response.data.analyst_performance
//                         .map((o, i) => ({
//                             num: i,
//                             ..._.mapValues(o, (val) =>
//                                 isNaN(val) ? val : val,
//                             ),
//                         }))
//                         .sort((a, b) => (a.published > b.published ? 1 : -1))

//                     setTableDate(sorted)
//                     setBuildDate(response.data.team_update)
//                     setProfile(response.data.profile)
//                     console.log('analystPerformance', sorted)
//                 }
//                 console.log('getAlertExploreList response', response)
//             },
//             (error) => {
//                 console.log('getAlertExploreList error', error)
//             },
//         )
//     }, [])

//     useEffect(() => {
//         console.log('alert id 33333', alertId, bsRate)
//     }, [bsRate])

//     return (
//         <Dialog open={open} maxWidth="lg" onClose={onClose}>
//             <MainContainer>
//                 <TopRow>
//                     <Title style={{minWidth: 800, lineHeight: '23px'}}>
//                         {/* use minWidth so it doesn't  "jump" from open when it got data */}
//                         Based on{' '}
//                         <span
//                             style={{
//                                 color: AppColors.TIPIGO_GREEN,
//                                 fontWeight: 600,
//                             }}>
//                             {analystName}'s
//                         </span>{' '}
//                         past performance in similar{' '}
//                         <span style={{color: AppColors.TIPIGO_GREEN}}>
//                             {sector}
//                         </span>{' '}
//                         companies.<br></br>
//                         {buildDate ? (
//                             <span>
//                                 By Analyst Team{' '}
//                                 <span style={{color: AppColors.APP_GRAY}}>
//                                     created on:{' '}
//                                     {moment(buildDate).format('MM/DD/YY')}
//                                 </span>
//                             </span>
//                         ) : (
//                             ''
//                         )}
//                     </Title>
//                     <Close onClick={onClose} />
//                 </TopRow>

//                 <SubHeader>
//                     <HeadBox>
//                         <BoxNumber style={{color: AppColors.TIPIGO_GREEN}}>
//                             {bsRate}
//                         </BoxNumber>
//                         <BoxText>Grade</BoxText>
//                     </HeadBox>
//                     <HeadBox>
//                         <BoxNumber>
//                             {tableData ? tableData.length : ''}
//                         </BoxNumber>
//                         <BoxText>Opportunities</BoxText>
//                     </HeadBox>
//                     <HeadBox>
//                         <BoxNumber>
//                             {tableData && tableData.length
//                                 ? tableData.filter((item) => item.target_date)
//                                       .length
//                                 : ''}
//                         </BoxNumber>
//                         <BoxText>Reached Target</BoxText>
//                     </HeadBox>
//                     <HeadBox last>
//                         <BoxNumber>
//                             {tableData && tableData.length
//                                 ? tableData.filter(
//                                       (item) => item.outperform_sector == 1,
//                                   ).length
//                                 : ''}
//                         </BoxNumber>
//                         <BoxText>Outperform</BoxText>
//                     </HeadBox>
//                 </SubHeader>
//                 {false && successRate && (
//                     <BasedBox>
//                         <BasedElement
//                             name="opportunities"
//                             value={tableData ? tableData.length : 0}
//                         />
//                         <BasedElement
//                             name="months"
//                             value={successRate.success_month_back}
//                         />
//                         {/* <BasedElement
//                             name="success"
//                             value={
//                                 _.round(
//                                     //successRate.full_period_success_percentage,
//                                     // 20.01.22 see above
//                                     // both are not clear, calculation is strange.. see in user_service
//                                     // anyway doesn't add up with numbers displayed
//                                     successRate.success_percentage,
//                                     1,
//                                 ) + '%'
//                             }
//                             color={AppColors.TIPIGO_GREEN}
//                         /> */}
//                     </BasedBox>
//                 )}

//                 {tableData ? (
//                     <ScrollTable
//                         columns={exploreColumns}
//                         data={tableData}
//                         TableStyles={ScrollTableStyles}
//                         height={window.innerHeight - 350}
//                         sortDefault={'published'}
//                         maxWidth={870}
//                     />
//                 ) : (
//                     <Circular />
//                 )}
//             </MainContainer>
//         </Dialog>
//     )
// }
// // #explorepop
// // adding maxWidth to one or more cols solves a bug that it doesnt sit well in dialog (edges are cut)
// // still.. not a good solution. probably can change somthong in table styles
// const exploreColumns = [
//     {
//         Header: 'Type',
//         accessor: 'pos_type',
//         Cell: (o) => (o.value == 'long' ? 'Buy' : 'Sell'),
//     },
//     {
//         Header: 'Published',
//         accessor: 'published',
//         Cell: (o) => moment(o.value).format('MM/DD/YY'),
//     },
//     {
//         Header: 'Ticker',
//         accessor: 'ticker',
//         Cell: (o) => (
//             <a href={'#/stock/' + o.value} style={{textDecoration: 'none'}}>
//                 <SymbolInTable>{o.value}</SymbolInTable>
//             </a>
//         ),
//         //maxWidth: 52,
//     },
//     {
//         Header: 'Entry Price',
//         accessor: 'entry_price',
//         Cell: (o) => _.round(o.value, 2),
//     },
//     {
//         Header: 'Target Price',
//         accessor: 'target',
//         Cell: (o) => (o && o.value ? parseFloat(o.value).toFixed(2) : ''), //use toFixed and not _.round because they want to show 14.00
//         //maxWidth: 52,
//     },
//     {
//         Header: 'Reached Target',
//         accessor: 'target_date',
//         Cell: (o) => (o.value !== '' ? moment(o.value).format('MM/DD/YY') : ''),
//     },
//     // 21.11.21 temp remove next 2. need to check what has to be and data is wrong
//     // Keren: Close price: last price
//     //        close date: is reach target
//     // BUT she is wrong!!! it had some other meaning, not sure what...
//     // for now remove these cols and add last price, last price also added in server.
//     // need some major reorganize of all server side

//     {
//         Header: 'Outperform',
//         accessor: 'outperform_sector',
//         Cell: (o) => (o.value == '1' ? 'Yes' : ''),
//         maxWidth: 83,
//     },
//     {
//         Header: 'Success',
//         accessor: 'outperform_sector',
//         id: 'ok',
//         Cell: (o) =>
//             o.value == '1' || o.row.values.target_date !== '' ? 'Yes' : '',
//         maxWidth: 83,
//         sortMethod: (a, b) =>
//             a.value == '1' || a.row.values.target_date !== ''
//                 ? 1
//                 : 0 > b.value == '1' || b.row.values.target_date !== ''
//                 ? 1
//                 : 0,
//     },
// ]

// const TopRow = styled.div`
//     display: flex;
//     flex-direction: row;
//     flex: 1;
//     justify-content: space-between;
//     margin-top: 7px;
//     margin-bottom: 32px;
//     border: 0px solid green;
// `
// const Row = styled(TopRow)`
//     margin-top: 5px;
//     border: 0px solid green;
// `
// const Title = styled.div`
//     font-size: 17px;
//     padding-right: 20px;
//     color: ${AppColors.BLACK};
//     border: 0px solid red;
// `
// const SubHeader = styled.div`
//     display: flex;
//     flex: 1;
//     justify-content: space-between;
//     flex-direction: row;
//     margin-bottom: 20px;
//     border: 0px solid blue;
// `
// const HeadBox = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     flex: 1;
//     border-right: ${(p) => (p.last ? 0 : 1)}px solid ${AppColors.APP_GRAY_LIGHT};
//     padding-right: 20px;
//     padding-left: 20px;
//     justify-content: center;
// `
// const BoxNumber = styled.div`
//     font-size: 30px;
// `
// const BoxText = styled(BoxNumber)`
//     font-size: 20px;
//     margin-top: 10px;
// `
// const Close = styled(CloseIcon)`
//     vertical-align: middle;
//     cursor: pointer;
// `
// const MainContainer = styled.div`
//     padding: 30px;
//     border: 0px solid red;
//     flex: 1;
//     display: flex;
//     flex-direction: column;
// `
// const Circular = styled(CircularProgress)`
//     position: relative;
//     top: 30px;
//     transform: translateX(-50%);
//     left: 50%;
//     margin-bottom: 40px;
// `
