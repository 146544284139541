import React, { Component, PropTypes } from "react";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";

class RadioButtonList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <RadioButtonGroup
        style={{ display: "flex" }}
        onChange={this.props.onChange}
        defaultSelected={this.props.selected || null}
        name="shipSpeed"
      >
        {this.props.values.map((v, i) => {
          return (
            <RadioButton
              className="screener-radio-button"
              style={{ display: "block" }}
              labelStyle={{ lineHeight: 1.2 }}
              value={v.type}
              key={"rbx_" + i}
              label={v.title}
            />
          );
        })}
      </RadioButtonGroup>
    );
  }
}

// RadioButtonList.propTypes = {
//   onChange: PropTypes.func.isRequired,
//   values: PropTypes.array.isRequired,
//   selected: PropTypes.string
// };

export default RadioButtonList;
