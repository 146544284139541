import React from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {useState} from '@services/hooks'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {ReactComponent as SvgIcon} from '../../images/calender.svg'
import {AppColors} from '@services/styles'

const DateRange = ({
    mainColWidth,
    fromDate,
    toDate,
    setFromDate,
    setToDate,
    dateFormat,
    wholeMonth,
    oneDate = false,
    oneDateTitle = 'From date',
    minDate = false,
    style,
    disableWeekend,
    frameStyle,
}) => {
    //const partWidth = (mainColWidth - 80) / (oneDate ? 1 : 2)
    const partWidth = oneDate ? mainColWidth : (mainColWidth - 80) / 2
    const [maxDate, setMaxDate] = useState(toDate) // use differant var coz todate will change
    // for some strange reason couldn't do the same with minDate - so pass it as param
    //const [minDate, setMinDate] = useState(useMinDate ? fromDate : null)

    const isWeekday = (date) => {
        if (!disableWeekend) return true
        const day = date.getDay()
        return day !== 0 && day !== 6
    }

    const CustomInput = React.forwardRef((props, ref) => {
        return (
            <Container style={style}>
                {props.title2 && <LabelText>{props.title2}</LabelText>}
                <DateWrapper
                    width={partWidth}
                    onClick={props.onClick}
                    style={frameStyle}>
                    {/* <LabelText>{props.title2}</LabelText> */}
                    <label onClick={props.onClick} ref={ref}>
                        {props.value || props.placeholder}
                    </label>
                    <CalLogo onClick={props.onClick} />
                </DateWrapper>
            </Container>
        )
    })

    return (
        <>
            <Dates mainColWidth={mainColWidth}>
                <DatePicker
                    selected={fromDate}
                    dateFormat={dateFormat}
                    showMonthYearPicker={wholeMonth}
                    onChange={(date) => setFromDate(date)}
                    maxDate={maxDate}
                    minDate={minDate ? new Date(minDate) : null}
                    filterDate={isWeekday}
                    customInput={<CustomInput title2={oneDateTitle} />}
                />

                {!oneDate && (
                    <DatePicker
                        wrapperClassName="date_picker full-width1"
                        selected={toDate}
                        dateFormat={dateFormat}
                        showMonthYearPicker={wholeMonth}
                        onChange={(date) => setToDate(date)}
                        maxDate={maxDate}
                        filterDate={isWeekday}
                        minDate={minDate ? new Date(minDate) : null}
                        customInput={<CustomInput title2={'To date'} />}
                    />
                )}

                <DatePickerWrapperStyles />
            </Dates>
        </>
    )
}
export default DateRange

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 0px solid;
`
const Dates = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: ${(p) => p.mainColWidth}px;
    border: 0px solid green;
`
const DateWrapper = styled.div`
    width: ${(p) => p.width}px; //fit-content;
    padding: 15px;
    display: flex;
    padding-right: 10px;
    border: 1px solid ${AppColors.APP_GRAY_LIGHT};
    background-color: white;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    height: 20px;
`

const CalLogo = styled(SvgIcon)`
    margin-top: -4px;
    margin-left: 25px;
    color: ${AppColors.BLACK};
    &:hover {
        color: ${AppColors.TIPIGO_GREEN};
    }
`
const LabelText = styled.label`
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 3px;
    margin-left: 4px;
`
// const LabelText = styled.label`
//     position: absolute;
//     top: 0.1em;
//     font-size: 12px;
//     font-style: normal;
//     font-weight: normal;
//     background-color: white;
//     padding-left: 5px;
//     padding-right: 5px;
// `
//ATT: full-width1 will only override the right control because we supply it directly by wrapperClassName
//     but second class will override all datepickers on page because we override by class name that the control uses
//     didn't do it the same way because don't know the equivalent of wrapperClassName for input-container
const DatePickerWrapperStyles = createGlobalStyle`
    .date_picker.full-width1 {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border: 0px solid pink;
    }
    .react-datepicker__input-container {
        width: unset;
    }
    .react-datepicker-popper {
        z-index: 10 !important;
    }
`
// added z-index for EditAccount.jsx. if it gets on the way than add it only there as wraper or somthing..
