import React from 'react'
import {useState, useEffect, useUser} from '@services/hooks'
import styled from 'styled-components'
import {AppColors} from '@services/styles'
import {Checkbox} from '@components/General/Elements'
import FrameSelect from '@components/General/FrameSelect'
import {sectorShortName} from '@services/utils'
import {api} from '@services/api'
import {ReactComponent as InfoIcon} from '@images/info.svg'

const SelectSectors = ({
    sectors,
    setSectors,
    type,
    //user,
    width,
    givenTitle,
    givenList,
    single,
}) => {
    const user = useUser()
    const title =
        givenTitle === null
            ? false
            : givenTitle
            ? givenTitle
            : type === 'sector'
            ? 'Filter By Sector '
            : 'Add Benchmarks'

    // TODO: hardcoded...    unite with list in FileLineCahrt
    const defaultBenchmarks = [
        {title: 'SPY S&P500', name: 'SPY', value: true},
        {title: 'RSP', name: 'RSP', value: true},
        {title: 'XLI Industrials', name: 'XLI', value: false},
        {title: 'XLB Materials', name: 'XLB', value: false},
        {title: 'VNQ Real Estate', name: 'VNQ', value: false},
        {title: 'QQQ Nasdaq 100', name: 'QQQ', value: false},
        {title: 'XLY Consumer Discretionary', name: 'XLY', value: false},
        {title: 'XLC Communication', name: 'XLC', value: false},
        {title: 'XLE Energy', name: 'XLE', value: false},
        {title: 'XLV HealthCare', name: 'XLV', value: false},
        {title: 'XLF Finance', name: 'XLF', value: false},
        {title: 'SOXX Semiconductors', name: 'SOXX', value: false},
        {title: 'XLK Technology', name: 'XLK', value: false},
        {title: 'XLP Consumer Staples', name: 'XLP', value: false},
        {title: 'XLU Utilities', name: 'XLU', value: false},
        {title: 'QQEW Nas100 - Eq weight', name: 'QQEW', value: false},
        {title: 'OEF S&P100 Rel Weight', name: 'OEF', value: false},
        {title: 'EQWL S&P100 Eq Weight', name: 'EQWL', value: false},
        {title: 'IWM Russel 2000', name: 'IWM', value: false},
        {title: 'IWB Russel 1000', name: 'IWB', value: false},
    ]

    const [list, setList] = useState([])
    const [rows, setRows] = useState([])
    const numInRow = type === 'sector' ? 4 : 3
    const margin = width / 24 > 30 ? width / 24 : 30
    const w = width ? width : 700
    const itemWidth = (w - (numInRow - 1) * margin) / numInRow

    useEffect(() => {
        if (type === 'sector' && user) {
            let allSectors = []
            user.system_sectors
                .sort((a, b) => (a.name > b.name ? -1 : 1))
                .forEach((s) => {
                    const t = sectorShortName(s.name)
                    allSectors.push({name: s.name, title: t, value: false})
                })
            setList(allSectors)
        } else if (type === 'etf_allocation') {
            getEtfAllocKeys()
        } else if (givenList && givenList.length) {
            setList(givenList)
        } else {
            setList(defaultBenchmarks)
        }
    }, [])

    useEffect(() => {
        let parts = []
        let part = []
        list.forEach((item, i) => {
            part.push(item)
            if ((i + 1) % numInRow === 0) {
                parts.push(part)
                part = []
            }
        })
        if (part.length > 0) {
            console.log('part length', part.length)

            while (part.length < numInRow) part.push('stam')
            parts.push(part)
        }
        setRows(parts)
    }, [list])

    useEffect(() => {
        console.log('selected', sectors)
    }, [sectors])

    const updateSelected = (event, name) => {
        if (event.target.checked) {
            setSectors([...sectors, name])
        } else {
            let temp = sectors
            const index = temp.indexOf(name)
            if (index > -1) {
                temp.splice(index, 1)
                setSectors([...temp])
            }
        }
    }
    const updateSingle = (val) => {
        // in single select mode keep value in array to have same behivor for all modes
        if (val == 'All') setSectors([])
        else setSectors([val])
    }

    const getEtfAllocKeys = () => {
        api.getEtfAllocationsKeys().subscribe((response) => {
            console.log('etf allocation', response.data)
            if (response && response.data) {
                setList(
                    response.data.map((item) => {
                        return {name: item, title: item, value: false}
                    }),
                )
            }
        })
    }
    return (
        <Container width={w}>
            {single ? (
                // in single select mode keep value in array to have same behivor for all modes
                <FrameSelect
                    title="Sector"
                    value={sectors.length > 0 ? sectors[0] : 'All'}
                    values={['All', ...list.map((item) => item.name)]}
                    setValue={updateSingle}
                    style={{width: width}}
                />
            ) : (
                <>
                    {title && (
                        <Title>
                            {title}
                            <InfoIcon
                                data-tip="Default: No filter applied. Please note that filter data is based on current market data. For historical data, please contact support."
                                width={20}
                                style={{marginLeft: 10}}
                            />
                        </Title>
                    )}
                    {rows &&
                        rows.map((arr, j) => {
                            return (
                                <Row key={'row' + j}>
                                    {arr.map((item, i) => {
                                        return item.name ? (
                                            <SpanItem
                                                key={item.name}
                                                width={itemWidth}
                                                mr={
                                                    (i + 1) % numInRow !== 0
                                                        ? margin
                                                        : 0
                                                }>
                                                <Label>
                                                    <Checkbox
                                                        checked={sectors.includes(
                                                            item.name,
                                                        )}
                                                        onChange={(e) =>
                                                            updateSelected(
                                                                e,
                                                                item.name,
                                                            )
                                                        }
                                                    />
                                                    <SpanText>
                                                        {item.title}
                                                    </SpanText>
                                                </Label>
                                            </SpanItem>
                                        ) : (
                                            <div
                                                key={'place_holder' + i}
                                                style={{
                                                    width: itemWidth,
                                                    marginRight:
                                                        (i + 1) % numInRow !== 0
                                                            ? margin
                                                            : 0,
                                                }}
                                            />
                                        )
                                    })}
                                </Row>
                            )
                        })}
                </>
            )}
        </Container>
    )
}

export default SelectSectors

const Container = styled.div`
    width: ${(p) => p.width}px;
    border: 0px solid green;
`
const Title = styled.div`
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 15px;
    border: 0px solid green;
`
const SpanItem = styled.span`
    margin-bottom: 10px;
    display: inline-block;
    width: ${(p) => p.width}px;
    margin-right: ${(p) => p.mr}px;
    border: 0px solid ${(p) => (p.mr ? 'red' : 'blue')};
`

// const Checkbox_ = styled.input.attrs({type: 'checkbox'})`
//     vertical-align: middle;
//     margin-right: 9px;
//     margin-top: 1px;
//     width: 16px;
//     height: 16px;
//     accent-color: ${AppColors.TIPIGO_GREEN};
// `
const Label = styled.label`
    display: inline-block;
`
const SpanText = styled.span`
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    color: ${AppColors.BALCK};
`
