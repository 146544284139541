import {applyMiddleware, createStore, combineReducers} from 'redux'
import createSagaMiddleware from 'redux-saga'

import {routerReducer} from 'react-router-redux'

import userReducer from './reducers/user'
import financialReducer from './reducers/finance'
import manageReducer from './reducers/manage'

import aiSaga from '../sagas/sagas'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import myStorageMiddleWare from './storageMiddleWare'

const sagaMiddleware = createSagaMiddleware()

export default function configureStore(initialState) {
    // If in production, remove state logger

    // const myStorageMiddwelWare = store => next => action => {
    //   const user = store.getState() && store.getState().user && store.getState().user.user
    //   if (action.method == "updateUserProfile" && user) {
    //     storage.setProfileByUser(user, action.profile)
    //     next(action)
    //   } else if (["getCurrentUser", "login"].indexOf(action.method) > -1) {
    //     storage.getProfileByUser(action.data).then(profile => {
    //       if (profile) {
    //         action.data.current_profile = profile
    //       }
    //       next(action)
    //     })
    //   } else {
    //     next(action)
    //   }
    // }

    const reducers = combineReducers({
        user: userReducer,
        finance: financialReducer,
        manage: manageReducer,
        routing: routerReducer,
    })

    const store =
        window.location.host !== 'insight.tipigo.com'
            ? createStore(
                  reducers,
                  // initialState,
                  applyMiddleware(
                      thunk,
                      logger,
                      sagaMiddleware,
                      myStorageMiddleWare,
                  ),
                  // window.devToolsExtension ? window.devToolsExtension() : undefined
              )
            : createStore(
                  reducers,
                  // initialState,
                  applyMiddleware(thunk, sagaMiddleware, myStorageMiddleWare),
                  // window.devToolsExtension ? window.devToolsExtension() : undefined
              )

    // if (module.hot) {
    //   // Enable Webpack hot module replacement for reducers
    //   module.hot.accept('./reducers', () => {
    //     const nextReducer = require('./reducers');
    //     store.replaceReducer(nextReducer);
    //   });
    // }

    // then run the saga
    sagaMiddleware.run(aiSaga)

    return store
}
