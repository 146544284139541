/**
 * DataService class
 */

import * as ApiActions from '@store/actions/api-actions'
import Api from './api'

export default class DataService {
    constructor(dispatch) {
        if (!dispatch) {
            throw new Error(
                'DataService cannot be instantiated without dispatch',
            )
        }

        this.dispatch = dispatch
        this.api = new Api()

        this.updateAccount = this.updateAccount.bind(this)
        this.updateUser = this.updateUser.bind(this)
    }

    getCurrentUser() {
        this.api.getCurrentUser().subscribe(
            (response) => {
                if (response.data && response.data.data) {
                    this.dispatch(
                        ApiActions.apiResponse(
                            'getCurrentUser',
                            response.data.data,
                        ),
                    )
                } else {
                    this.dispatch(
                        ApiActions.apiError('getCurrentUser', {
                            error: 'no user data',
                        }),
                    )
                }
            },
            (error) => {
                this.dispatch(ApiActions.apiError('getCurrentUser', error))
            },
        )
    }

    /**
     * Get user financial data
     * @param  {any}  sessionToken user string or profile name
     * @param  {Boolean} [fetchSilently=false]    do not emit the apiRequest Action
     * @param  {Boolean} [financeOnly=false] if true, only fetch finance info
     *                                            (i.e. exclude user portfolio)
     */
    getFinancialData(
        sessionToken,
        fetchSilently = false,
        financeOnly = false,
        callback,
    ) {
        if (!fetchSilently) {
            // We're dispatching this so that the spinner show
            this.dispatch(ApiActions.apiRequest('getUserFinanceInfo'))

            if (financeOnly !== true) {
                // Get portfolio/user data
                this.dispatch(ApiActions.apiRequest('getUserProfileData'))
            }
        }

        let profile = 'session'
        if (typeof sessionToken !== 'string') {
            profile = 'profile'
            sessionToken = sessionToken.profile
        }

        this.api.getUserFinanceInfo(sessionToken, profile).subscribe(
            (response) => {
                this.dispatch(
                    ApiActions.apiResponse('getUserFinanceInfo', response),
                )
                callback && callback()
            },
            (error) => {
                this.dispatch(ApiActions.apiError('getUserFinanceInfo', error))
                callback && callback()
            },
        )

        if (financeOnly !== true) {
            // Get portfolio/user data
            this.api.getUserProfileData().subscribe(
                (response) =>
                    this.dispatch(
                        ApiActions.apiResponse('getUserProfileData', response),
                    ),
                (error) =>
                    this.dispatch(
                        ApiActions.apiError('getUserProfileData', error),
                    ),
            )
        }
    }

    getAccounts() {
        this.api.getAccounts().subscribe(
            (response) => {
                if (response.data && response.data.data) {
                    this.dispatch(
                        ApiActions.apiResponse(
                            'getAccounts',
                            response.data.data,
                        ),
                    )
                } else {
                    this.dispatch(
                        ApiActions.apiError('getAccounts', {
                            error: 'no accounts data',
                        }),
                    )
                }
            },
            (error) => {
                this.dispatch(ApiActions.apiError('getAccounts', error))
            },
        )
    }

    getUsers() {
        this.api.getUsers().subscribe(
            (response) => {
                console.log('got users', response.data)
                if (response.data && response.data.data) {
                    this.dispatch(
                        ApiActions.apiResponse('getUsers', response.data.data),
                    )
                } else {
                    this.dispatch(
                        ApiActions.apiError('getUsers', {
                            error: 'no users data',
                        }),
                    )
                }
            },
            (error) => {
                this.dispatch(ApiActions.apiError('getUsers', error))
            },
        )
    }

    getProfiles() {
        this.api.getProfiles().subscribe(
            (response) => {
                if (response.data && response.data.data) {
                    this.dispatch(
                        ApiActions.apiResponse(
                            'getProfiles',
                            response.data.data,
                        ),
                    )
                } else {
                    this.dispatch(
                        ApiActions.apiError('getProfiles', {
                            error: 'no profiles data',
                        }),
                    )
                }
            },
            (error) => {
                this.dispatch(ApiActions.apiError('getProfiles', error))
            },
        )
    }

    getAnalysts() {
        this.api.getAnalysts().subscribe(
            (response) => {
                console.log('got analysts', response.data)
                if (response.data && response.data.data) {
                    this.dispatch(
                        ApiActions.apiResponse(
                            'getAnalysts',
                            response.data.data,
                        ),
                    )
                } else {
                    this.dispatch(
                        ApiActions.apiError('getAnalysts', {
                            error: 'no users data',
                        }),
                    )
                }
            },
            (error) => {
                this.dispatch(ApiActions.apiError('getAnalysts', error))
            },
        )
    }

    updateAccount(item) {
        this.dispatch(ApiActions.apiRequest('updateAccount'))

        this.api.updateAccount(item).subscribe(
            (response) => {
                if (response.data && response.data.data) {
                    this.dispatch(
                        ApiActions.apiResponse(
                            'updateAccount',
                            response.data.data,
                        ),
                    )
                } else {
                    this.dispatch(
                        ApiActions.apiError('updateAccount', {
                            error: 'account not updated',
                        }),
                    )
                }
            },
            (error) => {
                this.dispatch(ApiActions.apiError('updateAccount', error))
            },
        )
    }

    updateUser(item) {
        this.dispatch(ApiActions.apiRequest('updateUser'))

        this.api.updateUser(item).subscribe(
            (response) => {
                if (response.data && response.data.data) {
                    this.dispatch(
                        ApiActions.apiResponse(
                            'updateUser',
                            response.data.data,
                        ),
                    )
                } else {
                    this.dispatch(
                        ApiActions.apiError('updateUser', {
                            error: 'account not updated',
                        }),
                    )
                }
            },
            (error) => {
                this.dispatch(ApiActions.apiError('updateUser', error))
            },
        )
    }

    createNewUser(item) {
        // TODO: create a new user!!  (without an email)
        // this.dispatch(ApiActions.apiRequest('updateUser'))
        // this.api.updateUser(item).subscribe(
        //   (response) => {
        //     if (response.data && response.data.data) {
        //       this.dispatch(ApiActions.apiResponse('updateUser', response.data.data))
        //     }
        //     else {
        //       this.dispatch(ApiActions.apiError('updateUser', { error: 'account not updated' }))
        //     }
        //   },
        //   (error) => {
        //     this.dispatch(ApiActions.apiError('updateUser', error))
        //   }
        // )
    }
}
