import styled from 'styled-components'
import FlatButton from 'material-ui/FlatButton'
import {ArrowBack} from '@mui/icons-material'
import {ReactComponent as tools} from '@images/tools.svg'
import {useUser} from '@services/hooks'
import {AppColors} from '@services/styles'

// 03.22 was  height: 100%; above
// height 100 gets a bit out of grey erea, why?
// when use less the 100 can see it's not center vertical. margin can fix that but it's stupid
//margin: 0px; #stupidhead
//height: 80%; #stupidhead
export const AppBarNavContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px solid pink;
`

// centered in parant but may be heigher (because of font size)
export const AppBarNavItem = styled.span`
    color: ${(p) => (p.isActive ? '#41c399' : '#ffffff')};
    text-decoration: none;
    font-weight: 100;
    padding: 10px;
    margin: 0px 6px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    border-bottom: ${(p) => (p.isActive ? '3' : '0')}px solid #41c399;
    //border: 1px solid yellow;
`
export const UcContainer = () => {
    const user = useUser()
    if (user && user.valid_to && user.valid_to < 32) {
        if (user.trail > user.valid_to) {
            return (
                <UCdiv>
                    {/* <Tools />
            <UnderTextMain>
                <UnderTitle>Under Maintenance</UnderTitle>
                <UnderText>
                    We are doing some work here - Some functionality may not be
                    fully operable
                </UnderText>
            </UnderTextMain> */}
                    You have used {user.trail - user.valid_to} out of{' '}
                    {user.trail} days of your trail subscription.
                    <br />
                    Contact{' '}
                    <a
                        style={{color: AppColors.BLACK}}
                        href="mailto:sales@tipigo.com">
                        Sales Team
                    </a>{' '}
                    within the next {user.valid_to} days to ensure continued
                    access.
                </UCdiv>
            )
        } else {
            return (
                <UCdiv>
                    Attention! Your subscription will expire in {user.valid_to}{' '}
                    days
                </UCdiv>
            )
        }
    } else return null // else: this could be a bug
}
// NOT GOOD! need to position in center of parent wuthout all this fine-tuning
//height: 85%;      #stupidhead
//margin-top: -5px; #stupidhead
const UCdiv = styled.div`
    background-color: #fff3db;
    border-radius: 8px;
    display: block;

    align-items: center;
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 3px;
    padding-bottom: 2px;
    font-size: 12px;
    line-height: 14px;
`
const Tools = styled(tools)``
const UnderTitle = styled.div`
    font-size: 13px;
    font-weight: 700;
    font-family: Inter;
`
const UnderText = styled.div`
    font-size: 11px;
    font-family: Inter;
    margin-top: -7px;
    font-weight: 400;
`
const UnderTextMain = styled.div`
    margin-left: 9px;
`
export const AppBarBack = ({onClick, text}) => {
    return (
        <BackDiv>
            <FlatButton
                label={text ? text : 'Back'}
                labelPosition="after"
                onClick={onClick}
                icon={<ArrowBack fontSize="large" sx={{color: 'white'}} />}
                //style={{marginTop: 6}} #stupidhead
                labelStyle={{color: '#fff'}}
            />
        </BackDiv>
    )
}

const BackDiv = styled.div`
    display: flex;
    align-items: center;
    //margin: 0;
`
