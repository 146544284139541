import React from 'react'
import {api} from '@services/api'
import {useState, useEffect, useHistory} from '@services/hooks'
import styled from 'styled-components'
import FrameInput from '@components/General/FrameInput'
import FrameSelect from '@components/General/FrameSelect'

import {Title, SendButton, SendButtonDis} from '@components/General/Elements'

const EditAnalyst = ({id}) => {
    const [name, setName] = useState('')
    const [globalName, setGlobalName] = useState('')
    const [canSave, setCanSave] = useState(false)
    const [analystList, setAnalystList] = useState([])
    const [last, setLast] = useState('Unknown')

    const history = useHistory()

    useEffect(() => {
        setCanSave(globalName && globalName !== '')
    }, [globalName])

    useEffect(() => {
        if (id) {
            api.getAnalyst(id).subscribe(
                (response) => {
                    console.log('getUser ', response.data)
                    const d = response.data
                    setName(
                        d.nick_name
                            ? d.nick_name
                            : d.first_name
                            ? d.first_name
                            : d.last_name,
                    )
                    setGlobalName(d.global_name)
                    if (d.last) {
                        setLast(d.last)
                    }
                },
                (error) => {
                    console.log('getUser error', error)
                },
            )

            api.getAllAnalystsGlobalNames().subscribe(
                (response) => {
                    if (response.data) {
                        setAnalystList(response.data)
                        console.log('get analysts', response)
                    } else {
                        console.log('get analysts error', response)
                    }
                },
                (error) => {
                    console.log('get analysts error', error)
                },
            )
        }
    }, [id])

    const saveGlobalName = () => {
        api.updateUser({id: id, global_name: globalName}).subscribe(
            (response) => {
                console.log('updateUser ', response.data)
                history.push('/manage/analysts')
            },
            (error) => {
                console.log('updateUser error', error)
            },
        )
    }

    return (
        // <Dialog fullWidth modal={true} open={isOpenDialog}>
        <Container>
            <Row>
                <Title text="Edit Analyst's Global Name" />
            </Row>
            <Row>
                <FrameInput
                    style={{flex: 1.1, marginRight: 20}}
                    value={name}
                    disabled
                    title="Name"
                />
                <FrameInput
                    style={{flex: 0.8, marginRight: 20}}
                    value={last}
                    disabled
                    title="Estimated last rating"
                />
                <FrameSelect
                    style={{flex: 2, marginRight: 20}}
                    values={analystList}
                    value={globalName}
                    setValue={setGlobalName}
                    title="Global Name"
                    canInput
                    searchable
                />
                {canSave ? (
                    <SendButton
                        style={{alignSelf: 'flex-end'}}
                        onClick={saveGlobalName}>
                        save
                    </SendButton>
                ) : (
                    <SendButtonDis style={{alignSelf: 'flex-end'}}>
                        save
                    </SendButtonDis>
                )}
            </Row>
        </Container>
        // </Dialog>
    )
}

export default EditAnalyst

const Container = styled.div`
    margin-top: 40px;
`
const Row = styled.div`
    display: flex;
    width: 90%;
    flex: 1;
    border: 0px solid green;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 20px;
`
