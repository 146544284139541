import queryString from 'query-string'
import {red400, green400} from 'material-ui/styles/colors'
import moment from 'moment'

export function getUrlParams() {
    const query = document.location.href.replace(/[^?]+(.+)/gim, '$1')
    return queryString.parse(query)
}

export const intOrEmpty = (value) => {
    value = value.trim().replaceAll(',', '')
    if (value === '') {
        return ''
    } else {
        value = parseInt(value)
        if (!isNaN(value) && value >= 0) {
            return value
        } else {
            return ''
        }
    }
}
export const floatOrEmpty = (value) => {
    // value = value.trim().replaceAll(',', '')
    if (value === '') {
        return ''
    } else {
        value = parseFloat(value)
        if (!isNaN(value)) {
            return value
        } else {
            return ''
        }
    }
}
export const toNiceNum = (str) => {
    return new Intl.NumberFormat('en-US', {}).format(str)
}

export const updateFieldInList = (value, itemId, fieldName, listSetter) => {
    listSetter((current) =>
        current.map((obj) => {
            if (obj.id === itemId) {
                return {
                    ...obj,
                    [fieldName]: value,
                }
            }
            return obj
        }),
    )
}
export const formatUserInputPrice = (value) => {
    const val = value?.trim() || ''
    if (val === '') {
        return ''
    } else {
        var matched = val.match(/^(\d*)([.]\d{0,2})?$/)
        if (matched) {
            var updated =
                matched[1] + (matched[2] ? matched[2].replace(',', '.') : '')
            return updated
        }
        return false
    }
}

export function formatDailyChange(
    symbol,
    removeParentheses = false,
    removePlusSign = false,
    outputAsString = false,
) {
    if (!symbol.dailyChangeVal || !symbol.dailyChange) {
        return 'n/a'
    }

    const dailyChangeVal = parseFloat(symbol.dailyChangeVal.replace('--', '-'))
    const dailyChange = parseFloat(symbol.dailyChange.replace('--', '-'))

    const color = dailyChange >= 0 ? green400 : red400
    let sign = ''
    if (dailyChange > 0 && !removePlusSign) {
        sign = '+'
    }

    if (outputAsString === true) {
        return `<span style="color: ${color}">${sign}${dailyChangeVal} ${sign}${dailyChange}%</span>`
    } else {
        if (removeParentheses) {
            return (
                <span style={{color: color}}>
                    {sign + dailyChangeVal + ' ' + sign + dailyChange + '%'}
                </span>
            )
        } else {
            return (
                <span style={{color: color}}>
                    {sign + dailyChangeVal + ' (' + sign + dailyChange + '%)'}
                </span>
            )
        }
    }
}

const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export function monthAndDayFormat(date) {
    let d = new Date(date)
    return monthNames[d.getMonth()] + ' ' + d.getDate()
}

export function validPassword(pass) {
    if (!pass) return false
    if (pass.length < 8) return false
    var upper = false
    var lower = false
    var num = false
    for (var i = 0; i < pass.length; i++) {
        var c = pass.charAt(i)
        if (c.match(/[a-z]/i)) {
            if (c === c.toLowerCase()) lower = true
            if (c === c.toUpperCase()) upper = true
        } else if (!isNaN(parseInt(c))) num = true
    }

    if (!(lower && upper && num)) return false
    return true
}

export const getDaysDiff = (earning) => {
    if (earning) {
        const er_date = moment(earning, 'YYYY-MM-DD')
        // startOf(day) - we want only "round" number of days.
        // diff by round client date!
        // if er_date is 4.10 and client date is 3.10 and NYC is still 2.10 - will show 1 day
        // if er_date is 4.10 and client date and NYC are 3.10 - will show 1 day
        // if er_date is 4.10 and client date is 2.10 and NYC is allredy 3.10 - will show 2 day
        const today = moment(new Date()).startOf('day')
        const numDays = er_date.diff(today, 'days')
        if (er_date >= today && numDays > 0)
            return {days: numDays, words: numDays + ' days'}
        else if (numDays === 0)
            return {
                days: numDays,
                words: 'Today',
            }
        // not an error. may be in the past
        else if (numDays === -1) return {days: numDays, words: 'Yesterday'}
        else return {days: '', words: ''}
    } else return {days: '', words: ''}
}

export const invalidPassMsg =
    'Password must be at least 8 charecters, include both lower and upper case letters and at least one number.'

export const sectorShortName = (sector) => {
    switch (sector) {
        case 'Information Technology':
        case 'information technology':
        case 'Technology':
            return 'IT'
        case 'Consumer Discretionary':
        case 'consumer discretionary':
            return 'Discretionary'
        case 'Consumer Staples':
        case 'consumer staples':
            return 'Staples'
        default:
            return sector
    }
}

export const formatUniverseResponse = (response, basic) => {
    let preList = []
    if (response.data?.indexes) {
        preList = response.data.indexes
    } else if (response.data?.data) {
        preList = response.data.data
    }

    return preList.map((item) => {
        // return basic
        //    ? {value: item._id || item.id, label: item.name}
        return {
            ...item,
            id: item._id || item.id,
            value: item._id || item.id,
            label: item.name,
        }
    })
}
