import React from 'react'
import styled from 'styled-components'
import {useState} from '@services/hooks'
import {SendButton, DialogRow} from '@components/General/Elements'
import FrameText from '@components/General/FrameText'
import {AppColors} from '@services/styles'
import FrameInput from '@components/General/FrameInput'
import swal from 'sweetalert2'
import {intOrEmpty, toNiceNum} from '@services/utils'

const AddFromList = ({totalListVal, setTotalListVal, setTicArrFromList}) => {
    const [listSym, setListSym] = useState('')

    const startAdd = () => {
        const arr = listSym
            .replace(/[\W_]+/g, ',') // this will keep numbers but there only so much we can guess as to user intentions...
            .split(',')
            .filter((a) => a) // remove empty
            .filter((val, index, theArray) => theArray.indexOf(val) === index) // unique

        if (arr.length === 0) {
            swal.fire({
                text: 'Invalid symbols data',
                icon: 'error',
            })
            return
        }
        if (!totalListVal) {
            swal.fire({
                text: 'Invalid total cash value',
                icon: 'error',
            })
            return
        }
        setTicArrFromList(arr)
    }
    const _setTotalListVal = (value) => {
        value = intOrEmpty(value)
        if (value == '') {
            setTotalListVal('')
        } else {
            setTotalListVal(toNiceNum(value))
        }
    }

    return (
        <div>
            <DialogRow>
                <Text>
                    Easily import a trading list by copying and pasting the
                    symbol and quantity information. The positions will be
                    automatically added to your portfolio with the quantity you
                    enter, While the entry price will be calculated based on the
                    total allocated value in equal weight. Next, you can change
                    the dates, entry prices and quantity figures.
                </Text>
            </DialogRow>
            <DialogRow>
                <FrameText
                    value={listSym}
                    setValue={setListSym}
                    title="Symbols (separate by comma, space or new line)"
                    style={{width: 500}}
                    frameStyle={{height: 130}}
                />
            </DialogRow>
            <DialogRow>
                <FrameInput
                    title="Total value"
                    value={totalListVal}
                    setValue={_setTotalListVal}
                    style={{width: 500}}
                />
            </DialogRow>
            <DialogRow style={{justifyContent: 'flex-end'}}>
                <SendButton onClick={startAdd}>Next</SendButton>
            </DialogRow>
        </div>
    )
}

export default AddFromList

const Text = styled.div`
    color: ${AppColors.BLACK};
    font-size: 16px;
    width: 700px;
`
