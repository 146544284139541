import React from 'react'
import styled from 'styled-components'
import FileLineChart from '@components/General/FileLineChart'
import HorizontalStack from '@components/General/HorizontalStack'

import {useState, useEffect, useAlerts} from '@services/hooks'
import {api} from '@services/api'
import _ from 'lodash'
import AlertBox from '@components/General/AlertBox'
import {initFilters, filterAlerts} from '../Home/HomeDrawer'
import {SendButton2} from '@components/General/Elements'

const Main = ({
    user,
    //setTotalAssets,
    //setFilePl,
    setTotalLf,
    history,
    myFile,
}) => {
    // const [pieChartData, setPieChartData] = useState(null)
    const [refreshChart, setRefreshChart] = useState(false)
    const [localAlerts, setLocalAlerts] = useState([])
    const alerts = useAlerts()

    useEffect(() => {
        if (alerts && alerts.length > 0) {
            setTotalLf(alerts.length)
            let filteredAlerts = filterAlerts(
                alerts,
                user && user.alert_filters ? user.alert_filters : initFilters,
            )
            if (filteredAlerts.length == 0) filteredAlerts = alerts
            setLocalAlerts(
                _.orderBy(filteredAlerts, ['time'], ['desc'])
                    .slice(0, 4) // Noam: show 4. all else is when navigating to LiveFeed
                    .map((a) => {
                        return {...a, team: true} // in this case they are always "team" but control expects that
                    }),
            )
        }
    }, [alerts])

    useEffect(() => {
        if (myFile) {
            setRefreshChart(true)
        }
    }, [myFile])

    return (
        <Container>
            <MidDataPart>
                <SidePart>
                    <PartTitle>My Portfolio</PartTitle>
                    {myFile && (
                        <SidePart2>
                            <FileLineChart
                                fileId={myFile.id}
                                refreshChart={refreshChart}
                                // setRefreshChart={setRefreshChart}
                                allowDates={false}
                                //contStyle={{height: 460}} // it doesnt do well with height under 450. need to edit inside control to br more flexiable
                                // lineMinDate={lineMinDate}
                            />
                            <div style={{marginTop: 15}}>
                                <HorizontalStack pId={myFile.id} small />
                            </div>
                        </SidePart2>
                    )}
                </SidePart>
                <SidePart style={{marginLeft: 20}}>
                    <PartTitle>
                        Last Analyst Ratings
                        <SubTitle>(Active filters)</SubTitle>
                    </PartTitle>
                    <SidePart2>
                        {localAlerts.map((alert, i) => (
                            <AlertBox
                                rec={alert}
                                i={i}
                                key={i}
                                style={i == 0 ? {marginTop: 0} : {}}
                            />
                        ))}
                    </SidePart2>
                </SidePart>
            </MidDataPart>
            <SendButton2
                onClick={() => history.push('/?tab=0')}
                style={{width: 180, marginTop: 18, alignSelf: 'end'}}>
                See more
            </SendButton2>
        </Container>
    )
}
export default Main

// 100% is all height under gray header (why?) but not including the space under header
// for now (by design) we dont try to fit in height
const Container = styled.div`
    border: 0px solid red;
    display: flex;
    flex: 1;
    padding-top: 8px;
    flex-direction: column;
`
const MidDataPart = styled.div`
    display: flex;
    border: 0px solid blue;
    flex: 1;
`
const PartTitle = styled.div`
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    border: 0px solid red;
`
const SubTitle = styled.div`
    font-size: 14px;
    font-weight: 400;
    margin-left: 12px;
    text-decoration: none;
    color: inherit;
`
const SidePart = styled.div`
    border: 0px solid blue;
    display: flex;
    flex-direction: column;
    flex: 1;
`
const SidePart2 = styled(SidePart)`
    justify-content: space-between;
`
