import styled from 'styled-components'

import {AppColors} from '@services/styles'

import {Resizable, ResizableBox} from 'react-resizable'
import _ from 'lodash'

export const Triggre = styled.a``
export const ExploreContent = styled(({rowCount, ...props}) => (
    <ResizableBox width={880} height={220 + _.min([(rowCount + 1) * 34, 400])}>
        <Content {...props}>{props.children}</Content>
    </ResizableBox>
))`
    .react-resizable-handle {
        position: absolute;
        width: 20px;
        height: 20px;
        bottom: 0;
        right: 0;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zd…BMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+);
        background-position: bottom right;
        padding: 0 3px 3px 0;
        background-repeat: no-repeat;
        background-origin: content-box;
        box-sizing: border-box;
        cursor: se-resize;
    }
    .rt-th:focus {
        outline: 0px;
    }
`
// export const ExploreContent = props => <Content>{props.children}</Content>
export const Content = styled.div`
    width: 100%;
    height: 100%;
    background: #fff;
    border: 1px solid ${AppColors.APP_GRAY_LIGHT};
    overflow: scroll;
    box-sizing: border-box;
    padding: 15px;
    ::-webkit-scrollbar {
        width: 0;
    }
`

export const AllWrapper = styled.span`
    ${Triggre}:hover ~ * {
        display: block !important;
    }
`

export const Note = styled.span`
    position: relative;
    margin-left: 10px;
    top: -10px;
`
