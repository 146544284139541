import React from 'react'
import {useState, useEffect} from '@services/hooks'
import styled from 'styled-components'
import FrameInput from '@components/General/FrameInput'
import {Checkbox} from '@components/General/Elements'

// import FrameSelect from '../General/FrameSelect'
// import {api} from '@services/api'
// import {SendButton, SendButtonDis} from '../General/Elements'
// import SaveScoreProfile from './SaveScoreProfile'
// import Swal from 'sweetalert2'
// import {AppColors} from '@services/styles'

import {ReactComponent as SvgRefresh} from '../../images/refresh.svg'

const adminScores = [
    {
        title: 'In analysts team',
        name: 'team_score_factor',
        value: 1,
        type: [0, 1],
    },
    {
        title: 'Outperform etf',
        name: 'outperform_score_factor',
        value: 4,
        type: [0],
    },
    {
        title: 'Positive P&L',
        name: 'positive_pl_score_factor',
        value: 5,
        type: [0],
    },
    {
        title: 'Team consensus factor',
        name: 'number_rec_score_factor',
        value: 2,
        type: [0, 1],
    },
    {
        title: 'Algorithm factor',
        name: 'algorithm_score_factor',
        value: 5,
        type: [0, 1],
    },
    {
        title: 'Momentum SD',
        name: 'momentum_score_factor',
        value: 0,
        type: [0, 1],
    },
    {
        title: 'PE',
        name: 'pe_score_factor',
        value: 1,
        type: [1],
    },
    {
        title: 'PB',
        name: 'pb_score_factor',
        value: 1,
        type: [1],
    },
    {
        title: 'Current Ratio',
        name: 'currentratio_score_factor',
        value: 3,
        type: [1],
    },
    {
        title: 'Beta',
        name: 'beta_score_factor',
        value: 3,
        type: [1],
    },
    {
        title: 'Top Market Val',
        name: 'top_mc_score_factor',
        value: 3,
        type: [1],
    },
    {
        title: 'Standard Deviation',
        name: 'sd_score_factor',
        value: 0,
        type: [1],
    },
    {
        title: 'MA Ratio',
        name: 'ma_ratio_score_factor',
        value: 0,
        type: [1],
    },
    {
        title: 'MA to ETF to SPY',
        name: 'ma_etf_spy_score_factor',
        value: 0,
        type: [1],
    },
    {
        title: 'EPS',
        name: 'eps_score_factor',
        value: 0,
        type: [1],
    },
    {
        title: 'ROE',
        name: 'roe_score_factor',
        value: 0,
        type: [1],
    },
]

const ScoreParams = ({
    scoreParam,
    setScoreParam,
    width,
    // user,
    repType,
    testMode,
}) => {
    // defultScores is the same as initialScores, used to force re-render when restore default
    const [defultScores, setDefultScores] = useState([]) // useState(initialScores)
    const [rows, setRows] = useState([])
    const numInRow = 4 // TODO: can make it reletive to width param
    // TODO: if last row is more then 1 but not full shouldn't use space-between
    const itemWidth = (width - 160) / numInRow

    const modeLimit = repType == 'admin_pl' ? true : false

    useEffect(() => {
        restoreDefault()
    }, [])

    useEffect(() => {
        console.log('defultScores change')
        let parts = []
        let part = []
        defultScores.forEach((item, i) => {
            part.push(item)
            if ((i + 1) % numInRow === 0) {
                parts.push(part)
                part = []
            }
        })
        if (part.length > 0) parts.push(part)
        setRows(parts)
    }, [defultScores])

    const restoreDefault = () => {
        let tempScores = adminScores

        if (modeLimit) {
            tempScores = tempScores.map((item) => {
                return {...item, value: 0}
            })
        }
        const tempParams = {}
        tempScores.forEach(({name, value}) => (tempParams[name] = value))

        setDefultScores(tempScores)
        setScoreParam(tempParams)
    }

    const updateScores = (value, fields) => {
        value = value.trim()
        if (!isNaN(value) || value === '-') {
            if (modeLimit) value = parseInt(value)
            const field = fields[0]
            setScoreParam((prev) => ({...prev, [field]: value}))
        }
    }

    return (
        <Container width={width}>
            <TitleContainer>
                <Title>Score {modeLimit ? 'MAX ' : ''} weights</Title>
            </TitleContainer>

            <Main>
                {scoreParam &&
                    Object.keys(scoreParam).length &&
                    rows.map((arr, i) => {
                        return (
                            <Row key={i}>
                                {arr.map((item, i) => {
                                    return (
                                        <FrameInput
                                            // disabled={
                                            //     !user.is_admin &&
                                            //     !user.client_admin
                                            // }
                                            key={item.name}
                                            title={item.title}
                                            value={scoreParam[item.name]}
                                            params={[item.name]}
                                            setValue={updateScores}
                                            style={{
                                                width: itemWidth,
                                            }}
                                            labelStyle={{fontSize: 13}}
                                            maxLength={50}>
                                            {item.name}
                                        </FrameInput>
                                    )
                                })}
                            </Row>
                        )
                    })}
            </Main>
        </Container>
    )
}

export default ScoreParams

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: ${(p) => p.width}px;
    border: 0px solid;
`
const Label = styled.label`
    display: inline-block;
    margin-top: 10px;
`
const SpanText = styled.span`
    vertical-align: middle;
    font-size: 14px;
`
const Top = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
`
const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const Title = styled.div`
    font-size: 16px;
    font-weight: 500;
`
const SubTitle = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #808284;
`
const Main = styled.div`
    display: inline-block;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 19px;
`
const RowButtons = styled(Row)`
    justify-content: flex-start;
    margin-top: 19px;
`
const ButtonWrapper = styled.button`
    width: fit-content;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 23px;
    background: #ffffff;
    cursor: pointer;
    padding-right: 14px;
    padding-left: 9px;
    letter-spacing: 0.5px;
    color: #444444;
    height: 38px;
    margin-top: 22px;
`
const ButtonText = styled.div`
    display: block;
    float: right;
    margin-top: 6px;
    margin-bottom: 6px;
`
const ButtonLogo = styled(SvgRefresh)`
    display: block;
    float: left;
    margin-right: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
`
