/**
 * The concrete class for making server requests from the app.
 * Extends the core cl-api codelovers module
 * @see codelovers_modules/services/cl-api
 *
 * The only public method of cl-api is the this.request() method which takes the
 * following params:
 *
 * @param  {string}    url      e.g. /getCurrentUser (including the prefix '/')
 * @param  {string}    type     'POST' | 'GET'
 * @param  {object}    params   params to add to the request
 * @param  {boolean}   relogin  if set to true will attempt to re-login if
 *                              401 is received (default: true)
 * @param  {boolean}   cache  disable caching for this request
 * @return {Rx.Observable}
 *
 * The method returns an Rx,Observable which you will need to subscribe
 * to in order to get the response.
 */

//import CLApi from '../codelovers_modules/services/cl-api';

import CLApi from '../cl_modules/cl-api'
import _ from 'lodash'
import Rx from 'rx'
import qs from 'qs'
import moment from 'moment'
import {floatOrEmpty} from '@services/utils'
const LOCAL_DEV = true

export const baseNewApiUrl =
    !window.location.host.includes('insight') && LOCAL_DEV
        ? 'http://localhost:3700/api/v1'
        : 'https://api.tipigo.com/api/v1'

const newApiUniverses = true
export const newApiReports = true
export const newApiPortfolioFunds = false
export const newApiScannerSettings = true

export default class Api extends CLApi {
    getCurrentUser() {
        return this.request('/user/get_current_user', 'POST', {}, true, false)
    }

    forgotPassword(email) {
        return this.request(
            '/user/change_pass_mail',
            'POST',
            {
                userData: {
                    email,
                    source: 'dss',
                },
            },
            true,
            false,
        )
    }
    changePassword(token, password) {
        return this.request(
            '/user/change_pass',
            'POST',
            {
                passData: {
                    new_pass: password,
                    validation_code: token,
                },
            },
            true,
            false,
        )
    }

    // changeProfileNameDirect(profileName) {
    //     return this.request(
    //         '/user/change_profile_name_direct',
    //         'POST',
    //         {
    //             profile_name: profileName,
    //         },
    //         true,
    //         false,
    //     )
    // }
    // 31.07.23 not in use. but may be needed back
    // changeUserNameDirect(firstName, lastName) {
    //     return this.request(
    //         '/user/change_user_name_',
    //         'POST',
    //         {
    //             first_name: firstName,
    //             last_name: lastName,
    //         },
    //         true,
    //         false,
    //     )
    // }

    changeNicknameDirect(displayName) {
        return this.request(
            '/user/change_nickname',
            'POST',
            {
                nickname: displayName,
            },
            true,
            false,
        )
    }

    changePasswordDirect(currentPassword, newPassword) {
        return this.request(
            '/user/change_pass_direct',
            'POST',
            {
                passData: {
                    current_pass: currentPassword,
                    new_pass: newPassword,
                },
            },
            true,
            false,
        )
    }

    // 21.06.23 this is BS field
    // changeProfileDescriptionDirect(profileDescription) {
    //     return this.request(
    //         '/user/change_profile_description_direct',
    //         'POST',
    //         {
    //             profile_description: profileDescription,
    //         },
    //         true,
    //         false,
    //     )
    // }

    adminCreateProfile(params) {
        return this.request('/admin/create_user', 'POST', params, false, false)
    }

    getUserFinanceInfo(sessionToken, profile = 'session') {
        return Rx.Observable.create((observer) => {
            const start_time = moment()
            const getDiff = () =>
                moment.duration(moment().diff(start_time)).seconds()
            const timing = {start: getDiff()}
            console.log('getUserFinanceInfo start', sessionToken, profile)

            this.request(
                'user/finance_info/' + sessionToken + `/${profile}/true`,
                'POST',
                {},
                true,
                false,
            ).subscribe(
                (response) => {
                    console.log('getUserFinanceInfo', response)
                    timing.info_response = getDiff()
                    timing.info_timing = response.data.timings
                    let symbols = []
                    if (
                        response.data.info &&
                        response.data.info.finance_info
                        // 06.11.22 && response.data.info.finance_info.positions
                    ) {
                        let alerts = []
                        if (
                            response.data.info.alerts &&
                            _.isArray(response.data.info.alerts)
                        ) {
                            alerts = response.data.info.alerts
                        }

                        // 06.11.22
                        // let followingSymbols = []
                        // if (
                        //     response.data.info.followingSymbols &&
                        //     _.isArray(response.data.info.followingSymbols)
                        // ) {
                        //     followingSymbols =
                        //         response.data.info.followingSymbols
                        // }

                        let values = _.concat(
                            // 06.11.22 _.values(response.data.info.finance_info.positions),
                            alerts,
                            //06.11.22 response.data.info, but don't think it was in use before as response.data.info is general and doesn't have symbol field
                            //06.11.22 followingSymbols,
                        )

                        symbols = _.union(values.map((v) => v.symbol))
                    }
                    // TODO: above part collects the symbols, below gets qouts.
                    // need to cancel both and get full data in one time
                    // 06.11.22 canceled followingSymbols from this cycle. it is still saved in state but only symbol name
                    // W.L page pulls the data on its own
                    // also symbols saved in state and in use in other palces

                    let outputData = Object.assign({}, response.data.info)
                    timing.quotes = getDiff()

                    this.getQuotes(symbols).subscribe(
                        (symbolsResponse) => {
                            timing.quotes_response = getDiff()
                            outputData.symbols = symbolsResponse.data
                            outputData.alerts = outputData.alerts.map(
                                (alert) => {
                                    const target = alert.price_target
                                    const lastPrice =
                                        outputData.symbols[
                                            alert.symbol.toUpperCase()
                                        ].lastPrice
                                    return Object.assign({}, alert, {
                                        upside:
                                            lastPrice > 0
                                                ? (100 *
                                                      Math.abs(
                                                          target - lastPrice,
                                                      )) /
                                                  lastPrice
                                                : 0,
                                    })
                                },
                            )
                            outputData.timing = timing
                            console.log('getUserFinanceInfo timing', timing)
                            observer.onNext(outputData)
                            observer.onCompleted()
                        },
                        (error) => {
                            console.log(
                                'getUserFinanceInfo getQuotes error',
                                error,
                            )
                            observer.onError(error)
                        },
                    )
                    //----------------------------
                },
                (error) => {
                    console.log('getUserFinanceInfo error', error)
                },
            )
        })
    }

    getAlertExploreList(alertId, analystName, symbol) {
        return this.request(
            'user/get_analysts_past_alerts',
            'POST',
            {alert_id: alertId, analyst: analystName, symbol: symbol},
            false,
            false,
        )
    }

    getUserProfileData() {
        return this.request(
            'user/get_user_portfolio_data',
            'POST',
            {},
            false,
            false,
        )
    }
    getPortfolioFunds(pId) {
        if (newApiPortfolioFunds) {
            return this.request(
                'portfolioDeposits/portfolio/' + pId,
                'GET',
                {},
                false,
                false,
                baseNewApiUrl,
            )
        } else
            return this.request(
                'file/get_funds_history/' + pId,
                'GET',
                {},
                false,
                false,
            )
    }

    getQuotes(symbols) {
        return this.request(
            'finance/quotes',
            'POST',
            {
                data: {
                    symbols: symbols,
                },
            },
            false,
            false,
        )
    }

    editSuperPortfolio(params) {
        return this.request(
            'user/add_super_portfolio_to_queue', //edit_super_portfolio
            'POST',
            params,
            false,
            false,
        )
    }
    addSector2Profile(params) {
        return this.request(
            'user/add_profile_sector',
            'POST',
            {data: params},
            false,
            false,
        )
    }

    saveScreener(sectorId, field, min, max, multi) {
        //TODO: multi
        return this.request(
            'user/edit_add_profile_screener',
            'POST',
            {sector_id: sectorId, field: field, min: min, max: max},
            false,
            false,
        )
    }
    deleteScreener(sectorId, field) {
        return this.request(
            'user/delete_profile_screener',
            'POST',
            {sector_id: sectorId, field: field},
            false,
            false,
        )
    }

    isMarketOpen() {
        return this.request(
            'finance/is_market_open',
            'POST',
            {
                data: {
                    format: 'json',
                },
            },
            false,
            false,
        )
    }

    getScreenedSymbols(sector) {
        return this.request(
            'user/get_screened_symbols',
            'POST',
            {sector},
            false,
            false,
        )
    }

    executeAlert(alertId) {
        return this.request(
            'finance/execute_alert',
            'POST',
            {
                alert_id: alertId,
            },
            false,
            false,
        )
    }

    dismissAlert(alertId) {
        return this.request(
            'finance/dismiss_alert',
            'POST',
            {
                alert_id: alertId,
            },
            false,
            false,
        )
    }

    closeSecurity(securityId, openUnits) {
        return this.request(
            'finance/close_security',
            'POST',
            {
                data: {
                    id: securityId,
                    open_units: openUnits,
                    close_when_market_is_closed: true,
                },
            },
            false,
            false,
        )
    }

    fetchStock(symbol) {
        return this.request(
            'finance/fetch_stock/' + symbol,
            'GET',
            {},
            false,
            false,
        )
    }

    getStockScoresHistory(symbol) {
        return this.request(
            'symbols/historyScore/' + symbol,
            'GET',
            {},
            false,
            false,
            baseNewApiUrl,
        )
    }

    isStockInUserFile(symbol) {
        return this.request(
            'file/get_is_symbol_in_file/' + symbol,
            'GET',
            {},
            false,
            false,
        )
    }

    getLastSymbolRecommendations_new(symbol) {
        return this.request(
            'finance/get_last_symbol_recommendations/' + symbol + '/1',
            'GET',
            {},
            false,
            false,
        )
    }

    findSymbolSearch(symbol) {
        return this.request(
            'finance/find_symbol_search/' + symbol + '/10/0',
            'GET',
            {},
            false,
            false,
        )
    }

    getCompanyProfile(symbol) {
        return this.request(
            'finance/get_company_profile/' + symbol,
            'GET',
            {},
            false,
            false,
        )
    }

    getNewsForStock(symbol) {
        return this.request(
            'finance/get_news_for_stock/' + symbol,
            'GET',
            {},
            false,
            false,
        )
    }

    /* 11.01.23
    getWatchlistData(sessionToken) {
        return this.request(
            'user/profile_info/' + sessionToken + '/session',
            'POST',
            {},
            false,
            false,
        )
    } */
    getWatchlist() {
        return this.request('user/get_watchlist', 'GET', {}, false, false)
    }

    followSymbol(symbol) {
        return this.request(
            'user/followSymbol',
            'POST',
            {data: {symbol: symbol}},
            false,
            false,
        )
    }

    unfollowSymbol(symbol) {
        return this.request(
            'user/unfollow_symbol',
            'POST',
            {data: {symbol: symbol}},
            false,
            false,
        )
    }

    inviteUserAccount(email, permissionLevel, name) {
        return this.request(
            '/invite',
            'POST',
            {email: email, level: permissionLevel, name: name},
            false,
            false,
        )
    }

    getUserAccounts() {
        return this.request('/get_user_accounts', 'POST', {}, true, false)
    }

    // 07.08.23 next 6 functions
    // getUserOrganizations() {
    //     return this.request('/get_user_organizations', 'POST', {}, true, false)
    // }
    // getInvitaionByKey(key) {
    //     return this.request(
    //         '/get_invitation_by_key',
    //         'POST',
    //         {key: key},
    //         false,
    //         false,
    //     )
    // }
    // acceptInvitation(email, password, invitation) {
    //     return this.request(
    //         '/accept_invitation',
    //         'POST',
    //         {email: email, password: password, invitation: invitation},
    //         false,
    //         false,
    //     )
    // }
    // sendToken(os, token, userEmail) {
    //     return this.request(
    //         '/send_token',
    //         'POST',
    //         {os: os, token: token, email: userEmail},
    //         false,
    //         false,
    //     )
    // }
    // markPushMessageAsRead(deviceToken, notificationText) {
    //     return this.request(
    //         'mark_push_message_as_read',
    //         'POST',
    //         {device_token: deviceToken, msg: notificationText},
    //         false,
    //         false,
    //     )
    // }
    // missedPushMessages(deviceToken, badge) {
    //     return this.request(
    //         'get_last_unread_push_messages_by_device_token',
    //         'POST',
    //         {device_token: deviceToken, badge: badge},
    //         false,
    //         false,
    //     )
    // }

    getPerformanceReport(
        sl,
        slType,
        startMonth,
        endMonth,
        sector,
        symbols,
        marketCapCond,
        marketCapVal1,
        marketCapVal2,
        betaCond,
        betaVal1,
        betaVal2,
        compareToMa50,
        maxNumberOfAnalysts,
        minNumberOfRecommendations,
    ) {
        return this.request(
            'reports/performance_report',
            'POST',
            {
                sl: sl,
                sl_type: slType,
                start_month: startMonth,
                end_month: endMonth,
                sector: sector,
                symbols: symbols,
                market_cap_cond: marketCapCond,
                market_cap_val1: marketCapVal1,
                market_cap_val2: marketCapVal2,
                beta_cond: betaCond,
                beta_val1: betaVal1,
                beta_val2: betaVal2,
                compare_to_ma50: compareToMa50,
                max_number_of_analysts: maxNumberOfAnalysts,
                min_number_of_recommendations: minNumberOfRecommendations,
            },
            false,
            false,
        )
    }

    getPerformanceReportByMail(
        // sl,
        // slType,
        startMonth,
        endMonth,
        sector,
        symbols,
        marketCapCond,
        marketCapVal1,
        marketCapVal2,
        betaCond,
        betaVal1,
        betaVal2,
        // compareToMa50,
        // maxNumberOfAnalysts,
        // minNumberOfRecommendations,
        mailTo,
        repType,
        scoreParam,
        adminTakeTop,
        //adminFromDate,
        //adminToDate,
        topN,
        adminIpMonths,
        adminBtMonths,
        teamThreshold,
        minRatio,
        minMonthAvg,
        scoreVer,
    ) {
        return this.request(
            'reports/performance_opportunities_csv/' +
                //sl +'/' + slType + '/' +
                startMonth +
                '/' +
                endMonth +
                '/' +
                sector +
                '/' +
                symbols +
                '/' +
                marketCapCond +
                '/' +
                marketCapVal1 +
                '/' +
                marketCapVal2 +
                '/' +
                betaCond +
                '/' +
                betaVal1 +
                '/' +
                betaVal2 +
                '/' +
                // compareToMa50 + '/' + maxNumberOfAnalysts + '/' + minNumberOfRecommendations + '/' +
                mailTo +
                '/' +
                repType +
                '/' +
                scoreParam +
                '/' +
                adminTakeTop +
                '/' +
                //adminFromDate +'/' + adminToDate +'/' +
                topN +
                '/' +
                adminIpMonths +
                '/' +
                adminBtMonths +
                '/' +
                teamThreshold +
                '/' +
                minRatio +
                '/' +
                minMonthAvg +
                '/' +
                scoreVer,
            'GET',
            {},
            false,
            false,
        )
    }

    portfolioBacktestMail(
        openDate,
        closeDate,
        //frequency,
        numFiles,
        numTickers,
        numInvMonths,
        universeId,
        email,
    ) {
        return this.request(
            'reports/portfolio_backtest_report',
            'POST',
            {
                start_month: openDate,
                end_month: closeDate,
                universeId: universeId,
                //frequency: frequency,
                numFiles: numFiles,
                numTickers: numTickers,
                num_inv_months: numInvMonths,
                email: email,
            },
            false,
            false,
        )
    }

    // performanceReportDirectAdminExcel(setId, universe, takeTop, sector) {
    //     window.location.href =
    //         'https://app.tipigo.com/reports/performance_opportunities_direct_admin_excel/' +
    //         setId +
    //         '/' +
    //         sector +
    //         '/' +
    //         universe +
    //         '/' +
    //         takeTop +
    //         '/'
    //     return 111
    // }

    performanceReportDirectExcel(setId, universe, sectors) {
        // see comments in performance_opportunities_direct_excel
        window.location.href =
            'https://app.tipigo.com/reports/performance_opportunities_direct_excel/' +
            setId +
            '/' +
            universe +
            '/' +
            sectors
        return 1
    }

    // performanceReportDirect(setId, universe, takeTop, sector) {
    //     console.log('run_direct', sector)
    //     return this.request(
    //         'reports/performance_opportunities_direct',
    //         'POST',
    //         {
    //             set_id: setId,
    //             take_top: takeTop,
    //             universe_id: universe,
    //             sector: sector,
    //         },
    //         false,
    //         false,
    //     )
    // }

    getBacktestGraphData(startMonth, endMonth, tickers, benchmarks) {
        return this.request(
            'reports/backtest_graph_data',
            'POST',
            {
                start_month: startMonth,
                end_month: endMonth,
                tickers: tickers,
                benchmarks: benchmarks,
            },
            false,
            false,
        )
    }

    getAnalystExplain(month, analyst, sector, profile) {
        return this.request(
            'reports/explain_analyst_selection/' +
                month +
                '/' +
                analyst +
                '/' +
                sector +
                '/' +
                profile,
            'GET',
            {},
            false,
            false,
        )
    }

    getBacktestReport(startMonth, endMonth, tickers, benchmarks, email) {
        return this.request(
            'reports/backtest_excel_report',
            'POST',
            {
                start_month: startMonth,
                end_month: endMonth,
                tickers: tickers,
                benchmarks: benchmarks,
                email: email,
            },
            false,
            false,
        )
    }
    // getMarketTrendReport(fromDate, toDate, email) {
    //     return this.request(
    //         'reports/market_trend_report',
    //         'POST',
    //         {
    //             start_date: fromDate,
    //             end_date: toDate,
    //             email: email,
    //         },
    //         false,
    //         false,
    //     )
    // }
    getMarketTrendGraph(fromDate, toDate) {
        if (newApiReports) {
            const queryParams = {
                fromDate: moment(fromDate).format('YYYY-MM-DD'),
                toDate: moment(toDate).format('YYYY-MM-DD'),
            }
            const query = qs.stringify(queryParams)
            console.log('new getMarketTrendGraph', query)
            return this.request(
                `reports/getMarketTrends?${query}`,
                'GET',
                {},
                true,
                false,
                baseNewApiUrl,
            )
        } else {
            return this.request(
                'reports/market_trend_graph_data',
                'POST',
                {
                    start_date: fromDate,
                    end_date: toDate,
                },
                false,
                false,
            )
        }
    }

    getAnalystSectorTrendGraph(
        includeLong,
        includeShort,
        teamOnly,
        numMonths,
        compMonth,
        universe,
    ) {
        // TODO: not done for inTeam - ask keren to cancel that. she said no.
        //       wait for new infra to have team
        if (false && newApiReports) {
            const queryParams = {
                long: includeLong,
                short: includeShort,
                team: teamOnly,
                months: numMonths,
                compareMonth: compMonth,
                universe,
            }
            const query = qs.stringify(queryParams)
            console.log('getAnalystSectorTrendGraph api call', query)
            return this.request(
                `reports/getAnalysSectorTrends?${query}`,
                'GET',
                {},
                true,
                false,
                baseNewApiUrl,
            )
        } else {
            return this.request(
                'reports/analyst_sector_trend/' +
                    (includeLong ? 1 : 0) +
                    '/' +
                    (includeShort ? 1 : 0) +
                    '/' +
                    (teamOnly ? 1 : 0) +
                    '/' +
                    numMonths +
                    '/' +
                    (compMonth ? compMonth : 0) +
                    '/' +
                    (universe ? universe : 0),
                'GET',
                {},
                false,
                false,
            )
        }
    }

    getEtfAllocationsKeys() {
        return this.request(
            'tools/get_etf_allocations_keys/',
            'GET',
            {},
            false,
            false,
        )
    }
    getEtfAllocations(etfs) {
        return this.request(
            'tools/get_etf_allocations_weights/',
            'POST',
            {etfs: etfs},
            false,
            false,
        )
    }
    getUserOrderByID(id) {
        return this.request(
            'user/get_order_by_user/' + id,
            'GET',
            {},
            false,
            false,
        )
    }

    /* 30.08.23
    getAlertsReport(email) {
        return this.request(
            'reports/alerts_report',
            'POST',
            {
                email: email,
            },
            false,
            false,
        )
    }*/

    // manage:

    getAccounts() {
        return this.request('manage/get_accounts', 'POST', {}, false, false)
    }
    // getUserPortolios() {
    //     return this.request(
    //         'manage/get_all_user_portfolios',
    //         'GET',
    //         {},
    //         false,
    //         false,
    //     )
    // }
    getAllUsersWithPortflioBySameAccount(userId) {
        return this.request(
            'reports/get_all_account_portfolios_by_user/' + userId,
            'GET',
            {},
            false,
            false,
        )
    }
    getUserPortfoliosNotificationsReport(
        portfolioId,
        fromDate,
        toDate,
        userId,
    ) {
        // TODO: add q params

        window.location.href =
            'https://app.tipigo.com/reports/user_porfolio_notifications_report/' +
            portfolioId +
            '/' +
            fromDate +
            '/' +
            toDate +
            '/' +
            userId
    }
    getUsers() {
        return this.request('manage/get_users', 'POST', {}, false, false)
    }
    getAnalyst(id) {
        return this.request('manage/get_analyst/' + id, 'GET', {}, false, false)
    }
    getAnalysts() {
        return this.request(
            'manage/get_users',
            'POST',
            {type: 'analysts'},
            false,
            false,
        )
    }

    getProfiles() {
        return this.request('manage/get_profiles', 'POST', {}, false, false)
    }

    updateAccount(account) {
        return this.request(
            'manage/update_account',
            'POST',
            {account},
            false,
            false,
        )
    }

    updateUser(user) {
        return this.request('manage/update_user', 'POST', {user}, false, false)
    }

    adminCreateUser(signUpData) {
        return this.request(
            'user/admin_signup_user',
            'POST',
            {signupData: signUpData},
            true,
            false,
        )
    }
    getAnalystList() {
        return this.request(
            'user/get_user_analyst_list',
            'POST',
            {},
            true,
            false,
        )
    }

    getAllAnalystsGlobalNames() {
        return this.request(
            'user/get_all_analysts_global_name',
            'POST',
            {},
            true,
            false,
        )
    }

    // apphome:
    appLogin(id) {
        return this.request(
            'user/login',
            'POST',
            {
                loginData: {
                    device_id: id,
                    method: '4',
                    rememberMe: true,
                    api_key: 'dab4c6dc-949e-42b3-910f-5cf34ebeb1d2',
                },
            },
            false,
            false,
        )
    }
    getTopNews() {
        return this.request(
            'finance/get_top_news',
            'POST',
            {page_size: 4},
            false,
            false,
        )
    }
    getTopNewsQm(page_size, page) {
        return this.request(
            'finance/get_top_news_qm',
            'POST',
            {page_size: page_size ? page_size : 4, page: page},
            false,
            false,
        )
    }
    getChannelNews(channels, page_size, page) {
        return this.request(
            'finance/get_channels_news_qm',
            'POST',
            {page_size: page_size, page: page, channels: channels},
            false,
            false,
        )
    }
    getTickerNews(tickers, page_size, page) {
        return this.request(
            'finance/get_news_by_tickers_qm',
            'POST',
            {page_size: page_size, page: page, tickers: tickers},
            false,
            false,
        )
    }
    getTopRatings() {
        //console.log('get_finance_info')
        // used by Rating page, is it active?? not in use in LiveFeed
        return this.request(
            'finance/get_finance_info',
            'POST',
            {},
            false,
            false,
        )
    }
    getAppFlavors() {
        return this.request(
            'admin/get_portfolios_app_names',
            'GET',
            {},
            false,
            false,
        )
    }
    getPortfolios(app) {
        return this.request(
            'finance/get_portfolios/' + app + '/false',
            'GET',
            {},
            false,
            false,
        )
    }
    addAppPortfolio(app, name, date) {
        return this.request(
            'finance/add_portfolio',
            'POST',
            {title: name, app: app, date: date},
            false,
            false,
        )
    }
    getPortfolio(pid) {
        return this.request(
            'finance/get_portfolio/' + pid + '/true',
            'GET',
            {},
            true,
            false,
        )
    }
    portfolioActive(pid, active) {
        return this.request(
            'finance/make_portfolio_active',
            'POST',
            {portfolio: pid, active: active},
            false,
            false,
        )
    }
    setEndMonthPl(pid, pl) {
        return this.request(
            'finance/set_portfolio_end_month',
            'POST',
            {portfolio: pid, value: pl},
            false,
            false,
        )
    }

    addPortfolioSymbol(pid, symbol, entry) {
        return this.request(
            'finance/add_portfolio_symbol',
            'POST',
            {portfolio: pid, symbol: symbol, entry: entry},
            false,
            false,
        )
    }
    removePortfolioSymbol(pid, symbol) {
        return this.request(
            'finance/remove_portfolio_symbol',
            'POST',
            {portfolio: pid, symbol: symbol},
            false,
            false,
        )
    }
    notifyAllUsers(app, msg) {
        return this.request(
            'user/send_notification_all_app_users',
            'POST',
            {app_flavor: app, msg: msg},
            false,
            false,
        )
    }

    getUserPortfolioOpenPositions(pid) {
        return this.request(
            'file/get_portfolio_open_positions/' + pid,
            'GET',
            {},
            true,
            false,
        )
    }
    getUserPortfolioSharpRatio(pid) {
        return this.request('file/get_sharp/' + pid, 'GET', {}, true, false)
    }
    getUserPortfolioTikers() {
        return this.request(
            'file/get_portfolio_symbols/',
            'GET',
            {},
            true,
            false,
        )
    }
    getUserPortfolioClosedPositions(pid) {
        return this.request(
            'file/get_portfolio_closed_positions/' + pid,
            'GET',
            {},
            true,
            false,
        )
    }
    deleteAllPortfolioData(pid) {
        return this.request(
            'file/delete_portfolio/' + pid,
            'GET',
            {},
            true,
            false,
        )
    }

    getUserPortfolios(userId) {
        return this.request(
            'file/get_user_portfolios/' + userId,
            'GET',
            {},
            true,
            false,
        )
    }
    getUserPortfolioDayPl(pId) {
        return this.request(
            'file/get_user_portfolios_lastday/' + pId,
            'GET',
            {},
            true,
            false,
        )
    }

    getUserPortfolioNotifications(pid, count, newOnly) {
        return this.request(
            'file/get_portfolio_notifications/' +
                pid +
                '/' +
                count +
                '/' +
                newOnly,
            'GET',
            {},
            true,
            false,
        )
    }
    getUserPortfolioLastMonthNotifications(pid) {
        return this.request(
            'file/get_portfolio_last_month_notifications/' + pid,
            'GET',
            {},
            true,
            false,
        )
    }
    setUserPortfolioNotiPop(pid, count) {
        return this.request(
            'file/set_portfolio_notifications_pop/' + pid + '/' + count,
            'GET',
            {},
            true,
            false,
        )
    }

    getPortfolioNotificationsSettings(pId) {
        return this.request(
            'file/get_notifications_settings/' + pId,
            'GET',
            {},
            true,
            false,
        )
    }

    setPortfolioNotificationsSettings(pId, settings) {
        return this.request(
            'file/update_notifications_settings',
            'POST',
            {
                portfolio: pId,
                settings: settings,
            },
            false,
            false,
        )
    }

    createUserPortfolio(userId, budget, name, notes, date) {
        return this.request(
            'file/create_user_portfolio',
            'POST',
            {
                user: userId,
                name: name,
                start_budget: budget,
                notes: notes,
                date: date,
            },
            false,
            false,
        )
    }
    addUserPortfolioBudget(pid, units, unitsPrice, notes, add) {
        if (newApiPortfolioFunds) {
            return this.request(
                'portfolioDeposits',
                'POST',
                {
                    portfolioId: pid,
                    units: units * (add ? 1 : -1),
                    unitPrice: unitsPrice,
                    notes,
                },
                true,
                false,
                baseNewApiUrl,
            )
        } else {
            return this.request(
                'file/add_user_portfolio_budget',
                'POST',
                {
                    portfolio_id: pid,
                    units: units,
                    units_price: unitsPrice,
                    notes: notes,
                    add: add,
                },
                true,
                false,
            )
        }
    }

    getUserPortfolioLastDepositeDate(pId, userId) {
        if (newApiPortfolioFunds) {
            return this.request(
                'portfolioDeposits/portfolio/' + pId,
                'GET',
                {},
                false,
                false,
                baseNewApiUrl,
            )
        } else
            return this.request(
                `file/get_last_fund_date/${pId}/${userId}`,
                'GET',
                {},
                true,
                false,
            )
    }

    getSymbol2EtfChart(sym, etf, from_date) {
        // TODO: this is kind of double to getBacktestGraphData. also on server.. check all
        return this.request(
            'finance/get_symbol_2_etf_chart/' +
                sym +
                '/' +
                etf +
                '/' +
                from_date,
            'GET',
            {},
            true,
            false,
        )
    }

    getUserPortfolioChart(pid, etfs, fromDate, toDate) {
        return this.request(
            'file/get_portfolio_chart',
            'POST',
            {
                pid: pid,
                etfs: etfs,
                //file_n: n,
                from_date: fromDate,
                to_date: toDate,
            },
            true,
            false,
        )
    }

    getUserPortfolioPieChart(pid) {
        return this.request(
            'file/get_portfolio_pie_chart',
            'POST',
            {pid: pid},
            true,
            false,
        )
    }
    copyPortfolioUser2User(sourceId, destId) {
        return this.request(
            'file/copy_file_to_user/' + sourceId + '/' + destId,
            'GET',
            {},
            true,
            false,
        )
    }
    downloadUserPortfolio(pId) {
        //TODO:? get url from config, use like others with response?
        window.location.href =
            'https://app.tipigo.com/file/download_user_portfolio/' + pId
        // return this.request(
        //     'finance/download_user_portfolio/' + pId,
        //     'GET',
        //     {},
        //     true,
        //     false,
        // )
    }

    // addUserPortfolioPosition(
    //     userId,
    //     symbol,
    //     pId,
    //     entry,
    //     quantity,
    //     entryDate,
    //     notes,
    //     sec_id,
    //     posType,
    // ) {
    //     return this.request(
    //         'file/add_user_security',
    //         'POST',
    //         {
    //             user: userId,
    //             symbol: symbol,
    //             portfolio_id: pId,
    //             entry_price: entry,
    //             quantity: quantity,
    //             notes: notes,
    //             entry_date: entryDate,
    //             scurity_id: sec_id,
    //             pos_type: posType,
    //         },
    //         false,
    //         false,
    //     )
    // }

    addUserPortfolioPositions(userId, pId, items) {
        return this.request(
            'file/add_user_securities',
            'POST',
            {
                user: userId,
                items: items,
                portfolio_id: pId,
            },
            false,
            false,
        )
    }

    deleteUserPortfolioPosition(sec_ids) {
        return this.request(
            'file/delete_user_security',
            'POST',
            {
                scurity_ids: sec_ids,
            },
            false,
            false,
        )
    }

    closeUserSecurities(pId, items) {
        return this.request(
            'file/close_user_securities',
            'POST',
            {
                portfolio_id: pId,
                items: items,
            },
            false,
            false,
        )
    }
    /*closeUserSecurity(positionId, price, quantity, notes, closeDate) {
        return this.request(
            'file/close_user_security',
            'POST',
            {
                scurity_id: positionId,
                close_price: price,
                quantity: quantity,
                notes: notes,
                close_date: closeDate,
            },
            false,
            false,
        )
    }*/

    getUserPortfolioUnitPrice(pId) {
        return this.request(
            'file/get_unit_price/' + pId,
            'GET',
            {},
            true,
            false,
        )
    }

    getSymbolLastPrice(symbol) {
        return this.request(
            'finance/get_symbol_and_last/' + symbol,
            'GET',
            {},
            true,
            false,
        )
    }
    getTipigoIndexes(onlyPrivate) {
        if (newApiUniverses) {
            return this.request(
                'universes',
                'GET',
                {},
                false,
                false,
                baseNewApiUrl,
            )
        } else {
            return this.request(
                'tools/get_tipigo_indexes/' + onlyPrivate,
                'GET',
                {},
                false,
                false,
            )
        }
    }

    addTipigoIndex(name, indexId, newTickersStr, deleted, tickersArr) {
        if (newApiUniverses) {
            const tickerArray = newTickersStr
                .split(/(?:,| |\n)+/)
                .map((t) => t.toUpperCase())
                .concat(tickersArr)
                .filter(Boolean)

            return this.request(
                'universes' + (indexId ? `/${indexId}` : ''),
                indexId ? 'PUT' : 'POST',
                {
                    name: name,
                    id: indexId,
                    tickers: tickerArray,
                },
                false,
                false,
                baseNewApiUrl,
            )
        } else {
            return this.request(
                'tools/set_tipigo_index',
                'POST',
                {
                    name: name,
                    index_id: indexId,
                    tickers: newTickersStr,
                    deleted: deleted,
                },
                false,
                false,
            )
        }
    }

    getTipigoIndexSymbols(indexId) {
        if (newApiUniverses) {
            return this.request(
                'universes/' + indexId,
                'GET',
                {},
                false,
                false,
                baseNewApiUrl,
            )
        } else {
            return this.request(
                'tools/get_index_symbols/' + indexId,
                'GET',
                {},
                true,
                false,
            )
        }
    }
    /*removeFromTipigoIndex(indexId, symbol) {
        // can also work with array of tickers
        return this.request(
            'tools/remove_from_tipigo_index',
            'POST',
            {
                index_id: indexId,
                ticker: symbol,
            },
            false,
            false,
        )
    }*/
    deleteTipigoIndex(indexId) {
        if (newApiUniverses) {
            return this.request(
                'universes/' + indexId,
                'DELETE',
                {},
                false,
                false,
                baseNewApiUrl,
            )
        } else {
            return this.request(
                'tools/delete_tipigo_index',
                'POST',
                {
                    index_id: indexId,
                },
                false,
                false,
            )
        }
    }
    // getScoreProfiles() {
    //     return this.request('tools/get_score_profiles', 'GET', {}, true, false)
    // }
    // setScoreProfile(name, params) {
    //     return this.request(
    //         'tools/add_score_profile',
    //         'POST',
    //         {
    //             name: name,
    //             params: params,
    //         },
    //         false,
    //         false,
    //     )
    // }
    // updateScoreProfile(id, params) {
    //     return this.request(
    //         'tools/upadte_score_profile',
    //         'POST',
    //         {
    //             id: id,
    //             params: params,
    //         },
    //         false,
    //         false,
    //     )
    // }
    sendSupportMail(name, mail, phone, company, reason, text) {
        return this.request(
            'user/support_msg/',
            'POST',
            {
                name: name ? name : 'empty',
                mail: mail ? mail : 'empty',
                phone: phone ? phone : 'empty',
                company: company ? company : 'empty',
                text: text ? text : 'empty',
                reason: reason ? reason : 'empty',
            },
            true,
            false,
        )
    }

    /* 06.08.23
    getProfileReport(profile, email) {
        return this.request(
            'reports/profile_alerts_report/',
            'POST',
            {
                profile_name: profile,
                email: email,
            },
            true,
            false,
        )
    }*/

    adminAddSymbols(symbols, sectorId) {
        return this.request(
            'tools/add_symbols_to_system/',
            'POST',
            {
                tickers: symbols,
                sector_id: sectorId,
            },
            true,
            false,
        )
    }
    adminSymbolsNoSector() {
        return this.request(
            'tools/get_symbols_no_sectors/',
            'GET',
            {},
            true,
            false,
        )
    }
    registerAccountAndUser(comapny, email, firstName, lastName, pass) {
        return this.request(
            'user/account_and_user_signup/',
            'POST',
            {
                signupData: {
                    password: pass,
                    email: email,
                    account_name: comapny,
                    first_name: firstName,
                    last_name: lastName,
                },
            },
            true,
            false,
        )
    }

    saveOppScannerSetting(
        id,
        name,
        desc,
        mcVal1,
        mcVal2,
        betaVal1,
        betaVal2,
        DaysToEarning,
        minUpside,
        minEps,
        minRoe,
        filterAdr,
        removeIfAnyShort,
        excludeNoRatings,
    ) {
        if (newApiScannerSettings) {
            const screeners = {
                daysToEarning: floatOrEmpty(DaysToEarning),
                minUpside: floatOrEmpty(minUpside),
                minEps: floatOrEmpty(minEps),
                minRoe: floatOrEmpty(minRoe),
                filterAdr,
                removeIfAnyShort,
                excludeNoRatings,
            }
            if (mcVal1 || mcVal2) {
                screeners.marketCap = {
                    min: floatOrEmpty(mcVal1),
                    max: floatOrEmpty(mcVal2),
                }
            }
            if (betaVal1 || betaVal2) {
                screeners.beta = {
                    min: floatOrEmpty(betaVal1),
                    max: floatOrEmpty(betaVal2),
                }
            }
            return this.request(
                'reportSettings' + (id ? `/${id}` : ''),
                id ? 'PUT' : 'POST',
                {
                    name,
                    description: desc,
                    screeners,
                },
                true,
                false,
                baseNewApiUrl,
            )
        } else {
            return this.request(
                'tools/save_opp_scanner/',
                'POST',
                {
                    id: id,
                    name: name,
                    description: desc,
                    mc_val1: mcVal1,
                    mc_val2: mcVal2,
                    beta_val1: betaVal1,
                    beta_val2: betaVal2,
                    filter_earning: DaysToEarning,
                    min_upside: minUpside,
                    min_eps: minEps,
                    min_roe: minRoe,
                },
                true,
                false,
            )
        }
    }
    getAccountOppScannerSettings() {
        if (newApiScannerSettings) {
            return this.request(
                'reportSettings/',
                'GET',
                {},
                false,
                false,
                baseNewApiUrl,
            )
        } else {
            return this.request(
                'tools/get_all_opp_scanner_settings/',
                'GET',
                {},
                true,
                false,
            )
        }
    }
    getOneOppScannerSettings(id) {
        if (newApiScannerSettings) {
            return this.request(
                'reportSettings/' + id,
                'GET',
                {},
                false,
                false,
                baseNewApiUrl,
            )
        } else {
            return this.request(
                'tools/get_opp_scanner_settings/' + id,
                'GET',
                {},
                true,
                false,
            )
        }
    }

    // new server

    loginNew(email, password) {
        return this.request(
            'auth/login',
            'POST',
            {email, password},
            false,
            false,
            baseNewApiUrl,
        )
    }
    logoutNew(email, password) {
        return this.request(
            'auth/logout',
            'POST',
            {},
            false,
            false,
            baseNewApiUrl,
        )
    }

    getOpportunityScanner(params) {
        return this.request(
            `reports/getOpportunityScanner?${qs.stringify(params)}`,
            'GET',
            {},
            false,
            false,
            baseNewApiUrl,
        )
    }

    invokeGetUrl(url) {
        return this.request(url, 'GET', {}, false, false, baseNewApiUrl)
    }
}
// reportparams.currentmonth
export const api = new Api()
