export const AlertTypes = {
    BUY: 'alert_type_buy',
    SELL: 'alert_type_sell',
    EARNING_ANNOUNCEMENT: 'alert_type_earning_announcement',
    TARGET_PRICE: 'alert_type_target_price',
    UNDER_PERFORMING_RELATIVE_TO_SECTOR:
        'alert_type_under_performin_relative_to_sector',
    EARNING_ANNOUNCEMENT_TODAY: 'alert_type_earning_announcement_today',
}

export const Indexes = [
    {
        type: 'sp500',
        title: 'S&P 500',
    },
    {
        type: 'russell2000',
        title: 'Russell 2000',
    },
]

export const ScreenersInfo = {
    indexes: {
        title: 'Index',
        description: 'Include only stocks that are part of a specific index.',
    },
    market_val: {
        title: 'Market Cap',
        description:
            'Market capitalization is the total value of a company in the stock market. It is calculated by multiplying total shares outstanding by the current price per share.',
    },
    // "moving_ave_50": { title: "Moving Ave 50", description: "description..." },
    beta: {
        title: 'Beta',
        description:
            "The measure of a fund's or a stock's risk in relation to the market or to an alternative benchmark.",
    },
    last_price: {
        title: 'Last Price',
        description:
            'The most recently reported trade price, which is not necessarily the most recently traded price.',
    },
    pe_ratio_12m: {
        title: 'P/E 12 Months',
        description:
            'The ratio of the stock price to the sum of its reported earnings, which may or may not account for dilution, over the last 4 quarters.',
    },
    // "dividend_yield": { title: "Dividend Yield", description: "description..." },
    avg_vol_20d: {
        title: 'Average Vol. 20 days',
        description:
            'The average number of shares of stock traded over the last 20 days.',
    },
    peg_ratio: {
        title: 'PEG Ratio',
        description:
            "Price/earnings to growth ratio is a valuation metric for determining the relative trade-off between the price of a stock, the earnings generated per share (EPS), and the company's expected growth.",
    },
    // "upside": { title: "Upside (target ratio)", description: "description..." },
    // "ea": { title: "Earning Announcements", description: "description..." },
    // "downside": { title: "Downside (stop loss)", description: "description..." }
    // 25.02.23 need to add this to filter on livefeed, not sure it's related to here...
    rate: {title: 'Analyst Rate', description: 'Analyst Rate description'},
    //ti_indexes: {title: '', description: ''},
}

export const Sectors = [
    {
        name: 'Energy',
        symbol: 'XLE',
        value: 1.05,
        material_icon: 'flight',
        svg: 'energy_sector',
    },
    {
        name: 'Materials',
        symbol: 'XLB',
        value: 1.27,
        material_icon: 'highlight',
        svg: 'materials_sector',
    },
    {
        name: 'Industrials',
        symbol: 'XLI',
        value: 0.91,
        material_icon: 'lightbulb_outline',
        svg: 'industrials_sector',
    },
    {
        name: 'Consumer Discretionary',
        symbol: 'XLY',
        value: 0.96,
        material_icon: 'account_balance',
        svg: 'discretionary_sector',
    },
    {
        name: 'Consumer Staples',
        symbol: 'XLP',
        value: 0.51,
        material_icon: 'flight',
        svg: 'staples_sector',
    },
    {
        name: 'Healthcare',
        symbol: 'XLV',
        value: 0.87,
        material_icon: 'highlight',
        svg: 'health_sector',
    },
    {
        name: 'Financials',
        symbol: 'XLF',
        value: 0.93,
        material_icon: 'lightbulb_outline',
        svg: 'financials_sector',
    },
    {
        name: 'Information Technology',
        symbol: 'XLK',
        value: 1.05,
        material_icon: 'account_balance',
        svg: 'tech_sector',
    },
    {
        name: 'Utilities',
        symbol: 'XLU',
        value: 0.17,
        material_icon: 'flight',
        svg: 'utilities_sector',
    },
    {
        name: 'Real Estate',
        symbol: 'VNQ',
        value: 0.58,
        material_icon: 'highlight',
        svg: 'realestate_sector',
    },
    {
        name: 'Biotechnology',
        symbol: 'XBI',
        value: 1.87,
        material_icon: 'lightbulb_outline',
        svg: 'biotech_health_sector',
    },
]

export const unvirsesForHistoryScore = [
    'S&P500',
    'S&P100',
    'NASDAQ100',
    'RUSSELL1000',
    'RUSSELL2000',
]
