import React, {Component} from 'react'
import styled from 'styled-components'
import FrameSelect from '@components/General/FrameSelect'
import {SendButton, SendButtonDis} from '@components/General/Elements'
import {
    useEffect,
    useUser,
    useSectors,
    useSystemSectors,
    useDispatch,
    usePortfolioSize,
} from '@services/hooks'
import {getFinancialData} from '@store/actions'
import {useState} from 'react'
import _ from 'lodash'
import {api} from '@services/api'
// import swal from 'sweetalert2'

const ProfileSideBar = ({
    sectors,
    currentSector,
    setCurrentSector,
    setReloadAll,
    setLoading,
}) => {
    const dispatch = useDispatch()
    const systemSectors = useSystemSectors()
    const user = useUser()
    const portfolioSize = usePortfolioSize()
    const [availSectors, setAvailSectors] = useState([])
    const [sector2Add, setSector2Add] = useState('')
    //const ssectors = useSectors()

    useEffect(() => {
        dispatch(getFinancialData(user.session_token)) // this will init sysytemSectors
    }, [])

    useEffect(() => {
        // possible bug: got here with systemSectors =[] and not again. adeed length
        if (systemSectors && systemSectors.length && sectors) {
            let hasSectors = sectors.map((s) => s.sector_ref_id)
            let sectorList = []

            systemSectors.forEach((ss) => {
                if (!hasSectors.includes(ss.id)) {
                    sectorList.push({label: ss.name, value: ss.id})
                }
            })
            setAvailSectors(sectorList)

            // systemSectors.map((s) => {
            //     return {label: s.name, value: s.id}
            // })
        }
    }, [systemSectors.length && sectors])

    const addSector = () => {
        setLoading(true)
        let sysSec = systemSectors.find((ss) => ss.id == sector2Add)
        // TODO: all this data seems to be BS, is it used? can it be changed? need to go over users_sectors table
        if (sysSec) {
            var us = {
                ...sysSec,
                sector_ref_id: sector2Add,
                size: 100000,
                vts_percentage: 0,
                svts_percentage: 0,
                val: 0,
                symbol: sysSec.etf,
                stoploss: 10,
                target_percentage: 0,
                ratings_to_copy: [],
                up_down_ratio: 1,
                sp_500_only: false,
                logic: 0,
                strategies: [],
                target_amount: 0.5,
                screeners: [
                    // updateOrCreateScreener(
                    //     null,
                    //     'market_val',
                    //     'greater_than',
                    //     2000,
                    //     null,
                    // ),
                    // updateOrCreateScreener(null, 'beta', 'between', 0.5, 2.5),
                ],
                portfolioSize: portfolioSize,
            }
            //console.log('selected sector', us)
            api.addSector2Profile(us).subscribe(
                (response) => {
                    console.log('addSector2Profile response', response)
                    setReloadAll(true)
                },
                (error) => {
                    console.log('addSector2Profile error', error)
                },
            )
        }
    }
    return (
        <Container>
            {sectors &&
                sectors.map((sector) => {
                    console.log('the_sector', sector)
                    return (
                        <OneSector
                            key={sector.id}
                            onClick={() => setCurrentSector(sector)}
                            selected={
                                currentSector &&
                                currentSector.name == sector.name
                            }>
                            {sector.name}
                        </OneSector>
                    )
                })}
            <AddCont>
                <FrameSelect
                    title={'Add Sector'}
                    values={availSectors}
                    value={sector2Add}
                    setValue={setSector2Add}
                    style={{width: 200, marginRight: 15}}
                />
                {sector2Add ? (
                    <SendButton onClick={addSector} style={{alignSelf: 'end'}}>
                        Add
                    </SendButton>
                ) : (
                    <SendButtonDis style={{alignSelf: 'end'}}>
                        Add
                    </SendButtonDis>
                )}
            </AddCont>
        </Container>
    )
}

export default ProfileSideBar

const Container = styled.div`
    width: 330px;
    padding: 20px;
    padding-right: 0px;
`
const OneSector = styled.div`
    font-size: 14px;
    background-color: ${(p) => (p.selected ? '#eee' : null)};
    padding: 20px;
`
const AddCont = styled.div`
    display: flex;
    margin-top: 20px;
`
