import React from 'react'
import {api, newApiScannerSettings} from '@services/api'
import {useState, useEffect} from '@services/hooks'
import {
    SendButton,
    SendButtonDis,
    SendButton2,
    FormRow,
} from '@components/General/Elements'
import FrameInput from '@components/General/FrameInput'
import FrameText from '@components/General/FrameText'
import styled from 'styled-components'
import ScreenerSelector from '../ReportContainer/ScreenerSelector'

const mainWidth = 550

const emptySettings = {
    marketCapCond: '0',
    marketCapValue1: 0,
    marketCapValue2: 0,
    betaCond: '0',
    betaValue1: 0,
    betaValue2: 0,
    daysToEarning: null,
    minEps: null,
    minRoe: null,
    minUpside: null,
    filterAdr: false,
    removeIfAnyShort: false,
    excludeNoRatings: false,
}

const OppScannerEdit = ({selectedSet, setSelectedSet, setStage}) => {
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')

    const [editSettings, setEditSettings] = useState(emptySettings)

    // const formatValidFloat = (value) => {
    //     value = value.trim()
    //     if (value === '' || value === '-' || value === '.' || value === '-0') {
    //         return value
    //     } else {
    //         if (!isNaN(value)) {
    //             if (value.endsWith('.')) return value
    //             else return parseFloat(value)
    //         }
    //     }
    //     return false
    // }
    // const _setMinUpside = (value) => {
    //     const newValue = formatValidFloat(value)
    //     if (newValue !== false) setMinUpside(newValue)
    // }
    // const _setMinEps = (value) => {
    //     const newValue = formatValidFloat(value)
    //     if (newValue !== false) setMinEps(newValue)
    // }
    // const _setMinRoe = (value) => {
    //     const newValue = formatValidFloat(value)
    //     if (newValue !== false) setMinRoe(newValue)
    // }
    // const _setFilterEarning = (value) => {
    //     value = value.trim()
    //     if (value == '') {
    //         setFilterEarning('')
    //     } else {
    //         value = parseInt(value)
    //         if (!isNaN(value) && value >= 0) {
    //             setFilterEarning(value)
    //         }
    //     }
    // }
    // const setSetting = (value, setter, checkSetter) => {
    //     const val = value || value === 0 ? value : ''
    //     checkSetter(val !== '')
    //     setter(val)
    // }

    const formatOneSet2 = (response) => {
        console.log('formatOneSet2', response)
        const {screeners} = response.data
        var betaCond = 0
        if (screeners?.beta?.min && !screeners?.beta?.max) betaCond = 2
        else if (!screeners?.beta?.min && screeners?.beta?.max) betaCond = 1
        else if (screeners?.beta?.min && screeners?.beta?.max) betaCond = 3
        var marketCapCond = 0
        if (screeners?.marketCap?.min && !screeners?.marketCap?.max)
            marketCapCond = 2
        else if (!screeners?.marketCap?.min && screeners?.marketCap?.max)
            marketCapCond = 1
        else if (screeners?.marketCap?.min && screeners?.marketCap?.max)
            marketCapCond = 3
        setEditSettings({
            ...screeners,
            marketCapCond,
            marketCapValue1: screeners?.marketCap?.min || '',
            marketCapValue2: screeners?.marketCap?.max || '',
            betaCond,
            betaValue1: screeners?.beta?.min || '',
            betaValue2: screeners?.beta?.max || '',
        })
    }
    useEffect(() => {
        console.log('editSettings', editSettings)
    }, [editSettings])

    // const formatOneSet = (response) => {
    //     // TODO: formatOneSet2 to reoplace this. work in progress. see TODO above
    //     if (response.data?.settings)
    //         return {set: response.data?.settings, isNew: false}
    //     const {screeners} = response.data
    //     return {
    //         set: {
    //             ...response.data,
    //             screeners: {
    //                 min_eps: screeners?.minEps,
    //                 min_roe: screeners?.minRoe,
    //                 market_val: screeners?.marketCap,
    //                 beta: screeners?.beta,
    //                 filter_target: screeners?.minUpside,
    //                 filter_earning: screeners?.daysToEarning,
    //                 filterAdr: screeners?.filterAdr || false,
    //                 removeIfAnyShort: screeners?.removeIfAnyShort || false,
    //             },
    //         },
    //         isNew: true,
    //     }
    // }

    // const setMoreOldScreeners = (set) => {
    //     if (set.screeners) {
    //         // screeners are not dynamic because there are only 2. if add more make it an array
    //         set.screeners.forEach((item) => {
    //             const f0 = item.type == 'market_val' ? setMcCond : setBetaCond
    //             const f1 = item.type == 'market_val' ? setMcVal1 : setBetaVal1
    //             const f2 = item.type == 'market_val' ? setMcVal2 : setBetaVal2
    //             f1(item.val1 ? item.val1 : 0)
    //             f2(item.val2 ? item.val2 : 0)
    //             f0(item.condition)
    //         })
    //     }
    // }

    // const setMoreNewScreeners = (set) => {
    //     const {screeners} = set
    //     console.log('new_screeners', screeners)
    //     const rangeFilters = ['beta', 'market_val']
    //     rangeFilters.forEach((filter) => {
    //         const f0 = filter == 'market_val' ? setMcCond : setBetaCond
    //         const f1 = filter == 'market_val' ? setMcVal1 : setBetaVal1
    //         const f2 = filter == 'market_val' ? setMcVal2 : setBetaVal2
    //         const min = screeners[filter]?.min
    //         const max = screeners[filter]?.max
    //         if (screeners[filter]) {
    //             if (min && max) f0(3)
    //             else if (min && !max) f0(2)
    //             else if (!min && max) f0(1)
    //             f1(min || '')
    //             f2(max || '')
    //         }
    //     })
    // }

    useEffect(() => {
        if (selectedSet) {
            console.log('got id ', selectedSet)
            api.getOneOppScannerSettings(selectedSet).subscribe(
                (response) => {
                    console.log('getOneOppScannerSettings response', response)

                    formatOneSet2(response)
                    setName(response.data.name)
                    setDesc(response.data.description)
                    // const {set, isNew} = formatOneSet(response)
                    // setName(set.name)
                    // setDesc(set.description)
                    // const screeners = isNew ? set.screeners : set
                    // setSetting(
                    //     screeners.filter_target,
                    //     setMinUpside,
                    //     setUseTarget,
                    // )
                    // setSetting(
                    //     screeners.filter_earning,
                    //     setFilterEarning,
                    //     setUseEarning,
                    // )
                    // setFilterAdr(screeners.filterAdr)
                    // setRemoveIfAnyShort(screeners.removeIfAnyShort)
                    // setSetting(screeners.min_eps, setMinEps, setUseEps)
                    // setSetting(screeners.min_roe, setMinRoe, setUseRoe)
                    // setMcCond(0)
                    // setBetaCond(0)
                    // if (isNew) {
                    //     setMoreNewScreeners(set)
                    // }

                    // else {
                    //     setMoreOldScreeners(set)
                    // }
                },
                (error) => {
                    console.log('getOneOppScannerSettings error', error)
                },
            )
        } else {
            setName('')
            setDesc('')
        }
    }, [selectedSet])

    const saveSettings = () => {
        api.saveOppScannerSetting(
            selectedSet,
            name.trim(),
            desc,
            editSettings.marketCapValue1,
            editSettings.marketCapValue2,
            editSettings.betaValue1,
            editSettings.betaValue2,
            editSettings.daysToEarning,
            editSettings.minUpside,
            editSettings.minEps,
            editSettings.minRoe,
            editSettings.filterAdr,
            editSettings.removeIfAnyShort,
            editSettings.excludeNoRatings,
        ).subscribe(
            (response) => {
                console.log('saveOppScannerSetting response', response)
                if (response.data.id || response.data._id) {
                    setSelectedSet(response.data.id || response.data._id)
                    setStage('afterEdit')
                } else {
                    console.log('saveOppScannerSetting wrong', response)
                }
            },
            (error) => {
                console.log('saveOppScannerSetting error', error)
            },
        )
    }

    // const getFilterRow = (
    //     title,
    //     defaultValue,
    //     value,
    //     setter,
    //     preSetter,
    //     check,
    //     setCheck,
    //     placeholder,
    //     checkOnly,
    // ) => {
    //     // this is a workaround. use to be with value and then they asked for no value
    //     // but no value is same as 0 - positive/negative
    //     // so keep it here for now as they might ask for value again..
    //     const visableInput =
    //         title.includes('EPS') || title.includes('ROE') ? 'none' : 'block'
    //     return (
    //         <FilterRow>
    //             <FormControlLabel
    //                 label={<div style={{fontSize: 15}}>{title} </div>}
    //                 sx={{fontSize: '17px'}}
    //                 control={
    //                     <Checkbox
    //                         checked={check}
    //                         onChange={(e) => {
    //                             setCheck(e.target.checked)
    //                             if (e.target.checked) setter(defaultValue)
    //                             else setter(checkOnly ? false : '')
    //                         }}
    //                     />
    //                 }
    //             />
    //             {!checkOnly && (
    //                 <FrameInput
    //                     style={{marginLeft: 40, display: visableInput}}
    //                     value={value}
    //                     setValue={preSetter}
    //                     placeholder={placeholder}
    //                     disabled={!check}
    //                 />
    //             )}
    //         </FilterRow>
    //     )
    // }

    return (
        <Container>
            <TopPart>
                <MainRow>
                    <FrameInput
                        title={'Name'}
                        value={name}
                        setValue={setName}
                    />
                </MainRow>
                <MainRow>
                    <FrameText
                        title={'Description'}
                        value={desc}
                        setValue={setDesc}
                        frameStyle={{height: 100}}
                    />
                </MainRow>
            </TopPart>

            <MainRow style={{marginBottom: 18}}>
                <InnerTitle>Market Filters</InnerTitle>
            </MainRow>
            <ScreenerSelector
                mainColWidth={mainWidth}
                // wideColWidth={500}
                useScreeners={true}
                tickerSelectionParams={editSettings}
                setTickerSelectionParams={setEditSettings}
            />

            <SaveRow>
                <SendButton2
                    style={{marginRight: 20}}
                    onClick={() => setStage('pre')}>
                    Cancel
                </SendButton2>
                {name.trim() !== '' ? (
                    <SendButton onClick={saveSettings}>Save</SendButton>
                ) : (
                    <SendButtonDis>Save</SendButtonDis>
                )}
            </SaveRow>
        </Container>
    )
}

export default OppScannerEdit

const Container = styled.div`
    dispaly: flex;
    width: ${mainWidth}px;
    border: 0px solid blue;
`
const TopPart = styled.div`
    dispaly: flex;
    width: ${mainWidth}px;
    border: 0px solid green;
`
const MainRow = styled(FormRow)`
    flex-direction: column;
    margin-bottom: 30px;
    width: ${mainWidth}px;
`
const SaveRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border: 0px solid red;
    flex: 1;
`
const InnerTitle = styled.div`
    font-size: 16px;
    font-weight: 500;
`
const FilterRow = styled(MainRow)`
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 40px;
    margin-bottom: 15px;
`
