/**
 * Style related elements
 */
import * as Colors from 'material-ui/styles/colors'
const isMobile = window.innerWidth <= 500

export const AppColors = {
    SECONDARY_APPBAR: Colors.blue500,
    TIPIGO_GREEN: '#00BA6C', //#13d68f', // '#49b649',
    TIPIGO_LIGHT_GREEN: '#a3f7d8',
    TIPIGO_RED: '#db4437',
    TIPIGO_LIGHT_RED: '#eda19b',
    TIPIGO_BLUE: '#212121', //'#4285f4',
    SUB_HEAD_GREY: '#666666',
    APP_GRAY: '#777777',
    APP_GRAY_LIGHT: '#D1D1D1',
    GRAY_BG: '#EFEFEF',
    LIGHT_GRAY_BG: '#F1F1F1',
    APP_BLACK: '#272727',
    //BORDER_GRAY: '#c6c6c6',

    BLACK: '#333333',
    //GREEN_TEXT: '#00BA6C', //'#41C399',
    //GREEN_TEXT2: '#00ba6c',
    RED_TEXT: '#E92D2D',
    YELLOW_TEXT: '#FDC32F',
    MENU_HOVER: '#C6C6C6',
}

export const Dimensions = {
    DRAWER_WIDTH: 330,
    NAVBAR_TOP_OFFSET: 80, // #stupidhead was 64 // the height of the top dark header.
    APP_BAR_HEIGHT: 46, // #stupidhead was 52
    CONTENT_BASE_PADDING: 50,
    PAGE_PADDING_RIGHT: isMobile ? 0 : '10vw',
    PAGE_PADDING_LEFT: isMobile ? 0 : '5vw',
}

export const sectorsChartColors = [
    ['rgba(83, 153, 218, 1)', 'rgba(83, 153, 218, 0.5)'],
    ['rgba(135, 83, 218, 1)', 'rgba(135, 83, 218, 0.5)'],
    ['rgba(218, 83, 172, 1)', 'rgba(218, 83, 172, 0.5)'],
    ['rgba(240, 157, 157, 1)', 'rgba(240, 157, 157, 0.5)'],
    ['rgba(253, 129, 39, 1)', 'rgba(253, 129, 39, 0.5)'],
    ['rgba(255, 214, 0, 1)', 'rgba(255, 214, 0, 0.5)'],
    ['rgba(183, 224, 67, 1)', 'rgba(183, 224, 67, 0.5)'],
    ['rgba(70, 224, 67, 1)', 'rgba(70, 224, 67, 0.5)'],
    ['rgba(164, 151, 84, 1)', 'rgba(164, 151, 84, 0.5)'],
    ['rgba(112, 120, 98, 1)', 'rgba(112, 120, 98, 0.5)'],
    ['rgba(172, 172, 172, 1)', 'rgba(172, 172, 172, 0.5)'],
    ['rgba(112, 85, 103, 1)', 'rgba(112, 85, 103, 0.5)'],
]

export const ChartColors = [
    'rgba(0, 186, 108, 1)',
    'rgba(172, 172, 172, 1)',
    'rgba(83, 153, 218, 1)',
    'rgba(240, 157, 157, 1)',
    'rgba(255, 214, 0, 1)',
    'rgba(164, 151, 84, 1)',
    'rgba(135, 83, 218, 1)',
    'rgba(253, 129, 39, 1)',
    'rgba(112, 85, 103, 1)',
    'rgba(183, 224, 67, 1)',
    'rgba(218, 83, 172, 1)',
    'rgba(70, 224, 67, 1)',
    'rgba(112, 120, 98, 1)',
]

// these were copied from ChartColors when we changed the color set
// they are used where we need green and red.
// BUT maybe they should be the same as alll other green/red that are im many other places
// TODO: ask Keren and Noam. (Noam said to keep them but before he new they are not the same as TIPIGO_GREEN)
export const ChartGreensReds = [
    'rgba(65, 195, 153, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(85, 255, 173, 1)',
    'rgba(255, 120, 150, 1)',
]
