import React from 'react'
import {api} from '@services/api'
import {useState, useEffect, useRef, useUser} from '@services/hooks'
import FrameSelect from '@components/General/FrameSelect'
import FrameInput from '@components/General/FrameInput'

import styled from 'styled-components'
import {ReactComponent as AddLine} from '@images/square_plus.svg'
import {ReactComponent as RemoveLine} from '@images/square_minus.svg'
import {AppColors} from '@services/styles'

const GetSector = ({
    selectedSectors,
    setSelectedSectors,
    lastSelected,
    setLastSelected,
    universe,
}) => {
    const user = useUser()
    const [sector, setSector] = useState('')
    const [sectorList, setSectorList] = useState([])
    const [count, setCount] = useState(35)
    const [countLimit, setCountLimit] = useState(false)

    useEffect(() => {
        setSectors()
    }, [])
    useEffect(() => {
        setSectors()
    }, [selectedSectors, universe])

    useEffect(() => {
        if (
            sectorList.length === 12 &&
            sectorList.some((s) => s.value === 'all') &&
            universe !== 'all'
        ) {
            // default to all only on first. allow user NOT to use all after start selecting
            setSector('all')
        }
    }, [sectorList])

    const _setCount = (value) => {
        value = value.trim()
        if (value == '') {
            setCount('')
        } else {
            value = parseInt(value)
            if (!isNaN(value) && value >= 0) {
                if (value > 500) {
                    value = 500
                    setCountLimit(true)
                    setTimeout(() => setCountLimit(false), 1000)
                }
                setCount(value)
            }
        }
    }

    const addSector = () => {
        let selectedName = allSectors().filter((s) => s.value === sector)[0]
            .label
        setSelectedSectors((prev) => ({
            ...prev,
            [sector]: {
                name: selectedName,
                count: count,
                list: [],
                done: false,
                //temp: false,
            },
        }))
    }

    useEffect(() => {
        if (sector && count && count !== '0') {
            const selectedName = allSectors().filter(
                (s) => s.value === sector,
            )[0].label
            setLastSelected({
                key: sector,
                value: {
                    name: selectedName,
                    count: count,
                    list: [],
                    done: false,
                },
            })
            // add the one currently selected in dropdown. because N and R did not understand the UI
            // this is stupid! if UI is not good than better fix it...

            // it should not be in selectedSectors because we remove each one added. but test it any way as it might change tomorrow..
        } else {
            setLastSelected({})
        }
    }, [sector, count])

    const removeSector = (id) => {
        console.log('remove sector', id)
        const filtered = Object.keys(selectedSectors)
            .filter((key) => !key.includes(id))
            .reduce((cur, key) => {
                return Object.assign(cur, {[key]: selectedSectors[key]})
            }, {})
        setSelectedSectors(filtered)
    }
    const allSectors = () => {
        let list = user.system_sectors.map((s) => {
            return {label: s.name, value: s.id}
        })
        list.unshift({label: 'All', value: 'all'})
        return list
    }
    const setSectors = () => {
        console.log('universe', universe)
        setSector('')
        setSectorList(
            allSectors()
                .filter((s) => !Object.keys(selectedSectors).includes(s.value))
                .map((s) => {
                    return {
                        label: s.label,
                        value: s.value,
                        disabled: universe === 'all' && s.value === 'all',
                    }
                }),
        )
    }

    return (
        <Container>
            {Object.entries(selectedSectors).map(([key, value]) => (
                <Row key={key}>
                    <FrameInput
                        value={value.name}
                        disabled
                        style={{width: 400}}
                    />
                    <FrameInput
                        value={value.count}
                        disabled
                        style={{marginLeft: 25, flex: 1}}
                    />
                    {/* <SectorName>{value.name}</SectorName>
                    <SectorCount>{value.count}</SectorCount> */}
                    <RemoveLine
                        onClick={() => removeSector(key)}
                        style={{
                            marginLeft: 25,
                            alignSelf: 'end',
                        }}
                    />
                </Row>
            ))}

            {sectorList.length > 0 && (
                <Row style={{marginTop: 10}}>
                    <FrameSelect
                        style={{width: 400}}
                        value={sector}
                        values={sectorList}
                        setValue={setSector}
                        title={'Add Sector'}
                    />
                    <FrameInput
                        style={{marginLeft: 25, flex: 1}}
                        inputStyle={{
                            color: countLimit ? AppColors.RED_TEXT : '#000',
                        }}
                        value={count}
                        setValue={_setCount}
                        title="Number of Symbols"
                    />
                    {sector !== '' &&
                    count !== '' &&
                    count !== '0' &&
                    count !== 0 ? (
                        <AddLine
                            style={{
                                marginLeft: 25,
                                stroke: AppColors.TIPIGO_GREEN,
                                alignSelf: 'end',
                            }}
                            onClick={addSector}
                        />
                    ) : (
                        <AddLine
                            style={{
                                marginLeft: 25,
                                stroke: AppColors.APP_GRAY_LIGHT,
                                alignSelf: 'end',
                                height: 50,
                            }}
                        />
                    )}
                </Row>
            )}
        </Container>
    )
}

export default GetSector

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 0px solid blue;
    font-family: 'Inter';
    align-items: flex-end;
`
const Row = styled.div`
    font-size: 14px;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    border: 0px solid red;
    width: 100%;
    margin-bottom: 15px;
`

const SectorName = styled.div`
    width: 400px;
    padding-left: 7px;
`
const SectorCount = styled.div`
    margin-left: 30px;
    display: flex;
    flex: 1;
`
