import React from 'react'
import styled from 'styled-components'
import {useState, useEffect} from '@services/hooks'
import Dialog from '@mui/material/Dialog'
import 'react-datepicker/dist/react-datepicker.css'
import FrameInput from '@components/General/FrameInput'
import FrameText from '@components/General/FrameText'
import FrameSelect from '@components/General/FrameSelect'
import DateRange from '@components/General/DateRange'
import swal from 'sweetalert2'
import {AppColors} from '@services/styles'

import {
    Title,
    SendButton,
    SendButtonDis,
    DialogContainer,
    DialogTopRow,
    DialogRow,
} from '@components/General/Elements'
import {api} from '@services/api'
import {Close} from '@mui/icons-material'
import Analytics from '@services/analytics'

const AddBudget = ({
    isOpenDialog,
    setIsOpenDialog,
    setNeedRefresh,
    pId,
    userId,
}) => {
    const [budget, setBudget] = useState(0)
    const [units, setUnits] = useState(100)
    const [canSave, setCanSave] = useState(false)
    const [unitPrice, setUnitPrice] = useState(0)
    const [notes, setNotes] = useState('')
    const [add, setAdd] = useState(true)
    const [startDate, setStartDate] = useState(new Date())

    useEffect(() => {
        // set it each time diaolg opens because it is related to market prices
        if (isOpenDialog && pId) {
            api.getUserPortfolioUnitPrice(pId).subscribe(
                (response) => {
                    console.log('getUserPortfolioUnitPrice response', response)
                    if (response.data && response.data.unit_price) {
                        setUnitPrice(response.data.unit_price)
                    }
                },
                (error) => {
                    console.log('getUserPortfolioUnitPrice error', error)
                },
            )
        } else if (isOpenDialog && pId === null && userId) {
            setUnitPrice(1)
            setUnits(100000)
        } else {
            // console.log('error on add Budget dialog', pId, userId)
            setIsOpenDialog(false)
        }
    }, [isOpenDialog, pId, userId])

    const save = () => {
        Analytics.exec('track', 'add funds')
        if (pId === null && userId) {
            api.createUserPortfolio(
                userId,
                budget,
                'My File',
                notes,
                startDate,
            ).subscribe(
                (response) => {
                    console.log('createUserPortfolio response', response)
                    setNeedRefresh(true)
                    setIsOpenDialog(false)
                },
                (error) => {
                    console.log('createUserPortfolio error', error)
                    swal.fire({
                        text: 'Error creating portfolio. Please contact support',
                        icon: 'error',
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                    })
                },
            )
        } else {
            api.addUserPortfolioBudget(
                pId,
                units,
                unitPrice,
                notes,
                add,
            ).subscribe(
                (response) => {
                    console.log('addUserPortfolioBudget', response)
                    // if (response.data && response.data.status == 1) {
                    setNeedRefresh(true)
                    setIsOpenDialog(false)
                    // } else if (response.data.status == 2) {
                    //     swal.fire({
                    //         text: 'Insufficient cash pool',
                    //         icon: 'error',
                    //         confirmButtonColor: AppColors.TIPIGO_GREEN,
                    //     })
                    // } else {
                    //     swal.fire({
                    //         text: 'Error adding funds',
                    //         icon: 'error',
                    //         confirmButtonColor: AppColors.TIPIGO_GREEN,
                    //     })
                    // }
                },
                (error) => {
                    console.log('addUserPortfolioBudget error', error)
                },
            )
        }
    }

    useEffect(() => {
        setBudget((units * unitPrice).toFixed(2))
        setCanSave(units * unitPrice > 0)
    }, [units, unitPrice])

    const _setUnits = (value) => {
        value = value.trim()
        if (value == '') {
            setUnits(0)
        } else {
            value = parseInt(value)
            if (!isNaN(value) && value >= 0) {
                setUnits(value)
            }
        }
    }

    return (
        <Dialog
            maxWidth="xs"
            fullWidth
            modal={true}
            open={isOpenDialog}
            onClose={() => setIsOpenDialog(false)}>
            <DialogContainer>
                <DialogTopRow>
                    <Title text={'Set Portfolio Funds'} />
                    <Close onClick={() => setIsOpenDialog(false)} />
                </DialogTopRow>
                <DialogRow
                    style={{
                        justifyContent: 'space-between',
                    }}>
                    {pId !== null ? (
                        <FrameSelect
                            title="Action"
                            style={{width: 168}}
                            values={[
                                {label: 'Deposit', value: true},
                                {label: 'Withdrawal', value: false},
                            ]}
                            value={add}
                            setValue={setAdd}
                        />
                    ) : (
                        <DateRange
                            dateFormat={'MM/dd/yyyy'}
                            oneDate
                            style={{width: 169}}
                            oneDateTitle={'Portfolio Start Date'}
                            fromDate={startDate}
                            setFromDate={setStartDate}
                            toDate={new Date()}
                        />
                    )}
                    <FrameInput
                        title={'Unit price'}
                        style={{width: 170}}
                        value={unitPrice.toFixed(5)}
                        disabled
                    />
                </DialogRow>

                <DialogRow
                    style={{
                        justifyContent: 'space-between',
                        alignItems: 'baseline',
                    }}>
                    <FrameInput
                        title={'Units to ' + (add ? 'buy' : 'sell')}
                        value={units}
                        style={{width: 170}}
                        setValue={_setUnits}
                    />
                    <FrameInput
                        title={'Funds to ' + (add ? 'add' : 'subtract')}
                        value={budget}
                        style={{width: 170}}
                        disabled
                    />
                </DialogRow>
                <DialogRow>
                    <FrameText
                        title={'Notes'}
                        value={notes}
                        setValue={setNotes}
                        style={{flex: 1}}
                    />
                </DialogRow>
                <DialogRow style={{color: AppColors.RED_TEXT}}>
                    Once a new deposit is added, you will not be able to edit
                    any open positions prior to the date of the deposit.
                </DialogRow>
                <DialogRow style={{justifyContent: 'flex-end'}}>
                    {canSave ? (
                        <SendButton onClick={save}>Save</SendButton>
                    ) : (
                        <SendButtonDis>Save</SendButtonDis>
                    )}
                </DialogRow>
            </DialogContainer>
        </Dialog>
    )
}

export default AddBudget
