import React, {Component} from 'react'
import Api from '../../services/api'
import styles from './Styles'
import pos from '@images/arrow_pos.png'
import neg from '@images/arrow_neg.png'

class Ratings extends Component {
    constructor(props) {
        super(props)
        this.api = new Api()
        this.state = {
            topRatings: null,
        }
        this.sendMessage = props.sendMessage.bind(this)
    }

    // // TODO: put in AppHome and pass here..?
    // sendMessage = () => {
    //     window.ReactNativeWebView.postMessage('{"navigate":"Ratings"}')
    // }
    getTopRatings = () => {
        if (!this.state.topRatings) {
            console.log('no ratings')
            this.api.getTopRatings().subscribe(
                (response) => {
                    if (response && response.data) {
                        console.log('ratings response')
                        // not the best preformance.. need to filter separately because might have gold in first 5
                        let sample = response.data.alerts
                            .filter((item, i) => !item.is_gold)
                            .filter((item, i) => i < 5)
                        this.setState({topRatings: sample})
                    }
                },
                (error) => {
                    console.log('error', error)
                },
            )
        }
    }
    renderRatingItem = (item) => {
        const arrowImg = item.max_upside > 0 ? pos : neg
        return (
            <div style={styles.rateDiv} key={item.ticker}>
                <img style={styles.arrows} src={arrowImg} alt="" />
                <div style={styles.stock_names}>
                    <div style={styles.stock_short}>{item.ticker}</div>
                    <div style={styles.stock_full}>{item.company_name_2}</div>
                </div>
                <div
                    style={
                        item.max_upside > 0
                            ? styles.stock_change_pos
                            : styles.stock_change_neg
                    }>
                    {item.max_upside > 0 ? '+' : '-'}
                    {item.max_upside.toFixed(2)}%
                </div>
            </div>
        )
    }
    componentDidMount() {
        this.getTopRatings()
    }

    render() {
        return (
            <div>
                {/* TODO: w3_container is not here, get it from.. */}
                <div style={{...styles.headers, ...styles.w3_container}}>
                    <div style={styles.headers_font}>Top Ratings</div>
                </div>

                {this.state.topRatings ? (
                    this.state.topRatings.map((item) =>
                        this.renderRatingItem(item),
                    )
                ) : (
                    <div>Loading Ratings</div>
                )}
                {/* <table style={styles.ratingTable}>
                    <tbody>
                        {this.state.topRatings ? 
                            this.state.topRatings.map(item => this.renderRatingItem(item))
                            : <tr><td>Loading Ratings</td></tr>}
                    </tbody>
                </table> */}

                <div style={{...styles.buttons, ...{marginTop: 23}}}>
                    <div
                        onClick={() =>
                            this.sendMessage('{"navigate":"Ratings"}')
                        }
                        style={styles.buttons_font}>
                        All Top Ratings
                    </div>
                </div>
            </div>
        )
    }
}
export default Ratings
