import React from 'react'
import {useState, useEffect} from '@services/hooks'
import {api} from '@services/api'
import styled from 'styled-components'
import ScrollTable from '@components/General/ScrollTable'
import {ScrollTableStyles} from '@components/General/Elements'

const columns = [
    {
        Header: 'Month',
        accessor: 'month',
        width: 67,
        Cell: (o) =>
            new Intl.DateTimeFormat('en-US', {
                month: 'short',
                year: 'numeric',
            }).format(new Date(o.value)),
    },
    {
        Header: 'Universe',
        accessor: 'universe',
        width: 105,
        Cell: (o) => {
            return (
                <OverflowCell data-tip={o.value} data-for="table_tool_path">
                    {o.value}
                </OverflowCell>
            )
        },
    },
    {
        Header: 'Analysts',
        accessor: 'analysts',
        Cell: (o) => {
            return (
                <OverflowCell data-tip={o.value} data-for="table_tool_path">
                    {o.value}
                </OverflowCell>
            )
        },
    },
    {Header: 'Risk', accessor: 'risk', width: 79},
    {Header: 'Score', accessor: 'score', width: 43},
]
const StockScores = ({symbol}) => {
    const [historyScores, setHistoryScores] = useState([])
    useEffect(() => {
        if (symbol && historyScores.length === 0) {
            api.getStockScoresHistory(symbol).subscribe(
                (response) => {
                    console.log('historyScores', response)
                    if (response.data && response.data.length > 0) {
                        setHistoryScores(response.data)
                    }
                },
                (error) => {
                    console.error('historyScores error', error)
                },
            )
        }
    }, [symbol])
    return (
        <Container>
            {historyScores.length > 0 && (
                <>
                    <Title>Opportunity Rank History</Title>
                    <ScrollTable
                        columns={columns}
                        data={historyScores}
                        TableStyles={ScrollTableStyles}
                        maxWidth={666}
                        height={300}
                    />
                </>
            )}
        </Container>
    )
}
export default StockScores

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 35px;
    font-family: 'Inter';
`
const OverflowCell = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`
const Title = styled.div`
    font-size: 21px;
    font-weight: 700;
    padding-bottom: 15px;
`
