import React, { PropTypes } from "react";
import Toggle from "material-ui/Toggle";

const YesNoToggle = (props) => {
  let style = { display: "flex", marginTop: 5 };

  if (props.style) {
    style = Object.assign({}, style, props.style);
  }

  return (
    <div style={style}>
      <span style={{ verticalAlign: "text-bottom", display: "inline-block" }}>
        No
      </span>
      <Toggle
        {...props}
        defaultToggled={props.value || false}
        style={{
          display: "inline-block",
          width: "auto",
          marginLeft: 20,
          marginRight: 20,
        }}
      />
      <span style={{ verticalAlign: "text-bottom", display: "inline-block" }}>
        Yes
      </span>
    </div>
  );
};

// YesNoToggle.propTypes = {
//   value: PropTypes.bool,
//   style: PropTypes.object
// };

export default YesNoToggle;
