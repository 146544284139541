import React from 'react'
import {api} from '@services/api'
import {useState, useEffect} from '@services/hooks'
import {
    SendButton,
    SendButtonDis,
    SendButton2,
    Title,
    FormTopRow,
    FormRow,
} from '@components/General/Elements'
import swal from 'sweetalert2'
import styled from 'styled-components'
import FrameInput from '@components/General/FrameInput'

const CopyPortfolio = () => {
    const [sourceUser, setSourceUser] = useState('62d5086b15b95657508b4cfa')
    const [destUser, setDestUser] = useState('60f5814f15b95689248b4b94')
    const makeCopy = () => {
        if (sourceUser !== '' && destUser !== '') {
            api.copyPortfolioUser2User(sourceUser, destUser).subscribe(
                (response) => {
                    console.log('copy_file', response)
                    if (response && response.data) {
                        if (response.data.status) {
                            swal.fire({text: 'done'})
                        } else {
                            swal.fire({
                                text: response.data?.error,
                                icon: 'error',
                            })
                        }
                    } else {
                        swal.fire({text: 'unknown error', icon: 'error'})
                    }
                },
            )
        } else {
            swal.fire({text: 'missing users', icon: 'error'})
        }
    }
    return (
        <Container>
            <Row>
                <FrameInput
                    style={{flex: 1, marginRight: 30}}
                    title={'from user id'}
                    value={sourceUser}
                    setValue={setSourceUser}
                />
                <FrameInput
                    style={{flex: 1}}
                    title={'to user id'}
                    value={destUser}
                    setValue={setDestUser}
                />
            </Row>
            <Row2>
                <SendButton onClick={makeCopy}>Copy</SendButton>
            </Row2>
        </Container>
    )
}

export default CopyPortfolio

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 0px solid blue;
    font-family: 'Inter';
`
const Row = styled(FormRow)`
    border: 0px solid pink;
    width: 600px;
`
const Row2 = styled(Row)`
    margin-top: 25px;
    justify-content: flex-end;
`
