import React from 'react'
import styled from 'styled-components'
import {useState, useHistory, useUser, useDispatch} from '@services/hooks'
import {PageContent} from './Home/Home.styled'
import Header from './Header'
import ChangePassword from './Profile/ChangePassword'
import {Dimensions, AppColors} from '@services/styles'
import {AppBarBack} from './NavAppBar/NavAppBar.styles'
import AppBar from 'material-ui/AppBar'
import {Title, SendButton, SendButton2} from '@components/General/Elements'
import FrameInput from '@components/General/FrameInput'
import {api} from '@services/api'
import swal from 'sweetalert2'
import {getCurrentUser} from '@store/actions'

const Profile = () => {
    const user = useUser()
    const dispatch = useDispatch()
    const [passwordModal, setPasswordModal] = useState(false)
    const [displayName, setDisplayName] = useState(user.nickname)
    const history = useHistory()

    const changeNickname = () => {
        api.changeNicknameDirect(displayName).subscribe(
            (response) => {
                dispatch(getCurrentUser())
                swal.fire({
                    text: 'Display Name updated',
                    confirmButtonColor: AppColors.TIPIGO_GREEN,
                })
            },
            (error) => {
                swal.fire({
                    text: 'Something went wrong... Please try again.',
                    icon: 'error',
                    confirmButtonColor: AppColors.TIPIGO_GREEN,
                })
            },
        )
    }
    return (
        <Container>
            <Header />

            <AppBar
                style={{
                    height: Dimensions.APP_BAR_HEIGHT,
                    backgroundColor: AppColors.SUB_HEAD_GREY,
                    position: 'fixed',
                    zIndex: 10,
                    top: Dimensions.NAVBAR_TOP_OFFSET,
                }}
                iconStyleLeft={{marginTop: 2}}
                iconElementLeft={
                    <AppBarBack onClick={() => history.push('/')} />
                }
            />

            <ChangePassword show={passwordModal} hide={setPasswordModal} />

            <Title text="My Profile" />
            <Main>
                <Line>
                    <FrameInput
                        value={displayName}
                        setValue={setDisplayName}
                        title="Dispaly name"
                        style={{flex: 3}}
                    />
                    <SendButton2
                        onClick={changeNickname}
                        style={{
                            flex: 1,
                            marginLeft: 15,
                            alignSelf: 'flex-end',
                        }}>
                        Save
                    </SendButton2>
                </Line>
                <Line>
                    <FrameInput
                        title="Name"
                        value={user.first_name + ' ' + user.last_name}
                        setValue={null}
                        disabled
                        style={{flex: 1}}
                    />
                </Line>
                <Line style={{marginTop: 10}}>
                    <SendButton
                        onClick={() => setPasswordModal(true)}
                        style={{width: '100%'}}>
                        Change password
                    </SendButton>
                </Line>
            </Main>
        </Container>
    )
}
export default Profile

const Container = styled.div`
    margin-top: ${50 +
    Dimensions.APP_BAR_HEIGHT +
    Dimensions.NAVBAR_TOP_OFFSET}px;
    display: flex;
    flex: 1;
    font-family: 'Inter';
    align-items: center;
    border: 0px solid red;
    flex-direction: column;
`
const Main = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-top: 30px;
`
const Line = styled.div`
    font-size: 14px;
    margin-bottom: 20px;
    flex-direction: row;
    display: flex;
`
