import {React, styled} from '@common'
import {useState, useEffect} from '@services/hooks'
import {useTable, useSortBy} from 'react-table' //usePagination
import ReactTooltip from 'react-tooltip'

//const pageSizeOptions = [5, 10, 20, 50, 100]

const InsightTable = ({
    columns,
    data,
    hiddenCols = [],
    TableStyles,
    sortDefault = false,
    sortDesc = true,
}) => {
    // const [searchDataSource, setSearchDataSource] = useState([])
    // const [filters, setFilters] = useState([])
    // const [filterTypes, setFilterTypes] = useState([
    //     'Action: Long',
    //     'Action: Short',
    // ])

    // this is to keep the user sort each time state changes, otherwise will go back to "no sort"
    const [sortCol, setSortCol] = useState([{id: sortDefault, desc: sortDesc}])

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: sortCol,
            },
        },
        useSortBy,
    ) //, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setHiddenColumns,
        // allColumns,
        // getToggleHideAllColumnsProps,
        // pageOptions,
        // page,
        // state: { pageIndex, pageSize },
        // gotoPage,
        // previousPage,
        // nextPage,
        // setPageSize,
        // canPreviousPage,
        // canNextPage,
        state: {sortBy},
    } = tableInstance

    // WTF!? but i remember it had some reason in MyFile
    useEffect(() => {
        if (hiddenCols && hiddenCols.length) {
            setHiddenColumns(hiddenCols)
        }
    }, [hiddenCols])

    useEffect(() => {
        console.log('sort change?', sortBy)
        setSortCol(sortBy)
    }, [sortBy])

    // console.log('allColumns', allColumns)
    // useEffect(() => {
    //     allColumns[0].getToggleHiddenProps()
    // }, [columns])
    const Styles = TableStyles ? TableStyles : SimpleDiv

    return (
        <TableContainer>
            {/* tooltip can be used by any client that uses the table. just add data-tip={} to element
            but the control and input has to be here */}
            <ReactTooltip />
            {data && (
                // <div style={{height: 350, border: '1px solid green'}}>
                <Styles>
                    <Table {...getTableProps()} className="watchlist-table">
                        <TableHeader>
                            {
                                // Loop over the header rows
                                headerGroups.map((headerGroup) => (
                                    // Apply the header row props
                                    <HeaderRow
                                        {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            // Loop over the headers in each row
                                            headerGroup.headers.map(
                                                (column) => (
                                                    // Apply the header cell props
                                                    <HeaderCell
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps(),
                                                        )}>
                                                        {
                                                            // Render the header
                                                            column.render(
                                                                'Header',
                                                            )
                                                        }
                                                        <span>
                                                            {column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? ' 🔽'
                                                                    : ' 🔼'
                                                                : ''}
                                                        </span>
                                                    </HeaderCell>
                                                ),
                                            )
                                        }
                                    </HeaderRow>
                                ))
                            }
                        </TableHeader>
                        {/* Apply the table body props */}

                        <TableBody {...getTableBodyProps()}>
                            {
                                // Loop over the table rows
                                rows.map((row) => {
                                    // Prepare the row for display
                                    prepareRow(row)
                                    return (
                                        // Apply the row props
                                        <Row {...row.getRowProps()}>
                                            {
                                                // Loop over the rows cells
                                                row.cells.map((cell) => {
                                                    // Apply the cell props
                                                    return (
                                                        <Cell
                                                            {...cell.getCellProps()}>
                                                            {
                                                                // Render the cell contents
                                                                cell.render(
                                                                    'Cell',
                                                                )
                                                            }
                                                        </Cell>
                                                    )
                                                })
                                            }
                                        </Row>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>

                    {/* <div>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous Page
          </button>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            Next Page
          </button>
          <div>
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>
          <div>Go to page:</div>
          <input
            type="number"
            defaultValue={pageIndex + 1 || 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {pageSizeOptions.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          +{" "}
        </div> */}
                </Styles>
                // </div>
            )}
        </TableContainer>
    )
}

export default InsightTable

const SimpleDiv = styled.div``
const TableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const Table = styled.table`
    width: 100%;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.1);
`

const TableHeader = styled.thead`
    box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%);
`

const HeaderRow = styled.tr``

const HeaderCell = styled.th`
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: normal;
`

const TableBody = styled.tbody``

const Row = styled.tr`
    border: 1px solid red;
`

const Cell = styled.td``
