import React from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {api} from '@services/api'
import {useState, useEffect, useUser} from '@services/hooks'
import Dialog from '@mui/material/Dialog'
import {Close} from '@mui/icons-material'
import FrameText from '@components/General/FrameText'
import DateRange from '@components/General/DateRange'
import FrameInput from '@components/General/FrameInput'
import swal from 'sweetalert2'
import {AppColors} from '@services/styles'

import {
    Title,
    SendButton,
    SendButtonDis,
    DialogContainer,
    DialogTopRow,
    DialogRow,
} from '@components/General/Elements'

const ClosePosition = ({
    userId,
    pId,
    isOpenDialog,
    setIsOpenDialog,
    setNeedRefresh,
    pos,
    minCloseDate,
}) => {
    const [closePrice, setClosePrice] = useState('')
    const [quantity, setQuantity] = useState(0)
    const [canSave, setCanSave] = useState(false)
    const [notes, setNotes] = useState('')
    const [closeDate, setCloseDate] = useState(new Date())

    useEffect(() => {
        if (pos) {
            setClosePrice(pos.last_price)
            setQuantity(Math.abs(pos.quantity))
            setNotes(pos.notes)
        }
    }, [pos])

    useEffect(() => {
        setCanSave(quantity && closePrice && closePrice != '.')
    }, [closePrice, quantity])

    const _setQuantity = (value) => {
        value = value.trim()
        if (value == '') setQuantity('')
        else {
            value = parseInt(value)
            if (!isNaN(value) && value >= 0 && value <= Math.abs(pos.quantity))
                setQuantity(value)
        }
    }

    const _setPrice = (value) => {
        value = value.trim()
        if (value == '') setClosePrice('')
        else {
            var matched = value.match(/^(\d*)([.]\d{0,2})?$/)
            if (matched)
                setClosePrice(
                    matched[1] +
                        (matched[2] ? matched[2].replace(',', '.') : ''),
                )
        }
    }

    const closeSecurity = () => {
        console.log('...closeSecurity...', pos.id)
        const closeItem = [
            {
                id: pos.id,
                quantity: quantity,
                price: closePrice,
                notes: notes,
                date: closeDate,
            },
        ]

        api.closeUserSecurities(pId, closeItem).subscribe(
            (response) => {
                console.log('close positions', response)
                if (!response.data.status) {
                    swal.fire({
                        text: response.data.msg,
                        icon: 'error',
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                    })
                } else {
                    setNeedRefresh(true)
                    setTimeout(() => setIsOpenDialog(false), 500)
                }
            },
            (error) => console.log('close positions error', error),
        )
        // api.closeUserSecurity(
        //     pos.id,
        //     closePrice,
        //     quantity,
        //     notes,
        //     closeDate,
        // ).subscribe(
        //     (response) => {
        //         console.log('after close pos', response)
        //         if (response.data) {
        //             setNeedRefresh(true)
        //             setTimeout(() => setIsOpenDialog(false), 500)
        //         }
        //     },
        //     (error) => {
        //         console.log('after close error', error)
        //     },
        // )
    }
    return (
        <Dialog maxWidth="xs" fullWidth modal open={isOpenDialog}>
            <DialogContainer>
                <DialogTopRow>
                    <Title text={'Close ' + (pos ? pos.symbol : '')} />
                    <Close onClick={() => setIsOpenDialog(false)} />
                </DialogTopRow>
                <DialogRow style={{justifyContent: 'space-between'}}>
                    <FrameInput
                        title={'Close Price'}
                        value={closePrice}
                        setValue={_setPrice}
                        style={{width: 168}}
                    />

                    <FrameInput
                        title={'Quantity'}
                        value={quantity}
                        setValue={_setQuantity}
                        style={{width: 168}}
                    />
                </DialogRow>
                <DialogRow>
                    <DateRange
                        fromDate={closeDate}
                        toDate={new Date()}
                        setFromDate={setCloseDate}
                        dateFormat={'MM/dd/yy'}
                        style={{width: 168}} //TODO: not good.. give width to others or handle flex and all..
                        oneDate
                        oneDateTitle={'Close Date'}
                        disableWeekend
                        minDate={minCloseDate}
                    />
                </DialogRow>
                <DialogRow>
                    <FrameText
                        title={'Notes'}
                        value={notes}
                        setValue={setNotes}
                        style={{flex: 1}}
                    />
                </DialogRow>
                <DialogRow style={{justifyContent: 'flex-end'}}>
                    {canSave ? (
                        <SendButton onClick={closeSecurity}>Close</SendButton>
                    ) : (
                        <SendButtonDis>Close</SendButtonDis>
                    )}
                </DialogRow>
            </DialogContainer>
        </Dialog>
    )
}

export default ClosePosition
