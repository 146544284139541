import React from 'react'
import {useState, useEffect} from '@services/hooks'
import {AppColors} from '@services/styles'
import styled from 'styled-components'
import {ReactComponent as BirdGreen} from '@images/bird_green.svg'
import {ReactComponent as BirdRed} from '@images/bird_red.svg'
import {ReactComponent as People} from '@images/ppl.svg'
import moment from 'moment'
import ExploreBox from '../Home/LiveFeed/Explore/ExploreBox'

const AlertBox = ({rec, i, style}) => {
    const [analystExplore, setAnalystExplore] = useState(false)

    const analyst = rec.analyst ? rec.analyst : rec.analyst_name
    const description =
        rec.description ||
        `Investment opportunity by ${analyst}.<br/>Original message not available.`
    const alertTime = rec.security_created_timestamp
        ? rec.security_created_timestamp
        : rec.time
    const rate = rec.grade ? rec.grade : rec.rate ? rec.rate : 'N/A'

    const openAnalystExplore = () => {
        setAnalystExplore(true)
    }

    const recommendationMessage = (rec) => {
        return (
            <span>
                {rec.rating
                    ? rec.rating.charAt(0).toUpperCase() + rec.rating.slice(1)
                    : ''}{' '}
                recommendation from{' '}
                <AnalystName
                    team={rec.team}
                    onClick={rec.team && openAnalystExplore}>
                    {analyst}
                </AnalystName>{' '}
                with price target: {rec.price_target}
            </span>
        )
    }

    return (
        <Card key={'rec_' + i} style={style}>
            <CardLeft>
                {rec.rating === 'buy' ? <BirdGreen /> : <BirdRed />}

                <GradeBox rate={rate}>{rate}</GradeBox>
            </CardLeft>
            <CardRight>
                <CardTop>
                    <CardTitle>{recommendationMessage(rec)}</CardTitle>
                </CardTop>
                {rec.team ? (
                    <InTeam>
                        <People />
                        &nbsp;&nbsp;In analysts team
                    </InTeam>
                ) : (
                    <InTeam />
                )}
                <CardText expandable={false} style={{paddingTop: 2}}>
                    <div
                        dangerouslySetInnerHTML={{
                            // 22.11.21 remove <a> from the text. request of Nechemia
                            __html: description.replace(/<\/?a[^>]*>/g, ''),
                        }}></div>
                    <Date>
                        {' '}
                        {moment(moment.unix(alertTime)).format('MMMM D, YYYY')}
                    </Date>
                </CardText>
            </CardRight>
            <ExploreBox
                symbol={rec.ticker}
                open={analystExplore}
                analystName={analyst}
                bsRate={rate}
                onClose={() => setAnalystExplore(false)}
            />
        </Card>
    )
}

export default AlertBox

const AnalystName = styled.span`
    font-weight: ${(p) => (p.team ? 700 : 500)};
    cursor: ${(p) => (p.team ? 'pointer' : 'default')};
    text-decoration: ${(p) => (p.team ? 'underline' : 'none')};
`
const Card = styled.div`
    margin-top: 11px;
    border-radius: 7px;
    //box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    border: 1px solid ${AppColors.APP_GRAY_LIGHT};
    display: flex;
    padding: 22px;
`
const Date = styled.div`
    color: ${AppColors.APP_GRAY};
    font-size: 13px;
    line-height: 25px;
`
const CardLeft = styled.div`
    border: 0px solid blue;
`
const CardRight = styled.div`
    border: 0px solid red;
    padding-left: 30px;
`
const CardTop = styled.div``
const CardText = styled.div`
    font-size: 15px;
`
const CardTitle = styled.div`
    font-size: 17px;
    line-height: 22.8px;
`
const GradeBox = styled.div`
    background-color: ${AppColors.GRAY_BG};
    border-radius: 7px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
    font-size: ${(p) => (p.rate === 'N/A' ? 14 : 17)}px;
    color: ${AppColors.BLACK};
`
const InTeam = styled.div`
    display: flex;
    align-items: end;
    font-size: 13px;
    color: ${AppColors.BLACK};
    height: 24px;
`
