import React from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {useState} from '@services/hooks'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {ReactComponent as SvgIcon} from '../../images/calender.svg'
import {AppColors} from '@services/styles'
import {useEffect} from 'react'
import {from} from 'form-data'
import moment from 'moment'

const DateRangeNoInput = ({
    //mainColWidth,
    fromDate,
    toDate,
    setFromDate,
    setToDate,
    dateFormat,
    wholeMonth,
    //oneDate = false,
    containerStyle,
    minDate = false,
    iconColor = AppColors.BLACK,
    afterSelection,
    disableShortRange,
}) => {
    const [maxDate, setMaxDate] = useState(toDate) // use differant var coz todate will change

    // for some strange reason couldn't do the same with minDate - so pass it as param
    // const [minDate, setMinDate] = useState(useMinDate ? fromDate : null)
    const CustomInput = React.forwardRef((props, ref) => {
        return <CalLogo onClick={props.onClick} color={iconColor} />
    })

    const onChange = (dates) => {
        const [start, end] = dates
        setFromDate(start)
        setToDate(end)
        afterSelection && afterSelection()
    }

    const filterInvalid = (date) => {
        if (disableShortRange && fromDate && !toDate) {
            var days = moment(date)
                .startOf('day')
                .diff(moment(fromDate).startOf('day'), 'days')

            if (days !== 0 && days < disableShortRange) return false
        }
        return true
    }

    return (
        <Dates style={containerStyle}>
            <DatePicker
                selected={fromDate}
                dateFormat={dateFormat}
                showMonthYearPicker={wholeMonth}
                onChange={onChange}
                maxDate={maxDate}
                minDate={minDate}
                startDate={fromDate}
                endDate={toDate}
                selectsRange={true}
                filterDate={filterInvalid}
                customInput={<CustomInput />}
            />
        </Dates>
    )
}
export default DateRangeNoInput

const Dates = styled.div`
    display: flex;
    width: 30px;
    border: 0px solid green;
`
const CalLogo = styled(SvgIcon)`
    color: ${(p) => p.color};
    &:hover {
        color: ${AppColors.TIPIGO_GREEN};
    }
`
const DatePickerWrapperStyles = createGlobalStyle`
    .date_picker.full-width1 {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border: 0px solid pink;
    }
    .react-datepicker__input-container {
        width: unset;
    }
`
