import React from 'react'
import styled from 'styled-components'
import {useState, useEffect} from '@services/hooks'
import {api} from '@services/api'
import FrameSelect from '@components/General/FrameSelect'
import DateRange from '@components/General/DateRange'
import {SendButton, SendButtonDis, FormRow} from '@components/General/Elements'
import CircularProgress from 'material-ui/CircularProgress'
import ReactJson from 'react-json-view'
import moment from 'moment'
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton'

const AnalystTeamExplain = ({user}) => {
    const [analyst, setAnalyst] = useState('')
    const [anaList, setAnaList] = useState([])
    const [sector, setSector] = useState('')
    const [sectorList, setSectorList] = useState([])
    const [profile, setProfile] = useState('')
    const [profileList, setProfileList] = useState([])
    const [teamDate, setTeamDate] = useState(new Date())
    const [result, setResult] = useState(null)
    const [canSend, setCanSend] = useState(false)
    const [loading, setLaoding] = useState(false)
    //const [summery, setSummery] = useState('')
    const [useSector, setUseSector] = useState(true)

    useEffect(() => {
        setSectorList(user.system_sectors.map((s) => s.name))

        api.getAllAnalystsGlobalNames().subscribe(
            (response) => {
                if (response.data) {
                    setAnaList(response.data)
                    console.log('get analysts', response)
                } else {
                    console.log('get analysts error', response)
                }
            },
            (error) => {
                console.log('get analysts error', error)
            },
        )

        api.getProfiles().subscribe(
            (response) => {
                console.log('getProfiles ', response.data)
                setProfileList(
                    response.data.data.map((item) => {
                        const display =
                            item.first_name !== item.last_name
                                ? item.first_name + ' ' + item.last_name
                                : item.nick_name
                        return {value: item.id, label: display}
                    }),
                )
            },
            (error) => {
                console.log('getProfiles error', error)
            },
        )
    }, [])

    useEffect(() => {
        setResult(null)
        setCanSend(
            analyst && ((sector && useSector) || (profile && !useSector)),
        )
    }, [analyst, teamDate, sector, profile, useSector])

    // useEffect(() => {
    //     if (result && result.stage_5) {
    //         setSummery(result.stage_5.conclusion)
    //     } else if (result) {
    //         setSummery('not enough data. ananlyst is not in team')
    //     } else {
    //         setSummery('')
    //     }
    // }, [result])

    const getResults = () => {
        setResult(null)
        setLaoding(true)
        api.getAnalystExplain(
            moment(teamDate).format('YYYY-MM'),
            encodeURIComponent(analyst),
            useSector ? sector : '0',
            useSector ? '0' : profile,
        ).subscribe(
            (response) => {
                console.log('get explain data', response)
                setResult(response.data.ret)
                setLaoding(false)
            },
            (error) => {
                console.log('get explain data error', error)
                setResult(error)
                setLaoding(false)
            },
        )
    }
    const shouldCollapse = (field) => {
        // some starnge bug in control (undefined == true or similar)
        if (!field.name) return false
        else if (result.stage_4)
            return ['stage_1', 'stage_2', 'stage_3'].includes(field.name)
        else return false
        // else {
        //     console.log('collapse', result.stage_5)
        //     return (
        //         result.stage_5 &&
        //         ['stage_1', 'stage_2', 'stage_3'].includes(field.name)
        //     )
        // }
    }
    // useEffect(() => {
    //     console.log('anaList', anaList)
    // }, [anaList])

    return (
        <Container>
            <Row>
                {anaList.length > 0 && (
                    <FrameSelect
                        style={{flex: 3, marginRight: 25}}
                        value={analyst}
                        values={anaList}
                        setValue={setAnalyst}
                        title={'Select Analyst'}
                        searchable
                    />
                )}

                <DateRange
                    style={{marginRight: 25}}
                    fromDate={teamDate}
                    toDate={teamDate}
                    setFromDate={setTeamDate}
                    dateFormat={'MM/yyyy'}
                    wholeMonth
                    //mainColWidth={150}
                    oneDate
                    oneDateTitle={'Team month'}
                />
                <RadioButtonGroup
                    name={'bs'}
                    defaultSelected="sector"
                    onChange={(e, value) => setUseSector(value == 'sector')}
                    style={{marginRight: 25, alignSelf: 'end'}}>
                    <RadioButton label="sector" value={'sector'} />
                    <RadioButton label="profile" value={'profile'} />
                </RadioButtonGroup>

                {sectorList.length && useSector && (
                    <FrameSelect
                        style={{flex: 2, marginRight: 25}}
                        value={sector}
                        values={sectorList}
                        setValue={setSector}
                        title={'Select Sector'}
                    />
                )}
                {profileList.length && !useSector && (
                    <FrameSelect
                        style={{flex: 2, marginRight: 25}}
                        value={profile}
                        values={profileList}
                        setValue={setProfile}
                        title={'Select Profile'}
                    />
                )}
                <ButtonCont>
                    {canSend ? (
                        <SendButton onClick={getResults}>explain</SendButton>
                    ) : (
                        <SendButtonDis>explain</SendButtonDis>
                    )}
                </ButtonCont>
            </Row>
            <Row
                style={{
                    alignItems: 'center',
                    marginTop: 25,
                    flexDirection: 'column',
                }}>
                {/* {summery && (
                    <SummeryCont>
                        <Summery>Summery: {summery}</Summery>
                    </SummeryCont>
                )} */}
                {loading ? (
                    <CircularProgress />
                ) : (
                    result && (
                        <ReactJson
                            name={false}
                            src={result}
                            displayObjectSize={false}
                            displayDataTypes={false}
                            enableClipboard={false}
                            shouldCollapse={
                                shouldCollapse
                                // console.log('collapse', result.satge_5 == null)
                                // return true

                                //else return !result.satge_5
                                // return (
                                //     result &&
                                //     result.satge_5 &&
                                //     [
                                //         'stage_1',
                                //         'stage_2',
                                //         'stage_3',
                                //     ].includes(f.name)
                                // )
                            }
                        />
                    )
                )}
            </Row>
        </Container>
    )
}

export default AnalystTeamExplain

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 0px solid red;
    font-family: 'Inter';
`
const Row = styled(FormRow)`
    align-items: center;
    flex-direction: row;
`
const SummeryCont = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    margin-bottom: 15px;
`
const Summery = styled.div`
    font-size: 16px;
    font-weight: 600;
`
const Results = styled.div``
const ButtonCont = styled.div`
    display: flex;
    align-self: flex-end;
`
