// import React from 'react'
import {useState, useEffect} from '@services/hooks'
import styled from 'styled-components'
import moment from 'moment'
import {getDaysDiff} from '@services/utils'
import {AppColors} from '@services/styles'

const InlineNotification = ({pos, monitorSettings, lastMonthNoti}) => {
    const [tipColor, setTipColor] = useState('')
    const [tipText, setTipText] = useState('')
    const [tipCount, setTipCount] = useState(0)

    const isSetting = (set, settings) => {
        if (settings && settings.find((s) => s.type == set)) {
            const thisSet = settings.filter((s) => s.type == set)[0]
            return thisSet.value ? thisSet.value : true
        } else return false
    }
    const addToTip = (tip, text) => {
        var newTip = tip ? tip + '<br/>' : ''
        return newTip + text
    }
    useEffect(() => {
        if (pos && monitorSettings) buildNotiData()
    }, [pos, monitorSettings, lastMonthNoti])

    const buildNotiData = () => {
        // buy is not in table for now. there are too many
        const eventTypes = ['sell', 'refresh', 'split']
        const eventNotifications = lastMonthNoti?.length
            ? lastMonthNoti.filter(
                  (noti) =>
                      noti.symbol === pos.row.original.symbol &&
                      eventTypes.includes(noti.rating),
              )
            : []
        console.log('tickerNotifications')
        const markEarning = isSetting('earning', monitorSettings)
        const markMa50 = isSetting('ma50', monitorSettings)
        const markSl = isSetting('sl', monitorSettings)
        var markEtf = isSetting('etf', monitorSettings)
        var markProfit = isSetting('profit', monitorSettings)
        if (markEtf === true) markEtf = 10
        if (markProfit === true) markProfit = 12
        const {
            etf_pl_per,
            pl_per,
            ma50,
            last_price: last,
            earning,
            pos_type,
        } = pos.row.original
        const {days, words} = getDaysDiff(earning)

        const colors = []
        var tip = ''
        // pl_per is already reversed for short
        // order by color green -> yellow -> red, last override previous
        if (markProfit && pl_per && pl_per > markProfit) {
            colors.push(AppColors.TIPIGO_GREEN)
            // if (pos_type === 'Long') {
            tip = addToTip(tip, 'Reached the defined Profit settings')
            // } else {
            //     tip = addToTip(tip, 'Reached the defined Profit settings')
            // }
        }
        if (markEarning && days && days < 15 && days > -2) {
            colors.push(AppColors.YELLOW_TEXT)
            tip = addToTip(
                tip,
                'Earning date ' +
                    moment(pos.row.original.earning).format('MMMM D, YYYY'),
            )
        }
        if (markEtf && etf_pl_per && pl_per && etf_pl_per >= pl_per + markEtf) {
            colors.push(AppColors.TIPIGO_RED)
            if (pos_type === 'Long') {
                tip = addToTip(tip, 'Under the Benchmark etf')
            } else {
                tip = addToTip(
                    tip,
                    'Over the Benchmark etf (for short position)',
                )
            }
        }
        if (markMa50 && ma50 && ma50 != '' && last) {
            if (ma50 > last && pos_type === 'Long') {
                tip = addToTip(tip, 'Under the Moving Average 50')
                colors.push(AppColors.TIPIGO_RED)
            } else if (ma50 < last && pos_type === 'Sell') {
                tip = addToTip(
                    tip,
                    'Over the Moving Average 50 (for short position)',
                )
                colors.push(AppColors.TIPIGO_RED)
            }
        }
        if (markSl && pl_per && pl_per * -1 > markSl) {
            colors.push(AppColors.TIPIGO_RED)
            if (pos_type === 'Long') {
                tip = addToTip(tip, 'Under the defined stoploss')
            } else {
                tip = addToTip(
                    tip,
                    'Over the defined stoploss (for short position)',
                )
            }
        }
        if (eventNotifications.some((noti) => noti.rating === 'sell')) {
            tip = addToTip(tip, 'New Sell rating for symbol')
            if (pos_type === 'Long') {
                colors.push(AppColors.TIPIGO_RED)
            } else {
                colors.push(AppColors.TIPIGO_GREEN)
            }
        }
        if (eventNotifications.some((noti) => noti.rating === 'split')) {
            tip = addToTip(tip, 'New Split/Dividend event for symbol')
            colors.push(AppColors.APP_GRAY_LIGHT)
        }
        if (eventNotifications.some((noti) => noti.rating === 'refresh')) {
            tip = addToTip(tip, 'Over the defined refresh time')
            colors.push(AppColors.APP_GRAY_LIGHT)
        }

        if (tip) {
            setTipText(tip)
            setTipCount(colors.length)
            if (colors.length) {
                if (colors.includes(AppColors.TIPIGO_RED))
                    setTipColor(AppColors.TIPIGO_RED)
                else if (colors.includes(AppColors.YELLOW_TEXT))
                    setTipColor(AppColors.YELLOW_TEXT)
                else if (colors.includes(AppColors.APP_GRAY_LIGHT))
                    setTipColor(AppColors.APP_GRAY)
                else if (colors.includes(AppColors.TIPIGO_GREEN))
                    setTipColor(AppColors.TIPIGO_GREEN)
            }
        }
    }

    return (
        <>
            {tipColor && tipText && tipCount && (
                <Circle
                    data-tip={tipText}
                    data-for="table_tool_path"
                    color={tipColor}>
                    {tipCount}
                </Circle>
            )}
        </>
    )
}
export default InlineNotification

const Circle = styled.div`
    border: 2.4px solid ${(p) => p.color};
    background-color: ${(p) => p.color}70;
    color: ${(p) => p.color};
    height: 20px;
    border-radius: 50%;
    width: 20px;
    display: flex:
    align-items: center;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`
