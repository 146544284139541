import React, {useCallback} from 'react'
import styled from 'styled-components'

import ScrollTable from '@components/General/ScrollTable'
import {
    ScrollTableStyles,
    ColoredCell,
    SymbolInTable,
    Checkbox,
    EarningDays,
} from '@components/General/Elements'
import {useState, useEffect, useRef} from '@services/hooks'
import {AppColors} from '@services/styles'
import CircularProgress from 'material-ui/CircularProgress'
import {Link} from 'react-router-dom'
import ScoreParts from './ScoreParts'
import moment from 'moment'
import {ReactComponent as InFile} from '@images/in_file.svg'
import {getDaysDiff, sectorShortName} from '@services/utils'

const OppScannerList = ({
    data,
    listSelected,
    setListSelected,
    done,
    tableWidth,
    sector,
}) => {
    const [selAll, setSelAll] = useState(false)
    const [list, setList] = useState([])
    const [dots, setDots] = useState(5)
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const [selectdRow, setSelectedRow] = useState(null)
    const dotsRef = useRef(1)
    const msgRef = useRef(0)
    const [progressText, setProgressText] = useState('Fetching symbol data')
    const [hideCols, setHideCols] = useState([])
    // control uses width even if not supplied, so better to use for control
    // TODO: but what sets the overall width of table, find out. find how to flex 1 or 100%.
    // it doesn't take full width even if there is space. setting 160 default somewhere
    // so need to set width to all cols if we want to control it

    const runningMsgs = [
        'Fetching symbol data',
        'Fetching analyst data',
        'Updating market data',
        'Building an analyst team',
        'Applying filters',
        'Calculating results',
    ]
    useEffect(() => {
        if (list.length === 0 && !done) {
            const interval = setInterval(
                () => {
                    //console.log('length ', runningMsgs.length, msgRef.current)
                    if (msgRef.current === runningMsgs.length) {
                        msgRef.current = 0
                    }
                    setProgressText(runningMsgs[msgRef.current])
                    msgRef.current = msgRef.current + 1
                    //console.log('This will run every second! ', msgRef.current)
                },
                (Math.random() + 1) * 1000,
            )

            const interval2 = setInterval(() => {
                setDots(5 * dotsRef.current)
                dotsRef.current = dotsRef.current + 1
                if (dotsRef.current == 4) dotsRef.current = 1
            }, 350)
            return () => {
                clearInterval(interval)
                clearInterval(interval2)
            }
        }
    }, [list])

    // TODO: consider usnig the sector complex object from parent instread of local list
    // will have to update entire obj for each check
    // give width to small ones, table will use remaining width for cols with no width
    const columns = [
        {
            accessor: 'select',
            Header: (o) => (
                <Checkbox
                    onChange={(e) => selectAll(e.target.checked)}
                    checked={selAll}
                />
            ),
            disableSortBy: true,
            width: 0.045 * tableWidth,
            Cell: (o) => (
                <Checkbox
                    checked={o.row.original.selected}
                    onChange={(e) => {
                        selectItem(o.row.original.id, e.target.checked)
                    }}
                />
            ),
        },
        {
            accessor: 'ticker',
            Header: 'Symbol',
            width: 0.0563 * tableWidth,
            Cell: (o) => (
                <Link
                    to={'/stock/' + o.value}
                    style={{textDecoration: 'none'}}
                    target="_blank">
                    <SymbolInTable>{o.value}</SymbolInTable>
                </Link>
            ),
        },
        {
            accessor: 'company',
            Header: 'Company',
            Cell: (o) => (
                <NowrapCell data-tip={o.value} data-for="table_tool_path">
                    {o.value}
                </NowrapCell>
            ),
        },
        {
            accessor: 'raw_sector',
            Header: (o) => <div style={{paddingLeft: 12}}>Sector</div>,
            Cell: (o) => <div style={{paddingLeft: 12}}>{o.value}</div>,
        },
        {
            accessor: 'industry',
            Header: 'Industry',
            Cell: (o) => (
                <NowrapCell data-tip={o.value} data-for="table_tool_path">
                    {o.value}
                </NowrapCell>
            ),
        },
        {
            accessor: 'market_cap',
            Header: 'Market Cap',
            Cell: (o) =>
                o.value.toLocaleString('en-US', {
                    // style: 'currency', // can use that, will $1.2T. i think too much
                    // currency: 'USD',
                    notation: 'compact',
                    maximumFractionDigits: 1,
                }),
            width: 0.071 * tableWidth,
        },
        {
            accessor: 'earning_date',
            Header: 'Earning',
            width: 0.071 * tableWidth,
            Cell: (o) => {
                const {days, words} = getDaysDiff(o.value)
                return (
                    <div>
                        <EarningDays
                            days={days}
                            data-tip={moment(o.value).format('MMMM D, YYYY')}
                            data-for="table_tool_path">
                            {words}
                        </EarningDays>
                    </div>
                )
            },
        },
        {
            accessor: 'analysts',
            Header: 'Analysts',
            Cell: (o) => {
                const count = (o.value.match(/[,]/g) || []).length + 1
                return (
                    <NowrapCell data-tip={o.value} data-for="table_tool_path">
                        {`${count} Analysts`}
                    </NowrapCell>
                )
            },
            // width: 0.318 * tableWidth,
        },
        {
            accessor: 'last_price',
            Header: (o) => <div style={{paddingLeft: 12}}>Last Price</div>,
            Cell: (o) => <div style={{paddingLeft: 12}}>{o.value}</div>,
            width: 0.09 * tableWidth,
        },
        {
            accessor: 'upside',
            Header: (o) => (
                <div
                    data-tip={'Last price to Target price'}
                    data-for="table_tool_path">
                    Upside
                </div>
            ),
            Cell: (o) => (
                <ColoredCell
                    val={o.value}
                    data-tip="Analyst Consensus"
                    data-for="table_tool_path">
                    {(o.value * 100).toFixed(2)}%
                </ColoredCell>
            ),
            sortType: 'number',
            width: 0.073 * tableWidth,
        },
        {accessor: 'riskWord', Header: 'Risk'},
        {
            accessor: 'total score',
            Header: 'Rank',
            Cell: (o) => (
                <div
                    style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }}
                    onClick={() => openScoreDialog(o.row.original)}>
                    {o.value}
                </div>
            ),
            width: 0.05 * tableWidth,
        },

        {
            accessor: 'in_file',
            width: 0.035 * tableWidth,
            Cell: (o) =>
                o.value ? (
                    <InFile
                        data-tip="In Portfolio"
                        data-for="table_tool_path"
                    />
                ) : null,
        },
    ]

    useEffect(() => {
        // remove and add all from THIS list but keep all else (there are multiple lists like this one)
        // this approach assums that a ticker cannot be on 2 lists (but it can for 'All')
        // or TODO: add 'selected' array to sector list and update object. above logic cannot cover cases when tic is in 2 lists

        // const ticks = list.map((item) => item.ticker)
        // const allButMe = listSelected.filter((item) => !ticks.includes(item))
        const selectedHere = list
            .filter((item) => item.selected)
            .map((item) => {
                return item.ticker
            })
        // setListSelected([...allButMe, ...selectedHere])
        setListSelected({...listSelected, [sector]: selectedHere})

        const hidden = []
        if (!list.some((a) => a.in_file)) hidden.push('in_file')

        setHideCols(hidden)
    }, [list])

    const selectAll = (checked) => {
        setSelAll(checked) // can move the rest to useEffect if we need more sel/un-sel functionality
        setList((current) =>
            current.map((obj) => {
                return {
                    ...obj,
                    selected: checked,
                }
            }),
        )
    }

    const selectItem = (id, checked) => {
        console.log('set selected', id, list.filter((o) => o.id == id).length)

        setList((current) =>
            current.map((obj) => {
                if (obj.id === id) {
                    return {
                        ...obj,
                        selected: checked,
                    }
                }
                return obj
            }),
        )
    }

    const getShortSectorName = useCallback((sectorName) => {
        return sectorShortName(sectorName)
    }, [])

    const convertOpportunity = useCallback((item) => {
        // TODO: reorgenize all once we move to new system
        if (!item.totalScore) {
            return {...item}
        }
        const {
            ticker,
            company,
            rawSector,
            marketCap,
            earningDate,
            analysts,
            lastPrice,
            upside,
            totalScore,
            in_file,
            normalizedScores,
            industry,
        } = item
        return {
            ticker,
            company,
            raw_sector: getShortSectorName(rawSector),
            market_cap: marketCap,
            earning_date: earningDate,
            analysts,
            last_price: lastPrice,
            upside: upside / 100,
            'total score': totalScore,
            in_file,
            // num_rec_score_disp: normalizedScores?.tickerRecommendationsCount,
            risk_disp: normalizedScores?.risk,
            market_score_disp: normalizedScores?.marketScore,
            fundamental_score: normalizedScores?.fundumentalScore,
            algorithm_score_disp: normalizedScores?.analystTeamScore,
            riskWord: normalizedScores?.riskWord,
            industry,
        }
    }, [])

    useEffect(() => {
        if (data) {
            const convertedData = data.map(convertOpportunity)
            setList(
                convertedData.map((item, i) => {
                    return {
                        ...item,
                        selected: false,
                        id: i,
                    }
                }),
            )
        }
    }, [data])

    const openScoreDialog = (row) => {
        console.log(row)
        setSelectedRow(row)
        setIsOpenDialog(true)
    }

    return (
        <Container>
            {/* {stage == 'results' && ( 
                when it was one list - but now we want to show each list when it's ready */}
            {list.length > 0 && done && (
                <ScrollTable
                    columns={columns.filter(
                        (col) =>
                            col.accessor !== 'raw_sector' || sector === 'All',
                    )}
                    data={list && list.length ? list : []}
                    hiddenCols={hideCols}
                    TableStyles={ScrollTableStyles}
                    sortDefault={'total score'}
                    maxWidth={tableWidth}
                    // TODO: how to deal with height? as there are n tables and each one can be scrolled
                    // height={window.innerHeight - 380}
                />
            )}
            {list.length === 0 && done && (
                <div style={{marginLeft: 6}}>
                    No Opportunities found for sector under current filters.
                </div>
            )}
            {list.length === 0 && !done && (
                <ProgressRow>
                    <CircularProgress color={AppColors.TIPIGO_GREEN} />
                    <ProgressMsg>
                        {progressText}
                        <div style={{paddingLeft: dots}}>.</div>
                    </ProgressMsg>
                </ProgressRow>
            )}
            <ScoreParts
                isOpenDialog={isOpenDialog}
                setIsOpenDialog={setIsOpenDialog}
                data={selectdRow}
            />
        </Container>
    )
}
export default OppScannerList

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 0px solid;
    align-items: flex-start;
`
const ProgressRow = styled.div`
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
    border: 0px solid green;
    flex-direction: column;
    flex: 1;
    display: flex;
    margin-bottom: 25px;
`
const ProgressMsg = styled.div`
    margin-top: 18px;
    font-size: 15px;
    display: flex;
    width: 200px;
    border: 0px solid red;
    color: ${AppColors.BLACK};
`
const NowrapCell = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`
