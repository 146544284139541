import styled from 'styled-components'
import {ReactComponent as ChevOpen} from '@images/chevron_open.svg'
import {ReactComponent as ChevClose} from '@images/chevron_close.svg'
import {ReactComponent as HelpBlack} from '@images/help.svg'
import {useUser} from '@services/hooks'

import {AppColors} from '@services/styles'
//import ReactTooltip from 'react-tooltip'
import {HelpOutline} from '@mui/icons-material'
import Checkbox from '@mui/material/Checkbox'
import oldCheckbox from 'material-ui/Checkbox'

import {Dimensions} from '@services/styles'
import {AddCircleOutline} from '@mui/icons-material'
import {on} from 'form-data'

const SendButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({disabled}) =>
        disabled ? '#bbbbbb' : AppColors.TIPIGO_GREEN};
    border: 0px solid ${AppColors.TIPIGO_GREEN};
    border-radius: 8px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    //line-height: 23px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
    font-family: Inter; // need to set beacuse overriden by user agent stylesheet
    height: 51px;
`
const SendButtonDis = styled(SendButton)`
    background: #bbbbbb;
    cursor: default;
`
const SendButton2 = styled(SendButton)`
    background: unset;
    border: 1.5px solid ${AppColors.TIPIGO_GREEN};
    color: ${AppColors.TIPIGO_GREEN};
`
const SendButton2Dis = styled(SendButton)`
    background: unset;
    border: 1.5px solid #bbbbbb;
    color: #bbbbbb;
    cursor: default;
`
const TitleContainer = styled.div`
    font-size: 29px;
    font-weight: 600;
    color: ${AppColors.BLACK};
    margin-bottom: 16px;
    display: flex;
    cursor: ${(p) => (p.fold ? 'pointer' : 'default')};
`
const ChevronClose = styled(ChevClose)`
    margin-left: 11px;
    width: 21px;
    height: 21px;
`
const ChevronOpen = styled(ChevOpen)`
    margin-left: 11px;
    width: 21px;
    height: 21px;
`
const Help = styled(HelpOutline)`
    margin-left: 7px;
    align-self
`
const DialogContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 38px;
    font-family: 'Inter';
`
const DialogTopRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const DialogRow = styled.div`
    padding-top: 19px;
    display: flex;
    flex: 1;
`
const FormTopRow = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
`
const FormRow = styled.div`
    display: flex;
    flex: 1;
    border: 0px solid green;
    justify-content: space-between;
`
const TopBlock = styled.div`
    border: 1px solid ${AppColors.APP_GRAY_LIGHT};
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 18px;
    padding-right: 15px;
    padding-top: 14px;
    padding-bottom: 14px;
    justify-content: flex-start;
`
const IconButton = styled.div`
    background-color: white;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.16);
    cursor: pointer;
`
// #scroll: class names should match the ones given in ScrollTable
// that control creates everything in divs

// TODO: not done, trying to make table 100%
// width: 100%;
// display: block;
// border: 1px solid grey;

// .tableWrap {
//     display: block;
//     max-width: 100%;
//     overflow-x: scroll;
//     overflow-y: hidden;
//     border-bottom: 1px solid black;
// }
const ScrollTableStyles = styled.div`
    .table {
        font-family: 'Inter';
        border-spacing: 0 8px;
        align-items: center;

        .tr {
            box-shadow: 0px 0px 6px rgb(56 66 73 / 10%);
            border-radius: 4px;
            height: 40px;
            background: #ffffff;
            margin-top: 8px;
        }

        .header {
            .tr {
                background: #e9eaec;
                height: 45px;
                margin-top: 0px;
                .th {
                    //text-align: center; //23.02.23 align left and add padding. Noam request
                    margin-left: 10px;
                    font-size: 12px;
                    border-right: 0px;
                    align-self: center;
                    top: 0px;
                }
            }
        }
        .td {
            //text-align: center;
            margin-left: 10px;
            font-size: 13px;
            line-height: 20px;
            align-self: center;
        }
    }
`
const SymbolInTable = styled.div`
    font-size: 13px;
    font-weight: 700;
    color: ${AppColors.BLACK};
    &:hover {
        text-decoration: underline;
    }
`
const ColoredCell = styled.div`
    color: ${(p) => (p.val > 0 ? AppColors.TIPIGO_GREEN : AppColors.RED_TEXT)};
`
const Title = ({text, onClick, fold, isOpen, toolTip, style}) => {
    return (
        <TitleContainer
            onClick={onClick}
            fold={fold}
            data-tip={toolTip}
            style={style}>
            {text}
            {toolTip && <Help sx={{fontSize: 14}} color="primary" />}
            {fold && (isOpen ? <ChevronClose /> : <ChevronOpen />)}
        </TitleContainer>
    )
}
const StyledCheckBox = styled(oldCheckbox)`
    svg {
        width: 22px !important;
        height: 22px !important;
    }
    input ~ div {
        align-items: center;
    }
`
const Checkbox1 = ({checked, onChange, disabled}) => (
    <Checkbox
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        sx={{
            color: AppColors.APP_GRAY_LIGHT,
            '&.Mui-checked': {
                color: AppColors.TIPIGO_GREEN,
            },
            //border: 1, NOT

            // this changes the size AND border width - nor exactly what we need
            // '& .MuiSvgIcon-root': {
            //     fontSize: '28px',
            // },
            height: 14,
        }}
    />
)
const HelpLink = ({linkSuffix}) => {
    const user = useUser()
    return (
        <div
            style={{
                top:
                    Dimensions.NAVBAR_TOP_OFFSET +
                    Dimensions.APP_BAR_HEIGHT +
                    25,
                right: 25,
                position: 'fixed',
            }}>
            <a
                href={
                    'https://app.tipigo.com/insight/help/?id=' +
                    user?.user_id +
                    (linkSuffix ? linkSuffix : '')
                }
                target={'_blank'}>
                <HelpBlack />
            </a>
        </div>
    )
}
const EarningDays = styled.div`
    background-color: ${(p) =>
        p.days !== '' && p.days !== false && p.days < 15 ? '#ffe9e9' : 'unset'};
    padding-top: 2px;
    padding-right: 4px;
    padding-left: 4px;
    border-radius: 4px;
    display: inline-block;
`

const AddBenchmarkButton = ({style, onClick}) => {
    return (
        <AddBC style={style} onClick={onClick}>
            <AddCircleOutline />
            &nbsp;&nbsp;Add Benchmarks
        </AddBC>
    )
}

const AddBC = styled.div`
    width: 160px;
    padding-left: 10px;
    justify-content: flex-start;
    background-color: white;
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    font-size: 13px;
`
export {
    SendButton,
    Title,
    ChevronClose,
    ChevronOpen,
    SendButtonDis,
    DialogContainer,
    DialogTopRow,
    DialogRow,
    ScrollTableStyles,
    SymbolInTable,
    ColoredCell,
    FormTopRow,
    FormRow,
    SendButton2,
    SendButton2Dis,
    TopBlock,
    Checkbox1 as Checkbox,
    HelpLink,
    StyledCheckBox,
    IconButton,
    EarningDays,
    AddBenchmarkButton,
}
