import React, {Component, PropTypes} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import IconButton from 'material-ui/IconButton'
import CircularProgress from 'material-ui/CircularProgress'

import BaseManageTable from './BaseManageTable'

import DataService from '@services/data'

import {
    red400,
    green400,
    grey400,
    grey200,
    grey50,
} from 'material-ui/styles/colors'

import moment from 'moment'
// import Sparkline from "react-sparkline";

import ReactTable from 'react-table'
import Pagination from '../General/Pagination'

import Api from '@services/api'
import {formatDailyChange} from '@services/utils'
import * as AppActions from '@store/actions/app-actions'

class UsersTable extends Component {
    constructor(props) {
        super(props)

        this.api = new Api()

        this.state = {}

        this.applySearchFilter = this.applySearchFilter.bind(this)
    }

    componentWillMount() {
        this.props.fetchData()
    }

    getColumns() {
        let idObj = {
            accessor: 'id',
            Header: 'ID',
            sortable: false,
            Cell: (row) => <div style={{fontSize: 7}}>{row.row.values.id}</div>,
        }

        let emailObj = {
            accessor: 'email',
            Header: 'Email',
            Cell: (o) =>
                o.value.length
                    ? o.value.length < 30
                        ? o.value
                        : o.value.substring(0, 28) + '...'
                    : '',
            //style: {'white-space': 'unset'},
            sortable: true,
        }
        let nicknameObj = {
            accessor: (row) =>
                row.nick_name
                    ? row.nick_name
                    : (row.first_name ? row.first_name : '') +
                      ' ' +
                      (row.last_name ? row.last_name : ''),
            Header: 'Name',
            sortable: true,
        }

        let accountObj = {
            accessor: 'account',
            Header: 'Account',
            //maxWidth: 50,
            sortMethod: (a, b) => {
                const v1 = a.name
                const v2 = b.name

                if (v1 < v2) {
                    return -1
                } else if (v1 > v2) {
                    return 1
                } else {
                    return 0
                }
            },
            Cell: (row) => (
                <div style={{textAlign: 'center'}}>
                    {row.value ? row.value.name : null}
                </div>
            ),
        }
        let lastLogin = {
            accessor: 'last_activity',
            Header: 'last login',
            Cell: (o) => moment(o.value.date).format('MM/DD/YY'),
            sortType: (a, b, id) => {
                return a.values[id].date > b.values[id].date ? 1 : -1
            },
        }

        let actionsObj = {
            accessor: 'id_',
            sortable: false,
            Cell: (row) => (
                <div style={{textAlign: 'center', textWrap: 'wrap'}}>
                    {/* TODO: sure this is the wrong way... row.row.values.id */}
                    <Link to={'/manage/user/' + row.row.values.id}>
                        <IconButton
                            style={{height: 25}}
                            iconStyle={{
                                color: grey400,
                                fontSize: 15,
                            }}
                            onClick={() => {
                                console.log('edit', row.row.values.id)
                            }}
                            iconClassName="material-icons">
                            edit
                        </IconButton>
                    </Link>
                </div>
            ),
        }

        return [idObj, emailObj, nicknameObj, accountObj, lastLogin, actionsObj]
    }

    applySearchFilter(searchText) {
        let {data} = this.props

        return data.filter(
            (d) =>
                d.id.indexOf(searchText) !== -1 ||
                d.email.toLowerCase().indexOf(searchText.toLowerCase()) !==
                    -1 ||
                (d.account.name &&
                    d.account.name
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) !== -1),
        )
    }

    render() {
        let {data} = this.props

        return (
            <BaseManageTable
                mode="user"
                columns={this.getColumns()}
                searchFilter={this.applySearchFilter}
                data={data}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.manage.data.users,
    }
}

const mapDispatchToProps = (dispatch) => {
    const ds = new DataService(dispatch)

    return {
        fetchData: () => ds.getUsers(),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable)
