import {React, styled} from '@common'
import Select from 'react-select'
import SearchIcon from '@mui/icons-material/Search'
import {AppColors} from '@services/styles'

const BGColor = AppColors.SUB_HEAD_GREY
const STYLE = {
    control: (base) => ({
        ...base,
        border: 0,
        // disable the blue border
        boxShadow: 'none',
        backgroundColor: BGColor,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}),
    input: (base) => ({...base, backgroundColor: BGColor, color: 'white'}),
}

const SearchBar = ({dataSource, onInputChange, onChange}) => {
    console.log('data source11', dataSource)

    return (
        <Container>
            <StyledSelect
                menuPortalTarget={document.body}
                styles={STYLE}
                placeholder="Search"
                onInputChange={onInputChange}
                options={dataSource.map((ds) => ({
                    value: ds.value,
                    label: ds.text,
                }))}
                onChange={onChange}
            />
            <FloatingSearchIcon>
                <SearchIcon />
            </FloatingSearchIcon>
        </Container>
    )
}

export default SearchBar

const Container = styled.div`
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 50px;
    background-color: ${BGColor};
    justify-content: center;
    width: 360px;
    position: relative;
`

const StyledSelect = styled(Select)`
    width: 340px;
    border: 0px;
    box-shadow: none;
    font-size: 16px;
`

const FloatingSearchIcon = styled.div`
    position: absolute;
    right: 5px;
    width: 60px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${BGColor};
    border-radius: 20px;
    border: 0px solid pink;
`
