import React, {Component, PropTypes} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import * as ApiActions from '@store/actions/api-actions'
import * as AppActions from '@store/actions/app-actions'

import AppBar from 'material-ui/AppBar'
import FlatButton from 'material-ui/FlatButton'
import IconButton from 'material-ui/IconButton'
import RaisedButton from 'material-ui/RaisedButton'
import Popover from 'material-ui/Popover'
import Menu from 'material-ui/Menu'
import MenuItem from 'material-ui/MenuItem'
import FontIcon from 'material-ui/FontIcon'
import CircularProgress from 'material-ui/CircularProgress'
import {Tabs, Tab} from 'material-ui/Tabs'

import Header from './Header'

//import {getUrlParams} from '@services/utils'

import DataService from '@services/data'

// import Analytics from '@services/analytics'
import ManageDrawer from './Manage/ManageDrawer'
import AccountsTable from './Manage/AccountsTable'
import UsersTable from './Manage/UsersTable'
import ProfilesTable from './Manage/ProfilesTable'
import AnalystsTable from './Manage/AnalystsTable'
import EditAccount from './Manage/EditAccount'
import EditUser from './Manage/EditUser'
import EditAnalyst from './Manage/EditAnalyst'

import * as Colors from 'material-ui/styles/colors'
import {Dimensions, AppColors} from '@services/styles'

const FINANCE_QUERY_INTERVAL = 10000 // In milliseconds

const styles = {
    customWidth: {
        width: 150,
    },
    headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
    },
    tabButtonStyle: {
        color: Colors.blue900,
    },
}

class Manage extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            profileSelectorOpen: false,
            drawerOpen: true,
            mode: null, //,
            //activeReport: reports.find((r) => r.selected)
        }
    }

    componentWillMount() {
        const {user} = this.props

        if (!user || !user.session_token || !user.is_admin) {
            console.warn(
                'Missing user or session token, redirecting back to login',
            )
            // TODO: back to login or something
            return
        }
    }

    componentDidMount() {
        const {match} = this.props

        //this.fetchData()
        this.setState({mode: match.params.mode})
    }

    componentWillUnmount() {
        // if (this.pollInterval) {
        //   window.clearInterval(this.pollInterval)
        //   this.pollInterval = null
        // }
        // TODO
    }

    componentWillReceiveProps(nextProps) {
        const {user, match} = nextProps
        let {mode} = this.state

        console.log('mode', match.params.mode)

        if (mode !== match.params.mode) {
            this.setState({mode: match.params.mode})

            //this.fetchData()
        }

        // TODO
    }

    handleActiveReport = () => {
        console.log('active report', this.state.activeReport)
    }

    // poll(restart = false) {

    //   let self = this

    //   if (this.pollInterval) {
    //     window.clearInterval(this.pollInterval)
    //     this.pollInterval = null
    //   }

    //   if (restart === true && self.props) {
    //     console.log("(FORCED): Polling financial data...")
    //     // Fire an API call now, and set the interval later
    //     const { user, initialized, dataLoading } = self.props
    //     if (user && user.session_token && initialized && !dataLoading) {
    //       // We have a user (i.e. we're logged in)
    //       // Fetch silently and only get finance info (exclude portfolio)
    //       self.props.getFinancialData(user.session_token, true, true)
    //     }
    //   }

    //   if (this.pollInterval === null) {
    //     this.pollInterval = setInterval(() => {
    //       const { user, initialized, dataLoading } = self.props
    //       if (user && user.session_token && initialized && !dataLoading) {
    //         // We have a user (i.e. we're logged in)
    //         // Fetch silently and only get finance info (exclude portfolio)
    //         self.props.getFinancialData(user.session_token, true, true)
    //       }
    //     }, FINANCE_QUERY_INTERVAL)
    //   }
    // }

    handleDrawerToggle = () => {
        this.setState({drawerOpen: !this.state.drawerOpen})
    }

    handleSelectReport = (report) => {
        let reports = this.state.reports
        reports.forEach((r) => (r.selected = r.name === report.name))

        let activeReport = reports.find((r) => r.selected)
        this.setState({reports: reports, activeReport: activeReport})

        console.log('reports', reports, activeReport)
    }

    // handleSelectedReport = (reports) => {
    //   this.setState
    // }

    // handleTouchTap = (event) => {
    //   // This prevents ghost click.
    //   event.preventDefault();

    //   this.setState({
    //     profileSelectorOpen: true,
    //     anchorEl: event.currentTarget
    //   });
    // };

    // handleRequestClose = () => {
    //   this.setState({
    //     profileSelectorOpen: false
    //   });
    // };

    renderAppBar = () => {
        return (
            <AppBar
                style={{
                    height: Dimensions.APP_BAR_HEIGHT,
                    backgroundColor: AppColors.SUB_HEAD_GREY,
                    position: 'fixed',
                    zIndex: 10,
                    top: Dimensions.NAVBAR_TOP_OFFSET,
                }}
                iconStyleLeft={{marginTop: 2}}
                iconElementLeft={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: 0,
                        }}>
                        {/* <IconButton
                            style={{marginRight: 5}}
                            onClick={this.handleDrawerToggle}
                            iconStyle={{color: '#fff'}}>
                            {this.state.drawerOpen ? (
                                <FontIcon className="material-icons">
                                    close
                                </FontIcon>
                            ) : (
                                <FontIcon className="material-icons">
                                    menu
                                </FontIcon>
                            )}
                        </IconButton> */}
                    </div>
                }
            />
        )
    }

    render() {
        console.log('manage props', this.props)

        let {match} = this.props

        return (
            <div>
                <Header />

                {this.renderAppBar()}

                <ManageDrawer
                    open={this.state.drawerOpen}
                    mode={match.params.mode}
                    loading={false}
                    width={Dimensions.DRAWER_WIDTH}
                    handleDrawerToggle={this.handleDrawerToggle.bind(this)}
                />

                <div
                    style={{
                        padding: Dimensions.CONTENT_BASE_PADDING,
                        marginTop: 30 + Dimensions.APP_BAR_HEIGHT,
                        marginLeft: this.state.drawerOpen
                            ? Dimensions.DRAWER_WIDTH
                            : 0,
                    }}>
                    {match.params.mode === 'accounts' && <AccountsTable />}
                    {match.params.mode === 'users' && <UsersTable />}
                    {match.params.mode === 'profiles' && <ProfilesTable />}
                    {match.params.mode === 'analysts' && <AnalystsTable />}
                    {match.params.mode === 'account' && (
                        <EditAccount id={match.params.itemId} />
                    )}
                    {match.params.mode === 'user' && (
                        <EditUser id={match.params.itemId} />
                    )}
                    {match.params.mode === 'analyst' && (
                        <EditAnalyst id={match.params.itemId} />
                    )}
                </div>
            </div>
        )
    }
}

// Manage.propTypes = {};

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        accounts: state.manage.data.accounts,
        users: state.manage.data.users,
    }
}

const mapDispatchToProps = (dispatch) => {
    const ds = new DataService(dispatch)

    return {
        getAccounts: () => ds.getAccounts(),
        getUsers: () => ds.getUsers(),
        // getFinancialData: (sessionToken, fetchSilently = false, financeOnly = false) =>
        //   ds.getFinancialData(sessionToken, fetchSilently, financeOnly),

        // setRedirectedOnceFlag: (value) => {
        //   dispatch(AppActions.setRedirectedOnceFlag(true))
        // }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Manage))
