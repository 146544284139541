import React, {Component, PropTypes} from 'react'

import {connect} from 'react-redux'
import store from '@store'
import _ from 'lodash'
// import clStorage from '../../cl_modules/cl-storage'

import DataService from '@services/data'
import * as storage from '@services/storage'

import {
    updateUserCurrentProfile,
    updateUserCurrentFilters,
} from '@store/actions/app-actions'

// import {List, ListItem} from 'material-ui/List'
// import FontIcon from 'material-ui/FontIcon'
// import FlatButton from 'material-ui/FlatButton'
// import Divider from 'material-ui/Divider'
import Drawer from 'material-ui/Drawer'
import CircularProgress from 'material-ui/CircularProgress'
//import {Link} from 'react-router-dom'
// import SectorItem from './SectorItem'
import {Dimensions} from '@services/styles'
import {Select, Range, Reset} from './HomeDrawer.styles'
// import MenuItem from 'material-ui/MenuItem'

import AlertRange from './AlertRange'
import FilterCheckBox from './FilterCheckBox'
import AlertRangeCheck from './AlertRangeCheck'
import styled from 'styled-components'
import {AppColors} from '@services/styles'

export const initFilters = {
    market_cap: [0, 0],
    beta: [0, 0],
    upside: [0, 0],
    lastPrice: [0, 0],
    pe_ratio_12m: [0, 0],
    rate: [0, 0],
    universes: [],
    //peg_ratio: [0, 0],
    rating: [],
    sector_name: [],
    analyst_name: [],
    inWatchlist: [],
    //mc_ranges: [],
}
// const maxMc = 3000000000 //consider making this dynamic by results
// const minMc = 300000000
export const filterAlerts = (alerts, filters) => {
    const {symbols, followingSymbols} = store.getState().finance.data
    console.log('filters', filters)
    return alerts.filter((alert) => {
        const rangers = [
            'market_cap',
            'beta',
            'upside',
            'pe_ratio_12m',
            //'peg_ratio',
        ]
        for (let i in rangers) {
            const key = rangers[i]
            const filter = filters[key]
            if (filter[0] != filter[1]) {
                if (key === 'market_cap') {
                    //TODO: unite with below if we keep it
                    const compareTo = alert['mc_index']
                    if (compareTo < filter[0] || compareTo > filter[1]) {
                        return false
                    }
                    // const compareTo = parseInt(alert[key])
                    // // this is for js issues with big numbers. TODO: find better
                    // const maxEq = Math.abs(filter[1] - maxMc) < 100
                    // const minEq = Math.abs(filter[0] - minMc) < 100
                    // if (maxEq && minEq) {
                    //     if (compareTo < filter[0]) return false
                    // } else if (!maxEq && minEq) {
                    //     if (compareTo > filter[1] + 100) return false
                    // } else if (!maxEq && !minEq) {
                    //     if (
                    //         compareTo < filter[0] || // may need here too..
                    //         compareTo > filter[1] + 100 //also for js-bs, find better
                    //     )
                    //         return false
                    // }
                } else {
                    // round it before comparing because it was rounded when given to AlertRange
                    // so if not rounded may filter out edges
                    const compareTo = _.round(alert[key], 2)
                    if (compareTo < filter[0] || compareTo > filter[1]) {
                        return false
                    }
                }
            }
        }
        //---- not done ----------------------
        // if (filters['mc_ranges'] && filters['mc_ranges'].length) {
        //     const compareTo = parseInt(alert['market_cap'])
        // }
        const filter = filters && filters['rate'] ? filters['rate'] : [1, 7]
        const r = alert['rate']
        const rate =
            r == 'C+'
                ? 1
                : r == 'B-'
                ? 2
                : r == 'B'
                ? 3
                : r == 'B+'
                ? 4
                : r == 'A-'
                ? 5
                : r == 'A'
                ? 6
                : r == 'A+'
                ? 7
                : 0
        if (
            filter[0] !== 0 &&
            filter[1] !== 0 &&
            (rate < filter[0] || rate > filter[1])
        ) {
            // for this filter allow filter[0] == filter[1] (B-B)
            return false
        }
        //----------------
        const symbolRangers = ['lastPrice']
        for (let i in symbolRangers) {
            const key = symbolRangers[i]
            const filter = filters[key]
            const symbol = symbols[alert.symbol.toUpperCase()]
            if (
                filter[0] != filter[1] &&
                (symbol[key] < filter[0] || symbol[key] > filter[1])
            ) {
                return false
            }
        }
        const pickers = ['rating', 'sector_name', 'analyst_name']
        //console.log('filters3', filters)
        for (let i in pickers) {
            const key = pickers[i]
            const filter = filters[key] ? filters[key] : []
            if (filter.length > 0 && !filter.includes(alert[key])) {
                return false
            }
        }

        if (filters.universes && filters.universes.length) {
            //arrys intersection. but still multi select, same as sectors
            return filters.universes.filter((uni) =>
                alert.universes.includes(uni),
            ).length
        }

        if (filters.inWatchlist.length == 1) {
            const inWatchlist = filters.inWatchlist[0]
            const followedAlert = _.find(
                followingSymbols,
                (o) => o.symbol.toUpperCase() == alert.symbol.toUpperCase(),
            )
            //??   if NOT ( (both true)  or (both false) )
            if (
                !(
                    (inWatchlist && followedAlert) ||
                    (!inWatchlist && !followedAlert)
                )
            ) {
                return false
            }
        }
        return true
    })
}

const ALERTS_FILTERS_STORAGE_KEY = `ALERTS_FILTERS_STORAGE_KEY`
class HomeDrawer extends Component {
    state = {
        ...this.props.user.alert_filters,
        showMoreSliders: false,
    }
    componentDidMount() {
        const {current_profile} = this.props
        this._isMounted = true
        // storage.getFiltersForAlert().then( data => {
        //   const { userId } = this.props
        //   if (data && data.userId == userId) {
        //     this.updateState(data.filters, false)
        //   } else if ( data && data.userId && userId) {
        //     this.clearStorage()
        //   }
        // })
    }
    componentWillUnmount() {
        this._isMounted = false
    }

    handleProfileSelect = (profile) => {
        const {
            updateUserProfile,
            onProfileSelected,
            current_profile,
            updateUserFilters,
        } = this.props
        if (current_profile.profile == profile.profile) return
        updateUserFilters(initFilters)
        updateUserProfile && updateUserProfile(profile)
        onProfileSelected && onProfileSelected(profile)
    }

    updateState = (newState) => {
        console.log('new_state', newState)

        const {onFilterChange, updateUserFilters} = this.props
        if (!this._isMounted) return
        this.setState(newState, () => {
            onFilterChange && onFilterChange(this.state)
            updateUserFilters(this.state)
        })
    }
    clearStorage = () => {
        this.updateStorage(null)
    }
    updateStorage = (filters) => {
        const {userId} = this.props
        storage.setFiltersForAlert({filters, userId})
    }
    gradeFormatter = (n) => {
        return n == 1
            ? 'C+'
            : n == 2
            ? 'B-'
            : n == 3
            ? 'B'
            : n == 4
            ? 'B+'
            : n == 5
            ? 'A-'
            : n == 6
            ? 'A'
            : 'A+'
    }

    render() {
        const width = Dimensions.DRAWER_WIDTH
        const {
            available_profiles,
            current_profile,
            symbols,
            followingSymbols,
            user,
        } = this.props

        // #noprofiles - 22.06.23 remove the profile selection by Keren - see comments client and server
        // 12.11.2023 - return this for admin/selected useres. not stable, not ux, nobody thought it through, not done at all...

        if (
            available_profiles &&
            current_profile &&
            current_profile.profile !== 'Default' &&
            (!user || !user.is_admin)
        ) {
            var def = available_profiles.filter((p) => p.profile == 'Default')
            if (def.length > 0) {
                this.handleProfileSelect(def[0])
            }
        }
        console.log('current_profile_', current_profile)

        return (
            <div
                style={{
                    position: 'relative',
                    height: 'fit-content',
                    paddingRight: Dimensions.CONTENT_BASE_PADDING,
                }}>
                <Drawer
                    open={this.props.open}
                    width={width}
                    containerStyle={{
                        position: 'relative',
                        boxShadow: 'none',
                        height: 'fit-content',
                        zIndex: 0,
                    }}>
                    {this.props.loading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 30,
                                paddingLeft: 0,
                            }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div>
                            {this.props.user.is_admin && (
                                // #noprofiles - see comments
                                <div>
                                    {available_profiles ? (
                                        <Select
                                            style={{width: '100%'}}
                                            value={profileToSelect(
                                                current_profile,
                                            )}
                                            options={available_profiles.map(
                                                (o) => profileToSelect(o),
                                            )}
                                            onChange={(val) => {
                                                this.handleProfileSelect(val)
                                            }}
                                        />
                                    ) : (
                                        <Select
                                            isDisabled
                                            value={{
                                                label: 'No profile available',
                                            }}
                                            style={{width: '100%'}}
                                        />
                                    )}
                                </div>
                            )}

                            <Reset
                                onClick={() => this.updateState(initFilters)}
                            />
                            {/* <AlertRangeCheck
                                label="Market Cap"
                                values={this.state.mc_ranges}
                                setValues={(values) =>
                                    this.updateState({mc_ranges: values})
                                }
                            /> */}
                            <AlertRange
                                label="Market Cap"
                                value={this.state.market_cap}
                                //maxMc={maxMc}
                                //minMc={minMc}
                                keyBy={(alert) => alert?.mc_index}
                                //{
                                // var mc = alert
                                //     ? parseInt(alert.market_cap)
                                //     : 0
                                // return mc < maxMc
                                //     ? mc > minMc
                                //         ? mc
                                //         : minMc
                                //     : maxMc
                                //}

                                step={1}
                                onChange={(val) => {
                                    //console.log('slider change', val)
                                    this.updateState({market_cap: val})
                                }}
                                //tipFormatter={marketCapFromt}
                            />
                            <AlertRange
                                label="Beta"
                                step={0.01}
                                value={this.state.beta}
                                keyBy={(alert) =>
                                    _.round(alert ? alert.beta : 0, 2)
                                }
                                onChange={(val) =>
                                    this.updateState({beta: val})
                                }
                                tipFormatter={(num) => _.round(num, 2)}
                            />

                            <AlertRange
                                step={0.01}
                                label="P/E 12m"
                                value={this.state.pe_ratio_12m}
                                keyBy={(alert) =>
                                    _.round(alert ? alert.pe_ratio_12m : 0, 2)
                                }
                                onChange={(val) =>
                                    this.updateState({pe_ratio_12m: val})
                                }
                                tipFormatter={(num) => _.round(num, 2)}
                            />
                            <AlertRange
                                step={1}
                                label="Analyst Grade"
                                value={this.state.rate}
                                //keyBy={(alert) => (alert ? alert.rate : 'N/A')}
                                onChange={(val) =>
                                    this.updateState({rate: val})
                                }
                                tipFormatter={this.gradeFormatter}
                            />

                            {/* <AlertRange
                                    step={0.01}
                                    label="PEG Ratio"
                                    value={this.state.peg_ratio}
                                    keyBy={(alert) =>
                                        _.round(alert ? alert.peg_ratio : 0, 2)
                                    }
                                    onChange={(val) =>
                                        this.updateState({peg_ratio: val})
                                    }
                                    tipFormatter={(num) => _.round(num, 2)}
                                /> */}
                            <ShowMore
                                onClick={() =>
                                    this.setState({
                                        showMoreSliders:
                                            !this.state.showMoreSliders,
                                    })
                                }>
                                {this.state.showMoreSliders
                                    ? 'see less'
                                    : 'see more'}
                            </ShowMore>
                            {this.state.showMoreSliders && (
                                <div>
                                    <AlertRange
                                        step={0.01}
                                        label="Upside"
                                        value={this.state.upside}
                                        keyBy={(alert) =>
                                            _.round(alert ? alert.upside : 0, 2)
                                        }
                                        onChange={(val) => {
                                            this.updateState({upside: val})
                                        }}
                                        tipFormatter={(num) =>
                                            _.round(num, 2) + '%'
                                        }
                                    />
                                    <AlertRange
                                        step={0.01}
                                        label="Last Price"
                                        value={this.state.lastPrice}
                                        keyBy={(alert) =>
                                            alert
                                                ? symbols[
                                                      alert.symbol.toUpperCase()
                                                  ]
                                                    ? _.round(
                                                          symbols[
                                                              alert.symbol.toUpperCase()
                                                          ].lastPrice,
                                                          2,
                                                      )
                                                    : 0
                                                : 0
                                        }
                                        onChange={(val) =>
                                            this.updateState({lastPrice: val})
                                        }
                                        tipFormatter={(num) => _.round(num, 2)}
                                    />
                                </div>
                            )}

                            <FilterCheckBox
                                title="Universe"
                                keyBy={(alert) =>
                                    alert ? alert.universes : []
                                }
                                values={this.state.universes}
                                onChange={(values) =>
                                    this.updateState({universes: values})
                                }
                            />
                            <FilterCheckBox
                                title="Position"
                                keyBy={(alert) => (alert ? alert.rating : 0)}
                                values={this.state.rating}
                                onChange={(values) =>
                                    this.updateState({rating: values})
                                }
                                labelBy={(rating) =>
                                    rating == 'sell' ? 'Bearish' : 'Bullish'
                                }
                                order="desc"
                            />
                            <FilterCheckBox
                                title="In Watchlist"
                                keyBy={(alert) =>
                                    alert
                                        ? _.find(
                                              followingSymbols,
                                              (o) =>
                                                  o.symbol.toUpperCase() ==
                                                  alert.symbol.toUpperCase(),
                                          )
                                            ? true
                                            : false
                                        : false
                                }
                                values={this.state.inWatchlist}
                                onChange={(values) =>
                                    this.updateState({inWatchlist: values})
                                }
                                labelBy={(inWatchlist) =>
                                    inWatchlist
                                        ? 'In Watchlist'
                                        : 'Not In Watchlist'
                                }
                                order="desc"
                            />

                            <FilterCheckBox
                                title="Sector"
                                keyBy={(alert) =>
                                    alert ? alert.sector_name : ''
                                }
                                values={this.state.sector_name}
                                onChange={(values) =>
                                    this.updateState({sector_name: values})
                                }
                            />
                            <FilterCheckBox
                                title="Analyst"
                                keyBy={(alert) =>
                                    alert ? alert.analyst_name : ''
                                }
                                values={this.state.analyst_name}
                                onChange={(values) =>
                                    this.updateState({analyst_name: values})
                                }
                            />
                        </div>
                    )}
                </Drawer>
            </div>
        )
    }
}

const profileToSelect = (profile) => {
    return {
        value: profile?.profile,
        label: profile ? capitalize(profile?.profile.replace('_', ' ')) : null,
        ...(profile || {}),
    }
}
// const profileToSelect2 = (profile) => {
//     return profile?.profile
// }
const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

// const marketCapFromt = (num) => {
//     console.log('Formater marketCapFromt', num)
//     return num.toLocaleString('en-US', {
//         // style: 'currency', // can use that, will $1.2T. i think too much
//         // currency: 'USD',
//         notation: 'compact',
//         maximumFractionDigits: 1,
//     })
// }

const mapStateToProps = (state) => ({
    available_profiles: state.user.user.available_profiles,
    account_type: state.user.user.account_type,
    current_profile: state.user.user.current_profile,
    userId: state.user.user.user_id,
    user: state.user.user,
    sectors: state.user.user.system_sectors,
    sectorsLoading: state.finance.isFetching['getUserProfileData'],
    symbols: state.finance.data.symbols,
    followingSymbols: state.finance.data.followingSymbols,
})
const mapDispatchToProps = (dispatch) => {
    const ds = new DataService(dispatch)
    return {
        updateUserProfile: (profile) => {
            //console.log('select_p selected', profile)
            dispatch(updateUserCurrentProfile(profile))
            return ds.getFinancialData(profile, false, true)
        },
        updateUserFilters: (filters) => {
            dispatch(updateUserCurrentFilters(filters))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeDrawer)

const ShowMore = styled.div`
    color: ${AppColors.TIPIGO_BLUE};
    cursor: pointer;
    border: 0px solid red;
    padding-left: 9px;
    margin-top: 10px;
`
