import * as types from '../ActionTypes'

const defaultState = {
    isFetching: false,
    initialized: false,
    redirectedOnce: false,
    data: {},
}

const dataEntities = {
    getAccounts: 'accounts',
    getUsers: 'users',
    getProfiles: 'profiles',
    updateUser: 'user',
    updateAccount: 'account',
}

const manageReducer = (state = defaultState, action) => {
    if (
        [
            'getAccounts',
            'getUsers',
            'getProfiles',
            'getAnalysts',
            'updateAccount',
            'updateUser',
        ].indexOf(action.method) > -1
    ) {
        switch (action.type) {
            case types.API_REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                })
            case types.API_RESPONSE:
                let entity = dataEntities[action.method]

                let newState = Object.assign({}, state, {
                    isFetching: false,
                    initialized: true,
                })

                if (entity && action.data) {
                    newState.data[entity] = action.data
                }
                return newState

            case types.API_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    initialized: true,
                })
            default:
                return state
        }
    }

    if (action.type === types.SET_REDIRECTED_ONCE_FLAG) {
        return Object.assign({}, state, {
            redirectedOnce: action.value,
        })
    }

    return state
}

export default manageReducer
