import {api} from '@services/api'
import {formatUniverseResponse} from '@services/utils'

export const setUser = (user) => {
    return {
        type: 'SET_USER',
        user,
    }
}

export const setIsFetching = (isFetching) => {
    return {
        type: 'SET_IS_FETCHING',
        isFetching,
    }
}

export const setUniverses = (universeList) => {
    return {
        type: 'SET_UNIVERSES',
        universeList,
    }
}

export const getCurrentUser = () => {
    return (dispatch, getState) => {
        dispatch(setIsFetching(true))
        api.getCurrentUser().subscribe(
            (response) => {
                console.log(
                    'get current user from user actions - response',
                    response,
                )
                if (response.data && response.data.data) {
                    const user = response.data.data
                    getUniList().then(
                        (response) => {
                            user.universeList = response
                            dispatch(setUser(user))
                        },
                        (error) => {
                            console.log('getUniList1 failed', error)
                            dispatch(setUser(user))
                        },
                    )

                    //dispatch(setUser(response.data.data))
                } else {
                    dispatch(setUser(null))
                }
            },
            (error) => {
                console.log('get current user from user actions - error', error)
                dispatch(setUser(null))
            },
        )
    }
}
export const getUniverses = (user) => {
    // this is after add uni
    return (dispatch, getState) => {
        const newUser = {...user}
        getUniList().then(
            (response) => {
                newUser.universeList = response
                console.log('getTipigoIndexes getUniverses', response, newUser)
                dispatch(setUser(newUser))
            },
            (error) => {
                // console.log('getUniList2 failed', error)
                dispatch(setUser(user))
            },
        )
    }
}

const getUniList = () => {
    // this is from getCurrentUser
    return new Promise((resolve, reject) => {
        api.getTipigoIndexes(0).subscribe(
            (response) => {
                console.log('v1 getTipigoIndexes2 list', response)
                const list = formatUniverseResponse(response)
                resolve(list)
            },
            (error) => {
                console.log('v1 getTipigoIndexes error', error)
                reject([])
            },
        )
    })
}

// export const setInputFieldsValues = (inputFieldsValues) => {
//     return {
//         type: 'SET_INPUT_FIELDS_VALUES',
//         inputFieldsValues,
//     }
// }

// export const setInputFieldValue = (field, value) => {
//     return (dispatch, getState) => {
//         console.log('dispatch set input field', field, value)
//         const newField = {...getState().finance.inputFieldsValues[field]}
//         newField.value = value
//         newField.error = null

//         const selections = {
//             ...getState().finance.inputFieldsValues,
//             [field]: newField,
//         }

//         api.setUserSelection('all', 'fieldMapping', selections).then(() =>
//             console.log('field mappings updated', selections),
//         )

//         // Object.keys(inputFieldsValues).forEach(
//         //     (field) => (selections[field] = inputFieldsValues[field].value)
//         // )
//         // api.setUserSelection('all', 'fieldMapping', selections).then(() =>
//         //     console.log('field mappings updated', selections)
//         // )

//         // console.log('set input field values', inputFieldsValues, fieldValues)
//         dispatch(setInputFieldsValues(selections))
//     }
// }
