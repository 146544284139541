import React from 'react'
import styled from 'styled-components'
import {useState, useEffect, useUser} from '@services/hooks'
import Dialog from '@mui/material/Dialog'
import {
    Title,
    SendButton,
    SendButtonDis,
    DialogContainer,
    DialogTopRow,
    DialogRow,
} from '@components/General/Elements'
import {api, newApiPortfolioFunds} from '@services/api'
import {Close} from '@mui/icons-material'
// import DateRange from '@components/General/DateRange'
import swal from 'sweetalert2'
import Analytics from '@services/analytics'
// import FrameInput from '@components/General/FrameInput'
// import FrameText from '@components/General/FrameText'
// import FrameSelect from '@components/General/FrameSelect'
// import {ReactComponent as RemoveLine} from '@images/square_minus.svg'
import {ReactComponent as AddLine} from '@images/square_plus.svg'
import moment from 'moment'
//import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton'
import {AppColors} from '@services/styles'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from 'material-ui/CircularProgress'
import {
    intOrEmpty,
    toNiceNum,
    updateFieldInList,
    formatUserInputPrice,
} from '@services/utils'
import AddPosLine from './AddPosLine'
import AddFromList from './AddFromList'

//import Backdrop from '@mui/material/Backdrop'

const BulkAddPositions = ({
    userId,
    pId,
    isOpenDialog,
    setIsOpenDialog,
    setNeedRefresh,
    pos,
    ticker,
    tickers,
    msgOnSuccess,
    currentCash,
    minDate,
}) => {
    const [items2Add, setItems2Add] = useState([])
    const [single, setSingle] = useState(false)
    const [canSave, setCanSave] = useState(false)
    const [manualAdd, setManualAdd] = useState(true)
    // const [listSym, setListSym] = useState('')
    const [countFromList, setCountFromList] = useState(-1)
    const [canUseList, setCanUseList] = useState(!(pos || ticker || tickers))
    const [listProgress, setListProgress] = useState(false)
    const [totalListVal, setTotalListVal] = useState(
        currentCash && currentCash > 0 ? currentCash : 0,
    )
    const [ticArrFromList, setTicArrFromList] = useState([])
    const [minimumDate, setMinimumDate] = useState(minDate)
    const user = useUser()

    const savePositions = () => {
        setCanSave(false)
        Analytics.exec('track', 'add portfolio position')
        const user_id = userId ? userId : user.user_id
        const items2Temp = items2Add.map((item) => {
            // clean date to avoid server changes when posting (because of time zone)
            return {...item, date: moment(item.date).format('YYYY-MM-DD')}
        })

        api.addUserPortfolioPositions(user_id, pId, items2Temp).subscribe(
            (response) => {
                console.log('addUserPortfolioPositions', response)
                if (!response.data.status) {
                    swal.fire({
                        icon: 'error',
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                        html: response.data.msg,
                    })
                    // do not close, let user fix and try again
                    // change: do close. if user has no file and got to this error and close this dailog - some strange client error/
                    // TODO: find error source.. not top priority..
                } else {
                    if (msgOnSuccess) {
                        // can make it more dynamic by passing the msg.
                        // for now no need. in use in myfile without msg and in stock with
                        swal.fire({
                            text: 'Position added to Portfolio',
                            confirmButtonColor: AppColors.TIPIGO_GREEN,
                        })
                    }
                    setNeedRefresh && setNeedRefresh(true)
                }
                if (response.data?.type !== 1) {
                    resetEmpty()
                    setTimeout(() => setIsOpenDialog(false), 500)
                }
            },
            (error) => {
                console.log('addUserPortfolioPosition error', error)
            },
        )
    }
    const getTemplate = (id) => {
        return {
            id: id ? id : 0,
            posId: false,
            symbol: '',
            type: 'Buy',
            date: new Date(),
            price: 0,
            notes: '',
            quantity: 0,
            value: '',
            color: '#333',
        }
    }

    const formatManualList = async () => {
        resetEmpty() // this is just in case there was somthing there
        setListProgress(true)
        setCountFromList(-1)
        setManualAdd(true)
        setItems2Add([])

        // use for and not .forEach for the await
        // use await because we have to know when done all
        for (var i = 0; i < ticArrFromList.length; i++) {
            const empty = getTemplate(i)
            empty.symbol = ticArrFromList[i]
            setItems2Add((current) => [...current, empty])
            await _setTicker(ticArrFromList[i], i, false)
        }
        setCountFromList(ticArrFromList.length)
    }

    useEffect(() => {
        console.log('formatedvalue4')
        if (ticArrFromList?.length) {
            formatManualList()
        }
    }, [ticArrFromList])

    const fromList2ndSatge = () => {
        var countValid = 0
        for (var i = 0; i < items2Add.length; i++) {
            if (!items2Add[i].price) {
                removeRow(items2Add[i].id)
            } else {
                countValid++
            }
        }
        // use countValid coz can't trust removeRow to be done before using items2Add.length
        const totalVal = parseInt(totalListVal.toString().replaceAll(',', ''))
        const posVal = totalVal / countValid
        for (var i = 0; i < items2Add.length; i++) {
            if (items2Add[i].price) {
                setByValue(items2Add[i].id, posVal)
            }
        }
        setListProgress(false)
    }

    useEffect(() => {
        console.log('formatedvalue3')
        if (countFromList === items2Add.length) {
            fromList2ndSatge()
            setCountFromList(-1)
        }
    }, [countFromList])

    useEffect(() => {
        console.log('formatedvalue2')
        setCanUseList(!(pos || ticker || tickers))
        setSingle(pos || ticker)
        if (pos) {
            // this is edit mode, don't let add lines
            setItems2Add([
                {
                    id: pos.id,
                    posId: pos.id,
                    symbol: pos.symbol,
                    type: pos.pos_type,
                    date: new Date(pos.entry_date.date),
                    price: pos.entry,
                    notes: pos.notes || '',
                    quantity: Math.abs(pos.quantity),
                },
            ])
        } else if (ticker) {
            setItems2Add([
                {
                    id: 0,
                    posId: false,
                    symbol: ticker,
                    type: 'Buy',
                    date: new Date(),
                    price: '',
                    value: '',
                    notes: '',
                    quantity: 1,
                },
            ])
            _setTicker(ticker, 0, false) // could be a  problem if setting the value before above finish.
        } else if (tickers) {
            setItems2Add([])
            tickers.forEach((ticker, i) => {
                const empty = getTemplate(i)
                empty.symbol = ticker
                setItems2Add((current) => [...current, empty])
                _setTicker(ticker, i, false)
            })
        } else {
            //empty mode for portolio page
            resetEmpty()
        }
    }, [pos, ticker, tickers])

    useEffect(() => {
        console.log('formatedvalue1')
        if ((ticker || tickers) && !minimumDate) {
            if (newApiPortfolioFunds) {
                if (user?.portfolio_id) {
                    console.log('set_min_date1 get id', user.portfolio_id)
                    api.getUserPortfolioLastDepositeDate(
                        user.portfolio_id,
                    ).subscribe(
                        (response) => {
                            console.log('set_min_date1', response.data)
                            const list = response.data
                                .map((deposit) =>
                                    moment(deposit.created).format(
                                        'YYYY-MM-DD',
                                    ),
                                )
                                .sort(function (a, b) {
                                    return b < a ? -1 : 1
                                })
                            setMinimumDate(list[0])
                        },
                        (error) => console.log('set_min_date1 error', error),
                    )
                }
            } else {
                const user_id = userId ? userId : user.user_id
                api.getUserPortfolioLastDepositeDate(0, user_id).subscribe(
                    (response) => {
                        console.log('set_min_date2', response.data)
                        setMinimumDate(response?.data?.last_deposite)
                    },
                    (error) => console.log('set_min_date2 error', error),
                )
            }
        }
    }, [ticker, tickers, minimumDate])

    const addLine = () => {
        var id = Math.max(...items2Add.map((o) => o.id)) + 1
        const empty = getTemplate(id)
        setItems2Add((current) => [...current, empty])
    }

    const removeRow = (id) => {
        const count = items2Add.length
        if (count == 1) {
            console.log('remove row2 ', id)
            resetEmpty()
            // if user tries to delete last one - just create empty new on (not sure Keren will approve)
        } else
            setItems2Add((current) => [
                ...current.filter((item) => item.id !== id),
            ])
    }

    const resetEmpty = () => {
        console.log('formatedvalue5')
        const first = getTemplate()
        setItems2Add([first])
    }

    const _setEntry = (value, id) => {
        const formatedvalue = formatUserInputPrice(value)
        console.log('formatedvalue', formatedvalue)
        // TODO: change above to always return formated value (now it just doesn't update illegal value)
        if (formatedvalue !== false) {
            updateField(formatedvalue, id, 'price')
            _setValue(id, formatedvalue, false)
        }
    }

    const setQuantity = (value, id) => {
        value = intOrEmpty(value)
        if (value == '') {
            updateField('', id, 'quantity')
        } else {
            updateField(toNiceNum(value), id, 'quantity')
        }
        _setValue(id, false, value)
    }

    const _setInputValue = (value, id) => {
        value = value.trim()
        if (value == '') {
            updateField('', id, 'value')
        } else {
            value = parseFloat(value.toString().replaceAll(',', ''))
            if (!isNaN(value) && value >= 0) {
                updateField(toNiceNum(value), id, 'value')
            }
        }
    }

    const setByValue = (id, wantedVal) => {
        const item = items2Add.filter((item) => item.id == id)[0]

        const val = wantedVal
            ? parseInt(wantedVal)
            : item.value
            ? parseInt(item.value.toString().replaceAll(',', ''))
            : 0
        if (!val) return
        console.log(val, wantedVal)
        const roundQ = Math.round(val / item.price)
        updateField(roundQ, id, 'quantity')
        // use toFixed because js is an asshole
        updateField(toNiceNum((roundQ * item.price).toFixed(2)), id, 'value')
    }

    const _setValue = (id, p, q) => {
        //TODO: not done, not all casess, consider still moving all to useEffect
        if (p === 0 || p === '' || q === 0 || q === '') {
            updateField('', id, 'value')
        } else {
            p = p
                ? parseFloat(p)
                : items2Add.filter((item) => item.id == id)[0].price
            q = q
                ? parseInt(q)
                : items2Add.filter((item) => item.id == id)[0].quantity
            if (p === 0 || q === 0) {
                return // 11.04.24 override error but not done
                updateField('', id, 'value')
            } else {
                updateField(toNiceNum(p * q), id, 'value')
            }
        }
    }

    async function _setTicker(val, id, input) {
        var letters = /^[A-Za-z]+$/
        if (val.match(letters) || val == '') {
            val = val.toUpperCase()
            updateField(val, id, 'symbol')
            const gotPrice = await symbolExits(val)
            if (gotPrice) {
                updateField(gotPrice, id, 'price')
                input && updateField('green', id, 'color')
            } else {
                updateField('', id, 'price')
                input && updateField('red', id, 'color')
            }
        } else {
            return
        }
    }

    const updateField = (val, id, field) => {
        updateFieldInList(val, id, field, setItems2Add)
    }

    const symbolExits = (ticker) => {
        return new Promise((resolve, reject) => {
            if (ticker) {
                api.getSymbolLastPrice(ticker).subscribe(
                    (response) => {
                        console.log('got symbol', response)
                        if (response.data.price) {
                            resolve(response.data.price)
                        } else {
                            resolve(false)
                        }
                    },
                    (error) => {
                        console.log('got symbol error', error)
                        resolve(false)
                    },
                )
            } else {
                resolve(false)
            }
        })
    }

    useEffect(() => {
        var okSave = items2Add.every((item, i) => {
            if (
                !item.quantity ||
                !item.price ||
                !item.symbol ||
                item.color === 'red'
            ) {
                return false
            }
            return true
        })
        items2Add.forEach((item) => {
            if (
                (!item.value || item.value == '0') &&
                item.quantity &&
                item.price
            ) {
                // this is only after setTicker, manual quantity and price are called from there
                _setValue(item.id, item.price, item.quantity)
            }
        })
        setCanSave(okSave)
    }, [items2Add])

    return (
        <Dialog
            maxWidth="lg"
            fullWidth
            modal={true}
            open={isOpenDialog}
            onClose={() => setIsOpenDialog(false)}>
            <DialogContainer
                style={{
                    paddingLeft: 48,
                    paddingRight: 48,
                }}>
                <DialogTopRow>
                    <Title
                        text={
                            pos
                                ? 'Edit Position'
                                : ticker
                                ? 'Add Position'
                                : 'Add Positions'
                        }
                    />
                    <Close onClick={() => setIsOpenDialog(false)} />
                </DialogTopRow>
                {canUseList && (
                    <DialogRow>
                        <RadioGroup
                            row
                            value={manualAdd}
                            onChange={(e, value) => {
                                setManualAdd(eval(value))
                            }}
                            style={{}}>
                            <FormControlLabel
                                value={true}
                                control={
                                    <Radio
                                        sx={{
                                            color: AppColors.APP_GRAY,
                                            '&.Mui-checked': {
                                                color: AppColors.TIPIGO_GREEN,
                                            },
                                        }}
                                    />
                                }
                                label="Manual"
                            />
                            <FormControlLabel
                                value={false}
                                control={
                                    <Radio
                                        sx={{
                                            color: AppColors.APP_GRAY,
                                            '&.Mui-checked': {
                                                color: AppColors.TIPIGO_GREEN,
                                            },
                                        }}
                                    />
                                }
                                label="From List"
                            />
                        </RadioGroup>
                    </DialogRow>
                )}

                {manualAdd && (
                    <div>
                        {items2Add?.length //getRow(item, i)
                            ? items2Add.map((item, i) => (
                                  <AddPosLine
                                      key={i}
                                      item={item}
                                      updateField={updateField}
                                      _setTicker={_setTicker}
                                      setEntry={_setEntry}
                                      setQuantity={setQuantity}
                                      _setInputValue={_setInputValue}
                                      single={single}
                                      removeRow={removeRow}
                                      setByValue={setByValue}
                                      ticker={ticker}
                                      minDate={minimumDate}
                                  />
                              ))
                            : null}

                        <DialogRow>
                            {!single && (
                                <AddLine
                                    onClick={addLine}
                                    style={{stroke: AppColors.TIPIGO_GREEN}}
                                />
                            )}
                        </DialogRow>
                        <div
                            style={{
                                height: single ? 140 : 90,
                            }}
                        />

                        <DialogRow style={{justifyContent: 'flex-end'}}>
                            {canSave ? (
                                <SendButton onClick={savePositions}>
                                    Save
                                </SendButton>
                            ) : (
                                <SendButtonDis>Save</SendButtonDis>
                            )}
                        </DialogRow>
                    </div>
                )}

                {!manualAdd && (
                    <AddFromList
                        totalListVal={totalListVal}
                        setTotalListVal={setTotalListVal}
                        setTicArrFromList={setTicArrFromList}
                    />
                )}

                {listProgress && (
                    <Overley>
                        <CircularProgress />
                    </Overley>
                )}
            </DialogContainer>
        </Dialog>
    )
}

export default BulkAddPositions

const Overley = styled.div`
    position: absolute;
    width: 95%;
    height: 95%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background-color: rgba(150, 150, 150, 0.4);
    align-items: center;
    display: flex;
    justify-content: center;
`

/*
const _setTotalListVal = (value) => {
        value = intOrEmpty(value)
        if (value == '') {
            setTotalListVal('')
        } else {
            setTotalListVal(toNiceNum(value))
        }
    }
async function formatFromList() {
        resetEmpty() // this is just in case there was somthing there
        setListProgress(true)
        setCountFromList(-1)
        const arr = listSym
            .replace(/[\W_]+/g, ',') // this will keep numbers but there only so much we can guess as to user intentions...
            .split(',')
            .filter((a) => a) // remove empty
            .filter((val, index, theArray) => theArray.indexOf(val) === index) // unique

        if (arr.length === 0) {
            swal.fire({
                text: 'Invalid symbols data',
                icon: 'error',
            })

            setListProgress(false)
            return
        }
        if (!totalListVal) {
            swal.fire({
                text: 'Invalid total cash value',
                icon: 'error',
            })
            setListProgress(false)
            return
        }
        setManualAdd(true)
        setItems2Add([])
        // use for and not .forEach for the await
        // use await because we have to know when done all
        for (var i = 0; i < arr.length; i++) {
            const empty = getTemplate(i)
            empty.symbol = arr[i]
            setItems2Add((current) => [...current, empty])
            await _setTicker(arr[i], i, false)
        }
        setCountFromList(arr.length)
    }*/

// const Text = styled.div`
// color: ${AppColors.BLACK};
// font-size: 16px;
// width: 700px;
// `
// <div>
//     <DialogRow>
//         <Text>
//             Easily import a trading list by copying and
//             pasting the symbol and quantity information. The
//             positions will be automatically added to your
//             portfolio with the quantity you enter, While the
//             entry price will be calculated based on the
//             total allocated value in equal weight. Next, you
//             can change the dates, entry prices and quantity
//             figures.
//         </Text>
//     </DialogRow>
//     <DialogRow>
//         <FrameText
//             value={listSym}
//             setValue={setListSym}
//             title="Symbols (separate by comma, space or new line)"
//             style={{width: 500}}
//             frameStyle={{height: 130}}
//         />
//     </DialogRow>
//     <DialogRow>
//         <FrameInput
//             title="Total value"
//             value={totalListVal}
//             setValue={_setTotalListVal}
//             style={{width: 500}}
//         />
//     </DialogRow>
//     <DialogRow style={{justifyContent: 'flex-end'}}>
//         <SendButton onClick={formatFromList}>
//             Next
//         </SendButton>
//     </DialogRow>
// </div>
