/**
 * Auth service
 *
 * This service is responsible for logging in and logging out
 * the user. It uses both cl-api and cl-storage to manage the
 * login and logout operations including storing the credentials
 * in local storage for future use.
 */
let Rx = require('rx')
let CLApi = (window.CLApi = require('../cl-api').default)
let CLStorage = (window.CLStorage = require('../cl-storage').default)

class Auth {
    constructor(props) {
        this.api = new CLApi()
    }

    login(email, password, rememberMe) {
        return Rx.Observable.create((observer) => {
            this.api._login({email, password, rememberMe}).subscribe(
                (loginResponse) => {
                    console.log('login_response4', loginResponse)
                    CLStorage.storeLoginCredentials('token', {
                        auth_token: loginResponse.data.auth_token,
                        method: 'token',
                    })
                    observer.onNext(loginResponse)
                },
                (error) => {
                    observer.onError(error)
                },
                () => observer.onCompleted(),
            )
        })
    }

    signup(email, password, rememberMe) {
        return Rx.Observable.create((observer) => {
            this.api._signup({email, password, rememberMe}).subscribe(
                (loginResponse) => {
                    CLStorage.storeLoginCredentials('token', {
                        auth_token: loginResponse.data.auth_token,
                        method: 'token',
                    })
                    observer.onNext(loginResponse)
                },
                (error) => {
                    observer.onError(error)
                },
                () => observer.onCompleted(),
            )
        })
    }

    resetPassword(email, password, rememberMe) {
        return Rx.Observable.create((observer) => {
            this.api._resetPassword({email, password, rememberMe}).subscribe(
                (loginResponse) => {
                    observer.onNext(loginResponse)
                },
                (error) => {
                    observer.onError(error)
                },
                () => observer.onCompleted(),
            )
        })
    }

    logout() {
        return Rx.Observable.create((observer) => {
            this.api._logout().subscribe(
                (logoutResponse) => {
                    // Remove credentials from local storage
                    CLStorage.clearLoginCredentials()

                    // Return response to caller
                    observer.onNext(logoutResponse)
                },
                (error) => {
                    observer.onError(error)
                },
            )
        })
    }
}

export default Auth
