import React, {Component, PropTypes} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import TextField from 'material-ui/TextField'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'

import DataService from '../../services/data'

import isEmail from 'validator/lib/isEmail'

import BaseEdit from './BaseEdit'
import Api from '../../services/api'

class EditUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isSuccess: false,
            isFetching: false,
        }

        this.api = new Api()

        this.itemForm = this.itemForm.bind(this)
        this.updateUser = this.updateUser.bind(this)
        this.isPasswordValid = this.isPasswordValid.bind(this)
    }

    componentDidMount() {
        this.props.fetchData()
    }

    updateState(newState) {
        if (this.state.isSuccess) {
            newState.isSuccess = false
        }
        this.setState(newState)
    }

    itemForm(item) {
        let {updateItem, accounts} = this.props
        console.log(item)

        if (!item) {
            return null
        }

        return (
            <div style={{width: '60%'}}>
                <div style={Style.rowContainer}>
                    <TextField
                        hintText="Email"
                        errorText={
                            isEmail(item.email || 'a@b.com')
                                ? ''
                                : 'Not a valid email'
                        }
                        autoComplete="off"
                        type="email"
                        style={Style.rowItem}
                        value={item && item.email}
                        onChange={(event, val) => {
                            item.email = val
                            this.updateState({searchText: item})
                        }}
                        fullWidth
                    />
                    <SelectField
                        style={Style.rowItem}
                        floatingLabelText="Set Account"
                        value={item && item.account ? item.account.name : null}
                        onChange={(event, ind, val) => {
                            item.account = ind === 0 ? null : accounts[ind - 1]
                            this.updateState({item})
                        }}
                        fullWidth>
                        <MenuItem value={null} primaryText="" />
                        {item &&
                            accounts &&
                            accounts.map((a) => (
                                <MenuItem
                                    key={'a_' + a.id}
                                    value={a.name}
                                    primaryText={a.name}
                                />
                            ))}
                    </SelectField>
                </div>
                <div style={{height: '20'}} />
                <div style={Style.rowContainer}>
                    <TextField
                        hintText="Password"
                        errorText={
                            this.isPasswordValid(item.password)
                                ? ''
                                : 'Password must contain at least 4 characters'
                        }
                        autoComplete="new-password"
                        type="password"
                        style={Style.rowItem}
                        value={item && item.password}
                        onChange={(event, val) => {
                            item.password = val
                            this.updateState({item})
                        }}
                        fullWidth
                    />
                    <TextField
                        hintText="Repeat Password"
                        errorText={
                            !item.repeatPassword ||
                            item.repeatPassword === item.password
                                ? ''
                                : 'Passwords do not mutch'
                        }
                        autoComplete="off"
                        type="password"
                        style={Style.rowItem}
                        value={item && item.repeatPassword}
                        onChange={(event, val) => {
                            item.repeatPassword = val
                            this.updateState({item})
                        }}
                        fullWidth
                    />
                </div>
                <div style={{...Style.rowContainer, marginTop: 10}}>
                    <div>
                        <input
                            type="checkbox"
                            checked={item && item.client_admin}
                            onChange={(e) => {
                                console.log(e.target.checked)
                                item.client_admin = e.target.checked
                                this.updateState({item})
                            }}
                        />
                        Client Admin
                    </div>
                </div>
            </div>
        )
    }

    isPasswordValid(pass) {
        return !pass || pass.length === 0 || pass.length > 4
    }

    updateUser(item) {
        // this.props.createNewUser(item)

        this.setState({isFetching: true}, () => {
            this.api.adminCreateUser(item).subscribe(
                (res) => {
                    this.setState({isFetching: false, isSuccess: true})
                },
                (err) => {
                    this.setState({isFetching: false})
                },
            )
        })
    }

    render() {
        let {data, id} = this.props
        let {isSuccess, isFetching} = this.state

        return (
            <BaseEdit
                data={data}
                id={id}
                displayName={false}
                itemForm={this.itemForm}
                mode="user"
                onUpdateItem={this.updateUser}
                isFetching={isFetching}
                isSuccess={isSuccess}
                onEditDone={() => console.log('user edit done')}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.manage.data.users,
        accounts: state.manage.data.accounts,
        isFetching: state.manage.isFetching,
    }
}

const mapDispatchToProps = (dispatch) => {
    const ds = new DataService(dispatch)

    return {
        fetchData: () => {
            ds.getAccounts()
            ds.getUsers()
        },
        createNewUser: ds.createNewUser,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser)

const Style = {
    rowContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },

    rowItem: {
        width: '45%',
    },
}
