import React from 'react'
import styled from 'styled-components'
import {useState} from '@services/hooks'
import Header from './Header'
import AppBar from 'material-ui/AppBar'
import {AppBarBack} from './NavAppBar/NavAppBar.styles'
import {Dimensions, AppColors} from '@services/styles'
import {useParams} from 'react-router-dom'
import {Title, SendButton} from './General/Elements'
import FrameInput from './General/FrameInput'
import {api} from '@services/api'
import Swal from 'sweetalert2'
import {validPassword, invalidPassMsg} from '@services/utils'

const ResetPassword = () => {
    const {code} = useParams()
    const [newPass1, setNewPass1] = useState('')
    const [newPass2, setNewPass2] = useState('')

    const sendResetRequest = () => {
        if (newPass1 !== newPass2) {
            Swal.fire({
                text: 'New password does not match confirm',
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
            return
        }

        if (!validPassword(newPass1)) {
            Swal.fire({
                text: invalidPassMsg,
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
            return
        }

        api.changePassword(code, newPass1).subscribe(
            (response) => {
                console.log('data', response)
                if (response.data && response.data.status) {
                    Swal.fire({
                        text: 'Password updated successfully',
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                        icon: 'success',
                    })
                } else {
                    Swal.fire({
                        text: 'Error updateing password. Please contact support',
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                        icon: 'error',
                    })
                }
            },
            (error) => {
                console.log('error', error)
                if (error.data && error.data.msg) {
                    Swal.fire({
                        text: error.data.msg,
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                        icon: 'error',
                    })
                } else {
                    Swal.fire({
                        text: 'Error updateing password. Please contact support',
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                        icon: 'error',
                    })
                }
            },
        )
    }

    return (
        <Container>
            <Header />
            <AppBar
                style={{
                    height: Dimensions.APP_BAR_HEIGHT,
                    backgroundColor: AppColors.SUB_HEAD_GREY,
                    position: 'fixed',
                    zIndex: 10,
                    top: Dimensions.NAVBAR_TOP_OFFSET,
                }}
                iconStyleLeft={{marginTop: 2}}
                iconElementLeft={
                    <AppBarBack onClick={() => this.props.history.push('/')} />
                }
            />
            <Title text={'Reset Password'} />
            <Main>
                <FrameInput
                    title={'New Password'}
                    password
                    value={newPass1}
                    setValue={setNewPass1}
                    style={{marginTop: 18}}
                />
                <FrameInput
                    title={'Confirm New Password'}
                    password
                    value={newPass2}
                    setValue={setNewPass2}
                    style={{marginTop: 18}}
                />
                <SendButton
                    onClick={sendResetRequest}
                    style={{marginTop: 18, flex: 1}}>
                    Reset password
                </SendButton>
            </Main>
        </Container>
    )
}
export default ResetPassword

const Container = styled.div`
    margin-top: ${50 +
    Dimensions.APP_BAR_HEIGHT +
    Dimensions.NAVBAR_TOP_OFFSET}px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const Main = styled.div`
    width: 300px;
    display: flex;
    flex: 1;
    flex-direction: column;
`
