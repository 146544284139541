import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'
import _ from 'lodash'

import Loading from '@components/General/Loading'

const locationHelper = locationHelperBuilder({})

const userIsAuthenticatedDefaults = {
    authenticatedSelector: (state) => {
        return state.user?.user && !_.isEmpty(state.user.user)
    },
    wrapperDisplayName: 'UserIsAuthenticated',
}

export const userIsAuthenticated = connectedAuthWrapper(
    userIsAuthenticatedDefaults,
)

export const userIsAuthenticatedRedir = connectedRouterRedirect({
    ...userIsAuthenticatedDefaults,
    AuthenticatingComponent: Loading,
    authenticatingSelector: (state) => state.user.isFetching,
    redirectPath: '/login',
})

// export const userIsAdminRedir = connectedRouterRedirect({
//   redirectPath: '/',
//   allowRedirectBack: false,
//   authenticatedSelector: state => state.user.user !== null && state.user.user.is_admin,
//   predicate: user => user.is_admin,
//   wrapperDisplayName: 'UserIsAdmin'
// })

const userIsNotAuthenticatedDefaults = {
    // Want to redirect the user when they are done loading and authenticated
    authenticatedSelector: (state) => {
        return !state.user.user || _.isEmpty(state.user.user)
    },
    wrapperDisplayName: 'UserIsNotAuthenticated',
}

export const userIsNotAuthenticated = connectedAuthWrapper(
    userIsNotAuthenticatedDefaults,
)

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
    ...userIsNotAuthenticatedDefaults,
    redirectPath: (state, ownProps) =>
        // 13.06.22 override history. if profile redirect only to profile. if user and last is profile also override
        // also disable all tabs for profile and allow anly edit profile, this is in NavBarApp.js
        // this whole thing is just for inner users that keeps login in and out
        state &&
        state.user &&
        state.user.user &&
        state.user.user.account_type === 'profile'
            ? '/investment-profile'
            : state &&
              state.user &&
              state.user.user &&
              state.user.user.account_type !== 'profile' &&
              locationHelper.getRedirectQueryParam(ownProps) ==
                  '/investment-profile'
            ? '/?tab=6' // 06.06.23 0->6
            : locationHelper.getRedirectQueryParam(ownProps) || '/',
    allowRedirectBack: false,
})
