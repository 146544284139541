import React, {Component, PropTypes} from 'react'
import {List, ListItem} from 'material-ui/List'
//import FontIcon from 'material-ui/FontIcon'
//import FlatButton from 'material-ui/FlatButton'
//import Divider from 'material-ui/Divider'
import Drawer from 'material-ui/Drawer'
import CircularProgress from 'material-ui/CircularProgress'
import {Link} from 'react-router-dom'

import {Dimensions} from '@services/styles'

const styles = {
    drawerHandle: {
        cursor: 'pointer',
        position: 'absolute',
        right: 0,
        top: 4,
        backgroundColor: 'rgb(239, 239, 239)',
        width: 40,
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow:
            'rgba(0, 0, 0, 0.16) 4px 0 8px, rgba(0, 0, 0, 0.16) 4px 0px 8px',
    },
}

class ManageDrawer extends Component {
    state = {
        open: false,
    }

    render() {
        const width = this.props.width || 256

        return (
            <div
                style={{
                    position: 'fixed',
                    top:
                        Dimensions.NAVBAR_TOP_OFFSET +
                        Dimensions.APP_BAR_HEIGHT,
                    bottom: 0,
                }}>
                <Drawer
                    open={this.props.open}
                    width={width}
                    containerStyle={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                    }}>
                    {this.props.loading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 30,
                            }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <List>
                            <Link
                                to="/manage/accounts"
                                style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                }}>
                                <div
                                    style={{
                                        padding: 20,
                                        background:
                                            this.props.mode === 'accounts'
                                                ? '#e0e0e0'
                                                : '#fff',
                                    }}>
                                    Accounts
                                </div>
                            </Link>

                            <Link
                                to="/manage/users"
                                style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                }}>
                                <div
                                    style={{
                                        padding: 20,
                                        background:
                                            this.props.mode === 'users'
                                                ? '#e0e0e0'
                                                : '#fff',
                                    }}>
                                    Users
                                </div>
                            </Link>

                            <Link
                                to="/manage/profiles"
                                style={{textDecoration: 'none', color: 'black'}}
                                disabled>
                                <div
                                    style={{
                                        padding: 20,
                                        background:
                                            this.props.mode === 'profiles'
                                                ? '#e0e0e0'
                                                : '#fff',
                                    }}>
                                    Profiles
                                </div>
                            </Link>

                            <Link
                                to="/manage/analysts"
                                style={{textDecoration: 'none', color: 'black'}}
                                disabled>
                                <div
                                    style={{
                                        padding: 20,
                                        background:
                                            this.props.mode === 'analysts'
                                                ? '#e0e0e0'
                                                : '#fff',
                                    }}>
                                    Analysts
                                </div>
                            </Link>
                        </List>
                    )}
                </Drawer>
            </div>
        )
    }
}

// ManageDrawer.propTypes = {
//   open: PropTypes.bool.isRequired,
//   width: PropTypes.number,
//   loading: PropTypes.bool
// };

export default ManageDrawer
