import React from 'react'
import styled from 'styled-components'
import {useState, useEffect, useUser, useRef} from '@services/hooks'
import {PageContent} from './Home.styled'
import {api} from '@services/api'
import Positions from './MyFile/Positions'
//import AddEditPosition from './MyFile/AddEditPosition'
import BulkAddPositions from './MyFile/BulkAddPositions'
import BulkClosePositions from './MyFile/BulkClosePositions'
import AddBudget from './MyFile/AddBudget'
import MonitorSettings from './MyFile/MonitorSettings'
import FileLineChart from '@components/General/FileLineChart'
import Notifications from './MyFile/Notifications'
import EmptyList from '@components/General/EmptyList'
import Totals from './MyFile/Totals'
import FundsHistory from './MyFile/FundsHistory'
import Swal from 'sweetalert2'

//import Select from 'react-select'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'
// import {ReactComponent as ChevOpen} from '@images/chevron_open.svg'
import {ReactComponent as NotiRedDot} from '@images/red_dot.svg'
import {ReactComponent as AddBudgetIcon} from '@images/add_budget.svg'
import {ReactComponent as AddSymbol} from '@images/add.svg'
import {ReactComponent as Settings} from '@images/settings.svg'
import Analytics from '@services/analytics'
import moment from 'moment'

//import Badge from '@mui/material/Badge'

import HorizontalStack from '@components/General/HorizontalStack'
import {
    FormTopRow,
    HelpLink,
    IconButton,
    SendButton2,
    SendButton2Dis,
} from '@components/General/Elements'
import CircularProgress from 'material-ui/CircularProgress'

import {AppColors} from '@services/styles'
import {
    FileDownloadOutlined,
    NotificationsNoneOutlined,
} from '@mui/icons-material'

const MyFile = () => {
    const user = useUser()
    const [myFile, setMyFile] = useState(null)
    const [needRefresh, setNeedRefresh] = useState(true)
    const [addPosDialog, setAddPosDialog] = useState(false)
    const [closePosDialog, setClosePosDialog] = useState(false)
    const [addBudgetDialog, setAddBudgetDialog] = useState(false)
    const [monitorDialog, setMonitorDialog] = useState(false)
    const [anchorNoti, setAnchorNoti] = useState(null)
    const openNoti = Boolean(anchorNoti)

    // next 3 are set by positions control. they are returned by sever for both open and history
    // values are calculated for open OR close and were shown in header here.
    // BUT in new design i think we need them only for open as the boxes dont change(?)
    // for now do not update for close on positions control (done)
    // it is ui issue, if they say otherwise than maybe hold double sets of them because we need total-open always

    // const [totalOpen, setTotalOpen] = useState('')
    // const [refreshList, setRefreshList] = useState(false)
    // const [refreshLineChart, setRefreshLineChart] = useState(false)
    // const [refreshPieChart, setRefreshPieChart] = useState(false)
    // const [refreshAllCharts, setRefreshAllCharts] = useState(false)
    const [noFile, setNoFile] = useState(false) //  use this so while loading will not show no-file case
    const [hasNewNoti, setHasNewNoti] = useState(false)
    const [selected, setSelected] = useState([])
    const [lastMonthNoti, setLastMonthNoti] = useState([])

    //===========================================
    // TODO: 13.12.23 added portfolio_id to user object
    // so now can load some of the elements beforE done loadPortfolio which takes time...
    // but this is not managed, there is no deleting and updateing new field (it comes with login)
    // changing elements here make loading less elegant (loaders and so...) but much faster
    //===========================================
    useEffect(() => {
        if (needRefresh) {
            loadPortfolio()
            setNeedRefresh(false)
            console.log('afterdelete needRefresh')
        }
    }, [needRefresh])

    const loadPortfolio = () => {
        // setRefreshAllCharts(false)
        getUserPortfolio().then(
            (response) => {
                console.log('loadp user portfolio response', response)
                if (!response.list || response.list.length === 0) {
                    console.log('loadp user portfolio non')
                    setNoFile(true)
                } else {
                    // loadp 1
                    setMyFile(response.list[0])
                    // setRefreshList(true)
                }
            },
            (error) => {
                console.log('user portfolio error', error)
            },
        )
    }

    const getUserPortfolio = () => {
        return new Promise((resolve, reject) => {
            api.getUserPortfolios(user.user_id).subscribe(
                (response) => {
                    resolve(response.data)
                },
                (error) => {
                    console.log('loadp_getUserPortfolio error', error)
                    reject(error)
                },
            )
        })
    }

    const getExtras = () => {
        api.getUserPortfolioDayPl(myFile.id).subscribe(
            (response) => {
                console.log('getExtras response', response)
                if (response.data?.extras) {
                    const {last_day_pl, last_day_pl_per, unit_price} =
                        response.data.extras

                    setMyFile({
                        ...myFile,
                        last_day_pl,
                        last_day_pl_per,
                        unit_price,
                    })
                }
            },
            (error) => {
                console.log('getExtras error', error)
            },
        )
    }

    useEffect(() => {
        if (myFile) {
            console.log('getExtras file', myFile)
            checkNoti(myFile.id)
            if (myFile.last_day_pl === 0) getExtras()

            // false is returned with myFile on laod, 0 is a legit value
            if (myFile.sharp === false) getSharp()

            Analytics.exec('track', 'portfolio page')
        }
    }, [myFile])

    const checkNoti = (pId) => {
        if (!hasNewNoti) {
            api.getUserPortfolioLastMonthNotifications(pId).subscribe(
                (response) => {
                    if (
                        response.data &&
                        response.data.data &&
                        response.data.data.length
                    ) {
                        // for now we assume there are allways. we only check if new for red dot
                        // so if there are not any then will not show dot but will open empty list
                        // can fix... but not very important..
                        if (response.data.data.some((noti) => !noti.pop))
                            setHasNewNoti(true)
                        setLastMonthNoti(response.data.data)
                    }
                },
                (error) => {
                    console.log('get notifications error', error)
                },
            )
        }
    }

    const download = () => {
        if (myFile) api.downloadUserPortfolio(myFile.id)
    }

    const showNotiPop = (e) => {
        setAnchorNoti(e.currentTarget)
    }

    const deletePotfolio = () => {
        Swal.fire({
            //title: 'Delete sector ',
            title: 'Reset Portfolio ?',
            text: 'This action will delete all positions and history and funds',
            showCancelButton: true,
            confirmButtonColor: AppColors.TIPIGO_GREEN,
            confirmButtonText: 'Delete',
        }).then((response) => {
            if (response && response.isConfirmed) {
                api.deleteAllPortfolioData(myFile.id).subscribe(
                    (response) => {
                        console.log('delete_portfolio', response)
                        setNoFile(true)
                        setMyFile(null)
                        setNeedRefresh(true)
                        setMonitorDialog(false)
                    },
                    (error) => {
                        console.log(error)
                    },
                )
            }
        })
    }

    const getSharp = () => {
        api.getUserPortfolioSharpRatio(myFile.id).subscribe(
            (response) => {
                console.log('got_sharp', response)
                const s = response?.data?.sharp ? response.data.sharp : 0
                setMyFile({...myFile, sharp: s})
            },
            (error) => {
                console.log(error)
                setMyFile({...myFile, sharp: 0})
            },
        )
    }

    const deletePositions = () => {
        console.log('before delete ', selected, myFile?.last_deposite)
        const cannotDelete = selected
            .filter(
                (pos) =>
                    moment(pos.entry_date.date).format('YYYY-MM-DD') <
                    myFile.last_deposite,
            )
            .map((s) => s.symbol)

        var text = 'Delete positions? data cannot be restored.'
        if (cannotDelete.length) {
            if (cannotDelete.length === selected.length) {
                Swal.fire({
                    text: 'Cannot delete these Positions as they were opened before the last deposit.',
                    icon: 'error',
                    confirmButtonColor: AppColors.TIPIGO_GREEN,
                })
                return
            }
            text +=
                '<br/> Following Positions cannot be deleted as they were opened before the last deposit: ' +
                cannotDelete.join(', ')
        }
        Swal.fire({
            title: 'Delete positions?',
            html: text,
            confirmButtonColor: AppColors.TIPIGO_GREEN,
            showCancelButton: true,
        }).then((response) => {
            if (response && response.isConfirmed) {
                const canDelete = selected
                    .filter((s1) => !cannotDelete.includes(s1.symbol))
                    .map((s2) => s2.id)
                api.deleteUserPortfolioPosition(canDelete).subscribe(
                    (response) => {
                        if (response.data && response.data.status) {
                            setNeedRefresh(true)
                        }
                    },
                )
            }
        })
    }
    // useEffect(() => {
    //     // loadp 2 - ok. false on laoding and then true
    //     console.log('refreshAllCharts', refreshAllCharts)
    //     // setRefreshLineChart(refreshAllCharts)
    //     // setRefreshPieChart(refreshAllCharts)
    //     console.log('afterdelete refreshAllCharts')
    // }, [refreshAllCharts])

    const portfolioId = myFile?.id || user?.portfolio_id

    return (
        <PageContent>
            {myFile && (
                <Notifications
                    open={openNoti}
                    setAnchor={setAnchorNoti}
                    anchor={anchorNoti}
                    pId={myFile.id}
                    setHasNewNoti={setHasNewNoti}
                />
            )}
            <Container>
                <FormTopRow style={{alignItems: 'end'}}>
                    <TitleCont0>
                        <TitleCont1>
                            {myFile?.last_day_pl ? <DayT>DAILY P&L</DayT> : ''}
                            {
                                <TitleCont2>
                                    <DayM>
                                        {myFile?.last_day_pl
                                            ? myFile.last_day_pl.toLocaleString(
                                                  'en-US',
                                                  {
                                                      style: 'currency',
                                                      currency: 'USD',
                                                  },
                                              )
                                            : ''}
                                    </DayM>
                                    <DayP val={myFile?.last_day_pl_per}>
                                        {myFile?.last_day_pl_per
                                            ? (myFile?.last_day_pl_per > 0
                                                  ? '+'
                                                  : '') +
                                              (
                                                  myFile?.last_day_pl_per * 100
                                              ).toFixed(2) +
                                              '%'
                                            : ''}
                                    </DayP>
                                </TitleCont2>
                            }
                        </TitleCont1>
                        {myFile &&
                            myFile.sharp !== 0 &&
                            myFile.sharp !== false && (
                                <>
                                    <TitleCont1
                                        style={{
                                            marginLeft: 22,
                                            width: 22,
                                            borderLeft:
                                                '0.5px solid ' +
                                                AppColors.APP_GRAY,
                                        }}
                                    />
                                    <TitleCont1>
                                        <DayT>SHARP RATIO</DayT>
                                        <TitleCont2 style={{flex: 1}}>
                                            <DayP style={{display: 'flex'}}>
                                                {(myFile.sharp > 0 ? '+' : '') +
                                                    myFile.sharp.toFixed(2)}
                                            </DayP>
                                        </TitleCont2>
                                    </TitleCont1>
                                </>
                            )}
                    </TitleCont0>

                    <FileGeneral>
                        <CloseButtonCont>
                            {selected?.length ? (
                                <>
                                    <ClosePosButton
                                        style={{marginRight: 15}}
                                        onClick={() => setClosePosDialog(true)}>
                                        Close
                                    </ClosePosButton>
                                    <ClosePosButton onClick={deletePositions}>
                                        Delete
                                    </ClosePosButton>
                                </>
                            ) : (
                                <></>
                                // <ClosePosButtonDis>Close Positions</ClosePosButtonDis>
                            )}
                        </CloseButtonCont>

                        <IconButton>
                            <AddBudgetIcon
                                onClick={() => setAddBudgetDialog(true)}
                            />
                        </IconButton>
                        <IconButton>
                            <AddSymbol
                                style={{stroke: '#333333'}}
                                onClick={() => setAddPosDialog(true)}
                            />
                        </IconButton>
                        <IconButton>
                            <FileDownloadOutlined onClick={download} />
                        </IconButton>

                        <IconButton
                            onClick={() => myFile && setMonitorDialog(true)}>
                            <Settings />
                        </IconButton>

                        <IconButton
                            onClick={showNotiPop}
                            style={{position: 'relative'}}>
                            {hasNewNoti && <RedDot />}
                            <NotificationsNoneOutlined />
                        </IconButton>
                    </FileGeneral>
                </FormTopRow>
                {myFile ? (
                    <Totals
                        plOpenPer={myFile?.open_pl_per}
                        plShortPer={myFile?.short_pl_per}
                        plOpenLongMoney={myFile?.open_pl_money}
                        plOpenShortMoney={myFile?.short_pl_money}
                        cashPool={myFile?.current_cash}
                        openLongTotal={myFile?.open_total}
                        totalInvest={myFile?.total_invest}
                        unitPrice={myFile?.unit_price}
                        openShortTotal={myFile?.pos_short_total}
                    />
                ) : (
                    !noFile && (
                        <CircularProgress
                            style={{marginTop: 100, alignSelf: 'center'}}
                        />
                    )
                )}
                {portfolioId && (
                    <>
                        <HorizontalStack
                            title={'Portfolio Sectors Allocation'}
                            pId={portfolioId}
                            refreshChart={needRefresh} //{refreshPieChart}
                            //setRefreshChart={setRefreshPieChart}
                        />
                        <div style={{height: 20}} />

                        <Positions
                            pId={portfolioId}
                            open={true}
                            // refreshList={refreshList}
                            // setRefreshList={setRefreshList}
                            setNeedRefresh={setNeedRefresh}
                            needRefresh={needRefresh}
                            selected={selected}
                            setSelected={setSelected}
                            lastDeposite={myFile?.last_deposite}
                            lastMonthNoti={lastMonthNoti}
                        />

                        <div style={{height: 26}} />
                        <FileLineChart
                            fileId={portfolioId}
                            refreshChart={needRefresh} //{refreshLineChart}
                            // setRefreshChart={setRefreshLineChart}
                            userId={user.user_id}
                        />

                        <FundsHistory
                            pId={portfolioId}
                            needRefresh={needRefresh}
                        />

                        <TitleRow>Closed positions</TitleRow>
                        <Positions
                            pId={portfolioId}
                            open={false}
                            // refreshList={refreshList}
                            needRefresh={needRefresh}
                        />
                    </>
                )}

                {!myFile && noFile && (
                    <EmptyList
                        type={'newFile'}
                        setAddBudgetDialog={setAddBudgetDialog}
                    />
                )}
                {myFile && (
                    <>
                        <BulkAddPositions
                            userId={user.user_id}
                            pId={myFile.id}
                            isOpenDialog={addPosDialog}
                            setIsOpenDialog={setAddPosDialog}
                            setNeedRefresh={setNeedRefresh}
                            currentCash={myFile.current_cash}
                            minDate={myFile.last_deposite}
                        />
                        <BulkClosePositions
                            userId={user.user_id}
                            pId={myFile.id}
                            isOpenDialog={closePosDialog}
                            setIsOpenDialog={setClosePosDialog}
                            setNeedRefresh={setNeedRefresh}
                            selected={selected}
                            minDate={myFile.last_deposite}
                        />
                    </>
                )}

                <AddBudget
                    pId={portfolioId || null}
                    isOpenDialog={addBudgetDialog}
                    setIsOpenDialog={setAddBudgetDialog}
                    setNeedRefresh={setNeedRefresh}
                    userId={user ? user.user_id : null}
                />
                <MonitorSettings
                    pId={portfolioId || null}
                    isOpenDialog={monitorDialog}
                    setIsOpenDialog={setMonitorDialog}
                    deletePotfolio={deletePotfolio}
                />
            </Container>
            <HelpLink linkSuffix={'#portfolio'} />
        </PageContent>
    )
}

export default MyFile

const Container = styled.div`
    display: flex;
    width: 1400px;
    font-family: 'Inter';
    flex-direction: column;
    border: 0px solid red;
`
const TitleRow = styled.div`
    margin-top: 30px;
    margin-bottom: 15px;
    padding-left: 18px;
    font-size: 17px;
    font-weight: 700;
`

const TitleCont0 = styled.div`
    display: flex;
`
const TitleCont1 = styled.div`
    display: flex;
    flex-direction: column;
`
const TitleCont2 = styled.div`
    display: flex;
`
const DayT = styled.div`
    font-size: 13px;
    color: ${AppColors.BLACK};
    margin-bottom: 8px;
`
const DayM = styled.div`
    font-size: 34px;
    line-height: 33px;
    margin-right: 15px;
    align-self: end;
`
const DayP = styled.div`
    font-size: 21px;
    align-self: end;
    color: ${(p) =>
        p.val
            ? p.val > 0
                ? AppColors.TIPIGO_GREEN
                : AppColors.RED_TEXT
            : AppColors.BLACK};
`

const FileGeneral = styled.div`
    display: flex;
    align-items: baseline;
`
const RedDot = styled(NotiRedDot)`
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 9;
    width: 8px;
    height: 8px;
`
const ClosePosButton = styled(SendButton2)`
    height: 37px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 6px;
`
const ClosePosButtonDis = styled(SendButton2Dis)`
    height: 37px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 6px;
`
const CloseButtonCont = styled.div`
    align-self: center;
    display: flex;
    flex-direction: row;
`

//  padding-top: 5px;
//     padding-bottom: 5px;
//     padding-left: 30px;
//     padding-right: 30px;
