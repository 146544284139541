import React, {Component, PropTypes} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import TextField from 'material-ui/TextField'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import Chip from 'material-ui/Chip'
import DataService from '@services/data'
import BaseEdit from './BaseEdit'
import DateRange from '@components/General/DateRange'
import {useState} from '@services/hooks'
import styled from 'styled-components'
import {AppColors} from '@services/styles'
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
//import FormLabel from '@mui/material/FormLabel'

const styles = {
    chip: {
        margin: 4,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}

class EditAccount extends Component {
    constructor(props) {
        super(props)

        this.state = {}

        this.itemForm = this.itemForm.bind(this)
        this.updateAccount = this.updateAccount.bind(this)
    }
    // const d = new Date()
    // const [validDate, setValidDate] = useState(new Date())

    componentDidMount() {
        this.props.fetchData()
    }

    handleRequestDelete(item, id) {
        console.log('delete', id)

        // let { item } = this.state

        item.linked_profiles = item.linked_profiles.filter((p) => p.id !== id)

        this.setState({item})
    }

    itemForm(item) {
        let {updateItem, profiles} = this.props

        if (!item) {
            return null
        }
        console.log('itemForm', item)
        // if (item.valid_to) {
        //     var wtf = new Date(
        //         item.valid_to,
        //         // TODO: this is awful! and probably wrong...
        //         // we get here from server GMT-2 while tz is GMT-3 where does it happen!?
        //         // so every save will add hour...
        //         // wtf going on here.. this is BS and waist of time
        //         //new Date(item.valid_to),
        //         // .setHours(
        //         //     new Date(item.valid_to).getHours() -
        //         //         new Date().getTimezoneOffset() / 60,
        //         // ),
        //     )
        //     //wtf.setHours(0)
        //     item.valid_to = wtf
        // }
        console.log('itemForm', item)

        let availableMasterProfiles =
            profiles &&
            profiles.filter(
                (p) =>
                    !item.linked_profiles ||
                    item.linked_profiles.length === 0 ||
                    !item.linked_profiles.find((lp) => p.id === lp.il),
            )
        let availableLinkedProfiles =
            profiles &&
            profiles.filter(
                (p) =>
                    !item.master_profile ||
                    item.master_profile.length === 0 ||
                    p.id !== item.master_profile.id,
            )

        return (
            <div>
                <TextField
                    hintText="Name"
                    style={{flex: 3}}
                    value={item ? item.name : ''}
                    onChange={(event, val) => {
                        item.name = val
                        this.setState({item})
                    }}
                    fullWidth
                />
                <DateContainer>
                    <DateRange
                        fromDate={
                            item && item.valid_to
                                ? new Date(item.valid_to)
                                : null
                        }
                        setFromDate={(val) => {
                            item.valid_to = val
                            this.setState({item})
                        }}
                        dateFormat={'MM/dd/yyyy'}
                        style={{width: 178}}
                        oneDate
                        oneDateTitle={'Valid to Date'}
                    />
                    <ValidAllLink
                        onClick={() => {
                            item.valid_to = null
                            item.trail = false
                            this.setState({item})
                        }}>
                        Valid Indefinitely
                    </ValidAllLink>
                    <RadioGroup
                        row
                        value={item && eval(item.trail) ? true : false}
                        onChange={(e, value) => {
                            item.trail = eval(value)
                            this.setState({item})
                        }}
                        style={{marginLeft: 25}}>
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Active"
                        />
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Trail"
                        />
                    </RadioGroup>
                </DateContainer>
                <SelectField
                    floatingLabelText="Set Master Profile"
                    value={
                        item && item.master_profile
                            ? item.master_profile.email
                            : null
                    }
                    onChange={(event, ind, val) => {
                        item.master_profile =
                            ind === 0 ? [] : availableMasterProfiles[ind - 1]
                        this.setState({item})
                    }}
                    fullWidth>
                    <MenuItem value={null} primaryText="" />
                    {item &&
                        availableMasterProfiles &&
                        availableMasterProfiles.map((p) => (
                            <MenuItem
                                key={'mp_' + p.id}
                                value={p.email}
                                primaryText={p.nick_name + ' - ' + p.email}
                            />
                        ))}
                </SelectField>
                <SelectField
                    floatingLabelText="Add Linked Profile"
                    value={null}
                    onChange={(event, ind, val) => {
                        if (!item.linked_profiles) {
                            item.linked_profiles = []
                        }
                        if (
                            !item.linked_profiles.find(
                                (p) => p.id === availableLinkedProfiles[ind].id,
                            )
                        ) {
                            item.linked_profiles.push(
                                availableLinkedProfiles[ind],
                            )
                            this.setState({item})
                        }
                    }}
                    fullWidth>
                    {item &&
                        availableLinkedProfiles &&
                        availableLinkedProfiles.map((p) => (
                            <MenuItem
                                key={'lp_' + p.id}
                                value={p.email}
                                primaryText={p.nick_name + ' - ' + p.email}
                            />
                        ))}
                </SelectField>
                <div style={styles.wrapper}>
                    {item &&
                        item.linked_profiles &&
                        item.linked_profiles.map((p) => (
                            <Chip
                                key={'lpc_' + p.id}
                                onRequestDelete={() =>
                                    this.handleRequestDelete(item, p.id)
                                }
                                onClick={() => console.log('clicked', p)}
                                style={styles.chip}>
                                {p.name + ' - ' + p.email}
                            </Chip>
                        ))}
                </div>
            </div>
        )
    }

    updateAccount(item) {
        this.props.updateItem(item)
    }

    render() {
        let {data, id, isFetching} = this.props
        let {item} = this.state

        return (
            <BaseEdit
                data={data}
                id={id}
                itemForm={this.itemForm}
                mode="account"
                onUpdateItem={this.updateAccount}
                isFetching={isFetching}
                onEditDone={() => (window.location = '/#/manage/accounts')}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.manage.data.accounts,
        profiles: state.manage.data.profiles,
        isFetching: state.manage.isFetching,
    }
}

const mapDispatchToProps = (dispatch) => {
    const ds = new DataService(dispatch)

    return {
        fetchData: () => {
            ds.getAccounts()
            ds.getProfiles()
        },
        updateItem: ds.updateAccount,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAccount)

const DateContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`
const ValidAllLink = styled.div`
    margin-left: 15px;
    color: ${AppColors.TIPIGO_GREEN};
    cursor: pointer;
    font-size: 16px;
`
