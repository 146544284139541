import styled from 'styled-components'
import React from 'react'
import {AppColors} from '@services/styles'

const FrameDiv = ({content, title, style}) => {
    return (
        <FormGroup style={style}>
            <GroupLabel>{title}</GroupLabel>
            <ScrollContent>{content}</ScrollContent>
        </FormGroup>
    )
}

export default FrameDiv

const FormGroup = styled.div`
    padding: 10px;
    padding-top: 12px;
    border: 1px solid ${AppColors.APP_GRAY_LIGHT};
    //margin: 10px;
    border-radius: 8px;
    position: relative;
    display: flex;
    // align-items: center;
`
const GroupLabel = styled.label`
    position: absolute;
    top: -10px;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    background-color: white;
    padding-left: 5px;
    padding-right: 5px;
`
const ScrollContent = styled.div`
    overflow: scroll;
    border: 0px solid pink;
    width: 98%;
`
