//import './AppHome.css'
import hiro from "@images/hiro.png";

const styles = {
  top_header_logo: { height: "64px", backgroundColor: "#FFFFFF" },
  logo: { display: "block", margin: "0 auto", paddingTop: "22px" },
  powered: {
    height: "14px",
    width: "120px",
    color: "#303D4C",
    fontFamily: "Montserrat",
    fontSize: "10px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "14px",
    textAlign: "center",
    display: "block",
    margin: "0 auto",
    paddingLeft: "25px",
  },
  stock_market_forex_trading_gra: {
    height: "362px",
    position: "relative",
    marginRight: "0",
    width: "100%",
    backgroundImage: "url(" + hiro + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
  },
  real_time_feed: {
    height: "98px",
    width: "300px",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
    letterSpacing: "0.2px",
    lineHeight: "30px",
    marginLeft: "27.5px",
    paddingTop: "148.5px",
  },
  buttons_wrapper: { height: "40px", overflow: "auto", marginTop: "17.5px" },
  buttons_holder: {
    height: "40px",
    width: "fit-content",
    display: "block",
    margin: "0 auto",
  },
  subscribe_button: {
    boxSizing: "border-box",
    height: "37px",
    width: "145px",
    border: "1px solid #06B37D",
    backgroundColor: "#06B37D",
    borderRadius: "4px",
    marginLeft: "10px",
    float: "right",
  },
  subscribe_text: {
    height: "19px",
    width: "145px",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "19px",
    textAlign: "center",
    marginTop: "8px",
  },
  allrating_button: {
    boxSizing: "border-box",
    height: "37px",
    width: "145px",
    border: "1px solid #06B37D",
    borderRadius: "4px",
    float: "left",
  },
  allrating_text: {
    height: "19px",
    width: "145px",
    color: "#06B37D",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "19px",
    textAlign: "center",
    marginTop: "8px",
  },
  boxes_wrapper: { height: "151px", overflow: "auto" },
  boxes_holder: {
    height: "151px",
    width: "fit-content",
    display: "block",
    margin: "0 auto",
  },
  tipigo_div: {
    boxSizing: "border-box",
    height: "94px",
    width: "151px",
    border: "1px solid #06B37D",
    borderRadius: "4px",
    marginTop: "32px",
    float: "left",
  },
  tipigo: {
    height: "64px",
    width: "135px",
    color: "#06B37D",
    fontFamily: "Montserrat",
    fontSize: "14px",
    letterSpacing: "0",
    lineHeight: "18px",
    marginTop: "13px",
    marginLeft: "9px",
  },
  tipigo_precent: {
    height: "56px",
    width: "135px",
    color: "#06B37D",
    fontFamily: "Montserrat",
    fontSize: "34px",
    letterSpacing: "0",
    lineHeight: "42px",
  },
  sandp_div: {
    boxSizing: "border-box",
    height: "94px",
    width: "151px",
    border: "1px solid #F8AB2D",
    borderRadius: "4px",
    marginLeft: "19px",
    marginTop: "32px",
    float: "right",
  },
  sandp: {
    height: "64px",
    width: "135px",
    color: "#F8AB2D",
    fontFamily: "Montserrat",
    fontSize: "14px",
    letterSpacing: "0",
    lineHeight: "18px",
    marginTop: "13px",
    marginLeft: "9px",
  },
  sandp_precent: {
    height: "56px",
    width: "135px",
    color: "#F8AB2D",
    fontFamily: "Montserrat",
    fontSize: "34px",
    letterSpacing: "0",
    lineHeight: "42px",
  },
  graph_wrapper: {
    height: "fit-content",
    width: "fit-content",
    display: "block",
    margin: "0 auto",
  },
  graph: { paddingBottom: "34px", width: "362px" },
  how_does_wrapper: {
    height: "430px",
    backgroundColor: "#F4F4F4",
    marginBottom: "33px",
  },

  how_does_bold: {
    height: "122px",
    width: "292px",
    color: "#303D4C",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: "900",
    letterSpacing: "0.21px",
    lineHeight: "30px",
    marginBottom: "14.5px",
    display: "block",
    marginLeft: "25.5px",
    paddingTop: "24.5px",
    paddingBottom: "25px",
  },

  infoTable: {
    marginTop: "46.5px",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#6A6F74",
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "20px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "24px",
  },
  symbols: { paddingRight: "18.5px" },
  table_text: {
    width: "234px",
    color: "#6A6F74",
    fontFamily: "Montserrat",
    fontSize: "19px",
    letterSpacing: "0",
    lineHeight: "24px",
  },
  marged_rows: { paddingBottom: "20px" },
  headers: {
    height: "36px",
    backgroundColor: "#FBFBFB",
    marginTop: "19px",
    marginBottom: "28px",
  },
  headers_font: {
    height: "24px",
    width: "200px",
    color: "#6A6F74",
    fontFamily: "Montserrat",
    fontSize: "21px",
    letterSpacing: "-0.1px",
    lineHeight: "24px",
    marginTop: "5px",
    marginLeft: "27px",
  },
  news_boxes: {
    height: "181px",
    width: "319px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 -1px 8px 0 rgba(0,0,0,0.09)",
    margin: "auto",
    marginBottom: "38px",
  },
  news_par: {
    color: "#273039",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "15px",
    fontWeight: "600",
    letterSpacing: "0",
    lineHeight: "23px",
    paddingLeft: "12px",
    paddingRight: "7px",
    marginTop: "12px",
  },
  news_date: {
    color: "#8698AA",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "12px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "16px",
    paddingLeft: "12px",
    paddingRight: "22px",
    paddingTop: "2px",
  },
  buttons: {
    height: "50px",
    width: "312px",
    borderRadius: "4px",
    backgroundColor: "#06B37D",
    margin: "auto",
    marginBottom: "48px",
  },
  buttons_font: {
    height: "25px",
    width: "200px",
    color: "#FFFFFF",
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "18px",
    fontWeight: "900",
    letterSpacing: "0",
    lineHeight: "25px",
    textAlign: "center",
    margin: "auto",
    paddingTop: "12px",
  },
  ratingTable: {
    position: "relative",
    margin: "auto",
    borderCollapse: "collapse",
    marginBottom: "23px",
    marginTop: "20px",
    paddingLeft: "28px",
    paddingRight: "29px",
  },
  ratingTable_td: { paddingLeft: "28px", paddingRight: "28px" },
  stock_short: {
    height: "30px",
    width: "auto",
    color: "#303D4C",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
    letterSpacing: "0.2px",
    lineHeight: "30px",
    marginTop: "14px",
  },
  stock_full: {
    height: "24px",
    width: "auto",
    color: "#6A6F74",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "14px",
    fontWeight: "300",
    letterSpacing: "0.14px",
    lineHeight: "24px",
  },
  stock_val: {
    height: "24px",
    width: "64px",
    color: "#6A6F74",
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "14px",
    fontWeight: "300",
    letterSpacing: "0.14px",
    lineHeight: "24px",
    textAlign: "right",
  },

  stock_change_neg: {
    height: "24px",
    width: "auto",
    color: "#EB460F",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    letterSpacing: "-0.28px",
    lineHeight: "24px",
    marginTop: "44px",
    marginRight: "28px",
    float: "right",
  },
  stock_change_pos: {
    height: "24px",
    width: "auto",
    color: "#06B37D",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    letterSpacing: "-0.28px",
    lineHeight: "24px",
    marginTop: "44px",
    marginRight: "28px",
    float: "right",
  },

  news_table: { position: "relative", margin: "auto" },
  stock_company: { borderBottom: "1px solid #EBEDEE" },
  newsDiv: {
    position: "relative",
    margin: "auto",
    height: "auto",
    width: "320px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 -1px 8px 0 rgba(0,0,0,0.09)",
    marginBottom: "27px",
    marginTop: "33px",
    paddingBottom: "8px",
  },
  newsItemTable: { borderCollapse: "collapse" },
  newsImg: { height: "175px", width: "320px" },
  rateDiv: { height: "80px", width: "100%", borderBottom: "1px solid #EBEDEE" },
  arrows: { marginBottom: "30px", marginLeft: "8px" },
  stock_names: { display: "inline-block" },
};

export default styles;
