import React, {Component} from 'react'
import {api} from '@services/api'
import {useState, useEffect} from '@services/hooks'
import InsightTable from '@components/General/InsightTable'
import styled from 'styled-components'
import BaseManageTable from './BaseManageTable'

const columns = [
    {
        accessor: 'id',
        Header: 'id',
        Cell: (row) => <div style={{fontSize: 7}}>{row.value}</div>,
    },
    {accessor: 'email', Header: 'Email'},
    {
        accessor: (row) =>
            row.nick_name
                ? row.nick_name
                : (row.first_name ? row.first_name : '') +
                  ' ' +
                  (row.last_name ? row.last_name : ''),
        Header: 'Name',
    },
    //style={{textAlign: 'center', whiteSpace: 'pre-wrap'}}
    {
        accessor: 'accounts',
        Header: 'accounts',
        Cell: (row) => (
            <div>{row.value ? row.value.map((lp) => lp).join(', ') : null}</div>
        ),
    },
]

const ProfilesTable = () => {
    const [tableData, setTableData] = useState(null)

    useEffect(() => {
        api.getProfiles().subscribe(
            (response) => {
                console.log('getProfiles ', response.data)
                setTableData(response.data.data)
            },
            (error) => {
                console.log('getProfiles error', error)
            },
        )
    }, [])

    const applySearchFilter = (searchText) => {
        return tableData.filter(
            (d) =>
                d.id.indexOf(searchText) !== -1 ||
                d.email.toLowerCase().indexOf(searchText.toLowerCase()) !== -1,
        )
    }

    return (
        <Container>
            {tableData && (
                // <InsightTable
                //     columns={columns}
                //     data={tableData}
                // />
                <BaseManageTable
                    mode="profile"
                    columns={columns}
                    searchFilter={applySearchFilter}
                    data={tableData}
                />
            )}
        </Container>
    )
}

export default ProfilesTable

const Container = styled.div`
    dispaly: flex;
    flex: 1;
    margin-top: 20px;
`
