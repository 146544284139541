import React from 'react'
import {useState, useEffect} from '@services/hooks'
import {AppColors} from '@services/styles'
import styled from 'styled-components'
import FrameSelect from '@components/General/FrameSelect'
import AlertBox from '@components/General/AlertBox'
import CircularProgress from 'material-ui/CircularProgress'
import {api} from '@services/api'
import {ReactComponent as InfoIcon} from '@images/info.svg'
import EmptyList from '@components/General/EmptyList'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

const gradeFilters = {
    'A+': 6,
    A: 5,
    'A-': 4,
    'B+': 3,
    B: 2,
    'B-': 1,
    'C+': 0,
    // All:  not the same as C+ because allows null
}

const StockRatings = ({symbol}) => {
    const [recommendations, setRecommendations] = useState([])
    const [filteredList, setFilteredList] = useState([])
    // const [teamOnly, setTeamOnly] = useState('All')
    const teamOnly = 'All'
    const [type, setType] = useState('All')
    const [grade, setGrade] = useState('All')
    const [loading, setLoading] = useState(true)
    const [consensusNum, setConsensusNum] = useState('')
    const [consensusTarget, setConsensusTarget] = useState('')
    const [numMonths, setNumMonths] = useState(3)

    useEffect(() => {
        if (symbol) {
            console.log('getLastSymbolRecommendations got ', symbol)
            getLastSymbolRecommendations()
        }
    }, [symbol])

    const getLastSymbolRecommendations = () => {
        api.getLastSymbolRecommendations_new(symbol).subscribe(
            (response) => {
                if (response?.data?.last_recommendations) {
                    console.log('getLastSymbolRecommendations', response.data)
                    setRecommendations(response.data.last_recommendations)
                    setConsensusNum(response.data?.count_buy)
                    if (response.data?.consensus_target)
                        setConsensusTarget(
                            response.data.consensus_target.toLocaleString(
                                'en-US',
                                {
                                    style: 'currency',
                                    currency: 'USD',
                                },
                            ),
                        )
                } else {
                    console.log(
                        'getLastSymbolRecommendations wrong data',
                        response,
                    )
                }
                setLoading(false)
            },
            (error) => {
                console.log('getLastSymbolRecommendations error', error)
                setLoading(false)
            },
        )
    }

    useEffect(() => {
        const t = type.toLowerCase()
        const dateToFilter = moment().subtract(numMonths, 'months')

        setFilteredList(
            recommendations.filter(
                (o) =>
                    (teamOnly === 'All' ||
                        (o.team && teamOnly === 'Yes') ||
                        (!o.team && teamOnly === 'No')) &&
                    (t === 'all' || o.rating === t) &&
                    (grade === 'All' ||
                        gradeFilters[o.grade] >= gradeFilters[grade]) &&
                    moment
                        .unix(o.security_created_timestamp)
                        .isAfter(dateToFilter),
            ),
        )
    }, [recommendations, teamOnly, type, grade, numMonths])

    const renderRecommendationBox = () => {
        return (
            <div style={{marginTop: 40}}>
                {filteredList.map((rec, i) => {
                    // let isFromTeam = false
                    // let color =
                    //     rec.rating === 'buy'
                    //         ? Colors.green400
                    //         : Colors.red400
                    // let description =
                    //     rec.description ||
                    //     `Investment opportunity by ${rec.analyst}.<br/>Original message not available.`

                    return <AlertBox i={i} rec={rec} key={`rec${i}`} />
                })}
            </div>
        )
    }

    return (
        <Container>
            <ReactTooltip id="stock-rating-path" html={true} />
            <Title>Analysts' Ratings</Title>
            {(recommendations.length !== 0 || loading) && (
                <>
                    <ConsensusBox>
                        <HeadBox>
                            <BoxTitle>
                                Analysts Consensus
                                <InfoIcon
                                    style={{marginLeft: 11}}
                                    data-tip="Number of Buy ratings over the last 3 months"
                                    data-for="stock-rating-path"
                                    width={18}
                                    height={18}
                                />
                            </BoxTitle>
                            <BoxNumber>{consensusNum}</BoxNumber>
                        </HeadBox>
                        <BoxSeparator />

                        <HeadBox>
                            <BoxTitle>
                                Analysts target price
                                <InfoIcon
                                    style={{marginLeft: 11}}
                                    data-tip="Calculated based on analyst ratings data over the last 3 months, with a higher weighting given to the most recent period than previous, older periods."
                                    data-for="stock-rating-path"
                                    width={18}
                                    height={18}
                                />
                            </BoxTitle>
                            <BoxNumber>{consensusTarget}</BoxNumber>
                        </HeadBox>
                    </ConsensusBox>
                    <Head>
                        {/* <FrameSelect
                            title={'Analyst in Team'}
                            values={['All', 'Yes', 'No']}
                            value={teamOnly}
                            setValue={setTeamOnly}
                            style={{flex: 1, marginRight: 25}}
                        /> */}
                        <FrameSelect
                            title={'Type'}
                            values={['All', 'Buy', 'Sell']}
                            value={type}
                            setValue={setType}
                            style={{flex: 1, marginRight: 25}}
                        />
                        <FrameSelect
                            title={'Min Grade'}
                            values={[
                                'All',
                                'A+',
                                'A',
                                'A-',
                                'B+',
                                'B',
                                'B-',
                                'C+',
                            ]}
                            value={grade}
                            setValue={setGrade}
                            style={{flex: 1, marginRight: 25}}
                        />
                        <FrameSelect
                            title={'Time'}
                            values={[
                                {label: 'Last month', value: 1},
                                {label: 'Last 2 months', value: 2},
                                {label: 'Last 3 months', value: 3},
                                {label: 'Last 6 months', value: 6},
                                {label: 'Last Year', value: 12},
                            ]}
                            value={numMonths}
                            setValue={setNumMonths}
                            style={{flex: 1.4}}
                        />
                    </Head>
                </>
            )}
            {filteredList.length === 0 && loading && (
                <CircularProgress
                    style={{marginTop: 50, alignSelf: 'center'}}
                    color={AppColors.TIPIGO_GREEN}
                />
            )}
            {filteredList.length === 0 && !loading && (
                <EmptyList
                    type={
                        recommendations.length === 0 ? 'stock' : 'stock_filter'
                    }
                />
            )}
            {renderRecommendationBox()}
        </Container>
    )
}

export default StockRatings

const Title = styled.div`
    font-size: 21px;
    font-weight: 700;
`
const Head = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-top: 20px;
    border: 0px solid green;
`
const ConsensusBox = styled.div`
    margin-top: 20px;
    border: 1px solid ${AppColors.APP_GRAY_LIGHT};
    display: flex;
    padding: 14px;
`
const HeadBox = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0px solid green;
`
const BoxSeparator = styled.div`
    border-left: 1px solid ${AppColors.APP_GRAY_LIGHT};
    width: 0px;
`
const BoxTitle = styled.div`
    font-size: 16px;
    border: 0px solid green;
    display: flex;
    align-items: center;
`
const BoxNumber = styled.div`
    margin-top: 14px;
    font-size: 24px;
`
// const NoRatings = styled(Head)`
//     font-size: 19px;
//     font-weight: 400;
// `
const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 35px;
    font-family: 'Inter';
    color: ${AppColors.BLACK};
`
