import React, {Component, PropTypes} from 'react'
import FlatButton from 'material-ui/FlatButton'
import Dialog from 'material-ui/Dialog'
import Checkbox from 'material-ui/Checkbox'
import {useState, useEffect} from '@services/hooks'

const ItemsDialog = ({
    preSelected,
    handleClose,
    options,
    title,
    open,
    submit,
}) => {
    const [selectedItems, setSelectedItems] = useState({...preSelected})
    const [rightSide, setRightSide] = useState([])
    const [leftSide, setLeftSide] = useState([])
    //const [locked, setLocked] = useState(false)
    console.log('selectedItems', selectedItems)
    // constructor(props) {
    //     super(props)
    //     // this.state = {
    //     //     //selectedItems: Object.assign({}, this.props.selectedItems),
    //     //     //locked: false,
    //     // }
    // }
    // componentDidMount() {
    //     this.setState({locked: false})
    // }

    useEffect(() => {
        // any changes made in parent control, like delete
        setSelectedItems({...preSelected})
    }, [preSelected])

    const updateSelectedItems = (itemName, selected) => {
        let newSelectedItems = {...selectedItems}
        newSelectedItems[itemName] = selected
        setSelectedItems(newSelectedItems)
        // at this point selectedItems != preSelected

        //setLocked(true)
        // let selectedItems = this.state.selectedItems
        // selectedItems[itemName] = selected
        // this.setState({selectedItems, locked: true})
    }

    // ? what was it for ?
    // componentWillReceiveProps(nextProps) {
    //     if (!this.state.locked) {
    //         this.setState({
    //             selectedItems: Object.assign({}, nextProps.selectedItems),
    //         })
    //     }
    // }

    const onDone = () => {
        console.log('before submit', selectedItems)
        //setLocked(false)
        submit(selectedItems)
        handleClose()

        // this.setState({locked: false}, () => {
        //     this.props.submit(this.state.selectedItems)
        //     this.props.handleClose()
        // })
    }

    useEffect(() => {
        // init display only after selectedItems is ready
        if (selectedItems) {
            console.log('start', selectedItems)
            let _right = options.slice(0) //this.props.options.slice(0)
            let half = Math.ceil(_right.length / 2)
            let _left = _right.splice(0, half)
            setRightSide(_right)
            setLeftSide(_left)
        }
    }, [selectedItems])

    const actions = [
        <FlatButton
            label="Cancel"
            primary={true}
            onClick={() => {
                // reset it for next open
                setSelectedItems({...preSelected})
                handleClose()
            }}
        />,
        <FlatButton
            label="Submit"
            primary={true}
            keyboardFocused={true}
            onClick={onDone}
        />,
    ]

    return (
        <Dialog
            title={title}
            actions={actions}
            modal={true}
            open={open}
            onRequestClose={handleClose}>
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                    {leftSide.length &&
                        leftSide.map((item, i) => (
                            <Checkbox
                                disabled={preSelected[item]}
                                checked={selectedItems[item]}
                                onCheck={(event, isInputChecked) => {
                                    updateSelectedItems(item, isInputChecked)
                                }}
                                key={'item_a_' + i}
                                label={item}
                            />
                        ))}
                </div>
                <div style={{flex: 1}}>
                    {rightSide.length &&
                        rightSide.map((item, i) => (
                            <Checkbox
                                disabled={preSelected[item]}
                                checked={selectedItems[item]}
                                onCheck={(event, isInputChecked) => {
                                    updateSelectedItems(item, isInputChecked)
                                }}
                                key={'item_a_' + i}
                                label={item}
                            />
                        ))}
                </div>
            </div>
        </Dialog>
    )
}

// ItemsDialog.propTypes = {
//   title: PropTypes.string.isRequired,
//   submit: PropTypes.func.isRequired,
//   handleClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   selectedItems: PropTypes.object,
//   options: PropTypes.array.isRequired
// };

export default ItemsDialog
