import React from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import Tooltip from './Tooltip'
import ActionStars from 'material-ui/svg-icons/action/grade'
import _ from 'lodash'
import Analytics from '@services/analytics'
import {AppColors} from '@services/styles'
import * as ApiActions from '@store/actions/api-actions'
import {yellow600} from 'material-ui/styles/colors'
import Api from '@services/api'

const UNStyledIcon = ({isExist, isBullish, ...props}) => (
    <ActionStars {...props} />
)
const Icon = styled(UNStyledIcon)`
    margin: 0px 6px -3px 6px;
    width: 26px;
    height: 26px;
    fill: ${(p) => (p.isExist ? yellow600 : '#fff')} !important;
    stroke: ${(p) => (p.isExist ? yellow600 : AppColors.APP_GRAY)} !important;
    cursor: pointer;
    :hover {
        fill: ${(p) => (p.isExist ? '#fff' : yellow600)} !important;
        stroke: #666 !important;
    }
`

export const WatchlistIcon = (props) => (
    <Tooltip
        text={props.isExist ? 'Remove from watchlist' : 'Add to watchlist'}>
        <Icon isExist={props.isExist} {...props} />
    </Tooltip>
)

class WatchlistIcn extends React.Component {
    state = {
        isWatched: false,
    }
    componentDidMount() {
        this.setState({isWatched: this._isInWatchlist()})
    }
    _isInWatchlist = () => {
        const {symbol, watchlist} = this.props
        if (watchlist) {
            let exsitingPos = _.find(watchlist, (pos) => {
                return symbol.toUpperCase() === pos.symbol.toUpperCase()
            })
            return exsitingPos ? true : false
        }
    }
    _onClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const {symbol} = this.props
        Analytics.exec('track', 'add to watclist')
        const {isWatched} = this.state
        this.props.toggleWatchlist(!isWatched, symbol).then()
        this.setState({isWatched: !isWatched})
    }
    componentWillReceiveProps(nextProps) {
        this.setState({isWatched: this._isInWatchlist()})
    }

    render() {
        const {isWatched} = this.state
        return <WatchlistIcon onClick={this._onClick} isExist={isWatched} />
    }
}

const mapStateToProps = (state) => {
    return {
        watchlist: state.finance.data.followingSymbols,
    }
}
const mapDispatchToProps = (dispatch) => {
    const api = new Api()
    return {
        toggleWatchlist: (shouldAdd, symbol) => {
            return new Promise((resolve, reject) => {
                const action = shouldAdd ? 'followSymbol' : 'unfollowSymbol'
                api[action](symbol).subscribe(
                    (response) => {
                        dispatch(ApiActions.apiUpdateWatchlist(response.data))
                        resolve(response.data)
                    },
                    (error) => {
                        console.warn(error)
                        reject(error)
                    },
                )
            })
        },
    }
}
export const connectedWatchlistIcon = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WatchlistIcn)
