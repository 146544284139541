import React, { Component, PropTypes } from "react";
import Checkbox from "material-ui/Checkbox";
import _ from "lodash";
const styles = {};

class CheckboxList extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    checked: _.zipObject(
      this.props.values.map((v) => v.type),
      this.props.values.map((v) =>
        this.props.selected ? this.props.selected.indexOf(v.type) > -1 : null
      )
    ),
  };

  update = () => {
    this.props.onChange(this.state.checked);
  };

  render() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {this.props.values.map((v, i) => {
          return (
            <Checkbox
              style={{ display: "block", width: 130 }}
              label={v.title}
              key={"cbx_" + i}
              checked={this.state.checked[v.type]}
              onCheck={(event) => {
                let checked = this.state.checked;
                checked[v.type] = checked[v.type] ? false : true;
                this.setState({ checked }, this.update);
              }}
            />
          );
        })}
      </div>
    );
  }
}

// CheckboxList.propTypes = {
//   onChange: PropTypes.func.isRequired,
//   values: PropTypes.array.isRequired,
//   selected: PropTypes.array,
// };

export default CheckboxList;
