import * as types from '../ActionTypes'

const defaultState = {
    isFetching: true,
    initialized: false,
    redirectedOnce: false,
    data: {},
    user: null,
}

const userReducer = (state = defaultState, action) => {
    if (
        [
            'getCurrentUser',
            'login',
            'logout',
            'changeProfileNameDirect',
            'changeProfileDescriptionDirect',
            'updateUserProfile',
            'updateUserFilters',
        ].indexOf(action.method) > -1
    ) {
        switch (action.type) {
            case types.API_REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                })
            case types.API_RESPONSE:
                if (
                    [
                        'changeProfileNameDirect',
                        'changeProfileDescriptionDirect',
                    ].indexOf(action.method) > -1
                ) {
                    state.user = Object.assign({}, state.user, action.data)
                } else {
                    //------------------------
                    if (action.method === 'getCurrentUser') {
                    }
                    //------------------------
                    state.data = action.data
                    if (
                        state.user &&
                        state.user.available_profiles &&
                        state.user.available_profiles.length > 0 &&
                        !state.user.current_profile
                    ) {
                        state.user.current_profile =
                            state.user.available_profiles[0]
                    }
                }
                return Object.assign({}, state, {
                    isFetching: false,
                    //data: action.data,
                    initialized: true,
                })
            case types.API_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    initialized: true,
                })
            case types.UPDATE_USER_CURRENT_PROFILE:
                let stateWithProfile = Object.assign({}, state)
                stateWithProfile.user.current_profile = action.profile
                return stateWithProfile
            case types.UPDATE_USER_CURRENT_FILTERS:
                // happens on change - not on load
                let stateWithFilters = Object.assign({}, state)
                stateWithFilters.user.alert_filters = action.filters
                return stateWithFilters
            default:
                return state
        }
    }

    if (action.type === 'SET_USER') {
        return {
            ...state,
            user: action.user,
            isFetching: false,
            initialized: true,
        }
    }

    if (action.type === types.SET_UNIVERSES) {
        return {
            ...state,
        }
    }

    if (action.type === 'SET_IS_FETCHING') {
        return {
            ...state,
            isFetching: action.isFetching,
        }
    }

    if (action.type === types.ANALYST_LIST_UPDATE) {
        let newState = Object.assign({}, state)
        newState.data.analystList = action.data
        return newState
    }

    if (action.type === types.SET_REDIRECTED_ONCE_FLAG) {
        return Object.assign({}, state, {
            redirectedOnce: action.value,
        })
    }

    if (action.method === 'logout') {
        return Object.assign({}, defaultState)
    }

    return state
}

export default userReducer
