import React, {Component, PropTypes} from 'react'
import {connect} from 'react-redux'
// import FontIcon from 'material-ui/FontIcon'
// import * as Colors from 'material-ui/styles/colors'
// import IconButton from 'material-ui/IconButton'
// import FlatButton from 'material-ui/FlatButton'
// import RaisedButton from 'material-ui/RaisedButton'
// import ChipInput from 'material-ui-chip-input'
// import {Card, CardActions, CardTitle, CardText} from 'material-ui/Card'
// import Subheader from 'material-ui/Subheader'
import _ from 'lodash'
import {withRouter} from 'react-router-dom'

import * as ApiActions from '@store/actions/api-actions'
import Alert from './LiveFeed/Alert'
import Api from '@services/api'

import {PageContent} from './Home.styled'
import HomeDrawer, {initFilters, filterAlerts} from './HomeDrawer'
//import EmptyAlerts from './LiveFeed/EmptyAlerts'
import ReactTooltip from 'react-tooltip'
//import AddEditPosition from './MyFile/AddEditPosition'
import BulkAddPositions from './MyFile/BulkAddPositions'
import {Link} from 'react-router-dom'
import {ReactComponent as Help} from '@images/help.svg'
import {Dimensions} from '@services/styles'
import EmptyList from '@components/General/EmptyList'
import {HelpLink} from '@components/General/Elements'

class LiveFeed extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchDataSource: [],
            alerts: [],
            filters: this.props.user.alert_filters,
            filterTypes: [],
            executingAlert: false,
            addPortfolioDialog: false,
        }
    }

    setOpenPortDialog = (open) => {
        this.setState({addPortfolioDialog: open})
    }
    //handleFilterChange(filters) {}

    render() {
        if (!this.props.alerts) return null

        const alerts = filterAlerts(this.props.alerts, this.state.filters) // not time
        console.log('my_alerts', alerts)
        return (
            <PageContent style={{width: 1270}}>
                <HomeDrawer
                    onFilterChange={(filters) => this.setState({filters})}
                />
                <ReactTooltip />
                <BulkAddPositions
                    isOpenDialog={this.state.addPortfolioDialog}
                    setIsOpenDialog={this.setOpenPortDialog}
                    ticker={this.state.symbo4Portfolio}
                    msgOnSuccess
                />
                <div style={{flex: 1}}>
                    {alerts && alerts.length > 0 ? (
                        _.orderBy(alerts, ['time'], ['desc']).map(
                            (alert, i) => (
                                <Alert
                                    key={`live_feed_alert_${i}`}
                                    alert={alert}
                                    actions={true}
                                    setOpenPortDialog={this.setOpenPortDialog}
                                    setSymbolForDialog={(sym) =>
                                        this.setState({symbo4Portfolio: sym})
                                    }
                                />
                            ),
                        )
                    ) : (
                        // <EmptyAlerts />
                        <EmptyList type="livefeed" />
                    )}
                </div>

                <HelpLink linkSuffix={''} />
            </PageContent>
        )
    }
}

// LiveFeed.propTypes = {
//     gotoWatchlist: PropTypes.func,
//     poll: PropTypes.func

// };

const mapStateToProps = (state) => {
    const fd = state.finance.data
    //console.log('select_p numalerts', fd?.alerts?.length)
    return {
        user: state.user.user,
        alerts: fd.alerts || [],
        sectors: fd.system_sectors || [],
        symbols: fd.symbols || [],
        positions:
            fd.finance_info &&
            fd.finance_info.positions &&
            _.isObject(fd.finance_info.positions)
                ? _.values(fd.finance_info.positions)
                : [],
    }
}

const mapDispatchToProps = (dispatch) => {
    const api = new Api()

    return {
        dismissAlert: (alertId) => {
            dispatch(ApiActions.apiRequest('dismissAlert', alertId))
            api.dismissAlert(alertId).subscribe(
                (response) => {
                    dispatch(ApiActions.apiResponse('dismissAlert', alertId))
                },
                (error) => {
                    dispatch(ApiActions.apiError('dismissAlert', {alertId}))
                },
            )
        },

        executeAlert: (alertId) => {
            dispatch(ApiActions.apiRequest('executeAlert', alertId))
            return new Promise((resolve, reject) => {
                api.executeAlert(alertId).subscribe(
                    (response) => {
                        dispatch(
                            ApiActions.apiResponse('executeAlert', alertId),
                        )
                        resolve(alertId)
                    },
                    (error) => {
                        dispatch(ApiActions.apiError('executeAlert', {alertId}))
                        reject(alertId)
                    },
                )
            })
        },
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(LiveFeed),
)
