import React from 'react'
import styled from 'styled-components'
import {useState, useEffect} from '@services/hooks'
import LineChart from '@components/General/LineChart'
import SelectBenchmarks from '@components/Home/MyFile/SelectBenchmarks'
import DateRangeNoInput from '@components/General/DateRangeNoInput'
import {api} from '@services/api'
// import {Checkbox} from 'material-ui'
import {AppColors} from '@services/styles'
//import {AddCircleOutline, HistoryOutlined} from '@mui/icons-material'
import ReactTooltip from 'react-tooltip'
import swal from 'sweetalert2'
import moment from 'moment'
import {AddBenchmarkButton} from '@components/General/Elements'

const FileLineChart = ({
    fileId,
    refreshChart,
    tickers,
    exposeBenchmarks,
    allowDates = true,
    fromDate = null,
    toDate = null,
    contStyle = {},
    autoFirst = null,
    userId,
}) => {
    const [chartData, setChartData] = useState(null)
    const [lineDateFrom, setLineDateFrom] = useState(fromDate)
    const [lineDateTo, setLineDateTo] = useState(toDate ? toDate : new Date())
    const [lineMinDate, setLineMinDate] = useState(null)
    const [lineBMDialog, setLineBMDialog] = useState(false)
    const [benchmarks, setBenchmarks] = useState(['SPY', 'RSP'])
    const [timeArr, setTimeArr] = useState(['10D', '1M', '3M', '6M', '1Y'])
    const [selectedTime, setSelectedTime] = useState('selection')
    const [chartLoading, setChartLoading] = useState(false)
    const [didLaod, setDidLoad] = useState(false)

    useEffect(() => {
        if (autoFirst) setDidLoad(true)
        console.log('user', userId)
    }, [autoFirst])

    const setCahrtDataAndColors = (allLinesData) => {
        // UI by Keren and Noam: first line (file) always same color, next lines are by the order
        // so benchmark will not get same color every time
        if (allLinesData.datasets) {
            setChartData(allLinesData)
        }
    }
    const loadCahrt = () => {
        // next one couse the chart and bench pop to disappears and reappears
        // but i added it here for a reason...
        //setChartData(null)

        setChartLoading && setChartLoading(1)

        if (fileId) {
            api.getUserPortfolioChart(
                fileId,
                benchmarks,
                lineDateFrom,
                lineDateTo,
            ).subscribe(
                (response) => {
                    console.log('got_chart_data data', response.data)
                    if (response?.data?.chart_data?.datasets?.length) {
                        setCahrtDataAndColors(response.data.chart_data)

                        var first = new Date(response.data.first)
                        if (!lineDateFrom || lineDateFrom < first)
                            setLineDateFrom(first)
                        setLineMinDate(first)
                        var today = new Date()
                        var days =
                            (today.getTime() - first.getTime()) /
                            (1000 * 3600 * 24)
                        if (days > 365)
                            setTimeArr(['10D', '1M', '3M', '6M', '1Y', '5Y'])
                        else if (days > 180)
                            setTimeArr(['10D', '1M', '3M', '6M', '1Y'])
                        else if (days > 90)
                            setTimeArr(['10D', '1M', '3M', '6M'])
                        else if (days > 30) setTimeArr(['10D', '1M', '3M'])
                        else if (days > 10) setTimeArr(['10D', '1M'])
                        else setTimeArr(['10D'])

                        setChartLoading(2)
                    } else {
                        console.log('got_chart data worng')
                        // 28.10.23 replace setChartData by setChartLoading. this is partial. not done
                        setChartLoading(2)
                        //setChartData(false)
                    }
                    setDidLoad(true)
                },
                (error) => {
                    setDidLoad(true)
                    console.log(
                        'got_chart data error',
                        fileId,
                        benchmarks,
                        lineDateFrom,
                        lineDateTo,
                        error,
                    )
                },
            )
        } else if (tickers) {
            // 14.09.23 next is only for display. (server will default to month) should not have any other effect or refresh

            if (lineDateFrom === null) {
                setSelectedTime('1M')
            }
            console.log(
                'get_backtest_data lineDateFrom1',
                lineDateFrom,
                tickers,
                benchmarks,
            )
            api.getBacktestGraphData(
                lineDateFrom,
                lineDateTo,
                tickers,
                benchmarks,
            ).subscribe(
                (response) => {
                    console.log('get_backtest_data', response)
                    if (
                        response.data &&
                        response.data.datasets &&
                        response.data.datasets.length
                    ) {
                        setCahrtDataAndColors(response.data)
                        setChartLoading(2)
                    } else {
                        setChartData(false)
                    }
                    setDidLoad(true)
                },
                (error) => {
                    setDidLoad(true)
                    setChartLoading(0)
                    console.log('get_backtest_data error', error)
                },
            )
        }
    }

    useEffect(() => {
        console.log('lineDateFrom', lineDateFrom, lineDateTo)
        if (didLaod && lineDateFrom !== null && lineDateFrom !== lineDateTo) {
            var days = Math.abs(
                moment(lineDateFrom, 'YYYY-MM-DD').diff(
                    moment(lineDateTo, 'YYYY-MM-DD'),
                    'days',
                ),
            )
            if (days > 1) {
                console.log(
                    'get_chart date change',
                    days,
                    lineDateFrom,
                    lineDateTo,
                )
                loadCahrt()
            } else if (lineDateFrom !== null && lineDateTo !== null) {
                // this was coverd by disableShortRange so it should not happen
                // but keep it here. not sure ALL and ALL
                swal.fire({
                    text: 'Please select a wider date range',
                    icon: 'error',
                    confirmButtonColor: AppColors.TIPIGO_GREEN,
                })
            } else {
                console.log('daysdays', days)
            }
        }
    }, [lineDateFrom, lineDateTo])

    useEffect(() => {
        if (didLaod) {
            console.log('get_chart benchmarks')
            exposeBenchmarks?.(benchmarks)
            loadCahrt()
        }
    }, [benchmarks])

    useEffect(() => {
        if (
            (didLaod || autoFirst) &&
            (Array.isArray(tickers) || (tickers && tickers.trim()))
        ) {
            console.log('get_data tickers')
            loadCahrt()
        }
    }, [String(tickers).trim()])

    useEffect(() => {
        if (refreshChart) {
            console.log('get_data main', refreshChart)
            loadCahrt()
        }
    }, [refreshChart])

    useEffect(() => {
        if (fromDate) setLineDateFrom(fromDate)
    }, [fromDate])

    useEffect(() => {
        if (toDate) setLineDateTo(toDate)
    }, [toDate])

    const changeTimeframe = (t) => {
        var days =
            t == '10D'
                ? 10
                : t == '1M'
                ? 31
                : t == '3M'
                ? 92
                : t == '6M'
                ? 184
                : t == '1Y'
                ? 365
                : 1825
        var today = new Date()
        var from = new Date()
        from.setDate(from.getDate() - days)
        if (from < lineMinDate) from = lineMinDate
        setLineDateFrom(from)
        setLineDateTo(today)
        setSelectedTime(t)
    }

    if (chartData === false || !chartData?.labels?.length) {
        console.log('no_chart_data', chartData)
        return null
    } else
        return (
            <ChartContainer style={contStyle}>
                <ReactTooltip />
                <ChartTopArea>
                    <TopLeft>
                        <AddBenchmarkButton
                            style={{marginLeft: 10}}
                            onClick={() => setLineBMDialog(true)}
                        />
                    </TopLeft>

                    {allowDates && (
                        <>
                            {timeArr.map((t) => (
                                <TimeLabel
                                    key={t}
                                    onClick={() => changeTimeframe(t)}
                                    selected={selectedTime == t}>
                                    {t}
                                </TimeLabel>
                            ))}

                            <DateRangeNoInput
                                fromDate={lineDateFrom}
                                toDate={lineDateTo}
                                setFromDate={setLineDateFrom}
                                setToDate={setLineDateTo}
                                minDate={lineMinDate}
                                containerStyle={{marginTop: -7}}
                                afterSelection={() =>
                                    setSelectedTime('selection')
                                }
                                iconColor={
                                    selectedTime == 'selection'
                                        ? AppColors.TIPIGO_GREEN
                                        : AppColors.BLACK
                                }
                                disableShortRange={2}
                            />
                        </>
                    )}
                </ChartTopArea>

                <LineChart
                    graphData={chartData?.labels?.length ? chartData : null}
                    loading={chartLoading}
                    height={400}
                    border
                />

                <div style={{height: 25}} />

                <SelectBenchmarks
                    benchmarks={benchmarks}
                    setBenchmarks={setBenchmarks}
                    isOpenDialog={lineBMDialog}
                    setIsOpenDialog={setLineBMDialog}
                    showLongShort={fileId}
                />
            </ChartContainer>
        )
}
export default FileLineChart

const ChartContainer = styled.div`
    border: 1px solid ${AppColors.APP_GRAY_LIGHT};
    border-radius: 7px;
    display: flex;
    flex-direction: column;
`
const ChartTopArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid ${AppColors.APP_GRAY_LIGHT};
    position: relative;
    margin-top: 10px;
    height: 70px;
`
const TopLeft = styled.div`
    margin-left: 0px;
    position: absolute;
    display: flex;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
`

// const AddBC = styled.div`
//     width: 160px;
//     padding-left: 10px;
//     justify-content: flex-start;
//     background-color: white;
//     height: 36px;
//     display: flex;
//     align-items: center;
//     border-radius: 4px;
//     box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.16);
//     cursor: pointer;
//     margin-right: 10px;
//     font-size: 13px;
// `
const TimeLabel = styled.div`
    width: 44px;
    font-size: 14px;
    color: ${(p) => (p.selected ? AppColors.TIPIGO_GREEN : AppColors.BLACK)};
    font-weight: ${(p) => (p.selected ? 700 : 400)};
    cursor: pointer;
    text-align: center;
    padding-bottom: 9px;
    margin-right: 10px;
    border-bottom: ${(p) =>
        p.selected ? '2px solid ' + AppColors.TIPIGO_GREEN : '0px'};
`
// const HistoryIcon = styled(HistoryOutlined)`
//     color: ${(p) => (p.history ? AppColors.TIPIGO_GREEN : AppColors.BLACK)};
//     outline: none;
// `
