import React from 'react'
import {api} from '@services/api'
import {useState, useEffect} from '@services/hooks'
import styled, {createGlobalStyle} from 'styled-components'
//import {Close, SwapCallsTwoTone} from '@material-ui/icons'
import {Close} from '@mui/icons-material'
import 'sweetalert2/dist/sweetalert2.min.css'
import Dialog from '@mui/material/Dialog'
import {
    Title,
    SendButton,
    DialogContainer,
    DialogTopRow,
    DialogRow,
} from '../General/Elements'
import Swal from 'sweetalert2'
import FrameDiv from './Controls/FrameDiv'
import FrameInput from '../General/FrameInput'
import FrameText from '../General/FrameText'
import {AppColors} from '@services/styles'
import CircularProgress from 'material-ui/CircularProgress'

const EditUniverse = ({universe, setUniverse, canEdit}) => {
    const [symbols, setSymbols] = useState([])
    const [filter, setFilter] = useState('')
    const [newSymbols, setNewSymbols] = useState('')
    const [deleteSymbols, setDeleteSymbols] = useState([])

    console.log('new symbols', newSymbols)
    useEffect(() => {
        if (universe) {
            setNewSymbols('')
            getSymbols()
        }
    }, [universe])

    const getSymbolsElements = (type) => {
        let data = symbols
        return (
            <>
                {data
                    .filter((a) => a.includes(filter))
                    .map((s) => {
                        return (
                            <Symbol
                                color={
                                    type === 'default' ? '#D0EAE1' : '#EAD0E2'
                                }
                                key={s}>
                                {s}&nbsp;
                                {canEdit && (
                                    <Xbox>
                                        <DeleteSymbol
                                            sx={{fontSize: 18}}
                                            onClick={() =>
                                                removeSymbol(
                                                    s,
                                                    type === 'default'
                                                        ? 'symbol'
                                                        : 'not',
                                                )
                                            }
                                        />
                                    </Xbox>
                                )}
                            </Symbol>
                        )
                    })}
            </>
        )
    }
    const preSetFilter = (value) => {
        setFilter(value.toUpperCase().trim())
    }
    const removeSymbol = (symbol) => {
        setDeleteSymbols([...deleteSymbols, symbol])
        let arr = [...symbols]
        arr = arr.filter((item) => item !== symbol)
        setSymbols(arr)
    }
    const removeAllSymbols = () => {
        setDeleteSymbols([...deleteSymbols, ...symbols])
        setSymbols([])
    }
    const getSymbols = () => {
        setSymbols([])
        api.getTipigoIndexSymbols(universe.id).subscribe(
            // TODO: redux
            (response) => {
                console.log('got symbols', response)
                if (
                    response?.data &&
                    (response.data.symbols || response.data.tickers)
                ) {
                    setSymbols(response.data.symbols || response.data.tickers)
                    setFilter('')
                }
            },
            (error) => {
                alert('error getting symbols')
            },
        )
    }
    const SaveEdit = () => {
        Swal.fire({
            text: 'save changes to univerese?',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true,
            focusConfirm: false,
            confirmButtonColor: AppColors.TIPIGO_GREEN,
            showCancelButton: true,
        }).then(
            (response) => {
                if (response.isConfirmed) {
                    api.addTipigoIndex(
                        universe.name,
                        universe.id,
                        newSymbols,
                        deleteSymbols,
                        symbols,
                    ).subscribe(
                        (response) => {
                            console.log('save response ', response)
                            if (response?.data?.status || response?.status) {
                                setNewSymbols('')
                                setDeleteSymbols([])
                                setUniverse(null)
                            }
                        },
                        (error) => {
                            console.log('error', error)
                            alert('error adding stocks to universe')
                        },
                    )
                }
            },
            (error) => {
                console.log('swal error', error)
            },
        )
    }

    return (
        <Dialog open={universe} maxWidth="md" fullWidth modal={true}>
            <OverrideStyles />
            <DialogContainer>
                <DialogTopRow>
                    <Title
                        text={
                            (canEdit ? 'Edit ' : '') +
                            'Universe ' +
                            universe.name
                        }
                    />
                    <Close onClick={() => setUniverse('')} />
                </DialogTopRow>
                {canEdit && (
                    <DialogRow>
                        <FrameText
                            style={{flex: 1}}
                            frameStyle={{height: 140}}
                            value={newSymbols}
                            setValue={setNewSymbols}
                            title={
                                'Add Symbols (separate by comma, space or new line)'
                            }
                        />

                        {/* <Add onClick={addSymbols}>Add</Add> */}
                    </DialogRow>
                )}
                <DialogRow>
                    <FrameInput
                        value={filter}
                        setValue={preSetFilter}
                        title={'filter'}
                        style={{flex: 1}}
                        maxLength={20}
                    />
                </DialogRow>
                <DialogRow>
                    <FrameDiv
                        title={<div onClick={removeAllSymbols}>Symbols</div>}
                        //content={getSymbolsElements('default')}
                        content={
                            symbols.length > 0 ? (
                                getSymbolsElements('default')
                            ) : (
                                <CircularProgress
                                    style={{top: '43%', left: '45%'}}
                                />
                            )
                        }
                        style={{height: 200, flex: 1}}
                    />
                </DialogRow>

                {canEdit && (
                    <BottomRow>
                        <SendButton onClick={SaveEdit}>Save</SendButton>
                    </BottomRow>
                )}
            </DialogContainer>
        </Dialog>
    )
}

export default EditUniverse

const BottomRow = styled.div`
    padding-top: 20px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
`
const Symbol = styled.div`
    background-color: ${(p) => p.color};
    display: inline-flex;
    margin-top: 19px;
    margin-left: 8px;
    //border: 1px solid #999999;
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 6px;
    align-items: center;
    font-size: 14px;
    height: 23px;
`
const DeleteSymbol = styled(Close)`
    vertical-align: middle;
    cursor: pointer;
    display: inline-flex;
`
const Xbox = styled.div`
    display: inline-flex;
`
const OverrideStyles = createGlobalStyle`
    .swal2-container {
        z-index: 2000;
    }`
