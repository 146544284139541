import React from 'react'
import {api, baseNewApiUrl} from '@services/api'
import {useState, useEffect, useUser, useDispatch} from '@services/hooks'
import styled from 'styled-components'
import {DeleteOutline, Edit} from '@mui/icons-material'
import 'sweetalert2/dist/sweetalert2.min.css'
import AddUniverse from './AddUniverse'
import EditUniverse from './EditUniverse'
import Swal from 'sweetalert2'
import moment from 'moment'
import {SendButton, Title, FormTopRow} from '@components/General/Elements'
import {AppColors} from '@services/styles'
import {formatUniverseResponse} from '@services/utils'
import {getUniverses} from '@store/actions'

const ManageUniverses = ({canEdit, sysAdmin}) => {
    const user = useUser()
    const dispatch = useDispatch()
    const [indexes, setIndexes] = useState('')
    const [universe, setUniverse] = useState(null)
    const [creating, setCreating] = useState(false)
    const [doneCreate, setDoneCreate] = useState(false)

    const reloadUniverses = () => {
        dispatch(getUniverses(user))

        // return new Promise((resolve, reject) => {
        //     api.getTipigoIndexes(0).subscribe(
        //         (response) => {
        //             if (response) {
        //                 setIndexesFromResponse(response)
        //                 resolve('resolve msg')
        //             }
        //         },
        //         (error) => {
        //             console.log('error loading indexes', error)
        //             reject('error msg')
        //         },
        //     )
        // })
    }

    // const setIndexesFromResponse = (response) => {
    //     const preList = formatUniverseResponse(response)
    //     console.log(preList)
    //     setIndexes(preList.sort((a, b) => (a.name > b.name ? 1 : -1)))
    // }

    useEffect(() => {
        console.log('user_change', user.universeList?.length)
        setIndexes(user.universeList || [])
    }, [user])

    useEffect(() => {
        if (doneCreate) {
            reloadUniverses()
            setDoneCreate(false)
        }
    }, [doneCreate])

    useEffect(() => {
        setIndexes(user.universeList || [])
    }, [])

    const deleteIndex = (indexId) => {
        Swal.fire({
            text: 'This action will remove permanently delete this universe. Are you sure?',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            focusConfirm: false,
            confirmButtonColor: AppColors.TIPIGO_GREEN,
        }).then(
            (response) => {
                if (response.isConfirmed) {
                    api.deleteTipigoIndex(indexId).subscribe(
                        (response) => {
                            console.log(response)
                            if (response?.data?.status || response?.status) {
                                reloadUniverses()
                            } else {
                                alert('error deleting this universe')
                            }
                        },
                        (error) => {
                            console.log('error deleting index', error)
                            alert('error deleting universe')
                        },
                    )
                }
            },
            (error) => {
                console.log('swal error', error)
            },
        )
    }

    return (
        <Container>
            {universe && (
                <EditUniverse
                    universe={universe}
                    setUniverse={setUniverse}
                    canEdit={canEdit && (!universe.system || sysAdmin)}
                />
            )}
            <AddUniverse
                show={creating}
                setShow={setCreating}
                setDone={setDoneCreate}
            />
            <FormTopRow style={{marginBottom: '40px'}}>
                <Title text={(canEdit ? 'Manage ' : '') + 'Universes'} />
                {canEdit && (
                    <SendButton onClick={() => setCreating(true)}>
                        Create New Universe
                    </SendButton>
                )}
            </FormTopRow>
            {indexes &&
                indexes.map((ti) => {
                    return (
                        <IndexRow key={ti.id} onClick={() => setUniverse(ti)}>
                            <IndexName>{ti.name}</IndexName>
                            <RightSide>
                                <DateText>
                                    {ti.updated
                                        ? 'Updated ' +
                                          moment(ti.updated).format('MM/DD/YY')
                                        : ''}
                                </DateText>

                                <EditDelete>
                                    {canEdit && (!ti.system || sysAdmin) && (
                                        <>
                                            <EditIndex />
                                            {!sysAdmin && (
                                                // 21.09.23 don't allow admin to detele, N is recreating every time and making a mess..
                                                // it may be saved in code or db... find better solution
                                                <DeleteIndex
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        deleteIndex(ti.id)
                                                    }}
                                                />
                                            )}
                                        </>
                                    )}
                                </EditDelete>
                            </RightSide>
                        </IndexRow>
                    )
                })}
        </Container>
    )
}

export default ManageUniverses

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    border: 0px solid pink;
    font-family: 'Inter';
`
// const TopRow = styled.div`
//     display: flex;
//     flex: 1;
//     border: 0px solid green;
//     justify-content: space-between;
//     margin-bottom: 40px;
// `

const IndexRow = styled.div`
    background-color: #f6f7f8;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 64px;
    margin-bottom: 10px;
    cursor: pointer;
`
const EditDelete = styled.div`
    display: flex;
    width: 55px;
`
const RightSide = styled.div`
    display: flex;
`
const DateText = styled.div`
    display: flex;
    margin-right: 35px;
`
const IndexName = styled.div`
    color: #333333;
    font-size: 16px;
`
const DeleteIndex = styled(DeleteOutline)`
    margin-left: 10px;
    cursor: pointer;
`
const EditIndex = styled(Edit)`
    cursor: pointer;
`
