import {useSelector} from 'react-redux'

export const useFinanceInitialized = () =>
    useSelector(
        (state) => state.finance?.initialized,
        () => {},
    )

export const useMarketOpen = () =>
    useSelector(
        (state) => state.finance?.marketOpen,
        () => {},
    )

export const useFollowingSymbols = () =>
    useSelector(
        (state) => state.finance?.data?.followingSymbols || [],
        () => {},
    )

export const useAlerts = () =>
    useSelector(
        (state) => state.finance?.data?.alerts || [],
        () => {},
    )

export const useSymbols = () =>
    useSelector(
        (state) => state.finance?.data?.symbols || [],
        () => {},
    )

export const useSystemSectors = () =>
    useSelector(
        (state) => state.finance?.data?.system_sectors || [],
        () => {},
    )

export const useDataLoading = () =>
    useSelector(
        (state) => state.finance?.isFetching['getUserFinanceInfo'],
        () => {},
    )

export const useSectorsLoading = () =>
    useSelector(
        (state) => state.finance?.isFetching['getUserProfileData'],
        () => {},
    )

export const useSectors = () =>
    useSelector(
        (state) => state.finance?.data?.sectors || [],
        () => {},
    )

export const usePortfolioSize = () =>
    useSelector(
        (state) => state.finance.data.portfolioSize,
        () => {},
    )
