import styled from 'styled-components'
import React from 'react'
import {AppColors} from '@services/styles'

const FrameText = ({
    value,
    setValue,
    title,
    placeholder,
    style,
    params,
    frameStyle,
    disabled,
}) => {
    return (
        <Container style={style}>
            <GroupLabel>{title}</GroupLabel>
            <FormGroup style={frameStyle}>
                {/*style={style}*/}
                {/* <GroupLabel>{title}</GroupLabel> */}
                <InputField
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value, params)}
                    maxLength={60000} // some QA BS
                    disabled={disabled}
                />
            </FormGroup>
        </Container>
    )
}

export default FrameText

const Container = styled.div`
    display: flex;
    flex-direction: column;
`
const FormGroup = styled.div`
    padding: 10px;
    border: 1px solid ${AppColors.APP_GRAY_LIGHT};
    background-color: white;
    border-radius: 8px;
    position: relative;
    //display: flex;
`
const GroupLabel = styled.label`
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 3px;
    margin-left: 4px;
`
// const GroupLabel = styled.label`
//     position: absolute;
//     top: -10px;
//     font-size: 12px;
//     font-style: normal;
//     font-weight: normal;
//     background-color: white;
//     padding-left: 5px;
//     padding-right: 5px;
// `
const InputField = styled.textarea`
    border: none;
    width: 100%;
    height: 100%;
    &:focus {
        outline: none;
    }
    resize: none;
`
