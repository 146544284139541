import React, {Component} from 'react' // PropTypes
import Api from '@services/api'
//import ReactTable, { useFilters } from 'react-table'
import styles from './Styles'
import {monthAndDayFormat} from '@services/utils'
import pic1 from '@images/pic1.jpg'
import pic2 from '@images/pic2.jpg'
import pic3 from '@images/pic3.jpg'
import pic4 from '@images/pic4.jpg'
import pic5 from '@images/pic5.jpg'
import pic6 from '@images/pic6.jpg'
import pic7 from '@images/pic7.jpg'
import pic8 from '@images/pic8.jpg'
import pic9 from '@images/pic9.jpg'

class News extends Component {
    constructor(props) {
        super(props)
        this.api = new Api()
        this.state = {
            topNews: null,
            randomImg: [pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8, pic9],
        }
        this.sendMessage = props.sendMessage.bind(this)
    }

    getTopNews = () => {
        if (!this.state.topNews) {
            console.log('go get news')

            this.api.getTopNewsQm().subscribe(
                (response) => {
                    console.log('response', response)
                    let news = response && response.data ? response.data : null
                    if (news) {
                        let imgArr = this.state.randomImg
                        news.forEach((n) => {
                            let img =
                                n.image && n.image.length >= 2
                                    ? n.image[1].url
                                    : null
                            if (!img) {
                                let randomIndex = Math.floor(
                                    Math.random() * imgArr.length,
                                )
                                n.image = imgArr[randomIndex]
                                imgArr.splice(randomIndex, 1)
                            }
                        })
                    }
                    this.setState({
                        topNews: news, //response && response.data ? response.data : null,
                    })
                    console.log('state', this.state)
                },
                (error) => {
                    console.log('error', error)
                },
            )
        }
    }

    renderNewsItem = (item) => {
        let img = item.image ? item.image : null
        return (
            <div
                style={styles.newsDiv}
                key={item.id ? item.id : item.url}
                onClick={() =>
                    this.sendMessage(
                        '{"navigate":"NewsWebpage", "item": {"url": "' +
                            item.url +
                            '"}}',
                    )
                }>
                <table style={styles.newsItemTable}>
                    <tbody>
                        <tr>
                            <td>
                                <img
                                    style={styles.newsImg}
                                    src={img}
                                    alt="news"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style={styles.news_par}>{item.title}</td>
                        </tr>
                        <tr>
                            <td style={styles.news_date}>
                                {monthAndDayFormat(item.updated)} |{' '}
                                {item.author}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    componentDidMount() {
        this.getTopNews()
    }
    render() {
        // temp, will be replaced by design
        // const newsCols =[
        //     {
        //         accessor: 'title',
        //         Header: 'Title',
        //         //maxWidth: 70,
        //         sortable: false
        //     }
        // ]

        return (
            <div>
                {this.state.topNews ? (
                    this.state.topNews.map((item) => this.renderNewsItem(item))
                ) : (
                    <div>Loading News</div>
                )}

                <div style={styles.buttons}>
                    <div
                        onClick={() => this.sendMessage('{"navigate":"News"}')}
                        style={styles.buttons_font}>
                        Go to News
                    </div>
                </div>
            </div>
        )
    }
}

export default News
