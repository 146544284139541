import React from 'react'
import {api, baseNewApiUrl, newApiReports} from '@services/api'
import {useState, useEffect} from '@services/hooks'
import FrameInput from '@components/General/FrameInput'
import {SendButton, SendButtonDis} from '@components/General/Elements'
import styled from 'styled-components'
import swal from 'sweetalert2'
import moment from 'moment'
import {AppColors} from '@services/styles'
import Analytics from '@services/analytics'
import qs from 'qs'

const scoreParamsConversions = {
    team_score_factor: 'inAnalystTeam',
    outperform_score_factor: 'outPerformEtf',
    positive_pl_score_factor: 'positivePl',
    number_rec_score_factor: 'tickerRecommendationsCount',
    algorithm_score_factor: 'analystTeam',
    momentum_score_factor: 'momentum',
    pe_score_factor: 'pe',
    pb_score_factor: 'pb',
    currentratio_score_factor: 'currentRatio',
    sd_score_factor: 'sd',
    beta_score_factor: 'beta',
    top_mc_score_factor: 'inTopMarketSymbols',
    ma_ratio_score_factor: 'maRatio',
    ma_etf_spy_score_factor: 'maEtfSpy',
    eps_score_factor: 'eps',
    roe_score_factor: 'roe',
}

const convertScoreParams = (scoreParams) => {
    return Object.keys(scoreParams).reduce((acc, key) => {
        const newKey = scoreParamsConversions[key]
        if (newKey) {
            acc[newKey] = scoreParams[key]
        }
        return acc
    }, {})
}

const GenerateReport = ({
    user,
    mainColWidth,
    fromDate,
    toDate,
    scoreParam,
    tickerSelectionParams, // TODO: change name of param as it includes reportParams OR add separate param
    analystTeamSelectionParams,
    scoreVer,
    actionText,
    adminPlParams,
    setAdminPlParams,
    editAdminPlParams,
    oneDate,
    reportType,
    tickers,
    benchmarks,
}) => {
    const [enableRun, setEnableRun] = useState(true)

    const [mailTo, setMailTo] = useState(
        window.location.hostname === 'localhost'
            ? 'ronkaplan69@gmail.com'
            : user?.email || '',
    )

    console.log(
        'analystTeamSelectionParams',
        analystTeamSelectionParams,
        scoreVer,
        window.location.hostname,
    )

    const {
        universe,
        universeName,
        marketCapCond,
        marketCapValue1,
        marketCapValue2,
        betaCond,
        betaValue1,
        betaValue2,
        sector,
        daysToEarning,
        minEps,
        minRoe,
        minUpside,
        filterAdr,
        removeIfAnyShort,
        excludeNoRatings,
    } = tickerSelectionParams || {}

    const {teamThreshold, minRatio, minMonthAvg} =
        analystTeamSelectionParams || {}

    useEffect(() => {
        setAdminPlParams?.({
            topN: '',
            adminTakeTop: 15,
            adminIpMonths: 3,
            adminBtMonths: 3,
            compareEtf: 'XLK',
        })
    }, [])

    useEffect(() => {
        setEnableRun(
            (sector?.length > 0 && sector.length < 10) ||
                universe !== 'all' ||
                reportType !== 'scored',
        )
    }, [universe, sector, reportType])

    const updateAdminPlParams = (field) => (value) => {
        setAdminPlParams({...adminPlParams, [field]: value})
    }

    const {topN, adminTakeTop, adminIpMonths, adminBtMonths, compareEtf} =
        adminPlParams || {}

    const generateOpportunitiesReport = () => {
        if (reportType === 'backtest') {
            console.log('backtest report', {
                fromDate,
                toDate,
                tickers,
                benchmarks,
            })

            const clenTickers = tickers.replace(/\s+/g, ',')
            const queryParams = {
                fromDate: moment(fromDate).format('YYYY-MM-DD'),
                toDate: moment(toDate).format('YYYY-MM-DD'),
                tickers: clenTickers.split(','),
                benchmarks,
            }

            const query = qs.stringify(queryParams)

            return `${baseNewApiUrl}/reports/getBackTestReport?${query}`
        }
        const queryParams = {isAdmin: user.is_admin}
        const selectedSectors = sector && sector.length > 0 ? sector : ['all']

        const fromDate2 = oneDate
            ? new Date(toDate).setMonth(toDate.getMonth() - 3)
            : fromDate

        queryParams.tickerSelectionParams = {
            universe: universeName,
            marketCapCond,
            marketCapVal1: marketCapValue1,
            marketCapVal2: marketCapValue2,
            betaCond,
            betaVal1: betaValue1,
            betaVal2: betaValue2,
            sectorNames: selectedSectors,
            daysToEarning,
            minEps,
            minRoe,
            filterAdr,
            excludeNoRatings,
        }

        queryParams.analystTeamSelectionParams = analystTeamSelectionParams
        // con6,conTeam
        // const {testCalc, uniPostTeam} = scoreVer
        // for scored the "to date" called "For" in UI actually means "month prior to". server does not need to know this B.S
        // for admin is still "From" "To" and will include range as intended
        const realToDate =
            reportType === 'scored' && !user.is_admin
                ? moment(toDate).subtract(1, 'months')
                : toDate
        queryParams.reportParams = {
            startMonth: moment(fromDate2).format('YYYY-MM'),
            endMonth: moment(realToDate).format('YYYY-MM'),
            reportType,
            scoreParams: convertScoreParams(scoreParam),
            excel: true,
            minUpside,
            includeNoRtaing: false,
            removeIfAnyShort,
        }
        if (reportType === 'admin_pl') {
            queryParams.reportParams.mail = mailTo
        }

        const {adminTakeTop, adminIpMonths, adminBtMonths, compareEtf} =
            adminPlParams
        queryParams.adminPlParams = {
            numberOfOpportunities: adminTakeTop,
            investmentProfileMonths: adminIpMonths,
            backTestMonths: adminBtMonths,
            compareEtf,
        }

        queryParams.debugOptions = {
            debugPrint: false,
            minimal: true,
            timings: true,
        }

        console.log('query', queryParams)

        const query = qs.stringify(queryParams)

        if (reportType === 'admin_pl') {
            return `reports/getAdminPlReport?${query}`
        }

        if (reportType === 'admin_signal') {
            return `${baseNewApiUrl}/reports/getAdminSignalReport?${query}`
        }

        return `${baseNewApiUrl}/reports/getPerformanceOpportunityReport?${query}`
    }

    console.log('dates', fromDate, toDate, editAdminPlParams)

    const getReportMail = (direct) => {
        if (reportType === 'backtest') {
            api.getBacktestReport(
                fromDate,
                toDate,
                tickers,
                benchmarks,
                mailTo,
            ).subscribe(
                (response) => {
                    swal.fire({
                        text: 'file will be sent by email',
                        focusConfirm: false,
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                        icon: 'success',
                    }).then()
                },
                (error) => {
                    console.log('error TODO', error)
                },
            )
        } else {
            let selectedSectors = sector && sector.length > 0 ? sector : ['all']
            // subtract 3 month but range will be 4 full months because we use month resolution

            const fromDate2 = oneDate
                ? new Date(toDate).setMonth(toDate.getMonth() - 3)
                : fromDate

            api.getPerformanceReportByMail(
                moment(fromDate2).format('YYYY-MM'),
                moment(toDate).format('YYYY-MM'),
                encodeURIComponent(JSON.stringify(selectedSectors)),
                universe,
                marketCapCond,
                marketCapValue1,
                marketCapValue2,
                betaCond,
                betaValue1,
                betaValue2,
                //false, //compareToMa50,
                //100, //maxNumberOfAnalysts,
                //1, //minNumberOfRecommendations,
                encodeURIComponent(mailTo),
                reportType,
                encodeURIComponent(JSON.stringify(scoreParam)),
                adminTakeTop,
                topN ? topN : 'All',
                adminIpMonths,
                adminBtMonths,
                teamThreshold,
                minRatio,
                minMonthAvg,
                user.is_admin && encodeURIComponent(JSON.stringify(scoreVer)),
            ).subscribe(
                (response) => {
                    console.log('response', response)
                    swal.fire({
                        text: 'Your request was received, please note that this process may take up to one hour to complete.',
                        focusConfirm: false,
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                        icon: 'success',
                    }).then()
                },
                (error) => {
                    console.log('error TODO', error)
                },
            )
        }

        Analytics.exec('track', `excute report ${reportType}`)
    }
    const useNewApiOnly = newApiReports && reportType !== 'admin_signal'
    return (
        <>
            {(!useNewApiOnly || reportType === 'admin_pl') && (
                <Row width={mainColWidth}>
                    <FrameInput
                        title={'Mail report to'}
                        value={mailTo}
                        setValue={setMailTo}
                        maxLength={100}
                    />
                </Row>
            )}
            {editAdminPlParams && (
                <>
                    <Row>
                        <AdminTitle>
                            Get average P&L by above report for:
                        </AdminTitle>
                    </Row>
                    <FlexRow
                        style={{
                            justifyContent: 'flex-start',
                            paddingTop: 20,
                        }}>
                        <FrameInput
                            title={'Take top'}
                            style={{width: 70, marginRight: 20}}
                            value={adminTakeTop}
                            setValue={updateAdminPlParams('adminTakeTop')}
                        />
                        <FrameInput
                            title={'Number of Backtest Months'}
                            style={{marginRight: 20}}
                            setValue={updateAdminPlParams('adminBtMonths')}
                            value={adminBtMonths}
                        />
                        <FrameInput
                            title={'Compare to ETF'}
                            setValue={updateAdminPlParams('compareEtf')}
                            value={compareEtf}
                        />
                    </FlexRow>
                </>
            )}
            <Row
                width={mainColWidth}
                style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                {!useNewApiOnly && (
                    <SendButton onClick={() => getReportMail(false)}>
                        {actionText}
                    </SendButton>
                )}

                <>
                    {enableRun ? (
                        <Link
                            href={
                                reportType === 'admin_pl'
                                    ? null
                                    : generateOpportunitiesReport()
                            }>
                            <SendButton
                                onClick={() => {
                                    var msgText =
                                        'The download should start shortly.'
                                    if (reportType === 'admin_pl') {
                                        msgText =
                                            'The report will be sent by mail.'
                                        api.invokeGetUrl(
                                            generateOpportunitiesReport(),
                                        ).subscribe(
                                            (response) =>
                                                console.log(
                                                    'response',
                                                    response,
                                                ),
                                            (error) =>
                                                console.log(
                                                    'error TODO',
                                                    error,
                                                ),
                                        )
                                    }
                                    swal.fire({
                                        html:
                                            'The report request was successfully sent. <br />' +
                                            msgText,
                                        focusConfirm: false,
                                        confirmButtonColor:
                                            AppColors.TIPIGO_GREEN,
                                        icon: 'success',
                                    }).then()
                                }}>
                                {actionText}
                            </SendButton>
                        </Link>
                    ) : (
                        <SendButtonDis>{actionText}</SendButtonDis>
                    )}
                </>
                {/* )} */}
            </Row>
        </>
    )
}

export default GenerateReport

const Row = styled.div`
    padding-top: 38px;
    width: ${(p) => p.width}px;
`

const Link = styled.a`
    text-decoration: none;
`

const AdminTitle = styled.div`
    font-size: 16px;
    font-weight: 500;
`

const FlexRow = styled(Row)`
    display: flex;
    justify-content: space-between;
    width: ${(p) => p.width}px;
    border: 0px solid purple;
    align-items: baseline;
`
