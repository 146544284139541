import * as storage from '../services/storage'
import {initFilters} from '../components/Home/HomeDrawer'
import _ from 'lodash'

// import {getCurrentUser} from '@store/actions' //Ruser?
// import {useDispatch} from '@services/hooks' //Ruser?

const storageMiddleWare = (store) => (next) => (action) => {
    const user =
        store.getState() && store.getState().user && store.getState().user.user
    if (action.method === 'updateUserProfile' && user) {
        storage.setProfileByUser(user, action.profile)
        next(action)
    } else if (action.method === 'updateUserFilters' && user) {
        storage.setFiltersByUser(user, action.filters)
        next(action)
    } else if (
        action.data &&
        ['getCurrentUser', 'login'].indexOf(action.method) > -1
    ) {
        // const dispatch = useDispatch() //Ruser?
        // getCurrentUser() //Ruser?
        storage.getProfileByUser(action.data).then((profile) => {
            storage.getFiltersByUser(action.data).then((filters) => {
                console.log('set user api stored data', {profile, filters})
                if (profile) action.data.current_profile = profile
                if (
                    profile &&
                    action.data.available_profiles &&
                    !_.find(
                        action.data.available_profiles,
                        (o) => o.profile === profile.profile,
                    )
                ) {
                    action.data.current_profile =
                        action.data.available_profiles[0]
                }
                action.data.alert_filters = filters || initFilters
                next(action)
            })
        })
    } else if (action.type === 'SET_USER' && action.user) {
        console.log('set user stored data', action.user)
        storage.getProfileByUser(action.user).then((profile) => {
            storage.getFiltersByUser(action.user).then((filters) => {
                console.log('set user stored data', {profile, filters})
                action.user.current_profile =
                    profile ||
                    (action.user?.available_profiles?.length > 0
                        ? action.user.available_profiles[0]
                        : null)
                if (
                    profile &&
                    action.user.available_profiles &&
                    !_.find(
                        action.user.available_profiles,
                        (o) => o.profile === profile.profile,
                    )
                ) {
                    action.user.current_profile =
                        action.user.available_profiles[0]
                }
                action.user.alert_filters = filters || initFilters
                next(action)
            })
        })
    } else {
        next(action)
    }
}

export default storageMiddleWare
