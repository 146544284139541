import React from 'react'
//import {Link} from 'react-router-dom'

//import RaisedButton from 'material-ui/RaisedButton'
import CircularProgress from 'material-ui/CircularProgress'
import Snackbar from 'material-ui/Snackbar'
//import FlatButton from 'material-ui/FlatButton'
//import FontIcon from 'material-ui/FontIcon'

//import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import {api} from '@services/api'
import {useState, useEffect} from '@services/hooks'
import Header from '../Header'
import styled from 'styled-components'
import FrameInput from '@components/General/FrameInput'
import {Title, SendButton} from '@components/General/Elements'
import Swal from 'sweetalert2'
import isEmail from 'validator/lib/isEmail'
import {validPassword, invalidPassMsg} from '@services/utils'
import {AppColors} from '@services/styles'

const Register = () => {
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('')
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const openSnackbar = (message) => {
        setSnackbarOpen(true)
        setSnackbarMessage(message)
    }

    const handleRequestClose = () => {
        setSnackbarOpen(false)
        setSnackbarMessage('')
    }

    // const handleChange = (type, event, newValue) => {
    //     let o = {[type]: newValue}
    //     this.setState(o)
    // }

    const handleSubmit = () => {
        if (!isEmail(email.trim())) {
            Swal.fire({
                text: 'Please enter a valid email',
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
            return
        }
        if (!validPassword(password)) {
            Swal.fire({
                text: invalidPassMsg,
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
            return
        }
        setLoading(true)

        api.adminCreateProfile({
            first_name: firstName,
            last_name: lastName,
            email: email.trim(),
            password: password,
        }).subscribe(
            (response) => {
                console.log(response)
                if (response.data.status) {
                    setEmail('')
                    setFirstName('')
                    setLastName('')
                    setPassword('')
                    setLoading(false)
                    openSnackbar('User created successfully')
                } else {
                    Swal.fire({
                        text: 'Profile not created. make sure name or email are not allready taken',
                        icon: 'error',
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                    })
                    setLoading(false)
                }
            },
            (error) => {
                // console.warn('createUser failed:', error)
                openSnackbar('Failed to create user')
                setLoading(false)
            },
        )
    }

    return (
        <div>
            <Header />

            <Snackbar
                open={snackbarOpen}
                message={snackbarMessage}
                autoHideDuration={4000}
                onRequestClose={handleRequestClose}
            />

            <Container>
                <Title
                    text={'Register a new profile'}
                    style={{marginTop: 20}}
                />
                {/* <ValidatorForm
                    ref="form"
                    onSubmit={handleSubmit}
                    onError={(errors) => console.log(errors)}> */}

                <Inputs>
                    <FrameInput
                        title={'First Name'}
                        value={firstName}
                        setValue={setFirstName}
                        style={{marginTop: 20}}
                        maxLength={100}
                    />
                    {/* <TextValidator
                                floatingLabelText="First name"
                                onChange={(event, newValue) => {
                                    this.handleChange(
                                        'firstName',
                                        event,
                                        newValue,
                                    )
                                }}
                                name="firstName"
                                value={firstName}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            /> */}

                    <FrameInput
                        title={'Last Name'}
                        value={lastName}
                        setValue={setLastName}
                        style={{marginTop: 20}}
                        maxLength={100}
                    />
                    {/* <TextValidator
                                floatingLabelText="Last name"
                                onChange={(event, newValue) => {
                                    this.handleChange(
                                        'lastName',
                                        event,
                                        newValue,
                                    )
                                }}
                                name="lastName"
                                value={lastName}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            /> */}

                    <FrameInput
                        title={'Email'}
                        value={email}
                        setValue={setEmail}
                        style={{marginTop: 20}}
                        maxLength={100}
                    />
                    {/* <TextValidator
                                floatingLabelText="Email"
                                onChange={(event, newValue) => {
                                    this.handleChange('email', event, newValue)
                                }}
                                name="email"
                                value={email}
                                validators={['required', 'isEmail']}
                                errorMessages={[
                                    'this field is required',
                                    'email is not valid',
                                ]}
                            /> */}

                    <FrameInput
                        title={'Password'}
                        value={[password]}
                        setValue={setPassword}
                        password
                        style={{marginTop: 20}}
                        maxLength={20}
                    />
                    {/* <TextValidator
                            name="password"
                            type="password"
                            hintText="Please enter password"
                            value={password}
                            onChange={(event, newValue) => {
                                this.handleChange('password', event, newValue)
                            }}
                            validators={['required']}
                            errorMessages={['this field is required']}
                            floatingLabelText="Password" 
                        />*/}
                </Inputs>

                {loading ? (
                    <CircularProgress />
                ) : (
                    // <RaisedButton
                    //     style={{marginTop: 20}}
                    //     type="submit"
                    //     label="Submit"
                    //     primary={true}
                    // />
                    <SendButton onClick={handleSubmit} style={{marginTop: 20}}>
                        Create
                    </SendButton>
                )}
                {/* </ValidatorForm> */}
            </Container>
        </div>
    )
}

export default Register

const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
`
