import React, {PropTypes, Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'

import IconButton from 'material-ui/IconButton'
import IconMenu from 'material-ui/IconMenu'
import MenuItem from 'material-ui/MenuItem'
import CircularProgress from 'material-ui/CircularProgress'
import AccountIcon from 'material-ui/svg-icons/action/account-circle'
import {Link} from 'react-router-dom'

import * as ApiActions from '@store/actions/api-actions'
import * as AppActions from '@store/actions/app-actions'
import {getCurrentUser} from '@store/actions'
import {Dimensions} from '@services/styles'

import Auth from '../cl_modules/cl-auth'
import Api from '@services/api'
import SearchBar from '@components/General/SearchBar'
import moment from 'moment'
import AppBar from 'material-ui/AppBar'
import styled from 'styled-components'

const MARKET_QUERY_INTERVAL = 100000 // In milliseconds. 12.21 change from 10 to 70

class Logged extends Component {
    constructor(props) {
        super(props)

        this.marketStatusInterval = null
        this.api = new Api()

        this.request = null
        this.searchRequest = null

        this.searchSymbol = _.debounce(this.searchSymbol, 200)

        this.state = {
            dataSource: [],
            searchText: null,
        }
    }

    componentWillMount() {
        this.marketStatus()
    }

    componentWillUnmount() {
        if (this.marketStatusInterval) {
            window.clearInterval(this.marketStatusInterval)
            this.marketStatusInterval = null
        }

        if (this.request) this.request.dispose()
        if (this.searchRequest) this.searchRequest.dispose()
    }

    gotoStock(symbol) {
        this.props.history.push('/stock/' + symbol)
    }

    gotoProfile() {
        this.props.history.push('/profile')
    }

    searchSymbol(symbol) {
        console.log('searchSymbol1 start', symbol)
        if (!symbol || symbol.length < 1) {
            this.setState({dataSource: []})
        } else {
            // else added 01.11.21

            this.searchRequest = this.api.findSymbolSearch(symbol).subscribe(
                (response) => {
                    console.log('searchSymbol response', response)
                    if (response.data && _.isArray(response.data)) {
                        const ds = response.data.map((r) => {
                            // return {
                            //     text: <span>{r.symbol}</span>,
                            //     value: r.symbol + ' FBI ',
                            // }
                            return {
                                text:
                                    r.symbol +
                                    '  ' +
                                    (r.company ? r.company : ''),
                                // add the company to the value so if user types a company name it will return in results but also display in list
                                // if value is not part of list it will not show even if its in the source, becuase input field acts as a filter
                                // click works by the displayed value so it doesn't matter
                                value:
                                    r.symbol +
                                    ' ' +
                                    (r.company ? r.company : '') +
                                    ' ' +
                                    (r.alias ? r.alias : ''), // else will add 'null' as text

                                // 18.07.22 this value is old, from before we started using a react-select for results
                                // value: (
                                //     <MenuItem
                                //         onClick={() => {
                                //             console.log(
                                //                 'clicked menu',
                                //                 r.symbol,
                                //             )
                                //             this.gotoStock(r.symbol)
                                //         }}
                                //         primaryText={r.symbol}
                                //         secondaryText={
                                //             <span
                                //                 style={{
                                //                     color: '#777',
                                //                     fontSize: 14,
                                //                 }}>
                                //                 {r.company}
                                //             </span>
                                //         }
                                //     />
                                // ),
                            }
                        })

                        console.log('searchSymbol done ds', ds)
                        this.setState({
                            dataSource: ds,
                        })
                    }
                },
                (error) => {
                    console.warn('searchSymbol error:', error)
                },
            )
        }
    }

    queryMarketStatus() {
        this.request = this.api.isMarketOpen().subscribe(
            (response) => {
                if (response && response.data) {
                    if (_.has(response.data, 'is_market_open')) {
                        // this.setState({ marketOpen: response.data.is_market_open })
                        this.props.updateMarketStatus(
                            response.data.is_market_open,
                        )
                        return
                    }
                }

                // this.setState({ marketOpen: null })
                this.props.updateMarketStatus(null)
            },
            (error) => {
                // this.setState({ marketOpen: null })
                this.props.updateMarketStatus(null)
            },
        )
    }

    marketStatus() {
        const {user} = this.props

        if (this.marketStatusInterval) {
            window.clearInterval(this.marketStatusInterval)
            this.marketStatusInterval = null
        }

        if (!this.marketStatusInterval) {
            this.marketStatusInterval = setInterval(() => {
                if (user && user.session_token) {
                    // We have a user (i.e. we're logged in)
                    this.queryMarketStatus()
                }
            }, MARKET_QUERY_INTERVAL)
        }
    }

    render() {
        const {user, logged, loading} = this.props
        const {dataSource} = this.state

        return (
            <div
                style={{
                    flexDirection: 'row',
                    display: 'flex',
                    //marginRight: 8,
                }}>
                <SearchBar
                    onInputChange={this.searchSymbol.bind(this)}
                    dataSource={dataSource}
                    onChange={(opt) => {
                        console.log('selected', opt)
                        this.gotoStock(opt.label.split(' ')[0])
                    }}
                />

                <div
                    style={{
                        flex: 1,
                        padding: 9,
                        marginLeft: 10,
                        color: '#fff',
                        fontSize: 12,
                        lineHeight: 1.4,
                    }}>
                    {moment().format('MMMM Do YYYY')}
                    <br />
                    {user && // user added 17.07.22
                        (this.props.marketOpen === true ? (
                            <span>
                                US markets are <b>OPEN</b>
                            </span>
                        ) : this.props.marketOpen === false ? (
                            <span>
                                US markets are <b>CLOSED</b>
                            </span>
                        ) : (
                            <span>
                                <i className="fa fa-circle-o-notch fa-spin" />{' '}
                                Checking market status...
                            </span>
                        ))}
                </div>

                {user &&
                    (!loading ? ( //17.07.22 was: !loading && user ?(). moved user outside - dont show CircularProgress when no user
                        <div>
                            <IconMenu
                                style={{marginLeft: 20}}
                                iconButtonElement={
                                    <IconButton iconStyle={{color: '#fff'}}>
                                        <AccountIcon />
                                    </IconButton>
                                }
                                targetOrigin={{
                                    horizontal: 'right',
                                    vertical: 'bottom',
                                }}
                                anchorOrigin={{
                                    horizontal: 'right',
                                    vertical: 'top',
                                }}>
                                <div
                                    style={{
                                        padding: 16,
                                    }}>
                                    Logged in as:
                                    <br />
                                    <b>
                                        {user.nickname
                                            ? user.nickname
                                            : user.first_name
                                            ? user.first_name
                                            : user.email}
                                    </b>
                                </div>
                                <MenuItem
                                    primaryText="My profile"
                                    onClick={this.gotoProfile.bind(this)}
                                />
                                <MenuItem
                                    primaryText="Sign out"
                                    onClick={this.props.logout}
                                />
                                <MenuItem
                                    primaryText="Contact us"
                                    href="/#/contact"
                                    //target="_blank"
                                />
                                {/* 28.11.21 https://www.tipigo.com/#contact-us
                                29.05.21 https://www.tipigo.com/contact-intelligence */}
                                {user.is_admin ? (
                                    <MenuItem
                                        primaryText="Create profile (not user!!!)"
                                        href="/#/newProfile"
                                    />
                                ) : null}
                                {user.is_admin ? (
                                    <MenuItem
                                        primaryText="Manage clients"
                                        href="/#/manage/accounts"
                                    />
                                ) : null}

                                {/* {user.is_admin ? (
                                <MenuItem primaryText="Reports" href="/#/reports" />
                                ) : null} */}

                                {user.is_admin ? ( // is_admin refers to system admin
                                    <MenuItem
                                        primaryText="Manage app portfolios"
                                        href="/#/appportfolios"
                                    />
                                ) : null}
                            </IconMenu>
                            <span
                                style={{
                                    color: 'white',
                                    position: 'relative',
                                    bottom: '7px',
                                }}>
                                {user.nickname
                                    ? user.nickname
                                    : user.first_name
                                    ? user.first_name
                                    : user.email}
                            </span>
                        </div>
                    ) : (
                        <CircularProgress
                            color="#fff"
                            size={32}
                            style={{
                                marginRight: 20,
                                marginLeft: 5,
                                padding: 8,
                            }}
                        />
                    ))}
            </div>
        )
    }
}

// Logged.propTypes = {
//   user: PropTypes.object,
// };

const mapStateToProps = (state) => {
    return {
        //user: _.isEmpty(state.user.user) ? null : state.user.user,
        user: _.isEmpty(state.user.user) ? null : state.user.user,
        loading: state.user.isFetching,
        marketOpen: state.finance.marketOpen,
        routing: state.routing,
    }
}

const mapDispatchToProps = (dispatch) => {
    const auth = new Auth()
    const api = new Api()

    return {
        logout: () => {
            return new Promise((resolve, reject) => {
                dispatch(ApiActions.apiRequest('logout'))
                auth.logout().subscribe(
                    (response) => {
                        dispatch(ApiActions.apiResponse('logout', {}))
                        dispatch(getCurrentUser())
                        resolve()
                    },
                    (error) => {
                        dispatch(ApiActions.apiError('logout', error))
                        reject(error)
                    },
                )
            })
        },

        updateMarketStatus: (open) => {
            dispatch(AppActions.marketStatusUpdate(open))
        },
    }
}

const LoggedComponent = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Logged),
)

export default class Header extends Component {
    render() {
        const {user, logout} = this.props

        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 10,
                }}>
                <header className="header">
                    <AppBar
                        style={{
                            paddingRight: Dimensions.PAGE_PADDING_RIGHT,
                            paddingLeft: Dimensions.PAGE_PADDING_LEFT,
                            boxShadow: 'none',
                        }}
                        iconElementLeft={
                            <div style={{padding: 8, marginLeft: 8}}>
                                <Link to={'/?tab=6'}>
                                    <img
                                        src={
                                            require('@images/site-logo.svg')
                                                .default
                                        }
                                    />
                                </Link>
                            </div>
                        }
                        iconElementRight={<LoggedComponent />}
                    />
                </header>
            </div>
        )
    }
}

const SearchBarWrapper = styled.div`
    > div {
        border-radius: 50px;
    }
`
