import clStorage from '../cl_modules/cl-storage'

const getItem = key => new Promise(resolve=>clStorage.getItem(key).then(data=>{ try{resolve(JSON.parse(data))} catch(err){resolve(data)}}))
const setItem = (key, data) => clStorage.setItem(key, JSON.stringify(data))

export const ALERTS_FILTERS_STORAGE_KEY = `ALERTS_FILTERS_STORAGE_KEY`
export const getFiltersForAlert = () => getItem(ALERTS_FILTERS_STORAGE_KEY)
export const setFiltersForAlert = data => setItem(ALERTS_FILTERS_STORAGE_KEY, data)

export const ALERTS_FILTERS_BY_USER_KEY = user => `ALERTS_FILTERS_KEY_FOR_USER_${user.user_id}`
export const getFiltersByUser = user => getItem(ALERTS_FILTERS_BY_USER_KEY(user || { user_id: 'none' }))
export const setFiltersByUser = (user, filters) => setItem(ALERTS_FILTERS_BY_USER_KEY(user), filters||user.alet_filters)

export const PROFILE_BY_USER_KEY = user => `PROFILE_FOR_USER_${user.user_id}`
export const getProfileByUser = user => getItem(PROFILE_BY_USER_KEY(user||{user_id: 'none'}))
export const setProfileByUser = (user, profile) => setItem(PROFILE_BY_USER_KEY(user), profile||user.current_profile)
