import _ from 'lodash'

export default class Analytics {
    static exec(method) {
        console.log('Analytics1')

        let args = Array.from(arguments)
        args.shift()

        if (window.analytics) {
            console.log('Analytics2', method, args)
            let func = window.analytics[method]

            if (func && _.isFunction(func)) {
                console.log('Analytics3', method, args)
                setTimeout(() => func.apply(this, args), 10)
            }
        }
    }
}
