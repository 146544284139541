import React from 'react'
import FlatButton from 'material-ui/FlatButton'
import FontIcon from 'material-ui/FontIcon'
import Divider from 'material-ui/Divider'
import CircularProgress from 'material-ui/CircularProgress'
import RaisedButton from 'material-ui/RaisedButton'
import {NavBarButton} from './InvestmentProfile/InvestmentProfile.styled'
import {withRouter} from 'react-router-dom'

import {Dimensions} from '@services/styles'
import {api} from '@services/api'
import * as AppActions from '@store/actions/app-actions'
import {List, ListItem} from 'material-ui/List'
import * as Colors from 'material-ui/styles/colors'
//import Dialog from 'material-ui/Dialog'

import Screener1 from './InvestmentProfile/Screener1'
import Header from './Header'
import ItemsDialog from './InvestmentProfile/ItemsDialog'
import {ScreenersInfo} from '@constants/Finance'
import Analytics from '@services/analytics'
import styled from 'styled-components'
import NavAppBar from './NavAppBar'
import _ from 'lodash'
import swal from 'sweetalert2'
import {
    useDispatch,
    useState,
    useEffect,
    useUser,
    useFinanceInitialized,
    useDataLoading,
    useSectorsLoading,
    useSectors,
    usePortfolioSize,
    useSystemSectors,
} from '@services/hooks'
import {getFinancialData} from '@store/actions'
import {AppColors} from '@services/styles'

const styles = {
    container: {
        display: 'flex',
    },
    content: {
        flex: 1,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#eee',
    },
}

let screenersInitialized, apiCalls, activeSectors, gettingMatches

const InvestmentProfile = () => {
    const dispatch = useDispatch()
    const user = useUser()
    const initialized = useFinanceInitialized()
    const dataLoading = useDataLoading()
    const sectorsLoading = useSectorsLoading()
    const sectors = useSectors()
    const systemSectors = useSystemSectors()
    const portfolioSize = usePortfolioSize()
    const [updatedSectors, setUpdatedSectors] = useState()
    const [updatingPortfolio, setUpdatingPortfolio] = useState()
    const [currentSector, setCurrentSector] = useState(null)
    const [selectedSectors, setSelectedSectors] = useState({})
    const [screenedSymbols, setScreenedSymbols] = useState(null)
    const [sectorDialogOpen, setSectorDialogOpen] = useState(false)
    const [filtersModalOpen, setFiltersModalOpen] = useState(false)

    useEffect(() => {
        screenersInitialized = false
        gettingMatches = false
        apiCalls = {}
        activeSectors = []
        if (!user || !user.session_token) {
            console.warn(
                'Missing user or session token, redirecting back to login',
            )
            // TODO: back to login or something
            return
        }

        // Check if we already initialized the finance data
        if (!initialized && !dataLoading && !sectorsLoading) {
            // No data, let's get some and show a spinner while we're at it
            if (user.account_type === 'user' && user.current_profile) {
                dispatch(getFinancialData(user.current_profile))
            } else {
                dispatch(getFinancialData(user.session_token))
            }
        }

        return () => {
            screenersInitialized = false

            _.forOwn(apiCalls, (apiCall, key) => {
                if (apiCall && _.isFunction(apiCall.dispose)) apiCall.dispose()
            })
        }
    }, [])

    useEffect(() => {
        console.log('got_sectors', sectors)
        if (!_.isEmpty(sectors) && !updatedSectors) {
            setUpdatedSectors(_.cloneDeep(sectors))

            //--new--new--new--------------------
            // is it correct? why it wasnt here? why the duplicate between updatedSectors and selectedSectors?
            let initSeletedSectors = {}
            sectors.forEach((s) => {
                initSeletedSectors[s.name] = true
            })
            setSelectedSectors(initSeletedSectors)
            setCurrentSector(sectors[0].name)
            //--new--new--new--------------------
        }
    }, [sectors])

    useEffect(() => {
        console.log('got_user', user)
    }, [user])

    useEffect(() => {
        getMatchingStocks()
    }, [updatedSectors, currentSector])

    const handleSave = () => {
        // const sectors = this.sectorsState // this.expandSectors(this.sectorsState)

        const params = {
            data: {
                pf_data: {
                    newBuild: true,
                    portfolioSize,
                    sectors: updatedSectors,
                    teamUpdatePeriod: 1, // 1 month
                },
            },
        }
        console.log('saving', params)

        setUpdatingPortfolio(true)

        Analytics.exec('track', 'User clicked save profile button')
        console.log('editSuperPortfolio params', params)
        //return

        apiCalls['editSuperPortfolio'] = api
            .editSuperPortfolio(params)
            .subscribe(
                (response) => {
                    console.log('save profile response', response)
                    // Saved successfully
                    setUpdatingPortfolio(false)
                    dispatch(AppActions.portfolioStatusUpdate(updatedSectors))

                    swal.fire({
                        title: 'Success',
                        text: 'Profile is being processed in the background and will be ready in a few minutes',
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                        cancelButtonText: 'Great',
                    })
                },
                (error) => {
                    console.log('save profile error', error)
                    swal.fire({
                        text: 'save failed. ' + error?.data?.result,
                        icon: 'error',
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                    })
                    setUpdatingPortfolio(false)
                    dispatch(AppActions.portfolioStatusUpdate(updatedSectors))
                },
            )
    }

    const handleScreenerChange = (type, value, a, b) => {
        console.log('handleScreenerChange start')
        if (updatedSectors) {
            // Based on the current sector, make the change
            let sectorIndex = _.findIndex(updatedSectors, {
                name: currentSector,
            })
            if (sectorIndex > -1) {
                let sector = updatedSectors[sectorIndex]
                let newScreeners
                // Check if we already have a screener of this type
                let screenerIndex = _.findIndex(sector.screeners, {field: type})
                if (screenerIndex > -1) {
                    let screener = sector.screeners[screenerIndex]
                    let newScreener = updateOrCreateScreener(
                        screener,
                        type,
                        value,
                        a,
                        b,
                    )
                    // newScreeners = {
                    //     ...updatedSectors[sectorIndex].screeners,
                    //     [screenerIndex]: newScreener,
                    // }
                    // replace above by next
                    newScreeners = [...updatedSectors[sectorIndex].screeners]
                    newScreeners[screenerIndex] = newScreener
                } else {
                    console.log('new screener')
                    // We need to create a new screener
                    let newScreener = updateOrCreateScreener(
                        null,
                        type,
                        value,
                        a,
                        b,
                    )
                    newScreeners = {
                        ...updatedSectors[sectorIndex].screeners,
                    }
                    newScreeners.push(newScreener)

                    // this.sectorsState[sectorIndex].screeners.push(newScreener)
                }
                console.log(
                    'updatedSectors handleScreenerChange1',
                    updatedSectors,
                )

                // setUpdatedSectors({
                //     ...updatedSectors,
                //     [sectorIndex]: {
                //         ...updatedSectors[sectorIndex],
                //         screeners: newScreeners,
                //     },
                // })
                // replace above by next
                const newUpdatedSectors = [...updatedSectors]
                newUpdatedSectors[sectorIndex] = {
                    ...updatedSectors[sectorIndex],
                    screeners: newScreeners,
                }
                console.log(
                    // ok
                    'updatedSectors handleScreenerChange2',
                    newScreeners,
                )
                setUpdatedSectors(newUpdatedSectors)
                getMatchingStocks()
            }
        }
    }

    useEffect(() => {
        // it SEEMS to get here ok
        console.log('updatedSectors', updatedSectors)
    }, [updatedSectors])

    const createNewSectorObject = (name) => {
        let s = _.find(systemSectors, {name})
        console.log('createNewSectorObject', s)

        if (!s) {
            console.warn('Invalid sector:', name)
            return null
        }

        return {
            ...s,
            sector_ref_id: s.id,
            size: 100000,
            vts_percentage: 0,
            svts_percentage: 0,
            val: 0,
            symbol: s.etf,
            stoploss: 10,
            target_percentage: 0,
            ratings_to_copy: [],
            up_down_ratio: 1,
            sp_500_only: false,
            logic: 0,
            strategies: [],
            target_amount: 0.5,
            screeners: [
                updateOrCreateScreener(
                    null,
                    'market_val',
                    'greater_than',
                    2000,
                    null,
                ),
                updateOrCreateScreener(null, 'beta', 'between', 0.5, 2.5),
            ],
        }
    }

    const updateOrCreateScreener = (screener, type, value, a, b) => {
        let newScreener = {
            ...(screener || {
                field: type,
            }),
        }

        if (newScreener.id) delete newScreener.id

        if (type === 'indexes') {
            newScreener.selector = a || null // '_all'
        } else if (
            ['pe_ratio_12m', 'peg_ratio', 'dividend_yield'].indexOf(type) >
                -1 &&
            _.isObject(value)
        ) {
            let v = _.toPairs(value)
                .filter((o) => o[1])
                .map((v) => v[0])
            newScreener.multi_selector = v && _.isArray(v) ? v : []
        } else if (value === 'greater_than') {
            delete newScreener.maxVal
            newScreener.minVal = parseFloat(a)
        } else if (value === 'less_than') {
            delete newScreener.minVal
            newScreener.maxVal = parseFloat(b)
        } else if (value === 'between') {
            newScreener.minVal = parseFloat(a)
            newScreener.maxVal = parseFloat(b)
        } else if (_.isObject(value)) {
            // Just pass the object as is, and we will sort this
            // out on the outgoing expandScreeners call
            newScreener.value = value
        }

        return newScreener
    }

    const initScreeners = () => {
        if (!initialized) {
            return
        }

        if (sectorsLoading) {
            return
        }

        if (screenersInitialized) {
            return
        }

        // Filter sectors which have screeners
        activeSectors = _.cloneDeep(sectors)
        activeSectors.forEach((s) => (selectedSectors[s.name] = true))
        handleSelectedSectorsUpdate(selectedSectors, true)

        this.screenersInitialized = true
    }

    const updateCurrentSector = (cs) => {
        setCurrentSector(cs)
        setScreenedSymbols(null)
    }

    const getMatchingStocks = () => {
        if (gettingMatches || !currentSector) {
            // Already getting matches, skipping...
            return
        }

        let sector = _.find(updatedSectors, {name: currentSector})
        if (sector) {
            gettingMatches = true
            apiCalls['getScreenedSymbols'] = api
                .getScreenedSymbols(sector)
                .subscribe(
                    (response) => {
                        if (
                            response.data &&
                            response.data.status &&
                            _.isObject(response.data.screened_symbols)
                        ) {
                            setScreenedSymbols(response.data.screened_symbols)
                        } else {
                            setScreenedSymbols(null)
                        }

                        gettingMatches = false
                    },
                    (error) => {
                        setScreenedSymbols(null)
                        gettingMatches = false
                    },
                )
        }
    }

    const handleSelectedSectorsUpdate = (selSectors, initialInvoke = false) => {
        let numOfSelectedSectors = _.filter(
            _.values(selSectors),
            (s) => s,
        ).length

        setSelectedSectors(selSectors)

        const newUpdatedSectors = _.filter(
            updatedSectors,
            (sector) => selSectors[sector.name] === true,
        )

        setUpdatedSectors(newUpdatedSectors)

        // If we have a new sector that's not in the state already
        // let's add it.
        _.forOwn(selSectors, (enabled, sectorName) => {
            if (enabled === true) {
                let sectorIndex = _.findIndex(newUpdatedSectors, {
                    name: sectorName,
                })
                if (sectorIndex === -1) {
                    newUpdatedSectors.push(createNewSectorObject(sectorName))
                }
            }
        })

        if (!numOfSelectedSectors || numOfSelectedSectors === 0) {
            setCurrentSector(null)
        } else {
            let validSector = null
            _.forOwn(selSectors, (v, k) => {
                if (!validSector) {
                    if (v) {
                        validSector = k
                    }
                }
            })

            setCurrentSector(validSector)
        }

        setUpdatedSectors(newUpdatedSectors)
    }

    const handleSelectedScreenerUpdate = (selScreeners) => {
        console.log(
            'handleSelectedScreenerUpdate',
            selScreeners,
            updatedSectors,
            currentSector,
        )
        // Update current sector screeners
        let sectorIndex = _.findIndex(updatedSectors, {name: currentSector})

        if (sectorIndex > -1) {
            console.log('handleSelectedScreenerUpdate3', sectorIndex)
            let screeners = _.filter(
                updatedSectors[sectorIndex].screeners,
                (s) => {
                    let screenerName = ScreenersInfo[s.field]
                        ? ScreenersInfo[s.field].title
                        : 'N/A'
                    return selScreeners[screenerName] === true
                },
            )
            console.log('handleSelectedScreenerUpdate4', screeners)

            // Create a new object in which the keys are the screener
            // titles and the values are the IDs
            const screenerIds = _.invert(
                _.mapValues(ScreenersInfo, (o) => o.title),
            )
            console.log('handleSelectedScreenerUpdate5', screenerIds)

            _.forOwn(selScreeners, (enabled, screenerName) => {
                if (enabled === true) {
                    // Check if screener is already in screeners list
                    let s = _.find(screeners, {
                        field: screenerIds[screenerName],
                    })
                    if (!s) {
                        // No screener, let's create a new one
                        screeners.push(
                            updateOrCreateScreener(
                                null,
                                screenerIds[screenerName],
                            ),
                        )
                    }
                }
            })
            console.log('handleSelectedScreenerUpdate6', screeners)

            // const newUpdatedSectors = {
            //     ...updatedSectors,
            //     [sectorIndex]: {...updatedSectors[sectorIndex], screeners},
            // }
            // 7.12.21 was like above, change to []. think it has to be an array but don't know if and when it worked...
            const newUpdatedSectors = [...updatedSectors]
            newUpdatedSectors[sectorIndex] = {
                ...updatedSectors[sectorIndex],
                screeners,
            }
            console.log('newUpdatedSectors', updatedSectors, newUpdatedSectors)
            setUpdatedSectors(newUpdatedSectors)
        }
    }

    const applyToAllSectors = (screener) => {
        let screenerName = ScreenersInfo[screener.field]
            ? ScreenersInfo[screener.field].title
            : 'N/A'

        swal.fire({
            title: 'Apply across all sectors?',
            text:
                'This will add the ' +
                screenerName +
                ' filter to all sectors and ' +
                'will override any existing ' +
                screenerName +
                ' filters that have been set for the sector',
            //buttonsStyling: 'false',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: AppColors.TIPIGO_GREEN,
            confirmButtonText: 'Yes, do it!',
        }).then(
            (response) => {
                if (response && response.isConfirmed) {
                    const newUpdatedSectors = updatedSectors.map((sector) => {
                        if (sector.name !== currentSector) {
                            // Get the screener if it already exists for this sector
                            _.remove(sector.screeners, {field: screener.field})
                            sector.screeners.push(screener)
                        }

                        return sector
                    })
                    setUpdatedSectors(newUpdatedSectors)
                }
            },
            (error) => {
                console.log('cancelled')
            },
        )
    }

    const removeScreener = (screenerType) => {
        // Update current sector screeners
        let sectorIndex = _.findIndex(updatedSectors, {name: currentSector})

        if (sectorIndex > -1) {
            const newUpdatedSectors = [...updatedSectors]
            newUpdatedSectors[sectorIndex].screeners = _.filter(
                newUpdatedSectors[sectorIndex].screeners,
                (s) => s.field !== screenerType,
            )

            setUpdatedSectors(newUpdatedSectors)
        }
    }

    const removeSector = (sectorName) => {
        swal.fire({
            title: 'Remove ' + sectorName + '?',
            text: 'You will lose all current settings for this sector',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            confirmButtonColor: AppColors.TIPIGO_GREEN,
        }).then(
            (response) => {
                if (response && response.isConfirmed) {
                    let newSelectedSectors = {...selectedSectors}
                    if (newSelectedSectors[sectorName]) {
                        delete newSelectedSectors[sectorName]
                    }
                    handleSelectedSectorsUpdate(newSelectedSectors)
                }
            },
            (error) => console.log('cancelled'),
        )
    }

    const handleDialogOpen = () => {
        setSectorDialogOpen(true)
    }

    const handleDialogClose = () => {
        setSectorDialogOpen(false)
    }

    const handleFiltersDialogOpen = () => {
        setFiltersModalOpen(true)
    }

    const handleFiltersDialogClose = () => {
        setFiltersModalOpen(false)
    }

    const handleFiltersDialogCloseRequest = () => {}

    // const renderFiltersModal = () => {
    //     return (
    //         <Dialog
    //             title="Select filters"
    //             actions={[
    //                 <FlatButton
    //                     label="Cancel_"
    //                     primary={true}
    //                     onClick={handleFiltersDialogClose}
    //                 />,
    //                 <FlatButton
    //                     label="Submit"
    //                     primary={true}
    //                     keyboardFocused={true}
    //                     onClick={handleFiltersDialogClose}
    //                 />,
    //             ]}
    //             modal={false}
    //             open={filtersModalOpen}
    //             onRequestClose={handleFiltersDialogCloseRequest}></Dialog>
    //     )
    // }

    const renderSideMenu = () => {
        console.log('renderSideMenu selectedSectors', selectedSectors)
        return (
            <div style={{width: 250}}>
                <List>
                    {systemSectors.length > 0
                        ? systemSectors.map((sector, index) => {
                              let leftIcon = null
                              let sectorConstant = _.find(systemSectors, {
                                  name: sector.name,
                              })
                              if (sectorConstant) {
                                  if (_.has(sectorConstant, 'svg')) {
                                      leftIcon = (
                                          <img
                                              src={
                                                  require('@images/sector_icons/' +
                                                      sectorConstant.svg +
                                                      '.svg').default
                                              }
                                          />
                                      )
                                  } else {
                                      leftIcon = (
                                          <FontIcon className="material-icons">
                                              label
                                          </FontIcon>
                                      )
                                  }
                              }

                              if (selectedSectors[sector.name]) {
                                  return (
                                      <span key={'sector_' + index}>
                                          <ListItem
                                              onClick={() => {
                                                  updateCurrentSector(
                                                      sector.name,
                                                  )
                                              }}
                                              style={{
                                                  fontSize: 13,
                                                  backgroundColor:
                                                      currentSector ===
                                                      sector.name
                                                          ? '#eee'
                                                          : null,
                                              }}
                                              primaryText={sector.name}
                                              leftIcon={leftIcon}
                                          />
                                          <Divider />
                                      </span>
                                  )
                              }
                          })
                        : null}

                    <ListItem disabled={true}>
                        <FlatButton
                            label="Add Sector"
                            primary={true}
                            onClick={handleDialogOpen}
                            labelPosition="after"
                        />
                    </ListItem>
                </List>
            </div>
        )
    }

    // Find currently selected sector
    // const sector = _.find(sectors, {name: currentSector})
    // replace above by next
    // what is useSectors above? is it updated? - yes, after save. is it user specific? - yes
    /*
    const sector = _.find(updatedSectors, {name: currentSector})
    let screeners = sector && sector.screeners ? sector.screeners : []
    let screenerNames = {}
    if (screeners) {
        screeners
            .map((s) =>
                ScreenersInfo[s.field] ? ScreenersInfo[s.field].title : 'N/A',
            )
            .forEach((name) => {
                screenerNames[name] = true
            })
    }*/

    const [screeners, setScreeners] = useState({})
    const [screenerNames, setScreenerNames] = useState({})
    useEffect(() => {
        if (updatedSectors && currentSector) {
            const sector = _.find(updatedSectors, {name: currentSector})
            let _screeners = sector && sector.screeners ? sector.screeners : []
            setScreeners(_screeners)
            let _screenerNames = {}
            if (_screeners) {
                //console.log('screeners', screeners)
                _screeners
                    .map((s) =>
                        ScreenersInfo[s.field]
                            ? ScreenersInfo[s.field].title
                            : 'N/A',
                    )
                    .forEach((name) => {
                        _screenerNames[name] = true
                    })
            }
            setScreenerNames(_screenerNames)
        }
    }, [updatedSectors, currentSector])

    return (
        <div>
            <Header />

            <div
                style={{
                    marginTop: Dimensions.NAVBAR_TOP_OFFSET,
                    // padding: Dimensions.CONTENT_BASE_PADDING,
                    paddingTop: 20 + Dimensions.APP_BAR_HEIGHT,
                }}>
                <ItemsDialog
                    options={_.map(systemSectors, (s) => s.name)}
                    title="Select Sectors"
                    preSelected={selectedSectors}
                    submit={handleSelectedSectorsUpdate}
                    handleClose={handleDialogClose}
                    open={sectorDialogOpen}
                />

                <ItemsDialog
                    options={_.map(_.values(ScreenersInfo), (o) => o.title)}
                    title="Select Filters"
                    preSelected={screenerNames}
                    submit={handleSelectedScreenerUpdate}
                    handleClose={handleFiltersDialogClose}
                    open={filtersModalOpen}
                />

                {updatingPortfolio ? (
                    <div
                        style={{
                            position: 'fixed',
                            top:
                                Dimensions.NAVBAR_TOP_OFFSET +
                                Dimensions.APP_BAR_HEIGHT,
                            width: '100%',
                            bottom: 0,
                            backgroundColor: 'rgba(255,255,255,.75)',
                            zIndex: 100,
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        <div style={{flex: 1, textAlign: 'center'}}>
                            <CircularProgress />
                            <div>Updating profile...</div>
                        </div>
                    </div>
                ) : null}

                {currentSector && !(dataLoading || sectorsLoading) ? (
                    <NavAppBar
                        rightElement={
                            <NavBarButton
                                disabled={updatingPortfolio}
                                onClick={handleSave}
                                label="Save"
                                secondary={true}
                            />
                        }
                    />
                ) : null}

                {dataLoading || sectorsLoading ? (
                    <div style={{textAlign: 'center'}}>
                        <CircularProgress style={{flex: 1}} />
                        <p>Loading...</p>
                    </div>
                ) : (
                    <div style={styles.container}>
                        {currentSector ? renderSideMenu() : null}

                        {currentSector ? (
                            <div
                                style={{
                                    ...styles.content,
                                    flexDirection: 'row',
                                }}>
                                {screeners && screeners.length > 0 ? (
                                    <div
                                        style={{
                                            flex: 1,
                                            flexDirection: 'column',
                                        }}>
                                        {screeners.map((screener, i) => {
                                            let value = '_all'

                                            if (
                                                _.has(screener, 'minVal') &&
                                                _.has(screener, 'maxVal')
                                            ) {
                                                value = {
                                                    minVal: screener.minVal,
                                                    maxVal: screener.maxVal,
                                                }
                                            } else if (
                                                _.has(screener, 'minVal')
                                            ) {
                                                value = {
                                                    minVal: screener.minVal,
                                                }
                                            } else if (
                                                _.has(screener, 'maxVal')
                                            ) {
                                                value = {
                                                    maxVal: screener.maxVal,
                                                }
                                            } else if (
                                                _.has(screener, 'selector') &&
                                                screener.selector &&
                                                screener.selector.length > 0
                                            ) {
                                                value = screener.selector
                                            } else if (
                                                _.has(
                                                    screener,
                                                    'multi_selector',
                                                ) &&
                                                _.isArray(
                                                    screener.multi_selector,
                                                )
                                            ) {
                                                value = screener.multi_selector
                                            }

                                            return (
                                                <Screener1
                                                    currentSector={
                                                        currentSector
                                                    }
                                                    key={'screener_' + i}
                                                    screenerType={
                                                        screener.field
                                                    }
                                                    data={{value}}
                                                    matchingStocks={
                                                        screenedSymbols &&
                                                        screenedSymbols[
                                                            screener.field
                                                        ]
                                                            ? screenedSymbols[
                                                                  screener.field
                                                              ]
                                                            : null
                                                    }
                                                    removeScreener={
                                                        removeScreener
                                                    }
                                                    onScreenerChange={
                                                        handleScreenerChange
                                                    }
                                                    applyAcrossAllSectors={() => {
                                                        let sectorIndex =
                                                            _.findIndex(
                                                                updatedSectors,
                                                                {
                                                                    name: currentSector,
                                                                },
                                                            )
                                                        if (sectorIndex > -1) {
                                                            let newScreener =
                                                                _.find(
                                                                    updatedSectors[
                                                                        sectorIndex
                                                                    ].screeners,
                                                                    {
                                                                        field: screener.field,
                                                                    },
                                                                )

                                                            if (newScreener) {
                                                                applyToAllSectors(
                                                                    newScreener,
                                                                )
                                                            }
                                                        }
                                                    }}
                                                />
                                            )
                                        })}

                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}>
                                            <FlatButton
                                                label="Add filter"
                                                primary={true}
                                                onClick={
                                                    handleFiltersDialogOpen
                                                }
                                                labelPosition="after"
                                            />

                                            <FlatButton
                                                label={
                                                    'Remove ' + currentSector
                                                }
                                                labelStyle={{
                                                    color: Colors.red400,
                                                }}
                                                onClick={() =>
                                                    removeSector(currentSector)
                                                }
                                                labelPosition="after"
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                {!screeners || screeners.length < 1 ? (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            flex: 1,
                                            flexDirection: 'column',
                                        }}>
                                        <p>
                                            No filters defined. Add a few
                                            filters to receive investment
                                            opportunities tailored to your
                                            specific investment profile.
                                        </p>

                                        <RaisedButton
                                            label="Add filter"
                                            primary={true}
                                            onClick={handleFiltersDialogOpen}
                                            labelPosition="after"
                                            icon={
                                                <FontIcon className="material-icons">
                                                    add
                                                </FontIcon>
                                            }
                                        />

                                        <div style={{marginTop: 30}}>
                                            <FlatButton
                                                label={
                                                    'Remove ' + currentSector
                                                }
                                                labelStyle={{
                                                    color: Colors.red400,
                                                }}
                                                onClick={() =>
                                                    removeSector(currentSector)
                                                }
                                                labelPosition="after"
                                                icon={
                                                    <FontIcon
                                                        className="material-icons"
                                                        color={Colors.red400}>
                                                        close
                                                    </FontIcon>
                                                }
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                <div style={{width: 200, paddingTop: 60}}>
                                    <MatchingNumber>
                                        <Matching>
                                            {screenedSymbols?.total_passed || 0}
                                        </Matching>
                                        <div>Matching stocks</div>
                                    </MatchingNumber>
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    ...styles.content,
                                    backgroundColor: '#fff',
                                }}>
                                <div
                                    style={{
                                        minHeight: 200,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: 16,
                                    }}>
                                    <div
                                        style={{
                                            flex: 1,
                                            textAlign: 'center',
                                        }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                maxWidth: 600,
                                                margin: 'auto',
                                            }}>
                                            {[
                                                {
                                                    side: 'l',
                                                    description:
                                                        'Define your investment profile by selecting custom sectors and filters.',
                                                },
                                                {
                                                    side: 'c',
                                                    description:
                                                        "Receive tailored investment opportunities located by Tipigo's algorithms.",
                                                },
                                                {
                                                    side: 'r',
                                                    description:
                                                        'Follow the recommendations on your watchlist to receive valuable alerts',
                                                },
                                            ].map((o, i) => (
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        margin: 20,
                                                    }}
                                                    key={'img_' + o.side}>
                                                    <img
                                                        style={{
                                                            width: '80%',
                                                        }}
                                                        src={
                                                            require('@images/sector_intro_' +
                                                                o.side +
                                                                '.png').default
                                                        }
                                                    />
                                                    <div
                                                        style={{
                                                            width: 40,
                                                            height: 40,
                                                            color: 'rgb(84, 93, 109)',
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            margin: 'auto',
                                                            marginTop: 10,
                                                            borderRadius: 40,
                                                            borderWidth: 2,
                                                            borderColor:
                                                                'rgb(84, 93, 109)',
                                                            borderStyle:
                                                                'solid',
                                                            fontSize: 20,
                                                        }}>
                                                        <span
                                                            style={{
                                                                flex: 1,
                                                            }}>
                                                            {i + 1}
                                                        </span>
                                                    </div>
                                                    <p>{o.description}</p>
                                                </div>
                                            ))}
                                        </div>

                                        <div>
                                            <RaisedButton
                                                onClick={handleDialogOpen}
                                                style={{marginTop: 20}}
                                                label="Define Investment Profile"
                                                primary={true}
                                            />
                                        </div>

                                        {/*<ReactTooltip id='_quickstart'
                        class='extraClass'
                        place="right"
                        type='info' effect='solid'>
                        <p>
                          A helpful tooltip
                        </p>
                      </ReactTooltip>

                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 10
                      }}>
                        <FlatButton label="Quickstart" primary={true} />

                        <FontIcon style={{
                            color: '#aaa',
                            cursor: 'default',
                            marginLeft: 10
                          }}
                          data-tip data-for='_quickstart'
                          className="material-icons">info</FontIcon>
                      </div>*/}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
export default withRouter(InvestmentProfile)

const MatchingNumber = styled.div`
    border-left-width: 3px;
    border-left-color: #c3c3c3;
    border-left-style: solid;
    margin-left: 40px;
    padding-left: 10px;
`
const Matching = styled.h3`
    font-size: 38px;
    margin: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 200;
`
{
    // class InvestmentProfile extends Component {
    //     constructor(props, context) {
    //         super(props, context)
    //         this.activeSectors = []
    //         // A place where we store the new/updated state
    //         // which we will later send to the server
    //         this.sectorsState = null
    //         this.api = new Api()
    //         this.apiCalls = {}
    //         this.getMatchingStocks = _.debounce(this.getMatchingStocks, 400)
    //         this.shouldAlertUserBeforeExit = false
    //         this.state = {
    //             sectors: [],
    //             updatingPortfolio: false,
    //             sectorDialogOpen: false,
    //             selectedSectors: {},
    //             currentSector: null,
    //             filtersModalOpen: false,
    //             selectedScreeners: {}, // { <sector_name>: [...screeners] }
    //             loading: props.dataLoading || props.sectorsLoading,
    //             screenedSymbols: null,
    //         }
    //     }
    //     componentWillMount() {
    //         const {user, initialized, dataLoading, sectorsLoading} = this.props
    //         if (!user || !user.session_token) {
    //             console.warn(
    //                 'Missing user or session token, redirecting back to login',
    //             )
    //             // TODO: back to login or something
    //             return
    //         }
    //         // Check if we already initialized the finance data
    //         if (!initialized && !dataLoading && !sectorsLoading) {
    //             // No data, let's get some and show a spinner while we're at it
    //             if (user.account_type === 'user' && user.current_profile) {
    //                 this.props.getFinancialData(user.current_profile)
    //             } else {
    //                 this.props.getFinancialData(user.session_token)
    //             }
    //         }
    //     }
    //     componentDidMount() {
    //         // Init state
    //         this.setSectorsState(true)
    //         this.setState({sectors: this.sectorsState}, () => {
    //             this.initScreeners()
    //         })
    //     }
    //     componentWillReceiveProps(nextProps) {
    //         this.initScreeners()
    //         // if (this.props.sectors !== nextProps.sectors) {
    //         //   // Sectors have changed, let's update their state
    //         //   this.setState({ sectors: nextProps.sectors })
    //         // }
    //     }
    //     componentWillUnmount() {
    //         this.screenersInitialized = false
    //         _.forOwn(this.apiCalls, (apiCall, key) => {
    //             if (apiCall && _.isFunction(apiCall.dispose)) apiCall.dispose()
    //         })
    //     }
    //     handleSave() {
    //         const sectors = this.sectorsState // this.expandSectors(this.sectorsState)
    //         const params = {
    //             data: {
    //                 pf_data: {
    //                     newBuild: true,
    //                     portfolioSize: this.props.portfolioSize,
    //                     sectors: sectors,
    //                     teamUpdatePeriod: 1, // 1 month
    //                 },
    //             },
    //         }
    //         this.setState({updatingPortfolio: true})
    //         Analytics.exec('track', 'User clicked save profile button')
    //         console.log('editSuperPortfolio params', params)
    //         this.apiCalls['editSuperPortfolio'] = this.api
    //             .editSuperPortfolio(params)
    //             .subscribe(
    //                 (response) => {
    //                     // Saved successfully
    //                     this.setState({updatingPortfolio: false})
    //                     this.props.commitPortfolioState(this.sectorsState)
    //                     // Reset flag
    //                     this.shouldAlertUserBeforeExit = false
    //                     swal({
    //                         title: 'Success',
    //                         text: 'Your profile has been saved successfully',
    //                         buttonsStyling: 'false',
    //                         reverseButtons: 'true',
    //                         showCancelButton: true,
    //                         showConfirmButton: false,
    //                         cancelButtonColor: '#d33',
    //                         cancelButtonText: 'Great',
    //                     })
    //                 },
    //                 (error) => {
    //                     this.setState({updatingPortfolio: false})
    //                     this.props.commitPortfolioState(this.sectorsState)
    //                 },
    //             )
    //     }
    //     setSectorsState(reset = false) {
    //         if (reset === true) {
    //             this.sectorsState = null
    //         }
    //         if (!this.sectorsState || _.isEmpty(this.sectorsState)) {
    //             // No state yet, let's create it
    //             // Store the current/latest state of the sectors and screeners
    //             this.sectorsState = _.cloneDeep(this.props.sectors)
    //         }
    //     }
    //     handleScreenerChange(type, value, a, b) {
    //         this.setSectorsState()
    //         this.shouldAlertUserBeforeExit = true
    //         if (this.sectorsState) {
    //             // Based on the current sector, make the change
    //             let sectorIndex = _.findIndex(this.sectorsState, {
    //                 name: this.state.currentSector,
    //             })
    //             if (sectorIndex > -1) {
    //                 let sector = this.sectorsState[sectorIndex]
    //                 // Check if we already have a screener of this type
    //                 let screenerIndex = _.findIndex(sector.screeners, {field: type})
    //                 if (screenerIndex > -1) {
    //                     let screener = sector.screeners[screenerIndex]
    //                     let newScreener = this.updateOrCreateScreener(
    //                         screener,
    //                         type,
    //                         value,
    //                         a,
    //                         b,
    //                     )
    //                     this.sectorsState[sectorIndex].screeners[screenerIndex] =
    //                         newScreener
    //                 } else {
    //                     // We need to create a new screener
    //                     let newScreener = this.updateOrCreateScreener(
    //                         null,
    //                         type,
    //                         value,
    //                         a,
    //                         b,
    //                     )
    //                     this.sectorsState[sectorIndex].screeners.push(newScreener)
    //                 }
    //                 this.getMatchingStocks()
    //             }
    //         }
    //     }
    //     createNewSectorObject(name) {
    //         let s = _.find(this.props.systemSectors, {name})
    //         if (!s) {
    //             console.warn('Invalid sector:', name)
    //             return null
    //         }
    //         return Object.assign({}, s, {
    //             sector_ref_id: s.id,
    //             size: 100000,
    //             vts_percentage: 0,
    //             svts_percentage: 0,
    //             val: 0,
    //             symbol: s.etf,
    //             stoploss: 10,
    //             target_percentage: 0,
    //             ratings_to_copy: [],
    //             up_down_ratio: 1,
    //             sp_500_only: false,
    //             logic: 0,
    //             strategies: [],
    //             target_amount: 0.5,
    //             screeners: [
    //                 this.updateOrCreateScreener(
    //                     null,
    //                     'market_val',
    //                     'greater_than',
    //                     2000,
    //                     null,
    //                 ),
    //                 this.updateOrCreateScreener(null, 'beta', 'between', 0.5, 2.5),
    //             ],
    //         })
    //     }
    //     /**
    //      * If a screener is provided, it will get updated.
    //      * If no screen is provided a new one will be created.
    //      */
    //     updateOrCreateScreener(screener, type, value, a, b) {
    //         let newScreener = Object.assign(
    //             {},
    //             screener || {
    //                 field: type,
    //             },
    //         )
    //         if (newScreener.id) delete newScreener.id
    //         if (type === 'indexes') {
    //             newScreener.selector = a || null // '_all'
    //         } else if (
    //             ['pe_ratio_12m', 'peg_ratio', 'dividend_yield'].indexOf(type) >
    //                 -1 &&
    //             _.isObject(value)
    //         ) {
    //             let v = _.toPairs(value)
    //                 .filter((o) => o[1])
    //                 .map((v) => v[0])
    //             newScreener.multi_selector = v && _.isArray(v) ? v : []
    //         } else if (value === 'greater_than') {
    //             delete newScreener.maxVal
    //             newScreener.minVal = parseFloat(a)
    //         } else if (value === 'less_than') {
    //             delete newScreener.minVal
    //             newScreener.maxVal = parseFloat(b)
    //         } else if (value === 'between') {
    //             newScreener.minVal = parseFloat(a)
    //             newScreener.maxVal = parseFloat(b)
    //         } else if (_.isObject(value)) {
    //             // Just pass the object as is, and we will sort this
    //             // out on the outgoing expandScreeners call
    //             newScreener.value = value
    //         }
    //         return newScreener
    //     }
    //     initScreeners() {
    //         if (!this.props.initialized) {
    //             return
    //         }
    //         if (this.props.sectorsLoading) {
    //             return
    //         }
    //         if (this.screenersInitialized) {
    //             return
    //         }
    //         // Filter sectors which have screeners
    //         this.activeSectors = _.cloneDeep(this.props.sectors)
    //         let selectedSectors = this.state.selectedSectors
    //         this.activeSectors.forEach((s) => (selectedSectors[s.name] = true))
    //         this.setSectorsState()
    //         this.handleSelectedSectorsUpdate(selectedSectors, true)
    //         this.setState({sectors: this.sectorsState}, () => {
    //             // Get matching stocks
    //             this.getMatchingStocks()
    //         })
    //         this.screenersInitialized = true
    //     }
    //     updateCurrentSector(currentSector) {
    //         this.setState({currentSector, screenedSymbols: null}, () => {
    //             this.getMatchingStocks()
    //         })
    //     }
    //     getMatchingStocks() {
    //         if (this.gettingMatches === true) {
    //             // Already getting matches, skipping...
    //             return
    //         }
    //         let currentSector = this.state.currentSector
    //         if (!currentSector) {
    //             return
    //         }
    //         let sector = _.find(this.sectorsState, {name: currentSector})
    //         if (sector) {
    //             this.gettingMatches = true
    //             this.apiCalls['getScreenedSymbols'] = this.api
    //                 .getScreenedSymbols(sector)
    //                 .subscribe(
    //                     (response) => {
    //                         if (
    //                             response.data &&
    //                             response.data.status &&
    //                             _.isObject(response.data.screened_symbols)
    //                         ) {
    //                             this.setState({
    //                                 screenedSymbols: response.data.screened_symbols,
    //                             })
    //                         } else {
    //                             this.setState({screenedSymbols: null})
    //                         }
    //                         this.gettingMatches = false
    //                     },
    //                     (error) => {
    //                         this.setState({screenedSymbols: null})
    //                         this.gettingMatches = false
    //                     },
    //                 )
    //         }
    //     }
    //     handleSelectedSectorsUpdate(selectedSectors, initialInvoke = false) {
    //         this.setSectorsState()
    //         if (initialInvoke !== true) {
    //             this.shouldAlertUserBeforeExit = true
    //         }
    //         let numOfSelectedSectors = _.filter(
    //             _.values(selectedSectors),
    //             (s) => s,
    //         ).length
    //         this.setState({selectedSectors})
    //         // Update sector state
    //         // this.sectorsState =
    //         this.sectorsState = _.filter(
    //             this.sectorsState,
    //             (sector) => selectedSectors[sector.name] === true,
    //         )
    //         // If we have a new sector that's not in the state already
    //         // let's add it.
    //         _.forOwn(selectedSectors, (enabled, sectorName) => {
    //             if (enabled === true) {
    //                 let sectorIndex = _.findIndex(this.sectorsState, {
    //                     name: sectorName,
    //                 })
    //                 if (sectorIndex === -1) {
    //                     this.sectorsState.push(
    //                         this.createNewSectorObject(sectorName),
    //                     )
    //                 }
    //             }
    //         })
    //         if (!numOfSelectedSectors || numOfSelectedSectors === 0) {
    //             this.setState({currentSector: null})
    //         } else {
    //             let validSector = null
    //             _.forOwn(selectedSectors, (v, k) => {
    //                 if (!validSector) {
    //                     if (v) {
    //                         validSector = k
    //                     }
    //                 }
    //             })
    //             this.setState({currentSector: validSector})
    //         }
    //         this.setState({sectors: this.sectorsState})
    //         this.getMatchingStocks()
    //     }
    //     handleSelectedScreenerUpdate(selectedScreeners) {
    //         let currentSector = this.state.currentSector
    //         this.setSectorsState()
    //         this.shouldAlertUserBeforeExit = true
    //         // Update current sector screeners
    //         let sectorIndex = _.findIndex(this.sectorsState, {name: currentSector})
    //         if (sectorIndex > -1) {
    //             let screeners = _.filter(
    //                 this.sectorsState[sectorIndex].screeners,
    //                 (s) => {
    //                     let screenerName = ScreenersInfo[s.field]
    //                         ? ScreenersInfo[s.field].title
    //                         : 'N/A'
    //                     return selectedScreeners[screenerName] === true
    //                 },
    //             )
    //             // Create a new object in which the keys are the screener
    //             // titles and the values are the IDs
    //             const screenerIds = _.invert(
    //                 _.mapValues(ScreenersInfo, (o) => o.title),
    //             )
    //             _.forOwn(selectedScreeners, (enabled, screenerName) => {
    //                 if (enabled === true) {
    //                     // Check if screener is already in screeners list
    //                     let s = _.find(screeners, {
    //                         field: screenerIds[screenerName],
    //                     })
    //                     if (!s) {
    //                         // No screener, let's create a new one
    //                         screeners.push(
    //                             this.updateOrCreateScreener(
    //                                 null,
    //                                 screenerIds[screenerName],
    //                             ),
    //                         )
    //                     }
    //                 }
    //             })
    //             this.sectorsState[sectorIndex].screeners = screeners
    //             this.setState({sectors: this.sectorsState}, () => {
    //                 this.getMatchingStocks()
    //             })
    //         }
    //     }
    //     applyAcrossAllSectors(screener) {
    //         this.setSectorsState()
    //         let screenerName = ScreenersInfo[screener.field]
    //             ? ScreenersInfo[screener.field].title
    //             : 'N/A'
    //         swal({
    //             title: 'Apply across all sectors?',
    //             text:
    //                 'This will add the ' +
    //                 screenerName +
    //                 ' filter to all sectors and ' +
    //                 'will override any existing ' +
    //                 screenerName +
    //                 ' filters that have been set for the sector',
    //             buttonsStyling: 'false',
    //             reverseButtons: 'true',
    //             showCancelButton: true,
    //             confirmButtonColor: '#66bb6a',
    //             confirmButtonClass: 'success',
    //             cancelButtonColor: '#d33',
    //             confirmButtonText: 'Yes, do it!',
    //         }).then(
    //             (response) => {
    //                 let sectors = this.state.sectors
    //                 sectors = sectors.map((sector) => {
    //                     if (sector.name !== this.state.currentSector) {
    //                         // Get the screener if it already exists for this sector
    //                         _.remove(sector.screeners, {field: screener.field})
    //                         sector.screeners.push(screener)
    //                     }
    //                     return sector
    //                 })
    //                 this.setState({sectors})
    //             },
    //             (error) => {
    //                 console.log('cancelled')
    //             },
    //         )
    //     }
    //     removeScreener(screenerType) {
    //         this.setSectorsState()
    //         let currentSector = this.state.currentSector
    //         // Update current sector screeners
    //         let sectorIndex = _.findIndex(this.sectorsState, {name: currentSector})
    //         if (sectorIndex > -1) {
    //             this.sectorsState[sectorIndex].screeners = _.filter(
    //                 this.sectorsState[sectorIndex].screeners,
    //                 (s) => s.field !== screenerType,
    //             )
    //             this.setState({sectors: this.sectorsState}, () => {
    //                 this.getMatchingStocks()
    //             })
    //         }
    //     }
    //     removeSector(sectorName) {
    //         let self = this
    //         this.shouldAlertUserBeforeExit = true
    //         swal({
    //             title: 'Remove ' + sectorName + '?',
    //             text: 'You will lose all current settings for this sector',
    //             buttonsStyling: 'false',
    //             reverseButtons: 'true',
    //             showCancelButton: true,
    //             confirmButtonColor: '#3085d6',
    //             cancelButtonColor: '#d33',
    //             confirmButtonText: 'Yes, remove it!',
    //         }).then(
    //             (response) => {
    //                 let newSelectedSectors = Object.assign(
    //                     {},
    //                     self.state.selectedSectors,
    //                 )
    //                 if (newSelectedSectors[sectorName]) {
    //                     delete newSelectedSectors[sectorName]
    //                 }
    //                 self.handleSelectedSectorsUpdate(newSelectedSectors)
    //             },
    //             (error) => console.log('cancelled'),
    //         )
    //     }
    //     handleDialogOpen = () => {
    //         this.setState({sectorDialogOpen: true})
    //     }
    //     handleDialogClose = () => {
    //         this.setState({sectorDialogOpen: false})
    //     }
    //     handleFiltersDialogOpen = () => {
    //         this.setState({filtersModalOpen: true})
    //     }
    //     handleFiltersDialogClose = () => {
    //         this.setState({filtersModalOpen: false})
    //     }
    //     renderFiltersModal = () => {
    //         return (
    //             <Dialog
    //                 title="Select filters"
    //                 actions={[
    //                     <FlatButton
    //                         label="Cancel"
    //                         primary={true}
    //                         onClick={this.handleFiltersDialogClose}
    //                     />,
    //                     <FlatButton
    //                         label="Submit"
    //                         primary={true}
    //                         keyboardFocused={true}
    //                         onClick={this.handleFiltersDialogClose}
    //                     />,
    //                 ]}
    //                 modal={false}
    //                 open={this.state.filtersModalOpen}
    //                 onRequestClose={this.handleFiltersDialogCloseRequest}></Dialog>
    //         )
    //     }
    //     renderSideMenu() {
    //         return (
    //             <div style={{width: 250}}>
    //                 <List>
    //                     {this.props.systemSectors.length > 0
    //                         ? this.props.systemSectors.map((sector, index) => {
    //                               let leftIcon = null
    //                               let sectorConstant = _.find(
    //                                   this.props.systemSectors,
    //                                   {
    //                                       name: sector.name,
    //                                   },
    //                               )
    //                               if (sectorConstant) {
    //                                   if (_.has(sectorConstant, 'svg')) {
    //                                       leftIcon = (
    //                                           <img
    //                                               src={
    //                                                   require('@images/sector_icons/' +
    //                                                       sectorConstant.svg +
    //                                                       '.svg').default
    //                                               }
    //                                           />
    //                                       )
    //                                   } else {
    //                                       leftIcon = (
    //                                           <FontIcon className="material-icons">
    //                                               label
    //                                           </FontIcon>
    //                                       )
    //                                   }
    //                               }
    //                               if (this.state.selectedSectors[sector.name]) {
    //                                   return (
    //                                       <span key={'sector_' + index}>
    //                                           <ListItem
    //                                               onClick={() => {
    //                                                   this.updateCurrentSector(
    //                                                       sector.name,
    //                                                   )
    //                                               }}
    //                                               style={{
    //                                                   fontSize: 13,
    //                                                   backgroundColor:
    //                                                       this.state
    //                                                           .currentSector ===
    //                                                       sector.name
    //                                                           ? '#eee'
    //                                                           : null,
    //                                               }}
    //                                               primaryText={sector.name}
    //                                               leftIcon={leftIcon}
    //                                           />
    //                                           <Divider />
    //                                       </span>
    //                                   )
    //                               }
    //                           })
    //                         : null}
    //                     <ListItem disabled={true}>
    //                         <FlatButton
    //                             label="Add Sector"
    //                             primary={true}
    //                             onClick={this.handleDialogOpen.bind(this)}
    //                             labelPosition="after"
    //                             icon={
    //                                 <FontIcon className="material-icons">
    //                                     add
    //                                 </FontIcon>
    //                             }
    //                         />
    //                     </ListItem>
    //                 </List>
    //             </div>
    //         )
    //     }
    //     render() {
    //         const sectors = this.state.sectors
    //         // Find currently selected sector
    //         const sector = _.find(sectors, {name: this.state.currentSector})
    //         let screeners = sector && sector.screeners ? sector.screeners : []
    //         let screenerNames = {}
    //         screeners
    //             .map((s) =>
    //                 ScreenersInfo[s.field] ? ScreenersInfo[s.field].title : 'N/A',
    //             )
    //             .forEach((name) => {
    //                 screenerNames[name] = true
    //             })
    //         return (
    //             <div>
    //                 <Header />
    //                 <div
    //                     style={{
    //                         marginTop: Dimensions.NAVBAR_TOP_OFFSET,
    //                         // padding: Dimensions.CONTENT_BASE_PADDING,
    //                         paddingTop: 20 + Dimensions.APP_BAR_HEIGHT,
    //                     }}>
    //                     <ItemsDialog
    //                         options={_.map(this.props.systemSectors, (s) => s.name)}
    //                         title="Select Sectors"
    //                         selectedItems={this.state.selectedSectors}
    //                         submit={this.handleSelectedSectorsUpdate.bind(this)}
    //                         handleClose={this.handleDialogClose.bind(this)}
    //                         open={this.state.sectorDialogOpen}
    //                     />
    //                     <ItemsDialog
    //                         options={_.map(_.values(ScreenersInfo), (o) => o.title)}
    //                         title="Select Filters"
    //                         selectedItems={screenerNames}
    //                         submit={this.handleSelectedScreenerUpdate.bind(this)}
    //                         handleClose={this.handleFiltersDialogClose.bind(this)}
    //                         open={this.state.filtersModalOpen}
    //                     />
    //                     {this.state.updatingPortfolio ? (
    //                         <div
    //                             style={{
    //                                 position: 'fixed',
    //                                 top:
    //                                     Dimensions.NAVBAR_TOP_OFFSET +
    //                                     Dimensions.APP_BAR_HEIGHT,
    //                                 width: '100%',
    //                                 bottom: 0,
    //                                 backgroundColor: 'rgba(255,255,255,.75)',
    //                                 zIndex: 100,
    //                                 display: 'flex',
    //                                 alignItems: 'center',
    //                             }}>
    //                             <div style={{flex: 1, textAlign: 'center'}}>
    //                                 <CircularProgress />
    //                                 <div>Updating profile...</div>
    //                             </div>
    //                         </div>
    //                     ) : null}
    //                     {this.state.currentSector &&
    //                     !(this.props.dataLoading || this.props.sectorsLoading) ? (
    //                         <NavAppBar
    //                             rightElement={
    //                                 <NavBarButton
    //                                     disabled={this.state.updatingPortfolio}
    //                                     onClick={this.handleSave.bind(this)}
    //                                     label="Save"
    //                                     secondary={true}
    //                                 />
    //                             }
    //                         />
    //                     ) : null}
    //                     {/* {
    //               this.state.currentSector && !(this.props.dataLoading || this.props.sectorsLoading) ?
    //               <AppBar
    //                 style={{
    //                   height: Dimensions.APP_BAR_HEIGHT,
    //                   backgroundColor: AppColors.SECONDARY_APPBAR,
    //                   position: 'fixed',
    //                   zIndex: 10,
    //                   top: Dimensions.NAVBAR_TOP_OFFSET
    //                 }}
    //                 iconStyleLeft={{ marginTop: 2}}
    //                 iconElementRight={
    //                   <RaisedButton
    //                     disabled={this.state.updatingPortfolio}
    //                     onClick={this.handleSave.bind(this)}
    //                     style={{backgroundColor: '#fff'}}
    //                     label="Save" secondary={true} />
    //                 }
    //                 iconElementLeft={
    //                   <div style={{
    //                       display: 'flex',
    //                       alignItems: 'center',
    //                       margin: 0
    //                     }}>
    //                     <FlatButton
    //                       label="Back to dashboard"
    //                       labelPosition="after"
    //                       onClick={() => {
    //                         if (this.shouldAlertUserBeforeExit === true) {
    //                           swal({
    //                             title: 'Are you sure?',
    //                             text: "Any unsaved changes will be lost",
    //                             buttonsStyling: "false",
    //                             reverseButtons: "true",
    //                             showCancelButton: true,
    //                             confirmButtonColor: '#3085d6',
    //                             cancelButtonColor: '#d33',
    //                             confirmButtonText: "Yes, let's go!"
    //                           }).then(
    //                             (response) => {
    //                               this.shouldAlertUserBeforeExit = false
    //                               this.props.history.push('/')
    //                             },
    //                             (error) => console.log("cancelled")
    //                           )
    //                         }
    //                         else {
    //                           this.props.history.push('/');
    //                         }
    //                       }}
    //                       icon={<FontIcon color="#fff" className="material-icons">arrow_back</FontIcon>}
    //                       style={{marginTop: 6}}
    //                       labelStyle={{color: '#fff'}}
    //                     />
    //                   </div>
    //                 }
    //               /> : null } */}
    //                     {this.props.dataLoading || this.props.sectorsLoading ? (
    //                         <div style={{textAlign: 'center'}}>
    //                             <CircularProgress style={{flex: 1}} />
    //                             <p>Loading...</p>
    //                         </div>
    //                     ) : (
    //                         <div style={styles.container}>
    //                             {this.state.currentSector
    //                                 ? this.renderSideMenu()
    //                                 : null}
    //                             {this.state.currentSector ? (
    //                                 <div
    //                                     style={Object.assign({}, styles.content, {
    //                                         flexDirection: 'row',
    //                                     })}>
    //                                     {screeners && screeners.length > 0 ? (
    //                                         <div
    //                                             style={{
    //                                                 flex: 1,
    //                                                 flexDirection: 'column',
    //                                             }}>
    //                                             {screeners.map((screener, i) => {
    //                                                 let value = '_all'
    //                                                 if (
    //                                                     _.has(screener, 'minVal') &&
    //                                                     _.has(screener, 'maxVal')
    //                                                 ) {
    //                                                     value = {
    //                                                         minVal: screener.minVal,
    //                                                         maxVal: screener.maxVal,
    //                                                     }
    //                                                 } else if (
    //                                                     _.has(screener, 'minVal')
    //                                                 ) {
    //                                                     value = {
    //                                                         minVal: screener.minVal,
    //                                                     }
    //                                                 } else if (
    //                                                     _.has(screener, 'maxVal')
    //                                                 ) {
    //                                                     value = {
    //                                                         maxVal: screener.maxVal,
    //                                                     }
    //                                                 } else if (
    //                                                     _.has(
    //                                                         screener,
    //                                                         'selector',
    //                                                     ) &&
    //                                                     screener.selector &&
    //                                                     screener.selector.length > 0
    //                                                 ) {
    //                                                     value = screener.selector
    //                                                 } else if (
    //                                                     _.has(
    //                                                         screener,
    //                                                         'multi_selector',
    //                                                     ) &&
    //                                                     _.isArray(
    //                                                         screener.multi_selector,
    //                                                     )
    //                                                 ) {
    //                                                     value =
    //                                                         screener.multi_selector
    //                                                 }
    //                                                 return (
    //                                                     <Screener
    //                                                         currentSector={
    //                                                             this.state
    //                                                                 .currentSector
    //                                                         }
    //                                                         key={'screener_' + i}
    //                                                         screenerType={
    //                                                             screener.field
    //                                                         }
    //                                                         data={{value}}
    //                                                         matchingStocks={
    //                                                             this.state
    //                                                                 .screenedSymbols &&
    //                                                             this.state
    //                                                                 .screenedSymbols[
    //                                                                 screener.field
    //                                                             ]
    //                                                                 ? this.state
    //                                                                       .screenedSymbols[
    //                                                                       screener
    //                                                                           .field
    //                                                                   ]
    //                                                                 : null
    //                                                         }
    //                                                         removeScreener={this.removeScreener.bind(
    //                                                             this,
    //                                                         )}
    //                                                         onScreenerChange={this.handleScreenerChange.bind(
    //                                                             this,
    //                                                         )}
    //                                                         applyAcrossAllSectors={() => {
    //                                                             let sectorIndex =
    //                                                                 _.findIndex(
    //                                                                     this
    //                                                                         .sectorsState,
    //                                                                     {
    //                                                                         name: this
    //                                                                             .state
    //                                                                             .currentSector,
    //                                                                     },
    //                                                                 )
    //                                                             if (
    //                                                                 sectorIndex > -1
    //                                                             ) {
    //                                                                 let sectorsState =
    //                                                                     this
    //                                                                         .sectorsState
    //                                                                 let newScreener =
    //                                                                     _.find(
    //                                                                         this
    //                                                                             .sectorsState[
    //                                                                             sectorIndex
    //                                                                         ]
    //                                                                             .screeners,
    //                                                                         {
    //                                                                             field: screener.field,
    //                                                                         },
    //                                                                     )
    //                                                                 if (
    //                                                                     newScreener
    //                                                                 ) {
    //                                                                     this.applyAcrossAllSectors(
    //                                                                         newScreener,
    //                                                                     )
    //                                                                 }
    //                                                             }
    //                                                         }}
    //                                                     />
    //                                                 )
    //                                             })}
    //                                             <div
    //                                                 style={{
    //                                                     display: 'flex',
    //                                                     justifyContent:
    //                                                         'space-between',
    //                                                 }}>
    //                                                 <FlatButton
    //                                                     label="Add filter"
    //                                                     primary={true}
    //                                                     onClick={
    //                                                         this
    //                                                             .handleFiltersDialogOpen
    //                                                     }
    //                                                     labelPosition="after"
    //                                                     icon={
    //                                                         <FontIcon className="material-icons">
    //                                                             add
    //                                                         </FontIcon>
    //                                                     }
    //                                                 />
    //                                                 <FlatButton
    //                                                     label={
    //                                                         'Remove ' +
    //                                                         this.state.currentSector
    //                                                     }
    //                                                     labelStyle={{
    //                                                         color: Colors.red400,
    //                                                     }}
    //                                                     onClick={() =>
    //                                                         this.removeSector(
    //                                                             this.state
    //                                                                 .currentSector,
    //                                                         )
    //                                                     }
    //                                                     labelPosition="after"
    //                                                     icon={
    //                                                         <FontIcon
    //                                                             className="material-icons"
    //                                                             color={
    //                                                                 Colors.red400
    //                                                             }>
    //                                                             close
    //                                                         </FontIcon>
    //                                                     }
    //                                                 />
    //                                             </div>
    //                                         </div>
    //                                     ) : null}
    //                                     {!screeners || screeners.length < 1 ? (
    //                                         <div
    //                                             style={{
    //                                                 textAlign: 'center',
    //                                                 flex: 1,
    //                                                 flexDirection: 'column',
    //                                             }}>
    //                                             <p>
    //                                                 No filters defined. Add a few
    //                                                 filters to receive investment
    //                                                 opportunities tailored to your
    //                                                 specific investment profile.
    //                                             </p>
    //                                             <RaisedButton
    //                                                 label="Add filter"
    //                                                 primary={true}
    //                                                 onClick={
    //                                                     this.handleFiltersDialogOpen
    //                                                 }
    //                                                 labelPosition="after"
    //                                                 icon={
    //                                                     <FontIcon className="material-icons">
    //                                                         add
    //                                                     </FontIcon>
    //                                                 }
    //                                             />
    //                                             <div style={{marginTop: 30}}>
    //                                                 <FlatButton
    //                                                     label={
    //                                                         'Remove ' +
    //                                                         this.state.currentSector
    //                                                     }
    //                                                     labelStyle={{
    //                                                         color: Colors.red400,
    //                                                     }}
    //                                                     onClick={() =>
    //                                                         this.removeSector(
    //                                                             this.state
    //                                                                 .currentSector,
    //                                                         )
    //                                                     }
    //                                                     labelPosition="after"
    //                                                     icon={
    //                                                         <FontIcon
    //                                                             className="material-icons"
    //                                                             color={
    //                                                                 Colors.red400
    //                                                             }>
    //                                                             close
    //                                                         </FontIcon>
    //                                                     }
    //                                                 />
    //                                             </div>
    //                                         </div>
    //                                     ) : null}
    //                                     <div style={{width: 200, paddingTop: 60}}>
    //                                         <div
    //                                             style={{
    //                                                 borderLeftWidth: 3,
    //                                                 borderLeftColor: '#c3c3c3',
    //                                                 borderLeftStyle: 'solid',
    //                                                 marginLeft: 40,
    //                                                 paddingLeft: 10,
    //                                             }}>
    //                                             <h3
    //                                                 style={{
    //                                                     fontSize: 38,
    //                                                     margin: 0,
    //                                                     paddingTop: 8,
    //                                                     paddingBottom: 8,
    //                                                     fontWeight: 200,
    //                                                 }}>
    //                                                 {this.state.screenedSymbols
    //                                                     ? this.state.screenedSymbols
    //                                                           .total_passed
    //                                                     : 0}
    //                                             </h3>
    //                                             <div>Matching stocks</div>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             ) : (
    //                                 <div
    //                                     style={Object.assign({}, styles.content, {
    //                                         backgroundColor: '#fff',
    //                                     })}>
    //                                     <div
    //                                         style={{
    //                                             minHeight: 200,
    //                                             display: 'flex',
    //                                             justifyContent: 'center',
    //                                             alignItems: 'center',
    //                                             fontSize: 16,
    //                                         }}>
    //                                         <div
    //                                             style={{
    //                                                 flex: 1,
    //                                                 textAlign: 'center',
    //                                             }}>
    //                                             <div
    //                                                 style={{
    //                                                     display: 'flex',
    //                                                     maxWidth: 600,
    //                                                     margin: 'auto',
    //                                                 }}>
    //                                                 {[
    //                                                     {
    //                                                         side: 'l',
    //                                                         description:
    //                                                             'Define your investment profile by selecting custom sectors and filters.',
    //                                                     },
    //                                                     {
    //                                                         side: 'c',
    //                                                         description:
    //                                                             "Receive tailored investment opportunities located by Tipigo's algorithms.",
    //                                                     },
    //                                                     {
    //                                                         side: 'r',
    //                                                         description:
    //                                                             'Follow the recommendations on your watchlist to receive valuable alerts',
    //                                                     },
    //                                                 ].map((o, i) => (
    //                                                     <div
    //                                                         style={{
    //                                                             flex: 1,
    //                                                             margin: 20,
    //                                                         }}
    //                                                         key={'img_' + o.side}>
    //                                                         <img
    //                                                             style={{
    //                                                                 width: '80%',
    //                                                             }}
    //                                                             src={
    //                                                                 require('@images/sector_intro_' +
    //                                                                     o.side +
    //                                                                     '.png')
    //                                                                     .default
    //                                                             }
    //                                                         />
    //                                                         <div
    //                                                             style={{
    //                                                                 width: 40,
    //                                                                 height: 40,
    //                                                                 color: 'rgb(84, 93, 109)',
    //                                                                 display: 'flex',
    //                                                                 justifyContent:
    //                                                                     'center',
    //                                                                 alignItems:
    //                                                                     'center',
    //                                                                 margin: 'auto',
    //                                                                 marginTop: 10,
    //                                                                 borderRadius: 40,
    //                                                                 borderWidth: 2,
    //                                                                 borderColor:
    //                                                                     'rgb(84, 93, 109)',
    //                                                                 borderStyle:
    //                                                                     'solid',
    //                                                                 fontSize: 20,
    //                                                             }}>
    //                                                             <span
    //                                                                 style={{
    //                                                                     flex: 1,
    //                                                                 }}>
    //                                                                 {i + 1}
    //                                                             </span>
    //                                                         </div>
    //                                                         <p>{o.description}</p>
    //                                                     </div>
    //                                                 ))}
    //                                             </div>
    //                                             <div>
    //                                                 <RaisedButton
    //                                                     onClick={this.handleDialogOpen.bind(
    //                                                         this,
    //                                                     )}
    //                                                     style={{marginTop: 20}}
    //                                                     label="Define Investment Profile"
    //                                                     primary={true}
    //                                                 />
    //                                             </div>
    //                                             {/*<ReactTooltip id='_quickstart'
    //                           class='extraClass'
    //                           place="right"
    //                           type='info' effect='solid'>
    //                           <p>
    //                             A helpful tooltip
    //                           </p>
    //                         </ReactTooltip>
    //                         <div style={{
    //                           display: 'flex',
    //                           alignItems: 'center',
    //                           justifyContent: 'center',
    //                           marginTop: 10
    //                         }}>
    //                           <FlatButton label="Quickstart" primary={true} />
    //                           <FontIcon style={{
    //                               color: '#aaa',
    //                               cursor: 'default',
    //                               marginLeft: 10
    //                             }}
    //                             data-tip data-for='_quickstart'
    //                             className="material-icons">info</FontIcon>
    //                         </div>*/}
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             )}
    //                         </div>
    //                     )}
    //                 </div>
    //             </div>
    //         )
    //     }
    // }
    // const mapStateToProps = (state) => {
    //     return {
    //         user: state.user.user,
    //         initialized: state.finance.initialized,
    //         dataLoading: state.finance.isFetching['getUserFinanceInfo'],
    //         sectorsLoading: state.finance.isFetching['getUserProfileData'],
    //         sectors: state.finance.data.sectors || [],
    //         portfolioSize: state.finance.data.portfolioSize,
    //         systemSectors: state.finance.data.system_sectors || [],
    //     }
    // }
    // const mapDispatchToProps = (dispatch) => {
    //     const ds = new DataService(dispatch)
    //     return {
    //         getFinancialData: (sessionToken, fetchSilently = false) =>
    //             ds.getFinancialData(sessionToken, fetchSilently),
    //         commitPortfolioState: (data) => {
    //             dispatch(AppActions.portfolioStatusUpdate(data))
    //         },
    //     }
    // }
    // export default withRouter(
    //     connect(mapStateToProps, mapDispatchToProps)(InvestmentProfile),
    // )
}
