/**
 * API communication service
 */

import store from '@store'
import {setUser} from '@store/actions'
import {api} from '@services/api'

let Config = require('../../config') //from '../../../../config';

let Rx = require('rx')

let CLStorage = require('../cl-storage')
// let Appinst = window.Appinst = require('../cl-app');

// Define base URL
let envs = Config.AVAILABLE_ENVIRONMENTS
let selectedEnv =
    window.location.host === 'app.tipigo.com' ||
    window.location.host === 'insight.tipigo.com' ||
    window.location.host === 'localhost:3000' // remove --not if you want local as prod
        ? 'production'
        : 'development' // Config.SELECTED_ENV;
let protocol = Config.USE_HTTPS ? 'https' : 'http'

let BASE_URL = protocol + '://' + envs[selectedEnv]

console.log('config', Config)

// Define some helper variables
let POST = 'POST'
let GET = 'GET'
let PUT = 'PUT'
let DELETE = 'DELETE'

// Logger tag
let TAG = 'CLApi'

// The singleton instance
let instance = null

class CLApi {
    /**
     * Generate the body params for a URL request
     * @param  {string} requestType POST or GET
     * @param  {object} params      params
     * @return {string}             encoded string
     */
    _bodyParams(requestType, params) {
        if (
            requestType === POST ||
            requestType === PUT ||
            requestType === DELETE
        ) {
            return JSON.stringify(params)
        } else if (requestType === GET) {
            return Object.keys(params)
                .map((key) => {
                    let v = params[key]
                    return key + '=' + encodeURIComponent(v)
                })
                .join('&')
        }
    }

    /**
     * Base request method
     * @param  {string}    url      e.g. /getCurrentUser (including the prefix '/')
     * @param  {string}    type     'POST' | 'GET'
     * @param  {object}    params   params to add to the request
     * @param  {boolean}   relogin  if set to true will attempt to re-login if
     *                              401 is received (default: true)
     * @param  {boolean}   cache  disable caching for this request
     * @return {Rx.Observable}
     */
    _request(url, type, params, relogin, cache, baseUrl) {
        // Default flags
        relogin =
            typeof relogin === 'undefined' || relogin === true ? true : false
        cache = typeof cache === 'undefined' || cache === true ? true : false

        if (typeof baseUrl === 'undefined') {
            baseUrl = BASE_URL
        }

        // Verify we have a proper request type (currently only supporting GET and POST)
        if ([POST, GET, PUT, DELETE].indexOf(type) === -1) {
            console.error(TAG, 'unsupported request type ->', type)
            return
        }

        // Verify we have the '/' at the beginning of the method
        if (!url.startsWith('/')) {
            url = '/' + url
        }

        // Construct the URL
        let requestUrl = baseUrl + url

        // Add debug flag for localhost
        if (Config.XDEBUG && Config.SELECTED_ENV === 'localhost') {
            requestUrl += '?XDEBUG_SESSION_START=PHPSTORM'
        }

        // Set up our general server headers
        let headers = new Headers()
        headers.append('Content-Type', 'application/json')
        headers.append('Accept', 'application/json')

        // console.log(TAG, "requesting ->", requestUrl + " [relogin: " + relogin + ", cache: " + cache + "]");

        // Create the request Observable
        return Rx.Observable.create((observer) => {
            // Define request config
            let requestConfig = {
                method: type,
                headers: headers,
                credentials: 'include',
            }

            // Encode request params
            // Encode request params
            let bodyParams = this._bodyParams(type, params)
            if (type === 'POST' || type === 'PUT' || type === 'DELETE') {
                requestConfig.body = bodyParams
            } else if (type === 'GET' && bodyParams) {
                requestUrl += '?' + bodyParams
            }

            // Begin execution
            // First we check if we should use the cache in this request
            // For some requests (e.g. login, logout) we don't ever want
            // to use the cache)
            if (Config.CACHE_API_RESPONSES === true && cache === true) {
                CLStorage.fetchResponse(requestUrl, type, requestConfig)
                    .then((response, err) => {
                        if (err)
                            console.warn(TAG, '_fetchResponse from cache error')
                        else if (response)
                            return Promise.resolve(JSON.parse(response))
                        else
                            console.log(
                                TAG,
                                '_fetchResponse from cache',
                                'NO RESPONSE',
                            )
                    })
                    .then((response) => {
                        if (!response) {
                            console.log(
                                TAG,
                                '_fetchResponse',
                                'no response from local storage',
                            )
                        } else {
                            observer.onNext({
                                status: 200,
                                data: response.data,
                                type: 'cached',
                                timestamp: response.timestamp,
                            })
                        }

                        // We have the storage request, let's proceed with the remote call
                        this._remoteRequest(
                            observer,
                            requestUrl,
                            requestConfig,
                            headers,
                            type,
                            params,
                            relogin,
                            cache,
                        )
                    })
                    .catch((err) => console.warn(TAG, '_fetchResponse', err))
            } else {
                // Caching disabled, make the remote server call
                this._remoteRequest(
                    observer,
                    requestUrl,
                    requestConfig,
                    headers,
                    type,
                    params,
                    relogin,
                    cache,
                )
            }

            /**
             * Make the remote API request
             */
        })
    }

    /**
     * Make a remote server request
     * @param  {observer} observer      Rx Observer object
     * @param  {string} requestUrl    the request url
     * @param  {object} requestConfig request config
     * @param  {object} headers       request headers
     * @param  {string} type          request type (e.g. POST or GET)
     * @param  {object} params        request params
     * @param  {boolean} relogin       relogin flag (set 'true' to enable)
     */
    _remoteRequest(
        observer,
        requestUrl,
        requestConfig,
        headers,
        type,
        params,
        relogin,
        cache,
    ) {
        let rememberMe = params.rememberMe ? params.rememberMe : false

        // Fire the request using Fetch API
        fetch(requestUrl, requestConfig)
            .then((response) => {
                if (
                    response.url !==
                    'https://app.tipigo.com/finance/is_market_open'
                )
                    console.log('api response', response)
                // Check response HTTP status code
                if (response.status >= 200 && response.status < 300) {
                    // We got a valid response from the server (at least network-wise)
                    let contentLength = response.headers.get('Content-Length')

                    // We have data in the body, let's convert to JSON
                    response.text().then((text) => {
                        let data = null
                        try {
                            data = JSON.parse(text)
                        } catch (error) {
                            observer.onError({
                                status: 500,
                                error: error.name,
                                data: error,
                            })
                        }

                        if (data) {
                            observer.onNext({
                                status: response.status,
                                data: data,
                                type: 'remote',
                                timestamp: new Date().getTime(),
                            })
                        }

                        done(observer, requestUrl, data, cache, rememberMe)
                    })
                } else if (response.status >= 300 && response.status < 400) {
                    // TODO: deal with redirects
                    console.info(
                        TAG,
                        'remoteRequest',
                        requestUrl,
                        'returned',
                        'response.status',
                    )
                } else if (response.status === 401) {
                    // Authentication issue, if relogin is true,
                    console.warn(
                        TAG,
                        'remoteRequest',
                        requestUrl,
                        'returned 401',
                    )
                    if (relogin) {
                        // attempt to relogin
                        this._autoLogin(
                            requestUrl,
                            requestConfig,
                            headers,
                            type,
                            params,
                        ).subscribe(
                            (response) => {
                                observer.onNext({
                                    status: response.status,
                                    data: response.data,
                                    type: 'remote',
                                    timestamp: new Date().getTime(),
                                })
                            },
                            (err) => {
                                observer.onError(err)
                                store.dispatch(setUser(null))
                            },
                        )
                    } else {
                        // No relogin, let's just return the error
                        observer.onError(response)
                        store.dispatch(setUser(null))
                    }
                } else {
                    // We have a problem
                    response
                        .json()
                        .then((data) =>
                            observer.onError({
                                status: response.status,
                                data: data,
                            }),
                        )
                        .catch((error) => {
                            observer.onError({
                                status: response.status,
                                error: error,
                            })
                        })
                }
            })
            // If for some reason something bad happen along the line...
            .catch((error) => {
                console.warn(TAG, requestUrl, error)
                let err = error.name + ': ' + error.message
                observer.onError({
                    status: 400,
                    error: error,
                })
            })

        /**
         * A response completion handler
         * @param  {observer}       observer the Observable observer
         * @param  {requestUrl}     the request URL
         * @param  {Object/Array}   data     the data to store
         */
        function done(observer, requestUrl, data, cache, rememberMe) {
            if (rememberMe && data.access_token) {
                // Store user
                // Appinst.getInstance().setUser({ email: data.email })

                // We received the access token, let's store
                CLStorage.storeLoginCredentials('token', {
                    email: data.email,
                    access_token: data.access_token,
                })
            } else if (data.user_info && data.user_info.access_token) {
                // Appinst.getInstance().setUser({ email: data.user_info.email });

                // We received the access token, let's store
                CLStorage.storeLoginCredentials('token', {
                    email: data.user_info.email || null,
                    access_token: data.user_info.access_token,
                })
            }

            if (Config.CACHE_API_RESPONSES === true && cache === true) {
                // Store the response and complete the observer
                CLStorage.storeResponse(requestUrl, type, requestConfig, data)
                    .then(() => observer.onCompleted())
                    .catch((err) => console.warn(TAG, '_storeResponse', err))
            } else {
                // Do not store, just return the observer
                observer.onCompleted()
            }
        }
    }

    /**
     * Attempt to login using credentials from local storage
     * @return {Observable}
     */
    _autoLogin(requestUrl, requestConfig, headers, type, params) {
        console.log('_autoLogin1')
        return Rx.Observable.create((observer) => {
            if (
                type !== POST &&
                type !== GET &&
                type !== PUT &&
                type !== DELETE
            ) {
                observer.onError({
                    error: '' + type + ' is not supported by autologin',
                })
            }
            // window.location.reload(true)
            // return
            CLStorage.fetchLoginCredentials()
                .then((response, err) => {
                    if (err) {
                        console.warn(
                            TAG,
                            '_fetchLoginCredentials',
                            'failed to get login creds from storage',
                        )
                        observer.onError(err)
                        window.location.reload(true)
                    } else if (response) {
                        console.log('_autoLogin2', response)
                        // We have the creds, let's attempt to login
                        let data = JSON.parse(response)
                        if (data.type && data.params) {
                            if (
                                data.type === 'token' &&
                                data.params.auth_token
                            ) {
                                this._login({
                                    method: 4,
                                    auth_token: data.params.auth_token,
                                    rememberMe: true,
                                }).subscribe(
                                    (res) => {
                                        // api.loginNew(email, password).subscribe(
                                        //     (res) =>
                                        //         console.log('loginNew', res),
                                        // )
                                        console.log('login_response3', res)
                                        CLStorage.storeLoginCredentials(
                                            'token',
                                            {
                                                method: 4,
                                                auth_token: res.data.auth_token,
                                            },
                                        )
                                        // requestConfig.body = this._bodyParams(type, params);
                                        this._remoteRequest(
                                            observer,
                                            requestUrl,
                                            requestConfig,
                                            headers,
                                            type,
                                            params,
                                            false,
                                            false,
                                        )
                                    },
                                    (err) => {
                                        observer.onError({
                                            error:
                                                'type ' +
                                                data.type +
                                                ' is not supported by autologin',
                                        })
                                        window.location.reload(true)
                                    },
                                )
                            } else {
                                observer.onError({
                                    error:
                                        'type ' +
                                        data.type +
                                        ' is not supported by autologin',
                                })
                                window.location.reload(true)
                            }
                        } else {
                            observer.onError('invalid login data: ' + response)
                            window.location.reload(true)
                        }
                    } else {
                        // No data
                        observer.onError('no data')
                        window.location.reload(true)
                    }
                })
                .catch((err) => {
                    observer.onError(err)
                    window.location.reload(true)
                })
        })
    }

    _login(params) {
        params.method = '1'
        params.remember_me = params.rememberMe || false

        return this._request(
            '/user/login',
            POST,
            {
                loginData: params,
            },
            false,
            false,
        )
    }

    _signup(params) {
        return this._request('/user/signup', POST, params, false, false)
    }

    _resetPassword(params) {
        return this._request('/user/reset_password', POST, params, false, false)
    }

    _logout() {
        api.logoutNew().subscribe(
            (response) => console.log('v1 logout', response),
            (error) => console.log('v1 logout error', error),
        )
        return this._request('/user/logout', POST, {}, false, false)
    }

    /**
     * Public API
     */
    request(url, type, params, relogin, cache, baseUrl) {
        return this._request(url, type, params, relogin, cache, baseUrl)
    }
}

export default CLApi
