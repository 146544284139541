import React from 'react'
import {api} from '@services/api'
import {useState, useEffect} from '@services/hooks'
import styled from 'styled-components'
import {SendButton, Title} from '@components/General/Elements'
import DateRange from '@components/General/DateRange'
import FrameInput from '@components/General/FrameInput'
import FrameSelect from '@components/General/FrameSelect'
import swal from 'sweetalert2'
import {AppColors} from '@services/styles'
import {formatUniverseResponse} from '@services/utils'

const PBT = ({mainColWidth, user}) => {
    const [openDate, setOpenDate] = useState(new Date())
    const [closeDate, setCloseDate] = useState(new Date())
    //const [frq, setFrq] = useState(3)
    const [numFiles, setNumFiles] = useState(3)
    const [numStock, setNumStock] = useState(20)
    const [numInv, setNumInv] = useState(2)
    const [universes, setUniverses] = useState([])
    const [universe, setUniverse] = useState('')
    const [mailTo, setMailTo] = useState(
        window.location.hostname === 'localhost'
            ? 'ronkaplan69@gmail.com'
            : user && user.email
            ? user.email
            : '',
    )

    useEffect(() => {
        // api.getTipigoIndexes(0).subscribe((response) => {
        //     setUniverses(formatUniverseResponse(response, true))
        // })
        setUniverses(user.universeList || [])
    }, [])
    // const _setFrq = (value) => {setFrq(intOrEmpty(value))}
    // const _setNumFiles = (value) => {setNumFiles(intOrEmpty(value))}
    const _setNumStock = (value) => {
        setNumStock(intOrEmpty(value))
    }
    const intOrEmpty = (value) => {
        value = value.trim()
        if (value == '') {
            return ''
        } else {
            value = parseInt(value)
            if (!isNaN(value) && value >= 0) {
                return value
            } else {
                return ''
            }
        }
    }

    const getResults = () => {
        // TODO: add validations //|| !frq
        if (!numFiles || !numStock || !mailTo) {
            swal.fire({text: 'missing data', icon: 'error'})
            return
        }
        // else if (numFiles > frq) {
        //     swal.fire({
        //         text: 'number of files cannot exceed refresh frequency',
        //         icon: 'error',
        //     })
        //     return
        // }
        api.portfolioBacktestMail(
            openDate,
            closeDate,
            //frq,
            numFiles,
            numStock,
            numInv,
            universe,
            mailTo,
        ).subscribe(
            (response) => {
                console.log('portfolioBacktestMail response', response.data)
                swal.fire({
                    text: 'Your request was received, please note that this process may take several hours to complete.',
                    focusConfirm: false,
                    confirmButtonColor: AppColors.TIPIGO_GREEN,
                    icon: 'success',
                })
            },
            (error) => console.log('portfolioBacktestMail error', error),
        )
    }

    return (
        <Container style={{width: mainColWidth}}>
            <Title text={'Strategy Backtest'} />
            <Row>
                <DateRange
                    fromDate={openDate}
                    toDate={closeDate}
                    setFromDate={setOpenDate}
                    setToDate={setCloseDate}
                    dateFormat={'MM/yyyy'}
                    wholeMonth
                    oneDateTitle={'Open Date'}
                    mainColWidth={mainColWidth}
                />
            </Row>
            <Row style={{justifyContent: 'space-between'}}>
                {/* <FrameSelect
                    title={'Refresh frequency (months)'}
                    value={frq}
                    values={[2, 3, 4]}
                    setValue={setFrq}
                    style={{flex: 1}}
                /> */}
                <FrameSelect
                    title={'Number of Parallel Portfolios'}
                    value={numFiles}
                    values={[1, 2, 3, 4]}
                    setValue={setNumFiles}
                    style={{flex: 1}}
                />
            </Row>
            <Row style={{justifyContent: 'space-between'}}>
                <FrameInput
                    title={'Number of Stocks per Portfolio'}
                    value={numStock}
                    setValue={_setNumStock}
                    style={{flex: 1}}
                />
                <FrameSelect
                    title={'Processed data (months)'}
                    values={[1, 2, 3, 4]}
                    value={numInv}
                    setValue={setNumInv}
                    style={{flex: 1, marginLeft: 20}}
                />
            </Row>

            <Row>
                {universes && (
                    <FrameSelect
                        style={{width: mainColWidth}}
                        value={universe}
                        values={[
                            {
                                value: 'all',
                                label: 'All stocks. No filter',
                            },
                            ...universes,
                        ]}
                        setValue={setUniverse}
                        title={'Select Universe'}
                    />
                )}
            </Row>
            <Row width={mainColWidth}>
                <FrameInput
                    title={'Mail report to'}
                    value={mailTo}
                    setValue={setMailTo}
                    maxLength={100}
                    style={{width: mainColWidth}}
                />
            </Row>
            <Row>
                <SendButton onClick={getResults}>Create</SendButton>
            </Row>
        </Container>
    )
}

export default PBT

const Container = styled.div`
    font-family: 'Inter';
`
const Row = styled.div`
    margin-top: 20px;
    display: flex;
    border: 0px solid green;
`
