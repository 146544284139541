import React, {Component, PropTypes} from 'react'
import TextField from 'material-ui/TextField'

class SimpleFloatInput extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        value: this.props.value || 0,
    }

    update = () => {
        this.props.onChange(this.state.value)
    }

    render() {
        return (
            <div>
                <TextField
                    id="simple-float-input-id"
                    style={{width: 100}}
                    type="number"
                    step="0.001"
                    value={this.state.value}
                    onChange={(event) => {
                        this.setState({value: event.target.value}, this.update)
                    }}
                />{' '}
                {this.props.showPrecentage ? '%' : null}
            </div>
        )
    }
}

export default SimpleFloatInput
