import React from 'react'
import {api, newApiReports} from '@services/api'
import {useState, useEffect, useUser} from '@services/hooks'
import {
    SendButton,
    FormRow,
    SendButton2,
    SendButton2Dis,
} from '@components/General/Elements'
import FrameSelect from '@components/General/FrameSelect'
import styled from 'styled-components'
import {AppColors} from '@services/styles'
import {ReactComponent as Plus} from '@images/green_plus.svg'
import GetSector from './GetSector'
import {Checkbox} from '@components/General/Elements'
// import {getUniverses} from '@store/actions'

const OppScannerRunner = ({
    customIndexes,
    //setDirectResults,
    setStage,
    stage,
    selectedSet,
    setSelectedSet,
    allSets,
    sectors,
    setSectors,
    // getResults,
    getResultsNew,
    universe,
    setUniverse,
    lastSelected,
    setLastSelected,
    onGotNewSettings,
    realtime,
    setRealtime,
}) => {
    const user = useUser()

    useEffect(() => {
        setDefaultUniverse()
    }, [customIndexes])

    const setDefaultUniverse = () => {
        if (customIndexes?.length && (!universe || universe === 'all')) {
            const sp = customIndexes.find((item) =>
                item.label.includes('S&P500'),
            )
            if (sp) setUniverse(sp.value)
        }
    }

    useEffect(() => {
        if (selectedSet) {
            api.getOneOppScannerSettings(selectedSet).subscribe((response) => {
                onGotNewSettings?.(response?.data?.settings || response.data)
            })
        } else if (selectedSet === 0) {
            onGotNewSettings?.({})
        }
    }, [selectedSet])

    useEffect(() => {
        console.log('sectors', sectors)
    }, [sectors])

    const getUniverses = () => {
        var temp = []
        if (customIndexes) {
            temp = [...customIndexes]
            temp.unshift({
                value: 'all',
                label: 'All',
                disabled: Object.keys(sectors).includes('all'),
            })
            return temp
        }
    }
    const disabled =
        Object.keys(sectors).length === 0 &&
        Object.keys(lastSelected).length === 0

    return (
        <MainRow>
            <Row>
                <SelectSet>
                    <FrameSelect
                        title="Settings"
                        value={selectedSet}
                        values={allSets}
                        setValue={setSelectedSet}
                        style={{flex: 1}}
                    />
                    {selectedSet !== 0 ? (
                        <SendButton2
                            style={{alignSelf: 'end', marginLeft: 15}}
                            onClick={() => setStage('edit')}>
                            Edit
                        </SendButton2>
                    ) : (
                        <SendButton2Dis
                            style={{alignSelf: 'end', marginLeft: 15}}>
                            Edit
                        </SendButton2Dis>
                    )}
                </SelectSet>
            </Row>
            <Row>
                <Create
                    onClick={() => {
                        setSelectedSet(0) // if user creates new while dropdown is selected
                        setStage('edit')
                    }}>
                    <Plus style={{marginRight: 15}} /> Create new Settings
                </Create>
            </Row>

            <Row>
                <FrameSelect
                    title={'Universe'}
                    value={universe}
                    values={getUniverses()}
                    style={{flex: 1}}
                    setValue={setUniverse}
                    //disabled={selectedSet !== 0}
                />
            </Row>

            <Label>
                <Checkbox
                    checked={realtime}
                    onChange={(e) => setRealtime(!realtime)}
                />
                <SpanText>
                    Enable responsiveness to the latest market shifts
                </SpanText>
            </Label>

            <Row>
                <GetSector
                    selectedSectors={sectors}
                    setSelectedSectors={setSectors}
                    lastSelected={lastSelected}
                    setLastSelected={setLastSelected}
                    universe={universe}
                />
            </Row>

            <Row style={{justifyContent: 'flex-end'}}>
                {stage != 'pre' && (
                    <SendButton2
                        style={{marginRight: 20}}
                        onClick={() => setStage('pre')}>
                        Cancel
                    </SendButton2>
                )}

                {/* !newApiReports && (
                    <SendButton onClick={getResults} disabled={disabled}>
                        Next
                    </SendButton>
                ) */}

                {newApiReports && (
                    <SendButton onClick={getResultsNew} disabled={disabled}>
                        Next {newApiReports ? '' : 'New'}
                    </SendButton>
                )}
            </Row>
        </MainRow>
    )
}

export default OppScannerRunner

const MainRow = styled(FormRow)`
    border: 0px solid pink;
    flex-direction: column;
    flex: 1;
    display: flex;
`
const Row = styled(FormRow)`
    border: 0px solid green;
    margin-bottom: 25px;
`
// const ProgressRow = styled(Row)`
//     align-items: center;
//     margin-top: 30px;
//     flex-direction: column;
//     border: 0px solid green;
// `
// const ProgressMsg = styled.div`
//     margin-top: 18px;
//     font-size: 15px;
//     display: flex;
//     width: 200px;
//     border: 0px solid red;
//     color: ${AppColors.BLACK};
// `
const SelectSet = styled.div`
    display: flex;
    border: 0px solid yellow;
    justify-content: space-between;
    flex: 1;
`
const Create = styled.div`
    color: ${AppColors.TIPIGO_GREEN};
    font-size: 16px;
    font-weight: 500;
    margin-top: 12px;
    flex-direction; column;
    display: flex; 
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

const Label = styled.label`
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 20px;
`

const SpanText = styled.span`
    vertical-align: middle;
    font-size: 14px;
    color: ${(p) => (p.disabled ? '#999' : AppColors.BLACK)};
`
