import {React, styled} from '@common'
import {useState, useEffect, useMemo} from '@services/hooks'
import {useTable, useSortBy, useBlockLayout} from 'react-table' //usePagination
import ReactTooltip from 'react-tooltip'
import {useSticky} from 'react-table-sticky'

//const pageSizeOptions = [5, 10, 20, 50, 100]

const ScrollTable = ({
    columns,
    data,
    hiddenCols = [],
    TableStyles,
    sortDefault = false,
    sortDesc = true,
    height = null,
    maxWidth = null,
}) => {
    // const [searchDataSource, setSearchDataSource] = useState([])
    // const [filters, setFilters] = useState([])
    // const [filterTypes, setFilterTypes] = useState([
    //     'Action: Long',
    //     'Action: Short',
    // ])

    // this is to keep the user sort each time state changes, otherwise will go back to "no sort"
    const [sortCol, setSortCol] = useState([{id: sortDefault, desc: sortDesc}])
    const width2use = maxWidth ? maxWidth : window.innerWidth * 0.85
    const activeCols = columns.filter(
        (col) => !hiddenCols.includes(col.accessor),
    )

    const widthGiven = activeCols.reduce((accumulator, col) => {
        return accumulator + (col.width ? col.width : 0)
    }, 0)
    const colsNoWidth = activeCols.filter((col) => !col.width).length
    const availWidth = width2use - widthGiven

    const defaultColumn = useMemo(() => {
        const colCount = colsNoWidth //columns.length - hiddenCols.length

        if (availWidth / colCount < 60) {
            return {
                minWidth: 20,
                width: 60,
            }
        } else {
            return {
                minWidth: 20,
                width: availWidth / colCount,
            }
        }
    }, [columns])

    const tableInstance = useTable(
        {
            columns,
            data,
            defaultColumn, //: {}, //31.05.23 not sure what it did, but we use fixed width on all cols. defaultColumn,
            initialState: {
                sortBy: sortCol,
            },
        },
        useSortBy,
        useBlockLayout,
        useSticky,
    ) //, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setHiddenColumns,
        // allColumns,
        // getToggleHideAllColumnsProps,
        // pageOptions,
        // page,
        // state: { pageIndex, pageSize },
        // gotoPage,
        // previousPage,
        // nextPage,
        // setPageSize,
        // canPreviousPage,
        // canNextPage,
        state: {sortBy},
    } = tableInstance

    // WTF!? but i remember it had some reason in MyFile
    useEffect(() => {
        console.log('hidden change', hiddenCols)
        if (hiddenCols && hiddenCols.length) {
            setHiddenColumns(hiddenCols)
        }
    }, [hiddenCols])

    useEffect(() => {
        console.log('sort change?', sortBy)
        setSortCol(sortBy)
    }, [sortBy])

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    return (
        <TableContainer>
            {/* tooltip can be used by any client that uses the table. just add data-tip={} to element
            but the control and input has to be here */}
            <ReactTooltip
                html={true}
                id="table_tool_path"
                className="tooltip-width"
            />
            {data && (
                <Styles>
                    <TableStyles>
                        <Table
                            {...getTableProps()}
                            className="table sticky"
                            style={{maxHeight: height ? height : 'unset'}}>
                            <TableHeader className="header">
                                {headerGroups.map((headerGroup) => (
                                    <HeaderRow
                                        className="tr"
                                        {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <HeaderCell
                                                className="th"
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps(),
                                                )}>
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>
                                            </HeaderCell>
                                        ))}
                                    </HeaderRow>
                                ))}
                            </TableHeader>

                            <TableBody
                                {...getTableBodyProps()}
                                className="body">
                                {rows.map((row) => {
                                    prepareRow(row)
                                    return (
                                        // Apply the row props
                                        <Row
                                            {...row.getRowProps()}
                                            className="tr">
                                            {
                                                // Loop over the rows cells
                                                row.cells.map((cell) => {
                                                    // Apply the cell props
                                                    return (
                                                        <Cell
                                                            className="td"
                                                            {...cell.getCellProps()}>
                                                            {cell.render(
                                                                'Cell',
                                                            )}
                                                        </Cell>
                                                    )
                                                })
                                            }
                                        </Row>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableStyles>
                </Styles>
                // </div>
            )}
        </TableContainer>
    )
}

export default ScrollTable

const SimpleDiv = styled.div``
//width: 100%;
const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 0px solid red;
    flex: 1;
`
// width: 100%; height: fit-content;border: 1px solid rgba(0, 0, 0, 0.1); justify-content: center; text-align: left;align-items: center;
// padding to allow show shdow on rows (in MyFile)
const Table = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px;
    padding-top: 0px;
`

const TableHeader = styled.div`
    box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%);
`
const HeaderRow = styled.div``

const HeaderCell = styled.div`
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: normal;
`
const TableBody = styled.div``

const Row = styled.div``

const Cell = styled.div``

const Styles = styled.div`
    .table {
        .th,
        .td {
            //padding: 5px;
            //border-bottom: 1px solid #ddd;
            //border-right: 1px solid #d0d;
            overflow: hidden;
            :last-child {
                border-right: 0;
            }
        }
        &.sticky {
            overflow-y: auto;
            overflow-x: hidden;
            .header,
            .footer {
                position: sticky;
                z-index: 1;
                width: fit-content;
            }
            .header {
                top: 0;
                box-shadow: 0px 3px 3px #ccc;
            }
            .footer {
                bottom: 0;
                box-shadow: 0px -3px 3px #ccc;
            }
            .body {
                position: relative;
                z-index: 0;
            }
            [data-sticky-td] {
                position: sticky;
            }
            [data-sticky-last-left-td] {
                box-shadow: 2px 0px 3px #ccc;
            }
            [data-sticky-first-right-td] {
                box-shadow: -2px 0px 3px #ccc;
            }
        }
    }
`
