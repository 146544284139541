import React, {Component, PropTypes} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import IconButton from 'material-ui/IconButton'
import CircularProgress from 'material-ui/CircularProgress'
import BaseManageTable from './BaseManageTable'
import DataService from '@services/data'
import {
    red400,
    green400,
    grey400,
    grey200,
    grey50,
} from 'material-ui/styles/colors'

// import moment from 'moment'
// import ReactTable from 'react-table'
// import Pagination from '../General/Pagination'

import Api from '@services/api'
// import {formatDailyChange} from '@services/utils'
// import * as AppActions from '@store/actions/app-actions'

class AccountsTable extends Component {
    constructor(props) {
        super(props)

        this.api = new Api()
        this.state = {}
        this.applySearchFilter = this.applySearchFilter.bind(this)
    }

    componentWillMount() {
        this.props.fetchData()
    }

    getColumns() {
        let idObj = {
            accessor: 'id',
            Header: 'ID',
            sortable: false,
            Cell: (row) => <div style={{fontSize: 7}}>{row.row.values.id}</div>,
        }

        let nameObj = {
            accessor: 'name',
            Header: 'Name',
            sortable: true,
        }

        // 12.04.22 remove. what was it for..?
        // let apiKeyObj = {
        //     accessor: 'api_key',
        //     Header: 'API Key',
        //     sortable: true,
        // }

        let masterProfileObj = {
            accessor: 'master_profile',
            Header: 'Master Profile',
            //maxWidth: 50,
            sortMethod: (a, b) => {
                const v1 = a.email
                const v2 = b.email

                if (v1 < v2) {
                    return -1
                } else if (v1 > v2) {
                    return 1
                } else {
                    return 0
                }
            },
            Cell: (row) => (
                <div style={{textAlign: 'center'}}>
                    {row.value ? row.value.name : null}{' '}
                    {/* 12.06.22 was email */}
                </div>
            ),
        }

        let linkedProfilesObj = {
            accessor: 'linked_profiles',
            Header: 'Linked Profiles',
            sortable: false,
            Cell: (row) => (
                <div style={{textAlign: 'center', whiteSpace: 'pre-wrap'}}>
                    {row.value
                        ? row.value.map((lp) => lp.name).join(', ') //12.06.22 was email
                        : null}
                </div>
            ),
        }

        let actionsObj = {
            accessor: 'id_',
            sortable: false,
            Cell: (row) => (
                <div style={{textAlign: 'center', textWrap: 'wrap'}}>
                    {/* TODO: sure this is the wrong way... row.row.values.id */}
                    <Link to={'/manage/account/' + row.row.values.id}>
                        <IconButton
                            style={{height: 25}}
                            iconStyle={{color: grey400, fontSize: 15}}
                            onClick={() => {
                                console.log('edit', row.id)
                            }}
                            iconClassName="material-icons">
                            edit
                        </IconButton>
                    </Link>
                </div>
            ),
        }
        let validTo = {
            accessor: 'valid_to',
            Header: 'Valid',
            sortable: true,
            Cell: (s) =>
                s.value ? new Date(s.value).toLocaleDateString('en-US') : null,
        }
        return [
            idObj,
            nameObj,
            //apiKeyObj,
            masterProfileObj,
            linkedProfilesObj,
            validTo,
            actionsObj,
        ]
    }

    applySearchFilter(searchText) {
        let {data} = this.props

        return data.filter(
            (d) =>
                d.id.indexOf(searchText) !== -1 ||
                d.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
                (d.api_key && d.api_key.indexOf(searchText) !== -1) ||
                (d.master_profile.email &&
                    d.master_profile.email
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) !== -1),
        )
    }

    render() {
        let {data} = this.props
        console.log('data:', data)
        return (
            //<div></div>
            <BaseManageTable
                mode="account"
                columns={this.getColumns()}
                searchFilter={this.applySearchFilter}
                data={data}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.manage.data.accounts,
    }
}

const mapDispatchToProps = (dispatch) => {
    const ds = new DataService(dispatch)

    return {
        fetchData: () => ds.getAccounts(),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsTable)
