import React from 'react'
import Header from './Header'
import {AppColors, Dimensions} from '@services/styles'
import styled from 'styled-components'
import {MailOutline} from '@mui/icons-material'
import AppBar from 'material-ui/AppBar'
import {api} from '@services/api'
import {useState} from '@services/hooks'
import Swal from 'sweetalert2'
import {AppBarBack} from './NavAppBar/NavAppBar.styles'
import FrameInput from './General/FrameInput'
import FrameSelect from './General/FrameSelect'
import FrameText from './General/FrameText'
import {Title, SendButton} from './General/Elements'
import isEmail from 'validator/lib/isEmail'

const Contact = () => {
    const [name, setName] = useState('')
    const [mail, setMail] = useState('')
    const [phone, setPhone] = useState('')
    const [company, setCompany] = useState('')
    const [text, setText] = useState('')
    const [reason, setReason] = useState('')
    const reasons = [
        //{label: 'Supporttarget', value: 'Supporttarget'},
        {label: 'New User', value: 'New User'},
        {label: 'Profile Issues', value: 'Profile Issues'},
        {label: 'Wrong Data', value: 'Wrong Data'},
        {label: 'Other', value: 'Other'},
    ]
    const sendMessage = () => {
        if (!isEmail(mail.trim())) {
            Swal.fire({
                text: 'Please enter a valid email',
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
            return
        }
        api.sendSupportMail(
            name,
            mail.trim(),
            phone.trim(),
            company,
            reason,
            text,
        ).subscribe(
            (response) => {
                Swal.fire({
                    text: 'We are on it. Our team would get back to you asap.',
                    confirmButtonColor: AppColors.TIPIGO_GREEN,
                })
            },
            (error) => {
                console.log('send support error', error)
            },
        )
    }
    return (
        <Container>
            <Header />
            <AppBar
                style={{
                    height: Dimensions.APP_BAR_HEIGHT,
                    backgroundColor: AppColors.SUB_HEAD_GREY,
                    position: 'fixed',
                    zIndex: 10,
                    top: Dimensions.NAVBAR_TOP_OFFSET,
                    alignItems: 'center', // #stupidhead
                }}
                iconStyleLeft={{marginTop: 0}}
                iconElementLeft={
                    <AppBarBack onClick={() => (window.location = '/#/')} />
                }
            />
            <Title text={'How can we help?'} />
            <Main>
                <Side>
                    <LeftText>
                        Our professional team would be happy to help you with
                        your question. Please fill in the form with the details
                        of your question or request or send us an email message
                        if you need to add attachments.
                    </LeftText>
                    <Mail href="mailto:support@tipigo.com">
                        <MailOutline sx={{color: '#444444'}} />
                        <MailText>support@tipigo.com</MailText>
                    </Mail>
                </Side>
                <Side>
                    <FrameInput
                        value={name}
                        setValue={setName}
                        title={'Full Name'}
                        maxLength={100}
                    />
                    <FrameInput
                        value={mail}
                        setValue={setMail}
                        title={'Email'}
                        style={{marginTop: 16}}
                        maxLength={100}
                    />
                    <FrameInput
                        value={phone}
                        setValue={setPhone}
                        title={'Phone Number'}
                        style={{marginTop: 16}}
                        maxLength={40}
                    />
                    <FrameInput
                        value={company}
                        setValue={setCompany}
                        title={'Company'}
                        style={{marginTop: 16}}
                        maxLength={100}
                    />
                    <FrameSelect
                        title={'Support Topic'}
                        value={reason}
                        values={reasons}
                        setValue={setReason}
                        style={{marginTop: 16}}
                    />
                    {/* <SelectCont>
                        <RSelect
                            placeholder={'Support Topic'}
                            styles={STYLE}
                            options={reasons}
                            closeMenuOnSelect={true}
                            onChange={(selected) => setReason(selected.value)}                        
                        />
                    </SelectCont> */}
                    <FrameText
                        value={text}
                        setValue={setText}
                        title={'Message'}
                        style={{marginTop: 16, flex: 1}}
                        frameStyle={{height: 150}}
                    />
                    {/* <FreeText
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder={'Message'}
                    /> */}
                    <SendButton onClick={sendMessage} style={{marginTop: 16}}>
                        Send
                    </SendButton>
                </Side>
            </Main>
        </Container>
    )
}

export default Contact

// const STYLE = {
//     control: (base) => ({
//         ...base,
//         border: 0,
//         // This line disable the blue border
//         boxShadow: 'none',
//     }),
// }

const Container = styled.div`
    margin-top: ${50 +
    Dimensions.APP_BAR_HEIGHT +
    Dimensions.NAVBAR_TOP_OFFSET}px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
// const Title = styled.div`
//     font-size: 50px;
//     margin-bottom: 16px;
// `
const Main = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 60px;
    margin-top: 30px;
    width: 65%;
`
const Side = styled.div`
    display: flex;
    flex-direction: column;
    border: 0px solid;
    paddig-right: 20px;
    flex: 1;
`
const LeftText = styled.div`
    color: #565656;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 20px;
    padding-right: 20px;
`
const Mail = styled.a`
    display: flex;
    flex-direction: row;
    text-decoration: none;
    margin-bottom: 14px;
`
// const Address = styled.div`
//     display: flex;
//     flex-direction: row;
// `
const MailText = styled.div`
    font-size: 18px;
    line-height: 24px;
    color: #444444;
    margin-left: 16px;
`
