import React, {Component} from 'react'

import {connect} from 'react-redux'

// import {Card, CardTitle, CardText} from 'material-ui/Card'
//import ArrowBackIcon from '@mui/icons-material/ArrowBack'
// import {AppBarBack, UcContainer} from './NavAppBar/NavAppBar.styles'
// import AddEditPosition from './Home/MyFile/AddEditPosition'
// import {Tabs, Tab} from 'material-ui/Tabs'
//import {PageContent} from '@components/Home/Home.styled'
// import {Checkbox} from 'material-ui'
// import Checkbox from '@mui/material/Checkbox'
// import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import Menu from 'material-ui/Menu'
import MenuItem from 'material-ui/MenuItem'
import Divider from 'material-ui/Divider'
import CircularProgress from 'material-ui/CircularProgress'
import {connectedWatchlistIcon as WatchlistIcon} from './General/WatchlistButton'
import _ from 'lodash'
import StockNews from './Stock/StockNews'
import StockRatings from './Stock/StockRatings'
import StockScores from './Stock/StockScores'
import * as Colors from 'material-ui/styles/colors'
import Api from '@services/api'
import * as actions from '@store/actions/api-actions'
import moment from 'moment'
import {ReactComponent as AddSymbol} from '@images/add.svg'
import Header from './Header'
import {AppColors, Dimensions} from '@services/styles'
import BulkAddPositions from './Home/MyFile/BulkAddPositions'
import ReactTooltip from 'react-tooltip'
import NavBar, {getActiveTab} from './NavAppBar'
import {Link} from 'react-router-dom'
import {SendButton} from './General/Elements'
import styled from 'styled-components'

const styles = {
    subtitle: {
        color: '#8c8c8c',
        fontWeight: '200',
    },
    headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
    },
    tabButtonStyle: {
        color: Colors.blue900,
    },
    addPortIcon: {
        stroke: AppColors.APP_GRAY,
        marginRight: 5,
        cursor: 'pointer',
        border: 0,
        outline: 'none',
    },
    LastRatingHeader: {
        backgroundColor: '#efefef',
        marginTop: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 12,
    },
    LastRatingHeaderText: {fontSize: 17},
    checkContainer: {
        display: 'flex',
        marginLeft: 10,
    },
}

class Stock extends Component {
    constructor(props, context) {
        super(props, context)

        this.api = new Api()

        this.apiCalls = {}

        this.state = {
            data: null,
            newsDialogOpen: false,
            dialogTitle: '',
            dialogContent: '',
            dialogImage: null,
            loading: false,
            error: null,
            recommendations: [],
            top_ten: [],
            news: [],
            addPortfolioDialog: false,
            teamOnly: false,
        }
    }

    componentWillMount() {
        const {user} = this.props

        if (!user || !user.session_token) {
            console.warn(
                'Missing user or session token, redirecting back to login',
            )
            // TODO: back to login or something
            return
        }
    }

    componentDidMount() {
        const {match, user, addUserAnalystList} = this.props
        const symbol = match.params.symbol

        if (!symbol) {
            this.setState({error: 'No symbol provided', loading: false})
            return
        }
        if (!user || !user.analystList) {
            addUserAnalystList()
        }
        this.reloadStock(symbol)
    }

    handleOpen = () => {
        this.setState({newsDialogOpen: true})
    }

    handleClose = () => {
        this.setState({
            newsDialogOpen: false,
            dialogContent: '',
            dialogTitle: '',
            dialogImage: null,
        })
    }

    componentWillReceiveProps(nextProps) {
        const {match} = nextProps
        const symbol = match.params.symbol

        if (!symbol) {
            this.setState({error: 'No symbol provided', loading: false})
            return
        }

        if (this.state.symbol === symbol) {
            // Nothing changed
            return
        }

        this.reloadStock(symbol)
    }

    reloadStock(symbol) {
        this.setState({loading: true, error: null, data: null, symbol})

        Promise.all([
            this.getStockData(symbol),
            this.getCompanyData(symbol),
            //this.getLastSymbolRecommendations(symbol),
            this.getNewsForStock(symbol),
        ]).then(
            (values) => {
                const stock = values[0]
                const company = values[1]

                const top_ten = _.values(values[2].last_analyst_recommendations)
                const news = values[3]

                this.setState({
                    loading: false,
                    error: null,
                    stock,
                    company,
                    //recommendations,
                    top_ten,
                    news,
                })
            },
            (error) => {
                this.setState({
                    loading: false,
                    error: 'Failed to get stock/company data',
                })
            },
        )
    }

    getStockData(symbol) {
        return new Promise((resolve, reject) => {
            this.apiCalls['getStockData'] = this.api
                .fetchStock(symbol)
                .subscribe(
                    (response) => {
                        if (response.data) {
                            console.log('getStockData', response.data)
                            resolve(response.data)
                        } else {
                            reject('Failed to get stock data')
                        }
                    },
                    (error) => {
                        reject(error)
                    },
                )
        })
    }

    getCompanyData(symbol) {
        return new Promise((resolve, reject) => {
            this.apiCalls['getCompanyData'] = this.api
                .getCompanyProfile(symbol)
                .subscribe(
                    (response) => {
                        if (response.data) {
                            console.log('getCompanyData', response.data)
                            resolve(response.data)
                        } else {
                            reject('Failed to get company profile')
                        }
                    },
                    (error) => {
                        reject(error)
                    },
                )
        })
    }

    getNewsForStock(symbol) {
        return new Promise((resolve, reject) => {
            resolve([
                {
                    title: 'demo',
                    subtitle: 'the big fat fox jumped over the dead cow',
                },
            ])
            // comment. not in use. but api may still be active
            // this.apiCalls['getNewsForStock'] = this.api
            //     .getNewsForStock(symbol)
            //     .subscribe(
            //         (response) => {
            //             console.log('getNewsForStock', response)
            //             if (response.data && _.isObject(response.data)) {
            //                 resolve(response.data)
            //             } else {
            //                 reject('Failed to get reco')
            //             }
            //         },
            //         (error) => {
            //             reject(error)
            //         },
            //     )
        })
    }

    componentWillUnmount() {
        _.forOwn(this.apiCalls, (value, key) => {
            if (_.isFunction(value.dispose)) {
                value.dispose()
            }
        })
    }

    extractStockDetails(stock) {
        return {
            Index:
                stock.StockExchange && stock.StockExchange != 'na'
                    ? stock.StockExchange
                    : 'N/A',
            'Daily range': stock.DaysRange || 'N/A',
            'Market cap': stock.MarketCapitalization || 'N/A',
            // "Stock exchange": stock.StockExchange,
            'Prev Day volume': stock.Volume
                ? new Intl.NumberFormat().format(stock.Volume)
                : 'N/A',
            '30d Avg Volume': stock.avg_vol
                ? new Intl.NumberFormat().format(stock.avg_vol)
                : 'N/A',
            Beta: stock.beta || 'N/A',
            'Earning date': stock.earningDate
                ? moment(stock.earningDate, 'YYYY-MMM-DD').format(
                      'D MMMM, YYYY',
                  )
                : 'N/A',
            'P/E': stock.pe_ratio_12m || 'N/A',
            'P/B Ratio': stock.pb_ratio || 'N/A',
            'Bid/Ask': stock.bid
                ? new Intl.NumberFormat().format(stock.bid)
                : 'N/A',
            // cancel 25.11.21 data not trusted..? 'PEG ratio': stock.PEG || 'N/A',
            'Forward dividend': stock.iad || 'N/A',
            EPS: stock.eps_act_fr0 || 'N/A', //'EPS last reported fiscal year'
            // cancel 25.11.21 'Avg daily vol last 20 days': stock.avg_vol_20d || 'N/A',
            'Shares Outstanding': stock.shares_outstanding || 'N/A',
            'Return on Equity': stock.roe || 'N/A',
        }
    }

    renderDataMenu(data, max = null) {
        if (data.length < max) {
            // Let's add additional empty items
            let diff = max - data.length
            data = _.concat(data, _.times(diff, ['', '']))
        }

        return (
            <Menu>
                {data.map((pair, i) => {
                    // Format what's needed
                    if (
                        ['Volume', 'Avg daily vol last 20 days'].indexOf(
                            pair[0],
                        ) > -1
                    ) {
                        // Format price
                        pair[1] = parseFloat(pair[1]).toLocaleString()
                    }

                    return (
                        <MenuItem
                            key={'stock_data_' + i}
                            disabled={true}
                            style={{
                                borderBottomWidth: 1,
                                borderBottomColor: 'rgb(224, 224, 224)',
                                borderBottomStyle: 'solid',
                            }}
                            primaryText={
                                <span style={{color: '#777'}}>{pair[0]}</span>
                            }
                            secondaryText={pair[1]}
                        />
                    )
                })}
            </Menu>
        )
    }

    setOpenPortDialog = (open) => {
        this.setState({addPortfolioDialog: open})
        if (!open) {
            console.log('add_port close')
            this.api.isStockInUserFile(this.state.stock.symbol).subscribe(
                (response) => {
                    if (response?.data?.in_file) {
                        console.log('in_file yes', response)
                        this.setState({
                            stock: {...this.state.stock, in_file: true},
                        })
                    }
                },
                (error) => {
                    console.log('in_file no', error)
                },
            )
        }
    }

    renderStock() {
        if (this.state.loading === true) {
            return (
                <div style={{marginTop: 40, textAlign: 'center'}}>
                    <CircularProgress />
                    <p>Loading stock data...</p>
                </div>
            )
        } else if (this.state.stock) {
            const stockDetails = _.toPairs(
                this.extractStockDetails(this.state.stock),
            )

            let rightSide = stockDetails.slice(0)
            let half = Math.ceil(rightSide.length / 2)
            let leftSide = rightSide.splice(0, half)

            let max = Math.max(rightSide.length, leftSide.length)

            const stock = this.state.stock

            let stockValColor = Colors.grey50
            const dailyChangeVal = parseFloat(
                stock.dailyChangeVal.replace('--', '-'),
            )
            if (dailyChangeVal && dailyChangeVal > 0) {
                stockValColor = Colors.green400
            } else if (dailyChangeVal && dailyChangeVal < 0) {
                stockValColor = Colors.red400
            }

            // 20.03.23 interval=61 => interval=1D
            let url =
                'https://s.tradingview.com/widgetembed/?symbol=xxxxxxx&interval=1D&hidesidetoolbar=0&symboledit=0&saveimage=1&studies=%5B%5D&hideideas=1&theme=White&style=1&timezone=Etc%2FUTC&withdateranges=1&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en&utm_medium=widget&utm_campaign=chart'

            url = url.replace('symbol=xxxxxxx', 'symbol=' + this.state.symbol)
            return (
                <div
                    style={{
                        maxWidth: 1000,
                        flex: 1,
                        padding: 20,
                        border: '0px solid red',
                    }}>
                    <ReactTooltip />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                border: '0px solid yellow',
                                marginTop: 20,
                            }}>
                            {/* 03.07.23 remove because bad benziga data, but they have a new ver api
                            <div
                                style={{
                                    display: 'block',
                                    border:
                                        '0.4px solid ' +
                                        AppColors.APP_GRAY_LIGHT,
                                    borderRadius: 3,
                                    width: 36,
                                    height: 36,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <img
                                    //src={stock.logo}
                                    src="https://image-util.benzinga.com/api/v2/logos/file/image/320193/mark_vector_dark__636684c42070cb48096151b6a5f5577d.svg?x-bz-cred=sb~jdPQ__WcWNPg4bWhRJ25IVT6p-bdUUd8tpncEYOeiovSx49SIlgPmnJi7VgJ8PmbbefI3v2xXdziIfO_Vt2-ME2CpBvu5BqkLLsnAix9yQKLlFbcR3MpBuL-SOAE3ETk3xoNCfV_&x-bz-exp=1688466196&x-bz-security-isin=US0378331005&x-bz-security-symbol=AAPL&x-bz-signature=a9374e771b95e94ebb34aaaa2018efe922d9b706221436143dd78fa9049ca435"
                                    style={{
                                        maxWidth: 32,
                                        maxHeight: 32,
                                        border: '0px solid red',
                                    }}
                                />
                            </div> */}
                            <div style={{border: '0px solid', marginLeft: 10}}>
                                <h1
                                    style={{
                                        fontSize: 32,
                                        marginBottom: 16,
                                        marginTop: 8,
                                        lineHeight: '34px',
                                    }}>
                                    {stock.comp_name_2 || stock.Name || ''}
                                    <small
                                        style={{
                                            fontWeight: 'normal',
                                            fontSize: 22,
                                            marginLeft: 20,
                                        }}>
                                        {this.state.stock.lastPrice}
                                        <span
                                            style={{
                                                marginLeft: 20,
                                                color: stockValColor,
                                            }}>
                                            {stock.dailyChangeVal} (
                                            {stock.dailyChange}%)
                                        </span>
                                    </small>
                                </h1>
                                <SubTitle>
                                    <h2 style={{color: '#666'}}>
                                        {stock.StockExchange &&
                                        stock.StockExchange !== 'na'
                                            ? stock.StockExchange + ':'
                                            : ''}
                                        {stock.symbol}
                                    </h2>
                                    {stock.in_file ? (
                                        <TagInFile>Portfolio</TagInFile>
                                    ) : (
                                        <TagNotInFile>
                                            Not in Portfolio
                                        </TagNotInFile>
                                    )}
                                </SubTitle>
                                {!this.state.stock.security_etf && (
                                    <Link
                                        to="/#"
                                        // not very good but simple. if they don't like it find better
                                        onClick={() =>
                                            document
                                                .getElementById('ratings')
                                                .scrollIntoView({
                                                    behavior: 'smooth',
                                                    block: 'start',
                                                })
                                        }
                                        style={{
                                            color: AppColors.TIPIGO_GREEN,
                                            fontSize: 16,
                                        }}>
                                        Last Ratings
                                    </Link>
                                )}
                            </div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <AddSymbol
                                onClick={() => this.setOpenPortDialog(true)}
                                data-tip="Add a position to Portfolio"
                                style={styles.addPortIcon}
                            />
                            <WatchlistIcon symbol={stock.symbol} />
                        </div>
                    </div>
                    <Divider style={{marginTop: 30, marginBottom: 30}} />

                    {this.state.symbol ? (
                        <div
                            style={{
                                display: 'flex',
                                border: '0px solid green',
                            }}>
                            <div style={{flex: 2, border: '0px solid red'}}>
                                <iframe
                                    id="tradingview_b0d5c"
                                    src={url}
                                    width="100%"
                                    height={500}
                                    frameBorder={0}
                                    allowTransparency="true"
                                    scrolling="no"
                                    title="trading_if"
                                />
                                <Divider
                                    style={{marginTop: 30, marginBottom: 30}}
                                />
                                <div style={{display: 'flex'}}>
                                    <div style={{flex: 1}}>
                                        {this.renderDataMenu(leftSide, max)}
                                    </div>
                                    <div style={{flex: 1}}>
                                        {this.renderDataMenu(rightSide, max)}
                                    </div>
                                </div>

                                <StockScores symbol={this.state.symbol} />

                                <div id="ratings" />

                                {!this.state.stock.security_etf && (
                                    // TODO: fix security_etf collection
                                    // many of them don't have company_profile, why?
                                    <StockRatings symbol={this.state.symbol} />
                                )}
                            </div>

                            <div
                                style={{
                                    flex: 1,
                                    marginLeft: 30,
                                    border: '0px solid gray',
                                }}>
                                <Box>
                                    <h2 style={{marginBottom: 4, marginTop: 0}}>
                                        {stock.comp_name_2 || stock.Name || ''}
                                    </h2>
                                    <p style={{marginTop: 0}}>
                                        <span style={styles.subtitle}>
                                            {stock.sector}
                                        </span>
                                    </p>
                                    {stock.comp_desc && stock.comp_desc !== '0'
                                        ? stock.comp_desc
                                        : ''}
                                </Box>

                                <StockNews symbol={this.state.symbol} />
                                {false && // see getNewsForStock to renew
                                this.state?.news?.length ? (
                                    <div>
                                        <h2>Latest news</h2>
                                        {this.state.news.map((n, i) => {
                                            return (
                                                <Box key={'news_' + i}>
                                                    <h3 style={{marginTop: 2}}>
                                                        {n.title}
                                                        <br />
                                                        <small
                                                            style={
                                                                styles.subtitle
                                                            }>
                                                            {moment(
                                                                n.created,
                                                            ).format(
                                                                'D MMMM, YYYY',
                                                            )}
                                                        </small>
                                                    </h3>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: n.teaser,
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            color: Colors.blue400,
                                                            marginTop: 10,
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            this.setState({
                                                                newsDialogOpen: true,
                                                                dialogContent:
                                                                    n.body,
                                                                dialogTitle:
                                                                    n.title,
                                                                dialogImage:
                                                                    n.image &&
                                                                    n.image
                                                                        .length >
                                                                        1
                                                                        ? n
                                                                              .image[1]
                                                                              .url
                                                                        : null,
                                                            })
                                                        }}>
                                                        READ MORE
                                                    </div>
                                                </Box>
                                            )
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <CircularProgress />
                    )}

                    <div style={{display: 'flex'}}>
                        <div style={{flex: 2}}></div>
                    </div>
                    <BulkAddPositions
                        //userId={this.state.user}
                        //pId={myFile.id}
                        isOpenDialog={this.state.addPortfolioDialog}
                        setIsOpenDialog={this.setOpenPortDialog}
                        ticker={stock.symbol}
                        msgOnSuccess
                    />
                </div>
            )
        } else {
            return (
                <div style={{textAlign: 'center', marginTop: 40}}>
                    <h3>Oops! something went wrong :(</h3>
                    <p>{this.state.error}</p>
                </div>
            )
        }
    }

    render() {
        const actions = [
            <FlatButton
                label="Close"
                keyboardFocused={true}
                onClick={this.handleClose}
            />,
        ]

        return (
            <div>
                <Header />
                <NavBar rightElement={null} changePortfolioModeFunc={null} />

                <Dialog
                    title={
                        <div
                            style={{
                                backgroundImage:
                                    'url(' + this.state.dialogImage + ')',
                                backgroundSize: 'cover',
                                height: 100,
                                backgroundPosition: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <div
                                style={{
                                    backgroundColor: 'rgba(0,0,0,.75)',
                                    color: '#fff',
                                    padding: 20,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    borderRadius: 8,
                                }}>
                                {this.state.dialogTitle}
                            </div>
                        </div>
                    }
                    actions={actions}
                    modal={false}
                    open={this.state.newsDialogOpen}
                    autoScrollBodyContent={true}
                    onRequestClose={this.handleClose}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this.state.dialogContent,
                        }}
                    />
                </Dialog>

                <Container>{this.renderStock()}</Container>
            </div>
        )
    }
}

// Stock.propTypes = {};

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
    }
}
const mapDispatchToProps = (dispatch) => {
    const api = new Api()
    return {
        addUserAnalystList: () => {
            api.getAnalystList().subscribe((response) => {
                dispatch(actions.updateUserAnalystList(response.data))
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stock)

const Box = styled.div`
    border 1px solid rgb(224, 224, 224);   
    padding: 15px;
    margin-bottom: 30px;
`
const Container = styled.div`
    margin-top: ${Dimensions.NAVBAR_TOP_OFFSET}px;
    padding: ${Dimensions.CONTENT_BASE_PADDING}px;
    padding-top: 40px;
    display: flex;
    justify-content: center;
`
const TagInFile = styled(SendButton)`
    padding-bottom: 4px;
    padding-top: 4px;
    padding-left: 8px;
    padding-right: 8px;
    height: 25px;
    margin-left: 15px;
    font-size: 14px;
    cursor: default;
`
const TagNotInFile = styled(TagInFile)`
    background: #bbbbbb;
`
const SubTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
