import React, {useEffect} from 'react'
import 'chartjs-adapter-luxon'
//import {api} from '@services/api'
import {useRef} from '@services/hooks'
import CircularProgress from 'material-ui/CircularProgress'
import styled from 'styled-components'
import {Line} from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {ChartColors} from '@services/styles'

let hovering = false
const options = {
    layout: {
        // cancel that since we moved scale to right and cancel last label - see comments brlow
        //padding: {right: 50}, // so last data label doesn't clip
    },
    indexAxis: 'x',
    spanGaps: false,
    scales: {
        y: {
            position: 'right',
            grid: {
                display: false,
                //{ callback: function () {return true},},
            },
            ticks: {
                //beginAtZero: false,
                callback: function (value, index, values) {
                    return value.toFixed(2) + '%'
                },
            },
        },

        // very strange: x:{} and also xAxes:[]
        // both work but very different, both have an effect but properties are not the same
        // seems like a big mess. it has to do with version but not sure wich one is right here. problem is they bot effect..
        x: {
            grid: {
                //drawBorder: true,
                display: false,
                //lineWidth: 0,
                //drawOnChartArea: true,
            },
            type: 'time',
            time: {
                tooltipFormat: 'DD',
                // unit: function (v, d, x) {
                //     const ds = v?.scale?.chart?.$datalabels?._datasets
                //     if (ds.length) {
                //         console.log('chart_unit length')
                //     } else {
                //         console.log('chart_unit 0')
                //     }

                //     return null
                // }, // 'day', //TODO: lern more about this, can use callback? can be auto?
                // when not using 'unit' it selects day or month by range. BUT than on small scale it uses hours...
            },
            ticks: {
                maxRotation: 0,
                minRotation: 0,
                // maxTicksLimit: 7,
                // callback: function (value, index, tickes) {
                //     //console.log(tickes.length)
                //     var d = new Date(this.getLabelForValue(value))
                //     return (
                //         d.toLocaleString('en-US', {month: 'short'}) +
                //         ' ' +
                //         d.getFullYear()
                //     )

                // },
            },
        },
    },
    elements: {
        point: {
            radius: 0,
        },
        //line: {borderWidth: 5},
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: true,
            align: 'start',
            //maxWidth: 16,
            //fullSize: false,
            labels: {boxWidth: 14, boxHeight: 14},
            onHover: function (event, legendItem) {
                return //TODO: not done, complete conditional text. and display at begining
                if (hovering) {
                    return
                }
                const tooltip = document.getElementById('xyz')
                hovering = true
                tooltip.innerHTML = 'some text'
                tooltip.style.left = event.x + 'px'
                tooltip.style.top = event.y + 'px'
                tooltip.style.display = ''
            },
            onLeave: function () {
                const tooltip = document.getElementById('xyz')
                tooltip.style.display = 'none'
                hovering = false
                tooltip.innerHTML = ''
            },
        },
        title: {
            display: false,
        },
        tooltip: {
            enabled: true,
            intersect: false, //false to alwys show. will select the one closest to cursor but position is still on the line
            displayColors: false, // shows a stupid box color that will change color within same set!
            callbacks: {
                label: function (item) {
                    return item.dataset.label + ': ' + item.raw.toFixed(2) + '%'
                },
                //beforeLabel: function (item) {return item.dataset.label},
            },
        },
        datalabels: {
            formatter: function (value, context) {
                return ' ' + (Math.round(value * 100) / 100).toFixed(2) + '%'
            },
            display: (context) => {
                // this was to display only last. but since we moved scale to right side we dont need
                // unlil the Nundnik asks for it again
                return false //context.dataIndex == context.dataset.data.length - 1
            },
            //  anchor: 'end',
            align: 'right',
            clip: false,
        },
    },
    // will give colors by order, so first always green, second always gray and so on..
    // so 'file' is always first and green but all other lines don't have a fixed color
    borderColor: ChartColors,
    backgroundColor: ChartColors,
}

//tickers, etfs, fromDate, toDate
const LineChart = ({graphData, loading, height, border}) => {
    const chartRef = useRef(null)
    // const testExport = () => {
    //     const link = document.createElement('a')
    //     link.download = 'myFile.png'
    //     link.href = chartRef.current.toBase64Image()
    //     link.click()
    // }
    useEffect(() => {
        if (graphData?.datasets) {
            if (graphData?.datasets[0].data.length < 10) {
                options.scales.x.time.unit = 'day'
            } else if (graphData?.datasets[0].data.length > 60) {
                options.scales.x.time.unit = 'month'
            } else {
                delete options.scales.x.time.unit // might have been set before
            }
        }
    }, [graphData])
    // const data = {
    //     labels: ['a', 'b', 'c', 'd', 'e'],
    //     datasets: [
    //         {
    //             label: 'line name',
    //             data: [12, 19, 3, 5, 2],
    //             //borderWidth: 1,
    //             //yAxisID: 'y',
    //         },
    //         {
    //             label: 'other name',
    //             data: [2, 9, 13, 8, 11, 3],
    //             //borderWidth: 1,
    //         },
    //     ],
    // }
    //const [graphData, setGraphData] = useState(data)
    //const [loading, setLaoding] = useState(0)

    // useEffect(() => {
    //     if (fromDate && toDate && tickers) {
    //         setLaoding(1)
    //         api.getBacktestGraphData(fromDate, toDate, tickers, etfs).subscribe(
    //             (response) => {
    //                 console.log('get backtest data', response)

    //                 setGraphData(response.data)
    //                 setLaoding(2)
    //             },
    //             (error) => {
    //                 setLaoding(0)
    //                 console.log('get backtest data error', error)
    //             },
    //         )
    //     } else {
    //         setLaoding(0)
    //     }
    // }, [tickers, etfs, fromDate, toDate])

    return (
        <Container
            style={{
                height: height ? height : null,
                // border: border
                //     ? '0.5px solid ' + AppColors.APP_GRAY_LIGHT
                //     : null,
                padding: border ? 10 : 0,
            }}>
            {loading == 0 ? null : loading == 1 ? (
                <Circule>
                    <CircularProgress />
                </Circule>
            ) : (
                graphData?.datasets?.length && (
                    <Line
                        ref={chartRef}
                        data={graphData}
                        options={options}
                        plugins={[ChartDataLabels]}
                    />
                )
            )}
            <LegendTooltip id="xyz" />
            {/* <div onClick={testExport}>export</div> */}
        </Container>
    )
}
export default LineChart

const LegendTooltip = styled.div`
    position: relative;
    background-color: #000;
    color: #fff;
    padding-top: 3px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 3px;
    display: none;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`
//align-items: center; - dont use - fucks the chart size
// position: relative needed to be able to set width and height on the container (by docs)

const Circule = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`
