import React from 'react'

import {api} from '@services/api'
import {useState, useEffect, useUser} from '@services/hooks'
import styled, {createGlobalStyle} from 'styled-components'
import {Title} from '../General/Elements'
import CircularProgress from 'material-ui/CircularProgress'
import {Bar} from 'react-chartjs-2'
import DateRange from '@components/General/DateRange'
import {Collapse} from 'react-collapse'
import {AppColors, ChartGreensReds} from '@services/styles'
import {sectorShortName} from '@services/utils'
import {Checkbox} from '@components/General/Elements'
import FrameSelect from '@components/General/FrameSelect'
import moment from 'moment'
import {formatUniverseResponse} from '@services/utils'

const fullNames = [
    {title: 'S&P500', name: 'SPY'},
    {title: 'Industrials', name: 'XLI'},
    {title: 'Materials', name: 'XLB'},
    {title: 'Real Estate', name: 'VNQ'},
    {title: 'Real Estate', name: 'XLRE'}, // both Real Estate. not a bug. but only one in chart (data from server)
    {title: 'Communication Services', name: 'XLC'},
    {title: 'Nasdaq 100', name: 'QQQ'},
    {title: 'Consumer Discretionary', name: 'XLY'},
    // 27.06.23 {title: 'Communication Services', name: 'VOX'},
    {title: 'Energy', name: 'XLE'},
    {title: 'HealthCare', name: 'XLV'},
    {title: 'Finance', name: 'XLF'},
    {title: 'Semiconductors', name: 'SOXX'},
    {title: 'Technology', name: 'XLK'},
    {title: 'Consumer Staples', name: 'XLP'},
    {title: 'Utilities', name: 'XLU'},
    {title: 'Aerospace & Defense', name: 'ITA'},
    {title: 'Water Resources', name: 'PHO'},
    {title: 'China Internet', name: 'KWEB'},
]

const options = {
    indexAxis: 'x',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    // elements: {
    //     bar: {
    //         borderWidth: 1,
    //     },
    // },
    scales: {
        y: {
            grid: {display: false},
            ticks: {
                //beginAtZero: false,
                callback: function (value, index, values) {
                    return value + '%'
                },
            },
        },
        xAxis: {grid: {display: false}},
    },
    responsive: true,
    plugins: {
        legend: {
            //position: 'left',
            display: false,
            labels: {color: 'red', boxWidth: 100},
            title: {color: 'red', text: 'stam', display: true},
        },
        // title: {
        //     display: false,
        //     text: 'Market Trends',
        // },

        tooltip: {
            displayColors: false, // shows a stupid box color that will change color within same set!
            enabled: true,
            callbacks: {
                label: function (tooltipItem) {
                    return tooltipItem.raw.toFixed(2) + '%'
                },
                afterTitle: function (tooltipItems) {
                    const bm = fullNames.find(
                        (item) => item.name === tooltipItems[0].label,
                    )
                    return bm && bm.title
                    //return 'after title' //console.log(tooltipItems)
                },
                // title: function (tooltipItem) {
                //     return 'title' //console.log(tooltipItem)
                // },
            },
        },
    },
}
const options_num = {
    ...options,
    parsing: {yAxisKey: 'val', xAxisKey: 'id'},
    scales: {
        //...options.scales,

        xAxes: {
            grid: {display: false},
        },

        yAxes: {
            grid: {display: false},

            id: 'A',
            position: 'left',

            //stacked: true,
            title: {
                text: 'Number of Ratings',
                display: true,
                padding: {bottom: 10}, // bottom is actually right as text is vertical
            },
            ticks: {
                callback: function (value, index, values) {
                    return value
                },
            },
        },
    },
    plugins: {
        ...options.plugins,
        tooltip: {
            ...options.plugins.tooltip,
            callbacks: {
                label: function (tooltipItem) {
                    return (
                        tooltipItem.raw.val +
                        ' | ' +
                        (tooltipItem.raw.per * 100).toFixed(2) +
                        '%'
                    )
                },
                afterTitle: function (tooltipItems) {
                    return tooltipItems[0]?.dataset.label
                },
            },
        },
    },
}

const Trends = ({
    mainColWidth,
    graphHeight,
    graphWidth,
    fold,
    title,
    foldState,
    type = false,
}) => {
    let d = new Date()
    d.setMonth(d.getMonth() - 3)
    let td = new Date()
    td.setDate(td.getDate() - 1)
    const user = useUser()
    const [fromDate, setFromDate] = useState(d)
    const [toDate, setToDate] = useState(td)
    const [openChevron, setOpenChevron] = useState(foldState)
    const [chartOptions, setChartOptions] = useState(options)
    const [sentBuy, setSentBuy] = useState(true)
    const [sentSell, setSentSell] = useState(true)
    const [sentTeam, setSentTeam] = useState(false)
    const [numTrendMonth, setNumTrendMonth] = useState(1)
    const [compareDate, setCompareDate] = useState(null)
    const [compareTitle, setCompareTitle] = useState('Compare to')
    const [universes, setUniverses] = useState([])
    const [universe, setUniverse] = useState('all')
    const data = {
        labels: [],
        datasets: [
            {
                label: null,
                data: [],
                borderWidth: 1,
            },
        ],
    }
    const [graphData, setGraphData] = useState(data)
    const [loading, setLaoding] = useState(true)
    const getEntry = (key, val, total) => {
        return {
            id: sectorShortName(key),
            val: val ? val : 0,
            per: val ? val / total : 0,
        }
    }
    const fixCasing = (data) => {
        const newData = {}

        Object.entries(data).forEach(([key, sectordata]) => {
            newData[key] = Object.fromEntries(
                Object.entries(sectordata).map(([rating, value]) => [
                    rating[0].toUpperCase() + rating.slice(1),
                    value,
                ]),
            )
        })
        return newData
    }

    const getGraph = () => {
        if (type === 'analystSector') {
            console.log('getAnalystSectorTrendGraph start')
            setChartOptions(options_num)
            api.getAnalystSectorTrendGraph(
                sentBuy,
                sentSell,
                sentTeam,
                numTrendMonth,
                compareDate ? moment(compareDate).format('YYYY-MM') : null,
                universe !== 'all' ? universe : null,
            ).subscribe((response) => {
                if (response?.data) {
                    const data = fixCasing(response.data)
                    console.log(
                        'getAnalystSectorTrendGraph',
                        response.data,
                        data,
                    )
                    let valsBuy = []
                    let valsSell = []
                    let valsBuy2 = []
                    let valsSell2 = []
                    let sum1 = 0
                    let sum2 = 0
                    for (let [key, item] of Object.entries(data)) {
                        sum1 += item.Buy ? item.Buy : 0
                        sum1 += item.Sell ? item.Sell : 0
                        sum2 += item.Buy2 ? item.Buy2 : 0
                        sum2 += item.Sell2 ? item.Sell2 : 0
                    }
                    for (let [key, item] of Object.entries(data)) {
                        //labels.push(sectorShortName(key))
                        valsBuy.push(getEntry(key, item.Buy, sum1))
                        valsSell.push(getEntry(key, item.Sell, sum1))
                        valsBuy2.push(getEntry(key, item.Buy2, sum2))
                        valsSell2.push(getEntry(key, item.Sell2, sum2))
                    }
                    let sets = [
                        {
                            data: valsBuy,
                            label: 'Buy',
                            backgroundColor: [ChartGreensReds[0]],
                            stack: 'Stack1',
                        },
                        {
                            label: 'Sell',
                            data: valsSell,
                            backgroundColor: [ChartGreensReds[1]],
                            stack: 'Stack1',
                        },
                    ]
                    if (valsBuy2.filter((num) => num.val > 0).length)
                        sets.push({
                            label: 'Buy compare period',
                            data: valsBuy2,
                            backgroundColor: [ChartGreensReds[2]],
                            stack: 'Stack2',
                        })
                    if (valsSell2.filter((num) => num.val > 0).length)
                        sets.push({
                            label: 'Sell compare period',
                            data: valsSell2,
                            backgroundColor: [ChartGreensReds[3]],
                            stack: 'Stack2',
                        })

                    setGraphData({
                        //labels: labels,
                        datasets: sets,
                    })
                    setLaoding(false)
                }
            })
        } else {
            setChartOptions(options)
            api.getMarketTrendGraph(fromDate, toDate).subscribe((response) => {
                //console.log('getMarketTrendGraph', response)

                let labels = []
                let values = []
                let colors = []
                let bColors = []
                const data = response.data?.data || response.data

                if (data) {
                    data.forEach((item) => {
                        labels.push(item.ticker)
                        values.push(item.pl * 100)
                        colors.push(
                            item.pl > 0
                                ? ChartGreensReds[0]
                                : ChartGreensReds[1],
                        )
                        bColors.push(
                            item.pl > 0
                                ? ChartGreensReds[0]
                                : ChartGreensReds[1],
                        )
                    })

                    // not good, not elegant, doesn't cover all cases
                    // why is datasets an array of objects (maybe for stacked bar and so)
                    setGraphData((prev) => ({
                        ...prev,
                        labels: labels,
                        datasets: [
                            {
                                ...prev.datasets[0],
                                yAxisID: 'y',
                                data: values,
                                backgroundColor: colors,
                                borderColor: bColors,
                            },
                        ],
                    }))
                    setLaoding(false)
                } else {
                    // TODO...
                }
            })
        }
    }
    const togglChevron = () => {
        if (fold) setOpenChevron(!openChevron)
    }
    useEffect(() => {
        if (type === 'analystSector') {
            // api.getTipigoIndexes(0).subscribe((response) => {
            //     setUniverses(formatUniverseResponse(response, true))
            // })
            setUniverses(user.universeList || [])
        }
    }, [type])

    useEffect(() => {
        setLaoding(true)
        getGraph()
    }, [
        fromDate,
        toDate,
        sentBuy,
        sentSell,
        sentTeam,
        numTrendMonth,
        compareDate,
        universe,
    ])

    useEffect(() => {
        if (numTrendMonth == 1) setCompareTitle('Compare to')
        else
            setCompareTitle(
                'Compare to ' + numTrendMonth + ' months\nstarting at',
            )
    }, [numTrendMonth])

    return (
        <Container width={graphWidth}>
            <OverrideStyles />
            <Title
                text={title ? title : 'Market Performance '}
                onClick={togglChevron}
                fold={fold}
            />
            <Collapse isOpened={openChevron}>
                {type !== 'analystSector' && (
                    <Params>
                        <DateRange
                            fromDate={fromDate}
                            toDate={toDate}
                            setFromDate={setFromDate}
                            setToDate={setToDate}
                            dateFormat={'MM/dd/yyyy'}
                            wholeMonth={false}
                            mainColWidth={mainColWidth}
                        />
                    </Params>
                )}
                {type == 'analystSector' && (
                    <>
                        <Params>
                            <div>
                                <Label>
                                    <Checkbox
                                        checked={sentBuy}
                                        onChange={(e) => setSentBuy(!sentBuy)}
                                    />
                                    <SpanText>Long</SpanText>
                                </Label>
                                <Label>
                                    <Checkbox
                                        checked={sentSell}
                                        onChange={(e) => setSentSell(!sentSell)}
                                    />
                                    <SpanText>Short</SpanText>
                                </Label>
                                <Label>
                                    <Checkbox
                                        checked={sentTeam}
                                        onChange={(e) => setSentTeam(!sentTeam)}
                                    />
                                    <SpanText>Analysts Team Only</SpanText>
                                </Label>
                            </div>
                            <ParamsRight>
                                <FrameSelect
                                    value={numTrendMonth}
                                    setValue={setNumTrendMonth}
                                    values={[
                                        {value: 1, label: 'Last month'},
                                        {value: 3, label: 'Last 3 months'},
                                        {value: 6, label: 'Last 6 months'},
                                    ]}
                                    style={{marginLeft: 15}}
                                />
                                <Label
                                    style={{
                                        marginLeft: 15,
                                        whiteSpace: 'pre-wrap',
                                    }}>
                                    {compareTitle}
                                </Label>
                                <DateRange
                                    fromDate={compareDate}
                                    toDate={td}
                                    setFromDate={setCompareDate}
                                    oneDate
                                    wholeMonth
                                    dateFormat="MM/yyyy"
                                    oneDateTitle={null}
                                    style={{marginLeft: 10}}
                                    frameStyle={{height: 18, width: 110}}
                                />
                            </ParamsRight>
                        </Params>
                        <Params style={{marginTop: 12}}>
                            <FrameSelect
                                style={{flex: 1}}
                                value={universe}
                                values={[
                                    {
                                        value: 'all',
                                        label: 'All stocks. No filter',
                                    },
                                    ...universes,
                                ]}
                                setValue={setUniverse}
                                title={'Filter by Universe'}
                            />
                        </Params>
                    </>
                )}
                <ChartContainer>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Bar
                            data={graphData}
                            options={chartOptions}
                            type={'bar'}
                            height={graphHeight}
                        />
                    )}
                </ChartContainer>
            </Collapse>
        </Container>
    )
}

export default Trends

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 68px;
    width: ${(p) => p.width}%;
    font-family: 'Inter';
`
const Params = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    align-items: center;
    border: 0px solid green;
    justify-content: space-between;
`
const ParamsRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const ChartContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    margin-top: 21px;
`
const OverrideStyles = createGlobalStyle`
    .ReactCollapse--collapse {
        transition: height 600ms;
    }
`
const Label = styled.label`
    display: inline-block;
`
const SpanText = styled.span`
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    color: ${AppColors.BALCK};
`
