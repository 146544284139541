import React from 'react'
import styled from 'styled-components'
import {Label, Container} from './HomeDrawer.styles'
import {Collapse} from 'react-collapse'
import {useState, useEffect} from '@services/hooks'
import {ReactComponent as ChevOpen} from '@images/chevron_open.svg'
import {ReactComponent as ChevClose} from '@images/chevron_close.svg'
import {StyledCheckBox} from '@components/General/Elements'

const AlertRangeCheck = ({label, values, setValues}) => {
    const [open, setOpen] = useState(true)
    // options:
    // non dynamic list and non dynamic values: control only needs to know selected
    // non dynamic list with dynamic values, alweys the same labels (big, small, etc) but they represent different values accordung to data
    // both dynamic

    const setSelected = (type, val) => {
        console.log(values)

        var arr = values ? [...values] : []
        if (val) {
            arr.push(type)
        } else {
            var i = arr.indexOf(type)
            if (i !== -1) {
                arr.splice(i, 1)
            }
        }
        setValues(arr)
    }
    return (
        <Container>
            <Title onClick={() => setOpen(!open)} fold={true}>
                {label}{' '}
                {open ? (
                    <ChevClose width={18} style={{marginTop: 2}} />
                ) : (
                    <ChevOpen width={20} />
                )}
            </Title>

            <Collapse isOpened={open}>
                <StyledCheckBox
                    className="checkbox"
                    onCheck={(e) => setSelected('big', e.target.checked)}
                    checked={values && values.includes('big')}
                    //key={`${title}_check_${i}`}
                    label={'Big'} //{labelBy ? labelBy(key) : key}
                />
                <StyledCheckBox
                    className="checkbox"
                    onCheck={(e) => setSelected('small', e.target.checked)}
                    checked={values && values.includes('small')}
                    //key={`${title}_check_${i}`}
                    label={'small'} //{labelBy ? labelBy(key) : key}
                />
            </Collapse>
        </Container>
    )
}

export default AlertRangeCheck

const Title = styled(Label)`
    border: 1px solid green;
    display: flex;
    justify-content: space-between;
    flex: 1;
`
// const Container = styled.div`
//     display: flex;
//     border: 1px solid red;
// `
