import * as types from "../ActionTypes";

export function marketStatusUpdate(open) {
  return {
    type: types.MARKET_STATUS_UPDATE,
    open,
  };
}

export function portfolioStatusUpdate(data) {
  return {
    type: types.PORTFOLIO_STATUS_UPDATE,
    data,
  };
}

export function commitRemovedPosition(positionId) {
  return {
    type: types.COMMIT_REMOVED_POSITION,
    positionId,
  };
}

export function setRedirectedOnceFlag(value) {
  return {
    type: types.SET_REDIRECTED_ONCE_FLAG,
    value,
  };
}

export function updateUserCurrentProfile(profile) {
  return {
    type: types.UPDATE_USER_CURRENT_PROFILE,
    method: "updateUserProfile",
    profile,
  };
}

export function updateUserCurrentFilters(filters) {
  return {
    type: types.UPDATE_USER_CURRENT_FILTERS,
    method: "updateUserFilters",
    filters,
  };
} // updateUserCurrentFilters
