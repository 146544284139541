import React from 'react'
import {useState, useEffect} from '@services/hooks'
import Trends from '@components/Reports/Trends'
import DateRange from '@components/General/DateRange'
import styled from 'styled-components'
import FrameInput from '@components/General/FrameInput'

const ReportDatesSelector = ({
    mainColWidth,
    oneDate,
    adminPlParams,
    setAdminPlParams,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    dateFormat = 'MM/yyyy',
    wholeMonth = true,
}) => {
    useEffect(() => {
        let d = new Date()
        d.setMonth(d.getMonth() - 3)
        let td = new Date()
        if (wholeMonth) {
            // td.setMonth(td.getMonth() - 1)
        } else {
            td.setDate(td.getDate() - 1)
        }

        setFromDate(d)
        setToDate(td)
    }, [])

    // 04.10.23 subtract one month AGAIN! after direct answer from N and K
    // the report cannot realy work on today. but N have a hard time giving it up...
    // 22.02.24 again... "To date" for admin and "For" for client means "to end of last month"
    // later GenerateReport subtracts 1 month
    // this is the behivor they insisted on...

    return (
        <FlexRow width={mainColWidth}>
            <DateRange
                fromDate={oneDate ? toDate : fromDate}
                toDate={toDate}
                setFromDate={oneDate ? setToDate : setFromDate}
                setToDate={setToDate}
                dateFormat={dateFormat}
                wholeMonth={wholeMonth}
                mainColWidth={
                    oneDate
                        ? mainColWidth / 2
                        : mainColWidth - (setAdminPlParams ? 200 : 0)
                }
                oneDate={oneDate}
                oneDateTitle={oneDate ? 'For' : 'From Date'}
            />

            {setAdminPlParams && (
                <FrameInput
                    title={'Months on each report'}
                    setValue={(value) =>
                        setAdminPlParams({
                            ...adminPlParams,
                            adminIpMonths: value,
                        })
                    }
                    value={adminPlParams.adminIpMonths}
                />
            )}
        </FlexRow>
    )
}

export default ReportDatesSelector

const Row = styled.div`
    padding-top: 38px;
    width: ${(p) => p.width}px;
`
const FlexRow = styled(Row)`
    display: flex;
    justify-content: space-between;
    width: ${(p) => p.width}px;
    border: 0px solid purple;
    align-items: baseline;
`
