import React, {Component} from 'react'
import {api} from '@services/api'
import {useState, useEffect} from '@services/hooks'

import Header from './Header'
import styled from 'styled-components'

import SymbolTable from './AppPortfolios/SymbolTable'
import NotifyUsers from './AppPortfolios/NotifyUsers'

const styles = {
    d2: {display: 'flex', flexDirection: 'column', alignItems: 'flex-start'},
    d1: {padding: 70, textAlign: 'center'},
    d0: {display: 'flex', flexDirection: 'row', paddingLeft: 30},
    newSymbol: {width: 45, marginLeft: 7, marginRight: 7},
}

const AppPortfolios = () => {
    //closePl: '', cabcel this for new port. close monthely
    const thisMonth = () => {
        const d = new Date()
        return d.getFullYear() + '-' + (d.getMonth() + 1)
    }

    const [flavors, setFlavors] = useState([])
    const [flavor, setFlavor] = useState('')
    const [portfolios, setPortfolios] = useState([])
    const [newPortName, setNewPortName] = useState('')
    const [newPortDate, setNewPortDate] = useState(thisMonth())
    const [active, setActive] = useState(false)
    const [selected, setSelected] = useState('')
    const [newSymbol, setNewSymol] = useState('')
    const [entryPrice, setEntryPrice] = useState('')
    const [refreshList, setRefreshList] = useState(false)
    const [pageMode, setPageMode] = useState(true)

    useEffect(() => {
        getFlavors()
    }, [])

    const getFlavors = () => {
        api.getAppFlavors().subscribe(
            (response) => {
                setFlavors(response.data)
                setFlavor(response.data[0])
            },
            (error) => {
                console.log('getAppFlavors error', error)
            },
        )
    }
    const getAppPortfolios = () => {
        api.getPortfolios(flavor).subscribe(
            (response) => {
                setPortfolios(response.data)
                // this.setState( {portfolios: response.data},this.afterGotPortfolios,)
            },
            (error) => {
                console.log('getPortfolios error', error)
            },
        )
    }

    useEffect(() => {
        if (flavor) {
            setSelected('')
            setPortfolios([])
            getAppPortfolios && getAppPortfolios()
        }
    }, [flavor])

    useEffect(() => {
        if (portfolios?.length) {
            setSelected(portfolios[0].id)
            setActive(portfolios[0].active)
        }
    }, [portfolios])

    useEffect(() => {
        if (portfolios?.length) {
            const p = portfolios.filter((item) => {
                return item.id === selected
            })[0]
            if (p) setActive(p.active)
        }
    }, [selected, portfolios])

    // const selectPortfolio = (event) => {
    //     this.setState({selected: event.target.value})
    //     // const p = this.state.portfolios.filter((item) => {
    //     //     return item.id === event.target.value
    //     // })[0]
    //     // this.setState({active: p.active})
    // }

    // afterGotPortfolios = () => {
    //     this.setState({
    //         selected: this.state.portfolios[0].id,
    //         active: this.state.portfolios[0].active,
    //     })
    // }
    // updateNewPortName = (event) => {
    //     this.setState({newPortName: event.target.value})
    // }
    // updateNewPortDate = (event) => {
    //     this.setState({newPortDate: event.target.value})
    // }
    const updateActive = (event) => {
        setActive(event.target.checked)
        api.portfolioActive(selected, event.target.checked).subscribe(
            (response) => {},
            (error) => {
                console.log('set active error', error)
            },
        )
    }
    // updateNewSymbol = (event) => {
    //     this.setState({newSymbol: event.target.value})
    // }
    // updateClosePl = (event) => {
    //     this.setState({closePl: event.target.value})
    // }
    // updateEntryPrice = (event) => {
    //     this.setState({entryPrice: event.target.value})
    // }

    // setClosePl = () => {
    //     this.api
    //         .setEndMonthPl(this.state.selected, this.state.closePl)
    //         .subscribe(
    //             (response) => {
    //                 if (response.data.status === true) {
    //                     alert('end month pl updated')
    //                 } else {
    //                     alert(response.data.status)
    //                 }
    //             },
    //             (error) => {
    //                 console.log('set active error', error)
    //             },
    //         )
    // }

    const addSymbol = () => {
        api.addPortfolioSymbol(selected, newSymbol, entryPrice).subscribe(
            (response) => {
                if (response.data.status === true) {
                    setRefreshList(!refreshList)
                } else {
                    alert(response.data.status)
                }
            },
            (error) => {
                console.log('set active error', error)
            },
        )
    }
    const createPortfolio = () => {
        if (validDate()) {
            if (newPortName && newPortName !== '') {
                api.addAppPortfolio(
                    flavor,
                    newPortName,
                    newPortDate + '-01',
                ).subscribe(
                    (response) => {
                        setNewPortName('')
                        getAppPortfolios()
                        alert('portfolio added, select it to start editing')
                    },
                    (error) => {
                        console.log('createPortfolio error', error)
                    },
                )
            } else {
                alert('please enter a name for the new portfolio')
            }
        } else {
            alert('please use month in format YYYY-mm')
        }
    }

    const validDate = () => {
        if (newPortDate && newPortDate.length >= 6 && newPortDate.length <= 7) {
            let dd = newPortDate.split('-')
            if (dd.length === 2) {
                if (!isNaN(dd[0] && isNaN(dd[1]))) {
                    if (
                        dd[0] <= new Date().getFullYear() &&
                        dd[1] <= 12 &&
                        dd[1] >= 1
                    ) {
                        return true
                    }
                }
            }
        }
        return false
    }

    return (
        <div>
            <Header />

            <div style={styles.d1}>
                <Selectors>
                    <Selector
                        onClick={() => setPageMode(true)}
                        selected={pageMode}>
                        Edit Portfolios
                    </Selector>
                    <Selector
                        onClick={() => setPageMode(false)}
                        selected={!pageMode}>
                        Notify Users
                    </Selector>
                </Selectors>

                <div style={styles.d0}>
                    {pageMode && (
                        <div>
                            <h2>Edit App Portfolios</h2>
                            <div style={styles.d2}>
                                {flavors && flavor && (
                                    <label>
                                        select app:&nbsp;
                                        <select
                                            onChange={(e) =>
                                                setFlavor(e.target.value)
                                            }
                                            value={flavor}>
                                            {flavors.map((m, i) => {
                                                return (
                                                    <option key={i} value={m}>
                                                        {m}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </label>
                                )}

                                {portfolios && (
                                    <div
                                        style={{
                                            paddingTop: 10,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        }}>
                                        <div>
                                            <label style={{}}>
                                                select portfolio:&nbsp;
                                                <select
                                                    onChange={(e) =>
                                                        setSelected(
                                                            e.target.value,
                                                        )
                                                    }>
                                                    {portfolios.map((p, i) => {
                                                        return (
                                                            <option
                                                                key={i}
                                                                value={p.id}>
                                                                {p.title}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                                <span style={{paddingLeft: 10}}>
                                                    &nbsp;&nbsp; <b>Or</b>{' '}
                                                    create new: &nbsp;&nbsp;
                                                    <input
                                                        value={newPortName}
                                                        onChange={(e) =>
                                                            setNewPortName(
                                                                e.target.value,
                                                            )
                                                        }
                                                        style={{
                                                            width: 100,
                                                            paddingLeft: 8,
                                                        }}
                                                    />
                                                    &nbsp;Month:
                                                    <input
                                                        value={newPortDate}
                                                        onChange={(e) =>
                                                            setNewPortDate(
                                                                e.target.value,
                                                            )
                                                        }
                                                        placeholder={thisMonth()}
                                                        style={{width: 50}}
                                                    />
                                                    &nbsp;&nbsp;
                                                    <button
                                                        onClick={
                                                            createPortfolio
                                                        }>
                                                        create
                                                    </button>
                                                </span>
                                            </label>
                                        </div>
                                        {/* <div style={{paddingTop: 10}}>
                                            set end-month PL
                                            <input
                                                onChange={this.updateClosePl}
                                                value={this.state.closePl}
                                                style={styles.newSymbol}
                                            />
                                            <button onClick={this.setClosePl}>
                                                set
                                            </button>
                                        </div> */}
                                        <div style={{paddingTop: 10}}>
                                            <input
                                                type="checkbox"
                                                checked={active}
                                                onChange={updateActive}
                                            />{' '}
                                            Portfolio is visiable in app
                                        </div>
                                        <div style={{paddingTop: 10}}>
                                            <label>
                                                add symbol
                                                <input
                                                    onChange={
                                                        (e) =>
                                                            setNewSymol(
                                                                e.target.value,
                                                            )
                                                        //this.updateNewSymbol
                                                    }
                                                    value={newSymbol}
                                                    style={styles.newSymbol}
                                                />
                                                &nbsp;&nbsp; entry price (may be
                                                empty)
                                                <input
                                                    onChange={
                                                        (e) =>
                                                            setEntryPrice(
                                                                e.target.value,
                                                            )
                                                        //this.updateEntryPrice
                                                    }
                                                    value={entryPrice}
                                                    style={styles.newSymbol}
                                                />
                                                <button onClick={addSymbol}>
                                                    add
                                                </button>
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div>
                                {selected && (
                                    <SymbolTable
                                        pid={selected}
                                        flag={refreshList}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    {!pageMode && <NotifyUsers app={flavor} />}
                </div>
            </div>
        </div>
    )
}

export default AppPortfolios

const Selector = styled.div`
    font-size: 16px;
    padding: 10px 30px;
    background-color: ${(p) => (p.selected ? '#00948D' : '#F6F7F8')};
    margin-right: 20px;
`
const Selectors = styled.div`
    display: flex;
    margin-top: 30px;
`
