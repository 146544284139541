import React from 'react'
import styled from 'styled-components'

import ActionStars from 'material-ui/svg-icons/action/grade'
import {CardText} from 'material-ui/Card'

import ExpandsLess from 'material-ui/svg-icons/navigation/expand-less'
import ExpandsMore from 'material-ui/svg-icons/navigation/expand-more'

import {AppColors} from '../../../services/styles'
import Tooltip from '../../General/Tooltip'
import {
    red400,
    yellow700,
    yellow600,
    yellow500,
} from 'material-ui/styles/colors'

export const WatchlistIcon = (props) => (
    <Tooltip
        text={props.isExist ? 'Remove from watchlist' : 'Add to watchlist'}>
        <Icon isExist={props.isExist} isBullish={props.isBullish} {...props} />
    </Tooltip>
)

const green = AppColors.TIPIGO_GREEN,
    red = red400
const lightGreen = '#c7e9c7',
    lightRed = '#f8bfbf',
    lightYellow = '#fde5b2'

const UNStyledIcon = ({isExist, isBullish, ...props}) => (
    <ActionStars {...props} />
)
const Icon = styled(UNStyledIcon)`
    margin: 0px 6px -3px 6px;
    width: 26px;
    height: 26px;
    fill: ${(p) => (p.isExist ? yellow600 : '#fff')} !important;
    stroke: ${(p) => (p.isExist ? yellow600 : AppColors.APP_GRAY)} !important;
    cursor: pointer;
    :hover {
        fill: ${(p) => (p.isExist ? '#fff' : yellow600)} !important;
        stroke: #666 !important;
    }
`

const UNSTyledExpandIcon = ({open, ...p}) =>
    open ? <ExpandsLess {...p} /> : <ExpandsMore {...p} />

export const ExpandIcon = styled(UNSTyledExpandIcon)`
    fill: ${AppColors.APP_GRAY} !important;
    width: ${(p) => p.size || 24}px !important;
    height: ${(p) => p.size || 24}px !important;
`
//padding: 0px 5px;
export const LIVE_FEED_ICON_SIZE = 70

export const AnalystImage = ({analystProfileImage, color}) => {
    return (
        <div>
            <div
                style={{
                    height: LIVE_FEED_ICON_SIZE,
                    width: LIVE_FEED_ICON_SIZE,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    border: '1px solid #eee',
                    borderRadius: 3,
                    backgroundColor: analystProfileImage ? '#fff' : color,
                    float: 'left',
                    marginRight: 10,
                }}>
                {analystProfileImage ? (
                    <img
                        src={analystProfileImage}
                        style={{
                            width: '80%',
                            borderRadius: 3,
                        }}
                    />
                ) : (
                    <img
                        src={
                            require('@images/logo-white-cutout-small.png')
                                .default
                        }
                        style={{
                            width: 32,
                            borderRadius: 3,
                        }}
                    />
                )}
            </div>
        </div>
    )
}
const UnExpanded = styled.div`
    padding: 16px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
`
export const Expand = styled((props) => (
    <div {...props}>
        <UnExpanded>{props.children}</UnExpanded>
    </div>
))`
    box-sizing: border-box;
    -moz-transition: max-height 0.5s ease-out;
    -ms-transition: max-height 0.5s ease-out;
    -o-transition: max-height 0.5s ease-out;
    -webkit-transition: max-height 0.5s ease-out;
    transition: max-height 0.5s ease-out;
    max-height: ${(p) => (p.open ? '99px' : '0px')};
    overflow: hidden;
`

export const ExpandTitle = styled.div`
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 9px;
`
