import React from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {useState} from '@services/hooks'
import Dialog from '@mui/material/Dialog'
import {api} from '@services/api'
import {Close} from '@material-ui/icons'
import Swal from 'sweetalert2'
import FrameInput from '../General/FrameInput'
import FrameText from '../General/FrameText'
import {AppColors} from '@services/styles'

import {
    SendButton,
    Title,
    DialogContainer,
    DialogTopRow,
    DialogRow,
} from '../General/Elements'

const AddUniverse = ({show, setShow, setDone}) => {
    const [newUniverse, setNewUniverse] = useState('')
    const [newSymbols, setNewSymbols] = useState('')

    const createUniverse = () => {
        setNewUniverse(newUniverse.trim()) // not immidiate..
        if (newUniverse.trim() !== '') {
            api.addTipigoIndex(
                newUniverse,
                false,
                newSymbols,
                [],
                [],
            ).subscribe(
                (response) => {
                    console.log('create_index response', response)
                    if (response && response.data && response.status === 200) {
                        if (response.data.message) {
                            // fail of old system (not sure how to consume error correctly)
                            Swal.fire({
                                text:
                                    'error createing universe.\n' +
                                    response.data.message,
                                icon: 'error',
                                confirmButtonColor: AppColors.TIPIGO_GREEN,
                            })
                        } else {
                            setDone(true)
                            setNewSymbols('')
                            Swal.fire({
                                text: 'Universe created',
                                focusConfirm: false,
                                confirmButtonColor: AppColors.TIPIGO_GREEN,
                                icon: 'success',
                            }).then(setShow(false))
                        }
                    }
                    setNewUniverse('')
                },
                (error) => {
                    // fail of new system - http error
                    console.log('error', error)
                    Swal.fire({
                        text:
                            'error createing universe.\n' + error.data?.message,
                        icon: 'error',
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                    })
                    setNewUniverse('')
                },
            )
        } else {
            Swal.fire({
                text: 'please set universe name',
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
        }
    }

    return (
        <Dialog
            modal={true}
            open={show}
            onClose={() => setShow(false)}
            maxWidth="sm"
            fullWidth>
            <OverrideStyles />
            <DialogContainer>
                <DialogTopRow>
                    <Title text={'Create Universe'} />
                    <Close onClick={() => setShow(false)} />
                </DialogTopRow>

                <DialogRow>
                    <FrameInput
                        title={'Universe Name'}
                        value={newUniverse}
                        setValue={setNewUniverse}
                        style={{flex: 1}}
                        maxLength={50}
                    />
                </DialogRow>
                <DialogRow>
                    <FrameText
                        value={newSymbols}
                        setValue={setNewSymbols}
                        title={'Symbols (separate by comma, space or new line)'}
                        frameStyle={{flex: 1, height: 180}}
                        style={{flex: 1, height: 180}}
                    />
                </DialogRow>
                <DialogRow style={{justifyContent: 'flex-end'}}>
                    <SendButton onClick={createUniverse}>Create</SendButton>
                </DialogRow>
            </DialogContainer>
        </Dialog>
    )
}

export default AddUniverse

const OverrideStyles = createGlobalStyle`
    .swal2-container {
        z-index: 2000;
    }`
