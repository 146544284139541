import React, {Component, PropTypes} from 'react'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import TextField from 'material-ui/TextField'
import _ from 'lodash'
import {useState, useEffect, useUser} from '@services/hooks'
import styled from 'styled-components'

const styles = {
    textField: {
        marginLeft: 20,
        width: 100,
    },
}

const RangeSelector1 = ({
    value1,
    value2,
    currentSector,
    currentScreener,
    numberInputStep,
    onChange,
}) => {
    const [operator, setOperator] = useState('between') //ATT state: value
    const [text1Value, setText1Value] = useState(value1 || 0)
    const [text2Value, setText2Value] = useState(value2 || 0)
    // currentSector make a local copy or use prop? the old componnent made a copy

    // constructor(props) {
    //     this.state = {
    //         value: operator,
    //         text1Value: this.props.value1 || 0,
    //         text2Value: this.props.value2 || 0,
    //         currentSector: this.props.currentSector,
    //     }
    // }

    useEffect(() => {
        updateState()

        // if (_.isNumber(value1) && !_.isNumber(value2)) {
        //     setOperator('greater_than')
        // } else if (!_.isNumber(value1) && _.isNumber(value2)) {
        //     setOperator('less_than')
        // }
    }, [])

    // they are all "outsiders", do we need useEffect??
    // componentWillReceiveProps(nextProps) {
    //     console.log('RangeSelector componentWillReceiveProps')
    //     if (
    //         this.props.currentSector !== nextProps.currentSector ||
    //         this.props.currentScreener !== nextProps.currentScreener
    //     ) {
    //         // Sector has been changed, let's reset
    //         console.log(
    //             'sector_change',
    //             this.props.currentSector,
    //             nextProps.currentSector,
    //         )
    //         this.updateState(nextProps)
    //     }
    // }
    useEffect(() => {
        console.log('sector_screener')
        updateState()
    }, [currentSector, currentScreener]) //, value1, value2

    // why do we need anything here..? (all props)
    const updateState = () => {
        //console.log(nextProps.value1)
        let derivedOperator = 'between'
        if (value1 && !value2) {
            derivedOperator = 'greater_than'
        } else if (value2 && !value1) {
            derivedOperator = 'less_than'
        }
        setOperator(derivedOperator)
        setText1Value(value1 || 0)
        setText2Value(value2 || 0)
        // let newState = {
        //     value: derivedOperator,
        //     text1Value: nextProps.value1 || 0,
        //     text2Value: nextProps.value2 || 0,
        // }
        // this.setState(newState)
    }

    // update = () => {
    //     let value = this.state.value
    //     let text1 = this.state.text1Value
    //     let text2 = this.state.text2Value
    //     if (value === 'greater_than') {
    //         text2 = null
    //     } else if (value === 'less_than') {
    //         text1 = null
    //     }
    //     this.props.onChange(value, text1, text2)
    // }

    useEffect(() => {
        // update parent, instead of update() above
        let text1 = text1Value
        let text2 = text2Value
        if (operator === 'greater_than') {
            text2 = null
        } else if (operator === 'less_than') {
            text1 = null
        }
        onChange(operator, text1, text2)
    }, [text1Value, text2Value, operator])

    return (
        <div style={{display: 'flex'}}>
            <SelectField
                style={{display: 'block', width: 150}}
                value={operator}
                onChange={(e, i, val) => {
                    setOperator(val)
                }}>
                <MenuItem value={'greater_than'} primaryText="greater than" />
                <MenuItem value={'less_than'} primaryText="less than" />
                <MenuItem value={'between'} primaryText="between" />
            </SelectField>

            {['between', 'greater_than'].indexOf(operator) > -1 ? (
                <TextField
                    style={styles.textField}
                    id="text-field-text1Value"
                    value={text1Value}
                    type="number"
                    step={
                        1
                        // seem this was not active, so was 0.1, can set it here (above) by screener type
                        // this.props.numberInputStep
                        //     ? this.props.numberInputStep.toString()
                        //     : '0.1'
                    }
                    onChange={(event) => {
                        setText1Value(event.target.value)
                        // this.setState(
                        //     {
                        //         text1Value: event.target.value,
                        //     },
                        //     this.update,
                        // )
                    }}
                />
            ) : null}

            {['between', 'less_than'].indexOf(operator) > -1 ? (
                <TextField
                    style={styles.textField}
                    id="text-field-text2Value"
                    value={text2Value}
                    onChange={(event) => {
                        setText2Value(event.target.value)
                        // this.setState(
                        //     {
                        //         text2Value: event.target.value,
                        //     },
                        //     this.update,
                        // )
                    }}
                />
            ) : null}
        </div>
    )
}

export default RangeSelector1
