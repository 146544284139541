import {useSelector} from 'react-redux'

export const useRedirectedOnce = () =>
    useSelector(
        (state) => state.manage.redirectedOnce,
        () => {},
    )

export const useUser = () =>
    useSelector(
        (state) => state.user.user,
        () => {},
    )
