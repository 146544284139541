import styled from 'styled-components'
import React from 'react'
import Select from 'react-select'
import SelectInput from 'react-select/creatable'
import makeAnimated from 'react-select/animated'
import {ArrowDropDown} from '@mui/icons-material'
import {useState, useEffect} from '@services/hooks'
import {AppColors} from '@services/styles'

const animatedComponents = makeAnimated()
const style1 = {
    control: (base) => ({
        ...base,
        border: 0,
        // This line disable the blue border
        boxShadow: 'none',
        backgroundColor: 'white',
        // height: '15px',
        // minHeight: '15px',
    }),
}

const FrameSelect = ({
    value,
    values,
    setValue,
    setLabel,
    title,
    style,
    params,
    canInput,
    disabled,
    frameStyle,
    searchable = false,
}) => {
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (values) {
            let startOptions = []
            values.forEach((val) =>
                startOptions.push({
                    value: val.hasOwnProperty('value') ? val.value : val, //use hasOwnProperty() to allow 0
                    label: val.label ? val.label : val,
                    disabled: val.disabled,
                }),
            )
            setOptions(startOptions)
        }
    }, [values])

    const onChange = (selected) => {
        if (selected && selected.__isNew__) {
            // this can happen only if canInput == true
            setOptions((prev) => [...prev, selected])
        }
        setValue(selected.value, params) // might need to move into useEffect on options
        setLabel?.(selected.label)
        //console.log('on change', selected)
    }

    const SelectControl = canInput ? InputSelect : RSelect

    return (
        <Container style={style}>
            {title && <GroupLabel disabled={disabled}>{title}</GroupLabel>}
            <FormGroup disabled={disabled} style={frameStyle}>
                <SelectControl
                    components={animatedComponents}
                    styles={style1}
                    options={options}
                    isDisabled={disabled}
                    closeMenuOnSelect={true}
                    isSearchable={searchable}
                    // onInputChange={(newValue) => {console.log('input', newValue) }}
                    onChange={onChange}
                    value={options.filter((option) => option.value === value)}
                    isOptionDisabled={(option) => option.disabled}
                />

                <FloatingIconContainer>
                    <ArrowDropDown
                        sx={{
                            fontSize: 30,
                            color: disabled ? '#dfdfdf' : 'black',
                        }}
                    />
                </FloatingIconContainer>
            </FormGroup>
        </Container>
    )
}

export default FrameSelect

const Container = styled.div`
    display: flex;
    flex-direction: column;
`
const FormGroup = styled.div`
    padding: 8px;
    border: 1px solid
        ${(p) => (p.disabled ? '#eeeeee' : AppColors.APP_GRAY_LIGHT)};
    border-radius: 8px;
    position: relative;
    height: 32px;
    background-color: white;
`
const GroupLabel = styled.label`
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 3px;
    margin-left: 4px;
    color: ${(p) => (p.disabled ? '#bbbbbb' : '#000000')};
`
// const GroupLabel = styled.label`
//     position: absolute;
//     top: -10px;
//     font-size: 12px;
//     font-style: normal;
//     font-weight: normal;
//     background-color: white;
//     padding-left: 5px;
//     padding-right: 5px;
// `
const RSelect = styled(Select)`
    border: none;
    min-width: 120px;
    margin-top: -3px;
`
const InputSelect = styled(SelectInput)`
    border: none;
    min-width: 120px;
    margin-top: -3px;
`
const FloatingIconContainer = styled.div`
    position: absolute;
    right: 1px;
    top: 5%;
    width: 51px;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    pointer-events: none; // allows click to "go through"
`
//const HSelect = styled.select`
//     border: none;
//     width: 100%;
//     &:focus {
//         outline: none;
//     }
// `
