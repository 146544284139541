import React, {Component, PropTypes} from 'react'
import {Link} from 'react-router-dom'

import IconButton from 'material-ui/IconButton'
import CircularProgress from 'material-ui/CircularProgress'
import TextField from 'material-ui/TextField'
import FloatingActionButton from 'material-ui/FloatingActionButton'
import ContentAdd from 'material-ui/svg-icons/content/add'

import {
    red400,
    green400,
    grey400,
    grey200,
    grey50,
} from 'material-ui/styles/colors'

import ReactTable from 'react-table'
import Pagination from '../General/Pagination'
import InsightTable from '@components/General/InsightTable'

class BaseManageTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            searchText: '',
        }

        this.applySearchFilter = this.applySearchFilter.bind(this)
    }

    applySearchFilter() {
        let {data, searchFilter} = this.props
        let {searchText} = this.state

        if (!searchText || searchText === '') {
            //TODO: next ? is new 11.21. why it gets here empty and is it ok?
            return data ? data : []
        }
        let d = this.props.searchFilter(searchText)
        console.log('table data2', d)
        return d ? d : []
    }

    render() {
        let {data, mode, columns} = this.props
        let {searchText} = this.state

        console.log('data columns', columns)
        return (
            <div>
                <div style={Styles.searchContainer}>
                    <TextField
                        hintText="Search..."
                        style={{flex: 3}}
                        defaultValue={this.state.searchText}
                        onChange={(event, val) =>
                            this.setState({searchText: val})
                        }
                        fullWidth
                    />
                    {mode !== 'profile' && (
                        <Link to={'/manage/' + mode + '/new'}>
                            <FloatingActionButton style={Styles.addButtonStyle}>
                                <ContentAdd />
                            </FloatingActionButton>
                        </Link>
                    )}
                </div>
                <InsightTable
                    columns={columns}
                    data={this.applySearchFilter()}
                />
            </div>
        )
    }
}

const Styles = {
    searchContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    addButtonStyle: {
        margin: 20,
    },
}

export default BaseManageTable
