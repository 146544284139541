import React from 'react'
import {useState, useEffect} from '@services/hooks'
import {AppColors} from '@services/styles'
import styled from 'styled-components'
import {api} from '@services/api'
import moment from 'moment'

const StockNews = (symbol) => {
    const [newsList, setNewsList] = useState([])
    const getRandomImg = () => {
        const i = ((Math.random() * 8) | 0) + 1
        return require('@images/pic' + i + '.jpg').default
    }

    const PrepList = (response) => {
        var wImage = response.data.map((item) => {
            return {...item, img: getRandomImg()}
        })

        setNewsList(wImage)
    }

    useEffect(() => {
        if (symbol && symbol.symbol) {
            //console.log('thesymbol', symbol.symbol)
            api.getTickerNews([symbol.symbol], 20, 0).subscribe(
                (response) => {
                    console.log('getTickerNews', response)
                    PrepList(response)
                },
                (error) => {
                    console.log('getTickerNews error', error)
                },
            )
        }
    }, [symbol])
    const openNewsItem = (url) => {
        window.open(url, '_blank')
    }
    return (
        <Container>
            <Title>News</Title>
            {newsList.length
                ? newsList.map((item, i) => {
                      return (
                          <Box onClick={() => openNewsItem(item.url)} key={i}>
                              <ItemTitle>{item.title}</ItemTitle>
                              <ItemDate>
                                  {moment(item.updated).format('MM/DD/YY')}
                              </ItemDate>
                          </Box>
                      )
                  })
                : null}
        </Container>
    )
}

export default StockNews

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 15px;
`
const Title = styled.div`
    font-size: 19px;
    color: ${AppColors.BLACK};
`
//
const Box = styled.div`
    border: 1px solid ${AppColors.APP_GRAY_LIGHT};
    border-radius: 7px;

    margin-top: 16px;
    //box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    padding: 12px;
    cursor: pointer;
`
//margin-left: 20px; padding-top:15px;
const ItemTitle = styled.span`
    font-size: 15px;
    color: ${AppColors.BLACK};
    border: 0px solid;
`
const ItemDate = styled.span`
    font-size: 13px;
    padding-left: 10px;
    color: ${AppColors.APP_GRAY};
`
