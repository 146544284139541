import React from 'react'
import {api} from '@services/api'
import {useState, useEffect} from '@services/hooks'
import SelectSectors from './SelectSectors.jsx'
import DateRange from '@components/General/DateRange'
import FrameInput from '@components/General/FrameInput'
// import FrameText from '@components/General/FrameText'
import {SendButton, Title} from '@components/General/Elements'
import FrameSelect from '@components/General/FrameSelect'
import RangeFilter from './Controls/RangeFilter'
import styled, {createGlobalStyle} from 'styled-components'
import swal from 'sweetalert2'
import moment from 'moment'
import {Collapse} from 'react-collapse'
import ReactTooltip from 'react-tooltip'
import {AppColors} from '@services/styles'
import Analytics from '@services/analytics'
// import FileLineChart from '@components/General/FileLineChart'
import ScoredReport from './ReportContainer/ScoredReport'
import AdminPlReport from './ReportContainer/AdminPlReport'
import BackTestReport from './ReportContainer/BackTestReport'
import AdminSignalReport from './ReportContainer/AdminSignalReport'
// import {formatUniverseResponse} from '@services/utils'

const ReportContainer = ({user, repType, mainColWidth, wideColWidth}) => {
    let d = new Date()
    d.setMonth(d.getMonth() - 3)
    let td = new Date()
    if (repType === 'backtest') td.setDate(td.getDate() - 1)
    else td.setMonth(td.getMonth() - 1)
    // 04.10.23 subtract one month AGAIN! after dierct answer from N and K
    // the report cannot realy work on today. but N have a hard time giving it up...

    const [mailTo, setMailTo] = useState(
        window.location.hostname === 'localhost'
            ? 'ronkaplan69@gmail.com'
            : user && user.email
            ? user.email
            : '',
    )
    const [fromDate, setFromDate] = useState(d)
    const [toDate, setToDate] = useState(td)
    const [customIndexes, setCustomIndexes] = useState(null)
    const [symbols, setSymbols] = useState('')
    const [universeName, setUniverseName] = useState(null)
    const [marketCapCond, setMarketCapCond] = useState('0')
    const [marketCapValue1, setMarketCapValue1] = useState(0)
    const [marketCapValue2, setMarketCapValue2] = useState(0)
    const [betaCond, setBetaCond] = useState('0')
    const [betaValue1, setBetaValue1] = useState(0)
    const [betaValue2, setBetaValue2] = useState(0)
    const [scoreParam, setScoreParam] = useState({})

    const [sector, setSector] = useState([])
    const [tickers, setTickers] = useState('')
    const [benchmarks, setBenchmarks] = useState(['SPY'])
    const [title, setTitle] = useState('Performance')
    const [dateFormat, setDateFormat] = useState('MM/yyyy')
    const [wholeMonth, setWholeMonth] = useState(false)
    const [selectUniverse, setSelectUniverse] = useState(false)
    const [selectSectors, setSelectSectors] = useState(false)

    const [topN, setTopN] = useState('')
    const [adminTakeTop, setAdminTakeTop] = useState(15)
    // const [refreshBtChart, setRefreshBtChart] = useState(false)
    const [adminIpMonths, setAdminIpMonths] = useState(3)
    const [adminBtMonths, setAdminBtMonths] = useState(3)
    const [teamThreshold, setTeamThreshold] = useState(4)
    const [minRatio, setMinRatio] = useState(0.7)
    const [minMonthAvg, setMinMonthAvg] = useState(0.6)
    const [scoreVer, setScoreVer] = useState({})

    useEffect(() => {
        setCustomIndexes(user?.universeList || [])
        // api.getTipigoIndexes(0).subscribe((response) => {
        //     setCustomIndexes(formatUniverseResponse(response, true))
        // })
    }, [user])

    useEffect(() => {
        if (customIndexes && customIndexes.length > 0) {
            const sp = customIndexes.find((item) =>
                item.label.includes('S&P500'),
            )
            if (sp) {
                setSymbols(sp.value)
                setUniverseName(sp.label)
            }
        }
    }, [customIndexes])

    useEffect(() => {
        setTitle(
            repType === 'default'
                ? 'Performance'
                : repType === 'scored' || repType === 'admin_pl'
                ? 'Offline Opportunity report'
                : repType === 'backtest'
                ? 'Backtest'
                : repType === 'admin_signal'
                ? 'Signal Report'
                : repType === 'uni_history'
                ? 'Universe Symbols History'
                : 'what?',
        )
        setDateFormat(
            repType === 'default' ||
                repType === 'scored' ||
                repType === 'admin_pl' ||
                repType === 'admin_signal' ||
                repType === 'uni_history'
                ? 'MM/yyyy'
                : 'MM/dd/yyyy',
        )
        setWholeMonth(
            repType === 'default' ||
                repType === 'scored' ||
                repType === 'admin_pl' ||
                repType === 'admin_signal' ||
                repType === 'uni_history'
                ? true
                : false,
        )
        setSelectUniverse(
            repType === 'default' ||
                repType === 'scored' ||
                repType === 'admin_pl' ||
                repType === 'admin_signal' ||
                repType === 'uni_history'
                ? true
                : false,
        )
        setSelectSectors(
            repType === 'default' ||
                repType === 'scored' ||
                repType === 'admin_pl'
                ? true
                : false,
        )
        setOpenMainChevron(true)
    }, [repType])

    useEffect(() => {
        if (title) {
            Analytics.exec('track', 'select report ' + title)
        }
    }, [title])

    /* moved to GenerateReport
    const generateOpportunitiesReport = () => {
        const queryParams = {isAdmin: true}
        const selectedSectors = sector && sector.length > 0 ? sector : ['all']
        const fromDate2 =
            repType === 'scored' && !user.is_admin
                ? new Date(toDate).setMonth(toDate.getMonth() - 3)
                : fromDate

        queryParams.tickerSelectionParams = {
            universe: universeName,
            marketCapCond,
            marketCapVal1: marketCapValue1,
            marketCapVal2: marketCapValue2,
            betaCond,
            betaVal1: betaValue1,
            betaVal2: betaValue2,
            sectorNames: selectedSectors,
            // daysToEarning: false,
        }

        queryParams.analystTeamSelectionParams = {
            teamThreshold,
            minRatio,
            minMonthAvg,
        }
        const {con6, conTeam, testCalc, uniPostTeam} = scoreVer
        queryParams.reportParams = {
            startMonth: moment(fromDate2).format('YYYY-MM'),
            endMonth: moment(toDate).format('YYYY-MM'),
            reportType: 'scored',
            scoreParams: convertScoreParams(scoreParam),
            scoreVersions: {
                newCalcCon: !!con6, // con6
                onlyAnalystTeam: !!conTeam, // conTeam
                newTestCalc: !!testCalc,
                uniPostTeam: !!uniPostTeam,
            },
            direct: false,
            excel: true,
        }

        queryParams.debugOptions = {
            debugPrint: true,
            minimal: true,
            timings: true,
        }
        console.log('query', queryParams, scoreVer)
        const query = qs.stringify(queryParams)
        const LOCAL_DEV = true
        const baseNewApiUrl = LOCAL_DEV
            ? 'http://localhost:3700/api/v1'
            : 'https://api.tipigo.com/api/v1'

        return `${baseNewApiUrl}/reports/getPerformanceOpportunityReport?${query}`
    }*/

    const getReportMail = (direct) => {
        /* 12.05.24
        if (repType === 'backtest') {
            console.log('get_backtest_report')
            api.getBacktestReport(
                fromDate,
                toDate,
                tickers,
                benchmarks,
                mailTo,
            ).subscribe(
                (response) => {
                    swal.fire({
                        text: 'file will be sent by email',
                        focusConfirm: false,
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                        icon: 'success',
                    }).then()
                },
                (error) => {
                    console.log('error TODO', error)
                },
            )
        } else*/
        if (
            // 12.05.24 repType === 'scored' ||
            // 12.05.24 repType === 'default' ||
            // 12.05.24 repType === 'admin_pl' ||
            repType === 'admin_signal' ||
            repType === 'uni_history'
        ) {
            let selectedSectors = sector && sector.length > 0 ? sector : ['all']
            // subtract 3 month but range will be 4 full months because we use month resolution

            const fromDate2 =
                repType === 'scored' && !user.is_admin
                    ? new Date(toDate).setMonth(toDate.getMonth() - 3)
                    : fromDate

            console.log('symbols ', symbols, selectedSectors)
            api.getPerformanceReportByMail(
                moment(fromDate2).format('YYYY-MM'),
                moment(toDate).format('YYYY-MM'),
                encodeURIComponent(JSON.stringify(selectedSectors)),
                symbols,
                marketCapCond,
                marketCapValue1,
                marketCapValue2,
                betaCond,
                betaValue1,
                betaValue2,
                encodeURIComponent(mailTo),
                repType,
                encodeURIComponent(JSON.stringify(scoreParam)),
                adminTakeTop,
                topN ? topN : 'All',
                adminIpMonths,
                adminBtMonths,
                teamThreshold,
                minRatio,
                minMonthAvg,
                user.is_admin &&
                    (repType === 'admin_signal' ||
                        repType === 'admin_pl' ||
                        repType === 'scored')
                    ? encodeURIComponent(JSON.stringify(scoreVer))
                    : false,
            ).subscribe(
                (response) => {
                    console.log('response', response)
                    swal.fire({
                        text: 'Your request was received, please note that this process may take up to one hour to complete.',
                        focusConfirm: false,
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                        icon: 'success',
                    }).then()
                },
                (error) => {
                    console.log('error TODO', error)
                },
            )
        }
        Analytics.exec('track', 'excute report ' + repType)
    }

    const [openMainChevron, setOpenMainChevron] = useState(true)

    const toggleMainChevron = () => {
        if (repType === 'scored' || repType === 'admin_pl')
            setOpenMainChevron(!openMainChevron)
    }

    if (repType === 'scored') {
        return (
            <ScoredReport
                user={user}
                mainColWidth={mainColWidth}
                wideColWidth={wideColWidth}
            />
        )
    }

    if (repType === 'admin_pl') {
        return (
            <AdminPlReport
                user={user}
                mainColWidth={mainColWidth}
                wideColWidth={wideColWidth}
            />
        )
    }

    if (repType === 'admin_signal') {
        return (
            <AdminSignalReport
                user={user}
                mainColWidth={mainColWidth}
                wideColWidth={wideColWidth}
            />
        )
    }

    if (repType === 'backtest') {
        return (
            <BackTestReport
                user={user}
                mainColWidth={mainColWidth}
                wideColWidth={wideColWidth}
            />
        )
    }

    return (
        <Container id="mainDiv" style={{width: wideColWidth}}>
            {/*repType === 'scored' && (
                <>
                    <Trends
                        mainColWidth={mainColWidth}
                        graphHeight={85}
                        graphWidth={80}
                        fold
                        foldState={false}
                    />
                    <Trends
                        title={'Sector Sentiment - Analysts'}
                        mainColWidth={mainColWidth}
                        graphHeight={85}
                        graphWidth={80}
                        fold
                        foldState={false}
                        type={'analystSector'}
                    />
                </>
            )*/}

            <OverrideStyles />
            <ReactTooltip />
            <RepTitle
                text={title}
                fold={repType === 'scored'}
                onClick={toggleMainChevron}></RepTitle>
            <Collapse isOpened={openMainChevron}>
                {/*repType === 'scored' && (
                    <Subtitle>
                        Uncover trading opportunities from a historical period
                        of time using this powerful tool. Gain insights into
                        past market trends and validate your investment
                        strategy. Whether you're analyzing performance or
                        seeking to refine your approach, the Historical
                        Opportunity Report provides essential information to
                        support your decision-making process.
                    </Subtitle>
                )*/}
                <FlexRow width={mainColWidth}>
                    {
                        // repType === 'scored' && !user.is_admin ? (
                        //     // pass toDate to fromDate. not an error - this mode has only one date and we use the from field on the left
                        //     <DateRange
                        //         fromDate={toDate}
                        //         toDate={toDate}
                        //         setFromDate={setToDate}
                        //         dateFormat={dateFormat}
                        //         wholeMonth={wholeMonth}
                        //         mainColWidth={mainColWidth / 2}
                        //         oneDate
                        //         oneDateTitle={
                        //             repType === 'admin_pl' ? 'From' : 'For'
                        //         }
                        //     />
                        // ) : (
                        <DateRange
                            fromDate={fromDate}
                            toDate={toDate}
                            setFromDate={setFromDate}
                            setToDate={setToDate}
                            dateFormat={dateFormat}
                            wholeMonth={wholeMonth}
                            mainColWidth={
                                mainColWidth -
                                (repType === 'admin_pl' ? 200 : 0)
                            }
                        />
                        // )
                    }
                </FlexRow>

                {selectUniverse && (
                    <Row width={mainColWidth}>
                        {customIndexes && (
                            <FrameSelect
                                value={symbols}
                                values={[
                                    {
                                        value: 'all',
                                        label: 'All stocks. No filter',
                                    },
                                    ...customIndexes,
                                ]}
                                setValue={setSymbols}
                                setLabel={setUniverseName}
                                title={'Select Universe'}
                            />
                        )}
                    </Row>
                )}
                {selectSectors && (
                    <>
                        <Row>
                            <SelectSectors
                                width={wideColWidth}
                                //user={user}
                                sectors={sector}
                                setSectors={setSector}
                                type={'sector'}
                            />
                        </Row>
                        <FlexRow width={mainColWidth}>
                            <RangeFilter
                                title={'Market Cap filter-'}
                                condition={marketCapCond}
                                setCondition={setMarketCapCond}
                                val1={marketCapValue1}
                                val2={marketCapValue2}
                                setVal1={setMarketCapValue1}
                                setVal2={setMarketCapValue2}
                                fromTitle={'from (B)'}
                            />
                        </FlexRow>
                        <FlexRow width={mainColWidth}>
                            <RangeFilter
                                title={'Beta filter'}
                                condition={betaCond}
                                setCondition={setBetaCond}
                                val1={betaValue1}
                                val2={betaValue2}
                                setVal1={setBetaValue1}
                                setVal2={setBetaValue2}
                            />
                        </FlexRow>
                    </>
                )}
                {/*repType === 'backtest' && (
                    <>
                        <Row width={mainColWidth}>
                            <FrameText
                                frameStyle={{height: 180}}
                                value={tickers}
                                setValue={setTickers}
                                title={
                                    'Symbols (separate by comma, space or new line)'
                                }
                            />
                        </Row>

                        <Row>
                            {tickers.trim() && (
                                <FileLineChart
                                    // refreshChart={refreshBtChart}
                                    // setRefreshChart={setRefreshBtChart}
                                    autoFirst={true}
                                    tickers={tickers}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    allowDates={false}
                                    exposeBenchmarks={setBenchmarks}
                                />
                            )}
                        </Row>
                    </>
                ) */}
                {/*(repType === 'scored' ||
                    repType === 'admin_signal' ||
                    repType === 'admin_pl') &&
                    user.is_admin && (
                        <>
                            <Row>
                                <ScoreVersion
                                    setScoreVer={setScoreVer}
                                    repType={repType}
                                />
                            </Row>

                            <Row>
                                <ScoreParams
                                    width={wideColWidth}
                                    scoreParam={scoreParam}
                                    setScoreParam={setScoreParam}
                                    //user={user}
                                    testMode={scoreVer.testCalc}
                                    repType={repType}
                                />
                            </Row>

                            <FlexRow
                                style={{
                                    justifyContent: 'flex-start',
                                }}>
                                <FrameInput
                                    title={'Team Threshold'}
                                    value={teamThreshold}
                                    setValue={setTeamThreshold}
                                    frameStyle={{width: 120, marginRight: 30}}
                                />
                                <FrameInput
                                    title={'Min success ratio per month'}
                                    value={minRatio}
                                    setValue={setMinRatio}
                                    frameStyle={{width: 200, marginRight: 30}}
                                />
                                <FrameInput
                                    title={'Min month avg'}
                                    value={minMonthAvg}
                                    setValue={setMinMonthAvg}
                                />
                            </FlexRow>
                        </>
                    ) */}

                <Row width={mainColWidth}>
                    <FrameInput
                        title={'Mail report to'}
                        value={mailTo}
                        setValue={setMailTo}
                        maxLength={100}
                    />
                </Row>
                {repType !== 'admin_pl' && (
                    <>
                        <Row
                            width={mainColWidth}
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>
                            <SendButton onClick={() => getReportMail(false)}>
                                Generate Report and Send by Email
                            </SendButton>
                        </Row>
                    </>
                )}
            </Collapse>
        </Container>
    )
}

export default ReportContainer

const Container = styled.div`
    font-family: 'Inter';
`

const Row = styled.div`
    padding-top: 38px;
    width: ${(p) => p.width}px;
    border: 0px solid purple;
`
const FlexRow = styled(Row)`
    display: flex;
    justify-content: space-between;
    width: ${(p) => p.width}px;
    border: 0px solid purple;
    align-items: baseline;
`
const Subtitle = styled.div`
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    margin-top: 10px;
    color: ${AppColors.APP_GRAY};
`
// const McContainer = styled.div`
//     display: flex;
//     flex-direction: row;
//     border: 1px solid purple;
// `

const OverrideStyles = createGlobalStyle`
    .ReactCollapse--collapse {
        transition: height 600ms;
    }
`
// .kaka {
//         background-color: red;
//         border: 1px solid green;
//     }
const RepTitle = styled(Title)`
    display: flex;
    flex-direction: row;
`
// const Dates = styled.div`
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     width: ${(p) => p.mainColWidth}px;
//     border: 0px solid green;
// `

const Link = styled.a`
    text-decoration: none;
`
