import React from 'react'
import styled from 'styled-components'

import {useState, useEffect, useUser} from '@services/hooks'
import {PageContent} from './Home.styled'
import {api} from '@services/api'

//import AlertsReport from '../Reports/AlertsReport'
import ReportContainer from '../Reports/ReportContainer'
import PBT from '../Reports/PBT'
import NotificationsReport from '../Reports/NotificationsReport'
//import GoldHistoryReport from '../Reports/GoldHistoryReport'
import MoreTools from '../Reports/MoreTools'
//import LiveReport from '../Reports/LiveReport'
import ManageUniverses from '../Reports/ManageUniverses'
import AddSymbols from '../Reports/AddSymbols'
import AnalystTeamExplain from '../Reports/AnalystTeamExplain'
import OppScanner from '../Reports/OppScanner'
import CopyPortfolio from '../Reports/CopyPortfolio'
import {HelpLink} from '@components/General/Elements'

// import Header from '../Header'
// import Drawer from 'material-ui/Drawer'
// import {List, ListItem} from 'material-ui/List'
import {Dimensions, AppColors} from '@services/styles'
import {getUrlParams} from '@services/utils'

const Tools = () => {
    const user = useUser()
    const mainColWidth = window.innerWidth / 3
    const wideColWidth = window.innerWidth / 1.72
    //const [btSymbols, setBtSymbols] = useState([])

    // useEffect(() => {
    //     console.log('btSymbols1', btSymbols)
    //     // setReport(reports[3]) this does not work because btSymbols inside the array is not current(?)
    //     if (btSymbols && btSymbols.length) {
    //         setReport({
    //             name: 'Back Test',
    //             module: (
    //                 <ReportContainer
    //                     user={user}
    //                     repType={'backtest'}
    //                     mainColWidth={mainColWidth}
    //                     wideColWidth={wideColWidth}
    //                     btSymbols={btSymbols}
    //                 />
    //             ),
    //         })
    //     }
    // }, [btSymbols])

    const initialReports = [
        {
            name: 'Opportunities Scanner',
            module: <OppScanner user={user} />,
        },
        {
            name: 'Market Data',
            module: (
                <ReportContainer
                    user={user}
                    repType={'scored'}
                    mainColWidth={mainColWidth}
                    wideColWidth={wideColWidth}
                />
            ),
        },
        {
            name: 'Backtest',
            module: (
                <ReportContainer
                    user={user}
                    repType={'backtest'}
                    mainColWidth={mainColWidth}
                    wideColWidth={wideColWidth}
                />
            ),
        },
        {
            name: 'Universes',
            module: (
                <ManageUniverses
                    canEdit={user && (user.is_admin || user.client_admin)}
                    sysAdmin={user && user.is_admin}
                />
            ),
        },
        {
            name: 'Portfolio Notifications',
            module: <NotificationsReport mainColWidth={mainColWidth} />,
        },
    ]

    const [reports, setReports] = useState(initialReports)
    const [report, setReport] = useState(initialReports[0])
    const urlParams = getUrlParams()

    useEffect(() => {
        if (user) {
            var adminReports = []
            var moreAdminReports = []
            if (
                user.is_admin ||
                user.user_id === '62d5086b15b95657508b4cfa' ||
                user.user_id === '64cb91e415b956dc688b457b'
            ) {
                adminReports = [
                    {
                        name: 'Strategy Backtest',
                        module: <PBT mainColWidth={mainColWidth} user={user} />,
                    },
                    {
                        name: 'Research Analyst Team',
                        module: <AnalystTeamExplain user={user} />,
                    },

                    {
                        name: 'Admin Investment PL',
                        module: (
                            <ReportContainer
                                user={user}
                                repType={'admin_pl'}
                                mainColWidth={mainColWidth}
                                wideColWidth={wideColWidth}
                            />
                        ),
                    },
                    {
                        name: 'Admin signal',
                        module: (
                            <ReportContainer
                                user={user}
                                repType={'admin_signal'}
                                mainColWidth={mainColWidth}
                                wideColWidth={wideColWidth}
                            />
                        ),
                    },
                    {
                        name: 'Universe symbols history',
                        module: (
                            <ReportContainer
                                user={user}
                                repType={'uni_history'}
                                mainColWidth={mainColWidth}
                                wideColWidth={wideColWidth}
                            />
                        ),
                    },
                ]
            }
            if (user.is_admin) {
                moreAdminReports = [
                    {
                        name: 'Add Symbols',
                        module: <AddSymbols user={user} />,
                    },
                    {
                        name: 'User Orders by User ID',
                        module: <MoreTools user={user} />,
                    },
                    {
                        name: 'Copy portfolio from user to user',
                        module: <CopyPortfolio />,
                    },
                    // {
                    //     name: 'Portfolio Notifications Report',
                    //     module: (
                    //         <NotificationsReport mainColWidth={mainColWidth} />
                    //     ),
                    // },
                ]
            }
            setReports((prev) => [
                ...initialReports,
                ...adminReports,
                ...moreAdminReports,
            ])
            if (urlParams && urlParams.opp) {
                setReport(initialReports[1])
            }
        }
    }, [user])

    return (
        <PageContent>
            <Container>
                <div
                    style={{
                        position: 'fixed',
                        left: 0,
                        height:
                            window.innerHeight -
                            Dimensions.NAVBAR_TOP_OFFSET -
                            Dimensions.APP_BAR_HEIGHT -
                            1,
                        top:
                            Dimensions.NAVBAR_TOP_OFFSET +
                            Dimensions.APP_BAR_HEIGHT +
                            1,
                        width: 300,
                        zIndex: 0,
                        backgroundColor: '#F6F7F8',
                        boxShadow: 'unset',
                        overflow: 'auto',
                        border: '0px solid blue',
                        display: 'flex',
                        flex: 1,
                    }}>
                    <br />
                    <div
                        style={{
                            top: 0,
                            width: '98%',
                            height: '99%',
                            border: '0px solid green',
                            overflow: 'scroll',
                        }}>
                        {reports.map((rep, index) => (
                            <MenueItem
                                selected={report.name == rep.name}
                                onClick={() => setReport(rep)}
                                key={'report_' + index}>
                                {rep.name}
                            </MenueItem>
                        ))}
                        <br />
                    </div>
                </div>

                <MainPane>{report.module}</MainPane>
            </Container>
            {report?.name == 'Opportunities Scanner' && (
                <HelpLink linkSuffix={'#opportunites-overview'} />
            )}
        </PageContent>
    )
}

export default Tools

// site is built incredibly stupid!!!!!
// things go over and under each other in relative and absolute position....
// dont know whats going on there, need to rebuild all but don't have the time
// this is why all the pixeling games...

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    border: 0px solid red;
    margin-left: 213px;
    font-family: 'Inter';
`
const MainPane = styled.div`
    border: 0px solid green;
    flex: 1;
`
const MenueItem = styled.div`
    font-family: 'Inter';
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    color: ${AppColors.BLACK};
    border: 0px solid;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 13px;
    border-radius: 10px;
    background-color: ${(p) =>
        p.selected ? 'RGBA(0,148,141,0.2)' : '#F6F7F8'};
    &:hover {
        background-color: ${(p) => (p.selected ? '' : AppColors.MENU_HOVER)};
    }
    cursor: ${(p) => (p.selected ? 'default' : 'pointer')};
`
