import React from 'react'
import {useEffect} from '@services/hooks'
import ScoreParams from '@components/Reports/ScoreParams'
// import ScoreVersion from '@components/Reports/ScoreVersion'
import FrameInput from '@components/General/FrameInput'
import styled from 'styled-components'

const AdminParamsSelector = ({
    user,
    repType,
    wideColWidth,
    scoreParam,
    setScoreParam,
    setScoreVer,
    analystTeamSelectionParams,
    setAanalystTeamSelectionParamsetAnalysisParams,
}) => {
    useEffect(() => {
        setAanalystTeamSelectionParamsetAnalysisParams({
            teamThreshold: 4,
            minRatio: 0.7,
            minMonthAvg: 0.6,
        })
    }, [setAanalystTeamSelectionParamsetAnalysisParams])

    const updateAnalystTeamSelectionParams = (field) => (value) => {
        setAanalystTeamSelectionParamsetAnalysisParams({
            ...analystTeamSelectionParams,
            [field]: value,
        })
    }

    if (!user.is_admin) {
        return null
    }

    const {teamThreshold, minRatio, minMonthAvg} = analystTeamSelectionParams
    return (
        <>
            {/* <Row>
                <ScoreVersion setScoreVer={setScoreVer} repType={repType} />
            </Row> */}

            <Row>
                <ScoreParams
                    width={wideColWidth}
                    scoreParam={scoreParam}
                    setScoreParam={setScoreParam}
                    repType={repType}
                />
            </Row>

            <FlexRow
                style={{
                    justifyContent: 'flex-start',
                }}>
                <FrameInput
                    title={'Team Threshold'}
                    value={teamThreshold}
                    setValue={updateAnalystTeamSelectionParams('teamThreshold')}
                    frameStyle={{width: 120, marginRight: 30}}
                />
                <FrameInput
                    title={'Min success ratio per month'}
                    value={minRatio}
                    setValue={updateAnalystTeamSelectionParams('minRatio')}
                    frameStyle={{width: 200, marginRight: 30}}
                />
                <FrameInput
                    title={'Min month avg'}
                    value={minMonthAvg}
                    setValue={updateAnalystTeamSelectionParams('minMonthAvg')}
                />
            </FlexRow>
        </>
    )
}

export default AdminParamsSelector

const Row = styled.div`
    padding-top: 38px;
    width: ${(p) => p.width}px;
`
const FlexRow = styled(Row)`
    display: flex;
    justify-content: space-between;
    width: ${(p) => p.width}px;
    border: 0px solid purple;
    align-items: baseline;
`
