import React from 'react'
import styled from 'styled-components'
//import {useState, useEffect} from '@services/hooks'
//import {PageContent} from './Home.styled'
// import {api} from '@services/api'
// import {SendButton, SendButtonDis} from '@components/General/Elements'
// import swal from 'sweetalert2'
import {AppColors} from '@services/styles'
import {ReactComponent as BigLogo} from '@images/gray_logo.svg'

const EmptyList = ({type, setAddBudgetDialog}) => {
    return (
        <Container>
            <BigLogo />
            <NewFileMsg>
                {type === 'newFile' ? (
                    <>
                        Welcome to your portfolio. Please enter your total
                        portfolio{' '}
                        <LinkStart onClick={() => setAddBudgetDialog(true)}>
                            Funds
                        </LinkStart>{' '}
                        and add your positions to be able to follow up your
                        performance over time.
                    </>
                ) : type === 'noOpenPos' ? (
                    'No positions yet. Get started by clicking the plus button on the top to start adding your positions.'
                ) : type === 'noClosePos' ? (
                    'No closed positions yet'
                ) : type === 'wl' ? (
                    'Choose preferred stocks from the live feed of opportunities, or search stocks manually and click the star to add them to this watchlist.'
                ) : type === 'livefeed' ? (
                    'No opportunities found under current filter settings. Please change filter settings or reset all filters.'
                ) : type == 'stock' ? (
                    'No recent ratings found'
                ) : type === 'stock_filter' ? (
                    'No recent ratings found by current filters'
                ) : null}
            </NewFileMsg>
        </Container>
    )
}

export default EmptyList
//  padding-left: 40px;
//     padding-right: 40px;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
`
const LinkStart = styled.div`
    color: ${AppColors.TIPIGO_GREEN};
    display: inline;
    cursor: pointer;
`
const NewFileMsg = styled.div`
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    align-items: center;
    width: 520px;
    margin-top: 20px;
    text-align: center;
`
const InputBudget = styled.input`
    border: 1px solid ${AppColors.APP_GRAY_LIGHT};
    border-radius: 8px;
    width: 98px;
    padding-left: 10px;
    font-size: 21px;
    outline: none;
    background-color: white !important;
    color: #000000;
    margin-left: 10px;
    margin-right: 10px;
`
