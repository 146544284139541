import {takeLatest} from 'redux-saga/effects'
import Analytics from '../services/analytics'

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* aiSaga() {
    yield takeLatest('API_RESPONSE', (action) => {
        if (action && action.method) {
            if (action.method === 'getCurrentUser') {
                // We have the user
                let s = action.data

                Analytics.exec('identify', s.email, {
                    email: s.email,
                    nickname: s.nickname,
                    user_id: s.user_id,
                })
            }

            if (['logout', 'login'].indexOf(action.method) > -1) {
                Analytics.exec('track', action.method)
            }
        }
    })
}

export default aiSaga
