import React, {useEffect} from 'react'
import styled from 'styled-components'
import {useState, useSectors} from '@services/hooks'
import {api} from '@services/api'
import Swal from 'sweetalert2'
import FrameText from '@components/General/FrameText'
import FrameSelect from '@components/General/FrameSelect'
import {SendButton, Title, FormRow} from '@components/General/Elements'
import {AppColors} from '@services/styles'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'

const AddSymbols = () => {
    const [newSymbols, setNewSymbols] = useState('')
    const [mode, setMode] = useState('add')
    const sectors = useSectors()
    const [sector, setSector] = useState(sectors[0].sector_ref_id)
    const [noSector, setNoSector] = useState('')

    useEffect(() => {
        api.adminSymbolsNoSector().subscribe(
            (response) => {
                if (response?.data) {
                    // console.log('symbol_no_sector', response.data)
                    setNoSector(response.data.tickers.join(', '))
                }
            },
            (error) => {
                setNoSector('error')
            },
        )
    }, [])

    const SaveSymbols = () => {
        if (newSymbols != '') {
            api.adminAddSymbols(
                newSymbols,
                mode === 'add' ? 0 : sector,
            ).subscribe(
                (response) => {
                    if (response && response.data && response.data.status) {
                        setNewSymbols('')

                        Swal.fire({
                            text:
                                (response.data.count
                                    ? response.data.count + ' '
                                    : '') +
                                'symbols added and will start collecting data within 24 hours',
                            icon: 'success',
                            confirmButtonColor: AppColors.TIPIGO_GREEN,
                        })
                    } else {
                        console.log('what..?', response)
                    }
                },
                (error) => {
                    console.log('error adding symbols', error)
                },
            )
        } else {
            Swal.fire({
                text: 'please set symbol names',
                icon: 'error',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
        }
    }

    return (
        <Container>
            <Row>
                <Title text={'Add symbols'} />
            </Row>
            <RowSet style={{alignItems: 'flex-start'}}>
                <RadioGroup
                    row
                    value={mode}
                    onChange={(e, value) => {
                        console.log(value)
                        setMode(value)
                    }}>
                    <FormControlLabel
                        value={'add'}
                        control={<Radio />}
                        label="Add"
                    />
                    <FormControlLabel
                        value={'edit'}
                        control={<Radio />}
                        label="Set Sector"
                    />
                </RadioGroup>
                <FrameSelect
                    title={null}
                    value={sector}
                    values={sectors.map((item) => {
                        return {value: item.sector_ref_id, label: item.name}
                    })}
                    setValue={setSector}
                    disabled={mode === 'add'}
                    style={{width: 250}}

                    //setValue={updateSingle}
                />
            </RowSet>
            <Row>
                <FrameText
                    title={'Symbols to add'}
                    style={{flex: 1}}
                    frameStyle={{height: 140}}
                    value={newSymbols}
                    setValue={setNewSymbols}
                />
            </Row>
            <Row>
                <SendButton onClick={SaveSymbols}>Save</SendButton>
            </Row>
            <Row>
                <FrameText
                    title={'Symbols with no sector'}
                    style={{flex: 1}}
                    frameStyle={{height: 240}}
                    disabled
                    value={noSector}
                />
            </Row>
        </Container>
    )
}

export default AddSymbols

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    border: 0px solid pink;
    font-family: 'Inter';
`
const Row = styled(FormRow)`
    margin-bottom: 40px;
`
const RowSet = styled(FormRow)`
    display: flex;
    justify-content: flex-start;
`
// const Row = styled.div`
//     display: flex;
//     flex: 1;
//     border: 0px solid green;
//     justify-content: space-between;
//     margin-bottom: 40px;
// `
