import React from 'react'
import styled from 'styled-components'
import {
    useState,
    useEffect,
    //useFollowingSymbols,
    useSymbols,
    useDispatch,
} from '@services/hooks'
import {PageContent} from './Home.styled'
import {api} from '@services/api'
import _ from 'lodash'
import ScrollTable from '@components/General/ScrollTable'
import {
    ScrollTableStyles,
    SymbolInTable,
    ColoredCell,
} from '@components/General/Elements'
import {Dimensions} from '@services/styles'
import {Link} from 'react-router-dom'
import {Close} from '@mui/icons-material'
import {apiUpdateWatchlist} from '@store/actions'
import {ReactComponent as BigLogo} from '@images/gray_logo.svg'
import EmptyList from '@components/General/EmptyList'
import Analytics from '@services/analytics'
import moment from 'moment'
import CircularProgress from 'material-ui/CircularProgress'
import Menu from '@mui/material/Menu'
import {MoreVert} from '@mui/icons-material'
import MenuItem from '@mui/material/MenuItem'
import Symbol2Bench from './MyFile/Symbol2Bench'
// import AddEditPosition from './MyFile/AddEditPosition'
import BulkAddPositions from './MyFile/BulkAddPositions'
import Checkbox from '@mui/material/Checkbox'
import {AppColors} from '@services/styles'

const columns = [
    // {
    //     accessor: 'select',
    //     Header: '',
    //     disableSortBy: true,
    //     maxWidth: 30,
    // },
    {
        accessor: 'symbol',
        Header: 'Symbol',
        sortType: 'basic',
        Cell: (o) => (
            <Link
                data-tip={o.row.original.company_name}
                to={'/stock/' + o.value}
                style={{textDecoration: 'none'}}>
                <SymbolInTable>{o.value}</SymbolInTable>
            </Link>
        ),
    },
    {accessor: 'sector', Header: 'Sector'},
    {
        accessor: 'entry_date',
        Header: () => (
            <span>
                Entry
                <br />
                Date
            </span>
        ),
        Cell: (o) => (o.value ? moment(o.value.date).format('MM/DD/YY') : ''),
    },
    {
        accessor: 'entryPrice',
        Header: () => (
            <span>
                Entry
                <br />
                Price
            </span>
        ),
    },
    {
        accessor: 'lastPrice',
        Header: () => (
            <span>
                Last
                <br />
                Price
            </span>
        ),
    },
    {
        accessor: 'pl',
        Header: 'P&L(%)',
        Cell: (s) => (
            <ColoredCell val={s.value}>
                {s.value ? s.value.toFixed(2) + '%' : ''}
            </ColoredCell>
        ),
    },
    {
        accessor: 'etf_pl',
        Header: () => (
            <span>
                Benchmark
                <br />
                P&L(%)
            </span>
        ),
        sortType: 'basic',
        Cell: (o) => (
            <div data-tip={'etf: ' + o.row.original.etf}>
                {o.value ? o.value.toFixed(2) + '%' : ''}
            </div>
        ),
    },
    {
        accessor: 'dailyChangePercent', //'daily_change',
        Header: () => (
            <span>
                Daily
                <br />
                Change
            </span>
        ),
        sortType: 'basic',
        Cell: (o) => <ColoredCell val={o.value}>{o.value}%</ColoredCell>,
    },
    {
        accessor: 'earningDate',
        Header: 'Earning',
        Cell: (o) => (o.value ? moment(o.value).format('MM/DD/YY') : ''),
    },
    {
        accessor: 'close',
        Header: '',
        disableSortBy: true,
        maxWidth: 30,
    },
]

const Watchlist = () => {
    //const followingSymbols = useFollowingSymbols()
    const [wlSymbols, setWlSymbols] = useState([]) //useState(_.cloneDeep(followingSymbols))
    const [data, setData] = useState([])
    const [tableHeight, setTableHeight] = useState(400)
    const [empty, setEmpty] = useState(false)
    const [selectedSymbol, setSelectedSymbol] = useState('')
    const dispatch = useDispatch()
    const [anchorActions, setAnchorActions] = useState(null)
    const openActions = Boolean(anchorActions)
    const [compareSymDialog, setCompareSymDialog] = useState(false)
    const [editPosDialog, setEditPosDialog] = useState(false)

    //const symbols = useSymbols()

    useEffect(() => {
        console.log('env', window.location.host)
        setTableHeight(
            window.innerHeight -
                Dimensions.NAVBAR_TOP_OFFSET -
                Dimensions.APP_BAR_HEIGHT -
                100,
        )
        api.getWatchlist().subscribe(
            (response) => {
                if (response.data && response.data.following) {
                    console.log('get_watchlist', response.data)
                    setWlSymbols(response.data.following)
                    setEmpty(response.data.following.length == 0)
                } else {
                    setEmpty(true)
                }
            },
            (error) => {
                setEmpty(true) // this is wrong but better than circular
                console.log('get watchlist error', error)
            },
        )

        Analytics.exec('track', 'watchlist page', {more: 'more3'})
    }, [])

    const unFollowSymbol = () => {
        setAnchorActions(null)
        // don't need to reload list, server done on background and here only remove from setWlSymbols
        if (selectedSymbol) {
            api.unfollowSymbol(selectedSymbol.symbol).subscribe(
                (response) => {
                    dispatch(apiUpdateWatchlist(response.data))
                },
                (error) => {
                    console.warn(error)
                },
            )
            const cleanList = wlSymbols.filter(
                (p) => p.symbol != selectedSymbol.symbol,
            )
            setWlSymbols(cleanList)
        }
    }

    const openActionsMenu = (e, symbol) => {
        setAnchorActions(e.currentTarget)
        setSelectedSymbol(symbol)
    }
    // need to create data here because coulmns is static and doesn't know unFollowSymbol()
    // i tried adding the column in useEffect() but didn't work. sure there is a more elegant way to do that
    useEffect(() => {
        if (wlSymbols?.length) {
            setData(
                wlSymbols.map((item, i) => {
                    return {
                        ...item,
                        selected: false,
                        id: i,
                        close: (
                            <MoreVert
                                onClick={(e) => openActionsMenu(e, item)}
                            />
                        ),
                    }
                }),
            )
        }
    }, [wlSymbols])

    useEffect(() => {
        console.log('data_change', data)
    }, data)

    return (
        <PageContent>
            <Container>
                {wlSymbols?.length ? (
                    <ScrollTable
                        columns={columns}
                        data={data}
                        hiddenCols={[]}
                        TableStyles={ScrollTableStyles}
                        sortDefault={'symbol'}
                        height={tableHeight}
                        maxWidth={1400}
                    />
                ) : empty ? (
                    <EmptyList type="wl" />
                ) : (
                    <CircularProgress />
                )}
                <Menu
                    open={openActions}
                    anchorEl={anchorActions}
                    onClose={() => setAnchorActions(null)}>
                    <StyledMenuItem onClick={unFollowSymbol}>
                        Remove from Watclist
                    </StyledMenuItem>
                    <StyledMenuItem
                        onClick={() => {
                            setAnchorActions(null)
                            setEditPosDialog(true)
                        }}>
                        Add to portfolio
                    </StyledMenuItem>

                    <StyledMenuItem
                        onClick={() => {
                            setAnchorActions(null)
                            setCompareSymDialog(true)
                        }}>
                        Compare to Benchmark
                    </StyledMenuItem>

                    <StyledMenuItem
                        component={Link}
                        // is it safe? selectedPos is state and might not be ready yet?
                        to={
                            '/stock/' +
                            (selectedSymbol ? selectedSymbol.symbol : '')
                        }>
                        Symbol's Page
                    </StyledMenuItem>
                </Menu>
                <Symbol2Bench
                    pos={selectedSymbol}
                    isOpenDialog={compareSymDialog}
                    setIsOpenDialog={setCompareSymDialog}
                />
                <BulkAddPositions
                    //pId={pId}
                    //pos={selectedPos}
                    //userId={user.user_id}
                    isOpenDialog={editPosDialog}
                    setIsOpenDialog={setEditPosDialog}
                    ticker={selectedSymbol.symbol}
                    msgOnSuccess
                />
            </Container>
        </PageContent>
    )
}

export default Watchlist

const Container = styled.div`
    display: flex;
    flex: 1;
    font-family: 'Inter';
    align-items: center;
    border: 0px solid red;
    flex-direction: column;
    //background-color: #f4e7e0;
`
const StyledMenuItem = styled(MenuItem)`
    font-size: 15px !important;
    margin-top: 8px !important;
`
