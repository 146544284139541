import styled, {keyframes} from 'styled-components'
import {Dimensions, AppColors} from '@services/styles'

// import CircularProgress from 'material-ui/CircularProgress'
// import {Label} from './HomeDrawer.styles'

// comment 31.03.22 it is double from NavAppBar.styled.js
// export const AppBarNavContainer = styled.div`
//     display: flex;
//     align-items: center;
//     margin: 0px;
//     height: 100%;
// `
// export const AppBarNavItem = styled.span`
//     color: #fff;
//     text-decoration: none;
//     font-weight: 100;
//     padding: 10px;
//     margin: 0px 5px;
//     font-size: 16px;
//     cursor: pointer;
//     text-align: center;
//     border-bottom: ${(p) => (p.isActive ? '2' : '0')}px solid #fff;
// `

export const PageContent = styled.div`
    /* margin-left: ${Dimensions.CONTENT_BASE_PADDING}px; */
    margin-top: ${Dimensions.CONTENT_BASE_PADDING +
    Dimensions.NAVBAR_TOP_OFFSET +
    Dimensions.APP_BAR_HEIGHT}px;
    padding: ${Dimensions.CONTENT_BASE_PADDING}px;
    padding-right: 0px;
    padding-top: 0px;
    display: flex;
`

//export const AlertContainer = styled.div``

// const loadingAnim = keyframes`
//     from {transform: translate(-100%, -90%);}
//     to {transform: translate(0%, 0%);}
// `
// const loadingCircleBorderAnim = keyframes`
//     from {transform: translateX(-8px);}
//     to {transform: translateX(142px);}
// `
// const PollWrap = styled.div`
//     position: relative;
//     border: 1px solid #000;
//     border-radius: 100px;
//     width: 150px;
//     height: 15px;
//     overflow: hidden;

//     > div:nth-child(1) {
//         animation: ${loadingAnim} ${(p) => p.interval || 30}s linear infinite;
//         background: repeating-linear-gradient(
//             -45deg,
//             #2e5daa,
//             #2e5daa 10px,
//             rgba(52, 106, 195, 0.3) 10px,
//             rgba(52, 106, 195, 0.3) 20px
//         );
//         width: 100%;
//         height: 1000%;
//     }
//     > div:nth-child(2) {
//         position: absolute;
//         height: 100%;
//         top: 0;
//         background-image: radial-gradient(
//             circle at 0 50%,
//             rgba(204, 0, 0, 0) 7.5px,
//             ${AppColors.TIPIGO_BLUE} 8.5px
//         );
//         width: 100%;
//         animation: ${loadingCircleBorderAnim} ${(p) => p.interval || 30}s linear
//             infinite;
//     }
// `

// 16.05.23 but seems to out of use long before
// export const PollLoading = ({interval}) => (
//     <PollWrap interval={interval}>
//         <div></div>
//         <div></div>
//     </PollWrap>
// )

// const blink = keyframes`
//     0% {
//       opacity: .2;
//     }
//     20% {
//       opacity: 1;
//     }
//     100% {
//       opacity: .2;
//     }
// `
// const LoadText = styled.div`
//     font-size: 18px;
//     color: #fff;
//     > span {
//         font-size: 27px;
//         animation-name: ${blink};
//         animation-duration: 1.4s;
//         animation-iteration-count: infinite;
//         animation-fill-mode: both;
//     }
//     > span:nth-child(2) {
//         animation-delay: 0.2s;
//     }
//     > span:nth-child(3) {
//         animation-delay: 0.4s;
//     }
// `

// const PollFetchWrap = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// `

// 16.05.23 but seems to out of use long before
// export const PollFetching = (props) => (
//     <PollFetchWrap>
//         <LoadText>
//             Updating<span>.</span>
//             <span>.</span>
//             <span>.</span>
//         </LoadText>
//         {/* <CircularProgress color="#fff" size={15} thickness={2} /> */}
//     </PollFetchWrap>
// )
