import React from 'react'
import styled from 'styled-components'
import {useState, useEffect, useUser} from '@services/hooks'
import Dialog from '@mui/material/Dialog'
import {
    Title,
    SendButton,
    SendButtonDis,
    DialogContainer,
    DialogTopRow,
    DialogRow,
} from '@components/General/Elements'
import {api} from '@services/api'
import {Close} from '@mui/icons-material'
import AddPosLine from './AddPosLine'
import {
    intOrEmpty,
    toNiceNum,
    updateFieldInList,
    formatUserInputPrice,
} from '@services/utils'
import swal from 'sweetalert2'
import {AppColors} from '@services/styles'

const BulkClosePositions = ({
    pId,
    isOpenDialog,
    setIsOpenDialog,
    selected,
    setNeedRefresh,
    minDate,
}) => {
    const [pos2Close, setPos2Close] = useState([])
    const [canClose, setCanClose] = useState(true)

    const closePositions = () => {
        api.closeUserSecurities(pId, pos2Close).subscribe(
            (response) => {
                console.log('close positions', response)
                if (!response.data.status) {
                    swal.fire({
                        text: response.data.msg,
                        icon: 'error',
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                    })
                } else {
                    setNeedRefresh(true)
                    setTimeout(() => setIsOpenDialog(false), 500)
                }
            },
            (error) => console.log('close positions error', error),
        )
    }

    useEffect(() => {
        if (selected?.length) {
            setPos2Close(
                selected.map((pos) => {
                    return {
                        ...pos,
                        posId: pos.id,
                        price: pos.last_price,
                        date: new Date(),
                        value: pos.last_price * pos.quantity,
                    }
                }),
            )
        }
    }, [selected])

    useEffect(() => {
        console.log('selected_p', pos2Close)
        setCanClose(
            pos2Close.every((pos) => pos.price && pos.date && pos.quantity),
        )
    }, [pos2Close])

    const updateField = (val, id, field) => {
        updateFieldInList(val, id, field, setPos2Close)
    }

    const setClosePrice = (value, id) => {
        const formatedPrice = formatUserInputPrice(value, id)
        console.log('close price1', formatedPrice)
        if (formatedPrice !== false) {
            console.log('close price2', formatedPrice)
            updateField(formatedPrice, id, 'price')
            setPosValue(id, false, formatedPrice)
        }
    }
    const setQuantity = (value, id) => {
        const maxQ = selected.find((pos) => pos.id == id).quantity
        value = intOrEmpty(value)
        if (value == '') {
            updateField('', id, 'quantity')
            updateField(0, id, 'value')
        } else {
            value = value > maxQ ? maxQ : value
            updateField(toNiceNum(value), id, 'quantity')
            setPosValue(id, value, false)
        }
    }
    const setPosValue = (id, qnty, price) => {
        const pos = pos2Close.find((pos) => pos.id == id)
        const newVal = qnty ? qnty * pos.price : pos.quantity * price
        updateField(newVal, id, 'value')
    }
    const removeRow = (id) => {
        const count = pos2Close.length
        if (count == 1) {
            setPos2Close([])
            setIsOpenDialog(false)
        } else
            setPos2Close((current) => [
                ...current.filter((item) => item.id !== id),
            ])
    }

    return (
        <Dialog
            maxWidth="lg"
            fullWidth
            modal={true}
            open={isOpenDialog}
            onClose={() => setIsOpenDialog(false)}>
            <DialogContainer
                style={{
                    paddingLeft: 48,
                    paddingRight: 48,
                }}>
                <DialogTopRow>
                    <Title text={'Close Positions'} />
                    <Close onClick={() => setIsOpenDialog(false)} />
                </DialogTopRow>
                {pos2Close.map((pos, i) => (
                    <AddPosLine
                        key={i}
                        item={pos}
                        closeMode
                        updateField={updateField}
                        // _setTicker={_setTicker}
                        setEntry={setClosePrice}
                        setQuantity={setQuantity}
                        // _setInputValue={_setInputValue}
                        // single={single}
                        removeRow={removeRow}
                        // setByValue={setByValue}
                        // ticker={ticker}
                        minDate={minDate}
                    />
                ))}
                <DialogRow style={{justifyContent: 'flex-end', marginTop: 14}}>
                    {canClose ? (
                        <SendButton onClick={closePositions}>Close</SendButton>
                    ) : (
                        <SendButtonDis>Close</SendButtonDis>
                    )}
                </DialogRow>
            </DialogContainer>
        </Dialog>
    )
}
export default BulkClosePositions
