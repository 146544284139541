import React from 'react'
//import {api} from '@services/api'
import {useState, useEffect} from '@services/hooks'
//import CircularProgress from 'material-ui/CircularProgress'
import styled from 'styled-components'
import {AppColors} from '@services/styles'
import ReactTooltip from 'react-tooltip'
import SelectBenchmarks from '@components/Home/MyFile/SelectBenchmarks'
import HorizontalStackLine from './HorizontalStackLine'
import {AddBenchmarkButton} from '@components/General/Elements'
import {api} from '@services/api'

const sectorOrder = [
    'IT',
    'Healthcare',
    'Staples',
    'Real Estate',
    'Energy',
    'Discretionary',
    'Communication',
    'Financials',
    'All',
    'Utilities',
    'Industrials',
    'Materials',
]

const HorizontalStack = ({
    small,
    title,
    pId,
    refreshChart,
    setsData = [],
    showMainTitle = true,
}) => {
    // keep data the same as for chart.js as we will probably go back to it
    // however this line takes only the first dataset

    const [localData, setLocalData] = useState([])
    const [benchmarks, setBenchmarks] = useState([])
    const [benchmarksSets, setBenchmarksSets] = useState([])
    const [benchmarksDialog, setBenchmarksDialog] = useState(false)
    const [graphData, setGraphData] = useState(null)

    useEffect(() => {
        if (pId) {
            getFileChartData()
        }
    }, [pId])

    useEffect(() => {
        if (refreshChart) {
            console.log('refresh_chart')
            // triger by change in parent (e.g add position)
            getFileChartData()
        }
    }, [refreshChart])

    const getFileChartData = () => {
        if (pId) {
            api.getUserPortfolioPieChart(pId).subscribe(
                (response) => {
                    console.log('got_pie_chart_data', response)
                    setGraphData(response.data)
                },
                (error) => {
                    console.log('pie chart error', error)
                },
            )
        }
    }

    const formatPieToLine = (data) => {
        const sum = data.datasets[0].data.reduce((a, b) => a + b, 0)
        const arr = data.labels.map((label, i) => {
            var p = data.datasets[0].data[i] / sum
            return {
                label: label + ' ' + (p * 100).toFixed(p > 0.01 ? 0 : 2) + '%',
                sector: label,
                value: data.datasets[0].data[i],
                per: p,
            }
        })
        return arr
    }

    useEffect(() => {
        if (setsData?.length) {
            console.log('mainSetData0', setsData)
        }
        if (setsData?.length) doSetLocalData(setsData)
    }, [setsData])

    useEffect(() => {
        if (graphData?.datasets) {
            const newData = {
                title: 'Portfolio',
                data: formatPieToLine(graphData),
            }
            doSetLocalData([newData])
        }
    }, [graphData])

    useEffect(() => {
        console.log('benchmarks', benchmarks)
        if (benchmarks?.length) {
            getBenchmarksData()
        } else {
            setBenchmarksSets([])
        }
    }, [benchmarks])

    const doSetLocalData = (mainSetData) => {
        var mainSet = mainSetData
        if (!mainSetData && localData?.length) {
            mainSet = [localData[0]]
        }
        if (mainSet?.length) {
            setLocalData([mainSet[0], ...benchmarksSets])
        }
    }

    useEffect(() => {
        doSetLocalData()
    }, [benchmarksSets])

    const getBenchmarksData = () => {
        api.getEtfAllocations(benchmarks).subscribe((response) => {
            const keys = Object.keys(response.data)

            var addSets = []
            keys.forEach((key) => {
                const newSet = Object.keys(response.data[key]).map((sector) => {
                    const p = response.data[key][sector]
                    return {
                        label: sector + ' ' + p + '%',
                        value: p,
                        sector: sector,
                        per: p / 100,
                    }
                })
                addSets.push({
                    title: key,
                    data: newSet,
                })
            })

            setBenchmarksSets(addSets)
        })
    }

    return localData?.length && localData[0].data?.length ? ( //pc
        <Container>
            <ReactTooltip id="path-bar" />
            {showMainTitle && (
                <TopRow>
                    <Title>{title || 'Sectors'}</Title>
                    {!small && (
                        <AddBenchmarkButton
                            onClick={() => setBenchmarksDialog(true)}
                        />
                    )}
                </TopRow>
            )}
            <MainLine>
                {localData &&
                    localData.map((oneSet, j) => {
                        return (
                            <HorizontalStackLine
                                oneSet={oneSet}
                                key={`lineKey${j}`}
                                sectorOrder={sectorOrder}
                                isLast={j === localData.length - 1}
                                deepColor={j === 0}
                                small={small}
                            />
                        )
                    })}
            </MainLine>
            <SelectBenchmarks
                benchmarks={benchmarks}
                setBenchmarks={setBenchmarks}
                isOpenDialog={benchmarksDialog}
                setIsOpenDialog={setBenchmarksDialog}
                type="etf_allocation"
            />
        </Container>
    ) : null
}
export default HorizontalStack

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${AppColors.APP_GRAY_LIGHT};
    border-radius: 7px;
`
const TopRow = styled.div`
    border-bottom: 0.5px solid ${AppColors.APP_GRAY_LIGHT};
    padding: 14px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex: 1;
`
const Title = styled.div`
    font-size: 17px;
    font-weight: 700;
`
const MainLine = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 24px;
    padding-left: 24px;
`
