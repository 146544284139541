/**
 * Our own wrapper for AsyncStorage
 */

let React = (window.React = require('react'))

let Config = (window.Config = require('../../config'))

let IS_MOBILE = Config.PLATFORM === 'react-native'

let storageComponent = (window.storageComponent = Config.STORAGE
    ? Config.STORAGE
    : localStorage)

let Utils = (window.Utils = require('../cl-utils'))

let TAG = 'CLStorage'

let RESPONSE_STORAGE_PREFIX = '@response-key-'
let LOGIN_CRED_STORAGE_PREFIX = '@login-cred-key'

class CLStorage {
    /**
     * Store an API response in local storage
     * @param  {string} requestUrl    the request url
     * @param  {string} type          POST or GET
     * @param  {object} requestConfig request config object
     * @param  {object} data          response data
     * @return {Promise}
     */
    static storeResponse(requestUrl, type, requestConfig, data) {
        let key = CLStorage._generateResponseStorageKey(
            requestUrl,
            type,
            requestConfig,
        )
        let cache = {
            data: data,
            requestConfig: requestConfig,
            timestamp: new Date().getTime(),
        }
        //console.log(TAG, '_storeResponse', 'storing key', key, 'data', cache);

        console.log(TAG, ' _storeResponse', 'storing key', key, 'data', cache)

        if (IS_MOBILE) {
            return storageComponent.setItem(key, JSON.stringify(cache))
        } else {
            return new Promise((resolve, reject) => {
                resolve(storageComponent.setItem(key, JSON.stringify(cache)))
            })
        }
    }

    /**
     * Fetch an API response from local storage
     * @param  {string} requestUrl
     * @param  {string} type          POST or GET
     * @param  {object} requestConfig
     * @return {Promise}
     */
    static fetchResponse(requestUrl, type, requestConfig) {
        let key = CLStorage._generateResponseStorageKey(
            requestUrl,
            type,
            requestConfig,
        )
        //return storageComponent.getItem(key);

        if (IS_MOBILE) {
            return storageComponent.getItem(key)
        } else {
            return new Promise((resolve, reject) => {
                resolve(storageComponent.getItem(key))
            })
        }
    }

    /**
     * Store login creds in local storage
     * @param  {string} type   email|facebook|google|etc.
     * @param  {object} params based on type
     *                 (e.g. email object is { email: <email>, password: <password>})
     * @return {Promise}
     */
    static storeLoginCredentials(type, params) {
        let key = LOGIN_CRED_STORAGE_PREFIX
        let value = JSON.stringify({type: type, params: params})
        console.log(TAG, '_storeLoginCredentials, storing', value)

        console.log(
            TAG,
            '_storeLoginCredentials, storing key',
            key,
            'value',
            value,
        )
        return storageComponent.setItem(key, value)
    }

    /**
     * Fetch login credentials from local storage
     * @return {Promise}
     */
    static fetchLoginCredentials() {
        let key = LOGIN_CRED_STORAGE_PREFIX

        console.log(';;', storageComponent.getItem(key))

        console.log(storageComponent)

        if (IS_MOBILE) {
            return storageComponent.getItem(key)
        } else {
            console.log('returning keys: ', storageComponent.getItem(key))
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    console.log(
                        'delayed storage creds key: ' +
                            key +
                            ', val: ' +
                            storageComponent.getItem(key),
                    )

                    resolve(storageComponent.getItem(key))
                }, 1000)
            })
        }
    }

    /**
     * Clear login credentials from local storage
     * @return {Promise}
     */
    static clearLoginCredentials() {
        let key = LOGIN_CRED_STORAGE_PREFIX
        return storageComponent.removeItem(key)
    }

    /**
     * Generates a local storage (cache) unique key
     * @return {string}        a unique key based on the method, request type, and request body
     */
    static _generateResponseStorageKey(requestUrl, type, requestConfig) {
        let k =
            RESPONSE_STORAGE_PREFIX +
            ':' +
            requestUrl +
            ':' +
            type +
            ':' +
            JSON.stringify(requestConfig)
        let hash = Utils.md5(k)
        return hash
    }

    static setItem(key, value) {
        return storageComponent.setItem(key, value)
    }

    static getItem(key, delay = 100) {
        if (IS_MOBILE) {
            return storageComponent.getItem(key)
        } else {
            //console.log("returning keys: ",storageComponent.getItem(key));
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    //console.log("delayed storage creds key: "+key+", val: "+storageComponent.getItem(key));

                    resolve(storageComponent.getItem(key))
                }, delay)
            })
        }
    }

    static removeItem(key) {
        return storageComponent.removeItem(key)
    }
}

export default CLStorage
