import React from 'react'
import styled from 'styled-components'
import {bearImage, bullImage} from '@images'
import {AppColors} from '@services/styles'
import {ContentClear} from 'material-ui/svg-icons'

const Text = styled.span`
    color: ${(p) => p.color || AppColors.APP_BLACK};
    font-size: ${(p) => p.size || 16}px;
`
const TableSymbolImage = styled.img.attrs({
    width: 30,
})``
export const BearBullImage = ({type}) =>
    type == 'N/A' ? (
        <span>N/A</span>
    ) : (
        <TableSymbolImage
            src={type == 'long' ? bullImage.default : bearImage.default}
        />
    )

const BasedElement = ({name, value, color, ...props}) => {
    return (
        <div {...props}>
            <Text size={30}>{value}</Text>
            <Text size={20}>{name}</Text>
        </div>
    )
}
const SBaseElement = styled(BasedElement)`
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const BasedElementWrapper = ({name, value, color, ...props}) => (
    <div {...props}>
        <SBaseElement name={name} value={value} color={color} />
    </div>
)
const StyledBasedElementWrapper = styled(BasedElementWrapper)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${Text}:first-child {
        color: ${(p) => p.color};
        font-weight: ${(p) => (p.color ? '400' : 'auto')};
    }
`
export {StyledBasedElementWrapper as BasedElement}
export const BasedBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 40px 0px 40px 0px;

    ${StyledBasedElementWrapper} {
        border-right: 1px solid ${AppColors.APP_GRAY_LIGHT};
    }
    ${StyledBasedElementWrapper}:last-child {
        border-right: none;
    }
`
// const UNStyledHeader = ({analystName, sector, onClose, ...props}) => {
//     return (
//         <div {...props}>
//             <Text>
//                 Based on {analystName}'s past performance in similar {sector}{' '}
//                 companies:
//             </Text>
//         </div>
//     )
// }
// export const Header = styled(UNStyledHeader)`
//     display: flex;
//     justify-content: space-between;
//     ${Text} {
//         color: ${AppColors.APP_GRAY};
//     }
// `
// export const BasedOn = styled(Text).attrs({size: 18})`
//     color: ${AppColors.APP_BLACK};
//     font-weight: 500;
// `
