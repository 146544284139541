import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

// import TextField from 'material-ui/TextField'
// import RaisedButton from 'material-ui/RaisedButton'
// import Snackbar from 'material-ui/Snackbar'
import CircularProgress from 'material-ui/CircularProgress'

import * as ApiActions from '@store/actions/api-actions'
import {getCurrentUser} from '@store/actions'
import {AppColors} from '@services/styles'

import isEmail from 'validator/lib/isEmail'
import Auth from '../cl_modules/cl-auth'
import {api} from '@services/api'
import DataService from '@services/data'

// import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator'

import {getUrlParams} from '@services/utils'
import Swal from 'sweetalert2'
import Analytics from '@services/analytics'
import {useState, useEffect, useDispatch} from '@services/hooks'
import FrameInput from './General/FrameInput'
import {SendButton} from './General/Elements'
import styled from 'styled-components'

const styles = {
    textField: {},
}

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [afterReset, setAfterReset] = useState(false)
    const auth = new Auth()
    const dispatch = useDispatch()
    const ds = new DataService(dispatch)

    //  constructor(props) {
    //     this.state = {
    //         snackbarOpen: false,
    //         snackbarMessage: '',
    //         route: 'login',
    //     }
    // }

    useEffect(() => {
        Analytics.exec('track', 'login page')
        let urlParams = getUrlParams()
        if (
            urlParams &&
            urlParams.newUserAccount &&
            urlParams.newUserAccount == 'newUserAccount'
        )
            Swal.fire({
                text: 'Thank you for registering to insight. Your email is confirmed.\n You may now login with email and password to use the service.',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
            })
    }, [])

    // snackbar(message, type = 'default') {
    //     let color = 'rgba(0, 0, 0, 0.87)'

    //     if (type === 'warning') {
    //         color = '#FF5722'
    //     } else if (type === 'danger') {
    //         color = '#F44336'
    //     }

    //     this.setState({
    //         snackbarOpen: true,
    //         snackbarMessage: message,
    //         snackbarColor: color,
    //     })
    // }

    // handleRequestClose() {
    //     this.setState({
    //         snackbarOpen: false,
    //         snackbarMessage: '',
    //     })
    // }

    const login = () => {
        console.log('start login1')

        if (!email || !password) {
            Swal.fire({
                text: 'Please enter a valid email and password',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
                icon: 'error',
            })
            return
        }

        if (!isEmail(email)) {
            Swal.fire({
                text: 'Please enter a valid email',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
                icon: 'error',
            })
            return
        }

        Analytics.exec('track', 'login click')
        setLoading(true)

        loginRequest()
            .then(
                (response) => {
                    console.log('login_response3', response)
                    // loginNew()
                },
                (error) => {
                    console.log('login_error', error)
                    const link = error.data.msg.indexOf('<a') > -1
                    Swal.fire({
                        text: link ? null : error.data.msg, // add link
                        html: link ? error.data.msg : null,
                        confirmButtonColor: AppColors.TIPIGO_GREEN,
                        icon: 'error',
                    }).then(setLoading(false))
                },
            )
            .finally(() => null)
    }

    // const loginNew = () => {
    //     api.loginNew(email, password).subscribe((res) =>
    //         console.log('v1 loginNew', res),
    //     )
    // }
    const loginNew = () => {
        return new Promise((resolve, reject) => {
            api.loginNew(email, password).subscribe(
                (res) => {
                    console.log('v1 loginNew ok', res)
                    resolve({})
                },
                // new login - for now we resolve also on error as this is not the "main login"
                (err) => {
                    console.log('v1 loginNew error', err)
                    resolve({})
                },
            )
        })
    }

    const loginRequest = () => {
        return new Promise((resolve, reject) => {
            auth.login(email, password, true).subscribe(
                (response) => {
                    console.log('v1 login_response1', response)
                    // dispatch(ApiActions.apiResponse('login', response.data))
                    loginNew().then((res) => {
                        console.log('v1 login_response2', res)
                        ds.getCurrentUser()
                        dispatch(getCurrentUser())
                        resolve({})
                    })
                },
                (error) => {
                    reject(error)
                },
            )
        })
    }

    const forgotPassword = () => {
        if (!email) {
            Swal.fire({
                text: 'Please enter a valid email.',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
                icon: 'error',
            })
            return
        }

        if (!isEmail(email)) {
            Swal.fire({
                text: 'Please enter a valid email',
                confirmButtonColor: AppColors.TIPIGO_GREEN,
                icon: 'error',
            })
            return
        }
        setLoading(true)

        api.forgotPassword(email).subscribe(
            (response) => {
                //TODO: is response always success? check
                console.log('forgotPassword request', response)
                // Swal.fire({
                //     text: 'Password Reset Email was sent, Please check your inbox.',
                //     confirmButtonColor: AppColors.TIPIGO_GREEN,
                // })
                setAfterReset(true)
                setLoading(false)
                // dispatch(
                //     ApiActions.apiResponse(
                //         'forgotPassword',
                //         response.data,
                //     ),
                // )
                // resolve(response.data)
            },
            (error) => {
                setLoading(false)
                console.log('forgotPassword request error', error)
            },
        )
        // this.props
        //     .forgotPassword(email)
        //     .then(
        //         (response) => {
        //             // Success, redirect the user to thank you page
        //             console.log('Forgot password request success')
        //             this.setState({route: 'forgotPasswordSuccess'})
        //         },
        //         (error) => {
        //             self.snackbar(
        //                 'Something went wrong. Please check your email and try again',
        //                 'danger',
        //             )
        //         },
        //     )
        //     .finally(() =>
        //         this.setState({
        //             email: '',
        //             password: '',
        //             loading: false,
        //         }),
        //     )
    }

    return (
        <div>
            <div
                style={{
                    padding: 100,
                    paddingBottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                <Main>
                    <Logo src={require('@images/tipigo_logo.png').default} />
                    {afterReset ? (
                        <Text>
                            Password Reset Email was sent, Please go to your
                            inbox.
                            <Forgot onClick={() => setAfterReset(false)}>
                                Back to Login
                            </Forgot>
                        </Text>
                    ) : (
                        <div>
                            <FrameInput
                                title={'Email'}
                                value={email}
                                setValue={setEmail}
                                disabled={loading}
                            />
                            <FrameInput
                                title={'Password'}
                                value={password}
                                setValue={setPassword}
                                disabled={loading}
                                password
                                style={{marginTop: 15}}
                            />

                            <Forgot onClick={forgotPassword}>
                                Forgot password?
                            </Forgot>

                            <Text style={{marginTop: 15}}>
                                By logging in to Tipigo you declare that you are
                                over 18 years old and that you read and agreed
                                to the{' '}
                                <Terms to={'/terms'}>Terms and EULA</Terms>
                            </Text>

                            {loading ? (
                                <CircularProgress
                                    size={24}
                                    style={{margin: 14}}
                                    color={AppColors.TIPIGO_GREEN}
                                />
                            ) : (
                                <SendButton
                                    onClick={login}
                                    style={{marginTop: 16}}>
                                    Login
                                </SendButton>
                            )}

                            {/* 03.01.24 <NewUser>
                                Don't have an account? Create{' '}
                                <Link
                                    style={{
                                        color: AppColors.TIPIGO_GREEN,
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                    }}
                                    to={'/register'}>
                                    New User
                                </Link>
                            </NewUser> */}
                        </div>
                    )}
                </Main>

                <div
                    style={{
                        flex: 1,
                        maxWidth: 600,
                        padding: 40,
                        paddingTop: 0,
                    }}>
                    <img
                        src={require('@images/bird-ai.png').default}
                        style={{width: '100%'}}
                    />
                </div>

                <div
                    style={{
                        position: 'fixed',
                        height: 60,
                        bottom: 20,
                        width: '50%',
                        textAlign: 'center',
                        color: '#aaa',
                        fontSize: 12,
                    }}>
                    Tipigo &copy; 2024. Please note that the analysts,
                    fundamentals, symbol and market data provided in the system
                    is near real-time and may have slight variations from
                    real-time sources. For access to real-time data, please
                    contact us to inquire about our data package offers. Tipigo
                    Insight does not autonomously generate recommendations. All
                    recommendation data is sourced from professional analyst
                    insights and market data.
                </div>

                {/* <Snackbar
                    open={this.state.snackbarOpen}
                    message={this.state.snackbarMessage}
                    bodyStyle={{backgroundColor: this.state.snackbarColor}}
                    autoHideDuration={4000}
                    onRequestClose={this.handleRequestClose.bind(this)}
                /> */}
            </div>
        </div>
    )
}

export default Login

const Main = styled.div`
    display: flex;
    flex-direction: column;
    width: 280px;
`
const Forgot = styled.div`
    color: ${AppColors.TIPIGO_GREEN};
    cursor: pointer;
    margin-top: 5px;
    font-size: 14px;
`
const Logo = styled.img`
    margin-bottom: 30px;
    width: 140px;
`
const Terms = styled(Link)`
    color: ${AppColors.TIPIGO_GREEN};
    font-size: 14px;
`
const NewUser = styled.div`
    margin-top: 10px;
    font-size: 14px;
`
const Text = styled.div`
    color: ${AppColors.BLACK};
    font-size: 14px;
`
/*const mapDispatchToProps = (dispatch) => {
    const auth = new Auth()
    const ds = new DataService(dispatch)

    return {
        // login: (email, password) => {
        //     return new Promise((resolve, reject) => {
        //         auth.login(email, password, true).subscribe(
        //             (response) => {
        //                 // dispatch(ApiActions.apiResponse('login', response.data))
        //                 ds.getCurrentUser()
        //                 dispatch(getCurrentUser())
        //             },
        //             (error) => {
        //                 reject(error)
        //             },
        //         )
        //     })
        // },
        // forgotPassword: (email) => {
        //     return new Promise((resolve, reject) => {
        //         api.forgotPassword(email).subscribe(
        //             (response) => {
        //                 dispatch(
        //                     ApiActions.apiResponse(
        //                         'forgotPassword',
        //                         response.data,
        //                     ),
        //                 )
        //                 resolve(response.data)
        //             },
        //             (error) => {
        //                 reject(error)
        //             },
        //         )
        //     })
        // },
    }
}*/

//export default connect(null, mapDispatchToProps)(Login)
