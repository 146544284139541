export const API_REQUEST = 'API_REQUEST'
export const API_RESPONSE = 'API_RESPONSE'
export const API_ERROR = 'API_ERROR'

export const MARKET_STATUS_UPDATE = 'MARKET_STATUS_UPDATE'
export const PORTFOLIO_STATUS_UPDATE = 'PORTFOLIO_STATUS_UPDATE'
export const COMMIT_REMOVED_POSITION = 'COMMIT_REMOVED_POSITION'

export const UPDATE_USER_CURRENT_PROFILE = 'UPDATE_USER_CURRENT_PROFILE'
export const UPDATE_USER_CURRENT_FILTERS = 'UPDATE_USER_CURRENT_FILTERS'
export const WATCHLIST_UPDATE = 'WATCHLIST_UPDATE'
export const ANALYST_LIST_UPDATE = 'ANALYST_LIST_UPDATE'
export const SET_UNIVERSES = 'SET_UNIVERSES'

export const SET_REDIRECTED_ONCE_FLAG = 'SET_REDIRECTED_ONCE_FLAG'
