import {api} from '@services/api'

export const setIsFinanceFetching = (isFetching) => {
    return {
        type: 'SET_IS_FINANCE_FETCHING',
        isFetching,
    }
}

export const setFinanceInitialized = (initialized) => {
    return {
        type: 'SET_FINANCE_INITIALIZED',
        initialized,
    }
}

export const setMarketOpen = (marketOpen) => {
    return {
        type: 'SET_MARKET_OPEN',
        marketOpen,
    }
}

export const setFinanceData = (data) => {
    return {
        type: 'SET_FINANCE_DATA',
        data,
    }
}

export const getFinancialData =
    (sessionToken, fetchSilently = false, financeOnly = false, callback) =>
    (dispatch, getState) => {
        console.log('getting_financical')
        let fetchingData = false
        let fetchingProfile = false

        if (!fetchSilently) {
            fetchingData = true

            if (!financeOnly) {
                fetchingProfile = true
            }

            dispatch(setIsFinanceFetching(true))
        }

        let profile = 'session'
        if (typeof sessionToken !== 'string') {
            profile = 'profile'
            sessionToken = sessionToken.profile
        }

        api.getUserFinanceInfo(sessionToken, profile).subscribe(
            (response) => {
                dispatch(setFinanceData(response))

                callback && callback()
                fetchingData = false
                if (!fetchingProfile) {
                    dispatch(setIsFinanceFetching(false))
                }
            },
            (error) => {
                dispatch(setFinanceData(null))
                callback && callback()
                fetchingData = false
                if (!fetchingProfile) {
                    dispatch(setIsFinanceFetching(false))
                }
            },
        )

        if (financeOnly !== true) {
            // Get portfolio/user data
            api.getUserProfileData().subscribe(
                (response) => {
                    console.log('getUserProfileData', response)
                    dispatch(setFinanceData(response))
                    fetchingProfile = false
                    if (!fetchingData) {
                        dispatch(setIsFinanceFetching(false))
                    }
                },
                (error) => {
                    dispatch(setFinanceData(null))
                    fetchingProfile = false
                    if (!fetchingData) {
                        dispatch(setIsFinanceFetching(false))
                    }
                },
            )
        }
    }
