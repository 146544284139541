import React, {Component, PropTypes} from 'react'

import RaisedButton from 'material-ui/RaisedButton'
import SuccessDone from 'material-ui/svg-icons/action/done'
import {LARGE} from 'material-ui/utils/withWidth'

class BaseEdit extends Component {
    constructor(props) {
        super(props)

        this.displayName =
            this.props.displayName === undefined ? true : this.props.displayName

        this.state = {
            item: {},
            isDone: false,
        }
    }

    componentDidUpdate(prevProps) {
        let {item} = this.state
        let {data, id, isFetching, onEditDone} = this.props

        //console.log('componentDidUpdate', item, data, id)

        if (prevProps.isFetching && !this.props.isFetching) {
            onEditDone()
        }

        if (id === 'new') {
            return
        }

        if (item.id !== id && data && data.length > 0) {
            this.setState({item: data.find((d) => d.id === id)})
        }
    }

    render() {
        // let mode ="account"

        let {data, id, itemForm, mode, onUpdateItem, isSuccess} = this.props
        let {item, isDone} = this.state

        console.log('item', item, id, mode)

        return (
            <div>
                {/* {JSON.stringify(item)} */}
                <h3>
                    {(id === 'new' ? 'New' : 'Edit') +
                        ' ' +
                        mode.charAt(0).toUpperCase() +
                        mode.slice(1)}
                </h3>
                {this.displayName && (
                    <h1>{item && (item.name || item.email)}</h1>
                )}
                {itemForm(item)}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 30,
                    }}>
                    <RaisedButton
                        primary={true}
                        label="Save Changes"
                        onClick={() => onUpdateItem(item)}
                    />
                    {isSuccess ? (
                        <SuccessDone
                            color="green"
                            style={{width: 40, height: 40}}
                        />
                    ) : null}
                </div>
            </div>
        )
    }
}

export default BaseEdit
