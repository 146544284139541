import React from 'react'
import styled from 'styled-components'
import {useState, useEffect} from '@services/hooks'
import {AppColors} from '@services/styles'
import {api} from '@services/api'
//import {internals} from 'rx'
import moment from 'moment'
import ScrollTable from '@components/General/ScrollTable'
import {ScrollTableStyles} from '@components/General/Elements'

const totalWidth = 1397 // sholud be 1400 but looks bigger than above because of border
const columns = [
    {
        //width: totalWidth / 6,
        accessor: 'amount',
        id: 1,
        Header: () => <span style={{paddingLeft: 15}}>Action</span>,
        Cell: (o) => (
            <span style={{paddingLeft: 15}}>
                {o.value > 0 ? 'Deposit' : 'Withdrawal'}
            </span>
        ),
    },
    {
        //width: totalWidth / 6,
        Header: 'Date',
        accessor: 'date',
        Cell: (o) => moment(o.value).format('MM/DD/YYYY'),
    },
    {
        //width: totalWidth / 6,
        accessor: 'units',
        Header: 'Units',
        Cell: (o) => Math.abs(o.value),
    },
    {
        //width: totalWidth / 6,
        accessor: 'price',
        Header: 'Unit Price',
        Cell: (o) => o.value.toFixed(4),
    },
    {
        //width: totalWidth / 6,
        accessor: 'amount',
        Header: 'Amount',
        Cell: (o) =>
            Math.abs(o.value).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            }),
    },
    {
        //width: totalWidth / 6,
        accessor: 'notes',
        Header: 'Comments',
    },
]

const FundsHistory = ({pId, needRefresh}) => {
    const [fundsActions, setFundsActions] = useState([])

    useEffect(() => {
        getResults()
    }, [pId])

    useEffect(() => {
        if (needRefresh) getResults()
    }, [needRefresh])

    const getResults = () => {
        if (pId) {
            api.getPortfolioFunds(pId).subscribe(
                (response) => {
                    console.log('getPortfolioFunds', response)
                    if (response?.data) {
                        if (response.data.funds) {
                            // old infra
                            const formated = response.data.funds.map((f) => {
                                return {
                                    ...f,
                                    date: f.date.date,
                                }
                            })
                            setFundsActions(formated)
                        } else {
                            // TODO: after move to new remove above part + change date and price col names
                            const formated = response.data
                                .map((f) => {
                                    return {
                                        ...f,
                                        date: moment(
                                            f.created,
                                            'YYYY-MM-DD',
                                        ).toDate(),
                                        price: f.unitPrice,
                                    }
                                })
                                .sort((a, b) => (a.date > b.date ? 1 : -1))
                            setFundsActions(formated)
                        }
                    }
                },
                (error) => {
                    console.log('getPortfolioFunds error', error)
                },
            )
        }
    }

    return (
        <Container>
            <TopRow>Portfolio Funds History</TopRow>
            {fundsActions.length > 0 && (
                <ScrollTable
                    columns={columns}
                    data={fundsActions}
                    //hiddenCols={hidden}
                    TableStyles={ScrollTableStyles}
                    //height={tableHeight}
                    maxWidth={totalWidth}
                />
            )}
        </Container>
    )
}

export default FundsHistory

const Container = styled.div`
    margin-top: 30px;
`

const TopRow = styled.div`
    margin-bottom: 15px;
    padding-left: 18px;
    font-size: 17px;
    font-weight: 700;
`
const ListMain = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 14px;
`
const ListItem = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
`
