import React from 'react'
import styled from 'styled-components'
import swal from 'sweetalert2'

import {useState, useEffect, useUser} from '@services/hooks'
//import InsightTable from '@components/General/InsightTable'
import ScrollTable from '@components/General/ScrollTable'

//import {Link} from 'react-router-dom'
import moment from 'moment'
import CircularProgress from 'material-ui/CircularProgress'

import {api} from '@services/api'
import {AppColors} from '@services/styles'
import {MoreVert} from '@mui/icons-material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import BulkAddPositions from './BulkAddPositions'
import ClosePosition from './ClosePosition'
import Symbol2Bench from './Symbol2Bench'
import InlineNotification from './InlineNotification'
import {ReactComponent as notes} from '@images/notes.svg'
import {Dimensions} from '@services/styles'
import {Link} from 'react-router-dom'
import {
    ScrollTableStyles,
    SymbolInTable,
    ColoredCell,
    EarningDays,
    Checkbox,
} from '@components/General/Elements'
import EmptyList from '@components/General/EmptyList'
// import {ReactComponent as Warn} from '@images/warning.svg'
import {getDaysDiff} from '@services/utils'

// #scroll - use width on each column. default is in ScrollTable
// table doesn't know how to 100%
// container has a fixed width and so are all the columns to match that... this is not good, better to find how to 100% table
// fix 05.23 give scroll table maxWidth, give width only to cols that we need extra wide or narrow. scrollTable will dvide the rest
const totalWidth = 1397
const col_width = {
    icon: 0.03,
    //symbol: 0.064,
    //pos_type: 0.046,
    sector: 0.09,
    //entry_date: 0.075,
    //entry: 0.067,
    //last_price: 0.067,
    //current_val: 0.065,
    //quantity: 0.048,
    //earning: 0.071,
    //percent_file: 0.067,
    //pl: 0.081,
    //pl_per: 0.06875,
    //etf_pl_per: 0.0687,
    //daily: 0.06,
    //close_price: 0.07,
    //close_date: 0.07,
    notes: 0.0328,
    close_btn: 0.0273,
}

const hideForOpen = ['close_price', 'close_date']
const hideForClose = [
    'close_btn',
    'last_price',
    'daily_change',
    'current_val',
    'percent_file',
    'pl_per',
    'etf_pl_per',
    'earning',
    'icon',
    'select',
]
// const isSetting = (set, settings) => {
//     if (settings && settings.find((s) => s.type == set)) {
//         const thisSet = settings.filter((s) => s.type == set)[0]
//         return thisSet.value ? thisSet.value : true
//     } else return false
// }

// const warnSign = (obj, monitorSettings) => {
//     var tip = ''
//     var color = AppColors.YELLOW_TEXT // red will override
//     const markEarning = isSetting('earning', monitorSettings)
//     const markMa50 = isSetting('ma50', monitorSettings)
//     var markEtf = isSetting('etf', monitorSettings)
//     const markSl = isSetting('sl', monitorSettings)
//     if (markEtf === true) markEtf = 10
//     const {days, words} = getDaysDiff(obj.row.original.earning)
//     if (markEarning && days && days < 15 && days > -2) {
//         tip +=
//             'Earning date ' +
//             moment(obj.row.original.earning).format('MMMM D, YYYY')
//     }
//     const etf_pl = obj.row.original.etf_pl_per
//     const pos_pl = obj.row.original.pl_per
//     if (markEtf && etf_pl && pos_pl && etf_pl >= pos_pl + markEtf) {
//         if (tip !== '') tip += '<br/>'
//         tip += 'Under the Benchmark etf'
//     }
//     const ma = obj.row.original.ma50
//     const last = obj.row.original.last_price
//     if (markMa50 && ma && ma != '' && last && ma > last) {
//         if (tip !== '') tip += '<br/>'
//         tip += 'Under the Moving Average 50'
//     }
//     if (markSl && pos_pl && pos_pl * -1 > markSl) {
//         if (tip !== '') tip += '<br/>'
//         tip += 'Under the defined stoploss'
//     }

//     if (tip) {
//         return (
//             <Warn
//                 color={color}
//                 data-tip={tip}
//                 style={{outline: 'none'}}
//                 data-for="table_tool_path"
//             />
//         )
//     }
//     return null
// }

const Positions = ({
    pId,
    open,
    // refreshList,
    // setRefreshList,
    setNeedRefresh,
    needRefresh,
    selected,
    setSelected,
    lastDeposite,
    lastMonthNoti,
    // setPlOpen,
    // setPlOpenMoney,
    // setTotalOpen,
    // 10.2023 setRefreshAllCharts,
    // 10.2023 setMinDate,
}) => {
    const allColumns = [
        {
            accessor: 'select',
            Header: (o) => (
                <Checkbox
                    onChange={(e) => toggleSelectAll(e.target.checked)}
                    checked={selectAll}
                />
            ),
            disableSortBy: true,
            width: col_width.icon * totalWidth,
            Cell: (o) => (
                <Checkbox
                    onChange={(e) => selectRow(o.row.original)}
                    checked={
                        selected &&
                        selected.some((item) => item.id == o.row.original.id)
                    }
                />
                //<Checkbox
                // checked={o.row.original.selected}
                // onChange={(e) => {
                //     selectItem(o.row.original.id, e.target.checked)
                // }}
                ///>
            ),
        },
        {
            accessor: 'icon',
            width: col_width.icon * totalWidth,
            Cell: (o) => (
                <InlineNotification
                    pos={o}
                    monitorSettings={monitorSettings}
                    lastMonthNoti={lastMonthNoti}
                />
            ), //warnSign(o, monitorSettings),
        },
        {
            accessor: 'symbol',
            Header: () => <span style={{paddingLeft: 10}}>Symbol</span>,
            Cell: (s) => (
                <Link
                    to={'/stock/' + s.value}
                    style={{textDecoration: 'none'}}
                    target="_blank">
                    <SymbolInTable
                        style={{paddingLeft: 10}}
                        data-tip={s.row.original.company}
                        data-for="table_tool_path">
                        {s.value}
                    </SymbolInTable>
                </Link>
            ),
        },
        {
            accessor: 'pos_type',
            Header: 'Type',
        },
        {
            accessor: 'sector',
            Header: 'Sector',
            width: col_width.sector * totalWidth,
        },
        {
            accessor: 'entry_date',
            Header: () => (
                <span>
                    Entry
                    <br />
                    date
                </span>
            ),
            Cell: (o) => (
                <div
                    data-for="table_tool_path"
                    data-tip={moment(o.value.date).format('MMMM D, YYYY')}>
                    {o.value ? moment(o.value.date).format('MM/DD/YY') : ''}
                </div>
            ),
        },
        {
            accessor: 'entry',
            //width: col_width.entry * totalWidth,
            Header: () => (
                <span>
                    Entry
                    <br />
                    Price
                </span>
            ),
            Cell: (s) => s.value.toFixed(2),
        },
        {
            accessor: 'quantity',
            Header: 'Qty',
            //width: col_width.quantity * totalWidth,
        },
        {
            //width: col_width.last_price * totalWidth,
            accessor: 'last_price',
            Header: () => (
                <span>
                    Last
                    <br />
                    Price
                </span>
            ),
            Cell: (o) => o.value.toFixed(2),
        },
        {
            accessor: 'current_val',
            //width: col_width.current_val * totalWidth,
            Header: () => <span>Value</span>,
            Cell: (s) => new Intl.NumberFormat('en-US', {}).format(s.value),
        },
        {
            accessor: 'earning',
            Header: 'Earning',
            Cell: (o) => {
                const {days, words} = getDaysDiff(o.value)

                return (
                    <div>
                        <EarningDays
                            days={days}
                            data-for="table_tool_path"
                            data-tip={moment(o.value).format('MMMM D, YYYY')}>
                            {words}
                        </EarningDays>
                    </div>
                )
            },
        },
        {
            accessor: 'percent_file',
            //width: col_width.percent_file * totalWidth,
            Header: () => (
                <span>
                    Rel
                    <br />
                    Weight
                </span>
            ),
            Cell: (s) => <>{s && s.value ? s.value.toFixed(2) + '%' : ''}</>,
        },
        {
            accessor: 'pl',
            Header: 'P&L',
            sortType: 'basic',
            Cell: (s) =>
                new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(s.value),
        },
        {
            accessor: 'pl_per',
            Header: 'P&L(%)',
            sortType: 'basic',
            Cell: (s) => (
                <ColoredCell val={s.value}>{s.value.toFixed(2)}%</ColoredCell>
            ),
        },
        {
            accessor: 'etf_pl_per',
            Header: () => (
                <span>
                    Benchmark
                    <br />
                    P&L(%)
                </span>
            ),
            sortType: 'basic',
            Cell: (s) => (
                <div
                    data-for="table_tool_path"
                    data-tip={'etf: ' + s.row.original.etf}>
                    {s.value ? s.value.toFixed(2) + '%' : ''}
                </div>
            ),
        },
        {
            accessor: 'daily_change',
            Header: () => (
                <span>
                    Daily
                    <br />
                    Change
                </span>
            ),
            sortType: 'basic',
            Cell: (s) => (
                <ColoredCell val={s.value}>
                    {s.value ? s.value + '%' : ''}
                </ColoredCell>
            ),
        },
        {
            accessor: 'close_price',
            Header: 'Close price',
        },
        {
            accessor: 'close_date',
            Header: 'Close Date',
            // WTF: nothing i tried with sort works... it sorts but not correct (with sortType it does)
            // but here above entry_date is the same but works as default... what is the problem
            sortType: (a, b) => {
                return a.values['close_date'].date > b.values['close_date'].date
                    ? 1
                    : -1
            },
            Cell: (o) => (
                <div>
                    {o.value ? moment(o.value.date).format('MM/DD/YY') : ''}
                </div>
            ),
        },
        {
            accessor: 'notes',
            Header: 'Notes',
            // data-tip for tooltip. tooltip control located in insight table
            Cell: (s) => (
                <>
                    {s.value ? (
                        <Notes data-tip={s.value} data-for="table_tool_path" />
                    ) : null}
                </>
            ),
            disableSortBy: true,
            width: col_width.notes * totalWidth,
        },
        {
            accessor: 'close_btn',
            Header: '',
            disableSortBy: true,
            width: col_width.close_btn * totalWidth,
        },
    ]
    //}
    const openCols = allColumns.filter(
        (col) => !hideForOpen.includes(col.accessor),
    )
    const closeCols = allColumns.filter(
        (col) => !hideForClose.includes(col.accessor),
    )
    const [posData, setposData] = useState(null)
    const [hidden, setHidden] = useState(open ? hideForOpen : hideForClose)
    const [selectedPos, setSelectedPos] = useState(null)
    const [anchorEdit, setAnchorEdit] = useState(null)
    const openEdit = Boolean(anchorEdit)
    const [editPosDialog, setEditPosDialog] = useState(false)
    const [closePosDialog, setClosePosDialog] = useState(false)
    const [compareSymDialog, setCompareSymDialog] = useState(false)
    const [apiData, setApiData] = useState(null)
    const [tableHeight, setTableHeight] = useState(400)
    // const [cols, setCols] = useState([])
    // const [selected, setSelected] = useState([])
    const [monitorSettings, setMonitorSettings] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [canEditPos, setCanEditPos] = useState(true)
    const user = useUser()

    const openEditMenu = (e, pos) => {
        console.log('openEditMenu', pos)
        setAnchorEdit(e.currentTarget)
        setSelectedPos(pos)
    }
    useEffect(() => {
        setHidden(open ? hideForOpen : hideForClose)
    })

    useEffect(() => {
        setTableHeight(
            window.innerHeight -
                Dimensions.NAVBAR_TOP_OFFSET -
                Dimensions.APP_BAR_HEIGHT -
                200,
        )
        // close is static, load it once and there are no actions
    }, [])

    // useEffect(() => {
    //     setApiData(null)
    //     // on first load pId exists but refreshList is false
    //     if (refreshList || pId) {
    //         loadPositions()
    //         setRefreshList && setRefreshList(false)
    //     }
    // }, [refreshList, pId])

    useEffect(() => {
        // is it safe: useEffect on needRefresh on parent sets it bsck to flase
        // will this fire anyway?
        setApiData(null)
        if (needRefresh && pId) {
            loadPositions()
        }
    }, [needRefresh, pId])

    useEffect(() => {
        if (selectedPos && lastDeposite)
            setCanEditPos(
                moment(selectedPos.entry_date.date).isSameOrAfter(lastDeposite),
            )
        else setCanEditPos(true)
    }, [selectedPos])

    const loadPositions = () => {
        setSelectedPos(null)
        console.log('do refresh list', open)
        if (open) {
            console.log('before got_positions0')
            api.getUserPortfolioOpenPositions(pId).subscribe(
                (response) => {
                    console.log('got_positions2', response.data)
                    setApiData(response.data)
                },
                (error) => {
                    console.log(
                        'got_postions getUserPortfolioOpenPositions error',
                        error,
                    )
                },
            )
        } else {
            console.log('got_postions3 get closed')
            api.getUserPortfolioClosedPositions(pId).subscribe(
                (response) => {
                    console.log('got_postions3', response.data)
                    setApiData(response.data)
                },
                (error) => {
                    console.log(
                        ' got_postions getUserPortfolioClosedPositions error',
                        error,
                    )
                },
            )
        }
    }

    useEffect(() => {
        if (apiData) {
            // setCols(getColumns(apiData.monitor_settings, testFunc))
            setMonitorSettings(apiData.monitor_settings)
            // allow [] in posData. it is not the same as null
            setposData(apiData?.list ? apiData.list : null)
            setHidden(open ? hideForOpen : hideForClose)
            console.log('api_data', open, apiData)
        } else {
            setposData(null)
        }
    }, [apiData])

    const editPosition = () => {
        setAnchorEdit(null)
        setEditPosDialog(true)
    }

    const deletePosition = () => {
        setAnchorEdit(null)
        swal.fire({
            text: 'Delete this Position?',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            confirmButtonColor: AppColors.TIPIGO_GREEN,
        }).then((response) => {
            if (response && response.isConfirmed) {
                api.deleteUserPortfolioPosition([selectedPos.id]).subscribe(
                    (response) => {
                        console.log('afterdelete', response)
                        if (response.data && response.data.status) {
                            // setRefreshList(true)
                            setNeedRefresh(true)
                        } else {
                            swal.fire({
                                text: 'error while deleting position',
                                icon: 'error',
                                confirmButtonColor: AppColors.TIPIGO_GREEN,
                            })
                        }
                    },
                    (error) => {
                        swal.fire({
                            text: 'error deleting position',
                            icon: 'error',
                            confirmButtonColor: AppColors.TIPIGO_GREEN,
                        })
                        console.log('deletePosition error', error)
                    },
                )
            }
        })
    }

    const closePosition = () => {
        setAnchorEdit(null)
        setClosePosDialog(true)
    }

    const data =
        posData &&
        posData.map((pos) => {
            // can  override cell here, but better do in in column array, if done here sorting may not work right
            return {
                ...pos,
                stam: 111,
                daily_change: parseFloat(pos.daily_change),
                close_btn: <MoreVert onClick={(e) => openEditMenu(e, pos)} />,
            }
        })

    const selectRow = (pos) => {
        if (selected.some((item) => item.id == pos.id)) {
            setSelected(selected.filter((item) => item.id !== pos.id))
        } else {
            const pos2Select = posData.find((item) => item.id == pos.id)
            if (pos2Select) setSelected([...selected, pos2Select])
        }
    }
    const toggleSelectAll = (selected) => {
        setSelectAll(selected)
        if (selected) {
            setSelected([...posData])
        } else {
            setSelected([])
        }
    }

    useEffect(() => {
        console.log(selected)
        if (selected?.length < posData?.length) {
            // when not ALL selected uncheck header w/o unchecking all others (ui decision)
            setSelectAll(false)
        }
    }, [selected, posData])

    return (
        <Container>
            <BulkAddPositions
                pId={pId}
                pos={selectedPos}
                userId={user.user_id}
                isOpenDialog={editPosDialog}
                setIsOpenDialog={setEditPosDialog}
                setNeedRefresh={setNeedRefresh}
                minDate={lastDeposite}
            />
            <ClosePosition
                pId={pId}
                pos={selectedPos}
                userId={user.user_id}
                isOpenDialog={closePosDialog}
                setIsOpenDialog={setClosePosDialog}
                setNeedRefresh={setNeedRefresh}
                minCloseDate={lastDeposite}
            />
            <Symbol2Bench
                pos={selectedPos}
                isOpenDialog={compareSymDialog}
                setIsOpenDialog={setCompareSymDialog}
            />
            {selectedPos && (
                <Menu
                    open={openEdit}
                    anchorEl={anchorEdit}
                    onClose={() => setAnchorEdit(null)}>
                    <StyledMenuItem onClick={closePosition}>
                        Close Position
                    </StyledMenuItem>

                    <StyledMenuItem
                        canedit={canEditPos ? 1 : 0}
                        onClick={canEditPos ? editPosition : undefined}>
                        Edit Position
                    </StyledMenuItem>
                    <StyledMenuItem
                        canedit={canEditPos ? 1 : 0}
                        onClick={canEditPos ? deletePosition : undefined}>
                        Delete Position
                    </StyledMenuItem>

                    <StyledMenuItem
                        canedit={selectedPos.symbol !== selectedPos.etf ? 1 : 0}
                        onClick={() => {
                            setAnchorEdit(null)
                            setCompareSymDialog(true)
                        }}>
                        Compare to Benchmark
                    </StyledMenuItem>

                    <StyledMenuItem
                        component={Link}
                        // is it safe? selectedPos is state and might not be ready yet?
                        to={'/stock/' + selectedPos.symbol}>
                        Symbol's Page
                    </StyledMenuItem>
                </Menu>
            )}

            {posData && posData.length > 0 ? (
                <ScrollTable
                    columns={open ? openCols : closeCols}
                    data={data}
                    hiddenCols={hidden}
                    TableStyles={ScrollTableStyles}
                    sortDefault={'entry_date'}
                    height={tableHeight}
                    maxWidth={totalWidth}
                />
            ) : posData && posData.length == 0 ? (
                <EmptyList type={open ? 'noOpenPos' : 'noClosePos'} />
            ) : (
                <CircularProgress />
            )}
        </Container>
    )
}

export default Positions

const Notes = styled(notes)``
const Container = styled.div`
    display: flex;
    flex: 1;
    border: 0px solid pink;
    justify-content: center;
`
// Received `false` for a non-boolean attribute `active`. is B.S ? (when pass false to canedit)
const StyledMenuItem = styled(MenuItem)`
    font-size: 15px !important;
    margin-top: 8px !important;
    color: ${({canedit}) =>
        canedit === 0
            ? AppColors.APP_GRAY_LIGHT
            : AppColors.APP_BLACK}!important;
`
//
// const MenuStyles = createGlobalStyle`
// .MuiMenuItem-root {
//     font-size: 11px;
// }
// `
// const EarningDays = styled.div`
//     background-color: ${(p) =>
//         p.days !== '' && p.days !== false && p.days < 15 ? '#ffe9e9' : 'unset'};
//     padding-top: 2px;
//     padding-right: 4px;
//     padding-left: 4px;
//     border-radius: 4px;
//     display: inline-block;
// `

//#scroll: to use with InsightTable
// const TableStyles_4_insightTable = styled.div`
//     table {
//         //position: relative;
//         //margin: auto;
//         font-family: 'Inter';
//         border-collapse: separate;
//         border-spacing: 0 8px;
//         border: 0px solid; // outer border
//         tr {
//             box-shadow: 0px 0px 6px rgb(56 66 73 / 10%);
//             border-radius: 4px;
//             height: 40px;
//             background: #ffffff;
//         }
//         thead {
//             box-shadow: unset;
//             border: 1px solid green;
//             border-spacing: 0 14px;
//             th {
//                 text-align: center;
//                 font-style: normal;
//                 font-weight: normal;
//                 font-size: 12px;
//                 //line-height: 25px;
//                 //text-transform: uppercase;
//                 //overflow-wrap: break-word;
//                 border-right: 0px;
//                 position: sticky;
//                 top: 0px;
//             }
//             tr {
//                 box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
//                 height: unset;
//                 background: #e9eaec;
//                 height: 29px;
//             }
//         }

//         td {
//             text-align: center;
//             font-style: normal;
//             font-weight: normal;
//             font-size: 13px;
//             line-height: 20px;
//         }
//     }
// `
