import React, {Component} from 'react'
import styled from 'styled-components'
import {ScreenersInfo} from '@constants/Finance'
import {useState, useEffect} from '@services/hooks'
import FrameInput from '@components/General/FrameInput'
import FrameSelect from '@components/General/FrameSelect'
import {SendButton, SendButtonDis} from '@components/General/Elements'
import {DeleteOutline, CopyAll, HelpOutline} from '@mui/icons-material'
import ReactTooltip from 'react-tooltip'
import {api} from '@services/api'
import swal from 'sweetalert2'
import {AppColors} from '@services/styles'

const FilterUnit = ({screener, setLoading, setReloadAll, currentSector}) => {
    // change screener values localy
    // add svae buttun to each screener
    // when save update db and screener object - for that need to set entire profile or re-pull from db
    // becuse EditSectors is a useState object

    //  both may be null. use 0?
    const [minVal, setMinVal] = useState(screener.minVal)
    const [maxVal, setMaxVal] = useState(screener.maxVal)
    // range type is totaly local and used only for UI (but i think it is saved in db tough we dont need it - ..)
    const rangeTypes = [
        {label: 'greater than', value: 1},
        {label: 'less than', value: -1},
        {label: 'between', value: 0},
    ]
    const [rangeType, setRangeType] = useState(-10)

    useEffect(() => {
        console.log('my_screener', screener)
    }, [])

    const saveChanges = () => {
        setLoading(true)
        // TODO: multi
        // TODO: conditions to allow save
        api.saveScreener(
            currentSector.sector_ref_id,
            screener.field,
            rangeType == 1 || rangeType == 0 ? minVal : false,
            rangeType == -1 || rangeType == 0 ? maxVal : false,
        ).subscribe(
            (response) => {
                console.log('saveScreener response', response)
                setReloadAll(true)
            },
            (error) => {
                console.log('saveScreener error', error)
                swal.fire({
                    text: 'error updateing filter',
                    icon: 'error',
                    confirmButtonColor: AppColors.TIPIGO_GREEN,
                })
            },
        )
    }

    const deleteScreener = () => {
        swal.fire({
            title: 'Delete filter?',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: AppColors.TIPIGO_GREEN,
            confirmButtonText: 'Delete',
        }).then(
            (response) => {
                if (response && response.isConfirmed) {
                    setLoading(true)
                    api.deleteScreener(
                        currentSector.sector_ref_id,
                        screener.field,
                    ).subscribe(
                        (response) => {
                            console.log('deleteScreener response', response)
                            setReloadAll(true)
                        },
                        (error) => {
                            console.log('deleteScreener error', error)
                        },
                    )
                }
            },
            (error) => {
                console.log('cancelled')
            },
        )
    }

    useEffect(() => {
        if (screener) {
            // set range type by min and max - only once at start, after that he sets them
            // otherwise we get a stupid cycle
            setRangeType(0)
            if (screener.minVal && !screener.maxVal) {
                setRangeType(1)
            } else if (screener.maxVal && !screener.minVal) {
                setRangeType(-1)
            }
        }
    }, [screener])

    return (
        screener && (
            <Container>
                <ReactTooltip />
                {ScreenersInfo[screener.field] && (
                    <FilterType>
                        <FilterName>
                            {ScreenersInfo[screener.field].title}
                            &nbsp;&nbsp;
                            {ScreenersInfo[screener.field].description ? (
                                <HelpIcon
                                    data-tip={
                                        ScreenersInfo[screener.field]
                                            .description
                                    }
                                />
                            ) : null}
                        </FilterName>
                        {screener.field == 'market_val' && (
                            <FilterSubtitle>(in millions)</FilterSubtitle>
                        )}
                    </FilterType>
                )}
                <FrameSelect
                    title={'range type'}
                    value={rangeType}
                    values={rangeTypes}
                    setValue={setRangeType}
                    style={{width: 160, marginRight: 15}}
                />
                <FrameInput
                    title={'from value'}
                    value={minVal}
                    setValue={setMinVal}
                    disabled={rangeType == -1}
                    style={{width: 90, marginRight: 15}}
                />
                <FrameInput
                    title={'to value'}
                    value={maxVal}
                    setValue={setMaxVal}
                    disabled={rangeType == 1}
                    style={{width: 90, marginRight: 15}}
                />
                <SendButton
                    onClick={saveChanges}
                    style={{alignSelf: 'end', marginRight: 15}}>
                    Save Changes
                </SendButton>
                <DeleteCont>
                    <DeleteIcon onClick={deleteScreener} />
                </DeleteCont>
            </Container>
        )
    )
}

export default FilterUnit

const Container = styled.div`
    border: 1px solid #bbb;
    margin-bottom: 15px;
    display: flex;
    align-items: end;
    padding: 10px;
    padding-bottom: 15px;
`
const FilterType = styled.div`
    width: 100px;
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    height: 52px;
    padding-top: 21px;
    border: 0px solid red;
`
const FilterName = styled.div`
    display: flex;
`
const FilterSubtitle = styled.div`
    font-size: 11px;
`
const HelpIcon = styled(HelpOutline)`
    color: #aaa;
    cursor: pointer;
    margin-left: 12px
    font-size: 18px
`
const DeleteCont = styled.div`
    padding-bottom: 10px;
    border: 0px solid red;
`
const DeleteIcon = styled(DeleteOutline)`
    color: ${AppColors.RED_TEXT};
`
// position: 'absolute',
// top: 0,

/*
const [test, setTest] = useState([{a: 1}, {a: 2}, {a: 3}])
const [elm, setElm] = useState(null)
useEffect(() => {
    if (test && test.length) {
        setElm(test[0]) - A copy. not a referance!
    }
    console.log('myTest1', test)
}, [test])
useEffect(() => {
    console.log('myTest2', test)
}, [elm])
const myFunc = () => {
    setElm({a: 22})
}
*/
