import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import AppBar from 'material-ui/AppBar'
import {
    AppBarNavContainer,
    AppBarNavItem,
    UcContainer,
} from './NavAppBar/NavAppBar.styles'

import {AppColors, Dimensions} from '@services/styles'
//import Menu from '@mui/material/Menu'
//import MenuItem from '@mui/material/MenuItem'
//import {useState} from '@services/hooks'

const NavAppBar = ({user, history, rightElement, changePortfolioModeFunc}) => {
    //const [anchorPort, setAnchorPort] = useState(null)
    //const openPort = Boolean(anchorPort)
    // const openPortMenu = (e, pos) => {setAnchorPort(e.currentTarget)}

    return (
        <>
            <AppBar
                style={{
                    backgroundColor: AppColors.SUB_HEAD_GREY,
                    height: Dimensions.APP_BAR_HEIGHT,
                    position: 'fixed',
                    zIndex: 10,
                    top: Dimensions.NAVBAR_TOP_OFFSET,
                    //paddingRight: Dimensions.PAGE_PADDING_RIGHT, 24.11.21 remove this to better display msg. if problem use state to dicide
                    paddingLeft: Dimensions.PAGE_PADDING_LEFT,
                    boxShadow: 'none',
                    //border: '1px solid cyan',
                    alignItems: 'center', // #stupidhead
                }}
                iconStyleLeft={{marginTop: 0}} // #stupidhead 0. need it because gets 16 from somewhere
                iconElementLeft={
                    // 12.06.22 dont give profile all the other tools. to make Nechemia less confused and to get less questions...
                    user.account_type !== 'profile' ? (
                        <AppBarNavContainer>
                            <AppBarNavItem
                                isActive={getActiveTab(history) === '6'}
                                onClick={() => history.push('/?tab=6')}>
                                Home
                            </AppBarNavItem>

                            <AppBarNavItem
                                isActive={getActiveTab(history) === '0'}
                                onClick={() => history.push('/?tab=0')}>
                                Live Feed
                            </AppBarNavItem>
                            <AppBarNavItem
                                isActive={getActiveTab(history) === '1'}
                                onClick={() => history.push('/?tab=1')}>
                                Watchlist
                            </AppBarNavItem>

                            <AppBarNavItem
                                isActive={getActiveTab(history) === '3'}
                                onClick={() => history.push('/?tab=3')}>
                                Portfolio
                            </AppBarNavItem>

                            <AppBarNavItem
                                isActive={getActiveTab(history) === '4'}
                                onClick={() => history.push('/?tab=4')}>
                                Tools
                            </AppBarNavItem>
                        </AppBarNavContainer>
                    ) : (
                        <AppBarNavContainer>
                            <AppBarNavItem
                                isActive={getActiveTab(history) === 2}
                                onClick={() =>
                                    history.push('/investment-profile')
                                }>
                                Edit Investment Profile
                            </AppBarNavItem>
                        </AppBarNavContainer>
                    )
                }
                iconStyleRight={{marginRight: 5, marginTop: 0}} //#stupidhead marginTop 0. need it because gets 16 from somewhere. 25.11.21 was 50, change for UcContainer. don't think it has any other effect
                iconElementRight={
                    rightElement ? (
                        <AppBarNavContainer>{rightElement}</AppBarNavContainer>
                    ) : (
                        <UcContainer />
                    )
                }
            />
        </>
    )
}

export const getActiveTab = (history) => {
    const {pathname, search} = history.location
    if (pathname === '/') {
        const query = new URLSearchParams(search)

        return query.get('tab') || '6'
    } else if (pathname === '/investment-profile') {
        return 2
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user.user,
    }
}

const NavAppBarConnected = connect(mapStateToProps, null)(NavAppBar)

NavAppBarConnected.defaultProps = {
    onTabClick: () => {
        console.warning('no functionality to onTabClick')
    },
}

export default withRouter(NavAppBarConnected)
