import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Explore from './Explore/ExploreButton'
// import Explore from './Explore' // cancel index.js
import {AppColors} from '../../../services/styles'
import {upsideCalculate} from './Alert'

export default ({
    buy,
    symbol,
    lastPrice,
    dailyChange,
    target,
    time,
    successRate,
    analyst,
    sector,
    alertId,
    //performance_data,
    rate,
    company,
}) => {
    const upside = upsideCalculate(target, lastPrice)
    return (
        <MessageBox>
            <TinyTitle>
                {moment(moment.unix(time)).format('MMMM D, YYYY')}
            </TinyTitle>
            <span>
                {analyst} is{` `}
                <Rating buy={buy} />
                {` `}on{` `}
                <a data-tip={company} href={'#/stock/' + symbol}>
                    {symbol}
                </a>
                {` (`}${lastPrice.toFixed(1)}
                {` `}
                <DailyChange dailyChange={dailyChange}></DailyChange>
                {`) `}
                {`with Target ${target.toFixed(1)} `}
                {`(`}
                <SSpan buy={buy}>{upside.toFixed(1)}%</SSpan>
                {`)`}
            </span>

            {successRate && (
                // alert table -> analyst_performance -> month_averages
                // cut out first 5 (take last 7, but could be less than 7)
                // success_percentage = take highst value of remaining
                // 03.10.22 remove it - it's always confusing M and rasing questions. better without it until do somthing else
                // {successRate.success_percentage.toFixed(0)}%
                // TODO: some b.s by performance_data.score C to A
                <BottomTitle>
                    Based on {analyst}'s success rate in similar companies{' '}
                    {rate && (
                        <Explore
                            symbol={symbol}
                            sector={sector}
                            analystName={analyst}
                            // 29.11.21 analystPerformance={analystPerfomence}
                            successRate={successRate}
                            alertId={alertId}
                            bsRate={rate}
                        />
                    )}
                </BottomTitle>
            )}
        </MessageBox>
    )
}

const SSpan = styled.span`
    color: ${(p) => (p.buy ? AppColors.TIPIGO_GREEN : AppColors.TIPIGO_RED)};
`
const SRating = styled(SSpan)`
    font-weight: bold;
`

const TinyTitle = styled.span`
    color: ${AppColors.APP_GRAY};
    font-size: 14px;
    line-height: normal;
`
const BottomTitle = styled.span`
    color: ${AppColors.APP_GRAY};
    font-size: 16px;
    line-height: normal;
    a {
        font-size: 14px;
    }
`
export const HorizontalBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const VerticalBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const MessageBox = styled(VerticalBox)`
    align-items: flex-start;
    height: 100%;
    padding: 2px 0px;
    box-sizing: border-box;
    a {
        color: #0000ee;
        text-decoration: none;
        cursor: pointer;
    }
`

const DailyChange = ({dailyChange}) => {
    const sign = dailyChange[0] !== '-'
    return dailyChange != '' ? (
        <SSpan buy={sign}>
            {sign && '+'}
            {dailyChange}%
        </SSpan>
    ) : (
        ''
    )
}

const Rating = ({buy}) => (
    <SRating buy={buy}>{buy ? 'BULLISH' : 'BEARISH'}</SRating>
)
