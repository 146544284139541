import React, {Component} from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'

import {Label, Container as UnStyledContainer} from './HomeDrawer.styles'
//import Checkbox from 'material-ui/Checkbox'
import {AppColors} from '../../services/styles'
import {StyledCheckBox} from '@components/General/Elements'

import ArrTransition from '@components/General/ArrayTransition'

import _ from 'lodash'

const ArrayTransition = styled(ArrTransition)``
// const StyledCheckBox = styled(Checkbox)`
//     svg {
//         width: 22px !important;
//         height: 22px !important;
//     }
//     input ~ div {
//         align-items: center;
//     }
// `
const ShowMore = styled.a`
    color: ${AppColors.TIPIGO_BLUE};
    cursor: pointer;
    width: 100%;
    padding-left: 40px;
`
const Container = styled(UnStyledContainer)`
    ${ArrayTransition} {
        margin-top: 10px;
    }
`
const showLimit = 4
class FilterCheckBox extends Component {
    getValFromAlert = () => {
        if (this.props.alerts && this.props.keyBy) {
            const arr = _.union(this.props.alerts.map(this.props.keyBy))
            if (arr.length && Array.isArray(arr[0])) {
                //arr = [[],[],[]...]
                return _.union(arr.flat())
            } else {
                return arr
            }
        } else {
            return []
        }
    }
    state = {
        keys: this.getValFromAlert(),
        // keys: this.props.alerts
        //     ? this.props.keyBy
        //         ? _.union(this.props.alerts.map(this.props.keyBy))
        //         : []
        //     : [],
        filters: this.props.values,
        open: false,
    }

    componentWillReceiveProps(nextProps) {
        // const nextKeys = nextProps.alerts ? _.union(nextProps.alerts.map(nextProps.keyBy)) : []
        // const {keys: currentKeys, filters} = this.state
        // if (!_.isEqual(nextKeys, currentKeys)) {
        //     const nextFilters = _.unionWith(filters, nextProps.values).filter(val => nextKeys.includes(val))
        //     this.updateState({keys: nextKeys, filters: nextFilters})
        // }
        if (!_.isEqual(nextProps.values?.sort(), this.state.filters?.sort())) {
            this.setState({filters: nextProps.values})
        }
    }
    updateState = (nextState) => {
        const onChange = _.isEqual(nextState.filters, this.state.filters)
            ? undefined
            : this.props.onChange
        this.setState(nextState, () => {
            onChange && onChange(nextState.filters)
        })
    }
    handleKeyChange = (key) => {
        let filters = this.state.filters ? this.state.filters.slice() : []
        if (filters.includes(key)) {
            filters = filters.filter((val) => val != key)
        } else {
            filters.push(key)
        }
        this.updateState({filters})
    }
    render() {
        //console.log('filters_value: ', this.props.title, this.props.values)
        const {title, alerts, labelBy, order} = this.props
        const {filters, keys, open} = this.state
        // 16.04.23 == 0 -> >2 becuse cannot filter by all the same
        if (!alerts || keys.length < 2) return null
        //console.log('filters2', filters)
        //if (title === 'Analyst') console.log('keys', keys)
        return (
            <Container>
                <Label>{title}</Label>
                <ArrayTransition
                    length={open ? keys.length : showLimit}
                    estimatedChildheight={24}>
                    {(order ? _.orderBy(keys, (k) => k, order) : keys)
                        .slice(0, open ? keys.length : showLimit)
                        .map((key, i) => (
                            <StyledCheckBox
                                className="checkbox"
                                onCheck={() => this.handleKeyChange(key)}
                                checked={filters && filters.includes(key)}
                                key={`${title}_check_${i}`}
                                label={labelBy ? labelBy(key) : key}
                            />
                        ))}
                </ArrayTransition>
                {keys.length > showLimit && (
                    <ShowMore onClick={(e) => this.setState({open: !open})}>
                        {`see ${open ? 'less' : 'more'}`}
                    </ShowMore>
                )}
            </Container>
        )
    }
}

const mapStateToProps = (state) => ({alerts: state.finance.data.alerts})
export default connect(mapStateToProps)(FilterCheckBox)
