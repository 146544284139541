import React from 'react'
import styled from 'styled-components'
import {useState, useEffect} from '@services/hooks'
import {AppColors} from '@services/styles'
import {ReactComponent as InfoIcon} from '@images/info.svg'
import ReactTooltip from 'react-tooltip'
import {TopBlock} from '@components/General/Elements'

const Totals = ({
    plOpenPer,
    plShortPer,
    plOpenLongMoney,
    plOpenShortMoney,
    openLongTotal,
    openShortTotal,
    cashPool,
    totalInvest,
    unitPrice,
}) => {
    // ATT: if change behavior of middle box pay attention to const and param names - see comments in MyFile
    const [plTotalPer, setPlTotalPer] = useState('')
    //const [plTotalMoney, setPlTotalMoney] = useState('')

    useEffect(() => {
        if (totalInvest && cashPool) {
            console.log(
                'plOpenLongMoney start',
                openLongTotal,
                cashPool,
                plOpenShortMoney,
                totalInvest,
            )
            //&& openLongTotal #sell it may be 0 if all pos are short
            setPlTotalPer(
                ((openLongTotal + cashPool + plOpenShortMoney - totalInvest) /
                    totalInvest) *
                    100,
            )

            if (
                parseInt(openLongTotal * 10000) +
                    parseInt(cashPool * 10000) -
                    parseInt(totalInvest * 10000) !==
                parseInt(plOpenLongMoney * 10000)
            ) {
                console.error(
                    'plOpenLongMoney',
                    'x',
                    openLongTotal,
                    'x',
                    cashPool,
                    'x',
                    totalInvest,
                    'x',
                    plOpenLongMoney,
                )
            }
        } else if (totalInvest && cashPool) {
            console.log('plOpenLongMoney why here?', cashPool, totalInvest)
            setPlTotalPer(((cashPool - totalInvest) / totalInvest) * 100)
        } else {
            console.log('plOpenLongMoney why 2?', totalInvest, cashPool)
        }
    }, [totalInvest, openLongTotal, cashPool, plOpenLongMoney])

    const getBox = (title, total, pl, plMoney, tooltip, last, u_p) => {
        return (
            <TopBlock style={{marginRight: last ? 0 : 40}}>
                <TopPart>
                    <BoxTitle>{title}</BoxTitle>
                    <InfoIcon
                        data-tip={tooltip}
                        data-for="path"
                        width={24}
                        height={24}
                    />
                </TopPart>
                <MainNumber>
                    {total
                        ? total.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                          })
                        : ''}
                </MainNumber>
                <PlPart>
                    {pl && !last ? <PlText>P&L</PlText> : ''}
                    {pl ? (
                        <Pl val={pl}>
                            {(pl > 0 ? '+' : '') +
                                (pl ? pl.toFixed(2) + '%' : '')}
                        </Pl>
                    ) : (
                        ''
                    )}
                    {plMoney ? (
                        <Pl>
                            {plMoney.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}
                        </Pl>
                    ) : (
                        ''
                    )}
                    {u_p && (
                        <Pl>
                            <UnitPrice>unit price:</UnitPrice>
                            {u_p.toLocaleString('en-US', {
                                style: 'currency',
                                minimumFractionDigits: 5,
                                currency: 'USD',
                            })}
                        </Pl>
                    )}
                </PlPart>
            </TopBlock>
        )
    }

    return (
        <Container>
            <ReactTooltip id="path" html={true} />
            {getBox(
                // total file is cashpool + current value of open long pos + pl of short
                // this is because we do not subtract short from pool on opening
                'ASSETS UNDER MANAGEMENT',
                (openLongTotal ? openLongTotal : 0) +
                    cashPool +
                    (plOpenShortMoney ? plOpenShortMoney : 0),
                plTotalPer,
                openLongTotal + cashPool + plOpenShortMoney - totalInvest, //plOpenLongMoney + plOpenShortMoney,
                'Open buy (long) positions + sum of P&L sell (short) positions + Total Cash.',
            )}
            {openLongTotal &&
                getBox(
                    //#sell not sure what here.. removed this from end of description: ,<br /> indicating bullish intent; or sell short securities, signaling bearish intent.
                    'TOTAL LONG POSITIONS',
                    openLongTotal,
                    plOpenPer,
                    plOpenLongMoney,
                    'Sum of all buy (long) positions value.',
                )}
            {openShortTotal
                ? getBox(
                      //#sell new box
                      'TOTAL SHORT POSITIONS',
                      openShortTotal,
                      plShortPer,
                      plOpenShortMoney,
                      'Sum of all sell (short) positions value.',
                  )
                : null}
            {getBox(
                'TOTAL CASH',
                cashPool,
                null,
                null,
                'Cash: The net cash for trade.<br/>Unit price: Portfolio P&L for invested 1 USD.<br/>Units: Based on fund history',
                true,
                unitPrice,
            )}
        </Container>
    )
}

export default Totals

const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 20px;
    //border: 1px solid red;
    //max-width: 1200px;
`

const TopPart = styled.div`
    display: flex;
    justify-content: space-between;
`
const BoxTitle = styled.div`
    display: flex;
    color: ${AppColors.BLACK};
    font-size: 13px;
`
const MainNumber = styled.div`
    display: flex;
    color: ${AppColors.BLACK};
    font-size: 36px;
    margin-top: 11px;
    padding-bottom: 10px;
`
const PlPart = styled.div`
    display: flex;
    font-size: 21px;
    display: flex;
    padding-top: 14px;
`
const PlText = styled.span`
    font-size: 18px;
    margin-right: 3px;
`
const UnitPrice = styled.div`
    font-size: 19px;
    color: ${AppColors.BLACK};
    padding-right: 5px;
`
const Pl = styled.div`
    margin-right: 10px;
    display: flex;
    color: ${(p) =>
        p.val
            ? p.val > 0
                ? AppColors.TIPIGO_GREEN
                : AppColors.RED_TEXT
            : AppColors.BLACK};
`
