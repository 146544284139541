import React, {Component} from 'react'
import styled from 'styled-components'

const dauration = 1
export default class ArrayTransition extends Component {
    /* 21.11.21 cancel all these because they all take care of height to take care of show/hide
                but now we just pass it the nuber of elements and height is nor set (auto)
                didn't check for bugs...
                
    state = {
        length: this.props.length,
        childHeight: this.props.estimatedChildheight || 0,
    }
    componentDidMount() {
        this.setState({childHeight: Number(this.getChildHeight())})
    }
    componentWillReceiveProps = (nextProps) => {
        if (this.state.length != nextProps.length) {
            const childHeight = Number(this.getChildHeight())
            const length = nextProps.length
            this.setState({length, childHeight})
        }
    }
    getChildHeight = () => {
        if (this.node) {
            const el = this.node.firstChild
            const style = window.getComputedStyle(el)
            const childHeight =
                el.getBoundingClientRect().height + parseInt(style.marginTop)
            return childHeight
        }
        return null
    }*/

    render() {
        //const {length, childHeight} = this.state
        //console.log('general', length, childHeight)
        return (
            <Container
                innerRef={(c) => (this.node = c)}
                // 21.11.21 cancel next, control number by source
                //maxheight={96} //{length * childHeight}
            >
                {this.props.children}
            </Container>
        )
    }
}

const Container = styled.div`
    max-height: ${(p) => p.maxheight}px;
    transition: all ease-in-out ${dauration}s;
    overflow: hidden;
`
