import styled from 'styled-components'
import React from 'react'
import {AppColors} from '@services/styles'
import {useState} from '@services/hooks'
import {ReactComponent as GoArr} from '@images/go.svg'

// const STYLE = {
//     control: (base) => ({
//         ...base,
//         border: 0,
//         // This line disable the blue border
//         boxShadow: 'none',
//     }),
// }

const FrameInput = ({
    value,
    setValue,
    title,
    placeholder,
    style,
    params,
    disabled,
    password,
    maxLength,
    inputStyle,
    frameStyle,
    labelStyle,
    autoComplete,
    sMode,
    sModeDone,
}) => {
    const [focus, setFocus] = useState(false)
    const [showGo, setShowGo] = useState(false)

    const done = () => {
        if (sModeDone) {
            sModeDone()
        }
        setFocus(false)
        setShowGo(false)
    }
    const pressEnter = (e) => {
        if (e.key === 'Enter' && sMode) {
            done()
        }
    }

    return (
        <Container style={style}>
            {title && (
                <GroupLabel disabled={disabled} style={labelStyle}>
                    {title}
                </GroupLabel>
            )}
            <FormGroup disabled={disabled} style={frameStyle} focus={focus}>
                <InputField
                    autoComplete={autoComplete}
                    type={password ? 'password' : 'text'}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value, params)
                        // because we call done also on enter than focus = false but is still focused - so add these 2
                        setFocus(sMode)
                        setShowGo(sMode)
                    }}
                    maxLength={maxLength}
                    style={inputStyle}
                    onFocus={() => {
                        setFocus(sMode)
                        setShowGo(sMode)
                    }}
                    onBlur={done}
                    onKeyDown={pressEnter}
                />
                {sMode &&
                    showGo && ( // do not use && focus here becuase it disappears before the click
                        <Completer onClick={done}>
                            <GoArr />
                        </Completer>
                    )}
            </FormGroup>
        </Container>
    )
}

export default FrameInput

const Container = styled.div`
    display: flex;
    flex-direction: column;
`
const Completer = styled.div`
    border-left: 2px solid ${AppColors.TIPIGO_GREEN};
    height: 100%;
    align-items: center;
    padding-left: 6px;
`
const FormGroup = styled.div`
    padding: 10px;
    border: 1px solid
        ${(p) =>
            p.disabled
                ? '#eeeeee'
                : p.focus
                ? AppColors.TIPIGO_GREEN
                : AppColors.APP_GRAY_LIGHT};
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    height: 28px; // needed but get a totaly different height.. why..?
    background-color: white;
`
const GroupLabel = styled.label`
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 3px;
    margin-left: 4px;
    color: ${(p) => (p.disabled ? '#bbbbbb' : '#000000')};
`

const InputField = styled.input`
    border: none;
    width: 100%;
    outline: none;
    background-color: white !important;
    color: ${(p) => (p.disabled ? '#bbbbbb' : '#000000')};
    &:-webkit-autofill:focus {
        transition:
            background-color 600000s 0s,
            color 600000s 0s;
    }
    &:-webkit-autofill {
        transition:
            background-color 600000s 0s,
            color 600000s 0s;
    }
`
// &:focus {
//     outline: none;
//     transition: background-color 600000s 0s, color 600000s 0s;
// }
// &::-webkit-autofill {
//     focus {
//         transition: background-color 600000s 0s, color 600000s 0s;
//     }
// }
// &: data-autocompleted {
//     background-color: transparent !important;
// }
